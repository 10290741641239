import React, { ReactNode, useState } from 'react'
import {
  DialogContent,
  useMediaQuery,
  useTheme,
  styled,
  Skeleton,
  DialogActions,
  Paper,
} from '@mui/material'
import { Modal } from '../Modal'
import { PageContainer } from '../PageContainer'
import { FormProps } from './formTypes'
import { defaultFormLabels } from './defaultFormLabels'
import { FormContent } from './FormContent'
import { FormPageActionsLayout } from './FormActions'
import { FormLabels } from '.'
import { FilesWillBeDeletedWarning, useConfirmModal } from '../ConfirmModal'

const MobileBoxStyled = styled('div', {
  shouldForwardProp: prop => prop !== 'downSM',
})<{ downSM: boolean }>(({ theme, downSM }) => ({
  position: downSM ? 'absolute' : 'relative',
  ...(downSM && {
    zIndex: 1300,
    height: '100vh',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    top: 0,
  }),
}))

export const Form = (props: FormProps) => {
  const {
    onCloseForm,
    docLabel,
    editingDocID,
    wrapInside,
    modalMaxWidth,
    labels: labelsProp,
    labelsMode,
    loading = false,
    loadingSkeleton = 4,
  } = props
  const theme = useTheme()
  const confirmModal = useConfirmModal()
  const downSM = useMediaQuery(theme.breakpoints.down('sm'))
  const [isDirty, setIsDirty] = useState(false)
  const isEditing = Boolean(editingDocID)

  const editLabel = Boolean(labelsMode)
    ? labelsMode === 'update' || false
    : isEditing

  const labels: FormLabels = {
    ...defaultFormLabels,
    ...labelsProp, // overwite default
  }
  const { updating, adding, updated, added } = labels
  labels.mutatingLabel = `${docLabel} ${editLabel ? updating : adding}`
  labels.mutatedLabel = `${docLabel} ${editLabel ? updated : added}.`

  const onCancel = async () => {
    if (isDirty) {
      confirmModal({
        title: labels.closeText,
        confirmLabel: labels.closing,
        cancelLabel: labels.staying,
        message: isEditing ? labels.closeWarningEdit : labels.closeWarning,
        onConfirm: onCloseForm,
        content: <FilesWillBeDeletedWarning files={[]} labels={labels} />,
      })
    } else {
      onCloseForm()
    }
  }

  return (
    <>
      {wrapInside === 'page' && (
        <MobileBoxStyled downSM={downSM}>
          {loading ? (
            <>
              <FormPageActionsLayout
                title={labels.loading}
                onCloseForm={onCancel}
                ActionButtonsPage={<Skeleton height={48} width={120} />}
              />
              <PageContainer>
                <Paper
                  variant='outlined'
                  sx={{ marginTop: '16px', padding: '16px 24px' }}
                >
                  <SkeletonRenderer skeleton={loadingSkeleton} />
                </Paper>
              </PageContainer>
            </>
          ) : (
            <FormContent {...props} labels={labels} setIsDirty={setIsDirty} />
          )}
        </MobileBoxStyled>
      )}
      {wrapInside === 'modal' && (
        <Modal
          open={true}
          maxWidth={modalMaxWidth}
          onClose={onCancel}
          closeButton
          customHeight={downSM ? '100vh' : 'auto'}
          title={loading ? labels.loading : labels.mutatingLabel}
        >
          {loading ? (
            <>
              <DialogContent dividers style={{ padding: 16, width: '100%' }}>
                <SkeletonRenderer skeleton={loadingSkeleton} />
              </DialogContent>
              <DialogActions sx={{ padding: '0px 16px' }}>
                <Skeleton height={52} width={120} />
                <Skeleton height={52} width={120} />
              </DialogActions>
            </>
          ) : (
            <FormContent {...props} labels={labels} setIsDirty={setIsDirty} />
          )}
        </Modal>
      )}
      {wrapInside === 'formElement' &&
        (loading ? (
          <SkeletonRenderer skeleton={loadingSkeleton} />
        ) : (
          <FormContent {...props} labels={labels} setIsDirty={setIsDirty} />
        ))}
    </>
  )
}
const SkeletonRenderer = ({ skeleton }: { skeleton?: ReactNode | number }) => (
  <div>
    {typeof skeleton === 'number'
      ? Array.from(Array(skeleton).keys()).map(i => (
          <Skeleton key={i} height={54} />
        ))
      : skeleton
      ? skeleton
      : Array.from(Array(4).keys()).map(i => <Skeleton key={i} height={54} />)}
  </div>
)

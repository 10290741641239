import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { collectionNames } from '@fivano/models'

const adapter = createEntityAdapter({
  selectId: (doc: any) => doc._id,
})

const sliceCreator = ({ name, adapter }) => {
  const slice = createSlice({
    name,
    initialState: {
      ...adapter.getInitialState(),
      allQueried: false,
      queriedIDs: [],
    },
    reducers: {
      removeOne: adapter.removeOne,
      upsertOne: adapter.upsertOne,
      upsertMany: adapter.upsertMany,
      addMany: adapter.addMany,
      addQueriedIDs: (state, action) => ({
        ...state,
        queriedIDs: [...new Set([...state.queriedIDs, ...action.payload])],
      }),
      setAllQueried: (state, action) => ({
        ...state,
        allQueried: action.payload,
      }),
    },
  })

  const selectors = adapter.getSelectors(state => state[name])

  return { slice, selectors }
}

const modelSlices = collectionNames.reduce((acc, collection) => {
  return {
    ...acc,
    [collection]: sliceCreator({
      name: collection,
      adapter,
    }),
  }
}, {})

export const modelReducers = Object.keys(modelSlices).reduce((acc, slice) => {
  return { ...acc, [slice]: modelSlices[slice].slice.reducer }
}, {})

export default modelSlices

import { FormInputsBuilder } from 'app/components/Form/FormInputsBuilder'
import { Grid } from '@mui/material'

const PersonFormFieldInputs = ({ formObject, showAllFields }) => {
  const inputsConfig = [
    {
      type: 'FormPaper',
      content: [
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 9 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'firstName',
                        label: 'Voornaam',
                        rules: { required: 'Dit veld is verplicht.' },
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 3 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'middleName',
                        label: 'Voorvoegsel',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 9 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'lastName',
                        label: 'Achternaam',
                        rules: { required: 'Dit veld is verplicht.' },
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 3 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'initials',
                        label: 'Initialen',
                        rules: { required: 'Dit veld is verplicht.' },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          hide: !showAllFields,
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 9 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'givenNames',
                        label: 'Voornamen',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          hide: !showAllFields,
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 9 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'birthName',
                        label: 'Geboortenaam',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'location',
          props: { helperText: '' },
          content: [
            {
              type: 'AutoComplete',
              props: {
                name: 'location',
                label: 'Locatie persoon',
                staticOptions: { fivano: 'Fivano' },
                rules: { required: 'Dit veld is verplicht.' },
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'identity',
          props: { helperText: '' },
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'socialSecurityNumber',
                        label: 'BSN nummer',
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'DatePicker',
                      props: {
                        name: 'birthdate',
                        label: 'Geboortedatum',
                        rules: { required: 'Dit veld is verplicht.' },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          hide: !showAllFields,
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'AutoComplete',
                      props: {
                        name: 'identificationType',
                        label: 'Indentificatie document type',
                        staticOptions: {
                          passport: 'Paspoort',
                          identificationCard: 'ID kaart',
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'identificationNumber',
                        label: 'Document nummer',
                        type: 'tel',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          hide: !showAllFields,
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'DatePicker',
                      props: {
                        name: 'identificationValidUntil',
                        label: 'Geldig tot',
                        errorTranslations: {
                          minDateError:
                            'Legitimatie geldigheidsdatum moet in de toekomst liggen',
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'gender',
          props: { helperText: ' ' },
          content: [
            {
              type: 'RadioGroup',
              props: {
                name: 'gender',
                options: [
                  { label: 'Man', value: 'M' },
                  { label: 'Vrouw', value: 'F' },
                  { label: 'X', value: 'X' },
                  { label: 'Onbekend', value: 'O' },
                ],
                row: true,
                rules: { required: 'Dit veld is verplicht.' },
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'address',
          props: { helperText: '' },
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'addressStreet',
                        label: 'Straatnaam',
                        rules: { required: 'Dit veld is verplicht.' },
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 3 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'addressNumber',
                        label: 'Nummer',
                        rules: { required: 'Dit veld is verplicht.' },
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 3 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'addressAddition',
                        label: 'Toevoeging',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 4 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'addressZip',
                        label: 'Postcode',
                        rules: { required: 'Dit veld is verplicht.' },
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 8 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'addressCity',
                        label: 'Plaatsnaam',
                        rules: { required: 'Dit veld is verplicht.' },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          hide: !showAllFields,
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'AutoComplete',
                      props: {
                        name: 'addressCountry',
                        label: 'Land',
                        staticOptions: { NL: 'Nederland' },
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'addressState',
                        label: 'Provincie',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'phone',
          props: { helperText: '' },
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'phoneMobile',
                        label: 'Mobiele telefoon',
                        rules: { required: 'Dit veld is verplicht.' },
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'phoneLine',
                        label: 'Vaste telefoon',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          hide: !showAllFields,
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'phoneNoAnswer',
                        label: 'Geen gehoor tel.',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'email',
          props: { helperText: '' },
          content: [
            {
              type: 'TextField',
              props: {
                name: 'email',
                label: 'Email',
                rules: { required: 'Dit veld is verplicht.' },
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'education',
          props: {
            helperText:
              'Middelbare school niveau en profiel plus beroeps of hoger onderwijs opleiding. Bijvoorbeeld HAVO C&M, HBO Verpleegkunde.',
          },
          content: [
            {
              type: 'TextField',
              props: {
                name: 'education',
                label: 'School en opleiding',
                rows: 3,
                multiline: true,
                maxRows: 10,
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'maritalStatus',
          props: { helperText: '' },
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 9 },
                  content: [
                    {
                      type: 'AutoComplete',
                      props: {
                        name: 'civilStatus',
                        label: 'Burgelijke staat',
                        staticOptions: {
                          ongehuwd: 'Ongehuwd',
                          wettigGehuwd: 'Wettig gehuwd',
                          partnerschap: 'Partnerschap',
                          verweduwdNaWettigHuwelijk:
                            'Verweduwd na wettig huwelijk',
                          verweduwdNaPartnerschap: 'Verweduwd na partnerschap',
                          gescheidenNaWettigHuwelijk:
                            'Gescheiden na wettig huwelijk',
                          gescheidenNaPartnerschap:
                            'Gescheiden na partnerschap',
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 3 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'numberChilds',
                        label: 'Kinderen',
                        type: 'number',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'credit',
          props: { helperText: '' },
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'ibanNumber',
                        label: 'IBAN nummer',
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'ibanName',
                        label: 'IBAN ten name van',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          hide: !showAllFields,
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 8 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'dossierNumber',
                        label: 'Dossier nummer',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ]

  return (
    <FormInputsBuilder inputsConfig={inputsConfig} formObject={formObject} />
  )
}

export const PersonFormInputs = ({ formObject, showAllFields }) => {
  return (
    <Grid container direction='column' spacing={2}>
      <PersonFormFieldInputs
        formObject={formObject}
        showAllFields={showAllFields}
      />
    </Grid>
  )
}

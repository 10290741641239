import { Grid } from '@mui/material'
import { FormInputsBuilder } from 'app/components/Form/FormInputsBuilder'

const FormFieldInputs = ({ formObject }) => {
  const inputsConfig = [
    {
      type: 'FormRow',
      icon: '',
      content: [
        {
          type: 'TextField',
          props: {
            name: 'name',
            label: 'Groep naam',
            rules: { required: 'Dit veld is verplicht.' },
          },
        },
      ],
    },
    {
      type: 'FormRow',
      icon: '',
      content: [
        {
          type: 'TextField',
          props: {
            name: 'description',
            label: 'Beschrijving',
            rows: 4,
            multiline: true,
          },
        },
      ],
    },
    {
      type: 'FormRow',
      icon: '',
      content: [
        {
          type: 'AutoComplete',
          props: {
            name: 'attendant',
            label: 'begeleider',
            rules: { required: 'Dit veld is verplicht.' },
            optionsCollection: 'persons',
            getOptionLabel: option => `${option.firstName} ${option.lastName}`,
            query: {
              personType: { $eq: 'attendant' },
            },
          },
        },
      ],
    },
    {
      type: 'FormRow',
      icon: '',
      content: [
        {
          type: 'AutoComplete',
          props: {
            name: 'students',
            label: 'Leerlingen',
            rules: { required: 'Dit veld is verplicht.' },
            optionsCollection: 'persons',
            getOptionLabel: option => `${option.firstName} ${option.lastName}`,
            query: {
              personType: { $eq: 'student' },
            },
            multiple: true,
          },
        },
      ],
    },
    {
      type: 'FormRow',
      icon: '',
      content: [
        {
          type: 'AutoComplete',
          props: {
            name: 'module',
            label: 'Les module',
            rules: { required: 'Dit veld is verplicht.' },
            optionsCollection: 'courseModules',
          },
        },
      ],
    },
  ]

  return (
    <FormInputsBuilder inputsConfig={inputsConfig} formObject={formObject} />
  )
}

export const CourseGroupFormInputs = ({ formObject, showAllFields }) => {
  return (
    <Grid container direction='column' spacing={2}>
      <FormFieldInputs formObject={formObject} />
    </Grid>
  )
}

import React from 'react'
import { styled, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useSelector } from 'hooks/useSelector'
import { Avatar } from '@fivano/core'
import { changeThemeMode, selectThemeMode } from 'styles/theme/slice'
import { legacyRoutes } from 'app/router/legacyRoutes'
import NestedMenuItem from './NestedMenuItem'
import { MenuItemLink } from './MenuItemLink'
import { menuLayoutType } from 'app/router/types'
import {
  DarkMode,
  LightMode,
  AccountCircleOutlined,
  LogoutOutlined,
  KeyboardArrowRight,
  NotificationsOutlined,
  Code,
} from '@mui/icons-material'
import { personFullName } from 'model/person'
import { DevTools } from '../DevTools'
import { useSignOut } from 'hooks/useSignOut'
import { NotificationsContent } from './NotificationsContent'

const FooterWrapper = styled('div')(() => ({
  marginTop: 'auto',
  paddingBottom: 20,
}))

export const NavigationFooter = ({ open }) => {
  const profile = useSelector(state => state.firebase.profile)
  const handleLogout = useSignOut()
  const dispatch = useDispatch()
  const darkMode = useSelector(selectThemeMode)

  const accountMenu: menuLayoutType[] = [
    {
      name: 'Mijn Account',
      route: legacyRoutes.userAccount,
      icon: AccountCircleOutlined,
    },
    {
      name: darkMode ? 'Lichte modus' : 'Donkere modus',
      icon: darkMode ? DarkMode : LightMode,
      // @ts-ignore
      route: {},
      onClick: () => dispatch(changeThemeMode(!darkMode)),
    },
    {
      name: 'Uitloggen',
      icon: LogoutOutlined,
      // @ts-ignore
      route: {},
      onClick: handleLogout,
    },
  ]

  return (
    <FooterWrapper>
      {/** DevTools menu: only visible when developing*/}
      {process.env.REACT_APP_ENV === 'development' && (
        <NestedMenuItem
          menuItemContent={
            <MenuItemLink
              label='DevTools'
              disableTooltip
              open={open}
              hasMenu
              icon={Code}
            />
          }
          parentMenuOpen={true}
        >
          <DevTools />
        </NestedMenuItem>
      )}

      {/** Notifications Container: only visible when user has notifications role */}
      {profile?.claims?.n > 1 && (
        <NestedMenuItem
          menuItemContent={
            <MenuItemLink
              label='Notificaties'
              disableTooltip
              open={open}
              hasMenu
              icon={NotificationsOutlined}
            />
          }
          parentMenuOpen={true}
        >
          <NotificationsContent />
        </NestedMenuItem>
      )}

      {/** Account menu */}
      <NestedMenuItem
        menuItemContent={
          <MenuItemLink
            path='/mijn-account'
            label='Mijn Account'
            disableTooltip
            open={open}
            hasMenu
          >
            <Avatar personData={profile} />
            <Typography sx={{ marginLeft: '12px', width: '100%' }}>
              {personFullName(profile)}
            </Typography>
            <KeyboardArrowRight color='action' />
          </MenuItemLink>
        }
        parentMenuOpen={true}
      >
        {accountMenu.map(item => (
          <MenuItemLink
            key={`${item.route.path}${item.name}`}
            path={item.route.path}
            icon={item.icon}
            label={item.name}
            disableTooltip
            open
            onClick={item.onClick}
          />
        ))}
      </NestedMenuItem>
    </FooterWrapper>
  )
}

import React, { useEffect, useState } from 'react'
import { useDebounce, usePrevious } from 'react-use'
import { Autocomplete, Box, styled } from '@mui/material'
import { TextField as TextFieldMat } from '@mui/material'
import { Controller } from 'react-hook-form'
import useMongo from 'hooks/useMongo'
import { compareArrays, TextField } from '@fivano/core'
import { useApiRequest } from 'hooks/useApiRequest'
import { useDispatch } from 'react-redux'
import { queryAndCacheDocs } from 'hooks/useMongo/useMongo'
import { UseFormReturn } from 'react-hook-form'

const TextFieldMatStyled = styled(TextFieldMat)(() => ({
  margin: '0px 0px 6px 0px',
}))

type SetSearch = React.Dispatch<React.SetStateAction<string>>

type multiple = {
  /** If true, value must be an array and the menu will support multiple selections. */
  multiple?: boolean
}

type name = {
  /** Name of the input. */
  name: string
}

type formObject = {
  /** The RHF formObject returned by the RHF useForm method. */
  formObject: UseFormReturn
}

type optionsCollection = {
  /** The collection of the options that should be fetched from the database. */
  optionsCollection?: string
}

type readOnly = {
  readOnly?: boolean
}

type WithOptionsCollectionProps = {
  query?: any
  getOptionLabel?: () => void
  render: ({
    options,
    setSearch,
    loading,
  }: {
    options: object
    setSearch: SetSearch
    loading: boolean
  }) => JSX.Element
} & name &
  formObject &
  optionsCollection &
  multiple

type AutoCompleteProps = {
  /** The label shown above the input. */
  label: string
  /** Hardcoded options from which the user can choose. */
  staticOptions?: object
  rules?: object
  query?: any
  getOptionLabel?: () => void
} & multiple &
  name &
  formObject &
  optionsCollection &
  readOnly

type AutoCompleteInputProps = {
  onChangeRHF: (...event: any[]) => void
  value: any
  label: string
  options: object
  setSearch?: SetSearch
  rules?: object
  name: string
  formObject: UseFormReturn
  loading?: boolean
} & multiple &
  readOnly

const docsToOptions = (docs, getOptionLabel) =>
  docs?.reduce((acc, option) => {
    return {
      ...acc,
      [option?._id]: getOptionLabel ? getOptionLabel(option) : option?.name,
    }
  }, {}) as object

const AutoCompleteInput = ({
  multiple,
  onChangeRHF,
  value,
  label,
  options,
  rules,
  setSearch,
  name,
  formObject,
  loading = false,
  readOnly = false,
}: AutoCompleteInputProps) => {
  return (
    <>
      {readOnly && (
        <Autocomplete
          options={Object.keys(options)}
          getOptionLabel={option =>
            options[option] || 'Error: label not found.'
          }
          disabled
          value={value}
          renderInput={params => (
            <TextFieldMatStyled
              {...params}
              label={label}
              variant='outlined'
              size='small'
            />
          )}
        />
      )}
      <Box display={readOnly ? 'none' : undefined}>
        <Autocomplete
          size='small'
          multiple={multiple}
          loading={loading}
          loadingText='Laden...'
          noOptionsText='Geen opties gevonden'
          filterSelectedOptions={multiple}
          options={Object.keys(options)}
          onChange={(_, values) => {
            onChangeRHF(values)
            setSearch && setSearch('')
          }}
          value={value}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={option =>
            options[option] || 'Error: label not found.'
          }
          renderInput={params => (
            <TextField
              name={name}
              formObject={formObject}
              onChange={event => {
                setSearch && setSearch(event.target.value)
              }}
              label={label}
              rules={rules}
              variant='outlined'
              {...params}
            />
          )}
        />
      </Box>
    </>
  )
}

const WithOptionsCollection = ({
  query,
  optionsCollection = '',
  getOptionLabel,
  render,
  formObject,
  name,
  multiple,
}: WithOptionsCollectionProps) => {
  const [search, setSearch] = useState('')
  const [optionsData, setOptionsData] = useState({
    status: 'loading',
    options: {},
  })
  const value = formObject.watch(name)
  const selectedIDs = multiple ? value : [value].filter(Boolean)
  const prevSelectedIDs = usePrevious(selectedIDs) || []
  const prevSearch = usePrevious(search)
  const makeRequest = useApiRequest()
  const dispatch = useDispatch()
  const { useGetDocs } = useMongo()
  const selectedDocs = useGetDocs({
    collection: optionsCollection,
    docIDs: selectedIDs,
  })
  const selectedOptions = selectedDocs
    ? docsToOptions(Object.values(selectedDocs), getOptionLabel)
    : {}

  useEffect(() => {
    setOptionsData({
      status: 'loading',
      options: {
        ...(selectedIDs?.length > 0 && selectedOptions),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevSearch, search])

  useDebounce(
    () => {
      if (
        !compareArrays(selectedIDs, prevSelectedIDs) ||
        optionsData.status === 'loading' ||
        prevSearch !== search
      ) {
        const getOptions = async () => {
          const newOptions = docsToOptions(
            await queryAndCacheDocs({
              makeRequest,
              collection: optionsCollection,
              dispatch,
              limit: selectedIDs?.length + 5,
              query: {
                ...(search && { name: { $regex: search, $options: 'i' } }),
                ...(query && query),
              },
            }),
            getOptionLabel,
          )
          setOptionsData({
            status: 'success',
            options: {
              ...newOptions,
              ...(selectedIDs?.length > 0 && selectedOptions),
            },
          })
        }
        getOptions()
      }
    },
    500,
    [prevSearch, search, selectedIDs, prevSelectedIDs],
  )

  return render({
    setSearch,
    options: optionsData.options,
    loading: optionsData.status === 'loading',
  })
}

export const AutoComplete = ({
  query,
  name,
  formObject,
  label,
  multiple = false,
  optionsCollection,
  staticOptions = {},
  getOptionLabel,
  rules,
  readOnly,
}: AutoCompleteProps) => {
  return (
    <Controller
      name={name}
      control={formObject.control}
      defaultValue={multiple ? [] : null}
      rules={rules}
      render={({ field: { onChange: onChangeRHF, value } }) => {
        const params = {
          multiple,
          onChangeRHF,
          value,
          label,
          name,
          formObject,
          staticOptions,
          rules,
          readOnly,
        }
        if (optionsCollection) {
          return (
            <WithOptionsCollection
              getOptionLabel={getOptionLabel}
              query={query}
              formObject={formObject}
              name={name}
              multiple={multiple}
              optionsCollection={optionsCollection}
              render={({ options, setSearch, loading }) => (
                <AutoCompleteInput
                  options={options}
                  setSearch={setSearch}
                  loading={loading}
                  {...params}
                />
              )}
            />
          )
        }
        return <AutoCompleteInput options={staticOptions} {...params} />
      }}
    />
  )
}

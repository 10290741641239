import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import {
  Card,
  Box,
  ButtonBase,
  Grid,
  Typography,
  Chip,
  FormControlLabel,
  Switch as MaterialSwitch,
  Popover,
  MenuItem,
  styled,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  EmptyContent,
  FloatingButton,
  IconButton,
  PageContainer,
} from '@fivano/core'
import { BoardForm } from './BoardForm'
import { useSelector } from 'hooks/useSelector'
import { Paragraph } from '@fivano/core'

const breadcrumbArray = [
  { title: 'Home', path: '/' },
  { title: 'CRM', path: '/crm-borden' },
]

const CardStyled = styled(Card, {
  shouldForwardProp: prop => prop !== 'actionButtons',
})<any>(({ actionButtons }) => ({
  ...(actionButtons && {
    '&:hover .css-1dnct9n': {
      display: 'block',
    },
  }),
  width: '100%',
  height: 220,
  position: 'relative',
}))

const ButtonBaseStyled = styled(ButtonBase)<any>(() => ({
  display: 'block',
  padding: '16px 16px 0px 16px',
  width: '100%',
  height: '100%',
}))

const DivStyled = styled('div')(() => ({
  position: 'absolute',
  width: 'auto',
  top: 4,
  right: 4,
}))

const BoardLink = ({ board, onEdit }) => (
  <CardStyled actionButtons>
    <ButtonBaseStyled component={Link} to={`crm-borden/${board.id}`}>
      <Typography variant='h5'>{board.nameBoard}</Typography>
      <Typography gutterBottom variant='body2' color='textSecondary'>
        {board.locationBoard.label}
      </Typography>

      <Paragraph showButton={true} lines={5} variant='body2'>
        {board.descriptionBoard}
      </Paragraph>
      {board.archivedBoard && (
        <Box mt={1}>
          <Chip label='Gearchiveerd' size='small' />{' '}
        </Box>
      )}
    </ButtonBaseStyled>
    <DivStyled id={`edit_${board.id}`}>
      <IconButton label='Bewerken' onClick={onEdit} size='large'>
        <EditIcon />
      </IconButton>
    </DivStyled>
  </CardStyled>
)

export const Boards = () => {
  const [showArchived, setShowArchived] = useState(false)
  const [optionsAnchor, setOptionsAnchor] = useState(null)

  const openOptionsMenu = event => {
    setOptionsAnchor(event.currentTarget)
  }

  const closeOptionsMenu = () => {
    setOptionsAnchor(null)
  }
  const [boardModalProps, setBoardModalProps] = useState({
    boardID: undefined,
    open: false,
  })

  useFirestoreConnect([
    {
      collection: 'boards',
      where: ['archivedBoard', '==', showArchived],
      limit: 20,
      storeAs: 'boards',
    },
  ])

  const boardsData = useSelector(({ firestore: { ordered } }) => {
    return ordered.boards
      ?.slice()
      .sort((a, b) =>
        a.nameBoard.toLowerCase().localeCompare(b.nameBoard.toLowerCase()),
      )
  })

  const history = useHistory()
  const [pushRoute, setPushRoute] = useState<string>('')
  useEffect(() => {
    if (pushRoute !== '' && boardModalProps.open === false) {
      history.push(pushRoute)
      setPushRoute('')
    }
  }, [history, pushRoute, boardModalProps])

  return (
    <>
      <Helmet>
        <title>CRM borden</title>
      </Helmet>
      {boardModalProps.open && (
        <BoardForm
          onCloseForm={() => {
            setBoardModalProps({ boardID: undefined, open: false })
          }}
          onCreateSuccess={response =>
            setPushRoute(`/crm-borden/${response.id}`)
          }
          editingDocID={boardModalProps.boardID}
        />
      )}
      <PageContainer maxWidth='lg'>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Breadcrumbs breadcrumbs={breadcrumbArray} />
          </Grid>
          <Grid item>
            <IconButton
              label='Opties'
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={openOptionsMenu}
              size='large'
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              anchorEl={optionsAnchor}
              open={Boolean(optionsAnchor)}
              onClose={closeOptionsMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem>
                <FormControlLabel
                  control={
                    <MaterialSwitch
                      color='primary'
                      name='showArchivedPhases'
                      onChange={e => setShowArchived(e.target.checked)}
                      checked={showArchived}
                    />
                  }
                  label='Toon gearchiveerde borden'
                />
              </MenuItem>
            </Popover>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {boardsData?.length > 0 ? (
            boardsData.map(board => {
              return (
                <Grid key={board.id} item xs={12} sm={6} md={4}>
                  <BoardLink
                    onEdit={() => {
                      setBoardModalProps({ boardID: board.id, open: true })
                    }}
                    board={board}
                  />
                </Grid>
              )
            })
          ) : (
            <Grid item xs={12}>
              <EmptyContent text='U heeft geen borden. Voeg een bord toe of vraag toestemming tot een bord.' />
            </Grid>
          )}
        </Grid>
        <FloatingButton
          label='Bord toevoegen'
          onClick={() => {
            setBoardModalProps({ boardID: undefined, open: true })
          }}
        />
      </PageContainer>
    </>
  )
}

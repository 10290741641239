import React from 'react'
import { Typography, Grid, Box, Divider } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { SettingsContainer } from '../../components/SettingsContainer'

const breadcrumbArray = [
  { title: 'Instellingen', path: '/instellingen' },
  { title: 'Trajecten', path: '/instellingen' },
]

export const TrackSettings = () => (
  <>
    <Helmet>
      <title>Traject instellingen</title>
      <meta name='description' content='Traject instellingen' />
    </Helmet>
    <SettingsContainer breadcrumbs={breadcrumbArray}>
      <Box py={2} px={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant='h6'>
              Traject instellingen
            </Typography>

            <Box p={2} />
            <Divider orientation='horizontal' />
          </Grid>
        </Grid>
      </Box>
    </SettingsContainer>
  </>
)

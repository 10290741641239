export const readFile = (file: File, dataType: string) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader['readAs' + dataType](file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = err => reject(err)
  })

export const readArrayBuffer = (file: File): ArrayBuffer =>
  // @ts-ignore
  readFile(file, 'ArrayBuffer')

import { ActionMenu, MenuButton } from '@fivano/core'
import { Paper } from '@mui/material'
import useMongo from 'hooks/useMongo'
import { useHistory, useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import { GridColDef } from '@mui/x-data-grid-pro'
import { DataGridNew } from 'app/components/DataGridNew'
import { RouteTypes } from 'types/RouteTypes'

const personColumnsDesktop: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'Leerling',
    disableColumnMenu: true,
  },
]

const personColumnsMobile: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Leerling',
    disableColumnMenu: true,
  },
]

export const CourseGroupStudents = ({ studentIDs }) => {
  const { groupID } = useParams<RouteTypes>()
  const history = useHistory()
  const { useGetDocs } = useMongo()

  const studentsData = useGetDocs({
    collection: 'persons',
    docIDs: studentIDs,
    array: true,
  })

  return (
    <Paper>
      {studentsData && (
        <DataGridNew
          name='leerlingen'
          rows={studentsData}
          columns={personColumnsDesktop}
          columnsMobile={personColumnsMobile}
          hideFooterRowCount={true}
          onRowClick={rowData =>
            history.push(
              `/leerlingvolgsysteem/lesgroepen/${groupID}/leerlingen/${rowData.id}`,
            )
          }
          getRowId={row => row._id}
          rowActions={{
            renderCell: params => {
              return (
                <ActionMenu
                  iconPosition='vertical'
                  minWidth={250}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  <MenuButton
                    label='Bewerk leerling'
                    icon={<EditIcon />}
                    onClick={() =>
                      history.push(
                        `/leerlingvolgsysteem/lesgroepen/${groupID}/leerlingen/${params.id}`,
                      )
                    }
                  />
                </ActionMenu>
              )
            },
          }}
        />
      )}
    </Paper>
  )
}

import React, { useEffect, useState } from 'react'
import useMongo from 'hooks/useMongo'
import { DataGridNew } from 'app/components/DataGridNew'
import { GridColDef } from '@mui/x-data-grid-pro'
import { FloatingButton, PageContainer } from '@fivano/core'
import { Paper } from '@mui/material'
import { UserSettingsForm } from './UserSettingsForm'
import { useModalRoute } from 'hooks/useModalRoutes'
import { Breadcrumbs } from 'app/components/Breadcrumbs'

export const userSettingsColumnsDesktop: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'Gebruiker',
    disableColumnMenu: true,
    width: 500,
  },
]

export const userSettingsColumnsMobile: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'Gebruiker',
    disableColumnMenu: true,
    width: 300,
  },
]

export const UserSettingsPage = () => {
  const { useQueryDocs } = useMongo()
  const [docs, setDocs] = useState<any>(undefined)
  const { modalData, closeModal, openModal } = useModalRoute()

  const { docsData } = useQueryDocs({
    collection: 'persons',
  })

  useEffect(() => {
    if (docsData) {
      const emptyArray: any = Object.entries(docsData).map(
        ([_, value]) => value,
      )
      setDocs(emptyArray)
    }
  }, [docsData])

  return (
    <PageContainer maxWidth='lg'>
      <Breadcrumbs />
      <Paper>
        <DataGridNew
          name='gebruikers'
          rows={docs || []}
          columns={userSettingsColumnsDesktop}
          columnsMobile={userSettingsColumnsMobile}
          hideFooterSelectedRowCount={true}
          hideFooterRowCount={true}
          isEditable={true}
          onRowClick={data => openModal(data.row._id)}
        />
      </Paper>
      <FloatingButton label='Gebruiker toevoegen' onClick={() => openModal()} />
      {modalData.open && (
        <UserSettingsForm personID={modalData.id} onCloseForm={closeModal} />
      )}
    </PageContainer>
  )
}

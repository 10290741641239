import React, { useState } from 'react'
import { DevTool, PLACEMENT } from '@hookform/devtools'
import Button from '@mui/material/Button'
import { styled } from '@mui/material'

const ButtonStyled = styled(Button)(() => ({
  fontSize: 8,
  padding: 0,
  color: 'red',
  zIndex: 1400,
  margin: '10px 10px',
  position: 'fixed',
}))

export type DevToolPos = {
  placement?: PLACEMENT
  control: any
}

export const RHFDevTool = ({ control, placement = 'top-left' }: DevToolPos) => {
  const [showDevTool, setShowDevTool] = useState(false)
  return (
    <>
      {showDevTool && <DevTool control={control} placement={placement} />}
      <ButtonStyled
        variant='contained'
        size='small'
        onClick={() => {
          setShowDevTool(!showDevTool)
        }}
      >
        {showDevTool ? 'close DevTool' : 'open DevTool'}
      </ButtonStyled>
    </>
  )
}

import React, { useState } from 'react'

import { SettingsContainer } from 'app/components/SettingsContainer'
import { Chip, Grid, Typography } from '@mui/material'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { ActionMenu, MenuButton, IconButton, ListView } from '@fivano/core'

import ArchiveIcon from '@mui/icons-material/Archive'

import EditIcon from '@mui/icons-material/Edit'
import { WorkCodesField } from './WorkCodesField'
import { WorkCodesForm } from './WorkCodesForm'

export const WorkCodes = () => {
  const firestore = useFirestore()
  const [workCodesProps, setWorkCodesProps] = useState({
    workCodeID: null,
    open: false,
  })

  useFirestoreConnect([
    {
      collection: 'appSettings',
      doc: 'workCodes',
      storeAs: 'workCodes',
    },
  ])

  const docData = useSelector(
    ({ firestore: { data } }) => data.workCodes?.options,
  )

  // ==== LISTVIEW DEFINITIONS ==== //
  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          accessor: 'label',
          gridProps: {
            xs: 12,
            sm: 8,
            md: 8,
          },
          Cell: data => (
            <Grid container wrap='nowrap' spacing={2} alignItems='center'>
              <Grid item>
                <Typography variant='body1'>
                  {data.row.original.label}
                </Typography>
              </Grid>
            </Grid>
          ),
        },
        {
          Header: 'Gearchiveerd',
          accessor: `archived`,
          gridProps: {
            xs: 3,
            sm: 3,
            md: 3,
          },
          Cell: data => (
            <Grid item>
              {data.row.original[`archived`] && <Chip label='Gearchiveerd' />}
            </Grid>
          ),
        },
        {
          id: 'actions',
          Header: ' ',
          Cell: data => (
            <Grid item>
              <IconButton
                label='Bewerken'
                onClick={() =>
                  setWorkCodesProps({
                    workCodeID: data.row.original.id,
                    open: true,
                  })
                }
                size='large'
              >
                <EditIcon />
              </IconButton>
              <ActionMenu
                iconPosition='vertical'
                minWidth={250}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <MenuButton
                  label={
                    data.row.original?.archivedJoop
                      ? 'Werkcode tonen'
                      : 'Werkcode archiveren'
                  }
                  icon={<ArchiveIcon />}
                  onClick={() => handleArchive(data.row.original)}
                />
              </ActionMenu>
            </Grid>
          ),
        },
      ],
    },
  ]

  const handleArchive = data => {
    const copyData = { ...data }
    const optionsArray = [...docData]
    copyData.archived = !data.archived

    const replaceIndex = optionsArray.findIndex(item => item.id === copyData.id)
    optionsArray[replaceIndex] = copyData

    firestore.update(
      {
        collection: 'appSettings',
        doc: 'workCodes',
      },
      { options: optionsArray },
    )
  }

  const breadcrumbArray = [
    { title: 'Instellingen', path: '/instellingen' },
    {
      title: 'Werkcodes',
      path: '/instellingen/werkcodes',
    },
  ]

  return (
    <SettingsContainer breadcrumbs={breadcrumbArray} showTitle={false}>
      {workCodesProps.open && (
        <WorkCodesForm
          optionsData={docData}
          itemData={docData.find(doc => doc.id === workCodesProps.workCodeID)}
          formProps={workCodesProps}
          onClose={() => setWorkCodesProps({ workCodeID: null, open: false })}
        />
      )}
      <WorkCodesField optionsData={docData} />
      {docData && <ListView data={docData} columns={listColumns} />}
    </SettingsContainer>
  )
}

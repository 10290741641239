import React from 'react'
import { Grid, Typography } from '@mui/material'
import { DonutChart } from '@fivano/core'

type TimeRegistrationWidgetHeaderProps = {
  currentValue: number
  maximumValue: number
  label?: string
  subLabel?: string
}

export const TimeRegistrationWidgetHeader = ({
  currentValue,
  maximumValue,
  label,
  subLabel,
}: TimeRegistrationWidgetHeaderProps) => (
  <Grid container sx={{ padding: '16px' }} spacing={2}>
    <Grid item>
      <DonutChart
        currentValue={currentValue}
        maximumValue={maximumValue}
        valueFontSize='0.35em'
        chartLabel='Uur'
        labelFontSize='0.25em'
        customWidth='150'
        strokeWidth='5'
      />
    </Grid>
    <Grid item>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h6'>{subLabel}</Typography>
          <Typography variant='body1'>{label}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{`${`${Math.floor(currentValue / 60)}/${Math.floor(
            maximumValue / 60,
          )}`} uur voltooid`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)

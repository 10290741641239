import { routeObjectType } from 'app/router/types'
import { POSLayout } from './POSLayout/POSLayout'
import { PointOfSalePage } from './PointOfSalePage'

export const pointOfSaleRoutes: routeObjectType = {
  pointOfSalePage: {
    path: '/kassa',
    component: PointOfSalePage,
    claims: { dev: 1 },
  },
  pointOfSaleLayout: {
    path: '/kassa-indeling',
    component: POSLayout,
    claims: { dev: 1 },
  },
}

import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'hooks/useSelector'
import { useFirestoreConnect } from 'react-redux-firebase'

import {
  Grid,
  Box,
  Paper,
  Typography,
  Chip,
  Button,
  capitalize,
  styled,
} from '@mui/material'

import { PageContainer, Checkbox, FloatingButton, ListView } from '@fivano/core'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import EditIcon from '@mui/icons-material/Edit'
import { moduleSettings } from './moduleSettings'
import { ModuleForm } from './ModuleForm'
import { useSettings } from 'hooks/useSettings'
import { ActionMenu, MenuButton } from '@fivano/core'
import { Archive } from '@mui/icons-material'
import { useArchiveDoc } from 'hooks/useArchiveDoc'
import { SearchFilter } from 'app/components/SearchFilter'
import { useFilters } from 'hooks/useFilters'

const PaperStyled = styled(Paper)(() => ({
  marginBottom: '80px',
}))

export const Modules = () => {
  const [moduleFormProps, setModuleFormProps] = useState({
    moduleID: null,
    open: false,
  })

  const [modulesLimit, setModulesLimit] = useState(moduleSettings.listviewLimit)
  const settings = useSettings()
  const labels = settings.tracks.labels
  const breadcrumbArray = [
    { title: 'Home', path: '/' },
    { title: `${capitalize(labels.track)} instellen`, path: '/trajecten' },
  ]

  const [filters, setFilters] = useFilters({
    searchField: {
      value: '',
      label: 'Zoek op:',
      param: true,
    },
    archived: {
      value: false,
      label: 'Toon gearchiveerd',
      param: true,
    },
  })
  const handleFilter = data => {
    const newFilters = { ...filters }
    newFilters.searchField.value = data.searchField
    newFilters.archived.value = data.archived
    setFilters(newFilters)
  }

  useFirestoreConnect([
    {
      collection: 'modules',
      orderBy: ['nameModu', 'asc'],
      where: [
        [
          'keywordsModu',
          'array-contains',
          filters.searchField.value.toLowerCase(),
        ],
        ['archivedModu', '==', filters.archived.value],
      ],
      limit: modulesLimit,
      storeAs: 'modules',
    },
  ])

  const modulesData = useSelector(
    ({ firestore: { ordered } }) => ordered.modules,
  )

  const archiveDoc: any = useArchiveDoc()
  const handleArchive = data => {
    archiveDoc({
      docType: 'Module',
      path: `modules/${data.id}`,
      archived: !data?.archivedModu,
      extension: 'Modu',
    })
  }

  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: ' ',
          accessor: 'nameModu',
          gridProps: {
            xs: 12,
          },
          Cell: data => (
            <div>
              <Typography variant='body1' display='inline' gutterBottom>
                {data.row.original.nameModu}{' '}
                <Chip size='small' label={data.row.original.codeModu} />
              </Typography>
            </div>
          ),
        },
        {
          id: 'actions',
          Header: ' ',
          // hidden: { only: ['xs', 'sm'] },
          Cell: data => (
            <Grid item>
              <ActionMenu
                iconPosition='vertical'
                minWidth={250}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <MenuButton
                  label='Bewerk module'
                  onClick={() => {
                    setModuleFormProps({
                      moduleID: data.row.original.id,
                      open: true,
                    })
                  }}
                  icon={<EditIcon />}
                />
                <MenuButton
                  label={
                    data.row.original?.archivedModu
                      ? 'Module dearchiveren'
                      : 'Module archiveren'
                  }
                  icon={<Archive />}
                  onClick={() => handleArchive(data.row.original)}
                />
              </ActionMenu>
            </Grid>
          ),
        },
      ],
    },
  ]

  return (
    <>
      <Helmet>
        <title>{`${capitalize(labels.track)} instellingen overzicht`}</title>
      </Helmet>
      {moduleFormProps.open && (
        <ModuleForm
          wrapInside='modal'
          modalMaxWidth='sm'
          onCloseForm={() => {
            setModuleFormProps({ moduleID: null, open: false })
          }}
          editingDocID={moduleFormProps.moduleID}
        />
      )}
      <PageContainer maxWidth='lg'>
        <Breadcrumbs breadcrumbs={breadcrumbArray} />
        <PaperStyled>
          <Box p={2}>
            <Grid container>
              <Grid item xs={12}>
                <SearchFilter
                  placeholder={`Zoeken in ${labels.track}`}
                  onSubmit={handleFilter}
                  filterValues={filters}
                  filters={({ formObject }) => {
                    return (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Checkbox
                              label={`Toon gearchiveerde ${labels.tracks}`}
                              formObject={formObject}
                              name='archived'
                            />
                          </Grid>
                        </Grid>
                      </>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <FloatingButton
            label={`${capitalize(labels.track)} toevoegen`}
            onClick={() => {
              setModuleFormProps({ moduleID: null, open: true })
            }}
          />
          {modulesData?.length > 0 ? (
            <>
              <ListView
                data={modulesData}
                columns={listColumns}
                onClick={(_, data) => {
                  setModuleFormProps({
                    moduleID: data.original.id,
                    open: true,
                  })
                }}
              />
              {modulesData.length === modulesLimit && (
                <Button
                  fullWidth
                  onClick={() =>
                    setModulesLimit(
                      modulesLimit + moduleSettings.listviewLoadMore,
                    )
                  }
                >
                  {`Meer ${labels.track} laden`}
                </Button>
              )}
            </>
          ) : (
            <Box p={2}>
              <Typography variant='body1'>
                {`Geen ${labels.track} gevonden. Voeg een nieuwe toe.`}
              </Typography>
            </Box>
          )}
        </PaperStyled>
      </PageContainer>
    </>
  )
}

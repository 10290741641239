import { useState, forwardRef } from 'react'
import type { FC } from 'react'
import { Button, Box, Typography, Chip, styled } from '@mui/material'
import { Skeleton } from '@mui/material'
import { RouteTypes } from 'types/RouteTypes'
import { useParams } from 'react-router-dom'
import { IconButton, Modal, Switch } from '@fivano/core'
import { ServicesList } from './ServicesList'
import { ServiceDurationForm } from './ServiceDurationForm'
import { Controller } from 'react-hook-form'
import useMongo from 'hooks/useMongo'
import { sumKeys } from '@fivano/models'
import {
  DragDropContext,
  DropResult,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd'
import { addMinutes, format } from 'date-fns'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import ErrorIcon from '@mui/icons-material/Error'
import TimelapseIcon from '@mui/icons-material/Timelapse'

const removeService = (formObject, serviceID) => {
  const { setValue, getValues } = formObject
  setValue(
    'packageServices',
    getValues('packageServices').filter(id => id !== serviceID),
    { shouldDirty: true, shouldValidate: true },
  )
}

const addService = (formObject, serviceID) => {
  const { setValue, getValues } = formObject
  setValue('packageServices', getValues('packageServices').concat(serviceID), {
    shouldDirty: true,
    shouldValidate: true,
  })
}

const ServicePickerModal = ({ formObject, onClose }) => {
  const { watch } = formObject
  const { serviceID } = useParams<RouteTypes>()

  const chosenServiceIDs = [...watch('packageServices'), serviceID].filter(
    Boolean,
  )

  const filter = {
    packageServ: { $in: [null, false, undefined] },
  }

  const clientSideFilter = entity => {
    return !entity.packageServ && !chosenServiceIDs.includes(entity._id)
  }

  return (
    <Modal onClose={onClose} open={true}>
      <ServicesList
        filter={filter}
        clientSideFilter={clientSideFilter}
        onClick={(_, data) => {
          addService(formObject, data.original._id)
        }}
      />
    </Modal>
  )
}

type PackageServiceProps = {
  serviceID: String
  formObject: any
  isDragDisabled: boolean
  isDragged: boolean
}

const ListItem = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '& .actionButtons': {
    display: 'none',
  },
  '&:hover .actionButtons': {
    display: 'block',
  },
}))

const formatDur = minutes => {
  const date: Date = addMinutes(new Date(0), minutes)
  const timezoneDiff = date.getTimezoneOffset()
  const dateWithoutTimezoneDiff = addMinutes(date, timezoneDiff)
  return format(dateWithoutTimezoneDiff, 'HH:mm')
}

export const DurationInfo = ({ duration, blocked, overlap }) => {
  return (
    <Box display='flex' alignItems='flex-end'>
      <TimelapseIcon style={{ marginRight: '4px' }} fontSize='small' />
      <Typography variant='caption'>
        {duration && `Duur: ${formatDur(duration)}`}
        {blocked && `, Geblokkeerd: ${formatDur(blocked)}`}
        {overlap && `, Overlap: ${formatDur(overlap)}`}
      </Typography>
    </Box>
  )
}

const PackageService: FC<PackageServiceProps> = forwardRef(
  ({ serviceID, formObject, isDragDisabled, isDragged, ...rest }, ref) => {
    const [openEditService, setOpenEditService] = useState(false)
    const { useGetDoc } = useMongo()
    const docData = useGetDoc({
      collection: 'services',
      docID: serviceID,
    })

    if (!docData || docData._fetchStatus === 'loading') {
      return (
        <Box {...{ ref: ref }} {...rest} borderTop={1} padding={1}>
          <Skeleton height={40} />
        </Box>
      )
    }
    return (
      <>
        {openEditService && (
          <ServiceDurationForm
            serviceID={serviceID}
            onClose={() => setOpenEditService(false)}
          />
        )}
        <ListItem
          {...{ ref: ref }}
          {...rest}
          display='flex'
          alignItems='center'
          boxShadow={isDragged ? 3 : 0}
          borderTop={isDragged ? 0 : 1}
          padding={2}
          height={60}
        >
          {!isDragDisabled && <DragHandleIcon fontSize='small' />}
          <Box flexGrow={1} marginLeft={1}>
            <Typography>{docData?.name}</Typography>
            <DurationInfo
              duration={docData?.duration}
              overlap={docData?.overlapDuration}
              blocked={docData?.blockedDuration}
            />
          </Box>

          <Box className='actionButtons'>
            <IconButton
              style={{ marginRight: '4px' }}
              size='small'
              label='Tijden bewerken'
              onClick={() => setOpenEditService(true)}
            >
              <EditIcon fontSize='small' />
            </IconButton>
            <IconButton
              style={{ marginRight: '8px' }}
              size='small'
              label='Verwijderen'
              onClick={() => removeService(formObject, serviceID)}
            >
              <DeleteIcon fontSize='small' />
            </IconButton>
          </Box>

          {docData.package && (
            <Chip
              label='Pakket'
              variant='outlined'
              color='secondary'
              size='small'
              icon={<ErrorIcon />}
            />
          )}

          <Box>
            <Typography>€{docData?.price}</Typography>
          </Box>
        </ListItem>
      </>
    )
  },
)

const reorder = (list, startIndex, endIndex) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const PackageComposer = ({ formObject }) => {
  const { control, errors, watch, setValue } = formObject
  const [openPicker, setOpenPicker] = useState(false)
  const { useGetDocs } = useMongo()
  const chosenIDs = watch('packageServices')
  const chosenServices = useGetDocs({
    collection: 'services',
    docIDs: chosenIDs,
    disabled: chosenIDs.length === 0,
  })

  const handleDragEnd = async ({
    source,
    destination,
  }: DropResult): Promise<void> => {
    if (!destination || source.index === destination.index) {
      return
    }

    setValue(
      'packageServices',
      reorder(chosenIDs, source.index, destination.index),
      { shouldDirty: true },
    )
  }
  const isDragDisabled = watch('planningPackage')?.value !== 'specifiedOrder'

  return (
    <>
      {openPicker && (
        <ServicePickerModal
          formObject={formObject}
          onClose={() => setOpenPicker(false)}
        />
      )}
      <Controller
        control={control}
        defaultValue={[]}
        name='packageServices'
        rules={{ validate: value => value.length > 1 }}
        render={({ field: { value } }) => (
          <>
            <Box border={1} mb={1} borderRadius={1}>
              <Box display='flex'>
                <Box flexGrow={1} padding={1}>
                  <Typography variant='h6'>Diensten</Typography>
                </Box>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId='servicesList'>
                    {provided => (
                      <div ref={provided.innerRef}>
                        {value.map((serviceID, index) => (
                          <Draggable
                            isDragDisabled={isDragDisabled}
                            draggableId={serviceID}
                            index={index}
                            key={serviceID}
                          >
                            {(provided, snapshot) => (
                              <PackageService
                                isDragDisabled={isDragDisabled}
                                serviceID={serviceID}
                                formObject={formObject}
                                isDragged={snapshot.isDragging}
                                key={serviceID}
                                ref={provided.innerRef}
                                style={{ ...provided.draggableProps.style }}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              />
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <Box
                  display='flex'
                  alignItems='center'
                  borderTop={1}
                  padding={1}
                  paddingRight={2}
                >
                  <Box flexGrow={1}>
                    <Switch
                      label='Pakketprijs zelf instellen'
                      name='customPackagePrice'
                      formObject={formObject}
                    />
                  </Box>

                  <Box>
                    <Typography
                      align='right'
                      style={{
                        textDecoration: watch('customPackagePrice')
                          ? 'line-through'
                          : 'none',
                      }}
                    >
                      Totaal: €{' '}
                      {sumKeys(Object.values(chosenServices), 'price')}
                    </Typography>
                    <DurationInfo
                      duration={sumKeys(
                        Object.values(chosenServices),
                        'duration',
                      )}
                      blocked={sumKeys(
                        Object.values(chosenServices),
                        'blockedDuration',
                      )}
                      overlap={sumKeys(
                        Object.values(chosenServices),
                        'overlapDuration',
                      )}
                    />
                  </Box>
                </Box>
                <Button onClick={() => setOpenPicker(true)}>Toevoegen</Button>
              </Box>

              {/* {value.map(serviceID => (
              <PackageService
                key={serviceID}
                serviceID={serviceID}
                formObject={formObject}
              />
            ))} */}
            </Box>
            {errors.packageServices && (
              <Typography variant='body2' color='error'>
                Minimaal twee diensten
              </Typography>
            )}
          </>
        )}
      />
    </>
  )
}

import React, { useState } from 'react'
import { TagsProgressBoardForm } from './TagsProgressBoardForm'
import { SettingsPage } from 'app/legacy/SettingsBlock'

const breadcrumbArray = [
  { title: 'Instellingen', path: '/instellingen' },
  {
    title: 'CRM tags',
    path: '/instellingen/crm-tags',
  },
]

export const TagsProgressBoard = () => {
  const [tagModalProps, setTagModalProps] = useState({
    formID: null,
    open: false,
  })
  const firestoreCode = 'Tagpb'
  const firestoreSettingsPath = 'tagsProgressBoard'

  return (
    <>
      <TagsProgressBoardForm
        firestoreCode={firestoreCode}
        firestoreSettingsPath={firestoreSettingsPath}
        labelSingle='CRM-tag'
        formProps={tagModalProps}
        onClose={() =>
          setTagModalProps({
            formID: null,
            open: false,
          })
        }
      />
      <SettingsPage
        onAdd={() => setTagModalProps({ formID: null, open: true })}
        onEdit={(event, rowData) =>
          setTagModalProps({
            formID: rowData.original[`id${firestoreCode}`],
            open: true,
          })
        }
        labelMultiple='CRM-tags'
        labelSingle='CRM-tag'
        breadcrumbs={breadcrumbArray}
        firestoreSettingsPath={firestoreSettingsPath}
        firestoreCode={firestoreCode}
      />
    </>
  )
}

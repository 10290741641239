import React, { memo, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'hooks/useSelector'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'

import {
  Box,
  Grid,
  Paper,
  Typography,
  Link,
  Chip,
  Avatar,
  useMediaQuery,
  styled,
} from '@mui/material'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { WidgetHeader } from 'app/components/WidgetHeader'
import { PersonListItem } from 'app/components/ListItems/PersonListItem'
import {
  initialRelationPersonFormValues,
  relationPersonFormSettings,
} from './RelationPersonFormSettings'
import { LocationListItem } from 'app/components/ListItems/LocationListItem'
import { ListItemBase } from 'app/components/ListItems/ListItemBase'
import {
  ActionMenu,
  MenuButton,
  EmptyContent,
  HeaderInfoCard,
  IconButton,
  IconLink,
  Modal,
  PageContainer,
} from '@fivano/core'
import { PersonFormModal } from '../PersonForm/PersonFormModal'
import { PersonDetailModal } from 'app/components/DetailModals/PersonDetailModal'
import { LocationDetailModal } from 'app/components/DetailModals/LocationDetailModal'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import MailIcon from '@mui/icons-material/Mail'
import PhoneIcon from '@mui/icons-material/Phone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import PersonIcon from '@mui/icons-material/Person'
import BedrijfLocatie from 'app/icons/BedrijfLocatie'
import { useArchiveDoc } from 'hooks/useArchiveDoc'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { Archive, Edit, Visibility } from '@mui/icons-material'
import { RelationForm } from '../Relations/RelationForm'
import { LocationForm } from './LocationForm'
import { isNullish } from 'utils/isNullish'
import { Location } from '../../../types/Location'
import { Person } from 'types'
import { Relation } from 'types/Relation'

const WrapperGridStyled = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  backgroundColor: '#e0e0e0',
  width: 128,
  height: 128,
  marginRight: 16,
  fontSize: 48,
  [theme.breakpoints.down('lg')]: {
    width: 64,
    height: 64,
  },
  [theme.breakpoints.down('sm')]: {
    margin: '0 auto',
  },
}))

const BedrijfLocatieStyled = styled(BedrijfLocatie)(({ theme }) => ({
  fontSize: 48,
  [theme.breakpoints.down('lg')]: {
    fontSize: 24,
  },
}))

const modalLocationDefaultValues: Location = {
  idLoca: '',
  nameLoca: '',
  addressCityLoca: '',
  addressStreetLoca: '',
  addressNumberLoca: 0,
  addressZipLoca: '',
  id: '',
  phoneLoca: '',
  emailLoca: '',
  addressAdditionLoca: '',
  descriptionLoca: '',
  nameAddresseeLoca: '',
}

export const RelationDetails = memo(() => {
  const [relationLoaded, setRelationLoaded] = useState(false)
  const [relationName, setRelationName] = useState('Laden')
  const [showRelationPersonArchived, setShowRelationPersonArchived] =
    useState(false)
  const [showLocationArchived, setShowLocationArchived] = useState(false)
  const [relationModalProps, setRelationModalProps] = useState({
    open: false,
    locationID: '',
    relationID: undefined,
  })
  const [locationModalProps, setLocationModalProps] = useState({
    open: false,
    locationID: '',
  })

  const { relationID }: any = useParams()
  const firestore = useFirestore()
  const locationQuery = useLocationQuery()
  useFirestoreConnect([
    {
      collection: 'relations',
      doc: relationID,
      storeAs: `relations_${relationID}`,
    },
    {
      collectionGroup: 'persons',
      orderBy: ['lastNamePers', 'asc'],
      where: [
        ['archivedPers', '==', showRelationPersonArchived],
        ['personTypePers', '==', 'relationPerson'],
        ['relationIDPers', '==', relationID],
        ...locationQuery('locationIDPers'),
      ],
      limit: 100,
      storeAs: `relations_${relationID}_persons`,
    },
    {
      collection: 'locations',
      where: [
        ['relationLoca', '==', relationID],
        ['archivedLoca', '==', showLocationArchived],
      ],
      orderBy: ['nameLoca', 'asc'],
      limit: 100,
      storeAs: `locations_${relationID}`,
    },
    {
      collection: 'projectCodes',
      where: [['relationID', '==', relationID]],
      limit: 100,
      storeAs: `projectCodes_${relationID}`,
    },
  ])

  const locationDetails: Location[] = useSelector(
    ({ firestore: { ordered } }) => ordered[`locations_${relationID}`],
  )
  const relationPersons: Person[] = useSelector(
    ({ firestore: { ordered } }) => ordered[`relations_${relationID}_persons`],
  )
  const relationDetails: Relation = useSelector(
    ({ firestore: { ordered } }) => ordered[`relations_${relationID}`]?.[0],
  )

  useEffect(() => {
    if (relationDetails) {
      setRelationName(`${relationDetails?.nameRela}`)
      setRelationLoaded(true)
    }
  }, [relationDetails])

  const breadcrumbArray = [
    { title: 'Home', path: '/' },
    { title: 'Bedrijven', path: '/bedrijven' },
    {
      title: relationName,
      path: `/bedrijven/${relationID}`,
    },
  ]

  // location form and modal
  const [locationModalDefaultValues, setLocationModalDefaultValues] = useState(
    modalLocationDefaultValues,
  )
  const [locationDetailModalProps, setLocationDetailModalProps] = useState<{
    open: boolean
    locationData: Location
  }>({
    open: false,
    locationData: modalLocationDefaultValues,
  })

  // relationPerson details and form modal
  const [relationPersonForm, setRelationPersonForm] = useState(false)
  const [relationPersonDetails, setRelationPersonDetails] = useState(false)
  const [relationPersonValues, setRelationPersonValues] = useState(
    initialRelationPersonFormValues,
  )
  // detorderedopen/close
  const handleRelationPersonDetails = person => {
    setRelationPersonValues(person)
    setRelationPersonDetails(true)
  }
  const handleCloseRelationPersonDetail = () => {
    setRelationPersonValues(initialRelationPersonFormValues)
    setRelationPersonDetails(false)
  }

  // form data open/close
  const handleEditRelationPerson = person => {
    setRelationPersonValues(person)
    setRelationPersonForm(true)
  }

  const handleUpdateRelationPersonForm = (id, data) => {
    data.id = id
    setRelationPersonValues(data)
    setRelationPersonForm(false)
  }

  const contactButtonsHeader = [
    {
      label: 'Email openen',
      value: relationDetails?.mailEmailRela,
      href: 'mailto:',
      icon: <MailIcon />,
      newTab: false,
    },
    {
      label: 'Telefoon openen',
      value: relationDetails?.phoneRela,
      href: 'tel:',
      icon: <PhoneIcon />,
      newTab: false,
    },
    {
      label: 'Locatie openen',
      value: relationDetails?.addressZipRela
        ? `${
            relationDetails?.addressStreetRela
              ? relationDetails?.addressStreetRela
              : ''
          } ${
            relationDetails?.addressNumberRela
              ? relationDetails?.addressNumberRela
              : ''
          }${
            relationDetails?.addressAdditionRela
              ? relationDetails?.addressAdditionRela
              : ''
          }${
            relationDetails?.addressZipRela
              ? `,  ${relationDetails?.addressZipRela}`
              : ''
          } ${
            relationDetails?.addressCityRela
              ? relationDetails?.addressCityRela
              : ''
          }`
        : '',
      href: `http://maps.google.com/?q=`,
      icon: <LocationOnIcon />,
      newTab: true,
    },
  ]

  const expandibleHeaderLeftInfo = [
    { value: relationDetails?.kvkRela, label: 'KvK nummer' },
    { value: relationDetails?.vatNumberRela, label: 'BTW nummer' },
    { value: null },
    { value: relationDetails?.debtorNumberRela, label: 'Debit nummer' },
    { value: relationDetails?.creditorNumberRela, label: 'Credit nummer' },
  ]

  const expandibleHeaderRightInfo = [
    { value: relationDetails?.invoiceEmailRela, label: 'Factuur emailadres' },
    {
      value: relationDetails?.invoicePhoneRela,
      label: 'Factuur telefoonnummer',
    },
    { value: null },
    { value: relationDetails?.banknameRela, label: 'Bank naam' },
    { value: relationDetails?.bankBicCodeRela, label: 'BIC' },
    { value: relationDetails?.ibanNumberRela, label: 'IBAN nummer' },
  ]

  const expandibleHeaderFullWidthInfo = [
    { value: relationDetails?.textRela, label: '' },
  ]

  const archiveDoc = useArchiveDoc()
  // ===== ARCHIVE RELATION CONTACT ==== //
  const handleArchiveRelationContact = (person: Person) => {
    archiveDoc({
      docType: 'Deelnemer',
      path: `locations/${person.locationIDPers}/persons/${person.id}`,
      archived: !person?.archivedPers,
      extension: 'Pers',
    })
  }

  const handleArchiveLocation = (location: Location) => {
    archiveDoc({
      docType: 'Locatie',
      path: `locations/${location.id}`,
      archived: !location?.archivedLoca,
      extension: 'Loca',
    })
  }

  // ===== ARCHIVE RELATION ==== //
  const handleArchive = () => {
    archiveDoc({
      docType: 'Bedrijf',
      path: `relations/${relationID}`,
      archived: !relationDetails?.archivedRela,
      extension: 'Rela',
    }).then(() => {
      archiveLocations()
    })
  }

  const archiveLocations = async () => {
    const data: any[] = []

    await firestore
      .collection('locations')
      .where('relationLoca', '==', relationID)
      .get()
      .then(response => {
        response.docs.forEach(doc => {
          const docData = doc.data()
          docData.id = doc.id
          data.push(docData)
        })
      })
    data.forEach(location => {
      archiveDoc({
        docType: 'Locatie',
        path: `locations/${location.id}`,
        archived: !relationDetails?.archivedRela,
        extension: 'Loca',
        showSnackbar: false,
      })
    })
  }

  const matchesXsDown = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm'),
  )

  return (
    <PageContainer maxWidth='lg'>
      {relationLoaded ? (
        <>
          <Helmet>
            <title>{relationDetails.nameRela}</title>
            <meta name='description' content='Description of RelationDetails' />
          </Helmet>
          {relationModalProps.open && (
            <RelationForm
              relationID={relationModalProps.relationID}
              onCloseForm={() =>
                setRelationModalProps({
                  open: false,
                  locationID: '',
                  relationID: undefined,
                })
              }
            />
          )}
          {locationModalProps.open && (
            <LocationForm
              locationID={locationModalProps.locationID}
              relationID={relationID}
              onCloseForm={() =>
                setLocationModalProps({
                  open: false,
                  locationID: '',
                })
              }
            />
          )}
          <PersonFormModal
            title='Contact'
            open={relationPersonForm}
            onClose={() => setRelationPersonForm(false)}
            formSettings={relationPersonFormSettings}
            defaultValues={relationPersonValues}
            extraData={{ relationIDPers: relationID }}
            personTypePers='relationPerson'
            onAddSuccess={() => setRelationPersonForm(false)}
            onUpdateSuccess={handleUpdateRelationPersonForm}
          />
          <Modal
            maxWidth='sm'
            onClose={handleCloseRelationPersonDetail}
            open={relationPersonDetails}
          >
            <PersonDetailModal
              personData={relationPersonValues}
              onClose={handleCloseRelationPersonDetail}
              closeButton
              onEdit={handleEditRelationPerson}
            />
          </Modal>
          <Modal
            maxWidth='sm'
            onClose={() =>
              setLocationDetailModalProps({
                open: false,
                locationData: modalLocationDefaultValues,
              })
            }
            open={locationDetailModalProps.open}
          >
            <LocationDetailModal
              locationData={locationDetailModalProps.locationData}
              onClose={() =>
                setLocationDetailModalProps({
                  open: false,
                  locationData: modalLocationDefaultValues,
                })
              }
              closeButton
              onEdit={locationID =>
                locationModalDefaultValues.relaLocationTypeLoca === 'visitLoca'
                  ? setRelationModalProps({
                      open: true,
                      locationID: locationID,
                      relationID: relationID,
                    })
                  : setLocationModalProps({
                      open: true,
                      locationID: locationID,
                    })
              }
            />
          </Modal>
          <Breadcrumbs breadcrumbs={breadcrumbArray} />
          <HeaderInfoCard
            actionIconHeaderInfo={
              <ActionMenu iconPosition='vertical' minWidth={250}>
                <MenuButton
                  label='Bewerk bedrijf'
                  icon={<EditIcon />}
                  onClick={() =>
                    setRelationModalProps({
                      open: true,
                      locationID: '',
                      relationID: relationID,
                    })
                  }
                />
                <MenuButton
                  label={
                    relationDetails?.archivedRela
                      ? 'Bedrijf dearchiveren'
                      : 'Bedrijf archiveren'
                  }
                  icon={<ArchiveIcon />}
                  onClick={handleArchive}
                />
              </ActionMenu>
            }
            leftSideHeaderInfo={
              <Grid container>
                <WrapperGridStyled item>
                  <AvatarStyled>
                    <BedrijfLocatieStyled />
                  </AvatarStyled>
                </WrapperGridStyled>
                <WrapperGridStyled item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography variant='h5'>
                        {relationDetails.nameRela}
                      </Typography>
                      {relationDetails?.archivedRela && (
                        <Chip label='Gearchiveerd' />
                      )}
                    </Grid>
                    {relationDetails.websiteRela && (
                      <Grid item>
                        <Link
                          variant='subtitle1'
                          href={`http://${relationDetails.websiteRela}`}
                          color='inherit'
                          rel='noreferrer'
                          target='_blank'
                        >
                          {relationDetails.websiteRela}
                        </Link>
                      </Grid>
                    )}
                  </Grid>
                </WrapperGridStyled>
              </Grid>
            }
            rightSideHeaderInfo={
              <>
                {contactButtonsHeader.map((item, index) => {
                  return (
                    <Grid item key={index}>
                      {item.value !== '' && (
                        <IconLink
                          tooltip={item.label}
                          label={item.value}
                          href={`${item.href}${item.value}`}
                          icon={item.icon}
                          color='inherit'
                          newTab={item.newTab}
                        />
                      )}
                    </Grid>
                  )
                })}
              </>
            }
            expandibleHeaderLeftInfo={
              <>
                {expandibleHeaderLeftInfo.map((item, index) => {
                  if (item.value === null) {
                    return <Box p={1} key={index} />
                  } else if (isNullish(item.value)) {
                    return null
                  } else {
                    return (
                      <Grid item key={index}>
                        <Typography
                          variant='caption'
                          color='textSecondary'
                          display='block'
                        >
                          {item.label}
                        </Typography>
                        <Typography variant='body1'>{item.value}</Typography>
                      </Grid>
                    )
                  }
                })}
              </>
            }
            expandibleHeaderRightInfo={
              <>
                {expandibleHeaderRightInfo.map((item, index) => {
                  if (item.value === null) {
                    return <Box p={1} key={index} />
                  } else if (item.value === '') {
                    return null
                  } else {
                    return (
                      <Grid item key={index}>
                        <Typography
                          variant='caption'
                          color='textSecondary'
                          display='block'
                        >
                          {item.label}
                        </Typography>
                        <Typography variant='body1'>{item.value}</Typography>
                      </Grid>
                    )
                  }
                })}
              </>
            }
            expandibleHeaderFullWidthInfo={
              <>
                {expandibleHeaderFullWidthInfo.map((item, index) => {
                  if (item.value === null) {
                    return <Box p={1} key={index} />
                  } else if (item.value === '') {
                    return null
                  } else {
                    return (
                      <Grid item key={index}>
                        <Typography
                          variant='caption'
                          color='textSecondary'
                          display='block'
                        >
                          {item.label}
                        </Typography>
                        <Typography variant='body1'>{item.value}</Typography>
                      </Grid>
                    )
                  }
                })}
              </>
            }
          />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <WidgetHeader
                title='Bedrijfcontacten'
                buttonLabel='Toevoegen'
                icon={PersonIcon}
                onButtonClick={() => {
                  setRelationPersonForm(true)
                }}
                actions={
                  <ActionMenu>
                    <MenuButton
                      label={
                        showRelationPersonArchived
                          ? 'Toon niet gearchiveerd'
                          : 'Toon gearchiveerd'
                      }
                      icon={<ArchiveIcon />}
                      onClick={() =>
                        setShowRelationPersonArchived(
                          !showRelationPersonArchived,
                        )
                      }
                    />
                  </ActionMenu>
                }
              />
              <Paper>
                {relationPersons && relationPersons.length > 0 ? (
                  <Box pt={1} pb={1}>
                    {relationPersons.map(person => {
                      return (
                        <ListItemBase
                          key={person.id}
                          chevronIcon
                          onClick={() => handleRelationPersonDetails(person)}
                          listItemActions={
                            <>
                              {!matchesXsDown && (
                                <>
                                  {person.phoneLinePers && (
                                    <IconButton
                                      label='Telefoon'
                                      onClick={() => {
                                        window.open(
                                          `tel:${person.phoneLinePers}`,
                                          '_self',
                                        )
                                      }}
                                      size='large'
                                    >
                                      <PhoneEnabledIcon />
                                    </IconButton>
                                  )}
                                  {person.emailPers && (
                                    <IconButton
                                      label='Email'
                                      onClick={() => {
                                        window.open(
                                          `mailto:${person.emailPers}`,
                                          '_self',
                                        )
                                      }}
                                      size='large'
                                    >
                                      <MailIcon />
                                    </IconButton>
                                  )}
                                  {
                                    <ActionMenu
                                      iconPosition='vertical'
                                      minWidth={250}
                                    >
                                      <MenuButton
                                        label='Contact details'
                                        icon={<Visibility />}
                                        onClick={() =>
                                          handleRelationPersonDetails(person)
                                        }
                                      />
                                      <MenuButton
                                        label='Bewerk contact'
                                        icon={<Edit />}
                                        onClick={() =>
                                          handleEditRelationPerson(person)
                                        }
                                      />
                                      <MenuButton
                                        label={
                                          person?.archivedPers
                                            ? 'Contact tonen'
                                            : 'Contact archiveren'
                                        }
                                        icon={<Archive />}
                                        onClick={() =>
                                          handleArchiveRelationContact(person)
                                        }
                                      />
                                    </ActionMenu>
                                  }
                                </>
                              )}
                            </>
                          }
                        >
                          <PersonListItem
                            key={person.id}
                            personData={person}
                            archivedLabel={
                              <>
                                {person.archivedPers && (
                                  <Chip label='Gearchiveerd' size='small' />
                                )}
                              </>
                            }
                          />
                        </ListItemBase>
                      )
                    })}
                  </Box>
                ) : (
                  <EmptyContent
                    text={
                      showRelationPersonArchived
                        ? 'Er zijn geen gearchiveerde bedrijfscontacten gevonden'
                        : 'Er zijn geen bedrijfscontacten gevonden'
                    }
                  ></EmptyContent>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <WidgetHeader
                title='Locaties'
                buttonLabel='Toevoegen'
                icon={BedrijfLocatie}
                onButtonClick={() =>
                  setLocationModalProps({ open: true, locationID: '' })
                }
                actions={
                  <ActionMenu idIconButton='locationMenu'>
                    <MenuButton
                      label={
                        showLocationArchived
                          ? 'Toon niet gearchiveerd'
                          : 'Toon gearchiveerd'
                      }
                      icon={<ArchiveIcon />}
                      onClick={() =>
                        setShowLocationArchived(!showLocationArchived)
                      }
                    />
                  </ActionMenu>
                }
              />
              <Paper>
                {locationDetails && locationDetails.length > 0 ? (
                  <Box pt={1} pb={1}>
                    {locationDetails.map(location => {
                      return (
                        <ListItemBase
                          key={location.id}
                          chevronIcon
                          onClick={() => {
                            setLocationDetailModalProps({
                              open: true,
                              locationData: location,
                            })
                            setLocationModalDefaultValues(location)
                          }}
                          listItemActions={
                            <>
                              {location.phoneLoca && (
                                <IconButton
                                  label='Telefoon'
                                  onClick={() => {
                                    window.open(
                                      `tel:${location.phoneLoca}`,
                                      '_self',
                                    )
                                  }}
                                  size='large'
                                >
                                  <PhoneEnabledIcon />
                                </IconButton>
                              )}
                              {location.emailLoca && (
                                <IconButton
                                  label='Email'
                                  onClick={() => {
                                    window.open(
                                      `mailto:${location?.emailLoca}`,
                                      '_self',
                                    )
                                  }}
                                  size='large'
                                >
                                  <MailIcon />
                                </IconButton>
                              )}
                              {
                                <ActionMenu
                                  iconPosition='vertical'
                                  minWidth={250}
                                >
                                  <MenuButton
                                    label='Locatie details'
                                    icon={<Visibility />}
                                    onClick={() => {
                                      setLocationDetailModalProps({
                                        open: true,
                                        locationData: location,
                                      })
                                    }}
                                  />
                                  <MenuButton
                                    label='Bewerk locatie'
                                    icon={<Edit />}
                                    onClick={() =>
                                      location.relaLocationTypeLoca ===
                                      'visitLoca'
                                        ? setRelationModalProps({
                                            open: true,
                                            locationID: location.id,
                                            relationID: relationID,
                                          })
                                        : setLocationModalProps({
                                            open: true,
                                            locationID: location.id,
                                          })
                                    }
                                  />
                                  <MenuButton
                                    label={
                                      location?.archivedLoca
                                        ? 'Locatie tonen'
                                        : 'Locatie archiveren'
                                    }
                                    icon={<Archive />}
                                    onClick={() =>
                                      handleArchiveLocation(location)
                                    }
                                  />
                                </ActionMenu>
                              }
                            </>
                          }
                        >
                          <LocationListItem
                            locationData={location}
                            onEdit={id =>
                              setLocationModalProps({
                                open: true,
                                locationID: id,
                              })
                            }
                            relaLocationTypeLoca={location.relaLocationTypeLoca}
                            locationChip={
                              location.relaLocationTypeLoca === 'invoiceLoca' ||
                              location.relaLocationTypeLoca === 'mailLoca' ||
                              location.relaLocationTypeLoca === 'visitLoca'
                                ? true
                                : false
                            }
                            archivedLabel={
                              location.archivedLoca && (
                                <Chip label='Gearchiveerd' size='small' />
                              )
                            }
                          />
                        </ListItemBase>
                      )
                    })}
                  </Box>
                ) : (
                  <EmptyContent
                    text={
                      showLocationArchived
                        ? 'Er zijn geen gearchiveerde locaties gevonden'
                        : 'Er zijn nog geen locaties gevonden'
                    }
                  ></EmptyContent>
                )}
              </Paper>
            </Grid>
          </Grid>
        </>
      ) : (
        'Loading'
      )}
    </PageContainer>
  )
})

import React, { useEffect, useState } from 'react'
import { isLoaded, useFirebase, useFirestore } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import {
  AvatarPicker,
  bytesConvert,
  Checkbox,
  FormPaper,
  FormRow,
  TextField,
} from '@fivano/core'
import { RouteTypes } from 'types/RouteTypes'
import { createImageURL } from './MusicTrackFormInputs'
import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'
import { useUpload } from 'hooks/useUpload'
import { Form, LoadingOverlay, Modal } from '@fivano/core'
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material'
import { useSelector } from 'hooks/useSelector'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { removeUndefined } from './MusicTrackForm'
import { Subject, Title, Image, Public } from '@mui/icons-material'
import { SlugInput } from './SlugInput'
import { useDataStandards } from 'hooks/useDataStandards'
import { useConfirmModal } from '@fivano/core'
import { useFilesDelete, useSnackbar } from '@fivano/core'

export const PlayListForm = () => {
  const history = useHistory()
  const { playlistID } = useParams<RouteTypes>()
  const isEditing = !!playlistID
  const firestore = useFirestore()
  const firebase = useFirebase()
  const { upload, status } = useUpload()

  useEffect(() => {
    firestore.get({
      doc: `playlists/${playlistID}`,
      storeAs: `playlist_${playlistID}`,
    })
  }, [firestore, playlistID])

  const docData: any[] = useSelector(
    state => state.firestore.ordered[`playlist_${playlistID}`],
  )

  const [loadingMessage, setLoadingMessage] = useState<string>('')
  const createDataStandards = useDataStandards()
  const buildTrackData = async data => {
    const dataStandards = createDataStandards({
      data,
      dataName: '',
      editForm: isEditing,
    })
    // create idPlaylist if there is no idPlaylist defined
    data.idPlaylist = isEditing ? playlistID : firestoreIDGenerator()
    console.log(data)
    setLoadingMessage('Playlist opslaan...')
    const fileUploads = [
      {
        id: 'playlistImage',
        label: 'Playlist afbeelding',
        uploadPath: 'playlists',
      },
    ].map(async format => {
      const name = format.id
      // Check if file exists
      if (data[name]) {
        const file = data[name]
        // If file is already uploaded return from loop
        if (file[0]?.bucket) return
        // Check files to delete and delete them
        if (
          file[0] &&
          docData[0]?.[name] &&
          JSON.stringify(file[0]) !== JSON.stringify(docData[0]?.[name])
        ) {
          setLoadingMessage(`Verwijder ${format.label}`)
          const fileRef = firebase
            .storage()
            .ref(
              `/tracks/${data._id}/${format.id}/${docData[0]?.[name]?.[0].name}`,
            )
          await fileRef.delete().then(console.info).catch(console.warn)
        }
        // If file is not uploaded start uploading file
        setLoadingMessage(`Upload ${format.label}`)
        await upload({
          file: file[0],
          path: format.uploadPath || `/tracks/${data._id}/${format.id}/`,
          filename: file[0]?.name,
        }).then((response: any) => {
          data[name] = [removeUndefined(response.metadata)]
        })
      } else {
        data[name] = []
      }
    })
    await Promise.all(fileUploads)
    return { ...data, ...dataStandards }
  }

  const createTrack = async data => {
    const product = await data
    await firestore.set(`playlists/${product.idPlaylist}`, product)
    setLoadingMessage('')
  }

  const updateTrack = async data => {
    const product = await data
    await firestore.update(`playlists/${product.idPlaylist}`, product)
    setLoadingMessage('')
  }

  const errorLogger = useErrorLogger()

  const confirmModal = useConfirmModal()

  const { deletePlaylist } = usePlaylistDelete()
  return (
    <>
      <Modal maxWidth='xs' open={loadingMessage !== ''}>
        <Box width='100%' height='400px'>
          <LoadingOverlay label={loadingMessage}>
            {status && (
              <Box width='100%'>
                <Typography>
                  {bytesConvert(status.bytesTransferred)} /
                  {bytesConvert(status.totalBytes)}
                </Typography>
                <LinearProgress
                  variant='determinate'
                  value={Math.round(
                    (status.bytesTransferred / status.totalBytes) * 100,
                  )}
                />
              </Box>
            )}
          </LoadingOverlay>
        </Box>
      </Modal>
      {isLoaded(docData) && (
        <Form
          onCloseForm={() => history.push('/playlists')}
          wrapInside='page'
          docLabel='Playlist'
          hasDetails={false}
          onCreateSuccess={() => history.push('/playlists')}
          onUpdateSuccess={() => history.push('/playlists')}
          buildDocData={buildTrackData}
          updateDoc={updateTrack}
          createDoc={createTrack}
          editingDocID={playlistID}
          docData={
            isEditing
              ? docData?.[0]
              : {
                  public: true,
                }
          }
          onError={errorLogger}
          formInputs={({ formObject }) => {
            return (
              <Grid container direction='column' spacing={2}>
                {/** GENERAL PRODUCT FIELDS */}
                <FormPaper>
                  <FormRow helperText='' icon={<Title />}>
                    <TextField
                      name='namePlaylist'
                      label='Naam'
                      formObject={formObject}
                      rules={{ required: 'Voer een naam in' }}
                    />
                    <SlugInput
                      formObject={formObject}
                      name='slug'
                      sourceSlug='namePlaylist'
                      rootSlug='https://songstarter.com/playlist'
                      rules={{
                        validate: {
                          duplicate: async value =>
                            isEditing ||
                            (await firestore
                              .get({
                                collection: 'playlists',
                                where: ['slug', '==', value],
                              })
                              .then(
                                (response: any) => response.docs.length === 0,
                              )
                              .catch(() => false)) ||
                            'Er bestaat al een playlist onder deze link, kies een andere link',
                        },
                      }}
                    />
                  </FormRow>

                  <FormRow helperText='' icon={<Subject />}>
                    <TextField
                      name='description'
                      label='Omschrijving'
                      rows={4}
                      formObject={formObject}
                      maxRows={20}
                      multiline
                      rules={{ required: true }}
                    />
                  </FormRow>

                  <FormRow helperText='' icon=''>
                    <Typography>Spaans</Typography>
                  </FormRow>

                  <FormRow helperText='' icon={<Title />}>
                    <TextField
                      name='namePlaylistES'
                      formObject={formObject}
                      label='Naam (Spaans)'
                    />
                  </FormRow>

                  <FormRow helperText='' icon={<Subject />}>
                    <TextField
                      label='Omschrijving (Spaans)'
                      name='descriptionES'
                      formObject={formObject}
                      rows={4}
                      maxRows={20}
                      multiline
                    />
                  </FormRow>
                </FormPaper>

                <FormPaper>
                  <FormRow helperText='' icon={<Public />}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Checkbox
                          name='public'
                          label='Playlist openbaar'
                          formObject={formObject}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Checkbox
                          name='isGenre'
                          label='Als Genre instellen'
                          formObject={formObject}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name='ranking'
                          label='Ranking'
                          rules={{ required: 'Verplicht veld' }}
                          formObject={formObject}
                        />
                      </Grid>
                    </Grid>
                  </FormRow>
                </FormPaper>

                {/** IMAGE PICKER */}
                <FormPaper>
                  <FormRow helperText='' icon={<Image />}>
                    <AvatarPicker
                      name='playlistImage'
                      title='Afbeelding instellen'
                      imageURL={createImageURL(
                        formObject.control.defaultValuesRef.current[
                          'playlistImage'
                        ]?.[0],
                      )}
                      control={formObject.control}
                      size={640}
                      previewSize={320}
                      avatarBorderRadius={0}
                      returnFile
                    />
                  </FormRow>
                </FormPaper>

                {/** DELETE TRACKS */}
                {isEditing && (
                  <FormPaper>
                    <FormRow helperText='' icon=''>
                      <Button
                        color='error'
                        variant='contained'
                        onClick={() =>
                          confirmModal({
                            onConfirm: () => deletePlaylist(playlistID),
                            cancelLabel: 'Annuleren',
                            confirmLabel: 'Verwijderen',
                            title: 'Playlist verwijderen?',
                            message:
                              'De playlist en bijbehorende bestanden worden definitief verwijdert',
                          })
                        }
                      >
                        Playlist verwijderen
                      </Button>
                    </FormRow>
                  </FormPaper>
                )}
              </Grid>
            )
          }}
        />
      )}
    </>
  )
}

const usePlaylistDelete = () => {
  const firestore = useFirestore()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const errorLogger = useErrorLogger()
  const deleteFiles = useFilesDelete({ onError: errorLogger })

  const deletePlaylist = async (playlistID: string) => {
    const playlistData: any = await firestore
      .collection('playlists')
      .doc(playlistID)
      .get()
      .then((response: any) => response.data())

    if (playlistData?.playlistImage[0]?.bucket) {
      await deleteFiles({
        filesToDelete: [playlistData.playlistImage[0]],
        deleteFirestoreDoc: false,
      })
        .then(async () => {
          await firestore.delete(`playlists/${playlistID}`)
        })
        .catch(errorLogger)
    } else {
      await firestore.delete(`playlists/${playlistID}`)
    }

    enqueueSnackbar('Playlist en bestanden verwijdert', {
      variant: 'success',
    })

    history.push('/playlists')
  }
  return { deletePlaylist }
}

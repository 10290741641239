import React from 'react'
import { PageContainer } from '@fivano/core'
import { Box, Button, Paper, Typography } from '@mui/material'
import { ProductCategoriesList } from './ProductCategoriesList'
import { ProductCategoryForm } from './ProductCategoryForm'
import { useModalRoute } from 'hooks/useModalRoutes'

export const ProductCategoriesPage = () => {
  const { modalData, closeModal, openModal } = useModalRoute()

  return (
    <PageContainer maxWidth='lg'>
      {modalData.open && (
        <ProductCategoryForm
          onCloseForm={() => closeModal()}
          editingDocID={modalData.id}
        />
      )}

      <Paper>
        <Box p={1}>
          <Typography variant='h4'>Categorieen</Typography>
        </Box>
        <Button variant='contained' onClick={() => openModal()}>
          Toevoegen
        </Button>
        <ProductCategoriesList onClickEdit={id => openModal(id)} />
      </Paper>
    </PageContainer>
  )
}

import React, { useState } from 'react'

import { DocumentCategoryForm } from './DocumentCategoryForm'
import { SettingsPage } from 'app/legacy/SettingsBlock'

const breadcrumbArray = [
  { title: 'Instellingen', path: '/instellingen' },
  { title: 'Documenten', path: '/instellingen/documenten' },
]
export const DocumentCategory = () => {
  const [documentCategoryFormProps, setDocumentCategoryFormProps] = useState({
    formID: null,
    open: false,
  })
  const firestoreCode = 'Doca'
  const firestoreSettingsPath = 'documentCategory'

  return (
    <>
      <DocumentCategoryForm
        firestoreCode={firestoreCode}
        firestoreSettingsPath={firestoreSettingsPath}
        labelSingle='Document categorie'
        formProps={documentCategoryFormProps}
        onClose={() =>
          setDocumentCategoryFormProps({
            formID: null,
            open: false,
          })
        }
      />
      <SettingsPage
        onAdd={() => setDocumentCategoryFormProps({ formID: null, open: true })}
        onEdit={(event, rowData) =>
          setDocumentCategoryFormProps({
            formID: rowData.original[`id${firestoreCode}`],
            open: true,
          })
        }
        labelMultiple='document categorieën'
        labelSingle='document categorie'
        breadcrumbs={breadcrumbArray}
        firestoreSettingsPath={firestoreSettingsPath}
        firestoreCode={firestoreCode}
      />
    </>
  )
}

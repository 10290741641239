/** Function to construct route from routeLink and data routeLink:
 *'/locaties/{locationIDPers}/deelnemers/{id}' and routeData:
 *{ locationIDPers: 'abc', id: '123' } returns string '/locaties/abc/deelnemers/123' */
export const routeConstructor = ({
  routeLink,
  routeData,
}: {
  routeLink: string
  routeData: any
}) => {
  let route = `/locaties/{locationIDPers}/deelnemers/{id}`
  const routeVariables = (route.match(/\{.+?\}/g) || []).map(str =>
    str.slice(1, -1),
  )
  routeVariables.forEach(routeVariable => {
    routeLink = routeLink.replace(
      `{${routeVariable}}`,
      routeData[routeVariable],
    )
  })
  return routeLink
}

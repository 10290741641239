import React, { SyntheticEvent } from 'react'
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form'
import {
  TextField,
  BaseTextFieldProps,
  InputProps,
  InputBaseProps,
} from '@mui/material'
import { timeStringToSeconds } from 'utils/timeToSeconds'
import { addSeconds, format, isValid } from 'date-fns'
import { ErrorMessage } from '@hookform/error-message'

type TimepickerProps = {
  /** Name for React Hook Form */
  name: string
  /** Will add the value of the input to this date */
  currentDate?: Date
  /** Label of Timepicker */
  label?: string
  /** React Hook Form object to connect component to form */
  formObject: UseFormReturn<any>
  /** Function that triggers on changes, returns a date or 'invalid date' string => (Date) */
  onChange?: (date: Date | null | 'Invalid Date', event: SyntheticEvent) => void
  /** Function that triggers on changes, returns a date or 'invalid date' string => (Date) */
  onBlur?: (date: Date | null | 'Invalid Date', event: SyntheticEvent) => void
  /** If true, the picker and text field are disabled. */
  disabled?: boolean
  /** Step over minutes. */
  minutesStep?: number
  /** Callback fired when the popup requests to be closed. */
  onClose?: () => void
  /** Callback fired when error occurs. */
  onError?: () => void
  /**Rules that the component needs to abide to */
  rules?: RegisterOptions
  /** The default value for the component */
  defaultValue?: number | Date
  /** If true, the input will take up the full width of its container. */
  fullWidth?: boolean
  /** Attributes applied to the input element. */
  inputProps?: InputBaseProps['inputProps']
  /** Props applied to the Input element.  */
  InputProps?: InputProps
  /** Override or extend the styles applied to the component. */
  classesProp?: any
  /** Possible layout variants of the TexField*/
  variant?: BaseTextFieldProps['variant']
  /** Removes error helper text, there will be no margin under the input */
  disableHelperText?: boolean
  /** Pass a ref to the input element. */
  inputRef?: React.Ref<any>
  /** The size of the component. */
  size?: BaseTextFieldProps['size']
  /** Classname add to the element */
  className?: string
}

const handler = (event, currentDate, customFunction, rhfFunction) => {
  currentDate = new Date(new Date(currentDate).setHours(0, 0, 0, 0))
  const newValue = addSeconds(
    currentDate,
    timeStringToSeconds(event.target.value),
  )

  if (event.target.value) {
    if (!isNaN(newValue.getTime())) {
      rhfFunction(newValue)
      customFunction && customFunction(newValue, event)
    } else {
      rhfFunction(event.target.value)
      customFunction && customFunction('Invalid Date', event)
    }
  } else {
    rhfFunction(event.target.value)
    customFunction && customFunction('Invalid Date', event)
  }
}

const convertToDate = date => {
  if (isValid(new Date(date))) {
    return new Date(date)
  } else if (isValid(date)) {
    if (typeof date !== 'string') {
      return date
    }
  } else if (typeof date === 'object') {
    if (date.seconds) {
      return new Date(date.seconds)
    } else {
      return date.toDate()
    }
  }
}

export const Timepicker = ({
  name,
  currentDate = new Date(),
  label,
  formObject,
  onChange,
  onBlur,
  disabled,
  minutesStep,
  onClose,
  onError,
  rules,
  fullWidth = false,
  classesProp,
  inputProps,
  InputProps,
  variant,
  disableHelperText = false,
  inputRef,
  size = 'small',
  className,
  ...props
}: TimepickerProps) => {
  const {
    control,
    formState: { errors },
  } = formObject

  currentDate = new Date(convertToDate(currentDate).setHours(0, 0, 0, 0))

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { value, onChange: onChangeRHF, onBlur: onBlurRHF },
      }) => {
        return (
          <TextField
            name={name}
            fullWidth={fullWidth}
            variant={variant || 'standard'}
            value={
              typeof value !== 'string'
                ? isValid(value)
                  ? format(value, 'HH:mm')
                  : value
                : value
            }
            {...(label && { label: label })}
            type='time'
            size={size}
            className={className}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ...inputProps,
              step: 60,
            }}
            InputProps={InputProps}
            onChange={event => {
              handler(event, currentDate, onChange, onChangeRHF)
            }}
            onBlur={event => {
              handler(event, currentDate, onBlur, onBlurRHF)
            }}
            helperText={<ErrorMessage name={name} errors={errors} />}
            error={!!errors[name]}
            FormHelperTextProps={{ classes: { root: 'rootHelperText' } }}
            classes={classesProp}
            inputRef={inputRef}
            {...props}
          />
        )
      }}
    />
  )
}

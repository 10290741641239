import React from 'react'
import { FormControlLabel, styled, Switch as MSwitch } from '@mui/material'

interface DarkModeSwitchProps {
  value: boolean
  onChange: any
  label?: string
  disabled?: boolean
}

const SwitchStyled = styled(MSwitch)(({ theme }) => ({
  padding: 7,
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 18,
      height: 18,
      margin: 1,
    },
  },
}))

export function DarkModeSwitch({
  value = false,
  onChange,
  disabled,
  label,
}: DarkModeSwitchProps) {
  return (
    <FormControlLabel
      value={value}
      disabled={disabled}
      onChange={onChange}
      labelPlacement='start'
      label={label}
      control={<SwitchStyled checked={value} color='primary' />}
    />
  )
}

import React, { useMemo } from 'react'
import { Grid, Typography, Hidden } from '@mui/material'
import { useTable } from 'react-table'
import {
  ActionGridStyled,
  ButtonBaseStyled,
  ExpandableGridStyled,
  FoldingHeaderGridStyled,
  RowWrapperDivStyled,
} from './ListviewStyles'

import { AccordionBase, ExpandButton } from '@fivano/core'
import { format } from 'date-fns'

interface ListviewProps {
  /**Data that comes into the ListView*/
  data: any
  /**Array of element objects which depict the layout of the ListView*/
  columns: any
  /**Data for the expanded state*/
  expandedRows: any
  /**Function that handles the expanding of the component*/
  handleExpanded: any
}

export function FoldingListview({
  data,
  columns,
  expandedRows,
  handleExpanded,
}: ListviewProps) {
  const listviewData = useMemo(() => data, [data])
  const listviewColums = useMemo(() => columns, [columns])
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: listviewColums,
      data: listviewData,
    })

  return (
    <Grid container {...getTableProps()}>
      {/* Hier zit de header met alle column titels */}
      <FoldingHeaderGridStyled container>
        {headerGroups.map(headerGroup => (
          <Grid container {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <Hidden key={column.id} {...column.hidden}>
                <Grid
                  item
                  xs={6}
                  {...column.getHeaderProps()}
                  {...column.gridProps}
                >
                  <Typography variant='body2'>
                    {column.render('Header')}
                  </Typography>
                </Grid>
              </Hidden>
            ))}
          </Grid>
        ))}
      </FoldingHeaderGridStyled>

      {rows.map((row: any, i) => {
        prepareRow(row)
        return (
          <AccordionBase
            key={row.original.id}
            expanded={expandedRows.includes(row.original.id)}
            visibleContent={
              <Grid container {...getTableBodyProps()}>
                <RowWrapperDivStyled actionButtons {...row.getRowProps()}>
                  <ButtonBaseStyled
                    component='div'
                    onClick={() => handleExpanded(row.original.id)}
                  >
                    <Grid item container alignItems='center'>
                      <ExpandButton
                        label={
                          expandedRows.includes(row.original.id)
                            ? 'Inklappen'
                            : 'Uitklappen'
                        }
                        rotate={expandedRows.includes(row.original.id)}
                      />
                      {row.cells.map(cell => {
                        return (
                          <Hidden key={cell.column.id} {...cell.column.hidden}>
                            <Grid
                              item
                              xs={3}
                              {...cell.getCellProps()}
                              {...cell.column.gridProps}
                            >
                              {cell.column.id !== 'actions' &&
                                (cell.render('Cell').type.name ===
                                'defaultRenderer' ? (
                                  <Typography variant='body1' noWrap>
                                    {cell.render('Cell')}
                                  </Typography>
                                ) : (
                                  cell.render('Cell')
                                ))}
                            </Grid>
                          </Hidden>
                        )
                      })}
                    </Grid>
                  </ButtonBaseStyled>
                  <ActionGridStyled container>
                    {row.cells.map(cell => {
                      return (
                        <Hidden key={cell.column.id} {...cell.column.hidden}>
                          <React.Fragment>
                            {cell.column.id === 'actions' &&
                              cell.render('Cell')}
                          </React.Fragment>
                        </Hidden>
                      )
                    })}
                  </ActionGridStyled>
                </RowWrapperDivStyled>
              </Grid>
            }
            // HIER LIGT DE FOCUS VOOR NU

            expandableContent={
              <ExpandableGridStyled container>
                {row.original.subItems.map(subItem => {
                  return (
                    <Grid container key={subItem.date}>
                      <Grid item xs={5} />
                      <Grid item xs={3}>
                        {format(subItem.date, 'dd/MM/yyyy')}
                      </Grid>
                      <Grid item xs={3}>
                        {subItem.amount}
                      </Grid>
                    </Grid>
                  )
                })}
              </ExpandableGridStyled>
            }
          />
        )
      })}
    </Grid>
  )
}

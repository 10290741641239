import React from 'react'
import { Box, styled } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

const BoxStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: `${theme.spacing(6)} ${theme.spacing(3)}`,
  backgroundColor: theme.palette.background.paper,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  top: 0,
  left: 0,
  zIndex: theme.zIndex.modal + 10, // TRACKZINDEX
}))
interface LoadingOverlayProps {
  /**Visible label*/
  label: string
  /**Custom inline styling*/
  customStyle?: React.CSSProperties
  /**Children elements of the LoadingOverlay*/
  children?: Array<JSX.Element>
  /**Content that is visible on the top of the LoadingOverlay*/
  topContent?: any
}

export function LoadingOverlay({
  label,
  customStyle,
  children,
  topContent,
}: LoadingOverlayProps) {
  return (
    <BoxStyled style={customStyle}>
      {topContent}
      <CircularProgress />
      <Box p={2} />
      <Typography variant='h6' align='center'>
        {label}
      </Typography>
      {children}
    </BoxStyled>
  )
}

import React, { useState } from 'react'
import {
  Popover,
  Grid,
  Typography,
  Button,
  ButtonBase,
  Divider,
  ListItem,
  List,
  styled,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import { IconButton, convertTimestampDate } from '@fivano/core'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import MarkeerGelezen from 'app/icons/MarkeerGelezen'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import MarkeerOngelezen from 'app/icons/MarkeerOngelezen'
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed'
import FaceIcon from '@mui/icons-material/Face'
import { useHistory } from 'react-router-dom'
import { formatDistanceToNow } from 'date-fns'
import { nl } from 'date-fns/locale'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { TimeStamp } from 'types/CommonTypes'

const NotificationsWrapper = styled('div')(({ theme }) => ({
  width: '80vh',
  maxWidth: '400px',
  minWidth: '250px',
}))

const GridHeader = styled(Grid)(({ theme }) => ({
  padding: '0px 16px 4px 16px',
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const ListStyled = styled(List)(() => ({
  height: '80vh',
  maxHeight: '600px',
  minHeight: '300px',
  overflowY: 'auto',
  width: '100%',
  padding: 0,
}))

const ListItemStyled = styled(ListItem, {
  shouldForwardProp: prop => prop !== 'read',
})<any>(({ theme, read }) => ({
  backgroundColor: `${
    theme.palette.mode === 'light'
      ? theme.palette.grey[200]
      : theme.palette.grey[700]
  }`,
  ...(read && {
    backgroundColor: theme.palette.background.paper,
  }),
}))

const TypographyStyled = styled(Typography)(() => ({
  display: '-webkit-box',
  lineClamp: 2,
  boxOrient: 'vertical',
  overflow: 'hidden',
}))

const SettingsIconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  position: 'absolute',
  top: 5,
  right: 5,
  zIndex: 5,
  padding: 5,
}))

const SettingsPopoverStyled = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    maxWidth: 300,
    width: 'calc(100% - 32px)',
    border: `1px solid ${theme.palette.divider}`,
  },
}))

const NotiButtonBaseStyled = styled(ButtonBase)(() => ({
  width: '100%',
  padding: '8px 10px',
  justifyContent: 'flex-start',
}))

const GelezenStyled = styled(MarkeerGelezen)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginRight: 5,
}))

const OngelezenStyled = styled(MarkeerOngelezen)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginRight: 5,
}))

const DynamicFeedStyled = styled(DynamicFeedIcon, {
  shouldForwardProp: prop => prop !== 'read',
})<any>(({ theme, read }) => ({
  marginRight: 5,
  color: theme.palette.primary.main,
  ...(read && {
    color: theme.palette.text.secondary,
  }),
}))

const FaceStyled = styled(FaceIcon, {
  shouldForwardProp: prop => prop !== 'read',
})<any>(({ theme, read }) => ({
  marginRight: 5,
  color: theme.palette.primary.main,
  ...(read && {
    color: theme.palette.text.secondary,
  }),
}))

type Notifications = {
  creationDateNoti: TimeStamp
  id: string
  linkNoti: string
  locationNoti: string
  nameNoti: string
  readBy: string[]
  textNoti: string
  typeNoti: string
} & any[]

export const NotificationsContent = () => {
  const errorLogger = useErrorLogger()
  const firestore = useFirestore()
  const history = useHistory()

  const uid = useSelector(({ firebase: { auth } }) => auth.uid)

  // ==== GET NOTIFICATIONS ==== //
  // @TODO: get all unread notifications
  const [notificationLimit, setNotificationLimit] = useState(10)
  useFirestoreConnect([
    {
      collection: 'notifications',
      orderBy: ['creationDateNoti', 'desc'],
      limit: notificationLimit,
      storeAs: `notifications`,
    },
    // @TODO: return all notifications where uid is not in array
    // @TODO: all notifications where uid is undefined in readBy
  ])

  // ==== NOTIFICATION LISTENER ==== //
  const notifications = useSelector(
    ({ firestore: { ordered } }) => ordered[`notifications`],
  )

  // ==== LOAD MORE NOTIFICATIONS
  const handleLoadMore = () => {
    setNotificationLimit(notificationLimit + 5)
  }

  // HANDLE READ NOTIFICATIONS ==== //
  const handleReadNotification = (
    markAsReadNotis: Array<Notifications>,
    route: boolean,
  ) => {
    markAsReadNotis.forEach(item => {
      if (!item.readBy.includes(uid)) {
        firestore
          .update(
            { collection: 'notifications', doc: item.id },
            { readBy: firestore.FieldValue.arrayUnion(`${uid}`) },
          )
          .then(() => {
            if (route === true) {
              history.push(item.linkNoti)
            }
          })
          .catch(error => {
            errorLogger({ error })
          })
      } else if (route === true) {
        history.push(item.linkNoti)
      }
    })
  }

  // ==== HANDLE UNREAD SINGLE NOTIFICATION ==== //
  const handleUnreadSingle = (id: string) => {
    firestore
      .update(
        { collection: 'notifications', doc: id },
        { readBy: firestore.FieldValue.arrayRemove(`${uid}`) },
      )
      .then(response => {
        return response
      })
      .catch(error => {
        errorLogger({ error })
      })
  }

  // ==== SINGLE NOTIFICATION ITEM POPOVER ==== //
  const [anchorElSettings, setAnchorElSettings] = React.useState(null)
  const [notificationID, setNotificationID] = useState('')

  const handleClickSettings = (event, id) => {
    setAnchorElSettings(event.currentTarget)
    setNotificationID(id)
  }

  const handleCloseSettings = () => {
    setAnchorElSettings(null)
    setNotificationID('')
  }

  const openSettings = Boolean(anchorElSettings)

  return (
    <NotificationsWrapper>
      <GridHeader container alignItems='center'>
        <Grid item>
          <Typography variant='h6' display='inline'>
            Meldingen
          </Typography>
        </Grid>
        <Grid item marginLeft='auto'>
          <IconButton
            label='Markeer als gelezen'
            onClick={() => handleReadNotification(notifications, false)}
            size='large'
          >
            <MarkeerGelezen />
          </IconButton>
          <IconButton label='Instellingen' size='large'>
            <SettingsIcon />
          </IconButton>
        </Grid>
      </GridHeader>
      <ListStyled aria-label='notifications'>
        {notifications?.length > 0 &&
          notifications.map((notification: Notifications, index) => {
            return (
              <Grid key={index} container position='relative'>
                <ListItemStyled
                  button
                  read={
                    notification?.readBy?.find(id => id === uid) !== undefined
                  }
                  onClick={() => {
                    handleReadNotification([notification], true)
                  }}
                >
                  <Grid container>
                    <Grid container alignItems='center' item>
                      {notification.typeNoti === 'track' ? (
                        <DynamicFeedStyled
                          fontSize='small'
                          read={
                            notification?.readBy?.find(id => id === uid) !==
                            undefined
                          }
                        />
                      ) : notification.typeNoti === 'participant' ? (
                        <FaceStyled
                          fontSize='small'
                          read={
                            notification?.readBy?.find(id => id === uid) !==
                            undefined
                          }
                        />
                      ) : (
                        ''
                      )}
                      <Grid item>
                        <Typography variant='body1'>
                          {notification.nameNoti}
                        </Typography>
                        <TypographyStyled variant='body2' color='textSecondary'>
                          {notification.textNoti}
                        </TypographyStyled>
                        <Typography variant='caption' color='textSecondary'>
                          {notification.creationDateNoti &&
                            formatDistanceToNow(
                              convertTimestampDate(
                                notification.creationDateNoti,
                              ),
                              { locale: nl, addSuffix: true },
                            )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItemStyled>
                <SettingsIconButtonStyled
                  label='Instellingen'
                  onClick={e => {
                    handleClickSettings(e, notification.id)
                  }}
                  size='large'
                >
                  <MoreHorizIcon />
                </SettingsIconButtonStyled>
                <SettingsPopoverStyled
                  id={notification.id}
                  open={openSettings && notificationID === notification.id}
                  anchorEl={anchorElSettings}
                  onClose={handleCloseSettings}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <NotiButtonBaseStyled
                    onClick={() => {
                      handleReadNotification([notification], false)
                    }}
                    disabled={
                      notification?.readBy?.find(id => id === uid) !== undefined
                        ? true
                        : false
                    }
                  >
                    <GelezenStyled fontSize='small' />
                    <Typography
                      variant='body2'
                      color={
                        notification?.readBy?.find(id => id === uid) !==
                        undefined
                          ? 'textSecondary'
                          : 'textPrimary'
                      }
                    >
                      Markeer als gelezen
                    </Typography>
                  </NotiButtonBaseStyled>
                  <Divider />
                  <NotiButtonBaseStyled
                    disabled={
                      notification?.readBy?.find(id => id === uid) !== undefined
                        ? false
                        : true
                    }
                    onClick={() => {
                      handleUnreadSingle(notification.id)
                    }}
                  >
                    <OngelezenStyled fontSize='small' />
                    <Typography
                      variant='body2'
                      color={
                        notification?.readBy?.find(id => id === uid) !==
                        undefined
                          ? 'textPrimary'
                          : 'textSecondary'
                      }
                    >
                      Markeer als ongelezen
                    </Typography>
                  </NotiButtonBaseStyled>
                </SettingsPopoverStyled>
              </Grid>
            )
          })}
        {notifications?.length > 0 && (
          <Button fullWidth onClick={handleLoadMore}>
            Meer notificaties laden
          </Button>
        )}
      </ListStyled>
    </NotificationsWrapper>
  )
}

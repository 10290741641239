import React from 'react'
import { Box, capitalize, Chip, Grid, Paper, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { useSelector } from 'hooks/useSelector'
import { useFirestoreConnect } from 'react-redux-firebase'
import { SearchFilter } from 'app/components/SearchFilter'
import { Checkbox, FloatingButton, ListView, PageContainer } from '@fivano/core'
import { useFilters } from 'hooks/useFilters'

const listColumns = [
  {
    Header: ' ',
    canGroupBy: false,
    canSort: false,
    columns: [
      {
        Header: 'Versie',
        accessor: 'version',
        gridProps: {
          xs: 3,
        },
        Cell: data => (
          <div>
            <Typography variant='body1' display='inline' gutterBottom>
              {data.row.original.version}
              {data.row.original.archived && (
                <Chip label='Gearchiveerd' size='small' />
              )}
            </Typography>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'active',
        gridProps: {
          xs: 3,
        },
        Cell: data => (
          <div>
            <Typography variant='body1' display='inline' gutterBottom>
              {data.row.original.active ? 'actief' : 'non-actief'}
            </Typography>
          </div>
        ),
      },
    ],
  },
]

export const LicensesPage = () => {
  const [filters, setFilters] = useFilters({
    searchField: {
      value: '',
      label: 'Zoek op:',
      param: true,
    },
    archived: {
      value: false,
      label: 'Toon gearchiveerd',
      param: true,
    },
  })

  const history = useHistory()
  const pluralLabel = 'licenties'

  const breadcrumbArray = [
    { title: 'Home', path: '/' },
    { title: `${capitalize(pluralLabel)}`, path: `/${pluralLabel}` },
  ]

  const handleFilter = data => {
    const newFilters = { ...filters }
    newFilters.searchField.value = data.searchField
    newFilters.archived.value = data.archived
    setFilters(newFilters)
  }

  useFirestoreConnect([
    {
      collection: 'licenses',
      limit: 20,
      where: [
        ['keywords', 'array-contains', filters.searchField.value.toLowerCase()],
        ['archived', '==', filters.archived.value],
      ],
      storeAs: 'licenses',
    },
  ])

  const licensesData = useSelector(
    ({
      // @ts-ignore
      firestore: {
        ordered: { licenses },
      },
    }) => {
      if (licenses) {
        return licenses
      }
      return []
    },
  )

  return (
    <PageContainer maxWidth='lg'>
      <Breadcrumbs breadcrumbs={breadcrumbArray} />
      <Paper>
        <Box p={2}>
          <Grid container>
            <Grid item xs={12}>
              <SearchFilter
                placeholder='Zoeken op versie'
                onSubmit={handleFilter}
                filterValues={filters}
                filters={({ formObject }) => {
                  return (
                    <Checkbox
                      label='Toon gearchiveerde licenties'
                      formObject={formObject}
                      name='archived'
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <ListView
          data={licensesData}
          columns={listColumns}
          onClick={(_, data) => {
            history.push(`/${pluralLabel}/${data.original.id}/formulier`)
          }}
        />

        <FloatingButton
          label={`Licentie toevoegen`}
          onClick={() => {
            history.push(`/licenties/formulier`)
          }}
        />
      </Paper>
    </PageContainer>
  )
}

export const checkTimeOverlap = (
  idA,
  idB,
  startTimeA,
  endTimeA,
  startTimeB,
  endTimeB,
  index,
) => {
  let error
  if (idA !== idB) {
    const checkStartTimeOverlap =
      startTimeB > startTimeA && startTimeB < endTimeA
    const checkEndTimeOverlap = endTimeB > startTimeA && endTimeB < endTimeA

    const checkSameOverlap = startTimeB === startTimeA && endTimeB === endTimeA

    const checkStartEndInsideData =
      (startTimeB <= startTimeA && endTimeB >= endTimeA) ||
      (startTimeA <= startTimeB && endTimeA >= endTimeB)

    if (checkStartTimeOverlap && checkEndTimeOverlap) {
      error =
        'Starttijd en eindtijd overlappen met de begintijd van een andere rij'
    } else if (checkStartTimeOverlap) {
      error = 'Starttijd overlapt met de eindtijd van de vorige rij'
    } else if (checkEndTimeOverlap) {
      error = 'Eindtijd overlapt met de begintijd van de volgende rij'
    } else if (checkStartEndInsideData) {
      error = 'Andere rij valt binnen deze rij'
    } else if (checkSameOverlap) {
      error = 'Begin en eindtijd zijn hetzelfde als de volgende rij'
    }
    return { error: error, index: index }
  }
}

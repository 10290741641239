import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  Popover,
  Box,
  Button,
  FormControlLabel,
  Switch as MaterialSwitch,
  Grid,
  Typography,
  MenuItem,
  styled,
} from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useSnackbar } from '@fivano/core'
import { useSelector } from 'hooks/useSelector'
import { useParams } from 'react-router-dom'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { Phase } from './Phase'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { PhaseForm } from './PhaseForm'
import { IconButton } from '@fivano/core'
import { RouteTypes } from 'types/RouteTypes'
import { PhaseFormTypes } from './BoardTypes'
import { InfoOutlined } from '@mui/icons-material'
import { useErrorLogger } from 'hooks/useErrorLogger'

// ignore dnd warnings regarding: https://github.com/atlassian/react-beautiful-dnd/issues/131
//@ts-ignore
// window['__react-beautiful-dnd-disable-dev-warnings'] = true

const WrapperDivStyled = styled('div')(({ theme }) => ({
  flexGrow: 1,
  height: 'calc(100vh - 0px)', // appHeader (0px) on desktop
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 60px)', // appHeader (0px) + mobileMenu (60px) 60px
  },
}))

const ContentDivStyled = styled('div')(({ theme }) => ({
  flexGrow: 1,
  position: 'relative',
  height: 'calc(100vh - 60px)', // appHeader (0px) + boardHeader (60px) 60px
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 120px)', // appHeader mobile (0px)  + mobileMenu (60px) + boardHeader (60px) 120px
  },
}))

const InnerDivStyled = styled('div')(() => ({
  overflowX: 'auto',
  overflowY: 'auto',
  position: 'absolute',
  inset: 0,
  paddingBottom: '10px',
  whiteSpace: 'nowrap',
  height: '100%',
  '& > div:first-of-type': {
    marginLeft: 16,
  },
}))

const AddPhaseCardStyled = styled(Card)(({ theme }) => ({
  margin: '0 4px',
  display: 'inline-block',
  width: 380,
  maxHeight: 68,
  [theme.breakpoints.down('sm')]: {
    width: 300,
  },
}))

const InfoCardStyled = styled(Card)(() => ({
  minWidth: 400,
  maxWidth: 600,
}))

const HeaderGridStyled = styled(Grid)(() => ({
  paddingLeft: 8,
  marginRight: 'auto',
  width: 'calc(100% - 96px)',
}))

type PhaseModalProps = {
  phaseID?: string
  open: boolean
}

export const Board = () => {
  const [showArchivedPhases, setShowArchivedPhases] = useState(false)
  const [showArchivedBoardCards, setShowArchivedBoardCards] = useState(false)

  const [phaseModalProps, setPhaseModalProps] = useState<PhaseModalProps>({
    phaseID: undefined,
    open: false,
  })
  const errorLogger = useErrorLogger()
  const [phasesBoard, setPhasesBoard] = useState<PhaseFormTypes[]>([])
  const firestore = useFirestore()
  const { boardID } = useParams<RouteTypes>()
  const boardData = useSelector(
    ({ firestore: { ordered } }) => ordered[`boards_${boardID}`]?.[0],
  )

  useEffect(() => {
    setPhasesBoard(boardData?.phasesBoard)
  }, [boardData])

  useFirestoreConnect([
    {
      collection: 'boards',
      doc: boardID,
      storeAs: `boards_${boardID}`,
    },
    {
      collection: 'boards',
      doc: boardID,
      subcollections: [
        {
          collection: 'boardCards',
          where: ['archivedBoca', '==', showArchivedBoardCards],
        },
      ],
      storeAs: `boards_${boardID}_boardCards`,
    },
  ])

  const breadcrumbArray = [
    { title: 'Home', path: '/' },
    { title: 'CRM', path: '/crm-borden' },
    {
      title: boardData?.nameBoard || 'Laden',
      path: `/crm-borden/${boardID}`,
    },
  ]

  const { enqueueSnackbar } = useSnackbar()
  const moveCardWithinPhase = (source, destination, draggableId) => {
    const newPhasesBoard: PhaseFormTypes[] = []
    boardData.phasesBoard.forEach(phase => {
      if (phase.phaseID === source.droppableId) {
        const newPhaseCardIDs = phase.boardCardIDs.filter(
          id => id !== draggableId,
        )
        newPhaseCardIDs.splice(destination.index, 0, draggableId)
        const newPhaseObject = {
          ...phase,
          ...{ boardCardIDs: newPhaseCardIDs },
        }
        newPhasesBoard.push(newPhaseObject)
      } else {
        newPhasesBoard.push(phase)
      }
    })
    setPhasesBoard(newPhasesBoard)

    firestore
      .update(`boards/${boardID}`, {
        phasesBoard: newPhasesBoard,
      })
      .then(() => {
        enqueueSnackbar('Item verplaatst', {
          variant: 'success',
        })
      })
      .catch(error => {
        errorLogger({ error })
      })
  }

  const moveCardToOtherPhase = (source, destination, draggableId) => {
    const newPhasesBoard: Array<any> = []
    boardData.phasesBoard.forEach(phase => {
      if (phase.phaseID === source.droppableId) {
        const newSourceCardIDs = phase.boardCardIDs.filter(
          id => id !== draggableId,
        )
        const newSourcePhase = {
          ...phase,
          ...{ boardCardIDs: newSourceCardIDs },
        }
        newPhasesBoard.push(newSourcePhase)
      } else if (phase.phaseID === destination.droppableId) {
        const newDestinationCardIDs = phase.boardCardIDs.slice()
        newDestinationCardIDs.splice(destination.index, 0, draggableId)
        const newDestinationPhase = {
          ...phase,
          ...{
            boardCardIDs: newDestinationCardIDs,
          },
        }
        newPhasesBoard.push(newDestinationPhase)
      } else {
        newPhasesBoard.push(phase)
      }
    })
    setPhasesBoard(newPhasesBoard)

    firestore
      .update(`boards/${boardID}`, {
        phasesBoard: newPhasesBoard,
      })
      .then(async () => {
        await firestore.update(`boards/${boardID}/boardCards/${draggableId}`, {
          phaseBoca: {
            label: boardData.phasesBoard.find(
              phase => phase.phaseID === destination.droppableId,
            ).namePhase,
            value: destination.droppableId,
          },
        })
      })
      .then(() => {
        enqueueSnackbar('Item verplaatst', {
          variant: 'success',
        })
      })
      .catch(error => {
        errorLogger({ error })
      })
  }

  const handleDragEnd = async ({
    source,
    destination,
    draggableId,
  }: DropResult): Promise<void> => {
    if (!destination) {
      return
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      moveCardWithinPhase(source, destination, draggableId)
    } else {
      moveCardToOtherPhase(source, destination, draggableId)
    }
  }

  const [anchorInfo, setAnchorInfo] = useState(null)

  const handleOpenInfo = event => {
    setAnchorInfo(event.currentTarget)
  }

  const handleCloseInfo = () => {
    setAnchorInfo(null)
  }

  const open = Boolean(anchorInfo)
  const id = open ? 'info-popover' : undefined

  const [optionsAnchor, setOptionsAnchor] = useState(null)

  const openOptionsMenu = event => {
    setOptionsAnchor(event.currentTarget)
  }

  const closeOptionsMenu = () => {
    setOptionsAnchor(null)
  }

  return (
    <>
      <Helmet>
        <title>{boardData?.nameBoard + '- CRM overzicht'}</title>
        <meta name='description' content='Description of Crm' />
      </Helmet>
      {phaseModalProps.open && (
        <PhaseForm
          boardID={boardID}
          editingDocID={phaseModalProps.phaseID}
          onCloseForm={() => {
            setPhaseModalProps({ phaseID: undefined, open: false })
          }}
        />
      )}
      <WrapperDivStyled>
        <Grid container alignItems='baseline'>
          <HeaderGridStyled item>
            <Breadcrumbs breadcrumbs={breadcrumbArray} />
          </HeaderGridStyled>
          <Grid item>
            <IconButton
              label='Bord info'
              aria-describedby={id}
              onClick={handleOpenInfo}
              size='large'
            >
              <InfoOutlined />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorInfo}
              onClose={handleCloseInfo}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <InfoCardStyled>
                <CardContent>
                  <Typography variant='h5'>{boardData?.nameBoard}</Typography>
                  <Typography
                    gutterBottom
                    variant='body2'
                    color='textSecondary'
                  >
                    {boardData?.locationBoard.label}
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    {boardData?.descriptionBoard}
                  </Typography>
                </CardContent>
              </InfoCardStyled>
            </Popover>
            <IconButton
              label='Opties'
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={openOptionsMenu}
              size='large'
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              anchorEl={optionsAnchor}
              open={Boolean(optionsAnchor)}
              onClose={closeOptionsMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem>
                <FormControlLabel
                  control={
                    <MaterialSwitch
                      color='primary'
                      name='showArchivedPhases'
                      onChange={e => setShowArchivedPhases(e.target.checked)}
                      checked={showArchivedPhases}
                    />
                  }
                  label='Toon gearchiveerde fases'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={
                    <MaterialSwitch
                      color='primary'
                      name='showArchivedBoardCards'
                      onChange={e =>
                        setShowArchivedBoardCards(e.target.checked)
                      }
                      checked={showArchivedBoardCards}
                    />
                  }
                  label='Toon gearchiveerde items'
                />
              </MenuItem>
            </Popover>
          </Grid>
        </Grid>
        <DragDropContext onDragEnd={handleDragEnd}>
          <ContentDivStyled>
            <InnerDivStyled>
              {phasesBoard
                ?.filter(phase => phase.archivedPhase === showArchivedPhases)
                .map(phaseData => {
                  return (
                    <Phase
                      phaseData={phaseData}
                      moveCard={moveCardToOtherPhase}
                      key={phaseData.phaseID}
                      boardID={boardID}
                      openEdit={() => {
                        setPhaseModalProps({
                          phaseID: phaseData.phaseID,
                          open: true,
                        })
                      }}
                    />
                  )
                })}
              <AddPhaseCardStyled>
                <Box p={2} display='flex' justifyContent='center'>
                  <Button
                    disabled={showArchivedPhases}
                    onClick={() => {
                      setPhaseModalProps({ phaseID: undefined, open: true })
                    }}
                  >
                    Fase toevoegen
                  </Button>
                </Box>
              </AddPhaseCardStyled>
            </InnerDivStyled>
          </ContentDivStyled>
        </DragDropContext>
      </WrapperDivStyled>
    </>
  )
}

import {
  DialogContent,
  useTheme,
  useMediaQuery,
  Typography,
  Grid,
} from '@mui/material'

import { Modal } from '@fivano/core'
import useMongo from 'hooks/useMongo'
import { deepCopy } from 'utils/deepCopy'
import { ProductCard } from './ProductCard'

export const ProductForm = ({
  id,
  layoutDoc,
  onClose,
  activeID,
  onSubmitSucces,
}) => {
  const { useQueryDocs, updateDoc } = useMongo()

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  const currentIDs = layoutDoc.categories
    .find(x => x.id === activeID)
    .products.map(item => item.productID)

  const { docsData } = useQueryDocs({
    collection: 'products',
    array: true,
    query: {
      productCategories: { $in: activeID },
      _id: { $nin: currentIDs },
    },
  })

  const onSubmit = (data, activeID) => {
    const updateLayout = deepCopy(layoutDoc)

    const index = updateLayout.categories.findIndex(x => x.id === activeID)

    updateLayout.categories[index].products.push({
      id: id,
      productID: data._id,
    })

    updateDoc({
      collection: 'pointOfSaleLayout',
      docID: updateLayout._id,
      data: updateLayout,
    }).then(response => {
      onSubmitSucces(response, layoutDoc._id)
    })
  }

  return (
    <Modal
      open={true}
      onClose={onClose}
      closeButton
      title='Product toevoegen'
      customHeight={mobile ? '100vh' : 'auto'}
    >
      <>
        <DialogContent dividers style={{ display: 'flex' }}>
          <Grid container spacing={2}>
            {docsData.length > 0 ? (
              docsData.map(doc => {
                return (
                  <Grid
                    key={doc._id}
                    item
                    xs={3}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => onSubmit(doc, activeID)}
                  >
                    <ProductCard border data={doc} />
                  </Grid>
                )
              })
            ) : (
              <Typography>Geen producten beschikbaar</Typography>
            )}
          </Grid>
        </DialogContent>
      </>
    </Modal>
  )
}

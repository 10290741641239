import React, { useState } from 'react'
import { Grid, Typography, Chip } from '@mui/material'
import { DocumentRequiredTypesForm } from './DocumentRequiredTypesForm'
import { SettingsPage } from 'app/legacy/SettingsBlock'

const breadcrumbArray = [
  { title: 'Instellingen', path: '/instellingen' },
  {
    title: 'Verplichte documenten',
    path: '/instellingen/verplichte-documenten',
  },
]

export const DocumentRequiredTypes = () => {
  const [documentRequiredTypesFormProps, setDocumentRequiredTypesFormProps] =
    useState({
      formID: null,
      open: false,
    })

  const firestoreCode = 'Doty'
  const firestoreSettingsPath = 'documentRequiredTypes'

  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          accessor: `description${firestoreCode}`,
          gridProps: {
            xs: 9,
            sm: 6,
            md: 6,
          },
        },
        {
          Header: 'Categorie',
          accessor: `category${firestoreCode}`,
          gridProps: {
            xs: false,
            sm: 2,
            md: 2,
          },
          Cell: data => (
            <Grid item>
              <Typography variant='body1'>
                {data.row.original[`category${firestoreCode}`]?.label}
              </Typography>
            </Grid>
          ),
        },
        {
          Header: 'Verplicht',
          accessor: `mandatory${firestoreCode}`,
          gridProps: {
            xs: false,
            sm: 1,
            md: 1,
          },
          Cell: data => (
            <Grid item>
              {data.row.original[`mandatory${firestoreCode}`] ? (
                <Typography variant='body1'>Ja</Typography>
              ) : (
                <Typography variant='body1'>Nee</Typography>
              )}
            </Grid>
          ),
        },
        {
          Header: 'Gearchiveerd',
          accessor: `archived${firestoreCode}`,
          gridProps: {
            xs: 3,
            sm: 3,
            md: 3,
          },
          Cell: data => (
            <Grid item>
              {data.row.original[`archived${firestoreCode}`] && (
                <Chip label='Gearchiveerd' />
              )}
            </Grid>
          ),
        },
      ],
    },
  ]

  return (
    <>
      <DocumentRequiredTypesForm
        firestoreCode={firestoreCode}
        firestoreSettingsPath={firestoreSettingsPath}
        labelSingle='Verplicht document'
        formProps={documentRequiredTypesFormProps}
        onClose={() =>
          setDocumentRequiredTypesFormProps({
            formID: null,
            open: false,
          })
        }
      />
      <SettingsPage
        onAdd={() =>
          setDocumentRequiredTypesFormProps({ formID: null, open: true })
        }
        onEdit={(event, rowData) =>
          setDocumentRequiredTypesFormProps({
            formID: rowData.original[`id${firestoreCode}`],
            open: true,
          })
        }
        labelMultiple='verplichte documenten'
        labelSingle='verplicht document'
        breadcrumbs={breadcrumbArray}
        firestoreSettingsPath={firestoreSettingsPath}
        listColumns={listColumns}
        firestoreCode={firestoreCode}
        descriptionTitle='Document types'
        description='Voeg document types toe om standaard documenten bij personen toe te
        voegen, bijvoorbeeld een legitimatie, privacy verklaring of
        huurovereenkomst document. Een document type kan als verplicht worden
        gemarkeerd, in de applicatie wordt zichtbaar dat een document
        verplicht is.'
      />
    </>
  )
}

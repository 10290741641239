import React from 'react'
import { Box, Grid, styled, Typography } from '@mui/material'

import { Remove, Add } from '@mui/icons-material'
import { IconButton } from '../IconButton'
import { Controller } from 'react-hook-form'

const IconButtonSub = styled(IconButton)(({ theme }) => ({
  borderRadius: '6px 0px 0px 6px',
  border: `1px solid ${theme.palette.divider}`,
  borderRight: 0,
  padding: 7,
}))

const IconButtonAdd = styled(IconButton)(({ theme }) => ({
  borderRadius: '0px 6px 6px 0px',
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: 0,
  padding: 7,
}))

const GridStyled = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  [theme.breakpoints.down('lg')]: {
    width: '140px',
  },
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.divider}`,
  },
}))

type CounterboxProps = {
  /**Name of the Counterbox*/
  name: string
  /**Object with all the form methods from react-hook-form.*/
  formObject: any
}

export const CounterBox = ({ name, formObject }: CounterboxProps) => {
  const { control } = formObject
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value = 0 } }) => (
          <Grid container>
            <IconButtonSub
              label='1 verwijderen'
              onClick={() => onChange(value - 1)}
              disabled={value === 0}
              size='large'
            >
              <Remove />
            </IconButtonSub>
            <GridStyled item>
              <Typography variant='body1'>{value}</Typography>
            </GridStyled>
            <IconButtonAdd
              label='1 toevoegen'
              onClick={() => onChange(value + 1)}
              size='large'
            >
              <Add />
            </IconButtonAdd>
          </Grid>
        )}
      />
    </Box>
  )
}

import React from 'react'
import { PageContainer } from '@fivano/core'
import { FloatingButton } from '@fivano/core'
import { Paper } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { MusicTrackList } from './MusicTracksList'
import { Helmet } from 'react-helmet-async'
import { Breadcrumbs } from 'app/components/Breadcrumbs'

const breadcrumbArray = [
  { title: 'Home', path: '/' },
  { title: 'Tracks', path: '/tracks' },
]

export const MusicTracksPage = () => {
  const history = useHistory()

  return (
    <>
      <Helmet>
        <title>Music Tracks</title>
      </Helmet>
      <PageContainer maxWidth='lg'>
        <Breadcrumbs breadcrumbs={breadcrumbArray} />
        <FloatingButton
          label='Track toevoegen'
          onClick={() => history.push('/tracks/formulier')}
        />
        <Paper>
          <MusicTrackList
            onClick={(_, data) => {
              history.push(`/tracks/${data.original.id}/formulier`)
            }}
          />
        </Paper>
      </PageContainer>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestore,
} from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { OAuthProvider } from 'firebase/auth'
import { useSnackbar } from '@fivano/core'
import Button from '@mui/material/Button'
import { AuthWrapper } from 'app/containers/Authentication/components/AuthWrapper'
import { LoadingOverlay } from '@fivano/core'
import { Box, styled, Typography } from '@mui/material'
import MicrosoftIcon from 'app/icons/MicrosoftIcon'
import { useSettings } from 'hooks/useSettings'
import { useErrorLogger } from 'hooks/useErrorLogger'

const ButtonStyled = styled(Button)(() => ({
  height: '48px',
}))

export const SignInMicrosoft = () => {
  const history = useHistory()
  const errorLogger = useErrorLogger()
  const firebase = useFirebase()
  const firestore = useFirestore()

  const { enqueueSnackbar } = useSnackbar()
  const settings = useSettings(true)
  const auth = useSelector(state => state.firebase.auth)
  const [loadingMessage, setLoadingMessage] = useState(
    'Applicatie authorizatie koppelen aan Microsoft',
  )
  const [error, setError] = useState('')
  const provider = new OAuthProvider('microsoft.com')

  useEffect(() => {
    // Redirect if auth is available
    if (isLoaded(auth) && !isEmpty(auth)) {
      history.push('/')
    }
    // Load Microsoft auth
    if (settings.auth.microsoftAuth) {
      if (loadingMessage !== '') {
        setLoadingMessage('')
      }
      if (settings.auth.microsoftTenantID) {
        provider.setCustomParameters({
          tenant: settings.auth.microsoftTenantID,
        })
      }
    } else if (settings.auth.microsoftAuth === false) {
      setLoadingMessage(
        'Microsoft authenticatie is niet ingeschakeld in de instellingen. Vraag uw applicatiebeheerder om Microsoft authenticatie in te schakelen.',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings])

  const handleMicrosoftAuthentication = async () => {
    setLoadingMessage('Bezig met authoriseren')
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async auth => {
        setLoadingMessage('')
        setError('')
        enqueueSnackbar('Ingelogd met Microsoft account', {
          variant: 'success',
        })
        if (auth?.user?.uid) {
          firestore
            .collection('users')
            .doc(auth?.user?.uid)
            .get()
            .then(response => {
              const userData = response.data()
              if (
                response.exists &&
                userData?.firstNamePers &&
                userData?.lastNamePers
              ) {
                history.push('/')
              } else {
                history.push('/account-controle')
              }
            })
        }
      })
      .catch(error => {
        setLoadingMessage('')
        if (error.code === 'auth/account-exists-with-different-credential') {
          setError(
            'Er bestaat al een email/wachtwoord type account onder uw Microsoft email adres. U kunt niet gebruik maken van Microsoft Single Sign On. Neem contact op met uw organisatie.',
          )
        } else if (error.code === 'auth/auth-domain-config-required') {
          setError(
            'Er is een fout opgetreden tijdens de Microsoft Authenticatie. authDomain configuration is not provided when calling initializeApp(). Check Console for instructions on determining and passing that field.',
          )
        } else if (error.code === 'auth/cancelled-popup-request') {
          setError(
            'Er is een fout opgetreden tijdens de Microsoft Authenticatie. Een pop-up authenticatie vensters per login poging. Sluit alle vensters en probeer opnieuw.',
          )
        } else if (error.code === 'auth/operation-not-allowed') {
          setError(
            'Microsoft account type niet toegestaan voor authenticatie. Vraag uw organistatie om Microsoft authenticatie in te schakelen (voor uw account).',
          )
        } else if (
          error.code === 'auth/operation-not-supported-in-this-environment'
        ) {
          setError(
            'Microsoft authenticatie kan niet worden uitgevoerd. De applicatie omgeving wordt niet ondersteund, location.protocol moet https zijn.',
          )
        } else if (error.code === 'auth/popup-blocked') {
          setError(
            'Microsof authenticatie pop-up wordt geblokkeerd door uw browser. Sta pop-ups voor deze applicatie toe in uw browser of neem contact op met uw organisatie.',
          )
        } else if (error.code === 'auth/popup-closed-by-user') {
          setError(
            'Microsoft authenticatie mislukt door het vroegtijdig sluiten van de authenticatie pop-up. Probeer nogmaals te authentificeren via Microsoft.',
          )
        } else if (error.code === 'auth/unauthorized-domain') {
          setError(
            'Microsoft authenticatie mislukt. Deze domeinnaam heeft geen toegang to Microsoft authenticatie, neem contact op met uw organisatie om het probleem op te lossen.',
          )
        } else {
          setError(
            'Microsoft authenticatie mislukt. Neem contact op met uw organisatie.',
          )
        }
        errorLogger({ error })
      })
  }

  return (
    <>
      <Helmet>
        <title>Inloggen met Microsoft</title>
        <meta name='description' content='Inloggen met Microsoft' />
      </Helmet>
      <AuthWrapper title='Inloggen met Microsoft'>
        {loadingMessage && (
          <LoadingOverlay
            label={loadingMessage}
            customStyle={{ borderRadius: 4 }}
          />
        )}
        <Typography variant='body1' align='center'>
          {`Login in bij ${settings.applicationName} met uw Microsoft account via de onderstaande knop.`}
        </Typography>
        <Box p={1} />
        {error !== '' && (
          <>
            <Typography variant='body1' align='center' color='error'>
              {error}
            </Typography>
            <Box p={2} />
          </>
        )}

        <ButtonStyled
          fullWidth
          onClick={handleMicrosoftAuthentication}
          startIcon={<MicrosoftIcon />}
          size='large'
          variant='outlined'
        >
          Inloggen met Microsoft
        </ButtonStyled>
        <Box p={2} />
        <Typography variant='body1' align='center'>
          {`Heeft u geen Microsoft account van ${settings.applicationName}? Log dan met uw email adres in via de onderstaande knop.`}
        </Typography>
        <Box p={1} />
        <Button
          size='large'
          fullWidth
          color='primary'
          onClick={() => history.push('/inloggen')}
        >
          Inloggen met Email
        </Button>
      </AuthWrapper>
    </>
  )
}

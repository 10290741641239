import React from 'react'
import { capitalize } from '@mui/material'
import { ServiceCategories } from './ServiceCategories'
import { Helmet } from 'react-helmet-async'
import { servicesConfig } from '@fivano/models'

const labelMultiple = capitalize(servicesConfig.labels.plural)
// const breadcrumbs = [
//   { title: 'Instellingen', path: '/instellingen' },
//   {
//     title: labelMultiple,
//     path: `/instellingen/${servicesConfig.labels.plural}`,
//   },
// ]

export const ServicesSettings = () => {
  return (
    <>
      <Helmet>
        <title>{labelMultiple} instellingen</title>
        <meta name='description' content={`${labelMultiple} instellingen`} />
      </Helmet>
      <div>
        <ServiceCategories />
      </div>
    </>
  )
}

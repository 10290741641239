import React, { useState } from 'react'
import { Grid, styled, Typography } from '@mui/material'
import { ExpandButton } from '../AnimatedButtons'

const GridStyled = styled(Grid, {
  shouldForwardProp: prop => prop !== 'classname',
})<any>(({ classname }) => ({
  ...(classname && {
    classname,
  }),
  cursor: 'pointer',
}))

const TypographyStyled = styled(Typography)(() => ({
  marginRight: 10,
}))

type ListItemChevronProps = {
  /**Title of the Chevron*/
  title?: string
  /**Content element*/
  listItemContent?: JSX.Element
  /**Function that gets triggered when the Chevron is clicked*/
  onHandleClick: () => void
  /**String for the css layout*/
  className?: string
}

export const ListItemChevron = ({
  title,
  listItemContent,
  onHandleClick,
  className,
}: ListItemChevronProps) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <GridStyled
      container
      classname={className}
      alignItems='center'
      onClick={() => {
        onHandleClick()
        setExpanded(!expanded)
      }}
    >
      <Grid item>
        <ExpandButton
          label={expanded ? 'Uitklappen' : 'Inklappen'}
          rotate={expanded}
        />
      </Grid>
      {title && (
        <Grid item>
          <TypographyStyled>{title}</TypographyStyled>
        </Grid>
      )}
      {listItemContent}
    </GridStyled>
  )
}

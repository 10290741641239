import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Typography, CircularProgress } from '@mui/material'
import {
  Close,
  GetApp,
  Pause,
  PlayArrow,
  Rotate90DegreesCcw,
  Stop,
} from '@mui/icons-material'
import { useFirebase } from 'react-redux-firebase'
import wavesurferObject from 'wavesurfer.js'
import Regions from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js'
import {
  AudioWavesBase,
  AudioWaves,
  Regions as AudioRegions,
  bytesConvert,
  TextField,
  IconButton,
} from '@fivano/core'
import { FileInput } from 'app/components/FileInput'
import { throttle } from 'utils/throttle/throttle'
import { UseFormReturn } from 'react-hook-form'
import { audioBufferToMP3 } from './audioBufferToMP3'
import { readArrayBuffer } from './audioUtils'
import { sliceAudioBuffer } from './sliceAudioBuffer'

const downloadFile = (data: any, filename: string, mime: string) => {
  const blob = new Blob([data], { type: mime || 'application/octet-stream' })
  // @ts-ignore
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // @ts-ignore
    window.navigator.msSaveBlob(blob, filename)
    return
  }
  const blobURL = window.URL.createObjectURL(blob)
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', filename)
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL)
  }, 100)
}

const convertAudioFileToBlobUrl = file =>
  URL.createObjectURL(new Blob([file], { type: 'audio/mp3' }))

const defaultSelection = {
  id: 'selection',
  start: 0,
  end: 30,
  color: 'rgba(100, 149, 240, 0.3)',
}

export const MusicTrackWaveMaker = ({
  formObject,
}: {
  formObject: UseFormReturn
}) => {
  const firebase = useFirebase()

  /** Formobject from master form */
  const {
    watch,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = formObject

  const [audioConvertStatus, setAudioConvertStatus] = useState<string>('')
  const [audioFile, setAudioFile] = useState<any>(null)
  const [audioSelection, setAudioSelection] = useState<Blob | string>()
  const [wavesurfer, setWavesurfer] = useState<any>(null)
  const [playing, setPlaying] = useState(false)
  const [selectionTrack, setSelectionTrack] = useState(defaultSelection)

  const [wavesurferSelection, setWavesurferSelection] = useState()
  useEffect(() => {
    const createAudioSelectionWaves = async wavesurferSelection => {
      const audioWaves = await createPeaks(wavesurferSelection)
      setValue('wavesSelection', audioWaves)
    }
    if (wavesurferSelection) createAudioSelectionWaves(wavesurferSelection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wavesurferSelection])

  /** Instruct react-hook-form main form to keep track of these values: */
  useEffect(() => {
    register('previewFile', { required: true })
    register('previewFileOriginal', { required: true })
    register('duration', { required: true })
    register('waves', { required: true })
    register('wavesSelection')
  }, [register])

  /** convert trackPreviewOriginal url to file and load it for editing */
  const previewFileOriginal = watch('previewFileOriginal')
  useEffect(() => {
    const fullPath = previewFileOriginal?.[0]?.fullPath
    if (fullPath) {
      firebase
        .storage()
        .ref(fullPath)
        .getDownloadURL()
        .then(response => {
          fetch(response)
            .then(res => res.blob())
            .then(blob => {
              setAudioFile(
                new File([blob], 'previewFileOriginal.mp3', {
                  type: blob.type,
                }),
              )
            })
        })
    } else {
      setAudioFile(previewFileOriginal?.[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewFileOriginal])

  /** convert previewFile url to file and load it for editing */
  const previewFile = watch('previewFile')
  useEffect(() => {
    const fullPath = previewFile?.[0]?.fullPath
    if (fullPath) {
      setAudioSelection(
        `https://firebasestorage.googleapis.com/v0/b/${
          previewFile?.[0]?.bucket
        }/o/${fullPath.replace(/\//g, '%2F')}?alt=media`,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewFile])

  const handleWaveformReady = wavesurfer => {
    setWavesurfer(wavesurfer)
  }

  const handlePlay = () => {
    if (wavesurfer?.getDuration()) {
      wavesurfer.seekTo(
        (1 / wavesurfer?.getDuration()) * getValues('startPreview'),
      )
    }
    playing ? wavesurfer.pause() : wavesurfer.play()
    setPlaying(!playing)
  }

  const handleStop = () => {
    setPlaying(false)
    wavesurfer.pause()
    wavesurfer.seekTo(
      (1 / wavesurfer?.getDuration()) * getValues('startPreview'),
    )
  }

  const cutAudioFile = async (file: any, startSec: string, endSec: string) => {
    setAudioConvertStatus('MP3 Converteren')
    const start = typeof startSec === 'string' ? parseInt(startSec) : startSec
    const end = typeof endSec === 'string' ? parseInt(endSec) : endSec
    const arrayBuffer = await readArrayBuffer(file)
    const audioBuffer = await new AudioContext().decodeAudioData(arrayBuffer)
    const newBuffer = sliceAudioBuffer({ audioBuffer, start, end })
    const audioMP3Blob = audioBufferToMP3(newBuffer)
    setAudioSelection(audioMP3Blob)
    setAudioConvertStatus('')
    const audioMP3File = new File(
      // @ts-ignore
      [audioMP3Blob],
      `${getValues('nameTrack') || 'trackPreview'}.mp3`,
      {
        type: 'audio/mpeg',
      },
    )

    setValue('previewFile', [audioMP3File], {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const createPeaks = wavesurfer =>
    wavesurfer
      .exportPCM(256, 64, true, null)
      .then((exportPCM: any) => exportPCM)

  const handleConvertPreview = async () => {
    setValue('duration', wavesurfer?.getDuration())
    const newPeaks = await createPeaks(wavesurfer)
    setValue('waves', newPeaks, {
      shouldValidate: true,
      shouldDirty: true,
    })
    cutAudioFile(audioFile, getValues('startPreview'), getValues('endPreview'))
  }

  const handleCancelPreview = () => {
    setAudioFile(undefined)
    setAudioSelection(undefined)
    setSelectionTrack(defaultSelection)
    setWavesurfer(null)
    setPlaying(false)
    setValue('previewFile', undefined)
    setValue('previewFileOriginal', undefined)
  }
  const prevSelectionRef: any = useRef()
  useEffect(() => {
    prevSelectionRef.current = selectionTrack
  })
  const handleUpdatePreviewTimes = (wavesurferObject?: any) => {
    // Check if wavesurferObject region triggers function
    if (wavesurferObject.region) {
      const { start, end } = wavesurferObject.region
      const prevSelection = prevSelectionRef.current
      if (prevSelection.start !== start || prevSelection.end !== end) {
        if (prevSelection.start !== getValues('startPreview')) {
          setAudioSelection(undefined)
        }
        setValue('startPreview', start.toFixed(3))
        setValue('endPreview', end.toFixed(3))
        const duration = wavesurfer?.getDuration()
        if (duration) wavesurfer.seekTo((1 / duration) * start)
        setSelectionTrack({
          ...selectionTrack,
          start,
          end,
        })
      }
    } else {
      setSelectionTrack({
        ...selectionTrack,
        start: parseFloat(getValues('startPreview')),
        end: parseFloat(getValues('endPreview')),
      })
    }
  }

  useEffect(() => {
    const formValues = formObject.control._defaultValues.current
    if (formValues) {
      if (wavesurfer && wavesurfer.seekTo && wavesurfer?.getDuration) {
        wavesurfer.seekTo(
          (1 / wavesurfer?.getDuration()) * getValues('startPreview'),
        )
        setValue('duration', wavesurfer?.getDuration())
      }
      setSelectionTrack({
        ...defaultSelection,
        start: formValues.startPreview,
        end: formValues.endPreview,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {errors?.previewFile?.type === 'required' && (
        <Typography color='error'>
          Track preview moet eerst geconverteerd worden, klik op 'Convert'
        </Typography>
      )}
      <Box style={audioFile ? { display: 'none' } : {}}>
        <FileInput
          name='previewFileOriginal'
          fileInputTitle='MP3 toevoegen'
          formObject={formObject}
          acceptedFileTypes={['audio/mpeg']}
          maxFiles={1}
          required
        />
      </Box>
      {audioFile?.lastModified && (
        <>
          <Grid container style={{ height: 40 }} spacing={1}>
            <Grid item>
              <IconButton
                style={{ width: 40, height: 40 }}
                label={playing ? 'Pauze' : 'Start'}
                onClick={handlePlay}
                size='large'
              >
                {playing ? <Pause /> : <PlayArrow />}
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                style={{ width: 40, height: 40 }}
                label='Stop'
                onClick={handleStop}
                size='large'
              >
                <Stop />
              </IconButton>
            </Grid>
            <Grid item sx={{ width: '110px' }}>
              <TextField
                type='number'
                name='startPreview'
                formObject={formObject}
                onBlur={handleUpdatePreviewTimes}
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item sx={{ width: '110px' }}>
              <TextField
                type='number'
                name='endPreview'
                formObject={formObject}
                onBlur={handleUpdatePreviewTimes}
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item>
              <IconButton
                style={{ width: 40, height: 40 }}
                label='Omzetten'
                onClick={handleConvertPreview}
                size='large'
              >
                <Rotate90DegreesCcw />
              </IconButton>
            </Grid>
            {!audioConvertStatus && audioSelection && (
              <Grid item>
                <IconButton
                  style={{ width: 40, height: 40 }}
                  label='Download preview MP3'
                  onClick={() =>
                    downloadFile(audioSelection, 'track.mp3', 'audio/mpeg')
                  }
                  size='large'
                >
                  <GetApp />
                </IconButton>
              </Grid>
            )}
            {audioConvertStatus && (
              <Grid item>
                <Grid container alignItems='center' style={{ height: 40 }}>
                  <CircularProgress size={28} thickness={4} />
                  <Typography style={{ paddingLeft: 8 }}>
                    {audioConvertStatus}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item style={{ marginLeft: 'auto' }}>
              <IconButton
                style={{ width: 40, height: 40 }}
                label='Annuleren'
                onClick={handleCancelPreview}
                size='large'
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Box py={2}>
            {wavesurferObject && (
              <AudioWavesBase
                wavesurfer={wavesurferObject}
                onWaveformReady={handleWaveformReady}
                audioFile={convertAudioFileToBlobUrl(audioFile)}
                options={{
                  progressColor: '#fd7411',
                  waveColor: '#4a4e60',
                  height: 80,
                }}
                plugins={[Regions.create()]}
              >
                <AudioRegions
                  isReady={!!wavesurfer}
                  onSingleRegionUpdate={throttle(handleUpdatePreviewTimes, 500)}
                  // @ts-ignore
                  regions={{ selection: selectionTrack }}
                />
              </AudioWavesBase>
            )}
          </Box>
          <Grid container spacing={1}></Grid>
          {wavesurferObject && audioSelection && getValues('waves')?.length && (
            <Box py={1}>
              <AudioWaves
                wavesurfer={wavesurferObject}
                audioFile={
                  typeof audioSelection === 'string'
                    ? audioSelection
                    : URL.createObjectURL(audioSelection)
                }
                audioPeaks={getValues('waves')}
                region={selectionTrack}
                duration={getValues('duration')}
                onWavesReady={setWavesurferSelection}
              />
              <Box pt={1}>
                <Typography variant='body2' color='textSecondary' gutterBottom>
                  {audioSelection instanceof Blob
                    ? `Audio sample mp3 size: ${bytesConvert(
                        audioSelection?.size,
                      )} |`
                    : ''}
                  {`Wave vector size: ${bytesConvert(
                    new TextEncoder().encode(getValues('waves')).length,
                  )}`}
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  )
}

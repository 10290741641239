import React from 'react'
import { Typography, Grid, Paper, ButtonBase, styled } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'hooks/useSelector'
import { useFirestoreConnect } from 'react-redux-firebase'

import { hasClaimLevel } from 'utils/hasClaimLevel'
import { PageContainer } from '@fivano/core'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { useHistory } from 'react-router-dom'
import { settingsMenu } from '../settingsMenu'

const ButtonBaseStyled = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  padding: 8,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}))

const GridStyled = styled(Grid)(() => ({
  padding: '4px 16px 0px 8px',
}))

const breadcrumbArray = [{ title: 'Instellingen', path: '/instellingen' }]

export const SettingsOverview = () => {
  const profile = useSelector(state => state.firebase.profile)
  const history = useHistory()
  const settingsMenuList = settingsMenu()

  useFirestoreConnect([
    {
      collection: 'appSettings',
      doc: 'applicationLogo',
      storeAs: 'applicationLogo',
    },
  ])

  return (
    <>
      <Helmet>
        <title>Algemeen - Instellingen</title>
        <meta name='description' content='Algemeen - instellingen' />
      </Helmet>
      <PageContainer disableGuttersMobile>
        <Breadcrumbs breadcrumbs={breadcrumbArray} />
        <Paper>
          <Grid container>
            {settingsMenuList.map((route, index) => {
              const Icon = route.icon
              return (
                hasClaimLevel(profile, route.route.claims) && (
                  <Grid item xs={12} md={6} key={route.name}>
                    <ButtonBaseStyled
                      onClick={() => history.push(route.route.path)}
                    >
                      <Grid container>
                        {Icon && (
                          <GridStyled item>
                            <Icon />
                          </GridStyled>
                        )}
                        <Grid item>
                          <Typography variant='h6' gutterBottom>
                            {route.name}
                            <Typography variant='body2' color='textSecondary'>
                              {route.description}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </ButtonBaseStyled>
                  </Grid>
                )
              )
            })}
          </Grid>
        </Paper>
      </PageContainer>
    </>
  )
}

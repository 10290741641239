// Routes object to enable application wide router using react-router
import {
  Authentication,
  EmailSignIn,
  SignInMicrosoft,
  EmailRegistration,
  RegisterVerification,
  PasswordForget,
  UserAccount,
} from './legacy/Authentication'
import { EmailInvitation } from './legacy/Authentication/EmailInvitation'

export const publicRoutes = [
  {
    name: 'Inloggen',
    path: '/inloggen',
    component: EmailSignIn,
    disabled: false,
  },
  {
    name: 'Inloggen met Microsoft',
    path: '/inloggen-met-microsoft',
    component: SignInMicrosoft,
    disabled: false,
  },
  {
    name: 'Registreren met email',
    path: '/registreren-met-email',
    component: EmailRegistration,
    disabled: false,
  },
  {
    name: 'Wachtwoord vergeten',
    path: '/wachtwoord-vergeten',
    component: PasswordForget,
    disabled: false,
  },
  {
    name: 'Bevestig email',
    path: '/email-bevestigen',
    component: RegisterVerification,
    disabled: false,
  },
  {
    name: 'Authenticatie',
    path: '/authenticatie',
    component: Authentication,
    disabled: true,
  },
  {
    name: 'Account controleren',
    path: '/account-controle',
    component: UserAccount,
    disabled: false,
  },
  {
    name: 'Aanmelden',
    path: '/email-uitnodiging-registreren',
    component: EmailInvitation,
    disabled: false,
  },
]

import React, { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Grid, Paper, ClickAwayListener, styled } from '@mui/material'
import { HexColorPicker } from 'react-colorful'
import { TextField } from '../TextField'
import { hexColorRegex } from '../../utils/regex/regex'

const TextFieldStyled = styled(TextField)(() => ({
  fieldset: { borderRadius: '0px 4px 4px 0px' },
}))

const HexPickerStyled = styled(HexColorPicker)(() => ({
  width: 'auto',
  '& .react-colorful__saturation': {
    borderRadius: '4px 4px 0px 0px',
  },
  '& .react-colorful__last-control': {
    borderRadius: '0px 0px 4px 4px',
  },
}))

const GridStyled = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.action.disabled}`,
  padding: '19px',
  borderRadius: '6px 0px 0px 6px',
  display: 'inline-block',
  cursor: 'pointer',
  '&:hover': {
    border: `1px solid ${theme.palette.text.primary}`,
  },
}))

const PopoverStyled = styled(Paper)(() => ({
  position: 'absolute',
  zIndex: 2,
}))

type ColorPickerProps = {
  /** name for react-hook-form */
  name: string
  /**object of all react-hook-form methods, including defaultValue */
  formObject: UseFormReturn
  /** Array of HEX colors to show as presetcolors */
  // presetColors?: string[]
  defaultValue?: string
}

/** ColorPicker for react-hook-form using HEX format for color. Provide a name, defaultValue, control */
export function ColorPicker({
  name,
  formObject,
  // presetColors,
  defaultValue,
}: ColorPickerProps) {
  const { control } = formObject
  const [showPicker, setShowPicker] = useState(false)
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <Grid
            container
            alignItems='flex-start'
            style={{ position: 'relative' }}
          >
            <GridStyled
              item
              xs={2}
              style={{ backgroundColor: value }}
              onClick={() => setShowPicker(true)}
            />
            <Grid item xs={8}>
              <TextFieldStyled
                name={name}
                formObject={formObject}
                rules={{
                  required: 'Voer een hexcode in',
                  pattern: {
                    value: hexColorRegex,
                    message: 'Geen geldige hex code',
                  },
                  maxLength: { value: 7, message: 'Maximaal 7 karakters' },
                }}
                size='small'
                variant='outlined'
                value={value}
                onChange={value => {
                  onChange(value)
                }}
                onBlur={() => {
                  onChange(value)
                }}
              />
              {showPicker && (
                <ClickAwayListener onClickAway={() => setShowPicker(false)}>
                  <PopoverStyled>
                    <HexPickerStyled color={value} onChange={onChange} />
                    {/* {presetColors?.map(presetColor => (
                      <button
                        key={presetColor}
                        style={{ background: presetColor }}
                        onClick={() => onChange(presetColor)}
                      />
                    ))} */}
                  </PopoverStyled>
                </ClickAwayListener>
              )}
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

import React, { useEffect } from 'react'
import { REGIONS_EVENTS, REGION_EVENTS, capitalizeFirstLetter } from './utils'

type RegionsType = {
  start: number
  end: number
  color?: string
  id?: string
}

type RegionsProps = {
  isReady: boolean
  wavesurfer: any
  regions: { selection: RegionsType[] }
}

export const Regions = (props: RegionsProps) => {
  const { isReady, wavesurfer, regions } = props
  const hookUpRegionEvents = (region: any) => {
    REGION_EVENTS.forEach(e => {
      const propCallback = props[`onSingleRegion${capitalizeFirstLetter(e)}`]
      if (propCallback) {
        region.on(e, (...originalArgs) => {
          propCallback({
            wavesurfer,
            originalArgs,
            region,
          })
        })
      }
    })
    region.on('remove', () => {
      REGION_EVENTS.forEach(e => {
        region.un(e)
      })
    })
  }
  useEffect(() => {
    if (isReady) {
      let newRegionId
      REGIONS_EVENTS.forEach(e => {
        const propCallback = props[`on${capitalizeFirstLetter(e)}`]
        if (!propCallback) return
        wavesurfer.on(e, (...originalArgs) => {
          propCallback({
            wavesurfer,
            originalArgs,
          })
        })
      })
      // add regions and hook up callbacks to region objects
      for (newRegionId in regions) {
        if (
          {}.hasOwnProperty.call(regions, newRegionId) &&
          wavesurfer &&
          wavesurfer.addRegion
        ) {
          hookUpRegionEvents(wavesurfer.addRegion(regions[newRegionId]))
        }
      }
    }
    return () => {
      REGION_EVENTS.forEach(e => {
        wavesurfer.un(e)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wavesurfer, isReady])

  useEffect(() => {
    // only update if the wavesurfer instance has been ready
    if (!isReady) return
    // cache reference to old regions
    const oldRegions = Object.create(wavesurfer.regions.list)
    let newRegionId
    for (newRegionId in regions) {
      if ({}.hasOwnProperty.call(regions, newRegionId)) {
        const newRegion = regions[newRegionId]
        // remove from oldRegions
        delete oldRegions[newRegionId]
        // new regions
        if (
          !wavesurfer.regions.list[newRegionId] &&
          wavesurfer &&
          wavesurfer.addRegion
        ) {
          hookUpRegionEvents(wavesurfer.addRegion(newRegion))
          // update regions
        } else if (
          oldRegions[newRegionId] &&
          (oldRegions[newRegionId].start !== newRegion.start ||
            oldRegions[newRegionId].end !== newRegion.end)
        ) {
          wavesurfer.regions.list[newRegionId].update({
            start: newRegion.start,
            end: newRegion.end,
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wavesurfer, isReady, regions])

  return <div />
}

import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function MarkeerOngelezen(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill='currentColor'
        d='M20.41 19L22.54 21.12L21.12 22.54L19 20.41L16.88 22.54L15.47 21.12L17.59 19L15.47 16.88L16.88 15.47L19 17.59L21.12 15.47L22.54 16.88L20.41 19M13 19C13 19.34 13.04 19.67 13.09 20H4C2.9 20 2 19.11 2 18V6C2 4.89 2.89 4 4 4H20C21.1 4 22 4.89 22 6V13.81C21.12 13.3 20.1 13 19 13C15.69 13 13 15.69 13 19M20 8V6L12 11L4 6V8L12 13L20 8Z'
      />
    </SvgIcon>
  )
}

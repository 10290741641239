export const optionsCreator = (options, labelKey, valueKey) => {
  const newArray: any = []
  options?.length > 0 &&
    options.forEach(option => {
      newArray.push({
        label: option[`${labelKey}`],
        value: option[`${valueKey}`],
      })
    })
  return newArray
}

export const objectOptionsCreator = (
  options: Array<any>,
  objectName: string,
) => {
  const newArray: any = []
  options.length > 0 &&
    options.forEach(option => {
      newArray.push(option[`${objectName}`])
    })
  return newArray
}

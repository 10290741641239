import { FormInputsBuilder } from 'app/components/Form/FormInputsBuilder'

const GeneralInfoInputs = ({ formObject }) => {
  const inputsConfig = [
    {
      type: 'FormPaper',
      content: [
        {
          type: 'FormRow',
          icon: 'title',
          props: { helperText: '' },
          content: [
            {
              type: 'TextField',
              props: {
                name: 'name',
                label: 'Naam product',
                rules: { required: 'Verplicht veld' },
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'subject',
          props: { helperText: '' },
          content: [
            {
              type: 'TextField',
              props: {
                name: 'description',
                label: 'Omschrijving product',
                rows: 4,
                multiline: true,
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'category',
          props: { helperText: '' },
          content: [
            {
              type: 'AutoComplete',
              props: {
                name: 'productCategories',
                label: 'Categorie product',
                optionsCollection: 'productCategories',
                multiple: true,
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'category',
          props: { helperText: '' },
          content: [
            {
              type: 'TextField',
              props: {
                name: 'productCode',
                label: 'Productcode',
                rules: { required: 'Dit veld is verplicht' },
              },
            },
          ],
        },
      ],
    },
  ]

  return (
    <FormInputsBuilder inputsConfig={inputsConfig} formObject={formObject} />
  )
}

const StatusInputs = ({ formObject }) => {
  const inputsConfig = [
    {
      type: 'FormPaper',
      content: [
        {
          type: 'FormRow',
          icon: 'public',
          props: { helperText: '' },
          content: [
            {
              type: 'AutoComplete',
              props: {
                name: 'status',
                label: 'Status',
                rules: { required: 'Verplicht veld' },
                staticOptions: {
                  public: 'Direct publiceren',
                  publishOnDate: 'Inplannen publicatie',
                  concept: 'Concept',
                },
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Checkbox',
              props: {
                name: 'bookableOnline',
                label: 'Online te boeken',
              },
            },
          ],
        },
      ],
    },
  ]

  return (
    <FormInputsBuilder inputsConfig={inputsConfig} formObject={formObject} />
  )
}

const PriceInputs = ({ formObject }) => {
  const inputsConfig = [
    {
      type: 'FormPaper',
      content: [
        {
          type: 'FormRow',
          icon: 'euro',
          props: { helperText: '' },
          content: [
            {
              type: 'Grid',
              props: { container: true, spacing: 1 },
              content: [
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'price',
                        label: 'Prijs',
                        rules: { required: 'Dit veld is verplicht' },
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'AutoComplete',
                      props: {
                        name: 'BTW',
                        label: 'BTW',
                        staticOptions: { '21%': '21%' },
                      },
                    },
                  ],
                },
                {
                  type: 'Grid',
                  props: { item: true, xs: 6 },
                  content: [
                    {
                      type: 'TextField',
                      props: {
                        name: 'purchasePrice',
                        label: 'Inkoop prijs',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ]

  return (
    <FormInputsBuilder inputsConfig={inputsConfig} formObject={formObject} />
  )
}

const AccountancyInputs = ({ formObject, showAllFields }) => {
  const inputsConfig = [
    {
      type: 'FormPaper',
      content: [
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'AutoComplete',
              props: {
                name: 'ledgerAccount',
                label: 'Grootboekrekening',
                staticOptions: { losseBad: '8000 - Losse bad' },
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'AutoComplete',
              props: {
                name: 'department',
                label: 'Afdeling',
                staticOptions: { losseBad: 'Fivano (100)' },
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          hide: !showAllFields,
          content: [
            {
              type: 'AutoComplete',
              props: {
                name: 'externalLedgerAccount',
                label: 'Externe grootboekrekening',
                staticOptions: { losseBad: '8080 - Losse bad' },
              },
            },
          ],
        },
      ],
    },
  ]

  return (
    <FormInputsBuilder inputsConfig={inputsConfig} formObject={formObject} />
  )
}

const TicketSettingsInputs = ({ formObject }) => {
  const inputsConfig = [
    {
      type: 'FormPaper',
      content: [
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Typography',
              title: 'Ticket instellingen',
              props: { variant: 'h6' },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'TextField',
              props: {
                name: 'descriptionTicket',
                label: 'Omschrijving ticket',
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Checkbox',
              props: {
                name: 'printTicket',
                label: 'Ticket afdrukken',
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Checkbox',
              props: {
                name: 'registerVisit',
                label: 'Bezoek registreren',
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: {
            helperText: '',
          },
          hide: !formObject.getValues('registerVisit'),
          content: [
            {
              type: 'TextField',
              props: {
                name: 'numberOfVisitors',
                label: 'Aantal bezoekers',
                type: 'number',
                rules: { required: 'Verplicht veld' },
              },
            },
          ],
        },
      ],
    },
  ]

  return (
    <FormInputsBuilder inputsConfig={inputsConfig} formObject={formObject} />
  )
}

const CashRegisterSettingsInputs = ({ formObject }) => {
  const inputsConfig = [
    {
      type: 'FormPaper',
      content: [
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Typography',
              title: 'Kassa instellingen',
              props: { variant: 'h6' },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Checkbox',
              props: {
                name: 'adjustableCashRegister',
                label: 'Instelbaar bij kassa inrichting',
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Checkbox',
              props: {
                name: 'useMoneyBundleAllowed',
                label: 'Gebruik van geldbundel toegestaan',
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Checkbox',
              props: {
                name: 'allowPriceChangeCashRegister',
                label: 'Prijswijziging in kassa toestaan',
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Checkbox',
              props: {
                name: 'allowDiscountCashRegister',
                label: 'Korting in kassa toegestaan',
              },
            },
          ],
        },
      ],
    },
  ]

  return (
    <FormInputsBuilder inputsConfig={inputsConfig} formObject={formObject} />
  )
}

const SupplyInputs = ({ formObject }) => {
  const inputsConfig = [
    {
      type: 'FormPaper',
      content: [
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Typography',
              title: 'Voorraad',
              props: { variant: 'h6' },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'TextField',
              props: { name: 'purchasingUnitSupply', label: 'Inkoop eenheid' },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'TextField',
              props: { name: 'purchasePriceSupply', label: 'Inkoop prijs' },
            },
          ],
        },
      ],
    },
  ]

  return (
    <FormInputsBuilder inputsConfig={inputsConfig} formObject={formObject} />
  )
}

const SubscriptionInputs = ({ formObject }) => {
  const inputsConfig = [
    {
      type: 'FormPaper',
      content: [
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Typography',
              title: 'Abonnementen',
              props: { variant: 'h6' },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Checkbox',
              props: {
                name: 'enableSubscriptionDirectlyAtSale',
                label: 'Abonnement direct inschakelen bij de verkoop',
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'Checkbox',
              props: {
                name: 'allowMultipleSubscriptionsPerCustomer',
                label: 'Meerdere abonnementen per klant toestaan',
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'TextField',
              props: {
                name: 'purchasingUnitSubscription',
                label: 'Inkoop eenheid',
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'TextField',
              props: {
                name: 'purchasePriceSubscription',
                label: 'Inkoop prijs',
              },
            },
          ],
        },
      ],
    },
  ]
  return (
    <FormInputsBuilder inputsConfig={inputsConfig} formObject={formObject} />
  )
}

export const ProductFormInputs = ({ formObject, showAllFields }) => {
  return (
    <>
      <GeneralInfoInputs formObject={formObject} />
      <StatusInputs formObject={formObject} />
      <PriceInputs formObject={formObject} />
      <AccountancyInputs
        formObject={formObject}
        showAllFields={showAllFields}
      />
      <TicketSettingsInputs formObject={formObject} />
      <CashRegisterSettingsInputs formObject={formObject} />
      <SupplyInputs formObject={formObject} />
      <SubscriptionInputs formObject={formObject} />
    </>
  )
}

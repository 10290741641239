import React from 'react'
import { useFirestore } from 'react-redux-firebase'
import { Grid } from '@mui/material'
import { AutoComplete } from 'app/components/AutoComplete'
import { TextField, Switch, FormRow, Form } from '@fivano/core'
import { Place, Subject, Title } from '@mui/icons-material'
import { useSelector } from 'hooks/useSelector'
import { useDataStandards } from 'hooks/useDataStandards'
import { useErrorLogger } from 'hooks/useErrorLogger'
import {
  BoardBuildTypes,
  BoardDatastandardTypes,
  BoardFormtypes,
} from './BoardTypes'

const defaultValues = {
  nameBoard: '',
  locationBoard: '',
  descriptionBoard: '',
  archivedBoard: false,
}

export const BoardForm = ({
  onCloseForm,
  editingDocID,
  onCreateSuccess,
}: {
  onCloseForm: any
  editingDocID?: string
  onCreateSuccess: any
}) => {
  const docLabel = 'CRM-bord'
  const firestore = useFirestore()
  const createDataStandards = useDataStandards()

  const createBoard = async data => {
    await firestore
      .collection('boards')
      .add(data)
      .then(response => onCreateSuccess(response))
  }

  const updateBoard = async data => {
    await firestore.update({ collection: 'boards', doc: editingDocID }, data)
  }

  const buildBoardData = (data: BoardBuildTypes) => {
    const dataStandards: BoardDatastandardTypes = createDataStandards({
      data,
      dataName: 'Board',
      editForm: !!editingDocID,
      keywords: [data.nameBoard],
    })

    if (!editingDocID) data.phasesBoard = []

    return { ...data, ...dataStandards }
  }

  const boardData: BoardFormtypes = useSelector(
    ({
      firestore: {
        data: { boards },
      },
    }) => {
      if (boards && editingDocID) return boards[editingDocID]

      return null
    },
  )
  const errorLogger = useErrorLogger()

  return (
    <Form
      docLabel={docLabel}
      editingDocID={editingDocID}
      buildDocData={buildBoardData}
      updateDoc={updateBoard}
      createDoc={createBoard}
      docData={!!editingDocID ? boardData : defaultValues}
      hasDetails={true}
      wrapInside='modal'
      modalMaxWidth='sm'
      onCloseForm={onCloseForm}
      onError={errorLogger}
      formInputs={({ formObject, showAllFields }) => {
        const {
          formState: { errors },
        } = formObject
        return (
          <Grid container spacing={1}>
            <FormRow icon={<Title />}>
              <TextField
                name='nameBoard'
                rules={{ required: 'Voer een naam in voor het bord' }}
                formObject={formObject}
                label='Naam CRM-bord'
              />
            </FormRow>
            <FormRow icon={<Subject />}>
              <TextField
                label='Omschrijving CRM-bord'
                name='descriptionBoard'
                rows={4}
                maxRows={20}
                multiline
                formObject={formObject}
              />
            </FormRow>

            <FormRow icon={<Place />}>
              <AutoComplete
                label='Bord locatie'
                name='locationBoard'
                formObject={formObject}
                required
                collection='locations'
                where={[['archivedLoca', '==', false]]}
                labelKeys={['nameLoca']}
                searchKey='keywordsLoca'
                error={errors.locationBoard !== undefined}
                disabled={!!editingDocID}
                helperText={
                  errors.locationBoard
                    ? errors.locationBoard.type === 'required' &&
                      'Voer een locatie in'
                    : 'Gebruikers met toegang tot deze locatie krijgen toegang tot dit CRM-bord.'
                }
              />
            </FormRow>
            <FormRow showAllFields={showAllFields}>
              <Switch
                name='archivedBoard'
                formObject={formObject}
                label={`CRM-bord archiveren`}
              />
            </FormRow>
          </Grid>
        )
      }}
    />
  )
}

import { InputAdornment, Grid, Typography, styled } from '@mui/material'
import { Visibility, VisibilityOff, Close, Done } from '@mui/icons-material'
import { TextField, IconButton } from '@fivano/core'
import React, { useEffect, useState } from 'react'

type PasswordTextFieldTypes = {
  /** Form object from react-hook-form for handling some form elements */
  formObject: any
  /** Label of password Textfield */
  label: string
  /** Name for react-hook-form, will be use in RHF data object */
  name?: string
  /** Boolean that shows the password validation functionality only when then is an error, default: false  */
  showOnError?: boolean
  /** Callback function that returns a boolean if the validation has passed */
  onValidate?: any
  /** Disables the validation entirely */
  disableValidation?: boolean
  /** Custom instruction text to show above the password validation checks */
  instructionText?: string
  /** Helpertext of password Material UI TextField */
  helperText?: string
  /** Size of Material UI textfield, default: medium */
  size?: 'small' | 'medium'
}

const DivStyled = styled('div', {
  shouldForwardProp: prop => prop !== 'errors',
})<any>(({ theme, errors }) => ({
  margin: '0px 16px',
  color: 'inherit',
  ...(errors && {
    color: theme.palette.error.main,
  }),
}))

const DoneStyled = styled(Done)(({ theme }) => ({
  marginRight: '4px',
  color: theme.palette.success.main,
}))

const CloseStyled = styled(Close)(({ theme }) => ({
  marginRight: '4px',
  color: theme.palette.error.main,
}))

/** Password TextField (Material UI Textfield) to validate if password for minLenght, number, capital and special characters */
export const PasswordTextField = ({
  formObject,
  label,
  name = 'password',
  showOnError = false,
  onValidate,
  disableValidation = false,
  helperText,
  instructionText = 'Uw wachtwoord bevat minimaal:',
  size = 'medium',
}: PasswordTextFieldTypes) => {
  const {
    formState: { errors },
    watch,
  } = formObject

  const [showPassword, setShowPassword] = useState(false)
  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const [validation, setValidation] = useState({
    minLength: { valid: false, label: '8 Karakters' },
    aCapital: { valid: false, label: '1 Hoofdletter (a-z)' },
    aNumber: { valid: false, label: '1 Nummer (0-9)' },
    aSpecialChar: { valid: false, label: '1 Speciaal karakter (!@#$)' },
  })

  const password: string = watch(name)

  const validatePassword = (validation, password) => {
    if (validation) {
      validation.minLength.valid = /.{8,}/.test(password)
      validation.aCapital.valid = /[A-Z]/.test(password)
      validation.aNumber.valid = /\d/.test(password)
      validation.aSpecialChar.valid = /\W/.test(password)
    }
    const { minLength, aCapital, aNumber, aSpecialChar } = validation

    onValidate &&
      onValidate(
        minLength.valid &&
          aCapital.valid &&
          aNumber.valid &&
          aSpecialChar.valid,
        password,
        validation,
      )
    return validation
  }

  useEffect(() => {
    setValidation(validatePassword({ ...validation }, password))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password])
  return (
    <>
      <TextField
        label={label}
        size={size}
        name={name}
        formObject={formObject}
        rules={{
          required: true,
          validate: {
            password: password => {
              const validated = validatePassword(validation, password)
              return (
                validated.aNumber.valid &&
                validated.aCapital.valid &&
                validated.aSpecialChar.valid &&
                validated.minLength.valid
              )
            },
          },
        }}
        fullWidth
        variant='outlined'
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                label={showPassword ? 'Verbergen' : 'Tonen'}
                onClick={handleShowPassword}
                size='large'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={!!errors[name]}
        helperText={
          (errors &&
            (errors[name]?.type === 'required'
              ? 'Dit veld is verplicht'
              : errors[name]?.type === 'manual' && errors[name]?.message)) ||
          helperText
        }
      />
      {
        // if showOnError === true and there are errors show validation check
        ((showOnError === true && !!errors[name]) ||
          // if showOnError === false, dont show validation check
          showOnError === false) &&
          !disableValidation && (
            <DivStyled errors={errors.password}>
              <Typography variant='body2'>{instructionText}</Typography>
              {Object.entries(validation).map(([key, { valid, label }]) => (
                <PasswordMustContainItem
                  key={key}
                  label={label}
                  valid={valid}
                />
              ))}
            </DivStyled>
          )
      }
    </>
  )
}

export const PasswordMustContainItem = ({ label, valid }) => (
  <Grid container alignItems='center'>
    {valid ? (
      <DoneStyled fontSize='inherit' />
    ) : (
      <CloseStyled fontSize='inherit' />
    )}
    <Typography variant='caption' color={valid ? 'textPrimary' : 'inherit'}>
      {label}
    </Typography>
  </Grid>
)

import React from 'react'
import { Grid, Typography, Link, Avatar, styled } from '@mui/material'

const AvatarStyled = styled(Avatar)(() => ({
  width: 24,
  height: 24,
  fontSize: 14,
  textAlign: 'center',
  padding: '2px 0px 0px 1px',
  fontWeight: 600,
  marginRight: 8,
}))

type TrackCardItemProps = {
  title: string
  relation?: any
  relationPerson?: any
  onClickPerson?: any
  onClickRelation?: any
}

export const TrackCardItem = ({
  title,
  relation,
  relationPerson,
  onClickPerson,
  onClickRelation, // TODO implement later
}: TrackCardItemProps) => (
  <Grid container spacing={1}>
    {relation?.label ? (
      <>
        <Grid item xs={12} md={6}>
          <Typography variant='caption' color='textSecondary'>
            {title}
          </Typography>
          <Typography variant='body1'>
            {relation?.label ? relation?.label : `${title} leeg'`}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container>
            {relationPerson?.label ? (
              <>
                <Grid item xs={12}>
                  <Typography variant='caption' color='textSecondary'>
                    {title} contact
                  </Typography>
                </Grid>
                <Grid item>
                  <AvatarStyled>{relationPerson.label.charAt(0)}</AvatarStyled>
                </Grid>
                <Grid item>
                  <Link
                    variant='body1'
                    color='textPrimary'
                    onClick={() => onClickPerson(relationPerson.value)}
                    href='#'
                  >
                    {relationPerson.label}
                  </Link>
                </Grid>
              </>
            ) : (
              <Grid item>
                <Typography variant='caption' color='textSecondary'>
                  {title} contact
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {`${title} contact leeg`}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    ) : (
      <Grid item>
        <Typography variant='body1' gutterBottom color='textSecondary'>
          {`${title} leeg'`}
        </Typography>
      </Grid>
    )}
  </Grid>
)

import { useEffect, useState } from 'react'
import { useSelector } from 'hooks/useSelector'
import { useDispatch } from 'react-redux'
import { useSnackbar } from '@fivano/core'
import { useApiRequest, RequestParams } from 'hooks/useApiRequest'
import { pick } from 'utils/pick'
import modelSlices from 'store/modelSlices'
import { pickArray } from 'utils/pick/pick'

export const queryAndCacheDocs = async ({
  makeRequest,
  collection,
  dispatch,
  limit,
  query,
  onSuccess,
}: {
  makeRequest: (params: RequestParams) => Promise<any>
  collection: string
  dispatch: any
  query: any
  limit?: number
  onSuccess?: (response) => any
}) => {
  let docs
  if (Object.keys(query).length === 0) {
    await dispatch(modelSlices[collection].slice.actions.setAllQueried(true))
  }
  await makeRequest({
    handleError: true,
    route: 'mongo/queryDocs',
    requestBody: {
      collection,
      query,
      limit,
    },
    onSuccess: async response => {
      docs = response.docs
      await dispatch(modelSlices[collection].slice.actions.upsertMany(docs))
      await dispatch(
        modelSlices[collection].slice.actions.addQueriedIDs(
          docs.map(doc => doc._id),
        ),
      )
      onSuccess && onSuccess(docs)
    },
  })
  return docs
}

/** Makes a request to the backend and returns the request status and docs. */
const useAwaitRequest = ({ collection, query, disabled = false }) => {
  const makeRequest = useApiRequest()
  const dispatch = useDispatch()

  const [docs, setDocs] = useState<{
    status: 'loading' | 'success'
    docs: any
  }>({
    status: 'loading',
    docs: [],
  })
  const jsonQuery = JSON.stringify(query)

  useEffect(() => {
    if (disabled) {
      setDocs({ status: 'success', docs: [] })
    } else {
      queryAndCacheDocs({
        makeRequest,
        query,
        collection,
        dispatch,
        onSuccess: docs => {
          setDocs({ status: 'success', docs })
        },
      })
    }
    return () => {
      setDocs({ status: 'loading', docs: [] })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonQuery, disabled])
  return docs
}

/** Given an array of IDs it dispatches a request for all the docs that are not
 * already queried and/or cached in the redux store.*/
const useFetchDocs = ({
  collection,
  docIDs = [],
}: {
  collection: string
  docIDs: any[]
}) => {
  const makeRequest = useApiRequest()
  const dispatch = useDispatch()

  const allQueriedAlready = false // useSelector(state => state[collection].allQueried)
  const queriedIDs = useSelector(state => {
    return state[collection].queriedIDs
  })

  if (allQueriedAlready) {
    return
  }

  const missingIDs = docIDs.filter(id => !queriedIDs.includes(id))

  if (missingIDs.length) {
    dispatch(modelSlices[collection].slice.actions.addQueriedIDs(missingIDs))

    makeRequest({
      route: 'mongo/queryDocs',
      requestBody: {
        collection,
        query: {
          _id: { $in: missingIDs },
        },
      },
      handleError: true,
      onSuccess: response => {
        dispatch(modelSlices[collection].slice.actions.addMany(response.docs))
      },
    })
  }
}

const useMongo = () => {
  const makeRequest = useApiRequest()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  /** Creates a doc in the backend and redux cache. */
  const createDoc = async ({ collection, data }) => {
    return await makeRequest({
      route: 'mongo/createDoc',
      requestBody: {
        collection,
        newDocData: data,
      },
      onSuccess: response => {
        dispatch(
          modelSlices[collection].slice.actions.upsertOne(response.docs[0]),
        )
      },
    })
  }

  /** Updates a doc in the backend and redux cache. */
  const updateDoc = async ({ collection, docID, data }) => {
    return await makeRequest({
      route: 'mongo/updateDoc',
      requestBody: {
        collection,
        docID: docID,
        newDocData: data,
      },
      onSuccess: response => {
        dispatch(
          modelSlices[collection].slice.actions.upsertOne(response.docs[0]),
        )
      },
    })
  }

  /** Deletes a doc in the backend and redux cache. */
  const deleteDoc = async ({ collection, docID }) => {
    return await makeRequest({
      route: 'mongo/deleteDoc',
      requestBody: {
        type: 'delete',
        collection,
        docID: docID,
      },
      handleError: true,
      onSuccess: response => {
        enqueueSnackbar(`Document succesvol verwijderd.`, {
          variant: 'success',
        })
        dispatch(
          modelSlices[collection].slice.actions.removeOne(response.docID),
        )
      },
    })
  }

  /** Get all or multiple docs from redux store or backend given an array of IDs. */
  const useGetDocs = ({
    collection,
    docIDs = undefined,
    disabled = false,
    allCached,
    array = false,
  }: {
    collection: string
    docIDs?: String[]
    disabled?: boolean
    allCached?: boolean
    array?: boolean
  }) => {
    useFetchDocs({
      collection,
      docIDs: docIDs?.filter(Boolean) || [],
    })
    const cachedDocs = useSelector(
      modelSlices[collection].selectors.selectEntities,
    )

    const cachedDocsArray = useSelector(
      modelSlices[collection].selectors.selectAll,
    ) as any

    if (disabled) {
      return {}
    } else if (docIDs) {
      if (array) {
        return pickArray(cachedDocsArray, docIDs)
      }
      return pick(cachedDocs, docIDs)
    } else if (allCached) {
      if (array) return cachedDocsArray
      return cachedDocs
    }
  }

  /** Get a doc from redux store or backend given an ID */
  const useGetDoc = ({ collection, docID }) => {
    useFetchDocs({
      collection,
      docIDs: [docID],
    })
    return useSelector(state =>
      modelSlices[collection].selectors.selectById(state, docID),
    )
  }

  /** Returns a doc fetched from the backend,
   * To ensure the data is up to date, it does not use the redux cache. */
  const useRefetchDoc = ({ collection, docID, disabled = false }) => {
    const { status, docs } = useAwaitRequest({
      collection,
      query: {
        _id: { $in: [docID] },
      },
      disabled,
    })
    return { docData: docs[0], status }
  }

  /** Fetches and returns docs given a query and caches them in the redux store.
   * MongoDB query operators: https://docs.mongodb.com/manual/reference/operator/query/. */
  const useQueryDocs = ({
    collection,
    query = {},
    disabled = false,
    array = false,
  }) => {
    const { status, docs } = useAwaitRequest({
      collection,
      query,
      disabled,
    })
    const allCachedDocs = useSelector(
      modelSlices[collection].selectors.selectEntities,
    ) as any

    const allCachedDocsArray = useSelector(
      modelSlices[collection].selectors.selectAll,
    ) as any

    if (Object.keys(query).length === 0 && !array) {
      return { docsData: allCachedDocs, status }
    }

    if (Object.keys(query).length === 0 && array) {
      return { docsData: allCachedDocsArray, status }
    }

    return { docsData: docs, status }
  }

  return {
    createDoc,
    deleteDoc,
    updateDoc,
    useQueryDocs,
    useRefetchDoc,
    useGetDoc,
    useGetDocs,
  }
}

export default useMongo

import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Grid, Typography, Box, capitalize, useMediaQuery } from '@mui/material'
import { format } from 'date-fns'
import { useSelector } from 'hooks/useSelector'
import { DocumentRequiredCard } from 'app/legacy/ParticipantDocuments/DocumentRequiredCard'
import { ProgressForm } from 'app/legacy/Progress/ProgressForm'
import { PersonFormModal } from 'app/legacy/PersonForm/PersonFormModal'
import { ParticipantLocationAssignForm } from 'app/legacy/ParticipantDetails/ParticipantLocationAssignForm'
import { ProgressCard } from 'app/legacy/Progress/ProgressCard'
import { TracksWidget } from 'app/legacy/Tracks/TracksWidget'
import { PersonTagsWidget } from 'app/legacy/PersonTags'
import { SickReportingWidget } from 'app/legacy/SickReporting/SickReportingWidget'
import {
  formSettings,
  initialParticipantValues,
} from 'app/legacy/Participants/ParticipantFormSettings'
import { Avatar } from 'app/components/Avatar'

import {
  ActionMenu,
  MenuButton,
  EmptyContent,
  HeaderInfoCard,
  IconLink,
  Carousel,
} from '@fivano/core'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader'
import { PageContainer, convertTimestampDate } from '@fivano/core'
import { hasClaimLevel } from 'utils/hasClaimLevel'

import { RouteTypes } from 'types/RouteTypes'
import { Person } from 'types/Person'
import { personFullName } from 'model/person'
import { useArchiveDoc } from 'hooks/useArchiveDoc'
import { useSettings } from 'hooks/useSettings'
import {
  PersonAdd,
  Place,
  Edit,
  Archive,
  Timeline,
  Description,
  LocationOn,
  Mail,
  Phone,
} from '@mui/icons-material'
import { TimeRegistrationWidget } from 'app/legacy/TimeRegistration/TimeRegistrationWidget'
import { useInviteUser } from 'hooks/useInviteUser'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { isNullish } from 'utils/isNullish'
import { DocumentsRequiredTagTypes } from '../ParticipantDocuments/ParticipantDocumentsTypes'
import { ProgressItemTypes } from '../Progress/ProgressTypes'
import { TrackType } from 'types/Track'

export const ParticipantDetails = () => {
  const { locationID, participantID } = useParams<RouteTypes>()
  const profile = useSelector(state => state.firebase.profile)
  const settings = useSettings()
  const handleInviteUser = useInviteUser()
  const locationQuery = useLocationQuery()
  useFirestoreConnect([
    {
      collection: `locations/${locationID}/persons`,
      doc: participantID,
      storeAs: `persons_${participantID}`,
    },
    {
      collectionGroup: 'progress',
      orderBy: ['dateTimeProg', 'desc'],
      where: [
        ['personIDProg', '==', participantID],
        ...locationQuery('locationIDProg'),
      ],
      limit: 12,
      storeAs: `persons_${participantID}_progress`,
    },
    {
      collection: 'appSettings',
      doc: 'documentRequiredTypes',
      storeAs: 'documentRequiredTypes',
    },
  ])

  const person: Person = useSelector(
    state => state.firestore.ordered[`persons_${participantID}`]?.[0],
  )

  const [participantModal, setParticipantModal] = useState(false)
  const [defaultFormValues, setDefaultFormValues] = useState<any>(
    initialParticipantValues,
  )
  const handleEditParticipant = () => {
    const data = { ...person }
    data.id = participantID
    setDefaultFormValues(data)
    setParticipantModal(true)
  }
  const handleCloseParticipant = () => {
    setDefaultFormValues(initialParticipantValues)
    setParticipantModal(false)
  }

  const progressData: ProgressItemTypes[] = useSelector(
    state => state.firestore.ordered[`persons_${participantID}_progress`],
  )
  const [progressFormProps, setProgressFormProps] = useState({
    progressID: null,
    open: false,
  })

  const documentTypes: DocumentsRequiredTagTypes[] = useSelector(
    state => state.firestore.ordered.documentRequiredTypes,
  )

  const documentRequiredTypes = documentTypes?.[0]?.options
    ?.filter(item => item.archivedDoty === false)
    .sort((a, b) => b.mandatoryDoty - a.mandatoryDoty)

  const breadcrumbArray = [
    { title: 'Home', path: '/' },
    { title: 'Deelnemers', path: `/deelnemers` },
    {
      title: person?.firstNamePers ? personFullName(person) : 'Laden',
      path: `/locatie/${locationID}/deelnemers/${participantID}`,
    },
  ]

  const contactButtonsHeader = [
    {
      label: 'Email openen',
      value: person?.emailPers,
      href: 'mailto:',
      icon: <Mail />,
      newTab: false,
    },
    {
      label: 'Telefoon openen',
      value: person?.phoneLinePers,
      href: 'tel:',
      icon: <Phone />,
      newTab: false,
    },
    {
      label: 'Telefoon openen',
      value: person?.phoneMobilePers,
      href: 'tel:',
      icon: <Phone />,
      newTab: false,
    },
    {
      label: 'Locatie',
      value: person?.addressZipPers
        ? `${person?.addressStreetPers ? person?.addressStreetPers : ''} ${
            person?.addressNumberPers ? person?.addressNumberPers : ''
          }${person?.addressAdditionPers ? person?.addressAdditionPers : ''}${
            person?.addressZipPers ? `,  ${person?.addressZipPers}` : ''
          } ${person?.addressCityPers ? person?.addressCityPers : ''}`
        : '',
      href: `http://maps.google.com/?q=`,
      icon: <LocationOn />,
      newTab: true,
    },
  ]
  const expandibleHeaderLeftInfo = [
    { value: person?.firstNamePers, label: 'Voornaam' },
    {
      value: `${person?.middleNamePers} ${person?.lastNamePers}`,
      label: 'Achternaam:',
    },
    { value: person?.initialsPers, label: 'Initialen' },
    { value: person?.givenNamesPers, label: 'Voornamen' },
    { value: person?.birthNamePers, label: 'Geboortenamen' },
    { value: person?.genderPers?.label, label: 'Geslacht' },
    { value: person?.civilStatus?.label, label: 'Burgelijke staat' },
    { value: person?.numberChildsPers, label: 'Kinderen' },
    { value: person?.educationPers, label: 'School en opleiding' },
  ]
  const expandibleHeaderRightInfo = [
    { value: person?.socialSecurityNumberPers, label: 'BSN nummer' },
    { value: person?.identificationNumberPers, label: 'Legitimatie nummer' },
    {
      value:
        person?.identificationValidUntilPers &&
        format(
          convertTimestampDate(person?.identificationValidUntilPers),
          'dd-MM-yyyy',
        ),

      label: 'Legitimatie geldig tot',
    },
    { value: person?.civilStatus?.label, label: 'Burgelijke staat' },
    { value: person?.dossierNumberPers, label: 'Dossiernummer' },
    { value: person?.ibanNumberPers, label: 'IBAN nummer' },
    {
      value: person?.ibanNamePers,
      label: 'IBAN ten name van',
    },
  ]

  // ===== ARCHIVE PARTICIPANT ==== //
  const archiveDoc: any = useArchiveDoc()
  const handleArchive = () => {
    archiveDoc({
      docType: 'Deelnemer',
      path: `locations/${locationID}/persons/${participantID}`,
      archived: !person?.archivedPers,
      extension: 'Pers',
    })
  }

  const matchesXsDown = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm'),
  )

  const matchesSmDown = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md'),
  )

  // ==== ASSIGN NEW LOCATION TO PARTICIPANT ==== //
  const [locationAssignFormProps, setLocationAssignFormProps] = useState({
    personID: participantID,
    open: false,
  })

  const tracksData: TrackType[] = useSelector(
    state => state.firestore.ordered[`persons_${participantID}_tracks`],
  )
  return (
    <PageContainer maxWidth='lg'>
      <>
        <Helmet>
          <title>
            Overzicht {`${person ? personFullName(person) : 'Deelnemer laden'}`}
          </title>
        </Helmet>
        {locationAssignFormProps.open && (
          <ParticipantLocationAssignForm
            wrapInside='modal'
            modalMaxWidth='sm'
            onCloseForm={() => {
              setLocationAssignFormProps({
                personID: participantID,
                open: false,
              })
            }}
            editingDocID={locationAssignFormProps.personID}
          />
        )}

        <PersonFormModal
          title='Deelnemer'
          open={participantModal}
          onClose={handleCloseParticipant}
          formSettings={formSettings}
          defaultValues={defaultFormValues}
          personTypePers='participant'
          onAddSuccess={id => {
            handleCloseParticipant()
          }}
          onUpdateSuccess={id => {
            handleCloseParticipant()
          }}
        />
        {progressFormProps.open && (
          <ProgressForm
            wrapInside='modal'
            modalMaxWidth='sm'
            onCloseForm={() => {
              setProgressFormProps({ progressID: null, open: false })
            }}
            editingDocID={progressFormProps.progressID}
          />
        )}
        <Breadcrumbs
          breadcrumbs={breadcrumbArray}
          key={person?.firstNamePers}
        />
        {person && (
          <HeaderInfoCard
            actionIconHeaderInfo={
              <ActionMenu iconPosition='vertical' minWidth={250}>
                <MenuButton
                  label='Bewerk deelnemer'
                  icon={<Edit />}
                  onClick={handleEditParticipant}
                />
                <MenuButton
                  label={
                    person?.archivedPers
                      ? 'Deelnemer tonen'
                      : 'Deelnemer archiveren'
                  }
                  icon={<Archive />}
                  onClick={handleArchive}
                />
                {settings.person.participantAccountEnabled &&
                  hasClaimLevel(profile, { p: 5 }) && (
                    <MenuButton
                      label='Deelnemer uitnodigen'
                      subtitle={
                        person?.emailInvitedPers
                          ? `Verzonden: ${person?.emailInvitedPers}`
                          : ''
                      }
                      icon={<PersonAdd />}
                      onClick={() => handleInviteUser(person, locationID)}
                    />
                  )}
                {settings.person.locationAssignEnabled &&
                  hasClaimLevel(profile, { l: 5 }) && (
                    <MenuButton
                      label='Locatie toevoegen'
                      icon={<Place />}
                      onClick={() =>
                        setLocationAssignFormProps({
                          personID: participantID,
                          open: true,
                        })
                      }
                    />
                  )}
              </ActionMenu>
            }
            leftSideHeaderInfo={
              <>
                <Grid
                  container
                  spacing={1}
                  justifyContent={matchesXsDown ? 'center' : 'flex-start'}
                  direction={matchesXsDown ? 'column' : 'row'}
                >
                  <Grid item>
                    <Avatar
                      size={matchesSmDown ? 'medium' : 'large'}
                      personData={person}
                      centerAvatar
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant='h5'>
                      {person ? personFullName(person) : 'Deelnemer laden'}
                    </Typography>
                    {person?.birthdatePers && (
                      <Typography variant='subtitle1'>
                        {format(
                          convertTimestampDate(person?.birthdatePers),
                          'dd/MM/yyyy',
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {hasClaimLevel(profile, { pt: 1 }) && (
                  <Grid item xs={12} paddingTop='16px'>
                    <PersonTagsWidget
                      participantID={participantID}
                      tagName={capitalize(
                        settings.personTags.labels.personTags1,
                      )}
                    />
                  </Grid>
                )}
              </>
            }
            rightSideHeaderInfo={
              <>
                {contactButtonsHeader.map((item, index) => {
                  return (
                    <Grid item key={index}>
                      {item.value !== '' && item.value !== undefined && (
                        <IconLink
                          tooltip={item.label}
                          label={item.value}
                          href={`${item.href}${item.value}`}
                          icon={item.icon}
                          color='inherit'
                          newTab={item.newTab}
                        />
                      )}
                    </Grid>
                  )
                })}
              </>
            }
            expandibleHeaderLeftInfo={
              <>
                <Box p={1} />
                {expandibleHeaderLeftInfo.map((item, index) => {
                  if (item.value === null) {
                    return <Box p={1} key={index} />
                  } else if (isNullish(item.value)) {
                    return null
                  } else {
                    return (
                      <Grid item key={index}>
                        <Typography
                          variant='caption'
                          color='textSecondary'
                          display='block'
                        >
                          {item.label}
                        </Typography>
                        <Typography variant='body1'>{item.value}</Typography>
                      </Grid>
                    )
                  }
                })}
              </>
            }
            expandibleHeaderRightInfo={
              <>
                {expandibleHeaderRightInfo.map((item, index) => {
                  if (item.value === null) {
                    return <Box p={1} key={index} />
                  } else if (item.value === '' || item.value === undefined) {
                    return null
                  } else {
                    return (
                      <Grid item key={index}>
                        <Typography
                          variant='caption'
                          color='textSecondary'
                          display='block'
                        >
                          {item.label}
                        </Typography>
                        <Typography variant='body1'>{item.value}</Typography>
                      </Grid>
                    )
                  }
                })}
              </>
            }
          />
        )}

        <WidgetHeader
          title='Voortgang'
          buttonLabel='Toevoegen'
          icon={Timeline}
          onButtonClick={() => {
            setProgressFormProps({ progressID: null, open: true })
          }}
          path={`/locatie/${locationID}/deelnemers/${participantID}/voortgang`}
        />
        {progressData?.length > 0 ? (
          <div style={{ flexGrow: 1, maxWidth: '90vw' }}>
            <Carousel
              slidesPerView={3}
              spaceBetween={8}
              // @ts-ignore
              breakpoints={{
                '600': {
                  slidesPerView: 1,
                  spaceBetween: 16,
                },
                '900': {
                  slidesPerView: 2,
                  spaceBetween: 16,
                },
                '1200': {
                  slidesPerView: 3,
                  spaceBetween: 16,
                },
              }}
              slides={progressData?.map(item => {
                return (
                  <ProgressCard
                    key={item.id}
                    id={item.id}
                    text={item.textProg}
                    tags={item.tagsProg}
                    files={item.filesProg}
                    creatorID={item.createdByProg}
                    firstName={item.creatorFirstNameProg}
                    middleName={item.creatorMiddleNameProg}
                    lastName={item.creatorLastNameProg}
                    avatarImage=''
                    dateTime={item.dateTimeProg}
                    lines={7}
                    hideText={true}
                    duration={item.durationProg}
                    cardHeight={230}
                  />
                )
              })}
            />
          </div>
        ) : (
          <EmptyContent text='Geen voortgang gevonden, voeg voortgang bij deelnemer toe.' />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              {hasClaimLevel(profile, { d: 1 }) && (
                <Grid item xs={12}>
                  <WidgetHeader
                    title='Documenten'
                    icon={Description}
                    path={`/locatie/${locationID}/deelnemers/${participantID}/documenten`}
                  />
                  {documentRequiredTypes?.length > 0 ? (
                    <Grid container spacing={1}>
                      {documentRequiredTypes.map(item => {
                        return (
                          <Grid item xs={12} sm={6} key={item.idDoty}>
                            <DocumentRequiredCard documentRequiredType={item} />
                          </Grid>
                        )
                      })}
                    </Grid>
                  ) : (
                    <EmptyContent text='Geen verplichte documenten gevonden, maak een verplicht document aan in de applicatie instellingen.' />
                  )}
                </Grid>
              )}
              {hasClaimLevel(profile, { si: 1 }) && (
                <SickReportingWidget
                  person={person}
                  participantID={participantID}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <TracksWidget participantID={participantID} />
            </Grid>
            {settings.tracks.enableTimeRegistration && (
              <TimeRegistrationWidget trackData={tracksData} disableEditing />
            )}
          </Grid>
        </Grid>
      </>
    </PageContainer>
  )
}

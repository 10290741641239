import React from 'react'
import { columns, columnsMobile } from './DataGrid/productColumns'
import useMongo from 'hooks/useMongo'
import { useHistory } from 'react-router'
import { DataGridNew } from 'app/components/DataGridNew'

export const ProductsList = () => {
  const { useQueryDocs, useGetDocs } = useMongo()
  const history = useHistory()

  const getCategoryIDs = docsData => {
    if (docsData) {
      const emptyArray: any = []
      Object.entries(docsData)?.forEach(([_, value]: any) => {
        value?.productCategories?.forEach(i => {
          if (!emptyArray.find(id => id === i)) {
            emptyArray?.push(i)
          }
        })
      })
      return emptyArray
    }
  }

  const { docsData } = useQueryDocs({
    collection: 'products',
    array: true,
  })

  useGetDocs({
    collection: 'productCategories',
    docIDs: docsData ? getCategoryIDs(docsData) : [],
    array: true,
  })

  return (
    <div style={{ width: '100%' }}>
      <DataGridNew
        name='products'
        rows={docsData || []}
        columns={columns}
        columnsMobile={columnsMobile}
        hideFooterSelectedRowCount={true}
        hideFooterRowCount={true}
        isEditable={true}
        localeText={{ noRowsLabel: 'Er zijn nog geen producten toegevoegd' }}
        onRowClick={params => {
          history.push(`/producten/${params.row._id}/formulier`)
        }}
      />
    </div>
  )
}

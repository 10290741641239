import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'hooks/useSelector'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { format } from 'date-fns'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { Person as PersonIcon } from '@mui/icons-material'

import { TimeRegistrationWidget } from '../TimeRegistration/TimeRegistrationWidget'
import { PageContainer, convertTimestampDate } from '@fivano/core'
import { WidgetHeader } from 'app/components/WidgetHeader'
import { TrackCard } from 'app/legacy/Tracks/TrackCard'
import { PersonFormModal } from 'app/legacy/PersonForm/PersonFormModal'
import {
  formSettings,
  initialParticipantValues,
} from '../Participants/ParticipantFormSettings'
import { Person } from 'types'
import { personFullName } from 'model/person'
import { TrackType } from 'types/Track'

export const ParticipantHome = () => {
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)

  useFirestoreConnect([
    {
      collectionGroup: 'tracks',
      limit: 50,
      where: ['personIDTrack', '==', profile.uid],
      storeAs: 'participant_track',
    },
  ])

  const trackData: TrackType[] = useSelector(
    state => state.firestore.ordered.participant_track,
  )
  const getPerson = (locationID, personID) => {
    firestore.get({
      doc: `locations/${locationID}/persons/${personID}`,
      storeAs: 'participantDetails',
    })
  }
  const locationID = profile?.locationIDPers || trackData?.[0]?.locationIDTrack
  useEffect(() => {
    if (locationID) {
      getPerson(locationID, profile.uid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackData])

  const p: Person = useSelector(
    state => state.firestore.ordered.participantDetails?.[0],
  )

  const [participantModal, setParticipantModal] = useState(false)
  const [defaultFormValues, setDefaultFormValues] = useState<any>(
    initialParticipantValues,
  )
  const handlePersonEdit = personData => {
    setDefaultFormValues(personData)
    setParticipantModal(true)
  }
  const handleCloseParticipant = () => {
    getPerson(locationID, profile.uid)
    setDefaultFormValues(initialParticipantValues)
    setParticipantModal(false)
  }
  const personInfo = [
    { value: p?.firstNamePers, label: 'Voornaam' },
    {
      value: `${p?.middleNamePers} ${p?.lastNamePers}`,
      label: 'Achternaam:',
    },
    { value: p?.initialsPers, label: 'Initialen' },
    { value: p?.givenNamesPers, label: 'Voornamen' },
    { value: p?.birthNamePers, label: 'Geboortenamen' },
    {
      label: 'Email',
      value: p?.emailPers,
    },
    {
      label: 'Telefoon',
      value: p?.phoneLinePers,
    },
    {
      label: 'Telefoon',
      value: p?.phoneMobilePers,
    },
    {
      label: 'Adres',
      value: p?.addressZipPers
        ? `${p?.addressStreetPers ? p?.addressStreetPers : ''} ${
            p?.addressNumberPers ? p?.addressNumberPers : ''
          }${p?.addressAdditionPers ? p?.addressAdditionPers : ''}${
            p?.addressZipPers ? `,  ${p?.addressZipPers}` : ''
          } ${p?.addressCityPers ? p?.addressCityPers : ''}`
        : '',
    },
    { value: p?.genderPers?.label, label: 'Geslacht' },
    { value: p?.educationPers, label: 'School en opleiding' },
    { value: p?.civilStatus?.label, label: 'Burgelijke staat' },
    { value: p?.numberChildsPers, label: 'Kinderen' },
    { value: p?.socialSecurityNumberPers, label: 'BSN nummer' },
    { value: p?.identificationNumberPers, label: 'Legitimatie nummer' },
    {
      value:
        p?.identificationValidUntilPers &&
        format(
          convertTimestampDate(p?.identificationValidUntilPers),
          'dd-MM-yyyy',
        ),

      label: 'Legitimatie geldig tot',
    },
    { value: p?.civilStatus?.label, label: 'Burgelijke staat' },
    { value: p?.dossierNumberPers, label: 'Dossiernummer' },
    { value: p?.ibanNumberPers, label: 'IBAN nummer' },
    {
      value: p?.ibanNamePers,
      label: 'IBAN ten name van',
    },
  ]
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <PersonFormModal
        title='Deelnemer'
        open={participantModal}
        onClose={handleCloseParticipant}
        formSettings={formSettings}
        defaultValues={defaultFormValues}
        personTypePers='participant'
        onAddSuccess={id => {
          handleCloseParticipant()
        }}
        onUpdateSuccess={id => {
          handleCloseParticipant()
        }}
      />

      <PageContainer maxWidth='lg'>
        <Box p={2} />
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Paper>
              <Box p={2}>
                <Typography variant='h6' gutterBottom>
                  {`Welkom ${personFullName(profile)}`}
                </Typography>
                <Typography gutterBottom>
                  Via jouw FC Noaber account kan je de uren bijhouden van jouw
                  opdracht en jouw persoongegevens bewerken.
                </Typography>
              </Box>
            </Paper>

            <TimeRegistrationWidget trackData={trackData} />
            {trackData?.length > 0 && (
              <>
                <WidgetHeader icon={PersonIcon} title='Jouw opdracht' />
                {trackData.map(track => (
                  <TrackCard key={track.id} trackData={track} />
                ))}
              </>
            )}
            <WidgetHeader
              icon={PersonIcon}
              title='Jouw gegevens'
              {...(p !== undefined && {
                buttonLabel: 'Bewerken',
                onButtonClick: () => handlePersonEdit(p),
              })}
            />
            <Paper>
              <Box p={2}>
                {p ? (
                  personInfo.map(
                    (item, index) =>
                      item.value && (
                        <Grid item key={index}>
                          <Typography
                            variant='caption'
                            color='textSecondary'
                            display='block'
                          >
                            {item.label}
                          </Typography>
                          <Typography variant='body1' gutterBottom>
                            {item.value}
                          </Typography>
                        </Grid>
                      ),
                  )
                ) : (
                  <Typography>Gegevens laden</Typography>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </PageContainer>
    </>
  )
}

import React, { useState } from 'react'
import {
  Paper,
  Button,
  Box,
  Typography,
  Divider,
  Grid,
  Menu,
  Chip,
  MenuItem,
  styled,
} from '@mui/material'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { BoardCard } from './BoardCard'
import { BoardCardView } from './BoardCardView'
import { BoardCardForm } from './BoardCardForm'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Modal } from '@fivano/core'
import { PhaseProps } from './BoardTypes'

const DivStyled = styled('div')(() => ({
  display: 'inline-block',
  margin: '0 4px',
  verticalAlign: 'top',
}))

const PaperStyled = styled(Paper)(({ theme }) => ({
  display: 'flex',
  marginBottom: '20px',
  flexDirection: 'column',
  width: 320,
  [theme.breakpoints.down('sm')]: {
    width: 300,
  },
}))

const TitleGridStyled = styled(Grid)(() => ({
  padding: '8px 0px 4px 16px',
  width: 'calc(100% - 48px)',
}))

const IconGridStyled = styled(Grid)(() => ({
  padding: '8px 0px 4px 0px',
  width: 48,
}))

const DropAreaDivStyled = styled('div')(({ theme }) => ({
  minHeight: 80,
  flexGrow: 1,
  overflowY: 'unset',
  paddingTop: theme.spacing(-1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}))

const ModalStyled = styled(Modal)(({ theme }) => ({
  '& .MuiDialog-paperScrollPaper': {
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'unset',
    },
  },
}))

export const Phase = ({
  moveCard,
  boardID,
  phaseData,
  openEdit,
}: PhaseProps) => {
  const [boardCardView, setBoardCardView] = useState<{
    boardCardID?: string
    open: boolean
  }>({
    boardCardID: undefined,
    open: false,
  })
  const [openBoardCardForm, setOpenBoardCardForm] = useState(false)
  const [optionsAnchor, setOptionsAnchor] = useState(null)

  const openOptionsMenu = event => {
    setOptionsAnchor(event.currentTarget)
  }

  const closeOptionsMenu = () => {
    setOptionsAnchor(null)
  }

  return (
    <DivStyled>
      {boardCardView.open && (
        <ModalStyled
          open
          maxWidth='md'
          onClose={() => {
            setBoardCardView({
              boardCardID: undefined,
              open: false,
            })
          }}
        >
          <BoardCardView
            moveCard={moveCard}
            boardID={boardID}
            onClose={() => {
              setBoardCardView({
                boardCardID: undefined,
                open: false,
              })
            }}
            phaseID={phaseData.phaseID}
            boardCardID={boardCardView.boardCardID}
          />
        </ModalStyled>
      )}
      {openBoardCardForm && (
        <BoardCardForm
          moveCard={moveCard}
          boardID={boardID}
          phaseID={phaseData.phaseID}
          editingDocID={undefined}
          onCloseForm={() => {
            setOpenBoardCardForm(false)
          }}
          handleOpenBoardCard={id => {
            setBoardCardView({ boardCardID: id, open: true })
          }}
        />
      )}
      <PaperStyled>
        <Grid container>
          <TitleGridStyled item>
            <Typography noWrap variant='h6'>
              {phaseData.namePhase}
            </Typography>
            <Typography
              gutterBottom
              noWrap
              color='textSecondary'
              variant='body2'
            >
              {phaseData.descriptionPhase}
            </Typography>
            {phaseData.archivedPhase && (
              <Chip label='Gearchiveerd' size='small' />
            )}
          </TitleGridStyled>
          <IconGridStyled item>
            <IconButton
              ref={optionsAnchor}
              label='Opties'
              aria-haspopup='true'
              onClick={openOptionsMenu}
              size='large'
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              anchorEl={optionsAnchor}
              open={Boolean(optionsAnchor)}
              onClose={closeOptionsMenu}
            >
              <MenuItem
                onClick={() => {
                  closeOptionsMenu()
                  openEdit()
                }}
              >
                Bewerken
              </MenuItem>
            </Menu>
          </IconGridStyled>
        </Grid>
        <Divider />
        <Box>
          <Droppable droppableId={phaseData.phaseID} type='card'>
            {provided => (
              <DropAreaDivStyled ref={provided.innerRef}>
                {phaseData.boardCardIDs?.map((cardID, index) => (
                  <Draggable draggableId={cardID} index={index} key={cardID}>
                    {(provided, snapshot) => (
                      <BoardCard
                        boardID={boardID}
                        boardCardID={cardID}
                        onClick={() => {
                          setBoardCardView({
                            boardCardID: cardID,
                            open: true,
                          })
                        }}
                        dragging={snapshot.isDragging}
                        key={cardID}
                        ref={provided.innerRef}
                        style={{ ...provided.draggableProps.style }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </DropAreaDivStyled>
            )}
          </Droppable>
        </Box>
        <Divider />
        <Box p={2} display='flex' justifyContent='center'>
          <Button
            disabled={phaseData.archivedPhase}
            onClick={() => {
              setOpenBoardCardForm(true)
            }}
          >
            Item toevoegen
          </Button>
        </Box>
      </PaperStyled>
    </DivStyled>
  )
}

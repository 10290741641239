import React from 'react'
import { Box, Grid, Typography, styled } from '@mui/material'

import { TrackBlock } from './TrackBlock'

import { format } from 'date-fns'
import { nl } from 'date-fns/locale'
import { WeekNavigationType, FormBlockType } from './types'

const ErrorOverlay = styled('div')(() => ({
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  position: 'absolute',
  zIndex: 2,
}))

const TrackBlockContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
}))

const GridItemTotals = styled(Grid)(() => ({
  padding: '0px 14px',
}))

export const FormBlock = ({
  tracks,
  timeDocs,
  selectedWeek,
  showWeekend,
  showSkeleton,
  onHandleExpand,
  onCreateDoc,
  onUpdateDoc,
  onOpenModal,
  onHandleErrorInput,
  inputError,
  viewportLG,
}: FormBlockType) => {
  return (
    <>
      {viewportLG && (
        <FormBlockHeader
          selectedWeek={selectedWeek}
          showWeekend={showWeekend}
        />
      )}
      {tracks?.map(track => {
        const docs = timeDocs.filter(doc => doc.trackID === track.id)
        return (
          <TrackBlockContainer key={track.id}>
            {inputError && <ErrorOverlay />}
            <TrackBlock
              track={track}
              timeDocs={docs}
              selectedWeek={selectedWeek}
              showWeekend={showWeekend}
              showSkeleton={!!showSkeleton?.find(item => item === track.id)}
              onHandleExpand={onHandleExpand}
              onCreateDoc={onCreateDoc}
              onUpdateDoc={onUpdateDoc}
              onOpenModal={onOpenModal}
              onHandleErrorInput={onHandleErrorInput}
              inputError={inputError}
              viewportLG={viewportLG}
            />
          </TrackBlockContainer>
        )
      })}
    </>
  )
}

const FormBlockHeader = ({ selectedWeek, showWeekend }: WeekNavigationType) => {
  const showDays = showWeekend ? 7 : 5
  return (
    <Grid container>
      <Grid item lg={showWeekend ? 4 : 6}></Grid>
      {selectedWeek.map(
        (date, index) =>
          index < showDays && (
            <Grid key={format(date, 'dd-MM-yyyy')} item xs={1}>
              <Box pl='14px'>
                <Typography variant='body1'>
                  {format(date, 'iii', { locale: nl })}
                </Typography>
                <Typography variant='body1' color='textSecondary'>
                  {format(date, 'dd MMM', { locale: nl })}
                </Typography>
              </Box>
            </Grid>
          ),
      )}
      <GridItemTotals display='flex' flexDirection='column-reverse' item xs={1}>
        <Typography color='textSecondary'>Totaal</Typography>
      </GridItemTotals>
    </Grid>
  )
}

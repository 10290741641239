/** hasClaimLevel checks returns true if the signed in uses profile contains
 * all the claims with an equal or higher value inside the containsClaims parameter.
 * When two or more claims are provided in containsClaims parameter, the user profile
 * should match every claims. To use create a seperate check use multiple hasClaimsLevel
 * functions. Examples:
 * hasClaimsLevel({ claims: { a: 5, b: 5 }}, { a: 5 }) // returns true
 * hasClaimsLevel({ claims: { a: 5, b: 5 }}, { a: 5, c: 5 }) //  returns false
 * hasClaimsLevel({ claims: { a: 5, b: 5 }}, { a: 5 }) && hasClaimsLevel({ claims:
 * { a: 5, b: 5 }}, { b: 5 }) // returns true */
export const hasClaimLevel = (profile: any, containsClaims: any) => {
  if (containsClaims) {
    const claimsCheck: boolean[] = []
    Object.entries(containsClaims).forEach(([key, value]: any) => {
      claimsCheck.push(profile?.claims && profile.claims[key] >= value)
    })
    return claimsCheck.every(value => value === true)
  }
}

import React from 'react'
import useMongo from 'hooks/useMongo'
import { courseCompetencesConfig } from '@fivano/models'
import { capitalize, Grid } from '@mui/material'
import { Form, TextField } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { useLocation } from 'react-router-dom'

const docLabel = capitalize(courseCompetencesConfig.labels.single)

export const CourseCompetencesForm = ({
  onClose,
  wrapInside,
  modalMaxWidth,
  editingDocID,
}) => {
  const errorLogger = useErrorLogger()
  const { updateDoc, createDoc, useRefetchDoc } = useMongo()
  const location = useLocation()
  const courseCompentenceID: any = new URLSearchParams(location.search).get(
    'id',
  )
  const createCompetence = async data => {
    return await createDoc({ collection: 'courseCompetences', data })
  }

  const updateCompetence = async data => {
    return await updateDoc({
      collection: 'courseCompetences',
      docID: courseCompentenceID,
      data,
    })
  }

  const { status, docData } = useRefetchDoc({
    collection: 'courseCompetences',
    docID: courseCompentenceID,
  })

  return (
    <Form
      onCloseForm={onClose}
      loading={status === 'loading'}
      wrapInside={wrapInside}
      modalMaxWidth={modalMaxWidth}
      modelConfig={courseCompetencesConfig}
      docLabel={docLabel}
      editingDocID={editingDocID}
      hasDetails={false}
      updateDoc={updateCompetence}
      createDoc={createCompetence}
      docData={docData}
      onError={errorLogger}
      formInputs={({ formObject }) => (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              formObject={formObject}
              name='name'
              label='Naam competentie'
              rules={{ required: 'Verplicht veld' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              formObject={formObject}
              name='description'
              label='Competentie omschrijving'
              rules={{ required: 'Verplicht veld' }}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}

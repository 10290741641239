import { styled, Typography } from '@mui/material'
import { IconButton } from '@fivano/core'
import CloseIcon from '@mui/icons-material/Close'

import PoolIcon from '@mui/icons-material/Pool'
import SchoolIcon from '@mui/icons-material/School'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import CakeIcon from '@mui/icons-material/Cake'
import AddIcon from '@mui/icons-material/Add'
import useMongo from 'hooks/useMongo'

const IconButtonStyled = styled(IconButton)(() => ({
  position: 'absolute',
  right: 0,
  top: 0,
}))

const CardWrapper = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'active' && prop !== 'hover' && prop !== 'placeholder',
})<any>(({ theme, active, hover, placeholder }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 20,
  height: '100%',
  backgroundColor: !placeholder
    ? theme.palette.background.paper
    : theme.palette.grey.A100,
  border: `2px solid ${
    !active ? theme.palette.divider : theme.palette.primary.main
  }`,
  color: active && theme.palette.primary.main,
  borderRadius: 4,
  ...(hover && {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
  '.deleteButton': {
    visibility: 'hidden',
  },
  '&:hover .deleteButton': {
    visibility: 'visible',
  },
}))

const renderIcon = iconName =>
  ({
    pool: <PoolIcon />,
    school: <SchoolIcon />,
    subscription: <PersonOutlineIcon />,
    party: <CakeIcon />,
    add: <AddIcon />,
  }[iconName] || '')

type CategoryCardProps = {
  active?: boolean
  hover?: boolean
  data?: any
  placeholder?: boolean
  onClick?: () => void
  onDelete?: (id: any) => void
}

export const CategoryCard = ({
  active = false,
  hover = false,
  data,
  placeholder = false,
  onClick,
  onDelete,
}: CategoryCardProps) => {
  const { useGetDoc } = useMongo()

  const doc = useGetDoc({ collection: 'productCategories', docID: data?.id })

  return (
    <CardWrapper
      hover={hover}
      active={active}
      placeholder={placeholder}
      {...(onClick && { onClick: onClick })}
    >
      {onDelete && (
        <IconButtonStyled
          label='verwijderen'
          size='small'
          className='deleteButton'
          onClick={e => {
            e.stopPropagation()
            onDelete(data)
          }}
        >
          <CloseIcon />
        </IconButtonStyled>
      )}
      <div style={{ margin: 'auto 0', textAlign: 'center' }}>
        {doc?.icon && renderIcon(data.icon)}
        <Typography variant='h6'>{doc?.name}</Typography>
      </div>
    </CardWrapper>
  )
}

import { GridColDef } from '@mui/x-data-grid-pro'
import { format } from 'date-fns'

export const categoryColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Naam',
    width: 400,
    disableColumnMenu: true,
  },
  {
    field: 'description',
    headerName: 'Omschrijving',
    width: 400,
    disableColumnMenu: true,
  },
  {
    field: 'createdAt',
    headerName: 'Aangemaakt op',
    disableColumnMenu: true,
    width: 300,
    valueFormatter: (params: any) =>
      format(new Date(params.value), 'dd-MM-yyyy'),
  },
]

export const categoryColumnsMobile: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Product',
    disableColumnMenu: true,
  },
]

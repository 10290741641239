import { useSelector } from 'hooks/useSelector'
import { useSnackbar } from '@fivano/core'

export type RequestParams = {
  route: 'crud' | string
  requestBody?: any
  onSuccess?: any
  handleError?: boolean
}

const apiRequest = ({ idToken, route, requestBody }) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:4001'}/${route}`,
    {
      method: 'POST',
      headers: {
        'id-token': idToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    },
  ).then(response => {
    if (response.ok) {
      return response.json()
    } else {
      if (response.status === 404) {
        throw response.statusText
      } else {
        return response.json().then(error => {
          throw error
        })
      }
    }
  })
}

export const useApiRequest = () => {
  const { enqueueSnackbar } = useSnackbar()
  const idToken = useSelector(
    state => state.firebase?.auth?.stsTokenManager?.accessToken,
  )
  const makeRequest = async ({
    route,
    requestBody,
    onSuccess,
    handleError = false,
  }: RequestParams) => {
    return await apiRequest({ idToken, route, requestBody })
      .then(res => {
        onSuccess && onSuccess(res)
        return res
      })
      .catch(error => {
        if (handleError) {
          enqueueSnackbar(
            error !== undefined ? error.message : `Er is iets fout gegaan!`,
            {
              variant: 'error',
            },
          )
        } else {
          throw error
        }
      })
  }

  return makeRequest
}

import { useHistory, useParams } from 'react-router-dom'
import { RouteTypes } from 'types/RouteTypes'
import { PersonForm } from '../Persons/PersonForm'
import { personRoutes } from './personRoutes'

export const PersonFormWrapper = () => {
  const { personID } = useParams<RouteTypes>()
  const history = useHistory()
  return (
    <PersonForm
      personType='persoon'
      docTitle='Persoon'
      onCloseForm={() => history.push(personRoutes.personList.path)}
      editingDocID={personID === 'toevoegen' ? false : personID}
    />
  )
}

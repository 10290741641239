import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Grid, Box } from '@mui/material'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'

import DialogContent from '@mui/material/DialogContent'
import {
  TextField,
  RadioGroup,
  DatePicker,
  convertTimestampDate,
  AutoSelect,
  AvatarPicker,
} from '@fivano/core'

import CreditCardIcon from '@mui/icons-material/CreditCard'
import GenderIcon from '@mui/icons-material/Wc'
import IdentificationIcon from 'app/icons/Identification'
import PlaceIcon from '@mui/icons-material/Place'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import MaritalStatusIcon from 'app/icons/MaritalStatusIcon'

import { Person } from '../../../types/Person'
import { formChanged } from 'utils/formChanged'
import { OLDFormActions } from 'app/components/Form'
import { useSnackbar } from '@fivano/core'

import LocationIcon from 'app/icons/LocationIcon'
import { createNotification } from 'utils/createNotification'
import { personFullName } from 'model/person'
import { format } from 'date-fns'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { AccountBalance } from '@mui/icons-material'
import { useDataStandards } from 'hooks/useDataStandards'
import { AutoComplete } from 'app/components/AutoComplete'

type PersonFormProps = {
  title: string
  personTypePers: string
  defaultValues: any
  extraData?: any
  formSettings: any
  onClose: any
  onCloseModal: any
  onLoading: any
  onAddSuccess?: any
  onUpdateSuccess?: any
  onSubmitOverwrite?: any
  onHandleFormDirty: any
  onEditForm: any
  disableModal?: boolean
  personID?: string
}
export const PersonForm = ({
  title,
  personTypePers,
  defaultValues,
  extraData,
  formSettings,
  onClose,
  onCloseModal,
  onLoading,
  onAddSuccess,
  onUpdateSuccess,
  onSubmitOverwrite,
  onHandleFormDirty = false,
  onEditForm,
  disableModal,
  personID,
}: PersonFormProps) => {
  const [showAllFields, setShowAllFields] = useState(true)
  const profile = useSelector(state => state.firebase.profile)
  const firestore = useFirestore()
  const firebase = useFirebase()
  const { enqueueSnackbar } = useSnackbar()
  const createDataStandards = useDataStandards()
  const errorLogger = useErrorLogger()
  // react-hook-form
  const formObject = useForm({
    defaultValues: defaultValues,
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    formState: { dirtyFields },
  } = formObject

  // ==== CHECK IF FORM HAS DIRTY FIELDS ==== //
  const formChangedValue = formChanged(dirtyFields)

  useEffect(() => {
    onHandleFormDirty(formChanged(dirtyFields))
  }, [dirtyFields, onHandleFormDirty, formChangedValue])

  // ==== PERSON FORM ADD AND EDIT LOGIC ==== //
  const editForm = Boolean(defaultValues?.id)

  useEffect(() => {
    onEditForm(editForm)
  }, [editForm, onEditForm])

  // set initial person location
  useEffect(() => {
    if (
      profile?.locations?.length === 1 &&
      !editForm &&
      formSettings?.locationPers?.defaultValue === undefined
    ) {
      setValue('locationPers', profile.locations[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  const uploadAvatar = async (personID, avatarBlob, data) => {
    const namedImage = new File([avatarBlob], personID, {
      type: 'image/png',
    })
    return firebase
      .uploadFile(
        `/locations/publicLocation/persons/${personID}/avatar`,
        namedImage,
        undefined,
        {
          metadata: {
            customMetadata: {
              emulatorPassword:
                process.env.REACT_APP_EMULATOR_PASSWORD ||
                'NoEmulatorPasswordSet',
            },
          },
        },
      )
      .then(res => {
        enqueueSnackbar('Profiel foto succesvol geüpload.', {
          variant: 'success',
        })
        onLoading('')
        onCloseModal()
        onAddSuccess(personID, data)
      })
      .catch(error => {
        errorLogger({
          error,
          snackbarMessage: `Profiel foto uploaden mislukt. ${error}`,
        })

        onLoading('')
      })
  }

  const updatePerson = data => {
    if (data.locationIDPers === undefined) {
      data.locationIDPers = 'publicLocation'
    }
    onLoading('Bezig met bewerken')
    firestore
      .update(
        `/locations/${
          defaultValues.locationIDPers
            ? defaultValues.locationIDPers
            : data.locationIDPers
        }/persons/${data.personIDPers}`,
        data,
      )
      .then(() => {
        enqueueSnackbar('Deelnemer succesvol bewerkt.', {
          variant: 'success',
        })
        createNotification(
          firestore,
          data.personIDPers,
          `${personFullName(data)} gewijzigd`,
          `Er zijn gegevens bij ${personFullName(data)} gewijzigd`,
          'participant',
          `/locatie/${
            defaultValues.locationIDPers
              ? defaultValues.locationIDPers
              : data.locationIDPers
          }/deelnemers/${data.personIDPers}`,
        )
        onUpdateSuccess(data.personIDPers, data)
        onLoading('')
        onCloseModal()
      })
      .catch(error => {
        errorLogger({
          error,
          snackbarMessage: `Deelnemer bewerken mislukt. ${error}`,
        })

        onLoading('')
      })
  }

  const handleFormSubmit = (data: Person) => {
    const avatarBlob: any = data.avatarImagePers
    data.avatarImagePers =
      avatarBlob instanceof Blob || defaultValues.avatarImagePers === true
    const dataStandards = createDataStandards({
      data,
      dataName: 'Pers',
      editForm: editForm,
      keywords: [data.firstNamePers, data.middleNamePers, data.lastNamePers],
    })
    if (
      data.locationPers !== undefined &&
      data.locationPers !== 'publicLocation'
    ) {
      data.locationIDPers = data.locationPers.value
    } else {
      data.locationIDPers = 'publicLocation'
    }
    data = { ...data, ...dataStandards, ...extraData }
    if (onSubmitOverwrite !== undefined) {
      onSubmitOverwrite(data)
    } else {
      // regular build in person add or update to persons collection
      if (editForm) {
        // Person edit data
        data.personIDPers = defaultValues.id
        if (avatarBlob instanceof Blob) {
          onLoading('Profiel foto uploaden')
          uploadAvatar(data.personIDPers, avatarBlob, data).then(() =>
            updatePerson(data),
          )
        } else {
          updatePerson(data)
        }
      } else {
        // Person add new
        onLoading('Bezig met toevoegen')
        data.archivedPers = false
        data.personTypePers = personTypePers

        data.locationIDPers = data.locationPers.value
        data.locationsPers = [data.locationPers]
        if (personID) {
          firestore
            .set(
              `/locations/${data.locationPers.value}/persons/${personID}`,
              data,
            )
            .then(response => {
              enqueueSnackbar('Deelnemer succesvol toegevoegd.', {
                variant: 'success',
              })
              createNotification(
                firestore,
                data.locationIDPers,
                `${data.firstNamePers} ${data.middleNamePers} ${data.lastNamePers} toegevoegd`,
                `Deelnemer ${data.firstNamePers} ${data.middleNamePers} ${data.lastNamePers} is toegevoegd`,
                'participant',
                `/locatie/${data.locationPers.value}/deelnemers/${personID}`,
              )
              if (avatarBlob instanceof Blob) {
                onLoading('Profiel foto uploaden')
                uploadAvatar(personID, avatarBlob, data)
              } else {
                onLoading('')
                onCloseModal()
                onAddSuccess(personID, data)
              }
            })
            .catch(error => {
              errorLogger({
                error,
                snackbarMessage: `Deelnemer toevoegen mislukt. ${error}`,
              })

              onLoading('')
            })
        } else {
          firestore
            .add(`/locations/${data.locationPers.value}/persons`, data)
            .then(response => {
              enqueueSnackbar('Deelnemer succesvol toegevoegd.', {
                variant: 'success',
              })
              createNotification(
                firestore,
                data.locationIDPers,
                `${data.firstNamePers} ${data.middleNamePers} ${data.lastNamePers} toegevoegd`,
                `Deelnemer ${data.firstNamePers} ${data.middleNamePers} ${data.lastNamePers} is toegevoegd`,
                'participant',
                `/locatie/${data.locationPers.value}/deelnemers/${response.id}`,
              )
              if (avatarBlob instanceof Blob) {
                onLoading('Profiel foto uploaden')
                uploadAvatar(response.id, avatarBlob, data)
              } else {
                onLoading('')
                onCloseModal()
                onAddSuccess(response.id, data)
              }
            })
            .catch(error => {
              errorLogger({
                error,
                snackbarMessage: `Deelnemer toevoegen mislukt. ${error}`,
              })

              onLoading('')
            })
        }
      }
    }
  }

  // ==== FORM ACTION BUTTONS ==== //
  const handleShowDetails = showFormDetails => {
    setShowAllFields(showFormDetails)
  }

  const handleClose = () => {
    onCloseModal()
  }

  return (
    <>
      <DialogContent dividers sx={{ padding: '16px 3px 16px 12px' }}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Grid container>
            <Grid item sx={{ width: '60px', color: 'palette.grey[500]' }}>
              <AvatarPicker
                name='avatarImagePers'
                previewSize={48}
                size={256}
                control={control}
                imageURL={
                  defaultValues?.avatarImagePers &&
                  defaultValues.updatedAtPers &&
                  `https://firebasestorage.googleapis.com/v0/b/${
                    process.env.REACT_APP_FB_PROJECT_ID
                  }.appspot.com/o/locations%2FpublicLocation%2Fpersons%2F${
                    defaultValues.id
                  }%2Favatar%2F${defaultValues.id}?alt=media&date=${format(
                    convertTimestampDate(defaultValues.updatedAtPers),
                    'ddMMyyyyhhmmss',
                  )}`
                }
              />
            </Grid>
            <Grid
              item
              sx={{ width: 'calc(100% - 64px)' }}
              container
              spacing={1}
            >
              <Grid item xs={12} md={9}>
                <TextField
                  autoFocus
                  rules={{ required: 'Voer een voornaam in' }}
                  {...(formSettings.firstNamePersProps && {
                    ...formSettings.firstNamePersProps,
                  })}
                  label='Voornaam'
                  name='firstNamePers'
                  formObject={formObject}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  {...(formSettings.middleNamePersProps && {
                    ...formSettings.middleNamePersProps,
                  })}
                  label='Voorvoegsel'
                  name='middleNamePers'
                  formObject={formObject}
                />
              </Grid>
              <Grid item xs={12} md={formSettings.initialsPers.hide ? 12 : 9}>
                <TextField
                  {...(formSettings.lastNamePersProps && {
                    ...formSettings.lastNamePersProps,
                  })}
                  label='Achternaam'
                  name='lastNamePers'
                  rules={{ required: 'Voer een achternaam in' }}
                  formObject={formObject}
                />
              </Grid>
              {!formSettings.initialsPers.hide && (
                <Grid
                  item
                  xs={12}
                  md={3}
                  {...(formSettings.initialsPers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Initialen'
                    name='initialsPers'
                    rules={{ required: 'Voer initialen in' }}
                    formObject={formObject}
                  />
                </Grid>
              )}

              {!formSettings.relationPositionPers.hide && (
                <Grid
                  item
                  xs={12}
                  {...(formSettings.relationPositionPers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Functie'
                    name='relationPositionPers'
                    formObject={formObject}
                  />
                </Grid>
              )}
              {!formSettings.givenNamesPers.hide && (
                <Grid
                  item
                  xs={12}
                  md={9}
                  {...(formSettings.givenNamesPers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Voornamen'
                    name='givenNamesPers'
                    formObject={formObject}
                  />
                </Grid>
              )}
              {!formSettings.birthNamePers?.hide && (
                <Grid
                  item
                  xs={12}
                  md={9}
                  {...(formSettings.birthNamePers?.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Geboortenaam'
                    name='birthNamePers'
                    formObject={formObject}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {!formSettings?.locationPers?.hide && (
            <Box
              {...(formSettings.locationPers.toggleVisibility && {
                sx: { display: showAllFields ? 'block' : 'none' },
              })}
            >
              <Grid container sx={{ marginTop: '16px' }}>
                <Grid item sx={{ width: '60px', color: 'palette.grey[500]' }}>
                  <LocationIcon
                    sx={{ margin: '8px 0px 0px 12px' }}
                    color='inherit'
                  />
                </Grid>
                <Grid
                  item
                  sx={{ width: 'calc(100% - 64px)' }}
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                    {...(formSettings?.locationPers?.toggleVisibility && {
                      sx: { display: showAllFields ? 'block' : 'none' },
                    })}
                  >
                    {
                      /** When someone has access to all locations enable assigning all locations */
                      profile?.claims?.al >= 1 ? (
                        <AutoComplete
                          name='locationPers'
                          label={`Locatie ${title.toLowerCase()}`}
                          formObject={formObject}
                          disabled={
                            formSettings?.locationPers?.disabled ||
                            profile?.locations?.length === 0
                              ? true
                              : editForm
                          }
                          collection='locations'
                          labelKeys={['nameLoca']}
                          searchKey='keywordsLoca'
                          error={errors.locationPers !== undefined}
                          required={
                            formSettings.locationPers.required !== undefined
                              ? formSettings.locationPers.required
                              : true
                          }
                          {...(formSettings.locationPers.rules && {
                            rules: formSettings.locationPers.rules,
                          })}
                          helperText={
                            (errors.locationPers &&
                              (errors.locationPers?.type === 'required'
                                ? 'Kies een locatie voor de gegevens van deze persoon'
                                : 'Locatie voor deze persoon')) ||
                            profile?.locations?.length === 0
                              ? 'Uw account heeft geen locaties toegewezen. Vraag uw applicatie beheerder om locaties aan uw account toe te wijzen.'
                              : ''
                          }
                        />
                      ) : (
                        <AutoSelect
                          name='locationPers'
                          label={`Locatie ${title.toLowerCase()}`}
                          formObject={formObject}
                          disabled={
                            formSettings?.locationPers?.disabled ||
                            profile?.locations?.length === 0
                              ? true
                              : editForm
                          }
                          required={
                            formSettings.locationPers.required !== undefined
                              ? formSettings.locationPers.required
                              : true
                          }
                          {...(formSettings.locationPers.rules && {
                            rules: formSettings.locationPers.rules,
                          })}
                          {...(formSettings?.locationPers?.defaultValue && {
                            defaultValue:
                              formSettings?.locationPers?.defaultValue,
                          })}
                          // TODO hide archivedLocations
                          options={
                            formSettings?.locationPers?.options
                              ? formSettings.locationPers.options
                              : profile?.locations
                          }
                          disableCloseOnSelect={false}
                          error={errors.locationPers !== undefined}
                          helperText={
                            (errors.locationPers &&
                              (errors.locationPers?.type === 'required'
                                ? 'Kies een locatie voor de gegevens van deze persoon'
                                : 'Locatie voor deze persoon')) ||
                            profile?.locations?.length === 0
                              ? 'Uw account heeft geen locaties toegewezen. Vraag uw applicatie beheerder om locaties aan uw account toe te wijzen.'
                              : ''
                          }
                        />
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
          {!formSettings.birthdatePers.hide &&
            !formSettings.socialSecurityNumberPers.hide && (
              <Grid container sx={{ marginTop: '16px' }}>
                <Grid item sx={{ width: '60px', color: 'palette.grey[500]' }}>
                  <IdentificationIcon
                    sx={{ margin: '8px 0px 0px 12px' }}
                    color='inherit'
                  />
                </Grid>
                <Grid
                  item
                  sx={{ width: 'calc(100% - 64px)' }}
                  container
                  spacing={1}
                >
                  {!formSettings.socialSecurityNumberPers.hide && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      {...(formSettings.socialSecurityNumberPers
                        .toggleVisibility && {
                        sx: { display: showAllFields ? 'block' : 'none' },
                      })}
                    >
                      <TextField
                        label='BSN nummer'
                        name='socialSecurityNumberPers'
                        type='tel'
                        formObject={formObject}
                      />
                    </Grid>
                  )}
                  {!formSettings.birthdatePers.hide && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      {...(formSettings.birthdatePers.toggleVisibility && {
                        sx: { display: showAllFields ? 'block' : 'none' },
                      })}
                    >
                      <DatePicker
                        label='Geboortedatum'
                        name='birthdatePers'
                        formObject={formObject}
                        defaultValue={defaultValues.birthdatePers}
                        required={formSettings.birthdatePers.required}
                        {...formSettings.birthdatePers.datepickerProps}
                        maxDate={new Date()}
                        minDate={new Date('1900-01-01T22:00:00.000Z')}
                        rules={{ required: 'Verplicht veld' }}
                      />
                    </Grid>
                  )}
                  {!formSettings.identificationTypePers.hide && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      {...(formSettings.identificationTypePers
                        .toggleVisibility && {
                        sx: { display: showAllFields ? 'block' : 'none' },
                      })}
                    >
                      <AutoSelect
                        label='Indentificatie document type'
                        name='identificationTypePers'
                        formObject={formObject}
                        options={[
                          { value: 'passport', label: 'Paspoort' },
                          { value: 'identificationCard', label: 'ID kaart' },
                          // { value: 'driversLicense', label: 'Rijbewijs' },
                        ]}
                      />
                    </Grid>
                  )}
                  {!formSettings.identificationNumberPers.hide && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      {...(formSettings.identificationNumberPers
                        .toggleVisibility && {
                        sx: { display: showAllFields ? 'block' : 'none' },
                      })}
                    >
                      <TextField
                        label='Document nummer'
                        name='identificationNumberPers'
                        type='tel'
                        formObject={formObject}
                      />
                    </Grid>
                  )}
                  {!formSettings.identificationValidUntilPers.hide && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      {...(formSettings.identificationValidUntilPers
                        .toggleVisibility && {
                        sx: { display: showAllFields ? 'block' : 'none' },
                      })}
                    >
                      <DatePicker
                        label='Geldig tot'
                        name='identificationValidUntilPers'
                        formObject={formObject}
                        minDate={new Date()}
                        errorTranslations={{
                          minDateError:
                            'Legitimatie geldigheidsdatum moet in de toekomst liggen',
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          {!formSettings.genderPers.hide && (
            <Grid container sx={{ marginTop: '16px' }}>
              <Grid item sx={{ width: '60px', color: 'palette.grey[500]' }}>
                <GenderIcon sx={{ margin: '8px 0px 0px 12px' }} />
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 64px)' }}
                container
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  {...(formSettings.genderPers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <RadioGroup
                    name='genderPers'
                    formObject={formObject}
                    rules={{ required: 'Dit is een verplicht veld' }}
                    row
                    options={formSettings.genderPers.options}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container sx={{ marginTop: '16px' }}>
            <Grid item sx={{ width: '60px', color: 'palette.grey[500]' }}>
              <PlaceIcon sx={{ margin: '8px 0px 0px 12px' }} color='inherit' />
            </Grid>

            <Grid
              item
              sx={{ width: 'calc(100% - 64px)' }}
              container
              spacing={1}
            >
              {!formSettings.addressStreetPers.hide && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  {...(formSettings.addressStreetPers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Straatnaam'
                    name='addressStreetPers'
                    rules={
                      formSettings?.addressStreetPers?.required
                        ? { required: 'Voer een straatnaama in' }
                        : {}
                    }
                    formObject={formObject}
                  />
                </Grid>
              )}
              {!formSettings.addressNumberPers.hide && (
                <Grid
                  item
                  xs={6}
                  md={3}
                  {...(formSettings.addressNumberPers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Nummer'
                    name='addressNumberPers'
                    formObject={formObject}
                    rules={
                      formSettings?.addressNumberPers?.required
                        ? { required: 'Verplicht veld' }
                        : {}
                    }
                  />
                </Grid>
              )}
              {!formSettings.addressAdditionPers.hide && (
                <Grid
                  item
                  xs={6}
                  md={3}
                  {...(formSettings.addressAdditionPers?.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Toevoeging'
                    name='addressAdditionPers'
                    rules={
                      formSettings?.addressAdditionPers?.required
                        ? { required: 'Verplicht veld' }
                        : {}
                    }
                    formObject={formObject}
                  />
                </Grid>
              )}
              {!formSettings.addressZipPers.hide && (
                <Grid
                  item
                  xs={12}
                  md={4}
                  {...(formSettings.addressZipPers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Postcode'
                    name='addressZipPers'
                    formObject={formObject}
                    rules={
                      formSettings?.addressZipPers?.required
                        ? { required: 'Voer een postcode in' }
                        : {}
                    }
                  />
                </Grid>
              )}
              {!formSettings.addressCityPers.hide && (
                <Grid
                  item
                  xs={12}
                  md={8}
                  {...(formSettings.addressCityPers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Plaatsnaam'
                    name='addressCityPers'
                    formObject={formObject}
                    rules={
                      formSettings?.addressCityPers?.required
                        ? { required: 'Voer een plaatsnaam in' }
                        : {}
                    }
                  />
                </Grid>
              )}
              {!formSettings.addressCountryPers.hide && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  {...(formSettings.addressCountryPers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <AutoSelect
                    label='Land'
                    name='addressCountryPers'
                    formObject={formObject}
                    options={[{ value: 'NL', label: 'Nederland' }]}
                    rules={
                      formSettings?.addressCountryPers?.required
                        ? { required: 'Voer een land in' }
                        : {}
                    }
                  />
                </Grid>
              )}
              {!formSettings.addressStatePers.hide && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  {...(formSettings.addressStatePers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Provincie'
                    name='addressStatePers'
                    formObject={formObject}
                    rules={
                      formSettings?.addressStatePers?.required
                        ? { required: 'Voer een provincie in' }
                        : {}
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: '16px' }}>
            <Grid item sx={{ width: '60px', color: 'palette.grey[500]' }}>
              <PhoneIcon sx={{ margin: '8px 0px 0px 12px' }} color='inherit' />
            </Grid>
            <Grid
              item
              sx={{ width: 'calc(100% - 64px)' }}
              container
              spacing={1}
            >
              {!formSettings.phoneMobilePers.hide && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  {...(formSettings.phoneMobilePers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Mobiele telefoon'
                    name='phoneMobilePers'
                    type='tel'
                    formObject={formObject}
                    rules={
                      formSettings?.phoneMobilePers?.required
                        ? { required: 'Voer een mobiel telefoonnummer in' }
                        : {}
                    }
                  />
                </Grid>
              )}

              {!formSettings.phoneLinePers.hide && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  {...(formSettings.phoneLinePers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Vaste telefoon'
                    name='phoneLinePers'
                    type='tel'
                    formObject={formObject}
                    rules={
                      formSettings?.phoneLinePers?.required
                        ? { required: 'Voer een vast telefoonnummer in' }
                        : {}
                    }
                  />
                </Grid>
              )}
              {!formSettings.phoneNoAnswerPers.hide && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  {...(formSettings.phoneNoAnswerPers.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Geen gehoor tel. '
                    name='phoneNoAnswerPers'
                    type='tel'
                    formObject={formObject}
                    rules={
                      formSettings?.phoneNoAnswerPers?.required
                        ? { required: 'Voer een geen gehoor tel. in' }
                        : {}
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: '16px' }}>
            <Grid item sx={{ width: '60px', color: 'palette.grey[500]' }}>
              <EmailIcon sx={{ margin: '8px 0px 0px 12px' }} color='inherit' />
            </Grid>
            <Grid
              item
              sx={{ width: 'calc(100% - 64px)' }}
              container
              spacing={1}
            >
              <Grid item xs={12} md={12}>
                <TextField
                  label='Email'
                  name='emailPers'
                  type='email'
                  formObject={formObject}
                  rules={
                    formSettings?.emailPers?.required
                      ? { required: 'Vul een geldig email adres in' }
                      : {}
                  }
                  {...(formSettings.emailPersProps && {
                    ...formSettings.emailPersProps,
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
          {!formSettings?.educationPers?.hide &&
            process.env.REACT_APP_FB_PROJECT_ID &&
            process.env.REACT_APP_FB_PROJECT_ID.includes('fcnoaber') && (
              <Grid container sx={{ marginTop: '16px' }}>
                <Grid item sx={{ width: '60px', color: 'palette.grey[500]' }}>
                  <AccountBalance
                    sx={{ margin: '8px 0px 0px 12px' }}
                    color='inherit'
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: 'calc(100% - 64px)',
                  }}
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                    {...(formSettings?.educationPers?.toggleVisibility && {
                      sx: { display: showAllFields ? 'block' : 'none' },
                    })}
                  >
                    <TextField
                      label='School en opleiding'
                      helperText={`Middelbare school niveau en profiel plus beroeps of hoger onderwijs opleiding. Bijvoorbeeld HAVO C&M, HBO Verpleegkunde.`}
                      name='educationPers'
                      multiline
                      maxRows={10}
                      rows={3}
                      formObject={formObject}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          {!formSettings?.martialStatusData?.hide && (
            <Grid container sx={{ marginTop: '16px' }}>
              <Grid item sx={{ width: '60px', color: 'palette.grey[500]' }}>
                <MaritalStatusIcon
                  sx={{ margin: '8px 0px 0px 12px' }}
                  color='inherit'
                />
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 64px)' }}
                container
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  md={9}
                  {...(formSettings?.martialStatusData?.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <AutoSelect
                    label='Burgelijke staat'
                    name='civilStatus'
                    formObject={formObject}
                    options={[
                      { value: 'ongehuwd', label: 'Ongehuwd' },
                      { value: 'wettigGehuwd', label: 'Wettig gehuwd' },
                      { value: 'partnerschap', label: 'Partnerschap' },
                      {
                        value: 'verweduwdNaWettigHuwelijk',
                        label: 'Verweduwd na wettig huwelijk',
                      },
                      {
                        value: 'verweduwdNaPartnerschap',
                        label: 'Verweduwd na partnerschap',
                      },
                      {
                        value: 'gescheidenNaWettigHuwelijk',
                        label: 'Gescheiden na wettig huwelijk',
                      },
                      {
                        value: 'gescheidenNaPartnerschap',
                        label: 'Gescheiden na partnerschap',
                      },
                    ]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  {...(formSettings.martialStatusData.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='Kinderen'
                    name='numberChildsPers'
                    type='number'
                    formObject={formObject}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!formSettings.bankData?.hide && (
            <Grid container sx={{ marginTop: '16px' }}>
              <Grid item sx={{ width: '60px', color: 'palette.grey[500]' }}>
                <CreditCardIcon
                  sx={{ margin: '8px 0px 0px 12px' }}
                  color='inherit'
                />
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 64px)' }}
                container
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  {...(formSettings.bankData.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='IBAN nummer'
                    name='ibanNumberPers'
                    formObject={formObject}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  {...(formSettings.bankData.toggleVisibility && {
                    sx: { display: showAllFields ? 'block' : 'none' },
                  })}
                >
                  <TextField
                    label='IBAN ten name van'
                    name='ibanNamePers'
                    formObject={formObject}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!formSettings.dossierNumber?.hide && (
            <Box
              {...(formSettings.dossierNumber.toggleVisibility && {
                sx: { display: showAllFields ? 'block' : 'none' },
              })}
            >
              <Grid container sx={{ marginTop: '16px' }}>
                <Grid
                  item
                  sx={{ width: '60px', color: 'palette.grey[500]' }}
                ></Grid>
                <Grid
                  item
                  sx={{ width: 'calc(100% - 64px)' }}
                  container
                  spacing={1}
                >
                  <Grid item xs={12} md={8}>
                    <TextField
                      label='Dossier nummer'
                      name='dossierNumberPers'
                      formObject={formObject}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </form>
      </DialogContent>
      <OLDFormActions
        onSubmit={handleSubmit(handleFormSubmit)}
        onDetails={handleShowDetails}
        editForm={editForm && personID !== undefined}
        formDirty={!formChanged(dirtyFields)}
        onClose={handleClose}
        disableModal={disableModal}
      />
    </>
  )
}

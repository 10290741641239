import React from 'react'
import { AutoSelect } from '@fivano/core'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { personFullName } from 'model/person'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { UseFormReturn } from 'react-hook-form'

type PersonsSelectorProps = {
  name: string
  formObject: UseFormReturn
  relation: any
  multiple?: boolean
}

export const PersonsSelector = ({
  name,
  relation,
  formObject,
  multiple = true,
}: PersonsSelectorProps) => {
  const locationQuery = useLocationQuery()
  useFirestoreConnect([
    {
      collectionGroup: 'persons',
      orderBy: ['lastNamePers', 'asc'],
      where: [
        ['archivedPers', '==', false],
        ['personTypePers', '==', 'relationPerson'],
        ['relationIDPers', '==', relation?.value || null],
        ...locationQuery('locationIDPers'),
      ],
      limit: 12,
      storeAs: `relations_${relation?.value}_persons`,
    },
  ])

  const optionsCreator = options => {
    const newArray: any = []
    options?.length > 0 &&
      options.forEach(option => {
        newArray.push({
          label: personFullName(option),
          value: option['id'],
        })
      })
    return newArray
  }

  const options = useSelector(({ firestore: { ordered } }) =>
    optionsCreator(ordered[`relations_${relation?.value}_persons`]),
  )

  return (
    <AutoSelect
      name={name}
      formObject={formObject}
      label={
        relation?.label ? relation?.label + ' contact kiezen' : 'Contact kiezen'
      }
      multiple={multiple}
      disabled={!relation?.value}
      size='small'
      options={options}
    />
  )
}

import React from 'react'
import useMongo from 'hooks/useMongo'
import { courseModulesConfig } from '@fivano/models'
import { capitalize } from '@mui/material'
import { Form } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { CourseModulesFormInputs } from './CourseModulesFormInputs'

const docLabel = capitalize(courseModulesConfig.labels.single)

export const CourseModulesForm = ({ onCloseForm, editingDocID }) => {
  const errorLogger = useErrorLogger()
  const { updateDoc, createDoc, useRefetchDoc } = useMongo()
  const createModules = async data => {
    return await createDoc({ collection: 'courseModules', data })
  }

  const updateModules = async data => {
    return await updateDoc({
      collection: 'courseModules',
      docID: editingDocID,
      data,
    })
  }

  const { status, docData } = useRefetchDoc({
    collection: 'courseModules',
    docID: editingDocID,
  })

  return (
    <Form
      onCloseForm={onCloseForm}
      wrapInside='modal'
      modalMaxWidth='sm'
      modelConfig={courseModulesConfig}
      docLabel={docLabel}
      loading={status === 'loading'}
      editingDocID={editingDocID}
      hasDetails={false}
      updateDoc={updateModules}
      createDoc={createModules}
      docData={docData}
      onError={errorLogger}
      formInputs={({ formObject }) => (
        <CourseModulesFormInputs formObject={formObject} />
      )}
    />
  )
}

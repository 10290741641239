import CodeIcon from '@mui/icons-material/Code'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import RoomServiceOutlinedIcon from '@mui/icons-material/RoomServiceOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { songstarterMenu } from 'app/legacy/MusicTracks/songstarterMenu'
import { exampleRoutes } from 'app/router/exampleRoutes'
import { legacyRoutes } from 'app/router/legacyRoutes'
import { menuLayoutType } from '../../router/types'
import { authorizationMenu } from '../Authorization/authorizationMenu'
import { courseTrackingMenu } from '../CourseTracking/courseTrackingMenu'
import { pointOfSaleMenu } from '../PointOfSale/pointOfSaleMenu'
import { personMenu } from '../Persons/personMenu'
import { productsMenu } from '../Products/productMenu'
import ExplicitIcon from '@mui/icons-material/Explicit'

export const menuList = () => {
  const menu: menuLayoutType[] = [
    {
      name: 'Home',
      icon: HomeOutlinedIcon,
      route: legacyRoutes.home,
    },
    {
      name: 'Diensten',
      icon: RoomServiceOutlinedIcon,
      route: legacyRoutes.services,
    },
    ...productsMenu,
    ...pointOfSaleMenu,
    {
      name: 'Development page',
      icon: CodeIcon,
      route: legacyRoutes.development,
    },
    ...courseTrackingMenu,
    ...personMenu,
    ...songstarterMenu,
    { name: 'Example', icon: ExplicitIcon, route: exampleRoutes.exampleList },
    {
      name: 'Instellingen',
      icon: SettingsOutlinedIcon,
      route: legacyRoutes.settings,
      subMenu: [...authorizationMenu],
    },
  ]
  return menu
}

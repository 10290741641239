import { routeObjectType } from 'app/router/types'
import { ServiceForm } from '.'
import { ServicesPage } from './ServicesPage'

export const servicesRoutes: routeObjectType = {
  services: {
    path: '/diensten',
    component: ServicesPage,
    claims: { dev: 1 },
  },
  serviceForm: {
    path: '/diensten/formulier',
    component: ServiceForm,
    claims: { dev: 5 },
  },
  serviceFormNew: {
    path: '/diensten/formulier',
    component: ServiceForm,
    claims: { dev: 5 },
  },
  serviceFormEdit: {
    path: '/diensten/:serviceID/formulier',
    component: ServiceForm,
    claims: { dev: 5 },
  },
}

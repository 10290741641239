import React from 'react'
import { Link as RouteLink } from 'react-router-dom'
import { Typography, Grid, Button, Link, styled } from '@mui/material/'

type WidgetHeaderProps = {
  /**The visible title*/
  title: String
  /**Visible label of the button*/
  buttonLabel?: String
  /**Property that gets set with an Icon if necessary*/
  icon?: any
  /**String of the to-go-to page*/
  path?: String
  /**Function that gets triggered when the button is clicked*/
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>
  /**Possible actions*/
  actions?: React.ReactNode
}
export const WidgetHeader = ({
  title,
  buttonLabel,
  icon,
  path,
  onButtonClick,
  actions,
}: WidgetHeaderProps) => {
  const Icon = icon

  const IconStyled = styled(Icon)(({ theme }) => ({
    color: theme.palette.text.primary,
    margin: '8px 8px 0px 8px',
  }))

  return (
    <Grid
      container
      alignItems='center'
      sx={{ height: '50px', margin: '16px 0px 4px 0px' }}
    >
      <Grid item>{icon ? <IconStyled /> : null}</Grid>
      <Grid item>
        {path ? (
          <Link
            underline='hover'
            component={RouteLink}
            variant='h6'
            to={{ pathname: `${path}` }}
          >
            {title}
          </Link>
        ) : (
          <Typography variant='h6' color='textPrimary'>
            {title}
          </Typography>
        )}
      </Grid>
      <Grid item sx={{ marginLeft: 'auto' }}>
        {buttonLabel && (
          <Button
            {...(onButtonClick && { onClick: onButtonClick })}
            color='primary'
          >
            {buttonLabel}
          </Button>
        )}
      </Grid>
      <Grid item>{actions}</Grid>
    </Grid>
  )
}

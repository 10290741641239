import React, { useState } from 'react'
import { useSelector } from 'hooks/useSelector'
import { useFirestore } from 'react-redux-firebase'
import { Grid } from '@mui/material'
import BusinessIcon from '@mui/icons-material/Business'

import {
  TextField,
  Switch,
  FormRow,
  Form,
  DatePicker,
  AutoSelect,
} from '@fivano/core'
import { RelationsSelector } from './RelationsSelector'
import TitleIcon from '@mui/icons-material/Title'
import { DonutLarge, Event, Subject } from '@mui/icons-material'
import { useDataStandards } from 'hooks/useDataStandards'
import { useErrorLogger } from 'hooks/useErrorLogger'
import {
  BoardCardBuildTypes,
  BoardCardDatastandardTypes,
  BoardCardFormTypes,
  BoardFormtypes,
} from './BoardTypes'

type BoardCardFormPropTypes = {
  moveCard: any
  phaseID: string
  boardID: string
  onCloseForm: any
  editingDocID?: string
  handleOpenBoardCard?: any
}

export const BoardCardForm = ({
  moveCard,
  phaseID,
  boardID,
  onCloseForm,
  editingDocID,
  handleOpenBoardCard,
}: BoardCardFormPropTypes) => {
  const isEditing = !!editingDocID
  const [relationsUpdated, setRelationsUpdated] = useState(false)
  const createDataStandards = useDataStandards()
  const uid = useSelector(({ firebase: { auth } }) => auth.uid)

  const firestore = useFirestore()

  const boardCardData: BoardCardFormTypes = useSelector(
    ({ firestore: { data } }) => {
      if (editingDocID) {
        return data[`boards_${boardID}_boardCards_${editingDocID}`]
      }
      return null
    },
  )

  const boardData: BoardFormtypes = useSelector(
    ({ firestore: { data } }) => data[`boards_${boardID}`],
  )
  const handleMoveCard = data => {
    const source = { index: 0, droppableId: boardCardData.phaseBoca.value }
    const dest = { droppableId: data.phaseBoca.value, index: 0 }
    moveCard(source, dest, editingDocID)
  }

  const updateBoardCard = async data => {
    if (
      boardCardData &&
      data.phaseBoca.value !== boardCardData.phaseBoca.value
    ) {
      handleMoveCard(data)
    }

    await firestore.update(`boards/${boardID}/boardCards/${editingDocID}`, data)
  }

  const createBoardCard = async data => {
    await firestore
      .add(`boards/${boardID}/boardCards`, data)
      .then(async res => {
        const newPhasesBoard: Array<any> = []
        boardData.phasesBoard.forEach(phase => {
          if (phase.phaseID === data.phaseBoca.value) {
            const newCardIDs = phase.boardCardIDs.slice()

            newCardIDs.push(res.id)
            const newPhase = { ...phase, ...{ boardCardIDs: newCardIDs } }
            newPhasesBoard.push(newPhase)
          } else {
            newPhasesBoard.push(phase)
          }
        })
        await firestore.update(`boards/${boardID}`, {
          phasesBoard: newPhasesBoard,
        })
        handleOpenBoardCard(res.id)
      })
  }

  const buildBoardCardData = (data: BoardCardBuildTypes) => {
    const dataStandards: BoardCardDatastandardTypes = createDataStandards({
      data,
      dataName: 'Boca',
      editForm: isEditing,
      uid: uid,
    })

    data.relationsBoca = data.relationsBoca.filter(rel => rel.relation != null)

    const relationIDs: any[] = []
    data.relationsBoca.forEach((rel: any) => {
      relationIDs.push(rel.relation.value)
    })
    data.relationIDs = relationIDs
    return { ...data, ...dataStandards }
  }

  const phaseOptions = boardData.phasesBoard.map(phase => ({
    label: phase.namePhase,
    value: phase.phaseID,
  }))

  const docLabel = 'Item'
  const defaultValues = {
    dateDueBoca: null,
    changeBoca: null,
    expectedAmountBoca: null,
    descriptionBoca: '',
    relationsBoca: [{ relation: undefined, persons: [] }],
    phaseBoca: phaseOptions.find(option => option.value === phaseID),
  }

  const errorLogger = useErrorLogger()

  return (
    <Form
      wrapInside='modal'
      modalMaxWidth='sm'
      hasDetails={true}
      docLabel={docLabel}
      buildDocData={buildBoardCardData}
      updateDoc={updateBoardCard}
      createDoc={createBoardCard}
      editingDocID={editingDocID}
      docData={isEditing ? boardCardData : defaultValues}
      onCloseForm={onCloseForm}
      formUpdated={relationsUpdated}
      onError={errorLogger}
      formInputs={({ formObject, showAllFields }) => (
        <Grid container spacing={1}>
          <FormRow icon={<TitleIcon />}>
            <TextField
              rules={{ required: 'Verplicht veld' }}
              label='Titel'
              name='nameBoca'
              formObject={formObject}
            />
          </FormRow>
          <FormRow icon={<BusinessIcon />}>
            <RelationsSelector
              setRelationsUpdated={setRelationsUpdated}
              boardCardData={boardCardData || defaultValues}
              formObject={formObject}
            />
          </FormRow>
          <FormRow icon={<Subject />}>
            <TextField
              rules={{ required: 'Verplicht veld' }}
              label='Omschrijving'
              name='descriptionBoca'
              rows={4}
              maxRows={20}
              multiline
              formObject={formObject}
            />
          </FormRow>
          <FormRow icon={<DonutLarge />}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  label='€ Verwacht order bedrag'
                  name='expectedAmountBoca'
                  type='number'
                  formObject={formObject}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='% Kans op order'
                  name='chanceBoca'
                  type='number'
                  formObject={formObject}
                />
              </Grid>
            </Grid>
          </FormRow>
          <FormRow icon={<Event />}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <DatePicker
                  label='Fase deadline'
                  name='dateDueBoca'
                  formObject={formObject}
                />
              </Grid>
              <Grid item xs={6}>
                <AutoSelect
                  label='Fase'
                  name='phaseBoca'
                  formObject={formObject}
                  rules={{ required: 'Kies een fase voor dit item' }}
                  options={phaseOptions}
                />
              </Grid>
            </Grid>
          </FormRow>
          <FormRow showAllFields={showAllFields}>
            <Switch
              name='archivedBoca'
              formObject={formObject}
              label={`Item archiveren`}
            />
          </FormRow>
        </Grid>
      )}
    />
  )
}

import lamejs from 'lamejs'

export const audioBufferToMP3 = aBuffer => {
  let numOfChan = aBuffer.numberOfChannels,
    btwLength = aBuffer.length * numOfChan * 2 + 44,
    btwArrBuff = new ArrayBuffer(btwLength),
    btwView = new DataView(btwArrBuff),
    btwChnls = [],
    btwIndex,
    btwSample,
    btwOffset = 0,
    btwPos = 0

  const setUint16 = data => {
    btwView.setUint16(btwPos, data, true)
    btwPos += 2
  }
  const setUint32 = data => {
    btwView.setUint32(btwPos, data, true)
    btwPos += 4
  }

  setUint32(0x46464952) // "RIFF"
  setUint32(btwLength - 8) // file length - 8
  setUint32(0x45564157) // "WAVE"
  setUint32(0x20746d66) // "fmt " chunk
  setUint32(16) // length = 16
  setUint16(1) // PCM (uncompressed)
  setUint16(numOfChan)
  setUint32(aBuffer.sampleRate)
  setUint32(aBuffer.sampleRate * 2 * numOfChan) // avg. bytes/sec
  setUint16(numOfChan * 2) // block-align
  setUint16(16) // 16-bit
  setUint32(0x61746164) // "data" - chunk
  setUint32(btwLength - btwPos - 4) // chunk length

  for (btwIndex = 0; btwIndex < aBuffer.numberOfChannels; btwIndex++)
    // @ts-ignore
    btwChnls.push(aBuffer.getChannelData(btwIndex))

  while (btwPos < btwLength) {
    for (btwIndex = 0; btwIndex < numOfChan; btwIndex++) {
      // interleave btwChnls
      btwSample = Math.max(-1, Math.min(1, btwChnls[btwIndex][btwOffset])) // clamp
      btwSample =
        (0.5 + btwSample < 0 ? btwSample * 32768 : btwSample * 32767) | 0 // scale to 16-bit signed int
      btwView.setInt16(btwPos, btwSample, true) // write 16-bit sample
      btwPos += 2
    }
    btwOffset++ // next source sample
  }

  const wavHdr = lamejs.WavHeader.readHeader(new DataView(btwArrBuff))

  // Stereo
  const data = new Int16Array(btwArrBuff, wavHdr.dataOffset, wavHdr.dataLen / 2)
  const leftData: number[] = []
  const rightData: number[] = []
  for (let i = 0; i < data.length; i += 2) {
    leftData.push(data[i])
    rightData.push(data[i + 1])
  }
  const left = new Int16Array(leftData)
  const right = new Int16Array(rightData)

  if (wavHdr.channels === 2) {
    return wavToMp3({
      channels: wavHdr.channels,
      sampleRate: wavHdr.sampleRate,
      left,
      right,
    })
  }
}

const wavToMp3 = ({ channels, sampleRate, left, right }) => {
  const buffer: any = []
  const mp3enc: any = new lamejs.Mp3Encoder(channels, sampleRate, 320)
  let remaining: number = left.length
  const samplesPerFrame: number = 1152

  for (let i = 0; remaining >= samplesPerFrame; i += samplesPerFrame) {
    let mp3buf = []
    if (!right) {
      const mono = left.subarray(i, i + samplesPerFrame)
      mp3buf = mp3enc.encodeBuffer(mono)
    } else {
      const leftChunk = left.subarray(i, i + samplesPerFrame)
      const rightChunk = right.subarray(i, i + samplesPerFrame)
      mp3buf = mp3enc.encodeBuffer(leftChunk, rightChunk)
    }
    if (mp3buf.length > 0) {
      buffer.push(mp3buf) //new Int8Array(mp3buf));
    }
    remaining -= samplesPerFrame
  }
  const d = mp3enc.flush()
  if (d.length > 0) {
    buffer.push(new Int8Array(d))
  }
  const mp3Blob = new Blob(buffer, { type: 'audio/mp3' })
  return mp3Blob
}

import { PageContainer } from '@fivano/core'
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom'

export const ProductsSettingsPage = () => {
  const history = useHistory()
  return (
    <PageContainer maxWidth='lg'>
      <Button onClick={() => history.push('producten/categorieen')}>
        Categorieen
      </Button>
    </PageContainer>
  )
}

import React from 'react'
import MaterialInputBase, { InputBaseProps } from '@mui/material/InputBase'
import { RegisterOptions } from 'react-hook-form'

interface InputBasePropTypes extends InputBaseProps {
  /**Name of the InputBase*/
  name: string
  /**Object with all the form methods from react-hook-form.*/
  formObject: any
  /**Rules the InputBase has to abide to*/
  rules?: RegisterOptions
  /**String for the css layout*/
  className?: string
  /** Boolean to show field in error state. React-hook-form automatically initiates an error */
  error?: boolean
  /** onBlur */
  onBlur?: (e: any) => void
  /** onChange */
  onChange?: (e: any) => void
}

export function InputBase(props: InputBasePropTypes) {
  const {
    type,
    name,
    formObject,
    rules,
    fullWidth = true,
    autoComplete = 'off',
    className,
    error,
    onBlur,
    onChange,
    ...restProps
  } = props

  const {
    register,
    formState: { errors },
  } = formObject

  const extendedRules = {
    ...rules,
    ...(type === 'number' && { valueAsNumber: true }),
  }
  const { ref, ...rest } = register(name, extendedRules)
  return (
    <MaterialInputBase
      name={rest.name}
      inputRef={ref}
      onBlur={e => {
        onBlur && onBlur(e)
        rest.onBlur(e)
      }}
      onChange={e => {
        onChange && onChange(e)
        rest.onChange(e)
      }}
      fullWidth={fullWidth}
      required={!!rules?.required}
      autoComplete={autoComplete}
      error={error || errors[name]}
      type={type}
      {...restProps}
    />
  )
}

import React, { useEffect, useState } from 'react'
import { Typography, styled, Link } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'

const defaultVariant: Variant = 'body1'
interface StyleProps {
  variant?: any
  lines: number
  hideText?: boolean
}
const TypographyStyled = styled(Typography, {
  shouldForwardProp: prop =>
    prop !== 'variant' &&
    prop !== 'lines' &&
    prop !== 'hideText' &&
    prop !== 'defaultParagraph',
})<StyleProps>(({ theme, variant, lines, hideText }) => {
  const variantProp = variant || defaultVariant
  const lineHeightNumber: any = theme.typography[variantProp].lineHeight

  const fontSizeNumber: string | undefined =
    theme.typography[variantProp].fontSize?.toString()

  let newFontSize: any = Number(fontSizeNumber?.replace(/[^0-9.]+/g, ''))
  const variantValue = theme?.typography?.[variantProp]
  newFontSize = variantValue.fontSize

  const heightNumber = newFontSize * lineHeightNumber * 0.0625 * 3
  const heightValue = lines && variant ? `${heightNumber}rem` : 'auto'

  return lines && hideText
    ? {
        height: heightValue,
        whiteSpace: 'pre-line',
        position: 'relative',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: `${lines}`,
        WebkitBoxOrient: 'vertical',
        ...variantValue,
      }
    : {
        whiteSpace: 'pre-line',
        ...variantValue,
      }
})

const ButtonWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
}))

type ParagraphProps = {
  /**Possible layout variants of the Paragraph*/
  variant?: Variant
  /**The amount of lines the Paragraph has*/
  lines?: number
  /** Boolean if true, show more/less button */
  showButton?: boolean
  /**Children elements of the Paragraph*/
  children: any
  /**Custom color for the Paragraph */
  color?:
    | 'inherit'
    | 'initial'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error'
    | undefined
  /** Paragraph labels translations */
  translations?: Partial<ParagraphTranslations>
}

const isOverflown = element => {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  )
}

export function Paragraph({
  variant = defaultVariant,
  lines = 0,
  children,
  showButton = true,
  color = 'inherit',
  translations = defaultTranslations,
  ...props
}: ParagraphProps) {
  const [hideText, setHideText] = useState(true)
  const [isOverflowing, setIsOverflowing] = useState(false)
  useEffect(() => {
    const element = document.querySelector('#paragraphWrapper p')
    if (element) {
      const overflow = isOverflown(element)
      setIsOverflowing(overflow)
    }
  }, [])

  return (
    <>
      <div id='paragraphWrapper'>
        <TypographyStyled
          variant={variant}
          color={color}
          hideText={hideText}
          lines={lines}
          {...props}
        >
          {children}
        </TypographyStyled>
      </div>
      {showButton && lines > 0 && isOverflowing && (
        <ButtonWrapper>
          <Link
            onClick={() => setHideText(!hideText)}
            style={{ cursor: 'pointer' }}
          >
            {hideText ? translations.more : translations.less}
          </Link>
        </ButtonWrapper>
      )}
    </>
  )
}
type ParagraphTranslations = {
  more: string
  less: string
}
const defaultTranslations: ParagraphTranslations = {
  more: 'Meer',
  less: 'Minder',
}

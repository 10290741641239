import React from 'react'
import { styled } from '@mui/material'

const DonutStyles = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'customWidth' &&
    prop !== 'labelFontSize' &&
    prop !== 'valueFontSize',
})<any>(({ theme, customWidth, labelFontSize, valueFontSize }) => ({
  '@keyframes donutFade': {
    '0%': {
      opacity: '0.2',
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes donut': {
    '0%': {
      strokeDasharray: '0, 100',
    },
    '100%': {
      strokeDasharray: (props: DonutChartProps) =>
        `${props.currentValue}, ${props.maximumValue}`,
    },
  },

  width: `${customWidth}px` || '100%',
  fontSize: '16px',
  animation: `donutFade 1000ms ${theme.transitions.easing.easeInOut}`,

  '& .donutRing': {
    stroke: '#EBEBEB',
  },
  '& .donutSegment': {
    transformOrigin: 'center',
    stroke: theme.palette.primary.main,
    animation: `donut 2000ms ${theme.transitions.easing.easeInOut}`,
  },

  '& .donutPercent': {
    fontSize: valueFontSize || '0.3em',
    lineHeight: 1,
    transform: 'translateY(0.5em)',
    fontWeight: 'bold',
    fill: theme.palette.primary.main,
  },
  '& .donutLabel': {
    fontSize: labelFontSize || '0.2em',
    color: '#666',
    fill: '#666',
    animation: 'donutfadelong 1s',
  },
}))

type DonutChartProps = {
  /**The current value*/
  currentValue: number
  /**The maximum possible value*/
  maximumValue: number
  /**Visible label*/
  chartLabel: string
  /**Custom width of the total component*/
  customWidth?: string
  /**Custom width of the filling value bar*/
  strokeWidth?: string
  /**Custom fontSize of the values*/
  valueFontSize?: string
  /**Custom fontSize for the label */
  labelFontSize?: string
}

export const DonutChart = ({
  currentValue,
  maximumValue,
  chartLabel,
  customWidth,
  strokeWidth,
  valueFontSize,
  labelFontSize,
}: DonutChartProps) => {
  currentValue = currentValue ? currentValue : 0

  return (
    <DonutStyles
      customWidth={customWidth}
      valueFontSize={valueFontSize}
      labelFontSize={labelFontSize}
    >
      <svg width='100%' height='100%' viewBox='0 0 40 40' className='donut'>
        <circle
          className='donut-hole'
          cx='20'
          cy='20'
          r='15.91549430918954'
          fill='#fff'
        />
        <circle
          className='donutRing'
          cx='20'
          cy='20'
          r='15.91549430918954'
          fill='transparent'
          strokeWidth={strokeWidth ? strokeWidth : '3.5'}
        />
        <circle
          className='donutSegment'
          cx='20'
          cy='20'
          r='15.91549430918954'
          fill='transparent'
          strokeWidth={strokeWidth ? strokeWidth : '3.5'}
          strokeDasharray={`${
            100 -
            Math.round(((maximumValue - currentValue) / maximumValue) * 100)
          },${Math.round(
            ((maximumValue - currentValue) / maximumValue) * 100,
          )} `}
          // strokeDasharray='69 31'
          strokeDashoffset='25'
        />
        <g>
          <text y='50%' transform='translate(0, 2)'>
            <tspan x='50%' y='45%' textAnchor='middle' className='donutPercent'>
              {`${Math.floor(currentValue / 60)}/${Math.floor(
                maximumValue / 60,
              )}`}
            </tspan>
            <tspan x='50%' y='55%' textAnchor='middle' className='donutLabel'>
              {chartLabel}
            </tspan>
          </text>
        </g>
      </svg>
    </DonutStyles>
  )
}

import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import {
  styled,
  FormControlLabel,
  Switch as MSwitch,
  FormHelperText,
} from '@mui/material'
import { ErrorMessage } from '@hookform/error-message'

const SwitchStyled = styled(MSwitch)(({ theme }) => ({
  padding: 7,
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 18,
    height: 18,
    margin: 1,
  },
}))
type SwitchProps = {
  /**Visible label of the switch*/
  label?: string
  /**Name of the switch*/
  name: string
  /**Object with all the form methods from react-hook-form.*/
  formObject: UseFormReturn
  /**Rules the Switch has to abide to*/
  rules?: object
  /**Boolean whether the Switch is disabled or not*/
  disabled?: boolean
  /**Default value whether the switch is active or not*/
  defaultValue?: boolean
  /** HelperText displayed under the switch */
  helperText?: string
  /** HelperText displayed under the switch */
  onChange?: (e: any) => void
}

export const Switch = ({
  label,
  name,
  formObject,
  rules,
  disabled = false,
  defaultValue = false,
  helperText,
  onChange,
}: SwitchProps) => (
  <>
    <FormControlLabel
      label={label}
      disabled={disabled}
      control={
        <Controller
          name={name}
          control={formObject.control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field: { onChange: onChangeRHF, value } }) => (
            <SwitchStyled
              name={name}
              disabled={disabled}
              color='primary'
              onChange={e => {
                onChangeRHF(e.target.checked)
                onChange && onChange(e.target.checked)
              }}
              checked={value}
            />
          )}
        />
      }
    />
    <FormHelperText error={formObject.formState.errors?.[name]}>
      {formObject.formState.errors?.[name] ? (
        <ErrorMessage name={name} errors={formObject.formState.errors} />
      ) : (
        helperText
      )}
    </FormHelperText>
  </>
)

import React from 'react'
import { useDraggable } from '@dnd-kit/core'
import { styled, useTheme } from '@mui/material'

type DraggableProps = {
  data: any
  active?: boolean
  onClick?: (id: string) => void
  children: React.ReactFragment
}

const DivStyled = styled('div')(() => ({
  height: '100%',
  '&:hover': {
    position: 'relative',
  },
}))

export const Draggable = ({
  data,
  active,
  onClick,
  children,
}: DraggableProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: data.id,
  })

  const theme = useTheme()

  const styles = {
    height: '100%',
    cursor: transform ? 'grabbing' : 'grab',
    transform: transform
      ? `scale(1.02,1.02) translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    borderRadius: 4,
    boxShadow: transform ? theme.shadows[6] : theme.shadows[0],
    backgroundColor: active
      ? theme.palette.primary.main
      : theme.palette.background.paper,
  }

  return (
    <DivStyled>
      <div
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        onClick={() => onClick && onClick(data)}
        style={styles}
      >
        {children}
      </div>
    </DivStyled>
  )
}

import React, { useState, useEffect } from 'react'
import { DialogActions, Button, styled } from '@mui/material'
import { RHFDevTool } from '@fivano/core'

const DialogActionsStyled = styled(DialogActions)(() => ({
  padding: '8px 16px',
}))

const ButtonStyled = styled(Button)(() => ({
  marginRight: 'auto',
}))

type FormActionsProps = {
  editForm: boolean
  formDirty: boolean
  onSubmit: any
  onDetails?: any
  onClose: any
  control?: any
  disableModal?: boolean
}

export const OLDFormActions = ({
  editForm = false,
  formDirty = false,
  onSubmit,
  onDetails,
  onClose,
  control,
  disableModal = false,
}: FormActionsProps) => {
  const [showDetails, setShowDetails] = useState(false)

  useEffect(() => {
    if (onDetails) {
      onDetails(showDetails)
    }
  }, [showDetails, onDetails])

  return (
    <>
      {process.env.REACT_APP_ENV === 'development' && control && (
        <RHFDevTool placement='bottom-right' control={control} />
      )}
      <DialogActionsStyled>
        {onDetails && (
          <ButtonStyled
            color='primary'
            onClick={() => {
              setShowDetails(!showDetails)
            }}
          >
            {showDetails ? 'Verberg velden' : 'Alle velden'}
          </ButtonStyled>
        )}
        {!disableModal && (
          <Button color='primary' onClick={onClose}>
            {editForm
              ? formDirty
                ? 'Sluiten'
                : 'Bewerking annuleren'
              : 'Annuleren'}
          </Button>
        )}
        <Button
          onClick={onSubmit}
          variant='contained'
          color='primary'
          type='submit'
          disableElevation
          disabled={formDirty}
        >
          Opslaan
        </Button>
      </DialogActionsStyled>
    </>
  )
}

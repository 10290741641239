import React, { useState, createRef, useCallback, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import { Controller } from 'react-hook-form'
import {
  DialogActions,
  Button,
  Grid,
  Avatar,
  Tooltip,
  Box,
  ButtonBase,
  styled,
} from '@mui/material'
import { RotateRight, ZoomIn, ZoomOut } from '@mui/icons-material'
import { Modal } from '../Modal'
import { IconButton } from '../IconButton'
import { firestoreIDGenerator } from '../../utils/firestoreIDGenerator'
import AvatarPickerIcon from '../../icons/AvatarPickerIcon'

type AvatarPickerProps = {
  /** Name of AvatarPicker */
  name: string
  /** Title of image */
  title?: string
  /** imageURL of src (source) when an image is already defined */
  imageURL?: string
  /** Size of the eventual image to be cropped */
  size: number
  /** Size of the preview image in the button to open the image picker */
  previewSize: number
  /** React hook form control */
  control: any
  /** Radius of avatar */
  avatarBorderRadius?: number
  /** Width of the border of the AvatarPicker preview */
  avatarBorder?: number | []
  /** Boolean if AvatarPicker should return a file */
  returnFile?: boolean
}

const PickerIcon = styled(AvatarPickerIcon)(({ theme }) => ({
  position: 'absolute',
  zIndex: 500,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: '30%',
  visibility: 'hidden',
  color: theme.palette.common.white,
}))
const ButtonBaseImage = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.action.disabled}`,
  backgroundColor: theme.palette.action.disabled,
  ':hover [data-comp="pickerIcon"]': {
    visibility: 'visible',
    backgroundColor: theme.palette.action.hover,
  },
}))

export const AvatarPicker = ({
  imageURL,
  title = 'Avatar instellen',
  size = 48,
  previewSize = 24,
  name,
  control,
  avatarBorderRadius = 24,
  avatarBorder = 20,
  returnFile = false,
}: AvatarPickerProps) => {
  const dropzoneRef = createRef()
  const maxSize = 52428800
  const [open, setOpen] = useState<boolean>(false)
  const [avatarRotation, setAvatarRotation] = useState<number>(0)
  const [avatarScale, setAvatarScale] = useState<number>(1)
  const [avatarImage, setAvatarImage] = useState<File>()
  const [dataURL, setDataURL] = useState<string>('')

  const inputEl = useRef(null)

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setAvatarImage(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize,
  })

  const openDialog = () => {
    // Note that the ref is set async, so it might be null at some point
    if (dropzoneRef.current) {
      //@ts-ignore
      dropzoneRef.current.open()
    }
  }

  const handleAvatarRotation = () => {
    setAvatarRotation(avatarRotation + 90)
    if (avatarRotation === 270) {
      setAvatarRotation(0)
    }
  }

  const handleAvatarScale = zoomType => {
    if (zoomType === 'zoomIn') {
      if (avatarScale < 2) {
        setAvatarScale(avatarScale + 0.1)
      }
    }
    if (zoomType === 'zoomOut') {
      if (avatarScale > 1) {
        setAvatarScale(avatarScale - 0.1)
      }
    }
  }
  const handleClose = () => {
    setAvatarRotation(0)
    setAvatarScale(1)
    setOpen(false)
  }
  const isDisabled = avatarImage === undefined
  const modalWidth = size > 306 ? size : 306
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <>
          <Tooltip
            title={imageURL ? 'Afbeelding wijzigen' : 'Afbeelding instellen'}
            onClick={() => setOpen(true)}
          >
            <ButtonBaseImage
              sx={{
                width: previewSize,
                height: previewSize,
                borderRadius: avatarBorderRadius,
              }}
            >
              {dataURL || imageURL ? (
                <>
                  <PickerIcon
                    data-comp='pickerIcon'
                    style={{
                      borderRadius: avatarBorderRadius,
                    }}
                  />
                  <Avatar
                    variant='square'
                    sx={{
                      width: previewSize,
                      height: previewSize,
                    }}
                    src={dataURL || imageURL}
                  />
                </>
              ) : (
                <PickerIcon
                  data-comp='pickerIcon'
                  style={{
                    visibility: 'visible',
                    borderRadius: avatarBorderRadius,
                  }}
                />
              )}
            </ButtonBaseImage>
          </Tooltip>
          <Modal
            open={open}
            closeButton
            onClose={handleClose}
            title={title}
            maxWidth={false}
            fullWidth={false}
            customMaxWidth={modalWidth + 40}
          >
            <Box
              sx={{
                width: '100%',
                borderTop: `1px solid ${theme => theme.palette.divider}`,
                borderBottom: `1px solid ${theme => theme.palette.divider}`,
              }}
            >
              <Grid container justifyContent='center'>
                <Grid item>
                  <IconButton
                    label='Draaien'
                    disabled={isDisabled}
                    color='primary'
                    onClick={handleAvatarRotation}
                    size='large'
                  >
                    <RotateRight />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    label='Inzoomen'
                    disabled={isDisabled}
                    color='primary'
                    onClick={() => handleAvatarScale('zoomIn')}
                    size='large'
                  >
                    <ZoomIn />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    label='Uitzoomen'
                    disabled={isDisabled}
                    color='primary'
                    onClick={() => handleAvatarScale('zoomOut')}
                    size='large'
                  >
                    <ZoomOut />
                  </IconButton>
                </Grid>
              </Grid>
              <div
                {...getRootProps({
                  className: 'dropzoneWrapper',
                  onClick: event => event.stopPropagation(),
                })}
                style={{ backgroundColor: '#999999' }}
              >
                <div
                  style={{
                    backgroundImage: `url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 24 24"><path fill="%23cccccc" d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>')`,
                    backgroundPosition: 'center',
                    lineHeight: 0, // to remove space under SVG background
                  }}
                >
                  <AvatarEditor
                    width={modalWidth}
                    height={modalWidth}
                    ref={inputEl}
                    border={avatarBorder}
                    borderRadius={avatarBorderRadius}
                    scale={avatarScale}
                    rotate={avatarRotation}
                    image={avatarImage}
                    color={[0, 0, 0, 0.4]}
                  />
                  <input {...getInputProps()} />
                </div>
              </div>
              <div
                {...getRootProps({ className: 'dropzoneButton' })}
                style={{ padding: '8px 16px' }}
              >
                <Button
                  variant='outlined'
                  color='primary'
                  type='button'
                  onClick={openDialog}
                  disableElevation
                  fullWidth
                >
                  Afbeelding uploaden
                </Button>
              </div>
            </Box>
            <DialogActions>
              <Button onClick={handleClose} color='primary'>
                Annuleren
              </Button>
              <Button
                onClick={() => {
                  const canvasScaled =
                    // @ts-ignore
                    inputEl.current.getImageScaledToCanvas()
                  canvasScaled.toBlob(blob => {
                    if (returnFile) {
                      const file: File = new File(
                        [blob],
                        avatarImage?.name || firestoreIDGenerator(),
                      )
                      onChange([file])
                    } else {
                      onChange(blob)
                    }
                    setDataURL(canvasScaled.toDataURL())
                    handleClose()
                  })
                }}
                color='primary'
                variant='contained'
                disableElevation
                disabled={isDisabled}
              >
                Opslaan
              </Button>
            </DialogActions>
          </Modal>
        </>
      )}
    />
  )
}

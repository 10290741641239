import React, { useState } from 'react'
import { SettingsPage } from 'app/legacy/SettingsBlock'
import { PersonTagsFormSetup } from './PersonTagsFormSetup'
import { Chip, Grid, Typography } from '@mui/material'

const breadcrumbArray = [
  { title: 'Instellingen', path: '/instellingen' },
  { title: 'Tags', path: '/instellingen/deelnemertags' },
]

export const PersonTags = () => {
  const [tagModalProps, setTagModalProps] = useState({
    formID: null,
    open: false,
  })
  const firestoreCode = 'Peta'
  const firestoreSettingsPath = 'personTags'

  return (
    <>
      <PersonTagsFormSetup
        firestoreCode={firestoreCode}
        firestoreSettingsPath={firestoreSettingsPath}
        labelSingle='Tag'
        formProps={tagModalProps}
        onClose={() =>
          setTagModalProps({
            formID: null,
            open: false,
          })
        }
      />
      <SettingsPage
        onAdd={() => setTagModalProps({ formID: null, open: true })}
        onEdit={(event, rowData) =>
          setTagModalProps({
            formID: rowData.original[`id${firestoreCode}`],
            open: true,
          })
        }
        listColumns={[
          {
            Header: ' ',
            canGroupBy: false,
            canSort: false,
            columns: [
              {
                Header: 'Naam',
                accessor: 'personTagCategoryPeta',
                gridProps: {
                  xs: 9,
                  sm: 9,
                  md: 9,
                },
                Cell: data => (
                  <div>
                    <Typography variant='body1'>
                      {data.row.original[`description${firestoreCode}`]}
                    </Typography>

                    {data.row.original[`categoryTags${firestoreCode}`] !==
                    undefined
                      ? data.row.original[`categoryTags${firestoreCode}`].map(
                          item => {
                            return (
                              <Typography
                                variant='caption'
                                sx={{ marginRight: 1 }}
                                key={item[`idTag${firestoreCode}`]}
                              >
                                {item[`label${firestoreCode}`]}
                              </Typography>
                            )
                          },
                        )
                      : null}
                  </div>
                ),
              },
              {
                Header: 'Gearchiveerd',
                accessor: `archived${firestoreCode}`,
                gridProps: {
                  xs: 3,
                  sm: 3,
                  md: 3,
                },
                Cell: data => (
                  <Grid item>
                    {data.row.original[`archived${firestoreCode}`] && (
                      <Chip label='Gearchiveerd' />
                    )}
                  </Grid>
                ),
              },
            ],
          },
        ]}
        labelMultiple='deelnemer tags'
        labelSingle='tag'
        breadcrumbs={breadcrumbArray}
        firestoreSettingsPath={firestoreSettingsPath}
        firestoreCode={firestoreCode}
      />
    </>
  )
}

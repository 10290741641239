import React from 'react'
import { Grid, Box, useMediaQuery, Tooltip, styled } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

const HelperIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.grey[500],
  padding: '8px 0px 0px 8px',
}))

const GridStyled = styled(Grid, {
  shouldForwardProp: prop => prop !== 'showAllFields',
})<any>(({ showAllFields }) => ({
  display: showAllFields || 'none',
  padding: '8px',
}))

const IconContainer = styled('div')(({ theme }) => ({
  color: theme.palette.grey[500],
  width: '48px',
  padding: '8px 16px 8px 8px',
}))

type FormRowProps = {
  icon?: any
  showAllFields?: boolean
  helperText?: string
}

const HelperText = ({ helperText }) => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md'),
  )
  return (
    <>
      {(helperText || helperText === '') &&
        (isSmallScreen && helperText ? (
          <HelperIconWrapper>
            <Tooltip title={helperText}>
              <InfoIcon />
            </Tooltip>
          </HelperIconWrapper>
        ) : (
          <Grid item md={4}>
            {helperText}
          </Grid>
        ))}
    </>
  )
}

export const FormRow: React.FC<FormRowProps> = ({
  icon,
  children,
  showAllFields = true,
  helperText,
}) => (
  <GridStyled item xs={12} showAllFields={showAllFields}>
    <Grid container>
      <Grid
        item
        md={helperText !== undefined ? 8 : 12}
        xs={11}
        style={{ display: 'flex' }}
      >
        {icon !== undefined && <IconContainer>{icon}</IconContainer>}
        <Box flexGrow={1}>{children}</Box>
      </Grid>
      <HelperText helperText={helperText} />
    </Grid>
  </GridStyled>
)

import React from 'react'
import { Grid, Chip, styled } from '@mui/material'
import { Typography } from '@mui/material'
import { Location } from '../../../../types/Location'

const GridStyled = styled(Grid)(() => ({
  minHeight: 56,
  padding: '4px 16px 4px 16px',
}))

const ChipStyled = styled(Chip)(() => ({
  marginLeft: '10px',
}))

type LocationListItemProps = {
  /**Data belonging to the location*/
  locationData: Location
  /**Property that depicts the function of the location*/
  relaLocationTypeLoca?: string
  /**Boolean whether a chip is shown*/
  locationChip?: boolean
  /**Visible label when the location is archived*/
  archivedLabel?: any
  /**Function that opens the form for editing*/
  onEdit?: any
}

export const LocationListItem = ({
  locationData,
  relaLocationTypeLoca,
  locationChip,
  archivedLabel,
}: LocationListItemProps) => (
  <GridStyled container item alignItems='center'>
    <Typography variant='body1'>{locationData?.nameLoca}</Typography>
    {locationChip && (
      <ChipStyled
        size='small'
        label={[
          relaLocationTypeLoca === 'invoiceLoca' && 'Factuuradres',
          relaLocationTypeLoca === 'mailLoca' && 'Postadres',
          relaLocationTypeLoca === 'visitLoca' && 'Bezoekadres',
        ]}
      />
    )}
    {archivedLabel && (
      <Grid item marginLeft={15}>
        {archivedLabel}
      </Grid>
    )}
  </GridStyled>
)

import { useFirestore } from 'react-redux-firebase'
import { Person, ProfileType } from 'types'
import { functions } from 'index'
import { useSnackbar } from '@fivano/core'

/** React hook that returns function to invite user by email */
export const useInviteUser = () => {
  const firestore = useFirestore()
  const { enqueueSnackbar } = useSnackbar()

  const handleEmailInvite = (personData: Person, locationID: string) => {
    const {
      id,
      emailPers,
      firstNamePers,
      middleNamePers,
      lastNamePers,
      keywordsPers,
    } = personData

    if (emailPers && id) {
      const userData: ProfileType = {
        avatarImagePers: false,
        claims: {},
        id: id,
        uid: id,
        email: emailPers,
        emailPers: emailPers,
        firstNamePers,
        lastNamePers,
        middleNamePers: middleNamePers || '',
        keywordsPers,
        locationIDPers: locationID,
        locationsAccess: [''],
        locations: [{ label: '', value: '' }],
        roles: [{ label: 'Deelnemer', value: 'participant' }],
      }

      functions
        .httpsCallable('inviteUser')({
          userData,
          domain: window.location.origin,
        })
        .then((response: any) => {
          if (response?.data?.error) {
            enqueueSnackbar(response?.data?.errorMessage, {
              variant: 'error',
            })
          } else {
            firestore
              .update(`locations/${locationID}/persons/${id}`, {
                emailInvitedPers: emailPers,
              })
              .then(() => {
                enqueueSnackbar('Uitnodiging email verzonden', {
                  variant: 'success',
                })
              })
          }
        })
        .catch(error => {
          enqueueSnackbar(`Er is iets fout gegaan: ${error}`, {
            variant: 'error',
          })
        })
    } else {
      enqueueSnackbar(
        'Geen email adres beschikbaar, kan de deelnemer niet uitnodigen. Stel een email adres is.',
        { variant: 'error' },
      )
    }
  }

  return handleEmailInvite
}

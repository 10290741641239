import React from 'react'
import MaterialTextField, {
  BaseTextFieldProps,
  OutlinedTextFieldProps,
} from '@mui/material/TextField'
import { ErrorMessage } from '@hookform/error-message'
import { RegisterOptions } from 'react-hook-form'
interface TextFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  /**Name of the TextField*/
  name: string
  /**Object with all the form methods from react-hook-form.*/
  formObject: any
  /**Rules the TextField has to abide to*/
  rules?: RegisterOptions<any>
  /**Possible layout variants of the TexField*/
  variant?: BaseTextFieldProps['variant']
  /**String for the css layout*/
  className?: string
  /**Boolean whether the input has a readOnly state*/
  readOnly?: boolean
  /** Boolean to show field in error state. React-hook-form automatically initiates an error */
  error?: boolean
  /** onBlur */
  onBlur?: (e: any) => void
  /** onChange */
  onChange?: (e: any) => void
  /** Remove margin from textfield */
  noMargin?: boolean
}

export function TextField(props: TextFieldProps) {
  const {
    type,
    name,
    formObject,
    rules,
    label,
    fullWidth = true,
    variant = 'outlined',
    size = 'small',
    autoComplete = 'off',
    className,
    readOnly = false,
    error,
    onBlur,
    onChange,
    helperText,
    ...restProps
  } = props
  const {
    register,
    formState: { errors },
  } = formObject

  const { ref, ...rest } = register(name, {
    ...rules,
    ...(type === 'number' && { valueAsNumber: true }),
  })
  const hasError = Boolean(error || errors[name])
  return (
    <MaterialTextField
      name={rest.name}
      inputRef={ref}
      onBlur={e => {
        onBlur && onBlur(e)
        rest.onBlur(e)
      }}
      onChange={e => {
        onChange && onChange(e)
        rest.onChange(e)
      }}
      className={className}
      label={label}
      fullWidth={fullWidth}
      variant={variant}
      size={size}
      required={!!rules?.required}
      autoComplete={autoComplete}
      error={hasError}
      helperText={
        hasError ? <ErrorMessage name={name} errors={errors} /> : helperText
      }
      type={type}
      InputProps={{ readOnly }}
      {...restProps}
    />
  )
}

import React from 'react'
import { useSelector } from 'react-redux'
import { selectThemeMode, themeSliceKey, reducer } from './slice'
import { useInjectReducer } from 'utils/redux-injectors'
import { useSettings } from 'hooks/useSettings'
// import createGenerateClassName from './createGenerateClassName'
// import { isEmulating } from 'utils/emulator'
import {
  ThemeProvider as MaterialThemeProvider,
  createTheme,
} from '@mui/material/styles'
import {
  appModeReducer,
  appModeSliceKey,
} from 'app/containers/DevTools/appModeSlice'

declare module '@mui/material/styles' {
  interface Theme {
    fivano: {
      palette: {
        backgroundColor: string
      }
    }
  }
  interface ThemeOptions {
    fivano: {
      palette: {
        backgroundColor: string
      }
    }
  }
}
export const ThemeProvider = (props: { children: React.ReactChild }) => {
  useInjectReducer({ key: themeSliceKey, reducer: reducer })
  useInjectReducer({ key: appModeSliceKey, reducer: appModeReducer })

  const darkMode = useSelector(selectThemeMode) || false
  const materialTheme = createTheme()
  const { theme: themeSettings } = useSettings()
  themeSettings.palette.mode = darkMode ? 'dark' : 'light'

  const theme = createTheme({
    ...themeSettings,
    // CUSTOM THEME VARIABLES:
    fivano: {
      palette: {
        backgroundColor: darkMode
          ? themeSettings.fivano.palette.backgroundColorDark || 'rgba(15,8,0,1)'
          : themeSettings.fivano.palette.backgroundColorLight ||
            'rgba(251, 138, 0, 0.1)',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          a: {
            textDecoration: 'none',
            color: 'inherit',
          },
          'input:read-only': {
            color: materialTheme.palette.text.disabled,
            backgroundColor: materialTheme.palette.action.disabledBackground,
            borderRadius: materialTheme.shape.borderRadius,
          },
        },
      },
    },
  })
  return (
    <MaterialThemeProvider theme={theme}>
      {React.Children.only(props.children)}
    </MaterialThemeProvider>
  )
}

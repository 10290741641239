import React, { forwardRef } from 'react'
import type { FC } from 'react'
import { format } from 'date-fns'
import { Grid, Card, Box, Typography, Chip, styled } from '@mui/material'
import { PersonAvatar } from 'app/legacy/Persons/PersonAvatar'
import { isNullish } from 'utils/isNullish'
import { useSelector } from 'hooks/useSelector'
import { convertToDate } from 'utils/convertToDate'

const CardStyled = styled(Card)(({ theme }) => ({
  padding: 8,
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
}))

interface boardCardProps {
  boardID: string
  boardCardID: string
  onClick: any
  dragging: boolean
  style?: {}
}

export const BoardCard: FC<boardCardProps> = forwardRef(
  ({ boardID, dragging, boardCardID, onClick, style, ...rest }, ref) => {
    const boardCard = useSelector(
      ({ firestore: { data } }) =>
        data[`boards_${boardID}_boardCards`]?.[boardCardID],
    )

    return (
      <Box {...{ ref: ref }} {...rest}>
        {boardCard && (
          <Box pt={1} style={style}>
            <CardStyled
              raised={dragging}
              variant={dragging ? 'elevation' : 'outlined'}
              onClick={onClick}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography noWrap variant='h6' color='textPrimary'>
                    {boardCard?.nameBoca}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {boardCard.relationsBoca?.map((rel, index) => (
                    <Typography
                      key={rel?.relation?.value}
                      variant='caption'
                      color='textPrimary'
                      gutterBottom
                    >
                      {rel?.relation?.label}
                      {boardCard?.relationsBoca.length - 1 !== index && ', '}
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='body2'
                    noWrap
                    color='textPrimary'
                    gutterBottom
                  >
                    {boardCard.descriptionBoca}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {!isNullish(boardCard.expectedAmountBoca) && (
                      <Grid xs={4} item>
                        <Typography
                          variant='caption'
                          noWrap
                          color='textPrimary'
                        >
                          €{boardCard.expectedAmountBoca},-
                        </Typography>
                      </Grid>
                    )}
                    {!isNullish(boardCard.chanceBoca) && (
                      <Grid xs={4} item>
                        <Typography
                          variant='caption'
                          noWrap
                          color='textPrimary'
                        >
                          {boardCard.chanceBoca}%
                        </Typography>
                      </Grid>
                    )}
                    {boardCard.dateDueBoca && (
                      <Grid xs={4} item>
                        <Typography
                          variant='caption'
                          noWrap
                          color='textPrimary'
                        >
                          {format(
                            convertToDate(boardCard.dateDueBoca),
                            'dd-MM-yyyy',
                          )}
                        </Typography>
                      </Grid>
                    )}
                    {boardCard.archivedBoca && (
                      <Grid xs={4} item>
                        <Chip label='Gearchiveerd' size='small' />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={1} justifyContent='flex-end'>
                    {boardCard?.relationsBoca?.map(
                      rel =>
                        rel?.persons?.length > 0 &&
                        rel.persons.map(person => (
                          <Grid key={person?.value} item>
                            <PersonAvatar
                              size='small'
                              locationID='publicLocation'
                              personID={person?.value}
                            />
                          </Grid>
                        )),
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardStyled>
          </Box>
        )}
      </Box>
    )
  },
)

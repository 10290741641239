import { ActionMenu, MenuButton, PageContainer } from '@fivano/core'
import { Edit } from '@mui/icons-material'
import { Grid, Paper, Typography } from '@mui/material'
import useMongo from 'hooks/useMongo'

import { useParams } from 'react-router-dom'
import { RouteTypes } from 'types/RouteTypes'
import { CourseModulesForm } from './CourseModulesForm'

import { CourseGroupStudents } from './CourseGroupStudents'
import { useModalRoute } from 'hooks/useModalRoutes'
import { Breadcrumbs } from 'app/components/Breadcrumbs'

export const CourseGroupPage = () => {
  const { groupID } = useParams<RouteTypes>()
  const { useRefetchDoc, useGetDoc } = useMongo()
  const { modalData, closeModal, openModal } = useModalRoute()

  const { docData } = useRefetchDoc({
    collection: 'courseGroups',
    docID: groupID,
    disabled: !groupID,
  })
  const moduleID = docData?.module
  const moduleData = useGetDoc({
    collection: 'courseModules',
    docID: moduleID,
  })
  const groupStudentIDs = docData?.students

  return (
    <PageContainer maxWidth='lg'>
      <Breadcrumbs />
      {modalData.open && (
        <CourseModulesForm
          onCloseForm={() => closeModal()}
          editingDocID={modalData.id}
        />
      )}
      {moduleData && (
        <Paper sx={{ padding: 1 }}>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Typography variant='h5'>
                {moduleData ? moduleData.name : 'Module laden'}
              </Typography>
              {moduleData?.description && (
                <Typography variant='subtitle1'>
                  {moduleData?.description}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <ActionMenu iconPosition='vertical' minWidth={250}>
                <MenuButton
                  label='Bewerk lesmodule'
                  icon={<Edit />}
                  onClick={() => openModal(moduleID)}
                />
              </ActionMenu>
            </Grid>
          </Grid>
        </Paper>
      )}
      <CourseGroupStudents studentIDs={groupStudentIDs} />
    </PageContainer>
  )
}

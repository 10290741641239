import React from 'react'
import { useSelector } from 'hooks/useSelector'
import { Avatar } from 'app/components/Avatar'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Person } from 'types'

type personAvatarProps = {
  locationID: string
  personID: string
  size?: 'small' | 'medium' | 'large'
  onClick?: any
}

export const PersonAvatar = ({
  size,
  onClick,
  locationID,
  personID,
}: personAvatarProps) => {
  useFirestoreConnect([
    {
      collection: 'locations',
      doc: locationID,
      subcollections: [
        {
          collection: 'persons',
          doc: personID,
        },
      ],
      storeAs: `persons_${personID}`,
    },
  ])

  const personData: Person = useSelector(
    ({ firestore: { ordered } }) => ordered[`persons_${personID}`]?.[0],
  )

  if (!personData) {
    return <div>...</div>
  } else return <Avatar onClick={onClick} size={size} personData={personData} />
}

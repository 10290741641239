import { FormLabels } from '.'

export const defaultFormLabels: FormLabels = {
  saving: 'Opslaan',
  cancelAdding: 'Toevoegen annuleren',
  cancelUpdating: 'Bewerken annuleren',
  closing: 'Sluiten',
  abortSave: 'Ga terug',
  staying: 'Blijven',
  updating: 'bewerken',
  adding: 'toevoegen',
  updated: 'bewerkt',
  added: 'toegevoegd',
  failed: 'mislukt',
  hideFields: 'Verberg velden',
  showFields: 'Alle velden',
  warningMessage: 'Weet u het zeker?',
  closeText: 'Formulier sluiten?',
  filesWillBeDeleted:
    'Onderstaande bestanden zullen definitief worden verwijderd.',
  closeWarning: 'Het document wordt niet opgeslagen.',
  closeWarningEdit: 'De wijzigingen worden niet opgeslagen.',
  closeWarningPage:
    'Het document wordt niet opgeslagen, weet je zeker dat je de pagina wil verlaten?',
  deletingFiles: 'Verwijderen bestanden',
  loading: 'Laden',
  mutatedLabel: 'Laden',
  mutatingLabel: 'Laden',
}

import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Form, Checkbox } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { useFirestore } from 'react-redux-firebase'
import { isEmptyObject } from 'utils/isEmptyObject'
import { PersonProjectType } from './types'

export const ProjectCodesForm = ({
  docData,
  projectCodes,
  trackID,
  personID,
  locationCode,
  onSubmitSuccess,
  onClose,
}) => {
  const errorLogger = useErrorLogger()
  const firestore = useFirestore()

  const createPersonProject = (projectCodes, key, value) => {
    const splitKey = key.split('_')
    const projectID = splitKey[0]
    const workcodeID = splitKey[1]

    const project = projectCodes?.find(project => project.id === projectID)

    const workcode = project?.totalWorkCodes?.find(
      workcode => workcode.value === workcodeID,
    )

    const object: PersonProjectType = {
      companyCode: locationCode,
      projectCode: {
        label: project.name,
        value: project.id,
      },
      workCode: workcode,
      showPreset: value,
      locationID: locationCode.value,
      trackID: trackID,
      personID: personID,
    }

    return object
  }

  const buildCodesData = data => {
    const dataArray = Object.entries(data).map(([key, value]: any) => {
      let object = {}

      const notInDB = !Object.keys(docData).find(x => x === key)
      const bool: boolean = notInDB && value === false
      if (!bool) {
        object = createPersonProject(projectCodes, key, value)
      }

      return !isEmptyObject(object) ? object : undefined
    })

    return dataArray.filter(data => data !== undefined || isEmptyObject(data))
  }

  const createCodesData = async promise => {
    const data = await promise

    await firestore.update(
      {
        collection: `locations/${locationCode.value}/persons/${personID}/tracks`,
        doc: `${trackID}`,
      },
      {
        personProjectsTrack: data,
      },
    )
  }

  return (
    <Form
      docLabel='Project'
      editingDocID={undefined}
      hasDetails={false}
      buildDocData={buildCodesData}
      createDoc={createCodesData}
      updateDoc={async () => {}}
      wrapInside='modal'
      modalMaxWidth='sm'
      docData={docData}
      onCloseForm={onClose}
      onCreateSuccess={onSubmitSuccess}
      onUpdateSuccess={onSubmitSuccess}
      onError={errorLogger}
      formInputs={({ formObject }) =>
        projectCodes
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(code => (
            <Grid key={code.id} container spacing={1}>
              <Grid item xs={12}>
                <Typography>{code.name}</Typography>
                {code.currentWorkCodes
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map(workcode => (
                    <Checkbox
                      key={`${code.id}_${workcode.value}`}
                      name={`${code.id}_${workcode.value}`}
                      formObject={formObject}
                      label={workcode.label}
                    />
                  ))}
              </Grid>
            </Grid>
          ))
      }
    />
  )
}

import { styled } from '@mui/material'
import { menuLayoutType } from 'app/router/types'
import { useSelector } from 'hooks/useSelector'
import { useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { hasClaimLevel } from 'utils/hasClaimLevel'
import { MenuItem } from './MenuItem'

const MenuDiv = styled('div')(() => ({
  paddingTop: 8,
  overflowX: 'hidden',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))

export const NavigationMenu = ({
  open,
  menuData,
}: {
  open: boolean
  menuData: menuLayoutType[]
}) => {
  const location = useLocation()
  const [currentRoute, setCurrentRoute] = useState(location.pathname)

  const profile = useSelector(state => state.firebase.profile)
  const menuRef = useRef<any>(null)

  return (
    <MenuDiv ref={menuRef}>
      {profile &&
        menuData.map(item => {
          return (
            hasClaimLevel(profile, item.route.claims) && (
              <MenuItem
                key={`${item.route.path}_${item.name}`}
                open={open}
                menuItem={item}
                currentRoute={currentRoute}
                onChangeRoute={setCurrentRoute}
              />
            )
          )
        })}
    </MenuDiv>
  )
}

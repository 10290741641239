import { routeObjectType } from 'app/router/types'
import { CourseCompetenceScoresList } from './CourseCompetenceScoresList'
import { CourseCompetencesList } from './CourseCompetencesList'
import { CourseGroupForm } from './CourseGroupForm'
import { CourseGroupsList } from './CourseGroupsList'
import { CourseModulesList } from './CourseModulesList'
import { CourseStudentForm } from './CourseStudentForm'
import { CourseStudentsList } from './CourseStudentsList'
import { CourseGroupPage } from './CourseGroupPage'
import { CourseStudentPage } from './CourseStudentPage'
import { routeParts } from 'app/router/routeParts'

export const courseTrackingRoutes: routeObjectType = {
  courseCompetencesList: {
    path: `/${routeParts.courseTracking.path}/${routeParts.courseCompetences.path}`,
    component: CourseCompetencesList,
    claims: {},
  },
  courseCompetenceScoresList: {
    path: `/${routeParts.courseTracking.path}/${routeParts.courseCompetenceScore.path}`,
    component: CourseCompetenceScoresList,
    claims: {},
  },
  courseModulesList: {
    path: `/${routeParts.courseTracking.path}/${routeParts.courseModules.path}`,
    component: CourseModulesList,
    claims: {},
  },
  studentsList: {
    path: `/${routeParts.courseTracking.path}/${routeParts.students.path}`,
    component: CourseStudentsList,
    claims: {},
  },
  studentsListFormEdit: {
    path: `/${routeParts.courseTracking.path}/${routeParts.students.path}/:personID`,
    component: CourseStudentForm,
    claims: {},
  },
  studentsGroupPage: {
    path: `/${routeParts.courseTracking.path}/${routeParts.courseGroups.path}/:groupID/${routeParts.students.path}/:personID`,
    component: CourseStudentPage,
    claims: {},
  },
  courseGroupsList: {
    path: `/${routeParts.courseTracking.path}/${routeParts.courseGroups.path}`,
    component: CourseGroupsList,
    claims: {},
  },
  courseGroupPage: {
    path: `/${routeParts.courseTracking.path}/${routeParts.courseGroups.path}/:groupID`,
    component: CourseGroupPage,
    claims: {},
  },
  courseGroupsFormNew: {
    path: `/${routeParts.courseTracking.path}/${routeParts.courseGroups.path}/${routeParts.adding.path}`,
    component: CourseGroupForm,
    claims: {},
  },
}

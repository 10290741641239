import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { RouteTypes } from 'types/RouteTypes'
import { Grid } from '@mui/material'
import LabelIcon from '@mui/icons-material/Label'

import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { useDataStandards } from 'hooks/useDataStandards'

import SubjectIcon from '@mui/icons-material/Subject'

import {
  Form,
  FormRow,
  FormPaper,
  Switch,
  Checkbox,
  TextField,
} from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import {
  LicenseBuildTypes,
  LicenseDatastandardTypes,
  LicenseFormTypes,
} from './LicenseTypes'

export const LicenseFormInputs = ({ formObject, showAllFields }) => {
  return (
    <Grid container direction='column' spacing={2}>
      <FormPaper>
        <FormRow helperText='' icon={<LabelIcon />}>
          <TextField
            required
            name='version'
            formObject={formObject}
            label='Versie'
          />
        </FormRow>
        <FormRow helperText='' icon={<SubjectIcon />}>
          <TextField
            required
            name='content'
            formObject={formObject}
            label='Voorwaardes'
            multiline
            rows={8}
          />
        </FormRow>
        <FormRow
          helperText='Wanneer deze licentie op actief wordt gezet, zullen alle andere licenties op non-actief worden gezet.'
          icon={''}
        >
          <Checkbox
            formObject={formObject}
            disabled={formObject.watch('archived')}
            name='active'
            label='Actief'
          />
        </FormRow>
        <FormRow showAllFields={showAllFields} icon=''>
          <Switch
            name='archived'
            formObject={formObject}
            disabled={formObject.watch('active')}
            label={`Licentie archiveren`}
          />
        </FormRow>
      </FormPaper>
    </Grid>
  )
}

export const LicenseForm = () => {
  const history = useHistory()
  const errorLogger = useErrorLogger()
  const firestore = useFirestore()
  const createDataStandards = useDataStandards()
  const { licenseID } = useParams<RouteTypes>()

  const resetActive = async () => {
    const licensesRef = firestore.collection('licenses')
    const activeLicenses = await licensesRef.where('active', '==', true).get()
    activeLicenses.forEach(async doc => {
      await licensesRef.doc(doc.id).update({ active: false })
    })
  }

  const createLicense = async data => {
    if (data.active) {
      await resetActive()
    }
    return await firestore.collection('licenses').add(data)
  }

  const updateLicense = async data => {
    if (data.active) {
      await resetActive()
    }
    return await firestore.update(
      { collection: 'licenses', doc: licenseID },
      data,
    )
  }

  useFirestoreConnect([
    {
      collection: 'licenses',
      limit: 20,
      storeAs: 'licenses',
    },
  ])

  const docData: LicenseFormTypes = useSelector(
    ({
      firestore: {
        data: { licenses },
      },
    }) => {
      if (licenses && licenseID) {
        return licenses[licenseID]
      }
      return null
    },
  )

  const buildLicenseData = (data: LicenseBuildTypes) => {
    const dataStandards: LicenseDatastandardTypes = createDataStandards({
      data,
      dataName: '',
      editForm: !!licenseID,
      keywords: [data.version],
    })

    return { ...data, ...dataStandards }
  }

  if (docData === null && !!licenseID) {
    return <div>Loading...</div>
  }

  return (
    <Form
      onCloseForm={() => history.push(`/licenties`)}
      wrapInside='page'
      docLabel='Licentie'
      hasDetails={true}
      buildDocData={buildLicenseData}
      updateDoc={updateLicense}
      onCreateSuccess={response =>
        history.push(`/licenties/${response.id}/formulier`)
      }
      createDoc={createLicense}
      editingDocID={licenseID}
      docData={docData}
      onError={errorLogger}
      formInputs={({ formObject, showAllFields }) => (
        <LicenseFormInputs
          formObject={formObject}
          showAllFields={showAllFields}
        />
      )}
    />
  )
}

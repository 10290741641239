export const routeParts = {
  adding: { name: 'Toevoegen', path: 'toevoegen' },
  settings: { name: 'Instellingen', path: 'instellingen' },
  users: { name: 'Gebruikers', path: 'gebruikers' },
  persons: { name: 'Personen', path: 'personen' },
  students: { name: 'Leerlingen', path: 'leerlingen' },
  workspaces: { name: 'Workspaces', path: 'workspaces' },
  roleProfiles: { name: 'Rollen profielen', path: 'rollen-profielen' },
  courseTracking: { name: 'Leerlingvolgsysteem', path: 'leerlingvolgsysteem' },
  courseGroups: { name: 'Lesgroepen', path: 'lesgroepen' },
  courseCompetences: { name: 'Competenties', path: 'competenties' },
  courseCompetenceScore: {
    name: 'Competentie-scores',
    path: 'competentie-scores',
  },
  courseModules: { name: 'Modules', path: 'modules' },
}

import React from 'react'
import { IconButton } from '../IconButton'
import { styled } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  transition: theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.short,
  }),
  lineHeight: '9px',
  transformOrigin: '50% 50%',
}))

type ExpandButtonProps = {
  label: string
  rotate: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  size?: 'medium' | 'small' | undefined
}

export const ExpandButton = ({
  label,
  onClick,
  size,
  rotate,
}: ExpandButtonProps) => {
  return (
    <IconButtonStyled
      style={{ transform: rotate ? 'rotate(-180deg)' : 'rotate(0)' }}
      label={label}
      onClick={onClick ? onClick : undefined}
      size={size}
    >
      <KeyboardArrowDown />
    </IconButtonStyled>
  )
}

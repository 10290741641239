import React from 'react'
import { styled, Typography } from '@mui/material'
import { IconButton } from '@fivano/core'
import CloseIcon from '@mui/icons-material/Close'
import useMongo from 'hooks/useMongo'
import { compareArraysAndObjects } from 'utils/compareArraysAndObjects'

const IconButtonStyled = styled(IconButton)(() => ({
  position: 'absolute',
  right: 0,
  top: 0,
}))

const CardWrapper = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'active' &&
    prop !== 'hover' &&
    prop !== 'placeholder' &&
    prop !== 'border',
})<any>(({ theme, active, hover, placeholder, border }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  height: '100%',
  backgroundColor: !placeholder
    ? active
      ? theme.palette.primary.main
      : theme.palette.background.paper
    : theme.palette.grey.A100,
  // boxShadow: theme.shadows[2],

  border: border ? `2px solid ${theme.palette.divider}` : undefined,
  borderRadius: 4,
  ...(hover && {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
  '.deleteButton': {
    visibility: 'hidden',
  },
  '&:hover .deleteButton': {
    visibility: 'visible',
  },
}))

type ProductCardProps = {
  active?: boolean
  hover?: boolean
  data?: any
  placeholder?: boolean
  border?: boolean
  onClick?: () => void
  onDelete?: (id: any) => void
}

export const ProductCard = React.memo(
  ({
    active = false,
    hover = false,
    data,
    placeholder = false,
    border = false,
    onClick,
    onDelete,
  }: ProductCardProps) => {
    const { useGetDoc } = useMongo()

    const doc = useGetDoc({ collection: 'products', docID: data?.productID })

    return (
      <CardWrapper
        hover={hover}
        active={active}
        placeholder={placeholder}
        border={border}
        {...(onClick && { onClick: onClick })}
      >
        <Typography variant='body1' fontWeight={700}>
          {doc?.name || data?.name}
        </Typography>
        {onDelete && (
          <IconButtonStyled
            label='verwijderen'
            size='small'
            className='deleteButton'
            onClick={e => {
              e.stopPropagation()
              onDelete(data)
            }}
          >
            <CloseIcon />
          </IconButtonStyled>
        )}
      </CardWrapper>
    )
  },
  (prevProps, nextProps) => {
    return (
      compareArraysAndObjects(prevProps.data, nextProps.data) &&
      prevProps.onDelete === nextProps.onDelete
    )
  },
)

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Action, Location } from 'history'
import { Prompt } from 'react-router-dom'
import { FileChanges, FormLabels } from '../Form/formTypes'
import { useConfirmModal, FilesWillBeDeletedWarning } from '../ConfirmModal'
import { defaultFormLabels } from '../Form/defaultFormLabels'

export function NavigationPrompt({
  open,
  beforeCancelForm,
  isEditing = false,
  translations: translationsProp,
  fileChanges,
}: {
  open: boolean
  translations?: Partial<FormLabels>
  beforeCancelForm?: () => Promise<any>
  isEditing?: boolean
  fileChanges?: FileChanges
}) {
  const confirmModal = useConfirmModal()
  const history = useHistory()
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const translations: FormLabels = {
    ...defaultFormLabels,
    ...translationsProp, // overwite default
  }

  const handleConfirmNavigationClick = async () => {
    beforeCancelForm && (await beforeCancelForm())

    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname)
    }
  }, [history, confirmedNavigation, lastLocation])

  const handleBlockedNavigation = (
    location: Location<unknown>,
    _: Action,
  ): string | boolean => {
    if (!confirmedNavigation && open) {
      confirmModal({
        title: translations.closeText,
        confirmLabel: translations.closing,
        cancelLabel: translations.staying,
        message: isEditing
          ? translations.closeWarningEdit
          : translations.closeWarning,
        content: (
          <FilesWillBeDeletedWarning
            files={fileChanges?.filesUploaded}
            labels={translations}
          />
        ),
        onConfirm: handleConfirmNavigationClick,
      })
      setLastLocation(location)
      return false
    }
    return true
  }

  return (
    <>
      <Prompt when={open} message={handleBlockedNavigation} />
    </>
  )
}

import { Form } from '@fivano/core'
import useMongo from 'hooks/useMongo'
import { personsConfig } from '@fivano/models'
import { PersonFormInputs } from './PersonFormInputs'

export const PersonForm = ({
  personType,
  docTitle,
  onCloseForm,
  editingDocID,
}) => {
  const { updateDoc, useRefetchDoc, createDoc } = useMongo()

  const buildPerson = data => {
    data.personType = personType
    return data
  }

  const createPerson = async data => {
    return await createDoc({ collection: 'persons', data })
  }
  const updatePerson = async data => {
    return await updateDoc({ collection: 'persons', docID: editingDocID, data })
  }

  const { status, docData } = useRefetchDoc({
    collection: 'persons',
    docID: editingDocID,
    disabled: !editingDocID,
  })

  return (
    <Form
      docLabel={docTitle}
      docData={
        !!editingDocID ? docData : { gender: { label: 'Onbekend', value: 'O' } }
      }
      loading={status === 'loading'}
      wrapInside='page'
      modelConfig={personsConfig}
      buildDocData={buildPerson}
      createDoc={createPerson}
      updateDoc={updatePerson}
      editingDocID={editingDocID}
      hasDetails={true}
      onCloseForm={onCloseForm}
      onError={() => console.log}
      formInputs={({ formObject, showAllFields }) => {
        return (
          <PersonFormInputs
            formObject={formObject}
            showAllFields={showAllFields}
          />
        )
      }}
    />
  )
}

import { Form } from '@fivano/core'
import useMongo from 'hooks/useMongo'
//@ts-ignore
import { courseGroupsConfig } from '@fivano/models'
import { CourseGroupFormInputs } from './CourseGroupFormInputs'

export const CourseGroupForm = ({ onCloseForm, editingDocID }) => {
  const { updateDoc, useRefetchDoc, createDoc } = useMongo()

  const createGroup = async data => {
    return await createDoc({ collection: 'courseGroups', data })
  }
  const updateGroup = async data => {
    return await updateDoc({
      collection: 'courseGroups',
      docID: editingDocID,
      data,
    })
  }

  const { status, docData } = useRefetchDoc({
    collection: 'courseGroups',
    docID: editingDocID,
    disabled: !editingDocID,
  })

  return (
    <Form
      docLabel='Lesgroepen'
      docData={docData}
      wrapInside='modal'
      modelConfig={courseGroupsConfig}
      loading={status === 'loading'}
      createDoc={createGroup}
      updateDoc={updateGroup}
      editingDocID={editingDocID}
      hasDetails={true}
      onCloseForm={onCloseForm}
      onError={() => console.log}
      formInputs={({ formObject, showAllFields }) => {
        return (
          <CourseGroupFormInputs
            formObject={formObject}
            showAllFields={showAllFields}
          />
        )
      }}
    />
  )
}

import React from 'react'
import { Button, Grid, styled } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { useGridApiContext } from '../hooks/useGridApiContext'
import { GridToolbarEdit } from './GridToolbarEdit'

const StyledBox = styled(Grid)(({ theme }) => ({
  padding: '20px 20px 20px 20px',
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

export const GridToolbar = ({
  isEditable = true,
  isEditing,
  isLoading,
  onEdit,
  onSubmit,
}: {
  isEditable?: boolean
  isEditing: boolean
  isLoading: string
  onSubmit: (columns: GridColDef[]) => void
  onEdit: (bool: boolean) => void
}) => {
  const apiRef = useGridApiContext()
  return (
    <>
      {isEditable && (
        <StyledBox container justifyContent='flex-end'>
          <Grid item>
            <Button
              variant={isEditing ? 'contained' : 'outlined'}
              disabled={!!isLoading}
              onClick={async () => {
                if (isEditing) {
                  const columns = await apiRef.current.state.columns.all.map(
                    field => apiRef.current.state.columns.lookup[field],
                  )
                  onSubmit(columns)
                } else {
                  onEdit(true)
                }
              }}
            >
              {isEditing ? 'Opslaan' : 'Aanpassen'}
            </Button>
          </Grid>
          {isEditing && <GridToolbarEdit />}
        </StyledBox>
      )}
    </>
  )
}

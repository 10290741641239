import React from 'react'
import { Grid, Typography } from '@mui/material'
import { ColorPicker } from '@fivano/core'

type ThemeColorSettingsProps = {
  theme: any
  themeName: string
  formObject: any
}

export const ThemeColorSettings = ({
  theme,
  themeName,
  formObject,
}: ThemeColorSettingsProps) => {
  return (
    <Grid item xs={12} md={6}>
      <Typography variant='subtitle2'>{themeName}</Typography>
      <Grid container spacing={1}>
        {Object.keys(theme).map((color, index) => {
          return (
            <Grid item key={`${color}_${index}`}>
              <Typography variant='caption'>{color}</Typography>
              <ColorPicker
                name={`${themeName}_${color}`}
                defaultValue={theme[color]}
                formObject={formObject}
              />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

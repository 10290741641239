import React, { useState, useEffect } from 'react'
import { Grid, Box, styled, Typography } from '@mui/material'
import { format } from 'date-fns'
import { Avatar } from 'app/components/Avatar'
import { Person } from 'types/Person'

const GridStyled = styled(Grid)(() => ({
  minHeight: 56,
  padding: '4px 16px 4px 16px',
  alignItems: 'center',
}))

type PersonListItemProps = {
  /**Data object belonging to specific person*/
  personData: Person
  /**Visible label when a person is archived*/
  archivedLabel?: any
  /**Boolean whether the birthday is shown with the person or not*/
  showBirthday?: boolean
}

export function PersonListItem({
  personData,
  archivedLabel,
  showBirthday,
}: PersonListItemProps) {
  const [birthdateString, setBirthdateString] = useState('')

  useEffect(() => {
    if (personData?.birthdatePers) {
      const birthDate: any = personData?.birthdatePers
      setBirthdateString(
        format(new Date(birthDate?.seconds * 1000), 'dd/MM/yyyy'),
      )
    }
  }, [personData])

  return (
    <GridStyled container item>
      <Grid item>
        <Box mr={2}>
          <Avatar personData={personData} />
        </Box>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant='body1'>
              {personData.firstNamePers} {personData.middleNamePers}{' '}
              {personData.lastNamePers}
            </Typography>
          </Grid>
          {archivedLabel && <Grid item>{archivedLabel}</Grid>}
        </Grid>

        {birthdateString && showBirthday && (
          <Grid container item>
            <Typography variant='body2' color='textSecondary'>
              {birthdateString}
            </Typography>
          </Grid>
        )}
        {personData?.personTypePers === 'relationPerson' &&
          personData?.addressCityPers && (
            <Grid container item>
              <Typography variant='body2' color='textSecondary'>
                {personData?.addressCityPers}
              </Typography>
            </Grid>
          )}
      </Grid>
    </GridStyled>
  )
}

import React, { useEffect, useState } from 'react'
import { useFirestore } from 'react-redux-firebase'
import { useForm } from 'react-hook-form'
import {
  differenceInDays,
  addDays,
  subDays,
  setMinutes,
  format,
} from 'date-fns'
import { setHours } from 'date-fns/esm'
import { personFullName } from 'model/person'
import { Paper, Box, Grid, Typography, Button, styled } from '@mui/material'
import { DatePicker, TextField, PageContainer } from '@fivano/core'
import { FoldingListview } from 'app/components/Listview/FoldingListView'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { lunchBreadcrumb, initialLunchValues } from './Lunch'
import { Skeleton } from '@mui/material'
import { convertToDate } from 'utils/convertToDate'

const weekdays = Object.keys(initialLunchValues)

const ButtonStyled = styled(Button)(() => ({
  height: '40px',
}))

const SkeletonStyled = styled(Skeleton)(() => ({
  paddingTop: '60px',
}))

type LunchDataArray = {
  mondayLure: number
  tuesdayLure: number
  wednesdayLure: number
  thursdayLure: number
  fridayLure: number
  saturdayLure: number
  sundayLure: number
  personIDLure: string
  startDateLure: any
}[]

type ConvertedArrayData = {
  datums: string
  id: string
  name: string
  subItems: {
    amount: number
    date: Date
  }[]
  totalLunches: number
}[]

type SubItems = {
  amount: number
  date: Date
}

export const LunchOverview = () => {
  const firestore = useFirestore()
  const [convertedLunchdata, setConvertedLunchData] =
    useState<ConvertedArrayData>()
  const [expandedArrayIDs, setExpandedArrayIDs] = useState<string[]>([])
  const formObject = useForm()
  const { watch, setValue, reset } = formObject
  const start: any = watch('startDate')
  const end: any = watch('endDate')
  const duration: any = watch('duration')
  const startDate = setHours(setMinutes(start, 0), 0)
  const endDate = setHours(setMinutes(end, 0), 0)

  const handleQuery = (startDate, endDate) => {
    firestore
      .get({
        collection: 'lunch',
        where: [
          ['startDateLure', '>=', subDays(startDate, 7)],
          ['startDateLure', '<=', endDate],
        ],
      })
      .then((response: any) => {
        const lunchDataArray: LunchDataArray = []
        response.docs.forEach(element => {
          const data = element.data()
          lunchDataArray.push(data)
        })
        const personLunchData: any = {}
        if (lunchDataArray?.length > 0) {
          lunchDataArray.forEach(async lunchweek => {
            // Create array with all dates of days in range between startDate and endDate
            const dateRangeArray: Date[] = []
            let date = startDate
            while (differenceInDays(date, endDate) < 1) {
              dateRangeArray.push(date)
              date = addDays(date, 1)
            }
            if (personLunchData[lunchweek.personIDLure]) {
              personLunchData[lunchweek.personIDLure] =
                // eslint-disable-next-line no-self-assign
                personLunchData[lunchweek.personIDLure]
            } else {
              personLunchData[lunchweek.personIDLure] = []
            }

            weekdays.forEach((day, index) => {
              const dayObject = {
                amount: lunchweek[day],
                date: addDays(convertToDate(lunchweek.startDateLure), index),
              }
              if (dayObject.date >= startDate && dayObject.date <= endDate) {
                personLunchData[lunchweek.personIDLure].push(dayObject)
              }
            })

            // Loop over personLunchData and for every unique person id create a personObject with value as subItem
            const personLunchDataArray: ConvertedArrayData = []
            await Promise.all(
              Object.entries(personLunchData).map(async ([uid, lunchItems]) => {
                await createLunchPersonData(
                  uid,
                  lunchItems,
                  dateRangeArray,
                  endDate,
                  startDate,
                ).then((response: any) => {
                  personLunchDataArray.push(response)
                })
              }),
            )
            setConvertedLunchData(personLunchDataArray)
          })
        }
      })
  }

  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          accessor: 'name',
          gridProps: {
            xs: 5,
            sm: 5,
            md: 5,
          },
        },
        {
          Header: 'Datums',
          accessor: 'datums',
          gridProps: {
            xs: 3,
            sm: 3,
            md: 3,
          },
          Cell: data => (
            <Grid item marginLeft='-16px'>
              <Typography variant='body2'>
                {data.row.original?.datums}
              </Typography>
            </Grid>
          ),
        },
        {
          Header: 'Aantal',
          accessor: 'totalAmount',
          gridProps: {
            xs: 3,
            sm: 3,
            md: 3,
          },
          Cell: data => (
            <Grid item marginLeft='-18px'>
              <Typography variant='body2'>
                {data.row.original.totalLunches}
              </Typography>
            </Grid>
          ),
        },
      ],
    },
  ]

  const createLunchPersonData = (
    key,
    value,
    dateRange: Date[],
    endDate,
    startDate,
  ) => {
    return new Promise(async resolve => {
      let name = key
      await firestore
        .collection('users')
        .doc(key)
        .get()
        .then((response: any) => {
          name = response.exists ? personFullName(response.data()) : key
        })
      let totalLunches = 0
      value.forEach(item => (totalLunches += item.amount))

      // Create new subItems array
      const newSubItems: SubItems[] = []
      dateRange.forEach(date => {
        const dateMatch = value.find(
          item => format(item.date, 'ddMMyyyy') === format(date, 'ddMMyyyy'),
        )
        if (dateMatch) {
          newSubItems.push(dateMatch)
        } else {
          newSubItems.push({ amount: 0, date: date })
        }
      })

      const personObject = {
        id: key,
        name: name,
        datums: `${startDate?.toLocaleDateString('nl-NL', {
          weekday: 'short',
          month: 'long',
          day: 'numeric',
        })} - ${endDate?.toLocaleDateString('nl-NL', {
          weekday: 'short',
          month: 'long',
          day: 'numeric',
        })}`,
        totalLunches: totalLunches,
        subItems: newSubItems,
      }
      resolve(personObject)
    })
  }
  // When startDate or endDate changes calculate duration based on startDate
  useEffect(() => {
    if (startDate instanceof Date && endDate instanceof Date) {
      const newDuration: any = differenceInDays(endDate, startDate)
      setValue('duration', newDuration)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end])

  // When duration changes calculate startDate or endDate depending which value is enabled
  useEffect(() => {
    if (start && duration) {
      const newEndDate: any = addDays(startDate, parseInt(duration))
      setValue('endDate', newEndDate)
      handleQuery(startDate, newEndDate)
    } else if (end && duration) {
      const newStartDate: any = subDays(endDate, parseInt(duration))
      setValue('startDate', newStartDate)
      handleQuery(newStartDate, endDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration])

  const handleExpanding = data => {
    if (expandedArrayIDs.includes(data)) {
      const copy = [...expandedArrayIDs]
      const index = copy.indexOf(data)
      copy.splice(index)
      setExpandedArrayIDs(copy)
    } else {
      setExpandedArrayIDs([...expandedArrayIDs, data])
    }
  }

  return (
    <>
      <PageContainer>
        <Breadcrumbs breadcrumbs={lunchBreadcrumb('Lunch overzicht')} />
        <Paper>
          <Box p={2}>
            <Grid container spacing={1}>
              <Grid item>
                <DatePicker
                  name='startDate'
                  formObject={formObject}
                  label='Start datum'
                  maxDate={endDate}
                />
              </Grid>
              <Grid item width='80px'>
                <TextField
                  name='duration'
                  formObject={formObject}
                  variant='outlined'
                  placeholder='Duur'
                  type='number'
                />
              </Grid>
              <Grid item>
                <DatePicker
                  name='endDate'
                  formObject={formObject}
                  label='Eind datum'
                  minDate={startDate}
                />
              </Grid>
              <Grid item>
                <ButtonStyled
                  variant='contained'
                  color='primary'
                  onClick={() => reset()}
                >
                  filters wissen
                </ButtonStyled>
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            {start && end ? (
              convertedLunchdata ? (
                <FoldingListview
                  columns={listColumns}
                  data={convertedLunchdata}
                  expandedRows={expandedArrayIDs}
                  handleExpanded={data => handleExpanding(data)}
                />
              ) : (
                <SkeletonStyled
                  variant='rectangular'
                  width='100%'
                  height='48px'
                />
              )
            ) : (
              <Box pl={2} pb={1}>
                <Typography variant='body2'>
                  Selecteer een start datum en eind datum
                </Typography>
              </Box>
            )}
          </Grid>
        </Paper>
      </PageContainer>
    </>
  )
}

import React from 'react'
import { Box, Button, Grid, styled } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { GridToolbarEditLayout } from './GridToolbar/GridToolbarEditLayout'
import { useGridApiContext } from './hooks/useGridApiContext'

const StyledBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

export const GridToolbar = ({
  isLoading,
  submitGridSettings,
  editGridLayout,
}: {
  isLoading: string
  submitGridSettings: (columns: GridColDef[]) => void
  editGridLayout: boolean
}) => {
  const apiRef = useGridApiContext()
  return (
    <div>
      {editGridLayout && (
        <StyledBox px={2} pt={2}>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <Button
                variant='outlined'
                disabled={!!isLoading}
                onClick={async () => {
                  const columns = await apiRef.current.state.columns.all.map(
                    field => apiRef.current.state.columns.lookup[field],
                  )
                  submitGridSettings(columns)
                }}
              >
                Indeling opslaan
              </Button>
            </Grid>
          </Grid>
          <GridToolbarEditLayout />
        </StyledBox>
      )}
    </div>
  )
}

import { FormInputsBuilder } from 'app/components/Form/FormInputsBuilder'

const FormFieldInputs = ({ formObject }) => {
  const inputsConfig = [
    {
      type: 'Grid',
      props: { container: true, spacing: 1, paddingTop: '16px' },
      content: [
        {
          type: 'FormRow',
          icon: 'title',
          content: [
            {
              type: 'TextField',
              props: {
                name: 'name',
                label: 'Module naam',
                rules: { required: 'Dit veld is verplicht.' },
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'subject',
          content: [
            {
              type: 'TextField',
              props: {
                name: 'description',
                label: 'Module omschrijving',
                rows: 4,
                multiline: true,
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          content: [
            {
              type: 'TextField',
              props: {
                name: 'minParticipants',
                label: 'Minmaal aantal leerlingen',
                type: 'number',
                rules: { required: 'Dit veld is verplicht.' },
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          content: [
            {
              type: 'AutoComplete',
              props: {
                name: 'subsequentCourse',
                label: 'Vervolg lessen',
                optionsCollection: 'courseModules',
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          content: [
            {
              type: 'AutoComplete',
              props: {
                name: 'courseCompetences',
                label: 'Competenties',
                rules: { required: 'Dit veld is verplicht.' },
                optionsCollection: 'courseCompetences',
                multiple: true,
              },
            },
          ],
        },
      ],
    },
  ]

  return (
    <FormInputsBuilder inputsConfig={inputsConfig} formObject={formObject} />
  )
}

export const CourseModulesFormInputs = ({ formObject }) => {
  return <FormFieldInputs formObject={formObject} />
}

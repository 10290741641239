import { routeObjectType } from 'app/router/types'
import { MusicTrackForm } from './MusicTrackForm'
import { MusicTracksPage } from './MusicTracksPage'
import { PlayListForm } from './PlaylistForm'
import { PlaylistView } from './PlaylistView'

export const songstarterRoutes: routeObjectType = {
  songstarterTracks: {
    path: '/songstarter/tracks',
    component: MusicTracksPage,
    claims: { mt: 1 },
  },
  songstarterTrackAdd: {
    path: '/songstarter/tracks/formulier',
    component: MusicTrackForm,
    claims: { mt: 5 },
  },
  songstarterTracksEdit: {
    path: '/songstarter/tracks/:musicTrackID/formulier',
    component: MusicTrackForm,
    claims: { mt: 1 },
  },
  songstarterPlaylists: {
    path: '/songstarter/playlists',
    component: PlaylistView,
    claims: { mt: 5 },
  },
  songstarterPlaylistsAdd: {
    path: '/songstarter/playlists/formulier',
    component: PlayListForm,
    claims: { mt: 1 },
  },
  songstarterPlaylistsEdit: {
    path: '/songstarter/playlists/:playlistID/formulier',
    component: PlayListForm,
    claims: { mt: 1 },
  },
}

import React from 'react'
import useMongo from 'hooks/useMongo'
import { useHistory } from 'react-router'
import { DataGridNew } from 'app/components/DataGridNew'
import { GridColDef } from '@mui/x-data-grid-pro'
import { FloatingButton, PageContainer } from '@fivano/core'
import { Paper } from '@mui/material'
import { Breadcrumbs } from 'app/components/Breadcrumbs'

export const roleProfileColumnsDesktop: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Rollen Profiel',
    disableColumnMenu: true,
    width: 500,
  },
]

export const roleProfileColumnsMobile: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Rollen Profiel',
    disableColumnMenu: true,
    width: 300,
  },
]

export const RoleProfilePage = () => {
  const { useQueryDocs } = useMongo()
  const history = useHistory()

  const { docsData } = useQueryDocs({
    collection: 'roleProfiles',
    array: true,
  })

  return (
    <PageContainer maxWidth='lg'>
      <Breadcrumbs />
      <Paper>
        <DataGridNew
          name='roleProfiles'
          rows={docsData}
          columns={roleProfileColumnsDesktop}
          columnsMobile={roleProfileColumnsMobile}
          hideFooterSelectedRowCount={true}
          hideFooterRowCount={true}
          isEditable={true}
          onRowClick={params =>
            history.push(`${history.location.pathname}/${params.row._id}`)
          }
        />
      </Paper>
      <FloatingButton
        label='Rollen profiel toevoegen'
        onClick={() => history.push(`${history.location.pathname}/toevoegen`)}
      />
    </PageContainer>
  )
}

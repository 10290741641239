import React, { useState } from 'react'
import { Dialog, LoadingOverlay, Modal } from '@fivano/core'
import { PersonForm } from './PersonForm'

type PersonFormModalProps = {
  title: string
  personTypePers: string
  defaultValues: any
  extraData?: any
  formSettings: any
  onClose?: any
  onAddSuccess?: any
  onUpdateSuccess?: any
  onSubmitOverwrite?: any
  open?: any
  disableModal?: boolean
  personID?: string
}

export const PersonFormModal = ({
  title,
  personTypePers,
  defaultValues,
  extraData,
  formSettings,
  onClose,
  onAddSuccess,
  onUpdateSuccess,
  onSubmitOverwrite,
  open,
  personID,
  disableModal = false,
}: PersonFormModalProps) => {
  const [formDirty, setFormDirty] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [editForm, setEditForm] = useState(false)

  // ==== DELETE FILE LOGIC ==== //
  const [deleteModal, setDeleteModal] = useState({
    id: '',
    data: {},
    message: '',
  })
  // ==== CHECK IF FORM IS DIRTY ==== //
  const handleFormDirty = dirty => {
    setFormDirty(dirty)
  }

  // ==== CLOSE MODAL, SHOW DIALOG WHEN FORM DIRTY ==== //
  const handleClose = () => {
    if (formDirty) {
      setDeleteModal({
        //@ts-ignore
        id: defaultValues.id,
        data: defaultValues,
        message: `Let op!`,
      })
    } else {
      onClose()
    }
  }

  const handleCloseModal = () => {
    !disableModal && onClose()
  }

  const onLoading = loading => {
    setLoadingMessage(loading)
  }

  const handleEditForm = editForm => {
    setEditForm(editForm)
  }

  return (
    <>
      {!disableModal ? (
        <>
          <Dialog
            deleteTitle={deleteModal.message}
            open={deleteModal.id !== ''}
            onClose={() => setDeleteModal({ id: '', data: {}, message: '' })}
            onCancel={() => setDeleteModal({ id: '', data: {}, message: '' })}
            onDelete={handleCloseModal}
            deleteSubtitle={
              'Weet je zeker dat je wil sluiten zonder op te slaan?'
            }
            removeLabel='Sluiten'
          />
          <Modal
            maxWidth='sm'
            onClose={handleClose}
            closeButton
            open={open}
            title={editForm ? `${title} bewerken` : `${title} toevoegen`} // implement toevoegen / bewerken in every modal
          >
            {loadingMessage !== '' && <LoadingOverlay label={loadingMessage} />}

            <PersonForm
              personID={personID}
              title={title}
              defaultValues={defaultValues}
              onClose={handleClose}
              onHandleFormDirty={handleFormDirty}
              onCloseModal={handleCloseModal}
              onLoading={onLoading}
              onEditForm={handleEditForm}
              personTypePers={personTypePers}
              formSettings={formSettings}
              extraData={extraData}
              onAddSuccess={onAddSuccess}
              onUpdateSuccess={onUpdateSuccess}
            />
          </Modal>
        </>
      ) : (
        <PersonForm
          title={title}
          personID={personID}
          defaultValues={defaultValues}
          onClose={handleClose}
          onHandleFormDirty={handleFormDirty}
          onCloseModal={handleCloseModal}
          onLoading={onLoading}
          onEditForm={handleEditForm}
          personTypePers={personTypePers}
          formSettings={formSettings}
          extraData={extraData}
          onAddSuccess={onAddSuccess}
          onUpdateSuccess={onUpdateSuccess}
          disableModal={disableModal}
        />
      )}
    </>
  )
}

import { Box, ButtonBase, Grid, styled } from '@mui/material'

export const HeaderGridStyled = styled(Grid)(({ theme }) => ({
  minHeight: 32,
  padding: '0px 24px',
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

export const RowBoxStyled = styled(Box, {
  shouldForwardProp: prop => prop !== 'actionButtons',
})<any>(({ theme, actionButtons }) => ({
  ...(actionButtons && {
    '&:hover .MuiGrid-root': {
      display: 'flex',
    },
  }),
  width: '100%',
  position: 'relative',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}))

export const RowGridStyled = styled(Grid)(() => ({
  width: '100%',
  minHeight: 56,
  padding: '0px 24px',
}))

export const ClickBoxStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  right: 16,
  top: 0,
  height: '100%',
}))

export const ActionGridStyled = styled(Grid)(() => ({
  display: 'none',
  position: 'absolute',
  zIndex: 5,
  height: '100%',
  width: 'auto',
  top: 4,
  right: 36,
  '@media (hover: none) and (pointer: coarse)': {
    display: 'block',
  },
}))

export const ButtonBaseStyled = styled(ButtonBase)(() => ({
  width: '100%',
  textAlign: 'left',
}))

import React, { useEffect } from 'react'
import { Grid, Box, styled } from '@mui/material'
import { useFieldArray } from 'react-hook-form'
import { IconButton } from '@fivano/core'
import { Add, Close } from '@mui/icons-material'
import { RelationAndContactInput } from 'app/components/Form'

const ContainerGridStyled = styled(Grid)(() => ({
  width: 'calc(100% - 40px)',
  marginBottom: 4,
}))

const ItemGridStyled = styled(Grid)(() => ({
  width: '40px',
  height: 40,
  marginTop: '-4px',
}))

export const RelationsSelector = ({
  boardCardData,
  setRelationsUpdated,
  formObject,
}) => {
  const { watch, control } = formObject
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'relationsBoca',
  })

  useEffect(() => {
    const relationsUpdated =
      fields.length !== boardCardData?.relationsBoca.length
    setRelationsUpdated(relationsUpdated)
  }, [fields, boardCardData, setRelationsUpdated])

  return (
    <Box mb={1} width='100%'>
      {fields.map((field: any, index) => {
        const relation = watch(`relationsBoca[${index}]`)?.relation
        return (
          <Box width='100%' key={field.id}>
            <Grid container>
              <ContainerGridStyled container spacing={1}>
                <RelationAndContactInput
                  formObject={formObject}
                  relationName={`relationsBoca[${index}].relation`}
                  contactsName={`relationsBoca[${index}].persons`}
                  label='Bedrijf kiezen'
                  required
                  rules={{ required: 'Verplicht veld' }}
                  multipleContacts={true}
                  filterRelations={watch('relationsBoca')
                    .map(rel => rel?.relation?.value)
                    .filter(val => val !== relation?.value)}
                />
              </ContainerGridStyled>
              <ItemGridStyled item xs={1} sm={1}>
                {index !== 0 ? (
                  <IconButton
                    label='Verwijderen'
                    onClick={() => {
                      remove(index)
                    }}
                    size='large'
                  >
                    <Close fontSize='small' />
                  </IconButton>
                ) : (
                  <IconButton
                    label='Bedrijf toevoegen'
                    onClick={() => {
                      append({})
                    }}
                    size='large'
                  >
                    <Add />
                  </IconButton>
                )}
              </ItemGridStyled>
            </Grid>
          </Box>
        )
      })}
    </Box>
  )
}

import React from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { Grid, InputAdornment, styled } from '@mui/material'
import { TextField, FormRow } from '@fivano/core'
import { timeStringToSeconds } from 'utils/timeToSeconds'

const GridStyled = styled(Grid, {
  shouldForwardProp: prop => prop !== 'showField',
})<any>(({ showField }) => ({
  maxWidth: 'none',
  display: 'none',
  ...(showField && {
    display: 'block',
  }),
}))

const TextFieldStyled = styled(TextField)(() => ({
  '& input': {
    zIndex: 3,
  },
  '& input[type="time"]::-webkit-calendar-picker-indicator': {
    background: 'transparent',
    cursor: 'pointer',
  },
  '& .MuiOutlinedInput-root': {
    paddingRight: 6,
  },
}))

const InputAdornmentStyled = styled(InputAdornment)(({ theme }) => ({
  position: 'absolute',
  marginRight: '8px',
  right: 0,
  zIndex: 1,
  color: `${
    theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.54)'
      : 'rgba(255, 255, 255)'
  }`,
}))

export const weekdayLabels = [
  { value: 'mo', label: 'Maandag' },
  { value: 'tu', label: 'Dinsdag' },
  { value: 'we', label: 'Woensdag' },
  { value: 'th', label: 'Donderdag' },
  { value: 'fr', label: 'Vrijdag' },
  { value: 'sa', label: 'Zaterdag' },
  { value: 'su', label: 'Zondag' },
]

export const WeekHoursInput = ({ formObject, showAllFields }) => {
  const { getValues, setValue } = formObject
  const calculateTotalHours = () => {
    let totalSeconds = 0
    weekdayLabels.forEach(day => {
      const timeString = getValues(day.value)
      if (timeString !== '00:00' && timeString !== undefined) {
        totalSeconds += timeStringToSeconds(timeString)
      }
    })
    const totalHours = `${Math.floor(totalSeconds / 60 / 60)}:${(
      Math.floor(totalSeconds / 60) % 60
    )
      .toString()
      .padStart(2, '0')}`
    return totalHours
  }

  return (
    <FormRow icon={<ScheduleIcon />}>
      <Grid container spacing={1}>
        {weekdayLabels.map((hoursDay, index) => {
          const isWeekend = index === 5 || index === 6
          const hasValue =
            getValues(hoursDay.value) !== '00:00' &&
            getValues(hoursDay.value) !== undefined
          const showField = !isWeekend || hasValue || showAllFields
          return (
            <GridStyled item xs={2} key={hoursDay.value} showField={showField}>
              <TextFieldStyled
                // TODO create TimePicker and DateTimePicker components
                label={`${hoursDay.label}`}
                name={hoursDay.value}
                formObject={formObject}
                type='time'
                defaultValue='00:00'
                onBlur={() => {
                  setValue('totalHoursWeek', calculateTotalHours())
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornmentStyled position='end'>
                      <AccessTimeIcon />
                    </InputAdornmentStyled>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // timestep of 5min
                }}
              />
            </GridStyled>
          )
        })}
        <Grid item xs={2}>
          <TextField
            defaultValue='00:00'
            formObject={formObject}
            name='totalHoursWeek'
            label='Totaal'
            readOnly
          />
        </Grid>
      </Grid>
    </FormRow>
  )
}

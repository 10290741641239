import React, { useCallback, MouseEvent } from 'react'
import { FormControlLabel, Grid, styled, Switch } from '@mui/material'
import { allGridColumnsSelector, useGridSelector } from '@mui/x-data-grid-pro'
import { useGridApiContext } from '../hooks/useGridApiContext'

export const StyledContainer = styled(Grid)(({ theme }) => ({
  padding: '6px 14px',
}))

export const GridToolbarEdit = () => {
  const apiRef = useGridApiContext()
  const columns = useGridSelector<any>(apiRef, allGridColumnsSelector).filter(
    x => x.field !== 'actions',
  )

  const toggleColumn = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { name } = event.target as HTMLInputElement
      const column = apiRef.current.getColumn(name)
      apiRef.current.setColumnVisibility(name, !!column.hide)
    },
    [apiRef],
  )

  return (
    <StyledContainer container>
      <Grid item xs={12}>
        <Grid container>
          {columns.map(column => (
            <Grid item xs={12} sm={6} md={2} key={column.field}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!column.hide}
                    onClick={toggleColumn}
                    name={column.field}
                    color='primary'
                    size='small'
                  />
                }
                label={column.headerName || column.field}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

import BusinessIcon from '@mui/icons-material/Business'
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed'
import CodeIcon from '@mui/icons-material/Code'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ScheduleIcon from '@mui/icons-material/Schedule'
import FastfoodIcon from '@mui/icons-material/Fastfood'
import AssignmentIcon from '@mui/icons-material/Assignment'
import DescriptionIcon from '@mui/icons-material/Description'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import RoomServiceOutlinedIcon from '@mui/icons-material/RoomServiceOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ColleagueOutlined from 'app/icons/ColleagueOutlined'
import { legacyRoutes } from 'app/router/legacyRoutes'
import { menuLayoutType } from '../../router/types'
import { AssignmentOutlined } from '@mui/icons-material'
import { songstarterMenu } from 'app/legacy/MusicTracks/songstarterMenu'

export const legacyMobileMenu = (settings: any) => {
  const menu: menuLayoutType[] = [
    {
      name: 'Home',
      icon: HomeOutlinedIcon,
      route: legacyRoutes.home,
    },
    {
      name: 'Deelnemers',
      icon: PersonOutlinedIcon,
      route: legacyRoutes.participants,
    },
    {
      name: 'Bedrijven',
      icon: BusinessIcon,
      route: legacyRoutes.relations,
    },
    {
      name: `Collega's`,
      icon: ColleagueOutlined,
      route: legacyRoutes.colleagues,
    },
    {
      name: 'CRM-borden',
      icon: DashboardOutlinedIcon,
      route: legacyRoutes.boards,
    },
    {
      name: 'Vacancies',
      icon: AssignmentOutlined,
      route: legacyRoutes.vacancies,
    },
    {
      name: 'Diensten',
      icon: RoomServiceOutlinedIcon,
      route: legacyRoutes.services,
    },
    {
      name: 'Trajecten',
      icon: DynamicFeedIcon,
      route: legacyRoutes.modules,
    },
    {
      name: `Toegewezen ${settings.tracks.labels.tracks}`,
      icon: ListAltIcon,
      route: legacyRoutes.trackAssignOverview,
    },
    {
      name: 'Licenties',
      icon: DescriptionIcon,
      route: legacyRoutes.licenses,
    },
    {
      name: 'Opdrachten overzicht',
      icon: AssignmentIcon,
      route: legacyRoutes.jobOpenings,
    },
    {
      name: 'Tijdregistratie',
      icon: ScheduleIcon,
      route: legacyRoutes.timeRegistration,
    },
    {
      name: 'Urenregistratie',
      icon: ScheduleIcon,
      route: legacyRoutes.durationRegistration,
    },
    {
      name: 'Lunch',
      icon: FastfoodIcon,
      route: legacyRoutes.lunchRegistration,
    },
    {
      name: 'Lunch overview',
      icon: FastfoodIcon,
      route: legacyRoutes.lunchOverview,
    },
    {
      name: 'Instellingen',
      icon: SettingsOutlinedIcon,
      route: legacyRoutes.settings,
    },
    {
      name: 'Development page',
      icon: CodeIcon,
      route: legacyRoutes.development,
    },
    ...songstarterMenu,
  ]
  return menu
}

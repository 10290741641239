import { useSnackbar } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { getAuth, signOut } from 'firebase/auth'

export const useSignOut = () => {
  const errorLogger = useErrorLogger()
  const { enqueueSnackbar } = useSnackbar()
  const auth = getAuth()
  return () =>
    signOut(auth)
      .then(() => {
        enqueueSnackbar('U bent uitgelogd.', {
          variant: 'success',
        })
      })
      .catch(error => {
        errorLogger({ error })
      })
}

import { courseTrackingRoutes } from './courseTrackingRoutes'
import { menuLayoutType } from 'app/router/types'
import { TimelineOutlined } from '@mui/icons-material'

export const courseTrackingMenu: menuLayoutType[] = [
  {
    name: 'Leerlingen volg systeem',
    icon: TimelineOutlined,
    route: courseTrackingRoutes.courseCompetencesList,
    subMenu: [
      {
        name: 'Competentie scores',
        route: courseTrackingRoutes.courseCompetenceScoresList,
      },
      {
        name: 'Les modules',
        route: courseTrackingRoutes.courseModulesList,
      },
      {
        name: 'Leerlingen',
        route: courseTrackingRoutes.studentsList,
      },
      {
        name: 'Les groepen',
        route: courseTrackingRoutes.courseGroupsList,
      },
    ],
  },
]

import { functionsURL } from 'utils/emulator'

export const revokeDownloadToken = filePath => {
  return new Promise((resolve, reject) => {
    fetch(`${functionsURL()}/removeDownloadToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        filePath: filePath,
      }),
    })
      .then(response => {
        return response.text()
      })
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

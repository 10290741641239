import React from 'react'
import { Modal } from '../Modal'
import {
  Typography,
  Button,
  DialogTitle,
  ButtonTypeMap,
  styled,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import CloseIcon from '@mui/icons-material/Close'

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}))

interface DialogProps {
  /**Id of dialog, set to make Dialog unique.*/
  id?: string | undefined
  /**Prop that determines if Dialog is open or not.*/
  open: boolean
  /**Function that is called when Dialog is closed. Also called when clicking outside Dialog.*/
  onClose: () => void
  /**Function that is called when Dialog is cancelled from being closed.*/
  onCancel: () => void
  /**Same as onClose, but this one can only be called with the buttons inside Dialog.*/
  onDelete: (data: any) => void
  /**Title of the dialog, shows at the top.*/
  deleteTitle: string
  /**Subtitle of the dialog.*/
  deleteSubtitle?: string
  /**Label for the cancel button.*/
  cancelLabel?: string
  /**Label for the delete button.*/
  removeLabel?: string
  /**Sets the variant of the delete button.*/
  removeButtonVariant?: ButtonTypeMap['props']['variant']
  /**Sets the color of the delete button.*/
  removeButtonColor?: ButtonTypeMap['props']['color']
  /**Sets the variant of the cancel button.*/
  cancelButtonVariant?: ButtonTypeMap['props']['variant']
  /**Sets the color of the cancel button.*/
  cancelButtonColor?: ButtonTypeMap['props']['color']
  /**The data that was produced by the form.*/
  data?: any
}

export function Dialog({
  open,
  onClose,
  deleteTitle,
  deleteSubtitle,
  cancelLabel,
  removeLabel,
  removeButtonVariant = 'contained',
  removeButtonColor,
  cancelButtonVariant,
  cancelButtonColor,
  onCancel,
  onDelete,
  data,
}: DialogProps) {
  return (
    <Modal maxWidth='xs' onClose={onClose} open={open}>
      <DialogTitle style={{ overflow: 'hidden' }}>
        <Typography variant='h6'>{deleteTitle}</Typography>
        <IconButtonStyled aria-label='close' onClick={onClose} size='large'>
          <CloseIcon />
        </IconButtonStyled>
      </DialogTitle>
      <DialogContent style={{ minHeight: '64px' }}>
        <DialogContentText id='alert-dialog-description'>
          {deleteSubtitle
            ? deleteSubtitle
            : 'Dit item zal permanent worden verwijderd.'}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          variant={cancelButtonVariant}
          color={cancelButtonColor}
          onClick={onCancel}
        >
          {cancelLabel ? cancelLabel : 'Blijven'}
        </Button>

        <Button
          variant={removeButtonVariant}
          color={removeButtonColor}
          onClick={() => {
            onDelete(data)
            onClose()
          }}
        >
          {removeLabel ? removeLabel : 'Verwijderen'}
        </Button>
      </DialogActions>
    </Modal>
  )
}

import React, { useState } from 'react'
import { Grid, capitalize, Link, Typography } from '@mui/material'
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader'
import { TrackAssignForm } from './TrackAssignForm'
import { TrackCard } from './TrackCard'
import { hasClaimLevel } from 'utils/hasClaimLevel'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { useSettings } from 'hooks/useSettings'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { ActionMenu, MenuButton, EmptyContent } from '@fivano/core'
import { Archive, DynamicFeed } from '@mui/icons-material'
import { TrackType } from 'types/Track'

type TracksWidgetProps = {
  participantID: string
}

export const TracksWidget = ({ participantID }: TracksWidgetProps) => {
  const [trackAssignModal, setTrackAssignModal] = useState({
    trackID: null,
    open: false,
  })
  const [showArchived, setShowArchived] = useState(false)
  const settings = useSettings()
  const labels = settings.tracks.labels
  const profile = useSelector(state => state.firebase.profile)
  const locationQuery = useLocationQuery()
  useFirestoreConnect([
    {
      collectionGroup: 'tracks',
      limit: 50,
      where: [
        ...locationQuery('locationIDTrack'),
        ['personIDTrack', '==', participantID],
        ['archivedTrack', '==', showArchived],
      ],
      orderBy: ['endDateTrack', 'desc'],
      storeAs: `persons_${participantID}_tracks`,
    },
  ])

  const trackData: TrackType[] = useSelector(
    state => state.firestore.ordered[`persons_${participantID}_tracks`],
  )

  return (
    <Grid item xs={12}>
      {trackAssignModal.open && (
        <TrackAssignForm
          onCloseForm={() => {
            setTrackAssignModal({ trackID: null, open: false })
          }}
          editingDocID={trackAssignModal.trackID}
          participantID={participantID}
        />
      )}
      <WidgetHeader
        title={capitalize(labels.tracks)}
        buttonLabel={
          hasClaimLevel(profile, { t: 3 }) ? `${labels.track} toewijzen` : ''
        }
        icon={DynamicFeed}
        onButtonClick={() => setTrackAssignModal({ trackID: null, open: true })}
        actions={
          <ActionMenu iconPosition='vertical'>
            <MenuButton
              label={
                showArchived
                  ? `Toon niet gearchiveerde ${labels.tracks}`
                  : `Toon gearchiveerde ${labels.tracks}`
              }
              icon={<Archive />}
              onClick={() => setShowArchived(!showArchived)}
            />
          </ActionMenu>
        }
      />
      <Grid container spacing={1}>
        {trackData?.length > 0 ? (
          trackData.map(track => {
            return (
              <Grid item xs={12} key={track.id}>
                <TrackCard
                  onEditTrack={data =>
                    setTrackAssignModal({ trackID: data.id, open: true })
                  }
                  locationID={track.locationIDTrack}
                  participantID={track.personIDTrack}
                  trackData={track}
                />
              </Grid>
            )
          })
        ) : (
          <Grid item xs={12}>
            {showArchived ? (
              <EmptyContent>
                <Typography variant='body1' display='inline'>
                  Geen gearchiveerde {labels.tracks} gevonden.{' '}
                </Typography>
                <Link onClick={() => setShowArchived(false)}>
                  Toon ongearchiveerde {labels.tracks}
                </Link>
              </EmptyContent>
            ) : (
              <EmptyContent>
                <Typography variant='body1' display='inline'>
                  Geen ongearchiveerd {labels.tracks} gevonden.
                </Typography>
                <Link onClick={() => setShowArchived(true)}>
                  Toon gearchiveerde {labels.tracks}
                </Link>
              </EmptyContent>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

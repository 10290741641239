import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'
import { emailRegex } from 'utils/regex'
import { Grid, Typography, Box, Button } from '@mui/material'
import { AuthWrapper } from 'app/containers/Authentication/components/AuthWrapper'
import { useSnackbar } from '@fivano/core'
import { TextField, LoadingOverlay } from '@fivano/core'
import { PasswordTextField } from './PasswordTextfield'
import { useErrorLogger } from 'hooks/useErrorLogger'

type UserAuthenticaiton = {
  email: string
  password: string
}
export function EmailRegistration() {
  const formObject = useForm()
  const { handleSubmit } = formObject
  const errorLogger = useErrorLogger()
  const history = useHistory()
  const firebase = useFirebase()
  const { enqueueSnackbar } = useSnackbar()

  const [loadingAuthorization, setLoadingAuthorization] = useState(false)
  const [error, setError] = useState('')

  const handleRegister = (data: UserAuthenticaiton) => {
    setLoadingAuthorization(true)
    firebase
      .auth()
      .createUserWithEmailAndPassword(data.email, data.password)
      .then((response: any) => {
        response.user
          ?.sendEmailVerification()
          .then(() => {
            enqueueSnackbar('Verificatie email verzonden.', {
              variant: 'success',
            })
          })
          .catch(error => {
            errorLogger({ error })
          })
      })
      .catch(error => {
        setLoadingAuthorization(false)
        if (error.code === 'auth/email-already-in-use') {
          setError(
            'Dit email adres wordt al gebruikt. Gebruiker een ander email adres of log in.',
          )
        } else if (error.code === 'auth/invalid-email') {
          setError('Ongeldig email adres, vul een geldig email adres in.')
        } else if (error.code === 'auth/operation-not-allowed') {
          setError(
            'Registreren via email en wachtwoord niet toegestaand. Vraag uw organisatie om het registreren via email en wachtwoord in te schakelen',
          )
        } else if (error.code === 'auth/weak-password') {
          setError(
            'Uw wachtwoord is niet veilig genoeg, gebruik een ander wachtwoord.',
          )
        } else {
          setError('Fout bij registreren, probeer opnieuw.')
        }
        errorLogger({ error })
      })
  }
  const [passwordValid, setPasswordValid] = useState(false)
  return (
    <>
      <Helmet>
        <title>Registreren</title>
      </Helmet>
      <AuthWrapper title='Account registreren'>
        {loadingAuthorization && (
          <LoadingOverlay
            label='Gegevens controleren en account registreren'
            customStyle={{ borderRadius: 4 }}
          />
        )}
        <form onSubmit={handleSubmit(handleRegister)}>
          <Typography variant='body1' color='error'>
            {error}
          </Typography>
          <Box p={1} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name='email'
                formObject={formObject}
                rules={{
                  required: 'Dit is een verplicht veld',
                  pattern: {
                    value: emailRegex,
                    message: 'Vul een geldig email adres in',
                  },
                }}
                label='E-mail'
                size='medium'
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordTextField
                formObject={formObject}
                label='Wachtwoord'
                name='password'
                onValidate={setPasswordValid}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type='submit'
                size='large'
                variant='contained'
                color='primary'
                disableElevation
                disabled={!passwordValid}
              >
                Account registreren
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color='primary'
                size='small'
                onClick={() => history.push('/inloggen')}
              >
                Al een account? Inloggen
              </Button>
            </Grid>
          </Grid>
        </form>
      </AuthWrapper>
    </>
  )
}

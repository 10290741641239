import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function MarkeerGelezen(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill='currentColor'
        d='M21,13.34C20.37,13.12 19.7,13 19,13A6,6 0 0,0 13,19C13,19.34 13.03,19.67 13.08,20H3A2,2 0 0,1 1,18V6C1,4.89 1.89,4 3,4H19A2,2 0 0,1 21,6V13.34M23.5,17L18.5,22L15,18.5L16.5,17L18.5,19L22,15.5L23.5,17M3,6V8L11,13L19,8V6L11,11L3,6Z'
      />
    </SvgIcon>
  )
}

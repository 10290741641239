import React from 'react'
import { useDroppable } from '@dnd-kit/core'
import { styled, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

const DroppableContainer = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'isOver' &&
    prop !== 'height' &&
    prop !== 'isEmpty' &&
    prop !== 'isOwn',
})<any>(({ theme, isOver, height, isEmpty, isOwn }) => ({
  height: height,
  backgroundColor: isOwn
    ? theme.palette.grey.A100
    : theme.palette.background.paper,
  borderRadius: 4,
  zIndex: 0,
  border:
    isOver && isEmpty && !isOwn
      ? `2px solid ${theme.palette.success.main}`
      : isOver && !isEmpty && !isOwn
      ? `2px solid ${theme.palette.error.main}`
      : !isEmpty && !isOver && !isOwn
      ? `2px solid ${theme.palette.text.primary}`
      : `2px solid ${theme.palette.divider}`,
}))

type DroppableTypes = {
  id: string
  isEmpty: boolean
  height: number
  createItem: (id: string) => void
  children: React.ReactNode
}

export const Droppable = ({
  id,
  isEmpty,
  createItem,
  height = 200,
  children,
}: DroppableTypes) => {
  const { isOver, active, setNodeRef } = useDroppable({
    id: id,
  })

  return (
    <DroppableContainer
      ref={setNodeRef}
      isOver={isOver}
      height={height}
      isEmpty={isEmpty}
      isOwn={id === active?.id}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        onClick={() => isEmpty && createItem(id)}
      >
        {isEmpty ? <EmptyDroppable /> : children}
      </div>
    </DroppableContainer>
  )
}

const EmptyDroppableCard = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 700,
  padding: 20,
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 4,
  color: 'rgba(0,0,0,0.4)',
}))

export const EmptyDroppable = () => {
  return (
    <EmptyDroppableCard>
      <AddCircleOutlineIcon />
      <Typography variant='h6'>Toevoegen</Typography>
    </EmptyDroppableCard>
  )
}

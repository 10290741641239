import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import {
  CounterBox,
  PageContainer,
  FloatingButton,
  useSnackbar,
  MenuButton,
  DateNavigation,
  ActionMenu,
} from '@fivano/core'
import WeekendCalender from 'app/icons/WeekendCalender'
import {
  Paper,
  Box,
  Grid,
  Typography,
  capitalize,
  Skeleton,
  styled,
} from '@mui/material'
import { addDays, format, startOfWeek } from 'date-fns'
import { useForm } from 'react-hook-form'
import { nl } from 'date-fns/locale'
import { isEmpty, useFirestore } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { isLoaded } from 'react-redux-firebase'
import { formChanged } from 'utils/formChanged'
import { LunchData, LunchType, WeekData } from './Lunchtypes'

const dayStyles = { padding: '8px 12px 0px 0px' }

const BoxStyled = styled(Box, {
  shouldForwardProp: prop => prop !== 'showWeekend',
})<any>(({ theme, showWeekend }) => ({
  ...(showWeekend && {
    padding: '16px',
    [theme.breakpoints.down('md')]: {
      height: '533px',
    },
  }),
}))

const ResponsiveGridStyled = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const TypographyStyled = styled(Typography)(() => ({
  ...dayStyles,
}))

const TotalGridStyled = styled(Grid, {
  shouldForwardProp: prop => prop !== 'showWeekend',
})<any>(({ theme, showWeekend }) => ({
  position: 'relative',
  right: '260px',
  ...dayStyles,
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    bottom: '140px',
    left: '0px',
    ...dayStyles,
  },
  ...(showWeekend && {
    ...dayStyles,
    right: '0px',
  }),
}))

const DaysGridStyled = styled(Grid, {
  shouldForwardProp: prop => prop !== 'showWeekend',
})<any>(({ showWeekend }) => ({
  ...dayStyles,
  ...(showWeekend && {
    visibility: 'hidden',
    ...dayStyles,
  }),
}))

export const lunchBreadcrumb = value => {
  return [
    { title: 'Home', path: '/' },
    { title: `${value}`, path: `/${value}` },
  ]
}

export const initialLunchValues = {
  mondayLure: 0,
  tuesdayLure: 0,
  wednesdayLure: 0,
  thursdayLure: 0,
  fridayLure: 0,
  saturdayLure: 0,
  sundayLure: 0,
}

export const Lunch = () => {
  const firestore = useFirestore()
  const { enqueueSnackbar } = useSnackbar()
  const profile = useSelector(state => state.firebase.profile)

  const [lunchCount, setLunchCount] = useState<number>()
  const [weekStartDate, setWeekStartDate] = useState<any>(
    startOfWeek(new Date(), { weekStartsOn: 1 }),
  )
  const [showWeekend, setShowWeekend] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  console.log(
    dataChanged,
    'console log added to fix warning to enable production build',
  )
  const [headerDays, setHeaderDays] = useState<Array<WeekData>>([])
  const formObject = useForm({
    defaultValues: initialLunchValues,
  })
  const {
    handleSubmit,
    watch,
    reset,
    formState: { dirtyFields },
  } = formObject

  useEffect(() => {
    firestore.get({
      collection: 'lunch',
      doc: `${profile.uid}_${format(weekStartDate, 'dd_MM_yyyy')}`,
      storeAs: `${profile.uid}_${format(weekStartDate, 'dd_MM_yyyy')}`,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekStartDate])

  const lunchData: LunchType[] = useSelector(
    state =>
      state.firestore.ordered[
        `${profile.uid}_${format(weekStartDate, 'dd_MM_yyyy')}`
      ],
  )

  useEffect(() => {
    if (isLoaded(lunchData)) {
      if (isEmpty(lunchData)) {
        reset(initialLunchValues)
      } else {
        reset(lunchData?.[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lunchData])

  // ==== SET DAYS WEEK BASED ON PICKED DATE ==== //
  useEffect(() => {
    const weekArray: Array<WeekData> = []
    const numberDays = 7
    const firstDayWeek = startOfWeek(weekStartDate, { weekStartsOn: 1 })
    for (let i = 0; i < numberDays; i++) {
      const day = addDays(firstDayWeek, i)
      weekArray.push({
        date: day,

        keyName: `${format(day, 'EEEE').toLocaleLowerCase()}Lure`,
      })
    }
    setHeaderDays(weekArray)
  }, [weekStartDate, showWeekend])

  // ==== SEND NEW DATA TO DATABASE ==== //
  const formSubmit = (data: LunchData) => {
    const lunchObject: LunchType = {
      ...data,
      personIDLure: profile.uid,
      startDateLure: weekStartDate,
    }
    firestore
      .collection('lunch')
      .doc(`${profile.uid}_${format(weekStartDate, 'dd_MM_yyyy')}`)
      .set(lunchObject)
      .then(() => {
        enqueueSnackbar('Lunch succesvol toegevoegd.', {
          variant: 'success',
        })
        reset(lunchObject)
      })
  }

  const weekDays = headerDays.map(day => day.keyName)
  const weekDayValues = watch<any>(weekDays)

  //Totaal berekenen wanneer totaal wijzigt//
  useEffect(() => {
    if (weekDayValues) {
      var sum = 0

      weekDays.forEach((day, index) => {
        sum += weekDayValues[index]
      })

      setLunchCount(sum)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekDayValues])

  useEffect(() => {
    if (formChanged(dirtyFields)) {
      setDataChanged(true)
    } else {
      setDataChanged(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formChanged(dirtyFields)])

  return (
    <PageContainer>
      <Breadcrumbs breadcrumbs={lunchBreadcrumb('Lunch')} />
      <Paper>
        <BoxStyled p={2} showWeekend={showWeekend === false}>
          <Grid container alignItems='center'>
            <DateNavigation
              name='date'
              onChange={date => date && setWeekStartDate(date)}
            />
            <Grid item marginLeft='auto'>
              <ActionMenu iconPosition='vertical'>
                <MenuButton
                  label={`${showWeekend ? 'Verberg' : 'Toon'} weekend`}
                  icon={<WeekendCalender />}
                  onClick={() => setShowWeekend(!showWeekend)}
                />
              </ActionMenu>
            </Grid>
          </Grid>
          <FloatingButton
            disabled={!dataChanged}
            label='Opslaan'
            onClick={handleSubmit(formSubmit)}
            customMargin={'-40px 8px 4px calc(100% - 250px)'}
          />
          {isLoaded(lunchData) ? (
            <ResponsiveGridStyled container>
              {headerDays.length > 0 &&
                headerDays.map((day: any, index) => {
                  return (
                    <DaysGridStyled
                      item
                      showWeekend={!showWeekend && index > 4}
                      key={index}
                    >
                      <Typography variant='body1'>
                        {capitalize(
                          format(day.date, 'EE dd LLL', { locale: nl }),
                        )}
                      </Typography>

                      <CounterBox name={day.keyName} formObject={formObject} />
                    </DaysGridStyled>
                  )
                })}
              <TotalGridStyled item showWeekend={showWeekend}>
                <Typography variant='body1'>Totaal: </Typography>
                <TypographyStyled variant='body1'>
                  {lunchCount}
                </TypographyStyled>
              </TotalGridStyled>
            </ResponsiveGridStyled>
          ) : (
            <Grid container>
              {headerDays.map((day: any, index) => {
                return (
                  <DaysGridStyled
                    item
                    showWeekend={!showWeekend && index > 4}
                    key={day.date}
                  >
                    <Skeleton
                      variant='rectangular'
                      width='90px'
                      height='20px'
                    />
                    <Skeleton
                      variant='rectangular'
                      width='118px'
                      height='40px'
                    />
                  </DaysGridStyled>
                )
              })}
            </Grid>
          )}
        </BoxStyled>
      </Paper>
    </PageContainer>
  )
}

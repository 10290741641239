import React, { useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useMeasure } from 'react-use'

import {
  Paper,
  Divider,
  Grid,
  Chip,
  Box,
  Button,
  ClickAwayListener,
  styled,
} from '@mui/material'
import { InputBase, IconButton } from '@fivano/core'
import { Search, Tune } from '@mui/icons-material'

const BoxStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRight: 'none',
  borderRadius: 4,
  width: '100%',
  height: 40,
  marginBottom: 8,
  zIndex: 1,
}))

const DividerStyled = styled(Divider)(() => ({
  height: 28,
  margin: 4,
}))

const SearchButtonStyled = styled(Button)(() => ({
  boxShadow: 'none',
  padding: '8px 22px 8px 22px',
  borderRadius: '0px 4px 4px 0px',
}))

const PaperStyled = styled(Paper, {
  shouldForwardProp: prop => prop !== 'filterOpen' && prop !== 'width',
})<any>(({ theme, filterOpen, width }) => ({
  ...(!filterOpen && {
    display: 'none',
  }),
  ...(width && {
    width: width,
  }),
  position: 'absolute',
  top: 40,
  padding: theme.spacing(2),
}))

type SearchFilterProps = {
  /** Placeholder label for search input */
  placeholder: string
  /** Filter values for searchField and filters load when mounting SearchFilter */
  filterValues?: [{ value: string; initialValue: string; label: string }]
  /** Filter children for component */
  filters?: any
  /** Function when search or filter is submitted search data is returned */
  onSubmit: any
  /** Function triggered when reset searchField is pressed */
  onReset?: any
  /** Max-width of searchbar */
  maxWidth?: number
}

/** SearchFilter that returns data of searchField and other filters */
export const SearchFilter = ({
  filterValues,
  placeholder,
  filters,
  onSubmit,
  maxWidth = 600,
}: SearchFilterProps) => {
  const [filterOpen, setFilterOpen] = useState(false)
  const [ref, { width }] = useMeasure()

  const defaultValues = {}
  const initialValues = {}
  if (filterValues) {
    Object.entries(filterValues).forEach(([name, filter]) => {
      defaultValues[name] = filter.value
      initialValues[name] = filter.initialValue
    })
  }

  const formObject = useForm({ defaultValues: defaultValues })
  const { handleSubmit, reset, setValue }: UseFormReturn = formObject

  const handleSearchSubmit = data => {
    if (data.searchField) {
      data.searchField = data.searchField.toLowerCase()
    }
    setFilterOpen(false)
    onSubmit(data)
  }

  const resetValue = (name: string, value: any) => {
    setValue(name, value)
    handleSubmit(handleSearchSubmit)()
  }

  const handleToggleFilter = () => {
    setFilterOpen(prev => !prev)
  }

  const handleReset = () => {
    reset(initialValues)
    handleSearchSubmit(initialValues)
    setFilterOpen(false)
  }

  const handleClickAway = () => {
    setFilterOpen(false)
  }
  return (
    <form onSubmit={handleSubmit(handleSearchSubmit)} autoComplete='off'>
      <ClickAwayListener onClickAway={handleClickAway}>
        <BoxStyled
          // @ts-ignore
          ref={ref}
          maxWidth={maxWidth}
        >
          <InputBase
            style={{ paddingLeft: 8 }}
            name='searchField'
            formObject={formObject}
            placeholder={placeholder}
          />
          {filters && (
            <>
              <DividerStyled orientation='vertical' />
              <IconButton
                label='Uitgebreid zoeken'
                color='primary'
                onClick={handleToggleFilter}
                size='large'
              >
                <Tune />
              </IconButton>
            </>
          )}
          <SearchButtonStyled
            type='submit'
            size='large'
            color='primary'
            variant='contained'
          >
            <Search />
          </SearchButtonStyled>
          <PaperStyled elevation={8} width={width} filterOpen={filterOpen}>
            <Grid container spacing={1}>
              {filters && filters({ formObject: formObject })}
            </Grid>
            <Grid container justifyContent='flex-end' spacing={1}>
              <Grid item>
                <Button onClick={handleReset}>Filter wissen</Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleSubmit(handleSearchSubmit)}
                  variant='contained'
                  color='primary'
                  disableElevation
                >
                  Zoeken
                </Button>
              </Grid>
            </Grid>
          </PaperStyled>
        </BoxStyled>
      </ClickAwayListener>
      <Grid container spacing={1}>
        {filterValues &&
          Object.entries(filterValues).map(([name, filter]) => {
            let actualValue = filter.value
            if (Array.isArray(filter.value)) {
              let valueString = ''
              filter.value.forEach((e, i) => {
                valueString += `${e.label}${
                  filter.value.length !== i + 1 ? ', ' : ''
                }`
              })
              actualValue = valueString
            }
            return (
              actualValue && (
                <Grid item key={name}>
                  <Chip
                    label={`${filter.label} ${
                      typeof actualValue !== 'boolean' ? actualValue : ''
                    }`}
                    onDelete={() => resetValue(name, filter.initialValue)}
                  />
                </Grid>
              )
            )
          })}
      </Grid>
    </form>
  )
}

import React from 'react'
import { formatDistanceToNow, format } from 'date-fns'
import nl from 'date-fns/locale/nl'
import { useSelector } from 'hooks/useSelector'
import { useFirebase } from 'react-redux-firebase'
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Chip,
  Tooltip,
  capitalize,
  styled,
} from '@mui/material'
import { IconButton } from '@fivano/core'
import { Avatar } from '../../components/Avatar'
import DeleteIcon from '@mui/icons-material/Delete'
import DescriptionIcon from '@mui/icons-material/Description'
import EditIcon from '@mui/icons-material/Edit'
import TimerIcon from '@mui/icons-material/Timer'
import { revokeDownloadToken } from 'utils/revokeDownloadToken'
import { useSettings } from 'hooks/useSettings'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { Paragraph } from '@fivano/core'
import { convertToDate } from 'utils/convertToDate'

const CardStyled = styled(Card, {
  shouldForwardProp: prop => prop !== 'height',
})<{ height?: number }>(({ height }) => ({
  width: '100%',
  marginBottom: '4px',
  position: 'relative',
  ...(height && {
    height: height,
  }),
}))

const GridStyled = styled(Grid)(() => ({
  width: 40,
  marginRight: 16,
}))

const TypographyStyled = styled(Typography)(() => ({
  display: 'inline-block',
}))

const ItemsGridStyled = styled(Grid, {
  shouldForwardProp: prop => prop !== 'wrapItems',
})<{ wrapItems: boolean }>(({ wrapItems }) => ({
  height: '32px',
  ...(wrapItems && {
    height: '100%',
  }),
}))

type ProgressCardProps = {
  id: string | null
  onEdit?: any
  onDelete?: any
  text: string
  tags?: Array<any>
  files?: Array<any>
  firstName: string
  middleName?: string
  lastName: string
  creatorID?: string
  avatarImage?: string
  dateTime: any | null
  lines?: number
  hideText?: boolean
  showActions?: boolean
  cardHeight?: number
  progressData?: any
  duration?: number
  wrapItems?: boolean
}

export function ProgressCard({
  id,
  onEdit,
  onDelete,
  text,
  tags,
  files,
  firstName,
  middleName,
  lastName,
  creatorID,
  dateTime,
  lines = 5,
  hideText = false,
  showActions = false,
  cardHeight,
  progressData,
  duration,
  wrapItems = false,
}: ProgressCardProps) {
  const errorLogger = useErrorLogger()
  const firebase = useFirebase()
  const settings = useSettings()
  const uid = useSelector(({ firebase: { auth } }) => auth.uid)

  const handleClickDocument = (event, fullPath) => {
    event.preventDefault()
    firebase
      .storage()
      .ref(fullPath)
      .getDownloadURL()
      .then(response => {
        window.open(response, '_blank')
        revokeDownloadToken(fullPath)
      })
      .catch(error => {
        errorLogger({ error })
      })
  }
  const dateFormatted = format(
    convertToDate(dateTime),
    settings.progress.cardDateFormat,
    { locale: nl },
  )
  const dateDistanceFormatted = capitalize(
    formatDistanceToNow(convertToDate(dateTime), {
      locale: nl,
      addSuffix: true,
    }),
  )

  return (
    <CardStyled height={cardHeight}>
      <CardContent>
        <Grid container>
          <GridStyled item>
            <Avatar
              personData={{
                firstNamePers: firstName,
                lastNamePers: lastName,
                id: creatorID,
              }}
            />
          </GridStyled>
          <Grid item>
            <Typography variant='body2'>{`${firstName} ${middleName} ${lastName}`}</Typography>
            {dateTime && (
              <Tooltip
                title={
                  settings.progress.cardDateDistance
                    ? dateDistanceFormatted
                    : dateFormatted
                }
                placement='right'
              >
                <TypographyStyled variant='body2' color='textSecondary'>
                  {settings.progress.cardDateDistance
                    ? dateFormatted
                    : dateDistanceFormatted}
                </TypographyStyled>
              </Tooltip>
            )}
          </Grid>
          {showActions && creatorID && creatorID === uid && (
            <Grid item marginLeft='auto'>
              <IconButton
                label='Bewerken'
                onClick={() => onEdit(id, progressData)}
                size='large'
              >
                <EditIcon />
              </IconButton>
              <IconButton
                label='Verwijderen'
                onClick={() => onDelete(id, progressData)}
                size='large'
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Box m={1} />
        <Grid container spacing={1} wrap={wrapItems ? 'wrap' : 'nowrap'}>
          {duration !== undefined && duration > 0 && (
            <Grid item>
              <Chip
                variant='outlined'
                label={`${duration} minuten`}
                size='small'
                icon={<TimerIcon />}
              />
            </Grid>
          )}
          {Array.isArray(tags) &&
            tags.map((tag, index) => {
              return (
                <Grid item key={index}>
                  <Chip size='small' label={tag.label} />
                </Grid>
              )
            })}
        </Grid>
        <Box mt='10px' mb={1}>
          <Paragraph
            variant='body1'
            showButton={!hideText}
            {...(hideText && {
              lines:
                lines -
                ((tags && tags.length > 0) || duration ? 2 : 0) -
                (files && files?.length > 0 ? 1 : 0) -
                1,
            })}
          >
            {text}
          </Paragraph>
        </Box>
        <ItemsGridStyled
          container
          spacing={1}
          wrap={wrapItems ? 'wrap' : 'nowrap'}
          wrapItems={wrapItems}
        >
          {files &&
            files?.length > 0 &&
            files.map((file, index) => {
              return (
                <Grid item key={index} zIndex='1'>
                  <Tooltip title='Download'>
                    <Chip
                      {...(file.fullPath !== undefined && {
                        onClick: event => {
                          event.preventDefault()
                          handleClickDocument(event, file.fullPath)
                        },
                      })}
                      variant='outlined'
                      icon={<DescriptionIcon />}
                      size='small'
                      label={file.customMetadata.originalName}
                    />
                  </Tooltip>
                </Grid>
              )
            })}
        </ItemsGridStyled>
      </CardContent>
    </CardStyled>
  )
}

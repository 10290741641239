import { routeObjectType } from 'app/router/types'
import { ProductCategoryForm } from './ProductCategories'
import { ProductCategoriesPage } from './ProductCategories/ProductCategoriesPage'
import { ProductForm } from './ProductForm'
import { ProductsPage } from './ProductsPage'
import { ProductsSettingsPage } from './ProductsSettingsPage'

export const productsRoutes: routeObjectType = {
  products: {
    path: '/producten',
    component: ProductsPage,
    claims: { dev: 1 },
  },
  productForm: {
    path: '/producten/formulier',
    component: ProductForm,
    claims: { dev: 1 },
  },
  productFormNew: {
    path: '/producten/formulier',
    component: ProductForm,
    claims: { dev: 5 },
  },
  productFormEdit: {
    path: '/producten/:productID/formulier',
    component: ProductForm,
    claims: { dev: 5 },
  },
  productsSettings: {
    path: '/producten/instellingen',
    component: ProductsSettingsPage,
    claims: { dev: 5 },
  },
  productCategories: {
    path: '/producten/categorieen',
    component: ProductCategoriesPage,
    claims: { dev: 5 },
  },
  productCategoryForm: {
    path: '/producten/categorie/:productID/formulier',
    component: ProductCategoryForm,
    claims: { dev: 5 },
  },
  productCategoryFormNew: {
    path: '/producten/categorie/:productID/formulier',
    component: ProductCategoryForm,
    claims: { dev: 5 },
  },
}

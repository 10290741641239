import React, { useEffect, useState } from 'react'
import { useSelector } from 'hooks/useSelector'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { LoadingOverlay } from '@fivano/core'
import { Authentication } from 'app/containers/Authentication/Authentication'
import { AuthWrapper } from 'app/containers/Authentication/components/AuthWrapper'
import { useApiRequest } from 'hooks/useApiRequest'
import { CreatePerson } from 'app/containers/Authentication/CreatePerson'

const HasPersonGuard = ({ children }) => {
  const [authPerson, setAuthPerson] = useState<{ status: String; person: any }>(
    {
      status: 'loading',
      person: undefined,
    },
  )

  const makeRequest = useApiRequest()

  useEffect(() => {
    makeRequest({
      route: 'functions/getAuthPerson',
      handleError: true,
      onSuccess: response => {
        setAuthPerson({ status: 'success', person: response.person })
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!authPerson.person) {
    return (
      <AuthWrapper title='Create person'>
        {authPerson.status === 'loading' ? (
          <div>Loading...</div>
        ) : (
          <CreatePerson setAuthPerson={setAuthPerson} />
        )}
      </AuthWrapper>
    )
  } else {
    return children
  }
}

export const AuthGuard = ({ children }) => {
  const auth = useSelector(state => state.firebase.auth)
  const hasAuth = isLoaded(auth) && !isEmpty(auth) && auth.uid
  if (!isLoaded(auth)) return <LoadingOverlay label='Loading auth' />

  if (!hasAuth) {
    return <Authentication />
  }
  return <HasPersonGuard children={children} />
}

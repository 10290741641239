type NameType = {
  firstNamePers: string
  middleNamePers?: string
  lastNamePers: string
}

export const personFullName = ({
  firstNamePers,
  lastNamePers,
  middleNamePers,
}: NameType) => {
  return `${firstNamePers || ''} ${
    middleNamePers ? `${middleNamePers} ` : ''
  }${lastNamePers}`
}

import React, { useState, useEffect } from 'react'
import { Grid, styled, Typography, Box, Button } from '@mui/material'
import { AccordionBase, ExpandButton } from '@fivano/core'
import { ProjectRow } from './ProjectRow'
import { personFullName } from 'model/person'
import { ProjectRowSkeleton } from './ProjectRowSkeleton'
import { addDays } from 'date-fns'
import { secondsToCustomTimeString } from 'utils/secondsToTime'
import { MINUTES_IN_DAY } from 'utils/variables'
import { TrackBlockHeaderProps, TrackBlockProps } from './types'

type TotalsType = {
  day: Date
  total: number
}

const calculateTotals = (timeDocs, selectedWeek) => {
  const totals: TotalsType[] = []
  selectedWeek.forEach(day => {
    const docs = timeDocs.filter(
      doc =>
        doc?.time.getTime() > day.getTime() &&
        doc.time.getTime() < addDays(day, 1).getTime(),
    )
    const total = docs?.reduce((sum, doc) => {
      return sum + (doc.time.getHours() * 60 + doc.time.getMinutes())
    }, 0)
    totals.push({ day: day, total: total })
  })

  return totals
}

const getUniqueListBy = arr => {
  return [...new Map(arr.map(item => [item.projectCode.value, item])).values()]
}

const showPreset = (project, timeDocs) => {
  let boolean = false

  timeDocs.forEach(doc => {
    if (
      project.showPreset === false &&
      project.projectCode.value === doc.projectCode.value &&
      project.workCode.value === doc.workCode.value
    ) {
      boolean = true
    }
  })

  return boolean
}

const TrackBlockWrapper = styled(Grid)(() => ({
  position: 'relative',
  width: '100%',
}))

const ProjectHeader = styled(Grid)(({ theme }) => ({
  padding: '8px 0px 8px 12px',
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

export const TrackBlock = ({
  track,
  timeDocs,
  selectedWeek,
  showSkeleton,
  showWeekend,
  onHandleExpand,
  onCreateDoc,
  onUpdateDoc,
  onOpenModal,
  onHandleErrorInput,
  inputError,
  viewportLG,
}: TrackBlockProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [weekTotals, setWeekTotals] = useState<TotalsType[]>([])

  useEffect(() => {
    if (expanded) {
      const totals = calculateTotals(timeDocs, selectedWeek)
      setWeekTotals(totals)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeDocs, expanded])

  let dataShowing = false
  return (
    <AccordionBase
      expanded={expanded}
      visibleContent={
        <TrackBlockHeader
          track={track}
          showWeekend={showWeekend}
          weekTotals={weekTotals}
          viewportLG={viewportLG}
          onClick={() => {
            setExpanded(!expanded)
            onHandleExpand(track.id, !expanded)
          }}
          onOpenModal={() => onOpenModal(track.id, track.personIDTrack)}
        />
      }
      expandableContent={
        <Grid container>
          {showSkeleton ? (
            <Grid item xs={12}>
              <ProjectRowSkeleton
                selectedWeek={selectedWeek}
                showWeekend={showWeekend}
                viewportLG={viewportLG}
              />
            </Grid>
          ) : (
            track?.personProjectsTrack?.length > 0 &&
            getUniqueListBy(track?.personProjectsTrack)
              .sort((a: any, b: any) =>
                a.projectCode.label.localeCompare(b.projectCode.label),
              )
              .map((project: any, index) => {
                const projects = track.personProjectsTrack.filter(
                  item => item.projectCode.value === project.projectCode.value,
                )

                const presets = projects.filter(
                  project =>
                    project.showPreset || showPreset(project, timeDocs),
                )

                const activePresets = projects
                  .filter(
                    project =>
                      project.showPreset || showPreset(project, timeDocs),
                  )
                  .sort((a, b) =>
                    a.workCode.label.localeCompare(b.workCode.label),
                  )

                if (presets.length) {
                  dataShowing = true
                }

                return (
                  presets.length > 0 && (
                    <Grid item xs={12} key={index}>
                      <TrackBlockWrapper container>
                        <ProjectHeader item xs={12}>
                          <Typography fontWeight={700}>
                            {project.projectCode.label}
                          </Typography>
                          {inputError === project.projectCode.value && (
                            <Typography color='error'>
                              Uren op één dag mogen niet meer dan 24 uur zijn
                            </Typography>
                          )}
                        </ProjectHeader>
                        {activePresets?.map((project, index) => (
                          <ProjectRow
                            key={index}
                            timeDocs={timeDocs.filter(
                              doc =>
                                doc.workCode.value === project.workCode.value,
                            )}
                            project={project}
                            selectedWeek={selectedWeek}
                            showWeekend={showWeekend}
                            weekTotals={weekTotals}
                            onCreateDoc={onCreateDoc}
                            onUpdateDoc={onUpdateDoc}
                            onHandleErrorInput={onHandleErrorInput}
                            viewportLG={viewportLG}
                          />
                        ))}
                      </TrackBlockWrapper>
                    </Grid>
                  )
                )
              })
          )}
          {!dataShowing && !showSkeleton && (
            <Box py='8px' pl='12px'>
              <Typography>Er zijn geen projecten geselecteerd.</Typography>
            </Box>
          )}
        </Grid>
      }
    />
  )
}

const HeaderWrapper = styled(Grid)(({ theme }) => ({
  padding: '5px 0px',
  backgroundColor: theme.palette.action.hover,
  borderTop: `1px solid ${theme.palette.action.selected}`,
  borderBottom: `1px solid ${theme.palette.action.selected}`,
  minHeight: 60,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}))

const GridItemTotals = styled(Grid)(() => ({
  padding: '0px 14px',
}))

const TrackBlockHeader = ({
  track,
  weekTotals,
  showWeekend,
  viewportLG,
  onClick,
  onOpenModal,
}: TrackBlockHeaderProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [trackTotal, setTrackTotal] = useState<number | undefined>(undefined)
  const showDays = showWeekend ? 7 : 5

  useEffect(() => {
    if (expanded) {
      const total = weekTotals?.reduce((sum, doc) => {
        return sum + doc.total * 60
      }, 0)
      setTrackTotal(total)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekTotals])

  return (
    <HeaderWrapper
      container
      alignItems='center'
      onClick={() => {
        setExpanded(!expanded)
        onClick()
      }}
    >
      <Grid item lg={showWeekend ? 4 : 6}>
        <Grid container alignItems='center'>
          <Grid item>
            <ExpandButton
              label={expanded ? 'Inklappen' : 'Uitklappen'}
              rotate={expanded}
            />
          </Grid>
          <Grid item>
            <Typography>
              {track?.participantTrack?.firstNamePers &&
                personFullName({
                  firstNamePers: track.participantTrack.firstNamePers,
                  lastNamePers: track.participantTrack.lastNamePers,
                })}
            </Typography>
            <Typography variant='body2'>{track?.moduleTrack?.label}</Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={e => {
                e.stopPropagation()
                onOpenModal()
              }}
            >
              Codes
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {expanded && viewportLG && (
        <>
          {weekTotals.map(
            (day, index) =>
              index < showDays && (
                <GridItemTotals key={index} item lg={1}>
                  <Typography
                    color={
                      day.total >= MINUTES_IN_DAY ? 'error' : 'textPrimary'
                    }
                  >
                    {secondsToCustomTimeString([day.total * 60])}
                  </Typography>
                  <Typography
                    color={
                      day?.total * 60 === track?.workdayDurationsTrack?.[index]
                        ? 'textSecondary'
                        : 'error'
                    }
                  >
                    {secondsToCustomTimeString([
                      track?.workdayDurationsTrack?.[index],
                    ])}
                  </Typography>
                </GridItemTotals>
              ),
          )}
          <GridItemTotals item lg={1}>
            <Typography>{secondsToCustomTimeString([trackTotal])}</Typography>
          </GridItemTotals>
        </>
      )}
    </HeaderWrapper>
  )
}

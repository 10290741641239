import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'types'

type appModeType = 'legacy' | 'app'

export const initialState: { appMode: appModeType } = {
  appMode: process.env.REACT_APP_MODE === 'app' ? 'app' : 'legacy' || 'app',
}

const appModeSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changeAppMode(state, action: PayloadAction<appModeType>) {
      state.appMode = action.payload
    },
  },
})

export const selectAppMode = createSelector(
  [(state: RootState) => state?.settings || initialState],
  theme => theme.appMode,
)

export const { changeAppMode } = appModeSlice.actions
export const appModeReducer = appModeSlice.reducer
export const appModeSliceKey = appModeSlice.name

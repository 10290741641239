import React, { useState } from 'react'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSnackbar } from '@fivano/core'
import { Grid, Chip } from '@mui/material'
import { useSelector } from 'hooks/useSelector'
import { PersonTagsForm } from 'app/legacy/PersonTags/PersonTagsForm'
import { IconButton } from '@fivano/core'
import { Add, Close, Edit } from '@mui/icons-material'
import { hasClaimLevel } from 'utils/hasClaimLevel'
import { useErrorLogger } from 'hooks/useErrorLogger'

type PersonTagsWidgetProps = {
  participantID: string
  tagName: string
}

export const PersonTagsWidget = ({
  participantID,
  tagName,
}: PersonTagsWidgetProps) => {
  const firestore = useFirestore()
  const errorLogger = useErrorLogger()
  const profile = useSelector(state => state.firebase.profile)
  const { enqueueSnackbar } = useSnackbar()
  const [editTags, setEditTags] = useState(false)

  useFirestoreConnect([
    {
      collection: `locations/publicLocation/persons/${participantID}/personTags`,
      storeAs: `participant_${participantID}_personTagsLita`,
    },
  ])

  const [personTagsFormProps, setPersonTagsFormProps] = useState({
    personTagsID: null,
    open: false,
    linkedPersonTagsData: [],
    tagName: tagName,
  })

  const linkedPersonTagsData = useSelector(
    state =>
      state.firestore.ordered[
        `participant_${participantID}_personTagsLita`
      ]?.[0]?.personTagsLita,
  )

  const handleDeletelinkedPersonTag = data => {
    firestore
      .update(
        `locations/publicLocation/persons/${participantID}/personTags/personTags`,
        {
          personTagsLita: firestore.FieldValue.arrayRemove(data),
          personTagsIDsLita: firestore.FieldValue.arrayRemove(
            data.linkPersonTagLita.value,
          ),
        },
      )
      .then(() => {
        enqueueSnackbar(`${tagName} succesvol ontkoppeld.`, {
          variant: 'success',
        })
      })
      .catch(error => {
        errorLogger({
          error,
          snackbarMessage: `Tag ontkoppelen mislukt. ${error}`,
        })
      })
  }

  const openPersonTagsForm = () => {
    setPersonTagsFormProps({
      personTagsID: null,
      open: true,
      linkedPersonTagsData: linkedPersonTagsData,
      tagName: tagName,
    })
  }

  return (
    <>
      {personTagsFormProps.open && (
        <PersonTagsForm
          wrapInside='modal'
          modalMaxWidth='sm'
          onCloseForm={() => {
            setPersonTagsFormProps({
              personTagsID: null,
              open: false,
              linkedPersonTagsData: [],
              tagName: tagName,
            })
          }}
          formProps={personTagsFormProps}
        />
      )}
      <Grid container spacing={1} alignItems='center'>
        {linkedPersonTagsData?.length > 0 ? (
          <>
            {linkedPersonTagsData.map(tagData => {
              return (
                <Grid item key={tagData.linkPersonTagLita.value}>
                  <Chip
                    label={tagData.linkPersonTagLita.label}
                    {...(editTags && {
                      onDelete: () => handleDeletelinkedPersonTag(tagData),
                    })}
                  />
                </Grid>
              )
            })}
            {hasClaimLevel(profile, { pt: 5 }) && editTags ? (
              <>
                <IconButton
                  label={`${tagName} toevoegen`}
                  onClick={openPersonTagsForm}
                  size='large'
                >
                  <Add />
                </IconButton>{' '}
                <IconButton
                  label='Bewerken stoppen'
                  onClick={() => setEditTags(false)}
                  size='large'
                >
                  <Close />
                </IconButton>
              </>
            ) : (
              hasClaimLevel(profile, { pt: 5 }) && (
                <IconButton
                  label='Bewerken'
                  onClick={() => setEditTags(true)}
                  size='large'
                >
                  <Edit />
                </IconButton>
              )
            )}
          </>
        ) : (
          hasClaimLevel(profile, { pt: 5 }) && (
            <Grid item>
              <Chip
                label={`${tagName} toevoegen`}
                clickable
                color='primary'
                variant='outlined'
                onClick={openPersonTagsForm}
              />
            </Grid>
          )
        )}
      </Grid>
    </>
  )
}

import { Button } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { AuthWrapper } from './components/AuthWrapper'
import { EmailLogin } from './EmailLogin'
import { Register } from './Register'

export const Authentication = () => {
  const [signUp, setSignUp] = useState(true)

  return (
    <AuthWrapper title='Account registreren'>
      {signUp ? (
        <div>
          <Register />
          <Button color='primary' size='small' onClick={() => setSignUp(false)}>
            Al een account? Inloggen
          </Button>
        </div>
      ) : (
        <div>
          <EmailLogin />
          <Button color='primary' size='small' onClick={() => setSignUp(true)}>
            Nog geen account? Registreer
          </Button>
        </div>
      )}
    </AuthWrapper>
  )
}

import { menuLayoutType } from '../../router/types'
import { MusicNote } from '@mui/icons-material'
import { songstarterRoutes } from './songstarterRoutes'

export const songstarterMenu: menuLayoutType[] = [
  {
    name: 'Tracks',
    icon: MusicNote,
    route: songstarterRoutes.songstarterTracks,
  },
  {
    name: 'Playlists',
    icon: MusicNote,
    route: songstarterRoutes.songstarterPlaylists,
  },
]

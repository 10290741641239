import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'hooks/useSelector'
import { useFirestoreConnect } from 'react-redux-firebase'
import {
  Paper,
  Grid,
  Box,
  Typography,
  Button,
  Chip,
  styled,
} from '@mui/material'
import { Avatar } from 'app/components/Avatar'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { PageContainer, Modal, ListView } from '@fivano/core'
import { PersonDetailModal } from 'app/components/DetailModals/PersonDetailModal'
import { initialRelationPersonFormValues } from 'app/legacy/RelationDetails/RelationPersonFormSettings'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { Person } from 'types'

const PaperStyled = styled(Paper)(() => ({
  marginBottom: 80,
}))

const breadcrumbArray = [
  { title: 'Home', path: '/' },
  { title: `Collega's`, path: '/collegas' },
]

export const Colleagues = props => {
  const [participantLimit, setParticipantLimit] = useState(10)

  const handleLoadMore = () => {
    setParticipantLimit(participantLimit + 5)
  }
  const locationQuery = useLocationQuery()
  useFirestoreConnect([
    {
      collectionGroup: 'persons',
      orderBy: ['lastNamePers', 'asc'],
      where: [
        ['archivedPers', '==', false],
        ['personTypePers', '==', 'colleague'],
        ...locationQuery('locationIDPers'),
      ],
      limit: 50,
      storeAs: 'colleagues_1',
    },
    {
      collectionGroup: 'persons',
      orderBy: ['lastNamePers', 'asc'],
      where: [
        ['archivedPers', '==', false],
        ['personTypePers', '==', 'userColleague'],
        ...locationQuery('locationIDPers'),
      ],
      limit: 50,
      storeAs: 'colleagues_2',
    },
  ])
  const colleaguesData = useSelector(({ firestore: { ordered } }) => {
    const colleagueArray: Person[] = []
    if (ordered?.colleagues_1?.length) {
      ordered.colleagues_1.forEach(item => colleagueArray.push(item))
    }
    if (ordered?.colleagues_2?.length) {
      ordered.colleagues_2.forEach(item => colleagueArray.push(item))
    }
    return colleagueArray
  })

  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          accessor: 'lastNamePers',
          gridProps: {
            xs: 12,
            sm: 6,
            md: 5,
          },
          Cell: data => {
            return (
              <>
                <Grid container wrap='nowrap' spacing={2} alignItems='center'>
                  <Grid item>
                    <Avatar personData={data.row.original} />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant='body1'
                      display='inline'
                    >{`${data.row.original.firstNamePers} ${data.row.original.middleNamePers} ${data.row.original.lastNamePers} `}</Typography>
                    {data.row.original.archivedPers && (
                      <Chip label='Gearchiveerd' size='small' />
                    )}
                  </Grid>
                </Grid>
              </>
            )
          },
        },
        {
          Header: 'Woonplaats',
          accessor: 'addressCityPers',
          gridProps: {
            sm: 6,
            md: 4,
          },
          hidden: { only: ['xs'] },
        },
        {
          Header: 'Telefoon',
          accessor: 'phoneMobilePers',
          gridProps: {
            md: 3,
          },
          hidden: { only: ['xs', 'sm'] },
        },
      ],
    },
  ]
  // ==== PERSON DETAILS MODAL ====//
  const [personValues, setPersonValues] = useState(
    initialRelationPersonFormValues,
  )
  const handleClosePersonDetail = () => {
    setPersonValues(initialRelationPersonFormValues)
  }
  const handleClickListItem = (event, data) => {
    setPersonValues(data.original)
  }

  return (
    <>
      <Modal
        maxWidth='sm'
        onClose={handleClosePersonDetail}
        open={personValues?.firstNamePers?.length > 0}
      >
        <PersonDetailModal
          personData={personValues}
          onClose={handleClosePersonDetail}
          closeButton
        />
      </Modal>
      <Helmet>
        <title>Collega's</title>
      </Helmet>
      <PageContainer maxWidth='lg'>
        <Breadcrumbs breadcrumbs={breadcrumbArray} />
        <PaperStyled>
          {colleaguesData?.length > 0 ? (
            <>
              <ListView
                data={colleaguesData}
                columns={listColumns}
                onClick={handleClickListItem}
              />
              {colleaguesData.length === participantLimit && (
                <Button fullWidth onClick={handleLoadMore}>
                  Meer collega's laden
                </Button>
              )}
            </>
          ) : (
            <Box p={2}>
              <Typography variant='body1'>
                Geen collega's gevonden. Voeg een nieuwe collega toe
              </Typography>
            </Box>
          )}
        </PaperStyled>
      </PageContainer>
    </>
  )
}

import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { DatePicker, TextField } from '@fivano/core'
import { Timepicker } from 'app/components/Timepicker'
import { MINUTES_IN_DAY } from 'utils/variables'
import { addDays, addMinutes, addSeconds, format, isValid } from 'date-fns'
import { timeStringToSeconds } from 'utils/timeToSeconds'
import { DateTimeRowProps } from './types'
import { convertToDate } from 'utils/convertToDate'

/** OnChange Datepicker set Timepicker values to the new date */
const onChangeDatepicker = (
  value,
  starttime,
  endtime,
  starttimeName,
  endtimeName,
  formObject,
) => {
  if (isValid(value)) {
    value = new Date(value.setHours(0, 0, 0, 0))

    if (starttime) {
      const newStarttimeDate = addSeconds(
        value,
        timeStringToSeconds(format(starttime, 'HH:mm')),
      )
      formObject.setValue(starttimeName, newStarttimeDate)
    }
    if (endtime) {
      const newEndtimeDate = addSeconds(
        value,
        timeStringToSeconds(format(endtime, 'HH:mm')),
      )
      formObject.setValue(endtimeName, newEndtimeDate)
    }
  }
}

const onChangeStarttime = (formObject, starttime, endtime, durationName) => {
  const startMinutes = starttime.getHours() * 60 + starttime.getMinutes()
  const endMinutes = endtime.getHours() * 60 + endtime.getMinutes()
  if (startMinutes < endMinutes) {
    const calculatedDuration = Math.abs(startMinutes - endMinutes)
    formObject.setValue(durationName, calculatedDuration)
  }
  if (endMinutes === 0) {
    const calculatedDuration = Math.abs(startMinutes - MINUTES_IN_DAY)
    formObject.setValue(durationName, calculatedDuration)
  }
}

const onChangeDuration = (
  formObject,
  starttime,
  endtime,
  duration,
  durationName,
  endtimeName,
) => {
  const startMinutes = starttime.getHours() * 60 + starttime.getMinutes()
  if (duration > 0 && !isNaN(starttime)) {
    if (startMinutes + duration > MINUTES_IN_DAY) {
      const calcDuration = Math.abs(startMinutes - MINUTES_IN_DAY)
      formObject.setValue(durationName, calcDuration)
      formObject.setValue(
        endtimeName,
        addDays(new Date(endtime.setHours(0, 0, 0, 0)), 1),
      )
    } else {
      formObject.setValue(endtimeName, addMinutes(starttime, duration))
    }
  }
}

const onChangeEndtime = (formObject, starttime, endtime, durationName) => {
  const startMinutes = starttime.getHours() * 60 + starttime.getMinutes()
  const endMinutes = endtime.getHours() * 60 + endtime.getMinutes()

  if (startMinutes < endMinutes) {
    const calculatedDuration = Math.abs(startMinutes - endMinutes)
    formObject.setValue(durationName, calculatedDuration)
  }
  if (endMinutes === 0) {
    const calculatedDuration = Math.abs(startMinutes - MINUTES_IN_DAY)
    formObject.setValue(durationName, calculatedDuration)
  }
}

export const DateTimeRow = ({
  generalSettings = { hideDatepicker: false },
  inputSettings,
  gridSettings,
  readOnly = false,
  formObject,
}: DateTimeRowProps) => {
  const datepickerProps = inputSettings?.datepickerProps
  const starttimeProps = inputSettings?.starttimeProps
  const durationProps = inputSettings?.durationProps
  const endtimeProps = inputSettings?.endtimeProps

  const datepickerName = datepickerProps?.name
    ? datepickerProps.name
    : 'datepicker'
  const starttimeName = starttimeProps?.name ? starttimeProps.name : 'starttime'
  const durationName = durationProps?.name ? durationProps.name : 'duration'
  const endtimeName = endtimeProps?.name ? endtimeProps.name : 'endtime'

  const starttime = formObject.watch(starttimeName)
  const endtime = formObject.watch(endtimeName)
  const datepicker = formObject.watch(datepickerName)

  const [date, setDate] = useState<Date | null>(datepicker)

  return (
    <>
      {!generalSettings.hideDatepicker && (
        <Grid item {...(gridSettings?.datepicker && gridSettings?.datepicker)}>
          {!readOnly ? (
            <DatePicker
              name={datepickerName}
              formObject={formObject}
              {...datepickerProps}
              onChange={(_, value) => {
                if (isValid(value)) {
                  setDate(convertToDate(value))
                  onChangeDatepicker(
                    value,
                    starttime,
                    endtime,
                    starttimeName,
                    endtimeName,
                    formObject,
                  )
                }
              }}
            />
          ) : (
            <>
              {generalSettings?.showLabels && (
                <Typography color='textSecondary' variant='caption'>
                  {datepickerProps?.label}
                </Typography>
              )}
              <Typography>
                {format(formObject.getValues(datepickerName), 'dd-MMMM-yyyy')}
              </Typography>
            </>
          )}
        </Grid>
      )}
      <Grid item {...(gridSettings?.starttime && gridSettings?.starttime)}>
        {!readOnly ? (
          <Timepicker
            name={starttimeName}
            formObject={formObject}
            currentDate={date ? date : generalSettings.starttimeDate}
            onBlur={async () => {
              await formObject.trigger(starttimeName)
              if (
                formObject.formState.errors?.[starttimeName]?.type ===
                'validateStart'
              ) {
                formObject.setValue(durationName, 60)
                formObject.setValue(endtimeName, '')
                formObject.clearErrors(endtimeName)
                formObject.trigger(starttimeName)
              }
            }}
            onChange={value => {
              if (typeof value !== 'string' && endtime) {
                formObject.trigger(endtimeName)
                onChangeStarttime(formObject, value, endtime, durationName)
              }
            }}
            rules={{
              required: 'Dit veld is verplicht',
              validate: {
                validateStart: value => {
                  if (endtime) {
                    if (
                      value.getTime() >= endtime.getTime() &&
                      timeStringToSeconds(format(endtime, 'HH:mm')) !== 0
                    ) {
                      return 'Starttijd moet voor eindtijd liggen'
                    }
                  }
                },
              },
            }}
            {...starttimeProps}
          />
        ) : (
          <>
            {generalSettings?.showLabels && (
              <Typography color='textSecondary' variant='caption'>
                {starttimeProps?.label}
              </Typography>
            )}
            <Typography>
              {format(formObject.getValues(starttimeName), 'HH:mm')}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item {...(gridSettings?.duration && gridSettings?.duration)}>
        {!readOnly ? (
          <TextField
            type='number'
            name={durationName}
            formObject={formObject}
            required={false}
            rules={{
              required: 'Dit veld is verplicht',
              validate: {
                notNull: value => {
                  if (value <= 0) {
                    return 'Groter dan 0'
                  }
                },
              },
            }}
            onChange={async event => {
              const value = event.target.value
              if (value && parseInt(value) > 0 && starttime) {
                formObject.trigger(durationName)
                onChangeDuration(
                  formObject,
                  starttime,
                  endtime,
                  parseInt(value),
                  durationName,
                  endtimeName,
                )
              }
            }}
            {...durationProps}
          />
        ) : (
          <>
            {generalSettings?.showLabels && (
              <Typography color='textSecondary' variant='caption'>
                {durationProps?.label}
              </Typography>
            )}
            <Typography>{formObject.getValues(durationName)}</Typography>
          </>
        )}
      </Grid>
      <Grid item {...(gridSettings?.endtime && gridSettings?.endtime)}>
        {!readOnly ? (
          <Timepicker
            name={endtimeName}
            formObject={formObject}
            currentDate={date ? date : generalSettings.endtimeDate}
            rules={{
              required: 'Dit veld is verplicht',
              validate: {
                validateEnd: value => {
                  if (starttime) {
                    if (
                      value.getTime() <= starttime.getTime() &&
                      timeStringToSeconds(format(value, 'HH:mm')) !== 0
                    ) {
                      return 'Eindtijd moet na starttijd liggen'
                    }
                  }
                },
              },
            }}
            onChange={value => {
              if (typeof value !== 'string') {
                onChangeEndtime(formObject, starttime, value, durationName)
              }
            }}
            {...endtimeProps}
          />
        ) : (
          <>
            {generalSettings?.showLabels && (
              <Typography color='textSecondary' variant='caption'>
                {endtimeProps?.label}
              </Typography>
            )}
            <Typography>
              {format(formObject.getValues(endtimeName), 'HH:mm')}
            </Typography>
          </>
        )}
      </Grid>
    </>
  )
}

import React from 'react'
import { useSelector } from 'hooks/useSelector'
import { Box, Grid, Typography, styled } from '@mui/material'
import { useFirestore } from 'react-redux-firebase'
import { useDataStandards } from 'hooks/useDataStandards'
import { TextField, Slider, Form } from '@fivano/core'
import { useSettings } from 'hooks/useSettings'
import { useErrorLogger } from 'hooks/useErrorLogger'

export const claimLevels = {
  none: { label: 'Geen', value: 0 },
  query: { label: 'Lees', value: 1 },
  update: { label: 'Bewerk', value: 2 },
  create: { label: 'Toevoeg', value: 3 },
  archive: { label: 'Archief', value: 4 },
  delete: { label: 'Verwijder', value: 5 },
}

const DivStyled = styled('div')(({ theme }) => ({
  padding: '0px 20px 8px 0px',
  [theme.breakpoints.down('sm')]: {
    padding: '16px 0px 0px 0px',
  },
}))

export const UserRolesProfileForm = ({
  editingDocID,
  onCloseForm,
  wrapInside,
  modalMaxWidth,
}) => {
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const settings: any = useSettings(true)
  // Give only these users the posibility to add the the admin 'ad' and developer 'dev' claims
  const isSuperAdmin =
    true ||
    profile.email === 'twan.keizer@fivano.com' ||
    profile.uid === '9Swr9lIXyzZRdTpuNXPzMgIX8eYV'

  const disableEditing =
    !isSuperAdmin && (editingDocID === 'dev' || editingDocID === 'admin')
  type ApplicationModuleType = {
    name: string
    shortName: string
    description?: string
  }
  const applicationModulesList: ApplicationModuleType[] = [
    {
      name: 'Basic gebruiker',
      shortName: 'u',
      description: 'Basis gebruiker functionaliteit zoals het inloggen',
    },
    {
      name: 'Home scherm',
      shortName: 'ho',
      description:
        'Home screen met overzichten van verschillende andere functionaliteiten bekijken',
    },
    {
      name: 'Deelnemers',
      shortName: 'p',
      description: 'Deelnemers lijst met zoekfunctie en deelnemer overzicht',
    },

    { name: 'Deelnemer voortgang', shortName: 'pr', description: '' },
    { name: 'Deelnemer traject', shortName: 't', description: '' },
    { name: 'Deelnemer documenten', shortName: 'd', description: '' },
    { name: 'Deelnemer tags', shortName: 'pt', description: '' },
    { name: 'Deelnemer ziek meldingen', shortName: 'si', description: '' },
    { name: 'Deelnemer eigen home', shortName: 'uo', description: '' },
    { name: 'Deelnemer eigen traject', shortName: 'ut', description: '' },
    ...(settings.jobOpenings.jobOpeningsEnabled
      ? [{ name: 'Deelnemer opdrachten', shortName: 'j', description: '' }]
      : []),
    {
      name: 'Deelnemer opdrachten overzicht',
      shortName: 'uj',
      description: '',
    },

    {
      name: 'Deelnemer eigen tijdregistratie',
      shortName: 'uh',
      description: '',
    },
    {
      name: 'Urenregistratie',
      shortName: 'du',
      description: 'Uren registratie over meerdere deelnemers bijhouden',
    },
    {
      name: 'Tijdregistratie',
      shortName: 'h',
      description: 'Eigen tijdregistration voor gebruikers',
    },
    {
      name: 'Bedrijven',
      shortName: 'r',
      description: 'Bedrijven lijst met zoekfunctie en bedrijven overzicht',
    },
    {
      name: 'Bedrijf contacten',
      shortName: 'c',
      description: 'Bedrijf contractpersonen binnen het bedrijf overzicht',
    },
    {
      name: 'Locaties',
      shortName: 'l',
      description: 'Locatie lijst met zoekfunctie en bedrijven overzicht',
    },
    {
      name: 'Locatie toegang',
      shortName: 'al',
      description:
        'Geeft toegang to alle locaties, onderliggende deelnemers en gegevens van deelnemers',
    },
    { name: 'Diensten', shortName: 'sv', description: '' },
    { name: 'CRM', shortName: 'b', description: 'CRM bord functionaliteit' },
    {
      name: 'Notificaties',
      shortName: 'n',
      description:
        'Notificaties over specifieke wijzigingen binnen de applicatie',
    },
    {
      name: 'Music tracks',
      shortName: 'mt',
      description:
        'Functionaliteit voor muziek tracks en instellingen voor genres en keys',
    },
    {
      name: 'Licenties',
      shortName: 'li',
      description: 'Licenties voor muziek',
    },
    { name: 'Instellingen', shortName: 's', description: '' },

    ...(isSuperAdmin
      ? [
          { name: 'Admin claim', shortName: 'a' },
          { name: 'Developer claim', shortName: 'dev' },
        ]
      : []),
  ]

  const selectedRoleProfile = useSelector(state => {
    if (editingDocID) {
      const roleProfileData = state.firestore.ordered.roleProfiles?.find(
        roleProfile => roleProfile.valueRopr === editingDocID,
      )
      const newData = {
        ...roleProfileData,
        ...roleProfileData.claimsObjectRopr,
      }
      delete newData.claimsObjectRopr
      return newData
    }
  })

  const createDataStandards = useDataStandards()
  const buildRoleProfile = data => {
    const claimsObjectRopr = applicationModulesList.reduce(
      (accumulator, item) => ({
        ...accumulator,
        [item.shortName]: data[item.shortName],
      }),
      {},
    )
    const { nameRopr, valueRopr } = data
    const newData = { nameRopr, valueRopr, claimsObjectRopr }
    const dataStandards = createDataStandards({
      data: newData,
      dataName: 'Ropr',
      editForm: !!editingDocID,
    })
    return { ...dataStandards, ...newData }
  }

  const createRoleProfile = async data => {
    return new Promise((resolve, reject) => {
      firestore
        .get(`appSettings/roleProfiles/roleProfiles/${data.valueRopr}`)
        .then(response => {
          if (response.exists) {
            reject(
              'Er bestaat al een rollen profiel met dit id, verander het id',
            )
          } else {
            firestore.set(
              `appSettings/roleProfiles/roleProfiles/${data.valueRopr}`,
              data,
            )
            resolve(data)
          }
        })
    })
  }

  const updateRoleProfile = async data => {
    firestore.update(
      `appSettings/roleProfiles/roleProfiles/${data.valueRopr}`,
      data,
    )
  }
  const errorLogger = useErrorLogger()
  return (
    <Form
      docLabel='Rollen profiel'
      editingDocID={editingDocID}
      buildDocData={buildRoleProfile}
      updateDoc={updateRoleProfile}
      createDoc={createRoleProfile}
      wrapInside={wrapInside}
      modalMaxWidth={modalMaxWidth}
      docData={
        !!editingDocID
          ? selectedRoleProfile
          : applicationModulesList.reduce(
              (accumulator, item) => ({ ...accumulator, [item.shortName]: 0 }),
              {},
            )
      }
      onCloseForm={onCloseForm}
      hasDetails={false}
      onError={errorLogger}
      formInputs={({ formObject }) => (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              name='nameRopr'
              formObject={formObject}
              label='Naam rollen profiel'
              rules={{ required: 'Dit veld is verplicht' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name='valueRopr'
              formObject={formObject}
              label='ID Rollen profiel'
              rules={{ required: 'Dit veld is verplicht' }}
            />
          </Grid>
          {disableEditing && (
            <Typography color='error'>
              U heeft geen bevoegdheid om deze rollen profielen te bewerken.
              Vraag uw applicatie beheerder om hier aanpassingen te maken.
            </Typography>
          )}
          {applicationModulesList.map(module => (
            <Grid item key={`${module.name}${module.shortName}`} container>
              <Grid item xs={12} md={4}>
                <DivStyled>
                  <Typography variant='body1'>{module.name}</Typography>
                  <Typography variant='caption'>
                    {module.description}
                  </Typography>
                </DivStyled>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box pr={2}>
                  <Slider
                    name={module.shortName}
                    formObject={formObject}
                    options={Object.values(claimLevels)}
                    max={Object.values(claimLevels).length - 1}
                    disabled={disableEditing}
                  />
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    />
  )
}

import React, { useState } from 'react'
import { useSelector } from 'hooks/useSelector'
import { useFirestore } from 'react-redux-firebase'
import { Grid, Typography } from '@mui/material'
import { FormRow, Form, AutoSelect } from '@fivano/core'
import { DriveFileMove } from '@mui/icons-material'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { BoardCardBuildTypes, BoardFormtypes } from './BoardTypes'
import { OptionType } from 'types/CommonTypes'

type BoardCardFormPropTypes = {
  moveCard: any
  phaseID: string
  boardID: string
  onCloseForm: any
  editingDocID?: string
  handleOpenBoardCard?: any
}

export const BoardCardMoveForm = ({
  moveCard,
  phaseID,
  boardID,
  onCloseForm,
  editingDocID,
  handleOpenBoardCard,
}: BoardCardFormPropTypes) => {
  const firestore = useFirestore()
  const errorLogger = useErrorLogger()

  const buildBoardCardData = (data: BoardCardBuildTypes) => {
    console.log(data)
  }

  const createBoardCard = async data => {}

  const boardsData: BoardFormtypes[] = useSelector(
    ({ firestore: { ordered } }) => ordered?.boards,
  )
  const boardOptions: OptionType[] = boardsData
    ?.map(board => ({
      label: board.nameBoard,
      // @ts-ignore
      value: board.id,
    }))
    .filter(item => item.value !== boardID)

  const [phaseOptions, setPhaseOptions] = useState([])
  return (
    <Form
      wrapInside='modal'
      modalMaxWidth='sm'
      hasDetails={true}
      docLabel='Board item verplaatsen / '
      docData={{}}
      buildDocData={buildBoardCardData}
      updateDoc={async () => {}}
      createDoc={createBoardCard}
      editingDocID={editingDocID}
      onCloseForm={onCloseForm}
      onError={errorLogger}
      formInputs={({ formObject }) => {
        const { getValues } = formObject
        const moveToBoard = getValues('moveToBoard')
        if (moveToBoard) {
          firestore
            .collection('boards')
            .doc(moveToBoard.value)
            .get()
            .then(response => {
              const boardData = response.data()
              const phaseOptions = boardData?.phasesBoard.map(phase => ({
                label: phase.namePhase,
                value: phase.phaseID,
              }))
              setPhaseOptions(phaseOptions)
            })
        }
        return (
          <Grid container spacing={1}>
            <FormRow icon={<DriveFileMove />}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    Selecteer een bord en fase om item te verplaatsen naar
                    anders bord.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <AutoSelect
                    label='Bord'
                    name='moveToBoard'
                    formObject={formObject}
                    rules={{ required: 'Kies een bord voor dit item' }}
                    options={boardOptions}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutoSelect
                    label='Fase'
                    name='moveToPhase'
                    formObject={formObject}
                    rules={{ required: 'Kies een fase voor dit item' }}
                    options={phaseOptions}
                  />
                </Grid>
              </Grid>
            </FormRow>
          </Grid>
        )
      }}
    />
  )
}

// ItemsList
import React, { useEffect, useState } from 'react'
import { Grid, styled, Typography } from '@mui/material'
import { format, differenceInCalendarDays } from 'date-fns'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { convertTimestampDate } from '@fivano/core'
import { nl } from 'date-fns/locale'
import { SickReportingTypes } from './SickReportingTypes'
import { usePerson } from './usePerson'
import { useSelector } from 'hooks/useSelector'
import { personFullName } from 'model/person'
import { Skeleton } from '@mui/material'
import { isLoaded } from 'react-redux-firebase'

const iconStyles = { width: 24, marginRight: 8 }

const CloseStyled = styled(CloseIcon)(() => ({
  ...iconStyles,
}))

const DoneStyled = styled(DoneIcon)(() => ({
  ...iconStyles,
}))

const GridStyled = styled(Grid)(() => ({
  minHeight: 56,
  padding: '8px 16px 8px 16px',
  display: 'flex',
  alignItems: 'center',
}))
interface Props {
  sickReportingdata: SickReportingTypes
  handleEdit?: any
}

export function SickReportingListItem({
  sickReportingdata,
  handleEdit,
}: Props) {
  const [calculatedDifference, setCalculatedDifference] = useState(0)
  const { getPerson } = usePerson()

  useEffect(() => {
    const calcDiffinCalendarDays = differenceInCalendarDays(
      convertTimestampDate(sickReportingdata?.endDateTimeSick),
      convertTimestampDate(sickReportingdata?.startDateTimeSick),
    )
    setCalculatedDifference(calcDiffinCalendarDays + 1)
    getPerson({
      uid: sickReportingdata.createdBySick,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sickReportingdata])

  const sickReporter = useSelector(
    store =>
      store.firestore?.data?.locations?.publicLocation?.persons?.[
        sickReportingdata?.createdBySick
      ],
  )
  return (
    <Grid item container>
      <GridStyled item>
        {sickReportingdata?.endDateTimeSick ? (
          <DoneStyled color='primary' fontSize='small' />
        ) : (
          <CloseStyled color='error' fontSize='small' />
        )}
        <Grid container>
          <Grid item container>
            <Typography variant='body1'>
              {format(
                convertTimestampDate(sickReportingdata?.startDateTimeSick),
                'dd MMMM',
                { locale: nl },
              )}{' '}
              t/m{' '}
              {sickReportingdata?.endDateTimeSick
                ? convertTimestampDate(
                    sickReportingdata?.endDateTimeSick,
                  )?.getFullYear() === new Date().getFullYear()
                  ? format(
                      convertTimestampDate(sickReportingdata?.endDateTimeSick),
                      'dd MMMM',
                      { locale: nl },
                    )
                  : format(
                      convertTimestampDate(sickReportingdata?.endDateTimeSick),
                      'dd MMMM yyyy',
                      { locale: nl },
                    )
                : 'heden'}{' '}
              {sickReportingdata.endDateTimeSick !== null &&
                `(${
                  calculatedDifference === 1
                    ? `${calculatedDifference} dag`
                    : `${calculatedDifference} dagen`
                })`}
            </Typography>
          </Grid>
          <Grid item container>
            {isLoaded(sickReporter) ? (
              <Typography variant='body2' color='textSecondary'>
                Ziekgemeld door:{' '}
                {sickReporter?.firstNamePers && personFullName(sickReporter)}
              </Typography>
            ) : (
              <Skeleton height={20} width={240} />
            )}
          </Grid>

          <Grid item container>
            <Typography variant='body1'>
              {sickReportingdata?.textSick}
            </Typography>
          </Grid>
        </Grid>
      </GridStyled>
    </Grid>
  )
}

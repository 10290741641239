import React, { useEffect, useState } from 'react'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase'
import { Grid } from '@mui/material'
import { optionsCreator } from 'utils/optionsCreator'
import {
  TextField,
  FormRow,
  DateTimePicker,
  AutoSelect,
  Form,
} from '@fivano/core'
import { PersonsSelector } from './PersonsSelector'
import { format } from 'date-fns'
import { useSelector } from 'hooks/useSelector'
import { useDataStandards } from 'hooks/useDataStandards'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { ProgressBoardCardFormTypes } from './BoardTypes'
import { convertToDate } from 'utils/convertToDate'
import { FileUpload } from 'app/components/FileUpload'

export const ProgressBoardCardForm = ({
  boardID,
  boardCardID,
  editingDocID,
  onCloseForm,
  wrapInside,
}: {
  boardID: string
  boardCardID: string
  editingDocID?: string
  onCloseForm: any
  wrapInside: 'page' | 'modal'
}) => {
  const docLabel = 'Voortgang'
  const defaultValues = {
    descriptionPrbc: '',
    filesPrbc: [],
    dateTimePrbc: new Date(),
    timePrbc: format(new Date(), 'HH:mm'),
  }
  const createDataStandards = useDataStandards()
  const profile = useSelector(state => state.firebase.profile)

  const firestore = useFirestore()
  const isEditing = !!editingDocID
  const progressBoardCardData: ProgressBoardCardFormTypes = useSelector(
    ({ firestore: { data } }) => {
      if (editingDocID) {
        const rawData =
          data[`boardCards_${boardCardID}_progressBoardCards`][editingDocID]
        return {
          ...rawData,
          ...{
            dateTimePrbc: convertToDate(rawData.dateTimePrbc),
          },
        }
      }
      return null
    },
  )

  const filesStorePath = `boards/${boardID}/boardCards/${boardCardID}/documents`

  const createProgressBoardCard = async data => {
    await firestore.add(
      {
        collection: 'boards',
        doc: boardID,
        subcollections: [
          {
            collection: 'boardCards',
            doc: boardCardID,
            subcollections: [{ collection: 'progressBoardCards' }],
          },
        ],
      },
      data,
    )
  }

  const updateProgressBoardCard = async data => {
    await firestore.update(
      {
        collection: 'boards',
        doc: boardID,
        subcollections: [
          {
            collection: 'boardCards',
            doc: boardCardID,
            subcollections: [
              { collection: 'progressBoardCards', doc: editingDocID },
            ],
          },
        ],
      },
      data,
    )
  }

  const [timeChanged, setTimeChanged] = useState(false)

  const buildProgressBoardCardData = (data: ProgressBoardCardFormTypes) => {
    const dataStandards = createDataStandards({
      data,
      dataName: 'Prbc',
      editForm: isEditing,
    })
    const personIDs: any[] = []
    Object.values(data.contactsPrbc).forEach((rel: any) => {
      rel.forEach(contact => {
        personIDs.push(contact.value)
      })
    })
    data.personIDs = personIDs

    if (data.dateTimePrbc && data.timePrbc && timeChanged) {
      let minutes = parseInt(data.timePrbc.substring(3, 5))
      data.dateTimePrbc.setHours(
        parseInt(data.timePrbc.substring(0, 2)),
        minutes > 0 && minutes < 60 ? minutes : 0,
      )
    } else if (!isEditing) {
      const dateNow = new Date()
      data.dateTimePrbc.setHours(dateNow.getHours(), dateNow.getMinutes())
    }

    return { ...data, ...dataStandards }
  }

  useFirestoreConnect([
    {
      collection: 'appSettings',
      doc: 'tagsProgressBoard',
      storeAs: `tagsProgressBoard`,
    },
  ])

  const tagsProgressBoard = useSelector(
    ({ firestore: { data } }) => data.tagsProgressBoard,
  )
  const [tagOptions, setTagOptions] = useState([])
  useEffect(() => {
    if (tagsProgressBoard?.options?.length > 0) {
      setTagOptions(
        optionsCreator(
          tagsProgressBoard?.options,
          'descriptionTagpb',
          'idTagpb',
        ),
      )
    }
  }, [tagsProgressBoard])

  const relationIDs = useSelector(({ firestore: { data } }) => {
    if (boardCardID) {
      return data[
        `boards_${boardID}_boardCards_${boardCardID}`
      ].relationsBoca.map(rel => rel.relation)
    }
    return null
  })

  const errorLogger = useErrorLogger()

  return (
    <Form
      wrapInside={wrapInside || 'modal'}
      modalMaxWidth='sm'
      hasDetails={true}
      docLabel={docLabel}
      buildDocData={buildProgressBoardCardData}
      updateDoc={updateProgressBoardCard}
      createDoc={createProgressBoardCard}
      editingDocID={editingDocID}
      docData={!!editingDocID ? progressBoardCardData : defaultValues}
      onCloseForm={onCloseForm}
      onError={errorLogger}
      formInputs={({ formObject, showAllFields, fileChangesState }) => (
        <Grid container spacing={1}>
          {relationIDs.map(rel => (
            <Grid key={rel.value} item xs={12}>
              <PersonsSelector
                name={`contactsPrbc[${'relation_' + rel.value}]`}
                formObject={formObject}
                relation={rel}
              />
            </Grid>
          ))}
          <Grid item xs={12} md={12}>
            <TextField
              name='descriptionPrbc'
              formObject={formObject}
              rules={{ required: 'Voeg een notitie toe' }}
              label='Notitie'
              rows={4}
              maxRows={20}
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <AutoSelect
              name='tagsPrbc'
              formObject={formObject}
              label='Tags'
              multiple
              options={tagOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <FileUpload
              onError={errorLogger}
              profile={profile}
              fileChangesState={fileChangesState}
              formObject={formObject}
              filesStorePath={filesStorePath}
              name='filesPrbc'
              label='Bestanden toevoegen'
            />
          </Grid>
          <FormRow showAllFields={showAllFields}>
            <DateTimePicker
              setTimeChanged={setTimeChanged}
              formObject={formObject}
              dateName='dateTimePrbc'
              timeName='timePrbc'
            />
          </FormRow>
        </Grid>
      )}
    />
  )
}

import React from 'react'
import { Box, Grid, capitalize } from '@mui/material'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import { AutoSelect } from '@fivano/core'

import { useSelector } from 'hooks/useSelector'
import { useDataStandards } from 'hooks/useDataStandards'
import { optionsCreator } from 'utils/optionsCreator'
import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'

import BedrijfLocatie from 'app/icons/BedrijfLocatie'
import { LabelOutlined } from '@mui/icons-material'

import { RouteTypes } from 'types/RouteTypes'
import { useTrackLocationOptions } from 'hooks/useTrackLocationOptions'
import { useSettings } from 'hooks/useSettings'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { Form, FormRow } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { OptionType } from 'types/CommonTypes'
import { PersonTagsDataTypes } from './PersonTagsTypes'

export const PersonTagsForm = ({
  formProps: { linkedPersonTagsData, tagName: tagTitle },
  onCloseForm,
  modalMaxWidth,
  wrapInside,
}) => {
  const { participantID } = useParams<RouteTypes>()
  const firestore = useFirestore()
  const tagName = tagTitle.toLowerCase()
  const settings = useSettings()
  const labels = settings.tracks.labels

  const locationQuery = useLocationQuery()
  useFirestoreConnect([
    {
      collection: 'appSettings',
      doc: 'personTags',
      storeAs: 'appSettings_personTags',
    },
    {
      collectionGroup: 'tracks',
      limit: 50,
      where: [
        ['personIDTrack', '==', participantID],
        ...locationQuery('locationIDTrack'),
      ],
      orderBy: ['endDateTrack', 'desc'],
      storeAs: `persons_${participantID}_tracks`,
    },
    {
      collection: `locations/publicLocation/persons/${participantID}/personTags`,
      storeAs: `participant_${participantID}_personTagsLita`,
    },
  ])

  const personTagsData: PersonTagsDataTypes = useSelector(
    state => state.firestore.ordered.appSettings_personTags?.[0],
  )

  const trackOptions: Array<OptionType> = useTrackLocationOptions({
    participantID,
    onlyActiveTracks: true,
  })

  trackOptions.push({ label: 'Openbaar', value: 'publicLocation' })

  const PersonTagsFormConfig = {
    docLabel: 'Persontags',
    defaultValues: {
      trackPeta: { label: 'Openbaar', value: 'publicLocation' },
    },
  }

  const personTagsDocData = useSelector(
    state =>
      state.firestore.ordered[
        `participant_${participantID}_personTagsLita`
      ]?.[0],
  )

  const createDataStandards = useDataStandards()

  const buildPersonTagsData = data => {
    data.idPeta = firestoreIDGenerator()
    data.personIDPeta = participantID
    const locationID = 'publicLocation'
    data.trackPeta.value = locationID
    data.locationPeta = { label: ' Openbaar', value: locationID }
    data.locationIDPeta = locationID
    const dataStandards = createDataStandards({
      data,
      dataName: 'Peta',
    })
    return { ...data, ...dataStandards }
  }

  const createPersonTags = async data => {
    if (personTagsDocData?.personTagsIDsLita?.length > 0) {
      await firestore.update(
        `locations/publicLocation/persons/${participantID}/personTags/personTags`,
        {
          personTagsLita: firestore.FieldValue.arrayUnion(data),
          personTagsIDsLita: firestore.FieldValue.arrayUnion(
            data.linkPersonTagLita.value,
          ),
        },
      )
    } else {
      await firestore.set(
        `locations/publicLocation/persons/${participantID}/personTags/personTags`,
        {
          name: 'personTags',
          personTagsLita: [data],
          personTagsIDsLita: [data.linkPersonTagLita.value],
        },
      )
    }
  }
  const errorLogger = useErrorLogger()
  return (
    <Form
      docLabel={PersonTagsFormConfig.docLabel}
      buildDocData={buildPersonTagsData}
      createDoc={createPersonTags}
      updateDoc={async () => {}}
      wrapInside={wrapInside}
      modalMaxWidth={modalMaxWidth}
      onCloseForm={onCloseForm}
      hasDetails={false}
      docData={PersonTagsFormConfig.defaultValues}
      onError={errorLogger}
      formInputs={({ formObject }) => {
        const { watch } = formObject
        const personTagsCategory = watch('linkCategoryTagLita')
        const getTagsOptions = () => {
          if (personTagsCategory) {
            const tagsCategory = personTagsData?.options?.find(
              category => category.idPeta === personTagsCategory.value,
            )
            const enabledOptions = tagsCategory?.categoryTagsPeta?.filter(
              option => !option.archivedTagPeta,
            )
            const allOptions = optionsCreator(
              enabledOptions,
              'labelPeta',
              'idTagPeta',
            )
            return allOptions.filter(
              option =>
                !linkedPersonTagsData?.some(
                  personTag =>
                    personTag.linkPersonTagLita.value === option.value,
                ),
            )
          } else {
            return []
          }
        }
        return (
          <Box minHeight={140}>
            <Grid container spacing={2}>
              <FormRow icon={<BedrijfLocatie />}>
                <AutoSelect
                  label={`${capitalize(labels.track)}`}
                  formObject={formObject}
                  name='trackPeta'
                  options={trackOptions}
                  disableCloseOnSelect={false}
                  rules={{ required: 'Verplicht veld' }}
                />
              </FormRow>
              <FormRow icon={<LabelOutlined />}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <AutoSelect
                      label='Categorie tag'
                      formObject={formObject}
                      name='linkCategoryTagLita'
                      options={optionsCreator(
                        personTagsData?.options?.filter(
                          tagData => tagData.archivedPeta !== true,
                        ),
                        'personTagCategoryPeta',
                        'idPeta',
                      )}
                      disableCloseOnSelect={false}
                      rules={{
                        required: `Kies een ${tagName} categorie die bij deze deelnemer wordt toegevoegd.`,
                        minLength: {
                          value: personTagsData?.options.length === 0,
                          message: `Er zijn nog geen ${tagName} categorieën aangemaakt`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AutoSelect
                      label={tagName}
                      formObject={formObject}
                      name='linkPersonTagLita'
                      disabled={!personTagsCategory}
                      options={getTagsOptions()}
                      disableCloseOnSelect={false}
                      rules={{
                        required: `Kies een ${tagName} die bij deze deelnemer wordt toegevoegd.`,
                        minLength: {
                          value: personTagsData?.options.length === 0,
                          message: `Geen ${tagName} om aan deze persoon te  koppelen.`,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </FormRow>
            </Grid>
          </Box>
        )
      }}
    />
  )
}

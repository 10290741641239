import React from 'react'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { Grid } from '@mui/material'
import { optionsCreator } from 'utils/optionsCreator'
import { SettingsForm } from 'app/legacy/SettingsBlock/SettingsForm'
import { TextField, Switch, FormRow, Checkbox, AutoSelect } from '@fivano/core'

export const DocumentRequiredTypesForm = props => {
  // // get items from firebase and put them in redux store
  useFirestoreConnect([
    {
      collection: 'appSettings',
      doc: 'documentCategory',
      storeAs: 'documentCategory',
    },
  ])

  // get items from redux store and put them in variable
  const documentCategoryData = useSelector(
    ({ firestore: { ordered } }) => ordered.documentCategory,
  )
  return (
    <>
      <SettingsForm {...props}>
        {({ formObject, showAllFields, isEditing }) => {
          return (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  rules={{ required: 'Voer een document label in' }}
                  name={`description${props.firestoreCode}`}
                  formObject={formObject}
                  readOnly={isEditing}
                  label='Document label'
                />
              </Grid>
              <Grid item xs={12}>
                <AutoSelect
                  label='Categorie kiezen'
                  name={`category${props.firestoreCode}`}
                  formObject={formObject}
                  options={optionsCreator(
                    documentCategoryData
                      ? documentCategoryData[0]?.options
                      : [],
                    'descriptionDoca',
                    'idDoca',
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  formObject={formObject}
                  name={`mandatory${props.firestoreCode}`}
                  label='Verplicht document'
                />
              </Grid>
              <FormRow showAllFields={showAllFields}>
                <TextField
                  name={`id${props.firestoreCode}`}
                  formObject={formObject}
                  readOnly={isEditing}
                  label='Document ID'
                />
                <Switch
                  name={`archived${props.firestoreCode}`}
                  formObject={formObject}
                  label='Verplicht document archiveren'
                />
              </FormRow>
            </Grid>
          )
        }}
      </SettingsForm>
    </>
  )
}

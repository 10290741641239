import React from 'react'
import { FloatingButton, PageContainer } from '@fivano/core'
import { Paper } from '@mui/material'
import { DataGridNew } from 'app/components/DataGridNew'
import { GridColDef } from '@mui/x-data-grid-pro'
import useMongo from 'hooks/useMongo'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { useHistory } from 'react-router-dom'
import { personRoutes } from './personRoutes'

const personColumnsDesktop: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'Persoon',
    disableColumnMenu: true,
  },
]

const personColumnsMobile: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Persoon',
    disableColumnMenu: true,
  },
]

export const PersonsList = () => {
  const history = useHistory()
  const { useQueryDocs } = useMongo()
  const { docsData } = useQueryDocs({
    collection: 'persons',
    array: true,
  })

  return (
    <PageContainer maxWidth='lg'>
      <Breadcrumbs />
      <Paper>
        <DataGridNew
          name='persons'
          rows={docsData || []}
          columns={personColumnsDesktop}
          columnsMobile={personColumnsMobile}
          hideFooterSelectedRowCount={true}
          hideFooterRowCount={true}
          isEditable={true}
          onRowClick={rowData =>
            history.push(`${personRoutes.personList.path}/${rowData.row._id}`)
          }
        />
      </Paper>
      <FloatingButton
        label='Persoon toevoegen'
        onClick={() => history.push(personRoutes.personFormNew.path)}
      />
    </PageContainer>
  )
}

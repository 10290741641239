import React from 'react'
import { useFirestoreConnect } from 'react-redux-firebase'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import PhoneIcon from '@mui/icons-material/Phone'
import { Relation } from 'types/Relation'
import { useSelector } from 'hooks/useSelector'

export const RelationPreview = ({ relationID }) => {
  useFirestoreConnect([
    {
      collection: 'relations',
      doc: relationID,
      storeAs: `relations_${relationID}`,
    },
  ])
  const relationData: Relation = useSelector(
    ({ firestore: { data } }) => data[`relations_${relationID}`],
  )

  if (!relationData) {
    return <div>Loading...</div>
  } else {
    return (
      <Card>
        <CardHeader
          subheader={relationData.addressCityRela}
          title={relationData.nameRela}
        ></CardHeader>
        <Divider />
        <List>
          {relationData.mailEmailRela && (
            <ListItem
              button
              component='a'
              href={`mailto:${relationData.mailEmailRela}`}
            >
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary={relationData.mailEmailRela} />
            </ListItem>
          )}
          {relationData.phoneRela && (
            <ListItem
              button
              component='a'
              href={`tel:${relationData.phoneRela}`}
            >
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary={relationData.phoneRela} />
            </ListItem>
          )}
        </List>
        <CardActions>
          <Button href={`/bedrijven/${relationID}`} target='_blank'>
            Open bedrijfspagina
          </Button>
        </CardActions>
      </Card>
    )
  }
}

import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  capitalize,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { useSnackbar } from '@fivano/core'
import { useSelector } from 'hooks/useSelector'
import { useFirestore } from 'react-redux-firebase'
import { format } from 'date-fns'
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader'
import {
  IconButton,
  Dialog,
  EmptyContent,
  LoadingOverlay,
  Modal,
} from '@fivano/core'
import { TimeRegistrationWidgetHeader } from './TimeRegistrationWidgetHeader'
import { TimeRegistrationWidgetForm } from './TimeRegistrationWidgetForm'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { roundOffSecondsToHours } from 'utils/roundOffSecondsToHours'
import { nl } from 'date-fns/locale'
import { convertTimestampDate } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { TrackType } from 'types/Track'
import { ListItemBase } from 'app/components/ListItems/ListItemBase'

type TimeRegistrationWidgetTypes = {
  trackData: TrackType[]
  disableEditing?: boolean
}
type TimeRegistrationFormPropsTypes = {
  timeRegistrationID: string | null
  open: boolean
  trackData: TrackType | null
  docData: any | undefined
}
export const TimeRegistrationWidget = ({
  trackData,
  disableEditing = false,
}: TimeRegistrationWidgetTypes) => {
  const firestore = useFirestore()
  const errorLogger = useErrorLogger()
  const { enqueueSnackbar } = useSnackbar()

  const [loadingMessage, setLoadingMessage] = useState('')

  // ==== TIME REGISTRATION ADD AND EDIT ==== //
  const [TimeRegistrationFormProps, setTimeRegistrationFormProps] =
    useState<TimeRegistrationFormPropsTypes>({
      timeRegistrationID: null,
      open: false,
      trackData: null,
      docData: undefined,
    })

  const [trackIDs, setTrackIDs] = useState<Array<string>>([])
  const [dataChanged, setDataChanged] = useState(true)
  useEffect(() => {
    if (trackData && dataChanged) {
      const combine: Array<string> = []
      trackData.forEach(track => {
        const findLimit = itemsQueryLimit.find(() => track.id)
        firestore.get({
          collection: 'timeRegistrations',
          where: [['trackIDTire', '==', track.id]],
          orderBy: ['startTimeTire', 'desc'],
          limit: findLimit ? findLimit.limit : 2,
          storeAs: `track_${track.id}`,
        })
        combine.push(track.id)
      })
      setDataChanged(false)
      setTrackIDs(combine)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackData, dataChanged])

  const timeData = useSelector(({ firestore: { ordered } }) => {
    const collect: Array<any> = []
    trackIDs.forEach(id => {
      const data = ordered[`track_${id}`]
      const emptyArray: Array<any> = []
      data?.forEach(timeItem => {
        const emptyObject: any = { ...timeItem }
        emptyObject.startDateTire = convertTimestampDate(timeItem.startTimeTire)
        emptyObject.startTimeTire = convertTimestampDate(timeItem.startTimeTire)
        emptyObject.endTimeTire = convertTimestampDate(timeItem.endTimeTire)
        emptyArray.push(emptyObject)
      })
      collect.push({ trackID: id, data: emptyArray })
    })

    return collect
  })

  // ==== LOAD MORE TIME REGISTRATION DOCS ==== //
  const [itemsQueryLimit, setItemsQueryLimit] = useState<any>([])
  const [itemsQueryLimitCopy, setItemsQueryLimitCopy] = useState<any>([])
  useEffect(() => {
    if (itemsQueryLimit) {
      itemsQueryLimit.forEach((limitItem, index) => {
        if (
          limitItem.id !== itemsQueryLimitCopy[index]?.id ||
          limitItem.limit !== itemsQueryLimitCopy[index]?.limit
        ) {
          firestore.get({
            collection: 'timeRegistrations',
            limit: limitItem.limit,
            orderBy: ['startTimeTire', 'desc'],
            where: [['trackIDTire', '==', limitItem.id]],
            storeAs: `track_${limitItem.id}`,
          })
        }
      })
      setItemsQueryLimitCopy(itemsQueryLimit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsQueryLimit])

  const handleQueryLimits = id => {
    const index = itemsQueryLimit.findIndex((x: any) => x.id === id)
    if (index === -1) {
      const limitObject = { id: id, limit: 4 }
      setItemsQueryLimit(itemsQueryLimit => [...itemsQueryLimit, limitObject])
    } else {
      const queryLimitsCopy = [...itemsQueryLimit]
      const limitObject = {
        id: queryLimitsCopy[index].id,
        limit: queryLimitsCopy[index].limit + 2,
      }
      queryLimitsCopy[index] = limitObject
      setItemsQueryLimit(queryLimitsCopy)
    }
  }

  // ==== DELETE TIME REGISTRATION LOGIC ==== //
  const [deleteModal, setDeleteModal] = useState<any>({
    id: '',
    data: {},
    message: '',
  })

  const handleDeleteTime = (data, track) => {
    const dataCopy: any = { ...data }
    dataCopy.totalMinutesTrack = track.totalMinutesTrack
    dataCopy.locationIDTrack = track.locationIDTrack
    dataCopy.idTrack = track.id

    setDeleteModal({
      id: dataCopy.id,
      data: dataCopy,
      message: `Gegevens verwijderen`,
    })
  }

  const handleDeleteTimeRegistration = data => {
    setLoadingMessage('Uren verwijderen')
    firestore
      .delete({
        collection: 'timeRegistrations',
        doc: deleteModal.id,
      })
      .then(() => {
        const newDuration = data.totalMinutesTrack - data.durationTire
        setDataChanged(true)
        firestore
          .update(
            `locations/${data.locationIDTrack}/persons/${data.personIDTire}/tracks/${data.idTrack}`,
            { totalMinutesTrack: newDuration },
          )
          .then(() => {
            setLoadingMessage('')
            enqueueSnackbar('Uren succesvol verwijderd.', {
              variant: 'success',
            })
          })
          .catch(error => {
            errorLogger({ error })
          })
      })
      .catch(error => {
        errorLogger({
          error,
          snackbarMessage: `Uren verwijderen mislukt. ${error}`,
        })
      })
  }

  return (
    <>
      {TimeRegistrationFormProps.open && (
        <TimeRegistrationWidgetForm
          wrapInside='modal'
          modalMaxWidth='sm'
          onCloseForm={() => {
            setTimeRegistrationFormProps({
              timeRegistrationID: null,
              open: false,
              trackData: null,
              docData: undefined,
            })
          }}
          onDataChanged={() => setDataChanged(true)}
          editingDocID={TimeRegistrationFormProps.timeRegistrationID}
          formProps={TimeRegistrationFormProps}
        />
      )}
      <Dialog
        id={deleteModal.id}
        deleteTitle={deleteModal.message}
        deleteSubtitle='Dit item zal permanent verwijderd worden.'
        open={deleteModal.id !== ''}
        onClose={() => setDeleteModal({ id: '', data: {}, message: '' })}
        onCancel={() => setDeleteModal({ id: '', data: {}, message: '' })}
        onDelete={handleDeleteTimeRegistration}
        data={deleteModal.data}
      />
      <Modal
        open={loadingMessage ? true : false}
        maxWidth='sm'
        customHeight='200px'
      >
        {loadingMessage !== '' && <LoadingOverlay label={loadingMessage} />}
      </Modal>
      <WidgetHeader title='Tijdregistratie' icon={ScheduleIcon} />
      <Grid container spacing={2}>
        {trackData?.length > 0 ? (
          trackData.map(track => {
            const timeItemsData = timeData.filter(
              x => x.trackID === track.id,
            )[0]
            const queryLimit = itemsQueryLimit.filter(x => x.id === track.id)[0]
            const type = track?.trackTypeTrack?.value
            const trackDuration =
              type === 'brons'
                ? 80 * 60
                : type === 'mdtKort50uur'
                ? 50 * 60
                : type === 'zilver'
                ? 120 * 60
                : type === 'goud'
                ? 160 * 60
                : type === 'light' || type === 'mdtOekraine'
                ? 32 * 60
                : 160 * 60
            return (
              <Grid key={track.id} item xs={12}>
                <Paper>
                  <Grid container>
                    <TimeRegistrationWidgetHeader
                      currentValue={
                        track?.totalMinutesTrack ? track?.totalMinutesTrack : 0
                      }
                      maximumValue={trackDuration}
                      label={track.locationTrack.label}
                      subLabel={track.moduleTrack.label}
                    />
                    <Grid item xs={12}>
                      <Box px={2}>
                        <Grid
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item>
                            <Typography color='textSecondary'>Datum</Typography>
                          </Grid>
                          {!disableEditing && (
                            <Grid item>
                              <Button
                                color='primary'
                                onClick={() => {
                                  setTimeRegistrationFormProps({
                                    timeRegistrationID: null,
                                    open: true,
                                    trackData: track,
                                    docData: undefined,
                                  })
                                }}
                              >
                                Toevoegen
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                      <Divider />
                    </Grid>
                    {timeItemsData?.data?.length > 0 ? (
                      <>
                        {timeItemsData?.data?.map((timeItem, index) => {
                          return (
                            <ListItemBase
                              key={index}
                              style={{ padding: '15px 8px' }}
                              {...(disableEditing === false && {
                                listItemActions: (
                                  <Grid item>
                                    <IconButton
                                      label='Bewerken'
                                      onClick={() => {
                                        setTimeRegistrationFormProps({
                                          timeRegistrationID: timeItem.id,
                                          open: true,
                                          trackData: track,
                                          docData: timeItem,
                                        })
                                      }}
                                      size='large'
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      label='Verwijderen'
                                      onClick={() => {
                                        handleDeleteTime(timeItem, track)
                                      }}
                                      size='large'
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Grid>
                                ),
                              })}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography>
                                    {capitalize(
                                      format(
                                        timeItem.startDateTire,
                                        'EEEEEE dd MMM yyyy',
                                        { locale: nl },
                                      ),
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography display='inline'>
                                    {format(timeItem.startTimeTire, 'HH:mm')}
                                    {' - '}
                                    {format(timeItem.endTimeTire, 'HH:mm')}
                                  </Typography>
                                  <Typography display='inline' noWrap>
                                    {' '}
                                    (
                                    {roundOffSecondsToHours(
                                      timeItem.durationTire,
                                    )}
                                    )
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ListItemBase>
                          )
                        })}
                        {timeItemsData.data?.length ===
                          (queryLimit ? queryLimit.limit : 2) && (
                          <Button
                            fullWidth
                            onClick={() => {
                              handleQueryLimits(track.id)
                            }}
                          >
                            Meer laden
                          </Button>
                        )}
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <EmptyContent text='Nog geen tijden geboekt op opdracht' />
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            )
          })
        ) : (
          <Grid item xs={12}>
            <EmptyContent
              text='Er is nog geen opdracht toegewezen aan jouw account. Vraag
                  een begeleider van FC Noaber voor een opdracht.'
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export const isSystemDark = window?.matchMedia
  ? window.matchMedia('(prefers-color-scheme: dark)')?.matches
  : undefined

export function saveThemeMode(themeMode) {
  window.localStorage && localStorage.setItem('selectedThemeMode', themeMode)
}

export function getThemeModeFromStorage(): boolean {
  const modeStorage =
    window.localStorage && localStorage.getItem('selectedThemeMode')
  return modeStorage ? JSON.parse(modeStorage) : isSystemDark
}

import { useSortable } from '@dnd-kit/sortable'
import { styled } from '@mui/material'
import { forwardRef } from 'react'
import { Position } from './SortableContainer'
import { CategoryCard } from './CategoryCard'

type SortableItemProps = {
  item: any
  activeID: any
  activeIndex: number
  onDelete: (data: any) => void
  onClick: (id: string) => void
}

export const SortableItem = ({
  item,
  activeID,
  activeIndex,
  onDelete,
  onClick,
  ...props
}: SortableItemProps) => {
  const {
    attributes,
    listeners,
    index,
    isDragging,
    isSorting,
    over,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: item.id,
  })

  return (
    <div
      key={item.id}
      onClick={() => onClick(item.id)}
      style={{ marginRight: 10 }}
    >
      <SortableItemContainer
        ref={setNodeRef}
        id={item.id}
        active={isDragging}
        style={{
          transition,
          transform: isSorting
            ? undefined
            : `translate3d(${transform?.x}px, ${transform?.y}px, 0)`,
        }}
        insertPosition={
          over?.id === item.id
            ? index > activeIndex
              ? Position.After
              : Position.Before
            : undefined
        }
        {...props}
        {...attributes}
        {...listeners}
      >
        <div style={{ width: 130, height: 130 }}>
          {isDragging ? (
            <CategoryCard placeholder />
          ) : (
            <CategoryCard
              active={activeID === item.id}
              data={item}
              onDelete={onDelete}
            />
          )}
        </div>
      </SortableItemContainer>
    </div>
  )
}

const ItemWrapper = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'active' &&
    prop !== 'clone' &&
    prop !== 'insertBefore' &&
    prop !== 'insertAfter',
})<any>(({ theme, active, clone, insertBefore, insertAfter }) => ({
  position: 'relative',
  marginBottom: '0.5rem',
  ...(!active &&
    !clone && {
      '&:after': {
        content: '""',
        position: 'absolute',
        backgroundColor: theme.palette.primary.main,
        ...(insertBefore && {
          width: '2px',
          height: '140px',
          left: -5,
          top: -5,
        }),
        ...(insertAfter && {
          width: '2px',
          height: '140px',
          right: -5,
          top: -5,
        }),
      },
    }),
}))

export const SortableItemContainer = forwardRef(
  (
    {
      id,
      index,
      active,
      clone,
      insertPosition,
      onRemove,
      style,
      children,
      ...props
    }: any,
    ref,
  ) => {
    return (
      <ItemWrapper
        active={active}
        clone={clone}
        insertBefore={insertPosition === -1 ? true : false}
        insertAfter={insertPosition === 1 ? true : false}
        className={`${insertPosition === -1 ? 'before' : 'after'}`}
        style={style}
        ref={ref}
        data-id={id}
        {...props}
      >
        {children}
      </ItemWrapper>
    )
  },
)

import React from 'react'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { useDataStandards } from 'hooks/useDataStandards'
import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'
import { Form } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'

/** Types required to use the SettingsForm */
interface FormTypes {
  /** LabelSingle is for the form label and screen labels */
  labelSingle: string
  /** String indicating the abbreviation for the document name */
  firestoreCode: string
  /** String representing the path to the correct firebase collection document */
  firestoreSettingsPath: string
  /** Object that provides the ID prop and open prop so that the form can be edited or newly created */
  formProps: any
  /** Function that is triggered when the form is closed */
  onClose: any
  /** Components visible as children in the page, for example TextFields, AutoComplete or AutoSelect */
  children: any
  /** DefaultValues of form, when empty the default value of the field is used, when editing existing item the data is used */
  defaultValues?: any
}
export const SettingsForm = ({
  labelSingle,
  firestoreCode,
  firestoreSettingsPath,
  formProps,
  onClose,
  children,
  defaultValues,
}: FormTypes) => {
  const firestore = useFirestore()
  const formConfig = {
    docLabel: labelSingle,
    defaultValues: defaultValues,
  }
  const createDataStandards = useDataStandards()

  const buildForm = data => {
    if (data[`id${firestoreCode}`] === '') {
      data[`id${firestoreCode}`] = firestoreIDGenerator()
    }

    const dataStandards = createDataStandards({
      data,
      dataName: firestoreCode,
      editForm: !!formProps.formID,

      archived: data[`archived${firestoreCode}`],
    })

    return { ...data, ...dataStandards }
  }

  const updateForm = async data => {
    const optionArray = [...listData]
    const replaceIndex = optionArray.findIndex(
      item => item[`id${firestoreCode}`] === formProps.formID,
    )
    optionArray[replaceIndex] = data
    await firestore.update(`appSettings/${firestoreSettingsPath}`, {
      options: optionArray,
    })
  }

  const createForm = async data => {
    if (!listData) {
      await firestore.set(`appSettings/${firestoreSettingsPath}`, {
        settingsName: firestoreSettingsPath,
        options: [data],
      })
    } else {
      await firestore.update(`appSettings/${firestoreSettingsPath}`, {
        options: [data, ...listData],
      })
    }
  }

  useFirestoreConnect([
    {
      collection: 'appSettings',
      doc: firestoreSettingsPath,
      storeAs: firestoreSettingsPath,
    },
  ])

  const listData = useSelector(
    ({ firestore: { data } }) => data[firestoreSettingsPath]?.options,
  )

  const selectedItemData = listData
    ? listData?.find(item => {
        return item[`id${firestoreCode}`] === formProps?.formID
      })
    : {}

  const errorLogger = useErrorLogger()

  formConfig.defaultValues = selectedItemData
  return (
    formProps.open && (
      <Form
        docLabel={formConfig.docLabel}
        editingDocID={formProps?.formID}
        buildDocData={buildForm}
        updateDoc={updateForm}
        createDoc={createForm}
        docData={
          !!formProps.formID ? selectedItemData : formConfig.defaultValues
        }
        hasDetails={true}
        wrapInside='modal'
        modalMaxWidth='sm'
        onCloseForm={() => onClose({ formID: null, open: false })}
        onError={errorLogger}
        formInputs={({ formObject, showAllFields }) => {
          return children({
            formObject: formObject,
            formID: formProps.formID,
            showAllFields: showAllFields,
            isEditing: !!formProps.formID,
          })
        }}
      />
    )
  )
}

import React, { useState } from 'react'
import { useSelector } from 'hooks/useSelector'
import { useFirestoreConnect } from 'react-redux-firebase'

import { Grid, Paper, Box } from '@mui/material'

import { ListItemBase } from 'app/components/ListItems/ListItemBase'
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader'
import PersoonAfwezig from 'app/icons/PersoonAfwezig'
import { EmptyContent } from '@fivano/core'
import { SickReportingListItem } from './SickReportingListItem'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { SickReportingForm } from './SickReportingForm'
import { SickReportingTypes } from './SickReportingTypes'

type SickReportingWidgetTypes = {
  person: any
  participantID: string
}

export const SickReportingWidget = ({
  person,
  participantID,
}: SickReportingWidgetTypes) => {
  const [sickReportingFormProps, setSickReportingFormProps] = useState<any>({
    sickReportingID: '',
    open: false,
  })

  const locationQuery = useLocationQuery()

  useFirestoreConnect([
    {
      collectionGroup: 'sickReporting',
      orderBy: ['startDateTimeSick', 'desc'],
      where: [
        ['personIDSick', '==', participantID],
        ...locationQuery('locationIDSick'),
      ],
      limit: 50,
      storeAs: `persons_${participantID}_sickReporting`,
    },
  ])
  const sickReportingData: SickReportingTypes[] = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[`persons_${participantID}_sickReporting`],
  )
  const sickReportingOpen = sickReportingData?.filter(
    item => item?.endDateTimeSick === null,
  )
  const sickReportingClosed = sickReportingData?.filter(
    item => item?.endDateTimeSick !== null,
  )
  return (
    <Grid item xs={12}>
      {sickReportingFormProps.open && (
        <SickReportingForm
          wrapInside='modal'
          modalMaxWidth='sm'
          onCloseForm={() => {
            setSickReportingFormProps({ sickReportingID: '', open: false })
          }}
          editingDocID={sickReportingFormProps.sickReportingID}
          person={person}
        />
      )}
      <WidgetHeader
        title='Ziekte en verzuim'
        buttonLabel='Toevoegen'
        icon={PersoonAfwezig}
        onButtonClick={() =>
          setSickReportingFormProps({ sickReportingID: '', open: true })
        }
      />
      {sickReportingData?.length > 0 ? (
        <Paper>
          <Box py={1}>
            {[...sickReportingOpen, ...sickReportingClosed].map(data => {
              return (
                <ListItemBase
                  key={data.id}
                  chevronIcon
                  onClick={() =>
                    setSickReportingFormProps({
                      sickReportingID: data.id,
                      open: true,
                    })
                  }
                >
                  <SickReportingListItem sickReportingdata={data} />
                </ListItemBase>
              )
            })}
          </Box>
        </Paper>
      ) : (
        <EmptyContent text='Geen ziek- of verzuimmelding gevonden.' />
      )}
    </Grid>
  )
}

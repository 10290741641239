import React from 'react'
import { Form, TextField } from '@fivano/core'
import { modelConfigs, roleProfilesConfig } from '@fivano/models'
import useMongo from 'hooks/useMongo'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { Grid, Paper } from '@mui/material'
import { RoleInput } from './RoleInput'
import { useHistory, useParams } from 'react-router-dom'
import { RouteTypes } from 'types/RouteTypes'

export const RoleProfileForm = () => {
  const history = useHistory()
  const errorLogger = useErrorLogger()
  const { updateDoc, useRefetchDoc, createDoc } = useMongo()
  const { roleProfileID } = useParams<RouteTypes>()
  const isEditing = Boolean(roleProfileID) && roleProfileID !== 'toevoegen'

  const createRoleProfile = async data =>
    createDoc({ collection: 'roleProfiles', data })

  const updateRoleProfile = async data =>
    updateDoc({
      collection: 'roleProfiles',
      docID: roleProfileID,
      data,
    })

  const { status, docData } = useRefetchDoc({
    collection: 'roleProfiles',
    docID: roleProfileID,
    disabled: !roleProfileID,
  })

  return (
    <Form
      modelConfig={roleProfilesConfig}
      docData={
        isEditing
          ? docData
          : modelConfigs.reduce(
              (accumulator, item) => ({ ...accumulator, [item.collection]: 0 }),
              {},
            )
      }
      loading={status === 'loading'}
      hasDetails={false}
      editingDocID={isEditing ? roleProfileID : undefined}
      updateDoc={updateRoleProfile}
      createDoc={createRoleProfile}
      onCloseForm={() => history.goBack()}
      wrapInside='page'
      docLabel='Rollen profiel'
      onError={errorLogger}
      formInputs={({ formObject }) => (
        <Paper sx={{ padding: 3 }}>
          <TextField
            label='Naam rollen profiel'
            name='name'
            formObject={formObject}
          />
          <Grid container>
            {modelConfigs.map(model => (
              <RoleInput
                key={model.collection}
                model={model}
                name={`roles.${model.collection}`}
                formObject={formObject}
              />
            ))}
          </Grid>
        </Paper>
      )}
    />
  )
}

import React, { useState } from 'react'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  Typography,
  FormLabel,
  styled,
} from '@mui/material'
import { Controller, RegisterOptions } from 'react-hook-form'
import { OptionType } from '../../types/CommonTypes'
import { ErrorMessage } from '@hookform/error-message'

const FormControlLabelStyled = styled(FormControlLabel, {
  shouldForwardProp: prop => prop !== 'outlined' && prop !== 'isChecked',
})<any>(({ theme, outlined, isChecked }) => ({
  ...(outlined && {
    width: '100%',
    marginBottom: 2,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
  }),
  ...(isChecked && {
    border: `1px solid ${theme.palette.primary.main}`,
  }),
}))

type CheckboxGroupProps = {
  /**Object with all the form methods from react-hook-form.*/
  formObject: any
  /**Name of the CheckboxGroup.*/
  name: string
  /**Visible label.*/
  label?: string
  /**Array of items that show the available options*/
  options: OptionType[]
  /**Boolean if the direction of the CheckboxGroep is row or not*/
  row?: boolean
  /**Rules that the component needs to abide to.*/
  rules?: RegisterOptions
  /**Shown text when an error occurs*/
  helperText?: string
  /**Boolean if the group has a outlined border or not*/
  outlined?: boolean
}

export const CheckboxGroup = ({
  label,
  name,
  formObject,
  options,
  row,
  rules,
  helperText,
  outlined,
}: CheckboxGroupProps) => {
  const { control, errors } = formObject
  const initialParentValue = formObject.getValues(name)
  const [checkedValues, setCheckedValues] = useState<OptionType[]>(
    initialParentValue || [],
  )
  const hasError = !!errors?.[name]
  row &&
    outlined &&
    console.warn(
      'Cannot use both row and outlined props in CheckboxGroup at the same time',
    )
  return (
    <FormControl>
      {label && <FormLabel component='legend'>{label}</FormLabel>}
      <FormGroup row={row}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          {...(initialParentValue && { defaultValue: initialParentValue })}
          // @ts-ignore
          render={({ field: { onChange } }) => {
            const handleCheck = (option: OptionType) => {
              const checkedValueIndex = checkedValues?.findIndex(
                checkedValue => checkedValue.value === option.value,
              )
              if (checkedValueIndex !== -1) {
                const newCheckedValue = checkedValues?.filter(
                  checkedValue => checkedValue.value !== option.value,
                )
                onChange(newCheckedValue)
                setCheckedValues(newCheckedValue)
              } else {
                const newCheckedValue = [...checkedValues, option]
                onChange(newCheckedValue)
                setCheckedValues(newCheckedValue)
              }
            }

            return options.map((option: OptionType, index) => {
              const isChecked =
                checkedValues.filter(
                  checkedValue => checkedValue.value === option.value,
                ).length > 0
              return (
                <FormControlLabelStyled
                  outlined={outlined}
                  isChecked={isChecked}
                  checked={isChecked}
                  key={`${option.label}${index}`}
                  label={option.label}
                  value={option}
                  control={
                    <Checkbox
                      color='primary'
                      onChange={() => handleCheck(option)}
                      checked={isChecked}
                      required
                    />
                  }
                />
              )
            })
          }}
        />
        <Typography variant='caption' color={hasError ? 'error' : 'inherit'}>
          {hasError ? <ErrorMessage name={name} errors={errors} /> : helperText}
        </Typography>
      </FormGroup>
    </FormControl>
  )
}

import React from 'react'
import { ButtonBase, Grid, styled } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const ButtonBaseStyled = styled(ButtonBase)(() => ({
  width: '100%',
  textAlign: 'left',
}))

const ContentGridStyled = styled(Grid)(() => ({
  width: 'calc(100% - 16px)',
}))

const ActionButtonGridStyled = styled(Grid, {
  shouldForwardProp: prop => prop !== 'showActions',
})<any>(({ showActions }) => ({
  visibility: 'hidden',
  position: 'absolute',
  zIndex: 5,
  height: '100%',
  width: 'auto',
  top: 0,
  right: 16,
  ...(showActions && {
    visibility: 'visible',
  }),
  '@media (hover: none) and (pointer: coarse)': {
    visibility: 'visible',
  },
}))

const DivStyled = styled('div', {
  shouldForwardProp: prop => prop !== 'style' && prop !== 'actionButtons',
})<any>(({ theme, style, actionButtons }) => ({
  ...(actionButtons && {
    '&:hover .MuiGrid-root': {
      visibility: 'visible',
    },
  }),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  width: '100%',
  position: 'relative',
  ...style,
}))

const ChevronRightIconStyled = styled(ChevronRightIcon)(() => ({
  marginRight: 4,
}))

type ListItemBaseProps = {
  /**Children elements of the ListItemBase*/
  children: any
  /**Function that gets triggered when an item is clicked*/
  onClick?: (event: any) => void
  /**Possible actions that can be taken on a ListItemBase item*/
  listItemActions?: any
  /**Boolean whether the ListItemBase item has a ChevronIcon at the end*/
  chevronIcon?: boolean
  /**Boolean whether the possible actions are visible or not*/
  showActions?: boolean
  /**Custom inline styling */
  style?: React.CSSProperties
}

export const ListItemBase = ({
  children,
  onClick,
  listItemActions,
  showActions,
  chevronIcon,
  style,
}: ListItemBaseProps) => (
  <DivStyled actionButtons style={style}>
    <ButtonBaseStyled
      onClick={event => {
        onClick && onClick(event)
      }}
      disabled={!onClick}
    >
      <ContentGridStyled item>{children && children}</ContentGridStyled>
      {chevronIcon && <ChevronRightIconStyled />}
    </ButtonBaseStyled>
    {listItemActions !== undefined && (
      <ActionButtonGridStyled
        container
        item
        showActions={showActions}
        alignItems='center'
        justifyContent='flex-end'
      >
        {listItemActions}
      </ActionButtonGridStyled>
    )}
  </DivStyled>
)

import React, { useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import useMongo from 'hooks/useMongo'
import { Searchbar } from 'app/components/Searchbar'
import { RadioGroup, ListView } from '@fivano/core'
import { useForm } from 'react-hook-form'

const DocNames = ({ docIDs, collection }) => {
  const { useGetDocs } = useMongo()
  const docsData = useGetDocs({
    collection,
    docIDs,
  })

  return (
    <>
      {docsData &&
        Object.values(docsData)?.map((docData: any) => `${docData.name}, `)}
    </>
  )
}

export const serviceListColumns = [
  {
    Header: ' ',
    canGroupBy: false,
    canSort: false,
    columns: [
      {
        Header: 'Naam',
        accessor: 'name',
        gridProps: {
          xs: 3,
        },
        Cell: data => (
          <Typography variant='body1'>{data.row.original.name}</Typography>
        ),
      },
      {
        Header: 'Categorie',
        accessor: '',
        gridProps: {
          xs: 2,
        },
        Cell: data => (
          <DocNames
            docIDs={[data.row.original.category]}
            collection='serviceCategories'
          />
        ),
      },
      {
        Header: 'Workspaces',
        accessor: '',
        gridProps: {
          xs: 2,
        },
        Cell: data => (
          <DocNames
            docIDs={data.row.original.workspaces}
            collection='workspaces'
          />
        ),
      },
    ],
  },
]

const buildFilter = data => {
  const filter: any = {}
  if (data?.showType?.value !== 'all') {
    filter.packageServ =
      data.showType?.value === 'packages' ? true : { $in: [null, false] }
  }
  return filter
}

type ServicesListProps = {
  clientSideFilter?: any
  filter?: any
  onClick: Function
}

export const ServicesList = ({
  clientSideFilter,
  filter,
  onClick,
}: ServicesListProps) => {
  const [filterByUser, setFilterByUser] = useState<any>({
    showType: { label: 'Alles', value: 'all' },
  })
  const [searchValue, setSearchValue] = useState('')
  const { useQueryDocs } = useMongo()

  const handleSearchValue = data => {
    setSearchValue(data.searchField)
  }
  const handleSearchClear = () => {
    setSearchValue('')
  }

  const { docsData, status } = useQueryDocs({
    collection: 'services',
    query: {
      // nameServ: { $regex: searchValue, $options: 'i' },
      ...buildFilter(filterByUser),
      ...filter,
    },
  })

  // ==== FILTER FUNCTIONALITY ====//
  const formObject = useForm()
  // const { handleSubmit } = useForm()
  const handleFilterSubmit = data => {
    setFilterByUser(data)
  }

  if (status === 'loading') return <p>Loading...</p>
  const filteredData = clientSideFilter
    ? Object.values(docsData).filter(clientSideFilter)
    : Object.values(docsData)

  return (
    <Box p={2}>
      <Searchbar
        placeholder={`Zoeken in diensten`}
        value={searchValue}
        elevation={0}
        onClear={handleSearchClear}
        onSubmit={handleSearchValue}
        filter={
          <>
            <Grid container>
              <Grid item xs={12}>
                <RadioGroup
                  label='Toon:'
                  formObject={formObject}
                  row
                  name='showType'
                  options={[
                    { label: 'Alles', value: 'all' },
                    { label: 'Losse diensten', value: 'singleServices' },
                    { label: 'Pakketten', value: 'packages' },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Button
                  disableElevation
                  color='primary'
                  variant='contained'
                  onClick={formObject.handleSubmit(handleFilterSubmit)}
                >
                  Zoeken
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
      <Box marginTop={1}>
        <ListView
          data={filteredData}
          columns={serviceListColumns}
          onClick={onClick}
        />
      </Box>
    </Box>
  )
}

import React, { useState } from 'react'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { useParams } from 'react-router-dom'
import {
  Grid,
  ButtonBase,
  Card,
  CardContent,
  Typography,
  Button,
  Chip,
  styled,
} from '@mui/material'

import { useLocationQuery } from 'hooks/useLocationQuery'
import { RouteTypes } from 'types/RouteTypes'
import { DocumentRequired } from './ParticipantDocumentsTypes'
import { DocumentsRequiredForm } from './DocumentsRequiredForm'

const ButtonBaseStyled = styled(ButtonBase)<any>(() => ({
  width: '100%',
}))

const CardStyled = styled(Card, {
  shouldForwardProp: prop => prop !== 'mandatory',
})<any>(({ theme, mandatory }) => ({
  width: '100%',
  height: 106,
  ...(mandatory && {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  }),
}))

const GridStyled = styled(Grid)(() => ({
  overflow: 'hidden',
  maxHeight: 95,
}))

const ButtonStyled = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  bottom: theme.spacing(1),
  backgroundColor: `${theme.palette.background.paper}`,
  color: `${theme.palette.text.secondary}`,
  '&:hover': {
    backgroundColor: `${theme.palette.background.paper}`,
    color: `${theme.palette.text.primary}`,
  },
}))

type DocumentRequiredCardProps = {
  documentRequiredType: any
  files?: Array<any>
  initialFiles?: Array<any>
}

export const DocumentRequiredCard = ({
  documentRequiredType,
}: DocumentRequiredCardProps) => {
  const { participantID } = useParams<RouteTypes>()
  const [documentRequiredForm, setDocumentRequiredForm] = useState({
    documentRequiredID: '',
    open: false,
  })

  const locationQuery = useLocationQuery()
  useFirestoreConnect([
    {
      collectionGroup: 'documentsRequired',
      where: [
        ['personIDDore', '==', participantID],
        ['documentTypeIDDore', '==', `${documentRequiredType.idDoty}`],
        ...locationQuery('locationIDDore'),
      ],
      limit: 50,
      storeAs: `persons_${participantID}_documentsRequired_${documentRequiredType.idDoty}`,
    },
  ])
  const documentRequired: DocumentRequired = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[
        `persons_${participantID}_documentsRequired_${documentRequiredType.idDoty}`
      ]?.[0],
  )

  return (
    <>
      {documentRequiredForm.open && (
        <DocumentsRequiredForm
          title={`${documentRequiredType.descriptionDoty}`}
          onClose={() =>
            setDocumentRequiredForm({ documentRequiredID: '', open: false })
          }
          documentType={documentRequiredType}
        />
      )}

      <ButtonBaseStyled
        component='div'
        onClick={() =>
          setDocumentRequiredForm({
            documentRequiredID: documentRequired?.id,
            open: true,
          })
        }
      >
        <CardStyled
          mandatory={
            documentRequiredType.mandatoryDoty &&
            (documentRequired?.filesDore?.length === undefined ||
              documentRequired?.filesDore?.length === 0)
          }
        >
          <CardContent>
            <Grid container spacing={1}>
              <Typography variant='body1' gutterBottom>
                {documentRequiredType.descriptionDoty}
              </Typography>
            </Grid>
            <GridStyled container spacing={1}>
              {documentRequiredType.mandatoryDoty &&
                (documentRequired?.filesDore?.length === undefined ||
                  documentRequired?.filesDore?.length === 0) && (
                  <Typography variant='body2'>
                    Dit is een verplicht document
                  </Typography>
                )}
              {documentRequired?.filesDore?.length > 0 &&
                documentRequired?.filesDore?.map((tag, index) => {
                  return (
                    <Grid key={index} item>
                      <Chip
                        size='small'
                        label={tag.customMetadata.originalName}
                      />
                    </Grid>
                  )
                })}
            </GridStyled>
            <ButtonStyled color='primary' size='small' disableElevation>
              Openen
            </ButtonStyled>
          </CardContent>
        </CardStyled>
      </ButtonBaseStyled>
    </>
  )
}

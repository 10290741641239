import React from 'react'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Box, Typography } from '@mui/material'
// import { Searchbar } from 'app/components/Searchbar'
import { ListView } from '@fivano/core'
import { useSelector } from 'hooks/useSelector'

type MusicTrackListProps = {
  productFilter?: Function
  onClick: any
}

const listColumns = [
  {
    Header: ' ',
    canGroupBy: false,
    canSort: false,
    columns: [
      {
        Header: 'Naam',
        accessor: 'nameTrack',
        gridProps: {
          xs: 12,
        },
      },
    ],
  },
]

export const MusicTrackList = ({ onClick }: MusicTrackListProps) => {
  // const [searchValue, setSearchValue] = useState('')

  // const handleSearchValue = data => {
  //   setSearchValue(data.searchField)
  // }
  // const handleSearchClear = () => {
  //   setSearchValue('')
  // }

  useFirestoreConnect([
    {
      collection: 'musicTracks',
      storeAs: 'musicTracks',
      orderBy: ['nameTrack', 'asc'],
    },
  ])

  const listData = useSelector(state => state.firestore.ordered.musicTracks)

  return (
    <>
      <Box p={2} pl={3}>
        <Typography>Aantal tracks in lijst: {listData?.length}</Typography>
        {/* <Searchbar
          placeholder='Zoeken in tracks'
          value={searchValue}
          elevation={0}
          onClear={handleSearchClear}
          onSubmit={handleSearchValue}
        /> */}
      </Box>
      <ListView data={listData || []} columns={listColumns} onClick={onClick} />
    </>
  )
}

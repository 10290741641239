import { TextField, FormRow, Switch } from '@fivano/core'
import { Grid, ButtonBase, Typography } from '@mui/material'
import { useFieldArray } from 'react-hook-form'
import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'

export const PersonTagsFormFields = ({
  formObject,
  tagProps,
  isEditing,
  showAllFields,
}) => {
  const { control, register, getValues } = formObject
  const { append, fields } = useFieldArray({
    control,
    name: `categoryTagsPeta`,
  })

  const handleAddItem = () => {
    append({})
  }

  return (
    <Grid container>
      <Grid item xs={12} paddingBottom='8px'>
        <TextField
          formObject={formObject}
          name='personTagCategoryPeta'
          readOnly={isEditing}
          label='Tag categorie label'
          rules={{
            required: 'Voer een tag label in',
            validate: {
              noItems: () => {
                if (getValues().categoryTagsPeta === undefined) {
                  return 'Voeg een veld toe'
                }
              },
            },
          }}
        />
        <input
          {...register(`id${tagProps.firestoreCode}`)}
          defaultValue={firestoreIDGenerator()}
          style={{ display: 'none' }}
        />
      </Grid>
      <Grid container spacing={1}>
        {fields.map((field: any, index) => {
          return (
            <Grid item container key={index}>
              <Grid item xs={8}>
                <TextField
                  required
                  name={`categoryTagsPeta[${index}].labelPeta`}
                  formObject={formObject}
                  // Readonly wordt later weer aangepast
                  readOnly={field.idTagPeta ? true : false}
                  rules={{
                    required: 'Dit veld is verplicht',
                    validate: {
                      duplicate: value => {
                        if (
                          getValues()?.categoryTagsPeta.filter(
                            item => item.labelPeta === value,
                          ).length > 1
                        ) {
                          return 'Dit veld is al eens gebruikt'
                        }
                      },
                    },
                  }}
                  variant='outlined'
                  defaultValue={field.labelPeta}
                  placeholder='Tag naam'
                />
                <input
                  {...register(`categoryTagsPeta[${index}].idTagPeta`)}
                  style={{ display: 'none' }}
                  defaultValue={
                    field.idTagPeta ? field.idTagPeta : firestoreIDGenerator()
                  }
                />
              </Grid>
              <Grid item xs={4} padding='0px 16px'>
                <Switch
                  name={`categoryTagsPeta[${index}].archivedTagPeta`}
                  formObject={formObject}
                  label={`Archiveren`}
                  defaultValue={field.archivedTagPeta}
                />
              </Grid>
              {/* Hieronder niet weghalen, gaat nog gebruikt worden*/}
              {/* <Grid item xs={1}>
                <IconButton
                  label='Verwijderen'
                  onClick={() => {
                    remove(index)
                    trigger()
                  }}
                  style={{ maxHeight: 42 }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Grid> */}
            </Grid>
          )
        })}
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <ButtonBase
          onClick={e => {
            e.preventDefault()
            handleAddItem()
          }}
        >
          <Typography>Toevoegen</Typography>
        </ButtonBase>
      </Grid>

      <FormRow showAllFields={showAllFields}>
        <Switch
          name={`archived${tagProps.firestoreCode}`}
          formObject={formObject}
          label={`Tag archiveren`}
        />
      </FormRow>
    </Grid>
  )
}

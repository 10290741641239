import React from 'react'
import { FloatingButton, PageContainer } from '@fivano/core'
import { Paper } from '@mui/material'
import { DataGridNew } from 'app/components/DataGridNew'
import { GridColDef } from '@mui/x-data-grid-pro'
import useMongo from 'hooks/useMongo'
import { Breadcrumbs } from 'app/components/Breadcrumbs'

import { useHistory } from 'react-router-dom'

const personColumnsDesktop: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'Leerling',
    disableColumnMenu: true,
  },
]

const personColumnsMobile: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Leerling',
    disableColumnMenu: true,
  },
]

export const CourseStudentsList = () => {
  const history = useHistory()
  const { useQueryDocs } = useMongo()

  const { docsData } = useQueryDocs({
    collection: 'persons',
    array: true,
  })

  return (
    <PageContainer maxWidth='lg'>
      <Breadcrumbs />
      <Paper>
        <DataGridNew
          name='students'
          rows={docsData || []}
          columns={personColumnsDesktop}
          columnsMobile={personColumnsMobile}
          hideFooterSelectedRowCount={true}
          hideFooterRowCount={true}
          isEditable={true}
          onRowClick={rowData => {
            history.push(`leerlingen/${rowData.row._id}`)
          }}
        />
      </Paper>
      <FloatingButton
        label='Leerling toevoegen'
        onClick={() => {
          history.push('leerlingen/toevoegen')
        }}
      />
    </PageContainer>
  )
}

import React from 'react'
import { SettingsForm } from 'app/legacy/SettingsBlock/SettingsForm'
import { PersonTagsFormFields } from './PersonTagsFormFields'

export const PersonTagsFormSetup = props => {
  const tagProps = props
  return (
    <SettingsForm {...props}>
      {({ formObject, showAllFields, isEditing }) => {
        return (
          <PersonTagsFormFields
            formObject={formObject}
            isEditing={isEditing}
            showAllFields={showAllFields}
            tagProps={tagProps}
          />
        )
      }}
    </SettingsForm>
  )
}

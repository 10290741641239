import React, { useEffect, useState } from 'react'
import { useSelector } from 'hooks/useSelector'
import { format, toDate } from 'date-fns'
import nl from 'date-fns/locale/nl'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  Tooltip,
  capitalize,
  styled,
} from '@mui/material'
import { PersonDetailModal } from 'app/components/DetailModals/PersonDetailModal'
import {
  AccordionBase,
  ActionMenu,
  MenuButton,
  ExpandButton,
  Modal,
} from '@fivano/core'

import { initialRelationPersonFormValues } from '../RelationDetails/RelationPersonFormSettings'
import { TrackCardItem } from './TrackCardItem'

import DescriptionIcon from '@mui/icons-material/Description'
import PlaceIcon from '@mui/icons-material/Place'
import DateRangeIcon from '@mui/icons-material/DateRange'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'

import {
  secondsToCustomTimeString,
  secondsToTimeString,
} from 'utils/secondsToTime'
import { hasClaimLevel } from 'utils/hasClaimLevel'

import { useSettings } from 'hooks/useSettings'
import { useArchiveDoc } from 'hooks/useArchiveDoc'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { isNullish } from 'utils/isNullish'
import { Person } from 'types'
import { TrackType } from 'types/Track'

const CardStyled = styled(Card)(() => ({
  position: 'relative',
  width: '100%',
}))

const IconGridStyled = styled(Grid)(({ theme }) => ({
  '&: first-of-type': {
    color: theme.palette.text.secondary,
  },
}))

const GridStyled = styled(Grid)(() => ({
  position: 'absolute',
  right: 5,
  top: 5,
}))

type TrackCardProps = {
  trackData: any
  locationID?: string
  participantID?: string
  onEditTrack?: any
}
const convertDate = date => {
  let newDate
  if (date instanceof Date) {
    newDate = date
  } else if (date !== null && date?.seconds !== undefined) {
    newDate = new Date(toDate(date?.seconds * 1000))
  } else if (date === null) {
    newDate = null
  } else {
    newDate = null
  }
  return newDate
}
export function TrackCard({
  trackData,
  locationID,
  participantID,
  onEditTrack,
}: TrackCardProps) {
  const firestore = useFirestore()
  const firebase = useFirebase()
  const errorLogger = useErrorLogger()

  const {
    moduleTrack,
    moduleCodeTrack,
    trackTypeTrack,
    locationTrack,
    startDateTrack,
    endDateTrack,
    submissionDateTrack,
    approvedDateTrack,
    rejectedDateTrack,
    employerTrack,
    employerPersonTrack,
    payerTrack,
    payerPersonTrack,
    referrerTrack,
    referrerPersonTrack,
    filesTrack,
    textTrack,
    jobcoachTrack,
    workdayDurationsTrack,
    hoursPerWeekSubsidizedTrack,
    costTrack,
    hourlyRateSubsidizedTrack,
  }: TrackType = trackData

  const {
    tracks: { trackAssign: trackFormSettings, labels },
  } = useSettings()

  const [status, setStatus] = useState('Onbekend')
  const startDate = convertDate(startDateTrack)
  const endDate = convertDate(endDateTrack)
  const submissionDate = convertDate(submissionDateTrack)
  const approvedDate = convertDate(approvedDateTrack)
  const rejectedDate = convertDate(rejectedDateTrack)

  const profile = useSelector(state => state.firebase.profile)
  useEffect(() => {
    if (startDate === null && endDate === null) {
      setStatus(`Geen ${labels.track} datums`)
    } else if (startDate === null && endDate !== null) {
      setStatus(`${capitalize(labels.track)} status onbekend`)
    } else if (
      startDate > new Date() ||
      (endDate === null && startDate > new Date())
    ) {
      setStatus(`${capitalize(labels.track)} aankomend`)
    } else if (
      (startDate < new Date() && endDate > new Date()) ||
      (endDate === null && startDate < new Date())
    ) {
      setStatus(`${capitalize(labels.track)} actief`)
    } else if (endDate < new Date() && endDate !== null) {
      setStatus(`${capitalize(labels.track)} afgerond`)
    } else {
      setStatus(`${capitalize(labels.track)} status onbekend`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])
  useEffect(() => {
    if (
      (startDate < new Date() && endDate > new Date()) ||
      (endDate === null && startDate < new Date())
    ) {
      setExpanded(true)
    } else if (endDate === null && startDate > new Date()) {
      setExpanded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [personDetailValues, setPersonDetailValues] = useState<Person>(
    initialRelationPersonFormValues,
  )
  const [personDetailModal, setPersonDetailModal] = useState(false)
  const openPersonDetailModal = personID => {
    firestore
      .get({ collection: 'locations/publicLocation/persons', doc: personID })
      .then((response: any) => {
        const personData: Person = response.data()
        setPersonDetailValues(personData)
        setPersonDetailModal(true)
      })
      .catch(error => {
        errorLogger({ error })
      })
  }
  const handleClosePerson = () => {
    setPersonDetailValues(initialRelationPersonFormValues)
    setPersonDetailModal(false)
  }

  const handleClickDocument = (event, fullPath) => {
    event.preventDefault()
    firebase
      .storage()
      .ref(fullPath)
      .getDownloadURL()
      .then(response => {
        window.open(response, '_blank')
      })
      .catch(error => {
        errorLogger({ error })
      })
  }

  const [workDayDurations, setWorkDayDurations] = useState([])
  useEffect(() => {
    if (workdayDurationsTrack !== undefined) {
      let totalDuration = 0
      workdayDurationsTrack.forEach(duration => {
        if (typeof duration === 'string') {
          totalDuration += parseInt(duration)
        } else {
          totalDuration += duration
        }
      })
      if (totalDuration > 0) {
        const daysOfWeek: any = [
          { label: 'Maa' },
          { label: 'Din' },
          { label: 'Woe' },
          { label: 'Don' },
          { label: 'Vri' },
          { label: 'Zat' },
          { label: 'Zon' },
        ]
        workdayDurationsTrack.forEach((item, index) => {
          daysOfWeek[index].value = secondsToTimeString(item)
        })
        daysOfWeek[7] = {
          label: 'Totaal',
          value: secondsToCustomTimeString([totalDuration])[0],
        }
        setWorkDayDurations(daysOfWeek)
      }
    }
  }, [workdayDurationsTrack])

  const archiveDoc: any = useArchiveDoc()
  const handleArchive = () => {
    archiveDoc({
      docType: 'Track',
      path: `locations/${locationID}/persons/${participantID}/tracks/${trackData.id}`,
      archived: !trackData.archivedTrack,
      extension: 'Track',
    })
  }
  const [expanded, setExpanded] = useState(
    startDate > new Date() ||
      (startDate < new Date() && endDate > new Date()) ||
      endDate === null,
  )
  return (
    <>
      <Modal maxWidth='sm' onClose={handleClosePerson} open={personDetailModal}>
        <PersonDetailModal
          personData={personDetailValues}
          onClose={handleClosePerson}
          closeButton
        />
      </Modal>
      <CardStyled>
        <CardContent>
          <AccordionBase
            expanded={expanded}
            visibleContent={
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container spacing={1} width='85%' alignItems='center'>
                    <Grid item>
                      <ExpandButton
                        label={expanded ? 'Toon minder' : 'Toon meer'}
                        rotate={expanded}
                        onClick={() => setExpanded(!expanded)}
                        size='small'
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        display='inline'
                        variant='body1'
                        color='primary'
                      >
                        {moduleTrack?.label}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Chip
                        color='primary'
                        size='small'
                        variant='outlined'
                        label={moduleCodeTrack?.label}
                      />
                    </Grid>
                    {trackTypeTrack?.label && (
                      <Grid item>
                        <Chip size='small' label={trackTypeTrack?.label} />
                      </Grid>
                    )}
                    {trackData?.archivedTrack && (
                      <Grid item>
                        <Chip size='small' label='Gearchiveerd' />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems='center' spacing={1}>
                    <IconGridStyled item>
                      <DateRangeIcon />
                    </IconGridStyled>
                    <Grid item>
                      <Typography variant='body2' gutterBottom display='inline'>
                        {`Vanaf ${
                          startDate instanceof Date
                            ? format(startDate, 'dd MMMM yyyy', {
                                locale: nl,
                              })
                            : 'Startdatum leeg'
                        }
                    t/m ${
                      endDate instanceof Date
                        ? format(endDate, 'dd MMMM yyyy', { locale: nl })
                        : 'einddatum leeg'
                    }`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Chip size='small' label={status} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
            expandableContent={
              <Grid container spacing={1}>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <Grid container alignItems='center' spacing={1}>
                    <IconGridStyled item>
                      <PlaceIcon />
                    </IconGridStyled>
                    <Grid item>
                      <Typography variant='body2' gutterBottom>
                        Locatie: {locationTrack?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems='center' spacing={1}>
                    {approvedDate && approvedDate instanceof Date ? (
                      <>
                        <IconGridStyled item>
                          <EventAvailableIcon />
                        </IconGridStyled>
                        <Grid item>
                          <Typography
                            variant='body2'
                            gutterBottom
                            display='inline'
                          >
                            {`Aanvraag goedgekeurd ${format(
                              approvedDate,
                              'dd-MM-yyyy',
                              {
                                locale: nl,
                              },
                            )}`}
                          </Typography>
                        </Grid>
                      </>
                    ) : rejectedDate && rejectedDate instanceof Date ? (
                      <>
                        <IconGridStyled item>
                          <EventBusyIcon />
                        </IconGridStyled>
                        <Grid item>
                          <Typography
                            variant='body2'
                            gutterBottom
                            display='inline'
                          >
                            {`Aanvraag afgekeurd op ${format(
                              rejectedDate,
                              'dd-MM-yyyy',
                              {
                                locale: nl,
                              },
                            )}`}
                          </Typography>
                        </Grid>
                      </>
                    ) : submissionDate && submissionDate instanceof Date ? (
                      <>
                        <IconGridStyled item>
                          <EventBusyIcon />
                        </IconGridStyled>
                        <Grid item>
                          <Typography
                            variant='body2'
                            gutterBottom
                            display='inline'
                          >
                            {`Aanvraag ingedient op ${format(
                              submissionDate,
                              'dd-MM-yyyy',
                              {
                                locale: nl,
                              },
                            )}`}
                          </Typography>
                        </Grid>
                      </>
                    ) : !trackFormSettings.submissionDateTrack.hide ? (
                      <Grid item>
                        <Typography
                          variant='body2'
                          gutterBottom
                          color='textSecondary'
                        >
                          Geen subsidies aangevraagd
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {employerTrack?.label && (
                      <Grid item xs={12}>
                        <TrackCardItem
                          title='Werkgever'
                          relation={employerTrack}
                          relationPerson={employerPersonTrack}
                          onClickPerson={openPersonDetailModal}
                        />
                      </Grid>
                    )}
                    {payerTrack?.label && (
                      <Grid item xs={12}>
                        <TrackCardItem
                          title='Betaler'
                          relation={payerTrack}
                          relationPerson={payerPersonTrack}
                          onClickPerson={openPersonDetailModal}
                        />
                      </Grid>
                    )}
                    {referrerTrack?.label && (
                      <Grid item xs={12}>
                        <TrackCardItem
                          title='Verwijzer'
                          relation={referrerTrack}
                          relationPerson={referrerPersonTrack}
                          onClickPerson={openPersonDetailModal}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {jobcoachTrack?.label && (
                  <Grid item xs={12}>
                    <Typography variant='caption' color='textSecondary'>
                      {capitalize(labels.jobcoach)}
                    </Typography>
                    <Typography variant='body1'>
                      {jobcoachTrack.label}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {!isNullish(costTrack) && (
                      <Grid item>
                        <Typography variant='caption' color='textSecondary'>
                          Totaal bedrag
                        </Typography>
                        <Typography>€ {costTrack}</Typography>
                      </Grid>
                    )}
                    {!isNullish(hoursPerWeekSubsidizedTrack) && (
                      <Grid item>
                        <Typography variant='caption' color='textSecondary'>
                          UWV uren
                        </Typography>
                        <Typography>{hoursPerWeekSubsidizedTrack}</Typography>
                      </Grid>
                    )}
                    {!isNullish(hourlyRateSubsidizedTrack) && (
                      <Grid item>
                        <Typography variant='caption' color='textSecondary'>
                          UWV Tarief
                        </Typography>
                        <Typography>
                          &euro; {hourlyRateSubsidizedTrack}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {workDayDurations && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {workDayDurations.map((item: any, index) => {
                        return (
                          <Grid item key={index}>
                            <Typography variant='caption' color='textSecondary'>
                              {item.label}
                            </Typography>
                            <Typography>{item.value}</Typography>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                )}

                {textTrack && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant='caption' color='textSecondary'>
                          Opmerkingen
                        </Typography>
                        <Typography variant='body1' color='textPrimary'>
                          {textTrack}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container wrap='nowrap' spacing={1}>
                    {filesTrack?.length > 0 &&
                      filesTrack.map((file, index) => {
                        return (
                          <Grid item key={index}>
                            <Tooltip title='Download'>
                              <Chip
                                {...(file.fullPath !== undefined && {
                                  onClick: event => {
                                    event.preventDefault()
                                    handleClickDocument(event, file.fullPath)
                                  },
                                })}
                                variant='outlined'
                                icon={<DescriptionIcon />}
                                size='small'
                                label={file.customMetadata.originalName}
                              />
                            </Tooltip>
                          </Grid>
                        )
                      })}
                  </Grid>
                </Grid>
              </Grid>
            }
          />
          <GridStyled item>
            {hasClaimLevel(profile, { t: 3 }) && (
              <ActionMenu iconPosition='vertical' minWidth={250}>
                <MenuButton
                  label={`Bewerk ${labels.track}`}
                  icon={<EditIcon />}
                  onClick={() => onEditTrack(trackData)}
                />
                {locationID && participantID && (
                  <MenuButton
                    label={
                      trackData?.archivedTrack
                        ? 'Track dearchiveren'
                        : 'Track archiveren'
                    }
                    icon={<ArchiveIcon />}
                    onClick={handleArchive}
                  />
                )}
              </ActionMenu>
            )}
          </GridStyled>
        </CardContent>
      </CardStyled>
    </>
  )
}

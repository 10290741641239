import React, { useState, useEffect } from 'react'
import { styled, useMediaQuery } from '@mui/material'
import { LicenseInfo, DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { useSnackbar } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { DataGridProps, GridSettingsProps } from './types'
import { GridToolbar } from './GridToolbar'

LicenseInfo.setLicenseKey(
  '612e007bf5cf7234e39cfaa012d6ffe3T1JERVI6MzAwNTYsRVhQSVJZPTE2NjQ0NDkzMjEwMDAsS0VZVkVSU0lPTj0x',
)

const DataGridWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'editGrid',
})<any>(({ editGrid }) => ({
  height: 600,

  '& .dataGrid': {
    border: 0,
  },

  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: '0px 6px 0px 0px !important',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: !editGrid ? 'hidden' : 'visible',
  },
  '& .noHeader': {
    visibility: 'hidden',
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
}))

export const DataGrid = ({
  gridName,
  rows,
  columns,
  columnsMobile,
  rowActions,
  editGridLayout,
  hideFooterRowCount,
  hideActionsMobile = false,
  onRowClick,
  onSubmitSuccess,
  ...props
}: DataGridProps) => {
  const firestore = useFirestore()
  const userID = useSelector(state => state.firebase.profile.uid)
  const [gridSettings, setGridSettings] = useState<
    GridSettingsProps | undefined
  >(undefined)
  const { enqueueSnackbar } = useSnackbar()
  const errorLogger = useErrorLogger()
  const [isLoading, setIsLoading] = useState('')

  const isEditing = !!gridSettings?.id
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md'),
  )

  const actions: GridColDef = {
    field: 'actions',
    headerName: 'Acties',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    resizable: false,
    disableColumnMenu: true,
    filterable: false,
    headerClassName: 'noHeader',
    width: 50,
    ...(rowActions && rowActions),
  }

  /** Only get settings when viewport is changing from mobile to desktop or vice versa */
  useEffect(() => {
    getGridSettings(columns, columnsMobile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallScreen])

  const addListActions = columns => {
    if (rowActions) {
      if (columns.desktop.length)
        columns.desktop = [...columns.desktop, actions]
      if (columns.mobile.length && !hideActionsMobile)
        columns.mobile = [...columns.mobile, actions]
    }

    return columns
  }

  const getGridSettings = async (columns, columnsMobile) => {
    setIsLoading('Data ophalen')
    await firestore
      .get({
        collection: 'gridSettings',
        where: [
          ['gridName', '==', gridName],
          ['userID', '==', userID],
        ],
      })
      .then(async (response: any) => {
        const docData = response.docs[0]?.data()
        if (docData) {
          docData.id = response.docs[0]?.id
          // Check if there are stored desktop settings if so combine with functions of default columns, if not set default columns as value
          if (docData?.desktop.length) {
            docData.desktop = await mergeColumns(docData?.desktop, columns)
          } else {
            docData.desktop = columns
          }
          // Check if there are stored mobile settings if so combine with functions of default columns, if not set default columns as value
          if (docData?.mobile.length) {
            docData.mobile = await mergeColumns(docData?.mobile, columnsMobile)
          } else {
            docData.mobile = columnsMobile
          }
          setGridSettings(addListActions(docData))
        } else {
          const gridSettings = {
            userID: userID,
            gridName: gridName,
            desktop: !isSmallScreen ? columns : [],
            mobile: isSmallScreen ? columnsMobile : [],
          }
          setGridSettings(addListActions(gridSettings))
        }
      })
      .catch(error => {
        errorLogger({ error })
      })
    setIsLoading('')
  }

  const submitGridSettings = async state => {
    setIsLoading('Data opslaan')
    const data: GridColDef[] = await formatGridSettings(state)

    const newData: GridSettingsProps = {
      userID: userID,
      gridName: gridName,
      desktop: !isSmallScreen ? data : [],
      mobile: isSmallScreen ? data : [],
    }

    if (!isEditing) {
      await firestore
        .collection('gridSettings')
        .add(newData)
        .then(async () => {
          await getGridSettings(columns, columnsMobile)
          enqueueSnackbar('Indeling opgeslagen', { variant: 'success' })
        })
        .catch(error => {
          errorLogger({ error })
        })
    } else {
      // If setttings for mobile or desktop already exists, add them to the new updated data
      if (isSmallScreen && gridSettings?.desktop.length) {
        newData.desktop = await formatGridSettings(gridSettings?.desktop)
      }
      if (!isSmallScreen && gridSettings?.mobile.length) {
        newData.mobile = await formatGridSettings(gridSettings?.mobile)
      }

      await firestore
        .collection('gridSettings')
        .doc(gridSettings?.id)
        .update(newData)
        .then(() => {
          enqueueSnackbar('Indeling opgeslagen', { variant: 'success' })
        })
        .catch(error => {
          errorLogger({ error })
        })
    }
    setIsLoading('')
  }

  const mobileColumns = gridSettings?.mobile.length ? gridSettings?.mobile : []
  const desktopColumns = gridSettings?.desktop.length
    ? gridSettings?.desktop
    : []

  return (
    <DataGridWrapper editGrid={editGridLayout}>
      <DataGridPro
        rows={rows || []}
        columns={
          gridSettings ? (isSmallScreen ? mobileColumns : desktopColumns) : []
        }
        loading={!!isLoading}
        className='dataGrid'
        disableColumnReorder={!editGridLayout}
        hideFooterRowCount={hideFooterRowCount}
        onRowClick={onRowClick}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            isLoading,
            editGridLayout,
            submitGridSettings: data =>
              submitGridSettings(data).then(onSubmitSuccess && onSubmitSuccess),
          },
        }}
        {...props}
      />
    </DataGridWrapper>
  )
}

const mergeColumns = async (columns, defaultColumns) =>
  await columns?.map(column => {
    const findData = defaultColumns?.find(x => x.field === column.field)
    return { ...findData, ...column }
  })

/** Format Datagrid columns before submitting, submit without the column state functions */
const formatGridSettings = async (columns: GridColDef[]) => {
  const newArray: GridColDef[] = []
  columns.forEach(item => {
    if (item.field !== 'actions') {
      newArray.push({
        field: item.field,
        ...(item.align && { align: item.align }),
        ...(item.disableColumnMenu && {
          disableColumnMenu: item.disableColumnMenu,
        }),
        ...(item.filterable && { filterable: item.filterable }),
        ...(item.headerName && { headerName: item.headerName }),
        ...(item.hide && { hide: item.hide }),
        ...(item.minWidth && { minWidth: item.minWidth }),
        ...(item.resizable && { resizable: item.resizable }),
        ...(item.sortable && { sortable: item.sortable }),
        ...(item.type && { type: item.type }),
        ...(item.width && { width: item.width }),
      })
    }
  })
  return newArray
}

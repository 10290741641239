import React, { useState } from 'react'
import { Form, TextField, ListView } from '@fivano/core'
import { Box, Button, Typography } from '@mui/material'
import useMongo from 'hooks/useMongo'
import { workspacesConfig } from '@fivano/models'
import { Breadcrumbs } from 'app/components/Breadcrumbs'

const WorkspaceForm = ({ workspaceID, onCloseForm }) => {
  const { updateDoc, useRefetchDoc, createDoc } = useMongo()

  const createWorkspace = async data =>
    await createDoc({ collection: 'workspaces', data })

  const updateWorkspace = async data =>
    updateDoc({ collection: 'workspaces', docID: workspaceID, data })

  const { status, docData } = useRefetchDoc({
    collection: 'workspaces',
    docID: workspaceID,
    disabled: !workspaceID,
  })

  if (status === 'loading') {
    return <p>Loading...</p>
  }

  return (
    <Form
      modelConfig={workspacesConfig}
      onCloseForm={onCloseForm}
      docData={docData}
      hasDetails={false}
      editingDocID={workspaceID}
      updateDoc={updateWorkspace}
      createDoc={createWorkspace}
      wrapInside='modal'
      docLabel='Mongo Workspace'
      onError={error => console.log(error)}
      formInputs={({ formObject }) => (
        <>
          <TextField formObject={formObject} name='name' label='Naam' />
        </>
      )}
    />
  )
}

const workspaceListColumns = [
  {
    Header: ' ',
    canGroupBy: false,
    canSort: false,
    columns: [
      {
        Header: 'Naam',
        accessor: 'name',
        gridProps: {
          xs: 3,
        },
        Cell: data => (
          <Typography variant='body1'>{data.row.original.name}</Typography>
        ),
      },
    ],
  },
]

export const Workspaces = () => {
  const [workspacesModal, setWorkspacesModal] = useState<any>()

  const { useQueryDocs } = useMongo()

  const { status, docsData } = useQueryDocs({
    collection: 'workspaces',
    query: {},
  })

  if (status === 'loading') return <p>Loading...</p>

  const docs = docsData ? Object.values(docsData) : []

  return (
    <Box py={2} px={3} maxWidth={600}>
      <Breadcrumbs />
      {workspacesModal?.open && (
        <WorkspaceForm
          onCloseForm={() => {
            setWorkspacesModal({ open: false })
          }}
          workspaceID={workspacesModal.workspaceID}
        />
      )}
      <Typography gutterBottom variant='h6'>
        Workspaces
      </Typography>

      <Button
        variant='contained'
        color='primary'
        onClick={() =>
          setWorkspacesModal({
            workspaceID: undefined,
            open: true,
          })
        }
      >
        Workspace toevoegen
      </Button>
      <Box marginTop={1}>
        <ListView
          data={docs}
          columns={workspaceListColumns}
          onClick={(_, data) =>
            setWorkspacesModal({ open: true, workspaceID: data.original._id })
          }
        />
      </Box>
    </Box>
  )
}

import React, { useState } from 'react'
import {
  DatePicker as MaterialDatePicker,
  DatePickerProps as MaterialDatePickerProps,
} from '@mui/lab'
import { useController, RegisterOptions, UseFormReturn } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { BaseTextFieldProps, TextField, TextFieldProps } from '@mui/material'
import { convertTimestampDate } from '../../utils/convertTimestampDate'
import { format } from 'date-fns'

interface DatePickerProps
  extends Omit<
    MaterialDatePickerProps,
    'onChange' | 'size' | 'renderInput' | 'value'
  > {
  /** Name of the TextField*/
  name: string
  /** Label of the DatePicker */
  label?: string
  /** Possible layout variants of the TexField*/
  variant?: BaseTextFieldProps['variant']
  /** Boolean if DatePicker should be disabled */
  disabled?: boolean
  /** Size of TextField input used for the DatePicker  */
  size?: 'small' | 'medium' | undefined
  /** Placeholder string inside DatePicker input */
  placeholder?: string
  /** Date format that shows in datepicker and that is used for the input, default is dd/MM/yyyy */
  dateFormat?: string
  /** Minimal date that has to be picked and that shows in the UI, default is 01-01-1900 (dd-MM-yyyy) */
  minDate?: Date
  /** Maximal date that has to be picked and that shows in the UI, default is 01-01-2099 (dd-MM-yyyy) */
  maxDate?: Date
  /** Additional onChange to get values from the DatePicker in custom implementations.
   * (value, date) => void
   * value returns a Javascript date like: Tue Jan 01 0002 00:00:00 GMT+0019 (zone)
   * date returns a string like '31-12-2020' */
  onChange?: (value: string | undefined, date: Date | null) => void
  /** Rules the TextField has to abide to*/
  rules?: RegisterOptions
  /** Object with all the form methods from react-hook-form.*/
  formObject: UseFormReturn<any>
  /** fullWidth makes the input TextField fullWidth, default is true  */
  fullWidth?: boolean
  /** Material-ui TextField props spreaded into the TextField of DatePicker */
  textFieldProps?: TextFieldProps
  /** DatePicker labels translations */
  translations?: Partial<DatePickerTranslations>
  /** DatePicker error labels translations, minDate and maxDate extend the string with the current date value */
  errorTranslations?: Partial<ErrorTranslations>
}

export function DatePicker({
  name,
  label,
  formObject,
  disabled,
  variant = 'outlined',
  size = 'small',
  placeholder = 'dd/mm/jjjj',
  dateFormat = 'dd/MM/yyyy',
  openTo = undefined,
  minDate,
  maxDate,
  fullWidth = true,
  onChange,
  rules,
  textFieldProps,
  translations: translationsProp,
  errorTranslations: errorTranslationsProp,
  ...props
}: DatePickerProps) {
  const [dateError, setDateError] = useState<string | null>(null)
  const errors = formObject.formState.errors

  const translations: DatePickerTranslations = {
    ...defaultTranslations,
    ...translationsProp, // overwite default
  }
  const errorTranslations: ErrorTranslations = {
    ...defaultErrorTranslation,
    ...errorTranslationsProp, // overwite default
  }
  const { invalidError, minDateError, maxDateError } = errorTranslations

  const {
    field: { value, onChange: onChangeRHF },
  } = useController({
    name,
    control: formObject.control,
    rules: {
      ...rules,
      validate: {
        /** valide using material-ui Date check 11/11/2 is already valid it uses year 2 */
        validDate: () => (dateError === 'invalidDate' ? invalidError : true),
        /** valide the minimum date */
        minDate: () =>
          minDate && dateError === 'minDate'
            ? `${minDateError} ${format(minDate, dateFormat)}`
            : true,
        /** valide the maximum date */
        maxDate: () =>
          maxDate && dateError === 'maxDate'
            ? `${maxDateError} ${format(maxDate, dateFormat)}`
            : true,
        /** other errors from material-ui */
        otherError: () => (dateError !== null ? dateError : true),
      },
    },
  })

  return (
    <MaterialDatePicker
      {...translations}
      {...props}
      /** Below props overwrite spreaded props */
      value={convertTimestampDate(value)}
      onChange={(date, value) => {
        onChangeRHF(date)
        onChange && onChange(value, date)
      }}
      inputFormat={dateFormat}
      minDate={minDate}
      maxDate={maxDate}
      label={label}
      onError={reason => setDateError(reason)}
      renderInput={params => (
        <TextField
          name={name}
          required={!!rules?.required}
          disabled={disabled}
          helperText={<ErrorMessage name={name} errors={errors} />}
          variant={variant}
          size={size}
          fullWidth={fullWidth}
          {...textFieldProps}
          {...params}
          error={params.error || !!errors[name]}
        />
      )}
    />
  )
}
type DatePickerTranslations = {
  cancelText: string
  clearText: string
  okText: string
  todayText: string
  toolbarTitle: string
}
const defaultTranslations: DatePickerTranslations = {
  cancelText: 'Annuleren',
  clearText: 'Verwijderen',
  okText: 'OK',
  todayText: 'Vandaag',
  toolbarTitle: 'Selecteer datum',
}
type ErrorTranslations = {
  invalidError: string
  minDateError: string
  maxDateError: string
}
const defaultErrorTranslation: ErrorTranslations = {
  invalidError: 'Ongeldige datum',
  minDateError: 'Vul een datum in na', // minDate is rendered after this string
  maxDateError: 'Vul een datum in voor', // maxDate is rendered after this string
}

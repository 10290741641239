import React from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CardHeader,
  Divider,
  Card,
} from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import PhoneIcon from '@mui/icons-material/Phone'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import { useFirestoreConnect } from 'react-redux-firebase'
import { PersonAvatar } from './PersonAvatar'
import { personFullName } from 'model/person'
import { useSelector } from 'hooks/useSelector'

export const PersonPreview = ({ locationID, personID }) => {
  useFirestoreConnect([
    {
      collection: 'locations',
      doc: locationID,
      subcollections: [
        {
          collection: 'persons',
          doc: personID,
        },
      ],
      storeAs: `persons_${personID}`,
    },
  ])

  const personData = useSelector(
    ({ firestore: { data } }) => data[`persons_${personID}`],
  )

  if (!personData) {
    return <div>Loading...</div>
  } else {
    return (
      <Card>
        <CardHeader
          avatar={<PersonAvatar locationID={locationID} personID={personID} />}
          title={personFullName(personData)}
        ></CardHeader>
        <Divider />
        <List>
          {personData.emailPers && (
            <ListItem
              button
              component='a'
              href={`mailto:${personData.emailPers}`}
            >
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary={personData.emailPers} />
            </ListItem>
          )}
          {personData.phoneMobilePers && (
            <ListItem
              button
              component='a'
              href={`tel:${personData.phoneMobilePers}`}
            >
              <ListItemIcon>
                <PhoneAndroidIcon />
              </ListItemIcon>
              <ListItemText primary={personData.phoneMobilePers} />
            </ListItem>
          )}
          {personData.phoneLinePers && (
            <ListItem
              button
              component='a'
              href={`tel:${personData.phoneLinePers}`}
            >
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary={personData.phoneLinePers} />
            </ListItem>
          )}
        </List>
      </Card>
    )
  }
}

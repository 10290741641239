import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { format } from 'date-fns'
import {
  ActionMenu,
  IconButton,
  IconText,
  MenuButton,
  useSnackbar,
} from '@fivano/core'
import {
  DialogContent,
  Grid,
  Chip,
  Divider,
  InputAdornment,
  InputBase,
  Popover,
  styled,
  Typography,
} from '@mui/material'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import EditIcon from '@mui/icons-material/Edit'
import BusinessIcon from '@mui/icons-material/Business'
import PersonIcon from '@mui/icons-material/Person'
import TimelineIcon from '@mui/icons-material/Timeline'
import EventIcon from '@mui/icons-material/Event'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { DriveFileMove, InfoOutlined, TrendingUp } from '@mui/icons-material/'
import { ProgressBoardCards } from './ProgressBoardCards'
import { BoardCardForm } from './BoardCardForm'
import { PersonPreview } from 'app/legacy/Persons'
import { RelationPreview } from 'app/legacy/Relations'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { isNullish } from 'utils/isNullish'
import { useSelector } from 'hooks/useSelector'
import { BoardCardMoveForm } from './BoardCardMoveForm'
import { Paragraph } from '@fivano/core'
import { convertToDate } from 'utils/convertToDate'

const InfoPopoverGridStyled = styled(Grid)(() => ({
  height: 48,
  position: 'relative',
}))

const ButtonGridStyled = styled(Grid)(({ theme }) => ({
  marginTop: -4,
  marginLeft: 'auto',
  marginRight: 0,
  color: theme.palette.grey[500],
}))

const HeaderDivStyled = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: 72,
  padding: '16px 24px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .Mui-focused': {
    borderColor: theme.palette.divider,
  },
}))

const ButtonDivStyled = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 12,
  top: 12,
  color: theme.palette.grey[500],
}))

const ProgressDialogContentStyled = styled(DialogContent)(({ theme }) => ({
  width: '66%',
  marginTop: 72,
  minHeight: '51vh',
  padding: '8px 16px 0px 24px',
  borderRight: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down('md')]: {
    minHeight: '200px',
    padding: '8px 8px 0px 8px',
    width: '100%',
    borderRight: 'none',
  },
}))

const DetailDialogContentStyled = styled(DialogContent)(({ theme }) => ({
  position: 'relative',
  width: '34%',
  marginTop: 72,
  minHeight: '51vh',
  padding: '8px 16px',
  [theme.breakpoints.down('md')]: {
    minHeight: '300px',
    width: '100%',
    marginTop: 0,
    paddingLeft: 24,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}))

const DetailsDivStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'nowrap',
  marginBottom: 16,
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const EditIconStyled = styled(EditIcon)(({ theme }) => ({
  color: theme.palette.grey[500],
}))

const IconDivStyled = styled('div')(({ theme }) => ({
  color: theme.palette.grey[500],
}))

const InputBaseStyled = styled(InputBase, {
  shouldForwardProp: prop => prop !== 'width',
})<any>(({ width, theme }) => ({
  minWidth: 320,
  maxWidth: 'calc(100% - 60px)',
  width: `${width * 10 + 100}px`,
  border: `1px solid ${theme.palette.background.paper}`,
  borderRadius: 5,
  marginLeft: -12,
  padding: '0px 12px',
  ...theme.typography.h6,
  '&:hover': {
    borderColor: theme.palette.divider,
  },
}))

const InfoWithPopover = ({
  data: { value, label },
  icon,
  popoverContent,
  textVariant,
}) => {
  const [info, setInfo] = useState(null)
  const openInfo = event => {
    setInfo(event.currentTarget)
  }
  const closeInfo = () => {
    setInfo(null)
  }

  return (
    <InfoPopoverGridStyled
      key={value}
      container
      alignItems='center'
      spacing={1}
      wrap='nowrap'
    >
      <Grid item>{icon}</Grid>
      <Grid item>
        <Typography variant={textVariant} gutterBottom>
          {label}
        </Typography>
      </Grid>
      <ButtonGridStyled item>
        <IconButton label='Meer info' onClick={openInfo} size='large'>
          <InfoOutlined fontSize='small' />
        </IconButton>
      </ButtonGridStyled>

      <Popover
        anchorEl={info}
        open={Boolean(info)}
        onClose={closeInfo}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {popoverContent}
      </Popover>
    </InfoPopoverGridStyled>
  )
}

const RelationInfo = ({ rel }) => (
  <Grid item>
    <InfoWithPopover
      textVariant='h6'
      popoverContent={<RelationPreview relationID={rel.relation.value} />}
      icon={
        <IconDivStyled>
          <BusinessIcon />
        </IconDivStyled>
      }
      data={rel.relation}
    />
    {rel.persons?.map(person => (
      <InfoWithPopover
        key={person.value}
        textVariant='body1'
        popoverContent={
          <PersonPreview locationID='publicLocation' personID={person.value} />
        }
        icon={
          <IconDivStyled>
            <PersonIcon />
          </IconDivStyled>
        }
        data={person}
      />
    ))}

    <Divider />
  </Grid>
)

export const BoardCardView = ({
  moveCard,
  onClose,
  boardCardID,
  boardID,
  phaseID,
}) => {
  const [editFormOpen, setEditFormOpen] = useState(false)
  const [moveFormOpen, setMoveFormOpen] = useState(false)

  const errorLogger = useErrorLogger()
  const { enqueueSnackbar } = useSnackbar()
  const firestore = useFirestore()

  useFirestoreConnect([
    {
      collection: 'boards',
      doc: boardID,
      subcollections: [
        {
          collection: 'boardCards',
          doc: boardCardID,
        },
      ],
      storeAs: `boards_${boardID}_boardCards_${boardCardID}`,
    },
  ])

  const boardCardData = useSelector(({ firestore: { data } }) => {
    if (boardCardID) {
      return data[`boards_${boardID}_boardCards_${boardCardID}`]
    }
    return null
  })

  const [name, setName] = useState(boardCardData?.nameBoca)

  useEffect(() => {
    setName(boardCardData?.nameBoca)
  }, [boardCardData])

  const updateName = newName => {
    if (boardCardData.nameBoca !== newName && newName !== '') {
      firestore
        .update(`boards/${boardID}/boardCards/${boardCardID}`, {
          nameBoca: newName,
        })
        .then(() => {
          enqueueSnackbar(`Titel aangepast.`, {
            variant: 'success',
          })
        })
        .catch(error => {
          errorLogger({ error })
        })
    }
  }

  if (!boardCardData) {
    return <div>Loading...</div>
  } else {
    return (
      <Grid container>
        {editFormOpen && (
          <BoardCardForm
            moveCard={moveCard}
            boardID={boardID}
            phaseID={phaseID}
            editingDocID={boardCardID}
            onCloseForm={() => {
              setEditFormOpen(false)
            }}
          />
        )}
        {moveFormOpen && (
          <BoardCardMoveForm
            moveCard={moveCard}
            boardID={boardID}
            phaseID={phaseID}
            editingDocID={boardCardID}
            onCloseForm={() => {
              setMoveFormOpen(false)
            }}
          />
        )}
        <HeaderDivStyled>
          <InputBaseStyled
            width={name?.length}
            value={name || ''}
            onChange={e => setName(e.target.value)}
            onBlur={e => updateName(e.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <EditIconStyled fontSize='small' />
              </InputAdornment>
            }
          />
          <ButtonDivStyled>
            <IconButton label='Sluiten' onClick={onClose} size='large'>
              <CloseIcon />
            </IconButton>
          </ButtonDivStyled>
        </HeaderDivStyled>
        <ProgressDialogContentStyled>
          <ProgressBoardCards
            archivedBoca={boardCardData.archivedBoca}
            boardID={boardID}
            boardCardID={boardCardID}
          />
        </ProgressDialogContentStyled>
        <DetailDialogContentStyled>
          <DetailsDivStyled>
            <Typography variant='h6'>Item details</Typography>
            <ActionMenu
              iconPosition='vertical'
              minWidth={250}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <MenuButton
                label='Item bewerken'
                icon={<EditIcon />}
                onClick={() => {
                  setEditFormOpen(true)
                }}
              />
              <MenuButton
                label='Item verplaatsen'
                icon={<DriveFileMove />}
                onClick={() => {
                  setMoveFormOpen(true)
                }}
              />
            </ActionMenu>
          </DetailsDivStyled>
          <Grid container spacing={2}>
            {boardCardData.relationsBoca?.map(rel => (
              <Grid item xs={12} key={rel.relation.value}>
                <RelationInfo rel={rel} />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Paragraph variant='body1' lines={5}>
                {boardCardData.descriptionBoca}
              </Paragraph>
            </Grid>
            {!isNullish(boardCardData.expectedAmountBoca) && (
              <Grid item xs={6} md={12}>
                <IconText
                  icon={
                    <IconDivStyled>
                      <AssignmentIcon />
                    </IconDivStyled>
                  }
                  label='Verwacht order bedrag'
                  value={`€ ${boardCardData.expectedAmountBoca},-`}
                />
              </Grid>
            )}
            {!isNullish(boardCardData.chanceBoca) && (
              <Grid item xs={6} md={12}>
                <IconText
                  icon={
                    <IconDivStyled>
                      <TrendingUp />
                    </IconDivStyled>
                  }
                  label='Kans op order'
                  value={`${boardCardData.chanceBoca} %`}
                />
              </Grid>
            )}
            {boardCardData.dateDueBoca && (
              <Grid item xs={6} md={12}>
                <IconText
                  icon={
                    <IconDivStyled>
                      <EventIcon />
                    </IconDivStyled>
                  }
                  label='Fase deadline'
                  value={format(
                    convertToDate(boardCardData.dateDueBoca),
                    'dd-MM-yyyy',
                  )}
                />
              </Grid>
            )}
            {boardCardData.phaseBoca.label && (
              <Grid item xs={6} md={12}>
                <IconText
                  icon={
                    <IconDivStyled>
                      <TimelineIcon />
                    </IconDivStyled>
                  }
                  label='Huidige fase'
                  value={boardCardData.phaseBoca.label}
                />
              </Grid>
            )}
            {boardCardData.archivedBoca && (
              <Grid item xs={12}>
                <Chip label='Gearchiveerd' size='small' />
              </Grid>
            )}
          </Grid>
        </DetailDialogContentStyled>
      </Grid>
    )
  }
}

import React from 'react'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Paper, Box, Typography } from '@mui/material'
// import { Searchbar } from 'app/components/Searchbar'
import { useSelector } from 'hooks/useSelector'
import { Helmet } from 'react-helmet-async'
import { FloatingButton, PageContainer, ListView } from '@fivano/core'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { useHistory } from 'react-router-dom'

const breadcrumbArray = [
  { title: 'Home', path: '/' },
  { title: 'Playlists', path: '/playlists' },
]
const listColumns = [
  {
    Header: ' ',
    canGroupBy: false,
    canSort: false,
    columns: [
      {
        Header: 'Naam',
        accessor: 'namePlaylist',
        gridProps: {
          xs: 12,
        },
      },
    ],
  },
]

export const PlaylistView = () => {
  const history = useHistory()
  // const [searchValue, setSearchValue] = useState('')
  const listData = useSelector(store => store.firestore.ordered.playlists)

  // const handleSearchValue = data => {
  //   setSearchValue(data.searchField)
  // }
  // const handleSearchClear = () => {
  //   setSearchValue('')
  // }
  useFirestoreConnect([
    {
      collection: 'playlists',
      storeAs: 'playlists',
      orderBy: ['namePlaylist', 'asc'],
    },
  ])

  return (
    <PageContainer maxWidth='lg'>
      <Helmet>
        <title>Playlists</title>
      </Helmet>
      <Breadcrumbs breadcrumbs={breadcrumbArray} />
      <Paper>
        <Box p={2} pl={3}>
          <Typography>Aantal playlists: {listData?.length}</Typography>
          {/* <Searchbar
            placeholder='Zoeken in playlists'
            value={searchValue}
            elevation={0}
            onClear={handleSearchClear}
            onSubmit={handleSearchValue}
          /> */}
        </Box>
        <FloatingButton
          label='Playlist toevoegen'
          onClick={() => history.push('/playlists/formulier')}
        />
        <ListView
          data={listData || []}
          columns={listColumns}
          onClick={(_, data) => {
            history.push(`/playlists/${data.original.id}/formulier`)
          }}
        />
      </Paper>
    </PageContainer>
  )
}

import { menuLayoutType } from 'app/router/types'
import PersonIcon from '@mui/icons-material/Person'
import { personRoutes } from './personRoutes'

export const personMenu: menuLayoutType[] = [
  {
    name: 'Personen',
    icon: PersonIcon,
    route: personRoutes.personList,
  },
]

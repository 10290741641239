import React from 'react'
import {
  Grid,
  Typography,
  Box,
  Button,
  Chip,
  capitalize,
  styled,
} from '@mui/material'
import { useSelector } from 'hooks/useSelector'
import { SettingsContainer } from '../../components/SettingsContainer'
import { ListView } from '@fivano/core'

const BoxSettings = styled(Box)(() => ({
  padding: '16px 24px 0 24px',
  maxWidth: 600,
}))

/** Types required for the SettingsPage */
interface Settings {
  /** LabelSingle and is for form labels and screen labels */
  labelSingle: string
  /** LabelSingle and is for form labels and screen labels */
  labelMultiple: string
  /** Array of strings showing navigation */
  breadcrumbs: any
  /** String representing the path to the correct firebase collection document */
  firestoreSettingsPath: string
  /** Function to be triggered when a form needs to be edited */
  onEdit: any
  /** Function that is triggered when a new form is to be created */
  onAdd: any
  /** Data that overwrites the default listColumns for the listView */
  listColumns?: any
  /** String indicating the abbreviation for the document name */
  firestoreCode: string
  /** String that functions as header for the description */
  descriptionTitle?: string
  /** String with text describing the function of this setting */
  description?: string
}

export const SettingsPage = ({
  labelSingle,
  labelMultiple,
  breadcrumbs,
  firestoreSettingsPath,
  onEdit,
  onAdd,
  listColumns,
  firestoreCode,
  descriptionTitle,
  description,
}: Settings) => {
  const listData = useSelector(
    ({ firestore: { data } }) => data[firestoreSettingsPath]?.options,
  )
  const listColumnsData = listColumns || [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          accessor: `description${firestoreCode}`,
          gridProps: {
            xs: 9,
            sm: 9,
            md: 9,
          },
        },
        {
          Header: 'Gearchiveerd',
          accessor: `archived${firestoreCode}`,
          gridProps: {
            xs: 3,
            sm: 3,
            md: 3,
          },
          Cell: data => (
            <Grid item>
              {data.row.original[`archived${firestoreCode}`] && (
                <Chip label='Gearchiveerd' />
              )}
            </Grid>
          ),
        },
      ],
    },
  ]
  return (
    <>
      <SettingsContainer breadcrumbs={breadcrumbs}>
        <Grid container justifyContent='space-between'>
          <BoxSettings>
            <Typography gutterBottom variant='h6'>
              {capitalize(labelMultiple)}
            </Typography>
          </BoxSettings>
          <BoxSettings>
            <Button color='primary' variant='contained' onClick={onAdd}>
              {labelSingle} toevoegen
            </Button>
          </BoxSettings>
        </Grid>
        {descriptionTitle && description ? (
          <BoxSettings>
            <Typography variant='h6' gutterBottom>
              {descriptionTitle}
            </Typography>
            <Typography variant='body2' gutterBottom>
              {description}
            </Typography>
          </BoxSettings>
        ) : null}

        <Grid container style={{ marginBottom: '15px' }}>
          <Grid item xs={12}>
            {listData && listData.length > 0 ? (
              <ListView
                onClick={onEdit}
                data={listData
                  ?.slice()
                  ?.sort(
                    (x, y) =>
                      x[`archived${firestoreCode}`] -
                      y[`archived${firestoreCode}`],
                  )}
                columns={listColumnsData}
              />
            ) : (
              <BoxSettings>
                <Typography>
                  Er zijn nog geen {labelMultiple} toegevoegd
                </Typography>
              </BoxSettings>
            )}
          </Grid>
        </Grid>
      </SettingsContainer>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'hooks/useSelector'
import { useFirestore } from 'react-redux-firebase'
import { format } from 'date-fns'
import { Grid, Paper, Box, Button, Typography, styled } from '@mui/material'
import { useHistory } from 'react-router'
import { Avatar } from 'app/components/Avatar'

import { PageContainer, ListView } from '@fivano/core'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { SickReportingTypes } from './SickReportingTypes'
import { Person } from 'types'
import { convertToDate } from 'utils/convertToDate'

const PaperStyled = styled(Paper)(() => ({
  marginBottom: '80px',
}))

const breadcrumbArray = [
  { title: 'Home', path: '/' },
  { title: 'Ziekmeldingen overzicht', path: '/ziekmeldingen-overzicht' },
]

export const SickReportingOverview = () => {
  const history = useHistory()
  const [sickReportsLimit, setSickReportsLimit] = useState(25)

  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          gridProps: {
            xs: 12,
            sm: 6,
            md: 4,
          },
          Cell: data => {
            return (
              <>
                <Grid container wrap='nowrap' spacing={2} alignItems='center'>
                  <Grid item>
                    <Avatar personData={data.row.original} />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant='body1'
                      display='inline'
                    >{`${data.row.original.firstNamePers} ${data.row.original.middleNamePers} ${data.row.original.lastNamePers} `}</Typography>
                  </Grid>
                </Grid>
              </>
            )
          },
        },
        {
          Header: 'Start',
          gridProps: {
            xs: 6,
            sm: 3,
            md: 2,
          },
          Cell: data => {
            return (
              <Grid item>
                <Typography variant='body1'>
                  {data.row.original.startDateTimeSick &&
                    data.row.original.startDateTimeSick !== '' &&
                    format(
                      convertToDate(data.row.original.startDateTimeSick),
                      'dd-MM-yyyy',
                    )}
                </Typography>
              </Grid>
            )
          },
        },
        {
          Header: 'Eind',
          gridProps: {
            xs: 6,
            sm: 3,
            md: 2,
          },
          Cell: data => {
            return (
              <Grid item>
                <Typography variant='body1'>
                  {data.row.original.endDateTimeSick &&
                  data.row.original.endDateTimeSick !== ''
                    ? format(
                        convertToDate(data.row.original.endDateTimeSick),
                        'dd-MM-yyyy',
                      )
                    : 'Open'}
                </Typography>
              </Grid>
            )
          },
        },
      ],
    },
  ]

  const handleClick = (event, data) => {
    if (data.original.personIDSick)
      history.push(
        `/locatie/${data.original.locationIDSick}/deelnemers/${data.original.personIDSick}`,
      )
  }
  const profile = useSelector(state => state.firebase.profile)
  const firestore = useFirestore()
  const [sickReportingData, setSickReportingData] = useState<
    Array<SickReportingTypes & Person>
  >([])
  const locationQuery = useLocationQuery()
  useEffect(() => {
    firestore
      .get({
        collectionGroup: 'sickReporting',
        orderBy: ['startDateTimeSick', 'asc'],
        where: [
          ['endDateTimeSick', '==', null],
          ...locationQuery('locationIDSick'),
        ],
        limit: sickReportsLimit,
      })
      .then(async response => {
        const sickReportingItems: Array<any> = []
        // create promise function to that resolves after add mergeData to trackItems array
        const getParentSickReporting = doc => {
          return new Promise((resolve, reject) => {
            const parentPath = doc.ref.path.split('/sickReporting')[0]
            if (
              !sickReportingItems.some(
                item => item.personIDPers === doc.data().id,
              )
            ) {
              firestore
                .get(parentPath)
                .then(async response => {
                  sickReportingItems.push({ ...doc.data(), ...response.data() })
                  resolve(response)
                })
                .catch(error => {
                  reject(error)
                })
            }
          })
        }
        // await all resolves of promise function on response.docs array
        await Promise.all(
          // @ts-ignore
          response.docs.map(async doc => {
            await getParentSickReporting(doc)
          }),
        )
        // setAllTracks state after trackItems has mergeData with every track and parent
        // @ts-ignore
        setSickReportingData(sickReportingItems)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sickReportsLimit, profile])
  return (
    <>
      <Helmet>
        <title>Ziekmeldingen overzicht</title>
      </Helmet>
      <PageContainer maxWidth='lg'>
        <Breadcrumbs breadcrumbs={breadcrumbArray} />
        <PaperStyled>
          <Box px={3} py={2}>
            <Typography variant='body1'>
              Overzicht van openstaande ziekmeldingen.
            </Typography>
          </Box>

          {sickReportingData?.length > 0 ? (
            <>
              <ListView
                data={sickReportingData}
                columns={listColumns}
                onClick={handleClick}
              />
              {sickReportingData.length === sickReportsLimit && (
                <Button
                  fullWidth
                  onClick={() => setSickReportsLimit(sickReportsLimit + 10)}
                >
                  Meer ziekmeldingen laden.
                </Button>
              )}
            </>
          ) : (
            <Box p={2}>
              <Typography variant='body1'>
                Geen ziekmeldingen gevonden.
              </Typography>
            </Box>
          )}
        </PaperStyled>
      </PageContainer>
    </>
  )
}

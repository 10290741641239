import { routeObjectTypeLegacy } from './types'

import { HomePage } from 'app/legacy/HomePage'
import { Lunch, LunchOverview } from 'app/legacy/Lunch'
import { JobOpenings } from 'app/legacy/JobOpenings'
import { Participants } from 'app/legacy/Participants'
import { ParticipantDetails } from 'app/legacy/ParticipantDetails/ParticipantDetails'
import { Progress } from 'app/legacy/Progress'
import { UserProfile } from 'app/legacy/UserProfile'
import { ParticipantDocuments } from 'app/legacy/ParticipantDocuments'
import { Relations } from 'app/legacy/Relations'
import { Modules } from 'app/legacy/Modules'
import { LicensesPage, LicenseForm } from 'app/legacy/Licenses'
import { TrackAssignOverview } from 'app/legacy/Tracks/TrackAssignOverview'
import { RelationDetails } from 'app/legacy/RelationDetails'
import { DevelopPage } from 'app/legacy/DevelopPage'
import { Boards, Board } from 'app/legacy/Board'
import { DurationRegistration } from 'app/legacy/DurationRegistration'
import { Colleagues } from 'app/legacy/Colleagues'
import { SickReportingOverview } from 'app/legacy/SickReporting/SickReportingOverview'
import { JobOpeningsOverview } from 'app/legacy/JobOpenings/JobOpeningsOverview'
import { TimeRegistration } from 'app/legacy/TimeRegistration/TimeRegistration'
import ParticipantHome from 'app/legacy/ParticipantHome'
import { EmailInvitation } from 'app/legacy/Authentication/EmailInvitation'
import {
  Authentication,
  EmailRegistration,
  EmailSignIn,
  PasswordForget,
  RegisterVerification,
  SignInMicrosoft,
  UserAccount,
} from 'app/legacy/Authentication'
import { servicesRoutes } from 'app/containers/Services/servicesRoutes'
import { settingRoutes } from 'app/legacy/ApplicationSettings/settingRoutes'
import { songstarterRoutes } from 'app/legacy/MusicTracks/songstarterRoutes'

export const legacyRoutes: routeObjectTypeLegacy = {
  home: {
    path: '/',
    component: HomePage,
    claims: { u: 1 },
    type: 'GUARDED',
  },
  participants: {
    path: '/deelnemers',
    component: Participants,
    claims: { p: 1 },
    type: 'GUARDED',
  },
  participantDetails: {
    path: '/locatie/:locationID/deelnemers/:participantID',
    component: ParticipantDetails,
    claims: { p: 1 },
    type: 'GUARDED',
  },
  participantProgress: {
    path: '/locatie/:locationID/deelnemers/:participantID/voortgang',
    component: Progress,
    claims: { pr: 1 },
    type: 'GUARDED',
  },
  participantDocuments: {
    path: '/locatie/:locationID/deelnemers/:participantID/documenten',
    component: ParticipantDocuments,
    claims: { d: 1 },
    type: 'GUARDED',
  },
  progress: {
    path: '/locatie/:locationID/deelnemers/:participantID/voortgang',
    component: Progress,
    claims: { pr: 1 },
    type: 'GUARDED',
  },
  documents: {
    path: '/locatie/:locationID/deelnemers/:participantID/documenten',
    component: ParticipantDocuments,
    claims: { d: 1 },
    type: 'GUARDED',
  },
  relations: {
    path: '/bedrijven',
    component: Relations,
    claims: { r: 1 },
    type: 'GUARDED',
  },
  relationDetails: {
    path: '/bedrijven/:relationID',
    component: RelationDetails,
    claims: { c: 1 },
    type: 'GUARDED',
  },
  colleagues: {
    path: '/collegas',
    component: Colleagues,
    claims: { u: 1 },
    type: 'GUARDED',
  },
  boards: {
    path: '/crm-borden',
    component: Boards,
    claims: { b: 1 },
    type: 'GUARDED',
  },
  boardOverview: {
    path: '/crm-borden/:boardID',
    component: Board,
    claims: { b: 1 },
    type: 'GUARDED',
  },
  vacancies: {
    path: '/vacatures',
    component: JobOpenings,
    claims: { dev: 5 },
    type: 'GUARDED',
  },
  ...servicesRoutes,
  modules: {
    path: '/trajecten',
    component: Modules,
    claims: { t: 1 },
    type: 'GUARDED',
  },
  trackAssignOverview: {
    path: '/toegewezen-trajecten',
    component: TrackAssignOverview,
    claims: { t: 1 },
    type: 'GUARDED',
  },
  licenses: {
    path: '/licenties',
    component: LicensesPage,
    claims: { li: 5 },
    type: 'GUARDED',
  },
  licenseForm: {
    path: '/licenties/formulier',
    component: LicenseForm,
    claims: { li: 5 },
    type: 'GUARDED',
  },
  licenseFormEdit: {
    path: '/licenties/:licenseID/formulier',
    component: LicenseForm,
    claims: { li: 5 },
    type: 'GUARDED',
  },
  jobOpenings: {
    path: '/vacatures',
    component: JobOpeningsOverview,
    claims: { uj: 5 },
    type: 'GUARDED',
  },
  timeRegistration: {
    path: '/tijdregistratie',
    component: TimeRegistration,
    claims: { h: 1 },
    type: 'GUARDED',
  },
  durationRegistration: {
    path: '/urenregistratie',
    component: DurationRegistration,
    claims: { du: 1 },
    type: 'GUARDED',
  },
  sickReportingOverview: {
    path: '/ziekmeldingen-overzicht',
    component: SickReportingOverview,
    claims: { si: 1 },
    type: 'GUARDED',
  },
  lunchRegistration: {
    path: '/lunch',
    component: Lunch,
    claims: { dev: 5 },
    type: 'GUARDED',
  },
  lunchOverview: {
    path: '/lunch-overzicht',
    component: LunchOverview,
    claims: { dev: 5 },
    type: 'GUARDED',
  },
  userAccount: {
    path: '/mijn-account',
    component: UserProfile,
    claims: { u: 1 },
    type: 'GUARDED',
  },

  development: {
    path: '/development',
    component: DevelopPage,
    claims: { dev: 5 },
    type: 'GUARDED',
  },
  // PARTICIPANT LOGIN FUNCTIONALITY
  participantHome: {
    path: '/',
    component: ParticipantHome,
    claims: { uo: 1 },
    type: 'GUARDED',
  },
  // AUTHENTICATION ROUTES
  login: {
    path: '/inloggen',
    component: EmailSignIn,
    type: 'AUTHENTICATION',
    claims: {},
  },
  loginMicrosoft: {
    path: '/inloggen-met-microsoft',
    component: SignInMicrosoft,
    type: 'AUTHENTICATION',
    claims: {},
  },
  registerEmail: {
    path: '/registreren-met-email',
    component: EmailRegistration,
    type: 'AUTHENTICATION',
    claims: {},
  },
  passwordForget: {
    path: '/wachtwoord-vergeten',
    component: PasswordForget,
    type: 'AUTHENTICATION',
    claims: {},
  },
  confirmEmail: {
    path: '/email-bevestigen',
    component: RegisterVerification,
    type: 'AUTHENTICATION',
    claims: {},
  },
  authentication: {
    path: '/authenticatie',
    component: Authentication,
    type: 'AUTHENTICATION',
    claims: {},
  },
  controlAccount: {
    path: '/account-controle',
    component: UserAccount,
    type: 'AUTHENTICATION',
    claims: {},
  },
  registerInvite: {
    path: '/email-uitnodiging-registreren',
    component: EmailInvitation,
    type: 'AUTHENTICATION',
    claims: {},
  },
  ...songstarterRoutes,
  ...settingRoutes,
}

import React from 'react'
import { Box, Button, Grid, Typography, useTheme } from '@mui/material'
import { defaultSettings } from 'defaultSettings'
import { useFirestore } from 'react-redux-firebase'
import { useDispatch, useSelector } from 'react-redux'
import { selectAppMode, changeAppMode } from './appModeSlice'
import { useHistory } from 'react-router'
import { useApiRequest } from 'hooks/useApiRequest'

export const DevTools = () => {
  const makeRequest = useApiRequest()
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const firestore = useFirestore()
  const handleConfigure = () => {
    defaultSettings.person.locationAssignEnabled = true
    defaultSettings.person.participantAccountEnabled = true
    defaultSettings.jobOpenings.jobOpeningsEnabled = true
    firestore.set('appSettings/settings', defaultSettings)
  }

  /** Change appMode */
  const appMode = useSelector(selectAppMode)
  const newAppMode = appMode === 'legacy' ? 'app' : 'legacy'
  const handleChangeAppMode = () => {
    dispatch(changeAppMode(newAppMode))
    history.push('/')
  }

  const resetPersonsAndRoleProfiles = async () => {
    makeRequest({
      route: 'testUtils/resetCollectionsMongo',
      requestBody: authorizationResetData,
    })
  }
  return (
    <Box p={1}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h6'>Devtools</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button size='small' variant='outlined' onClick={handleConfigure}>
            Set defaults settings
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button size='small' variant='outlined' onClick={handleChangeAppMode}>
            Wissel naar {newAppMode}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            size='small'
            variant='outlined'
            onClick={() => console.info(theme)}
          >
            Console.log MUI theme
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={resetPersonsAndRoleProfiles}>
            Reset persons and roleProfiles
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

const authorizationResetData = {
  data: {
    roleProfiles: [
      {
        _id: 'adminRole',
        name: 'Admin',
        roles: {
          persons: ['createDoc', 'queryDocs', 'updateDoc', 'deleteDoc'],
          workspaces: ['createDoc', 'queryDocs', 'updateDoc', 'deleteDoc'],
          services: ['createDoc', 'queryDocs', 'updateDoc', 'deleteDoc'],
          roleProfiles: ['createDoc', 'queryDocs', 'updateDoc', 'deleteDoc'],
          gridSettings: ['createDoc', 'queryDocs', 'updateDoc', 'deleteDoc'],
        },
      },
    ],
    persons: [
      {
        _id: '6103a70d1ddb116ced39fffa',
        workspaces: [],
        roleProfiles: ['adminRole'],
        firstName: 'Ad',
        uid: '9Swr9lIXyzZRdTpuNXPzMgIX8eYV',
      },
    ],
  },
}

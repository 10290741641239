import { useHistory, useParams } from 'react-router-dom'
import { RouteTypes } from 'types/RouteTypes'
import { PersonForm } from '../Persons/PersonForm'
import { courseTrackingRoutes } from './courseTrackingRoutes'

export const CourseStudentForm = () => {
  const { personID } = useParams<RouteTypes>()
  const history = useHistory()
  return (
    <PersonForm
      personType='student'
      docTitle='Leerling'
      onCloseForm={() => history.push(courseTrackingRoutes.studentsList.path)}
      editingDocID={personID === 'toevoegen' ? false : personID}
    />
  )
}

import { useCookie } from 'react-use'
import { useQueryParams } from 'hooks/useQueryParams'
import { useEffect, useState } from 'react'
import { isEmptyObject } from 'utils/isEmptyObject'
import { OptionType } from 'types/CommonTypes'

export type FilterSettingsObject = {
  value: string | boolean | Array<OptionType>
  cookie?: boolean
  param?: boolean
  label?: string
  initialValue?: string
}
export type FilterSettingsObjects = { [name: string]: FilterSettingsObject }

const getValue = (valueString, valueType) =>
  valueType === 'string'
    ? valueString
    : valueType === 'boolean'
    ? valueString === 'true'
    : valueType === 'array' && JSON.parse(valueString)

export const useFilters = (initialFilterState: FilterSettingsObjects) => {
  const [filterState, setFilterState] = useState(initialFilterState)
  const [firstRender, setFirstRender] = useState(true)
  const [cookies, updateCookie] = useCookie('participantLocations')

  const newFilterState: any = { ...filterState }

  const { setParams, getParams } = useQueryParams()
  const params = getParams()
  const newParams: any = {}

  let newCookies: any = {}
  if (cookies && !isEmptyObject(cookies)) {
    newCookies = JSON.parse(cookies)
  }
  // Loop over every filterSetting object
  Object.entries(filterState).forEach(
    ([name, filter]: [string, FilterSettingsObject]) => {
      // Set initialFilterState value to use for reset
      newFilterState[name].initialValue = initialFilterState[name].value

      // Determine value types based on initialValue type to use for params and cookie type conversion
      const valueType = Array.isArray(filter.value)
        ? 'array'
        : typeof filter.value

      // If cookie is enabled use the cookie as value
      if (filter.cookie) {
        const cookieValue = newCookies?.[name]
        // If firstRender and cookie is defined set filter.value to cookie value
        if (firstRender && cookieValue) {
          newFilterState[name].value = cookieValue
        } else {
          newFilterState[name].value = filter.value
        }
        // newCookies always follows filter.value
        newCookies[name] = filter.value
      } else if (filter.param) {
        const paramValue = getValue(params[name], valueType)
        // If firstRender and paramValue is defined set filter.value to params value
        if (firstRender && paramValue) {
          newFilterState[name].value = paramValue
        } else if (filter.value) {
          newFilterState[name].value = filter.value
        }
        // newParams always follows filter.value if filter value is defined and is not equal to initialValue
        if (filter.value && filter.value !== filter.initialValue) {
          newParams[name] = filter.value
        }
      } else {
        // If cookie and param are disabled use value
        newFilterState[name].value = filter.value
      }
    },
  )
  useEffect(() => {
    setParams(newParams)
    updateCookie(JSON.stringify(newCookies))
    setFirstRender(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState])

  return [newFilterState, setFilterState]
}

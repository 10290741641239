import React from 'react'
import { IconButton } from '../IconButton'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { styled, SvgIconTypeMap } from '@mui/material'

const ButtonStyled = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  right: 0,
  [theme.breakpoints.down('md')]: {
    position: 'relative',
  },
}))

type ActionButtonsProps = {
  /**Button properties that are send as props to the component.*/
  buttons: {
    label: string
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
    onClickFunction: () => void
  }[]
  /**Name for the styling class.*/
  className?: string
  /**Sets inline styling for the element.*/
  style?: React.CSSProperties
}

export const ActionButtons = ({
  buttons,
  className,
  style,
}: ActionButtonsProps) => {
  return (
    <ButtonStyled>
      {buttons.map((button, index) => {
        const Icon = button.icon
        return (
          <div key={`${button.label}_${index}`} className={className}>
            <IconButton
              label={button.label}
              onClick={button.onClickFunction}
              style={style}
              size='large'
            >
              <Icon />
            </IconButton>
          </div>
        )
      })}
    </ButtonStyled>
  )
}

export const keywordsCreator = (inputStrings: Array<string | undefined>) => {
  const keywordStrings: Array<string> = ['']
  let fullSearchString = ''
  // collect all strings to convert to indexable strings array
  inputStrings.forEach(string => {
    if (string !== undefined && string !== '') {
      // add full word keywordsStrings
      keywordStrings.push(string.toLowerCase())
      fullSearchString += string.toLowerCase() + ' '
      // seperate string at each space and add each word to keywordsStrings
      const seperateWords = string.split(' ')
      seperateWords.forEach(word => {
        const trimWord = word.trim()
        // only include string that are not already in keywordsStrings
        if (!keywordStrings.includes(trimWord)) {
          keywordStrings.push(word.toLowerCase())
        }
      })
    }
  })
  keywordStrings.push(fullSearchString)
  const indexArray: Array<string> = ['']
  // create index array of all string
  keywordStrings.forEach(string => {
    if (!indexArray.includes(string)) {
      let currentName = ''
      string.split('').forEach(letter => {
        currentName += letter
        if (!indexArray.includes(currentName)) {
          indexArray.push(currentName)
        }
      })
    }
  })
  return indexArray
}

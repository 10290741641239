import { Form } from '@fivano/core'
import useMongo from 'hooks/useMongo'
import { useHistory, useParams } from 'react-router-dom'
import { RouteTypes } from 'types/RouteTypes'
import { productsConfig } from '@fivano/models'
import { ProductFormInputs } from './ProductFormInputs'
import { useErrorLogger } from 'hooks/useErrorLogger'

export const ProductForm = () => {
  const { productID } = useParams<RouteTypes>()
  const errorLogger = useErrorLogger()
  const { updateDoc, useRefetchDoc, createDoc } = useMongo()

  const history = useHistory()
  const createProduct = async data => {
    return await createDoc({ collection: 'products', data })
  }
  const updateProduct = async data => {
    return await updateDoc({ collection: 'products', docID: productID, data })
  }

  const { status, docData } = useRefetchDoc({
    collection: 'products',
    docID: productID,
    disabled: !productID,
  })

  return (
    <Form
      docLabel='Product'
      docData={docData}
      loading={status === 'loading'}
      wrapInside='page'
      modelConfig={productsConfig}
      createDoc={createProduct}
      updateDoc={updateProduct}
      editingDocID={productID}
      hasDetails={true}
      onCloseForm={() => history.push(`/producten`)}
      onError={errorLogger}
      formInputs={({ formObject, showAllFields }) => {
        return (
          <ProductFormInputs
            formObject={formObject}
            showAllFields={showAllFields}
          />
        )
      }}
    />
  )
}

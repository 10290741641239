import React from 'react'
import { Grid, Skeleton, styled } from '@mui/material'

const SkeletonContainer = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const SkeletonHeader = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: '8px 0px 8px 12px',
}))

const SkeletonItem = styled(Grid)(({ theme }) => ({
  padding: 14,
  borderRight: `1px solid ${theme.palette.divider}`,
}))

export const ProjectRowSkeleton = ({
  selectedWeek,
  showWeekend,
  viewportLG,
}) => {
  return (
    <SkeletonContainer container>
      <SkeletonHeader item xs={12}>
        <Skeleton animation='wave' width='100px' height='24px' />
      </SkeletonHeader>
      <Grid item xs={12}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid
            item
            xs={12}
            lg={showWeekend ? 4 : 6}
            style={{ paddingLeft: 14 }}
          >
            <Skeleton animation='wave' width='50px' height='32px' />
            {!viewportLG && (
              <Skeleton animation='wave' width='100px' height='24px' />
            )}
          </Grid>
          {selectedWeek.map((_, index) => {
            const showDays = showWeekend ? 7 : 5
            return (
              index < showDays && (
                <SkeletonItem
                  key={index}
                  item
                  xs={6}
                  md={3}
                  lg={1}
                  {...(!viewportLG && { style: { border: 0 } })}
                >
                  <Skeleton animation='wave' width='100%' height='33px' />
                </SkeletonItem>
              )
            )
          })}
          <SkeletonItem item xs={12} lg={1} style={{ border: 0 }}>
            <Skeleton animation='wave' width='50px' height='32px' />
            {!viewportLG && (
              <Skeleton animation='wave' width='30px' height='18px' />
            )}
          </SkeletonItem>
        </Grid>
      </Grid>
    </SkeletonContainer>
  )
}

import { useFirebase, useFirestore } from 'react-redux-firebase'
import { useState } from 'react'
import { isEmulating } from '../../utils/emulator'
import { firestoreIDGenerator } from '../../utils/firestoreIDGenerator'
import { personFullName } from '../../model/person'
import { NEWdataStandards } from '../../utils/dataStandards'
import { onError } from '../../components/Form'

type filesCreator = {
  customDocumentData?: any
  addedFiles: File[]
  filesStorePath: string
  /** When true, a firestore document with the file data on the same path will also be saved. */
  firestoreDoc?: boolean
  /** DEPRECATED: doc extension which wel be added to all the keys */
  docExtension?: string
  /** Set fileID instead of generated ID for the file */
  fileID?: string
}

type uploadLabels = {
  uploadFilesFailed: string
}

const defaultLabels: uploadLabels = {
  uploadFilesFailed: 'Uploaden bestanden mislukt.',
}

export const useFilesUpload = ({
  profile,
  onError,
  labels = defaultLabels,
}: { labels?: uploadLabels; profile: any } & onError) => {
  const [uploadStatus, setUploadStatus] = useState<any>({})
  const firebase = useFirebase()
  const firestore = useFirestore()

  const uploadFiles = async ({
    customDocumentData,
    addedFiles,
    filesStorePath,
    docExtension,
    firestoreDoc = false,
  }: filesCreator): Promise<any[]> => {
    try {
      const filesUploaded: Array<any> = []
      const uploadFile = async file => {
        if (file.bucket === undefined) {
          const customMetadata = {
            originalName: file.name,
            filesStorePath: filesStorePath,
            size: file.size,
            firestoreDoc,
            createdBy: profile.uid,
            ...(customDocumentData && customDocumentData),
            ...(isEmulating() && {
              emulatorPassword: process.env.REACT_APP_EMULATOR_PASSWORD || '',
            }),
          }
          const generateFileID = firestoreIDGenerator()
          const storageRef = firebase
            .storage()
            .ref(`${filesStorePath}/${generateFileID}/`)
            .child(file.name)
          const uploadTask = storageRef.put(file, {
            customMetadata,
          })

          uploadTask.on('state_changed', snap => {
            setUploadStatus(state => {
              const newState = { ...state }
              if (snap.bytesTransferred === snap.totalBytes) {
                delete newState[generateFileID]
              } else {
                newState[generateFileID] = { snap: snap, fileName: file.name }
              }
              return newState
            })
          })

          const responseMetaData: any = await (
            await uploadTask
          ).ref.getMetadata()

          Object.keys(responseMetaData).forEach(
            key =>
              responseMetaData[key] === undefined &&
              delete responseMetaData[key],
          )

          const dataStandards = NEWdataStandards({
            data: {},
            editForm: false,
            uid: profile.uid,
            keywords: [file.name],
            archived: false,
          })

          const fileData = {
            name: file.name,
            fullPath: responseMetaData.fullPath,
            fileMetadata: customMetadata,
            createdByName: personFullName(profile),
            ...(customDocumentData && customDocumentData),
            ...dataStandards,
          }
          Object.keys(fileData).forEach(key =>
            fileData[key] === undefined ? delete fileData[key] : {},
          )

          if (firestoreDoc) {
            // add docExtension for backwards compatibility
            const withExtension = {}
            Object.entries(fileData).forEach(([key, value]) => {
              withExtension[`${key}${docExtension ? docExtension : ''}`] = value
            })

            await firestore
              .doc(`${filesStorePath}/${generateFileID}/`)
              .set(withExtension)
          }

          responseMetaData.generateFileID = generateFileID
          filesUploaded.push(responseMetaData)
        }
      }

      await Promise.all(addedFiles.map(async file => uploadFile(file)))

      return filesUploaded
    } catch (error) {
      console.error(error)
      onError({ error, snackbarMessage: labels.uploadFilesFailed })
      return []
    }
  }
  return { uploadStatus, uploadFiles }
}

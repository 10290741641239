import React, { useState } from 'react'
import { Grid, Box, Typography, Link, Skeleton } from '@mui/material'
import {
  DatePicker,
  FormRow,
  TextField,
  Checkbox,
  Modal,
  AutoSelect,
  Switch,
  FormPaper,
  ListView,
} from '@fivano/core'
import { servicesConfig } from '@fivano/models'
import useMongo from 'hooks/useMongo'
import { RouteTypes } from 'types/RouteTypes'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import CategoryIcon from '@mui/icons-material/Category'
import EuroIcon from '@mui/icons-material/Euro'
import ListAltIcon from '@mui/icons-material/ListAlt'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import PersonIcon from '@mui/icons-material/Person'
import { PackageComposer } from './PackageComposer'
import { ColleagueSelector } from './ColleagueSelector'
import { serviceListColumns } from './ServicesList'
import { FormInput } from 'app/components/Form/FormInput'
import { OptionalInputs } from 'app/components/Form/OptionalInputs'
import { FormInputsBuilder } from 'app/components/Form/FormInputsBuilder'

const GeneralInfoInputs = ({ formObject }) => {
  const inputsConfig = [
    {
      type: 'FormPaper',
      content: [
        {
          type: 'FormRow',
          content: [
            {
              type: 'AutoComplete',
              props: {
                name: 'workspaces',
                multiple: true,
                label: 'Workspaces',
                rules: { required: 'Verplicht veld' },
                optionsCollection: 'workspaces',
              },
            },
          ],
        },
      ],
    },
    {
      type: 'FormPaper',
      content: [
        {
          type: 'FormRow',
          icon: 'title',
          props: { helperText: '' },
          content: [
            {
              type: 'TextField',
              props: { name: 'name', label: 'Naam dienst' },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'subject',
          props: { helperText: '' },
          content: [
            {
              type: 'TextField',
              props: {
                name: 'description',
                label: 'Omschrijving dienst',
                rows: 4,
                multiline: true,
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: 'category',
          props: { helperText: '' },
          content: [
            {
              type: 'AutoComplete',
              props: {
                name: 'category',
                label: 'Categorie dienst',
                optionsCollection: 'serviceCategories',
              },
            },
          ],
        },
      ],
    },
    {
      type: 'FormPaper',
      content: [
        {
          type: 'FormRow',
          icon: 'public',
          props: { helperText: '' },
          content: [
            {
              type: 'AutoComplete',
              props: {
                name: 'status',
                label: 'Status',
                rules: { required: 'Verplicht veld' },
                staticOptions: {
                  public: 'Direct publiceren',
                  publishOnDate: 'Inplannen publicatie',
                  concept: 'Concept',
                },
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          hide: formObject.watch('status') !== 'publishOnDate',
          content: [
            {
              type: 'DatePicker',
              props: {
                name: 'publishDate',
                label: 'Inplannen op',
                rules: { required: 'Verplicht veld' },
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          content: [
            {
              type: 'Checkbox',
              props: {
                name: 'bookableOnline',
                label: 'Online te boeken',
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: {
            helperText:
              'De dienst zal onder jullie naam aangeboden worden op platformnaam.nl.',
          },
          content: [
            {
              type: 'Checkbox',
              props: {
                name: 'bookablePlatform',
                label: 'Te boeken via platformnaam.nl',
              },
            },
          ],
        },
        {
          type: 'FormRow',
          icon: '',
          props: { helperText: '' },
          content: [
            {
              type: 'AutoComplete',
              props: {
                name: 'platformCategory',
                label: 'Platform categorie',
                rules: { required: 'Verplicht veld' },
                staticOptions: {
                  cut: 'Knippen',
                  wax: 'Male waxing',
                  paint: 'Verven',
                },
              },
            },
          ],
        },
      ],
    },
  ]

  return (
    <FormInputsBuilder inputsConfig={inputsConfig} formObject={formObject} />
  )
}

const PartOfPackage = ({ packages }) => {
  const [open, setOpen] = useState(false)
  const history = useHistory()

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <>
          <Box paddingLeft={2} paddingTop={2}>
            <Typography variant='h6'>
              Pakketten waar deze dienst onderdeel van is:
            </Typography>
          </Box>
          <ListView
            data={packages}
            columns={serviceListColumns}
            onClick={(_, data) => {
              history.push(
                `/${servicesConfig.labels.plural}/${data.original._id}/formulier`,
              )
            }}
          />
        </>
      </Modal>
      <Box>
        <Typography variant='caption'>
          Deze dienst is onderdeel van
          <Link
            component='button'
            onClick={event => {
              event.preventDefault()
              setOpen(true)
            }}
            style={{ margin: '0px 4px 0px 4px' }}
          >
            {packages.length === 1
              ? 'een bestaand pakket'
              : `${packages.length} bestaande pakketten`}
          </Link>
          en kan daarom niet zelf worden omgezet naar een pakket.
        </Typography>
      </Box>
    </>
  )
}

export const PackageInputs = ({ formObject }) => {
  const { watch } = formObject
  const { useQueryDocs } = useMongo()
  const { serviceID } = useParams<RouteTypes>()

  const { docsData: packages, status } = useQueryDocs({
    collection: 'services',
    query: {
      packageServices: { $eq: serviceID },
    },
    disabled: !serviceID,
  })

  const options = [
    {
      value: 'specifiedOrder',
      label: 'Plannen op onderstaande volgorde',
    },
    {
      value: 'simultaneously',
      label: 'Diensten tegelijkertijd plannen',
    },
    {
      value: 'randomOrder',
      label: 'Plannen op willekeurige volgorde',
    },
  ]
  console.log(options)
  const isPartOfPackage = packages.length > 0
  if (status === 'loading') {
    return (
      <Box paddingX={1}>
        <Skeleton height={40} />
      </Box>
    )
  }
  return (
    <FormPaper>
      <FormRow
        icon={<ListAltIcon />}
        helperText='Een diensten pakket bestaat uit meerdere bestaande diensten.'
      >
        <Switch
          disabled={isPartOfPackage}
          label='Diensten pakket'
          name='package'
          formObject={formObject}
        />
        {isPartOfPackage && <PartOfPackage packages={packages} />}
      </FormRow>
      {watch('package') && (
        <>
          <FormRow
            helperText='Selecteer een volgorde waarin de diensten worden gepland.'
            icon={<CategoryIcon />}
          >
            {/* <AutoSelect
              label='Planning diensten'
              name='planningPackage'
              formObject={formObject}
              disableClearable
              defaultValue={options[0]}
              options={options}
            /> */}
          </FormRow>
          <FormRow
            icon=''
            helperText='Minimaal 2 diensten selecteren. De totalen worden automatisch doorgerekend.'
          >
            <PackageComposer formObject={formObject} />
          </FormRow>
        </>
      )}
    </FormPaper>
  )
}

const taxOptions = [
  { value: '21%', label: '21% BTW' },
  { value: '9%', label: '9% BTW' },
  { value: 'taxFree', label: 'BTW vrij' },
]

const priceTypeOptions = [
  {
    value: 'fixedPrice',
    label: 'Vaste prijs',
  },
  {
    value: 'floorPrice',
    label: 'Prijs vanaf',
  },
  {
    value: 'anyPrice',
    label: 'Prijs vrij in te vullen',
  },
]

export const PricingInputs = ({ formObject }) => {
  const { watch } = formObject
  const formInputs = [
    {
      Component: TextField,
      required: true,
      props: {
        name: 'price',
        label: 'Prijs',
        type: 'number',
      },
    },
    {
      Component: AutoSelect,
      controlled: true,
      required: true,
      props: {
        name: 'packageTax',
        label: 'Belasting',
        disableClearable: true,
        defaultValue: taxOptions[0],
        options: taxOptions,
      },
    },
    {
      Component: AutoSelect,
      controlled: true,
      required: true,
      props: {
        name: 'priceType',
        label: 'Prijs soort',
        disableClearable: true,
        defaultValue: priceTypeOptions[0],
        options: priceTypeOptions,
      },
    },
  ]

  const optionalInputs = [
    {
      Component: TextField,
      small: true,
      required: true,
      props: {
        name: 'salePrice',
        label: 'Sale-prijs',
        type: 'number',
      },
    },
    {
      Component: DatePicker,
      small: true,
      controlled: true,
      props: {
        name: 'saleStart',
        label: 'Sale vanaf',
      },
    },
    {
      Component: DatePicker,
      small: true,
      controlled: true,
      props: {
        name: 'saleEnd',
        label: 'Sale t/m',
        minDate: watch('saleStart'),
      },
    },
    {
      Component: TextField,
      small: true,
      props: {
        name: 'bonusPoints',
        label: 'Bonuspunten',
      },
    },
    {
      Component: Checkbox,
      controlled: true,
      props: {
        name: 'couponEnabled',
        label: 'Waardebon inschakelen',
      },
    },
  ]
  console.log(optionalInputs)

  return (
    <FormPaper>
      <FormRow icon={<EuroIcon />} helperText=''>
        <Grid xs={12} item>
          <Grid container spacing={1}>
            {formInputs.map(input => (
              <Grid key={input.props.name} xs={12} md={4} item>
                {FormInput({ formObject, ...input })}
              </Grid>
            ))}
            <Grid item xs={12}>
              {/* <OptionalInputs
                formObject={formObject}
                optionalInputs={optionalInputs}
              /> */}
            </Grid>
          </Grid>
        </Grid>
      </FormRow>
    </FormPaper>
  )
}

export const DurationInputs = ({ formObject }) => {
  return (
    <FormRow
      icon={<TimelapseIcon />}
      helperText='Bepaal de duratie van de afspraak. Onder "extra opties" kunt u geblokkeerde
         tijd na de afspraak instellen, andere afspraken kunnnen niet binnen de geblokkeerde tijd vallen. 
         Tijdens de overlap tijd kunnnen wel andere diensten worden gepland.'
    >
      <Grid xs={12} item>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <TextField
              type='number'
              label='Duratie dienst (minuten)'
              name='duration'
              formObject={formObject}
            />
          </Grid>
          <Grid item xs={12}>
            <OptionalInputs
              formObject={formObject}
              optionalInputs={[
                {
                  Component: TextField,
                  small: true,
                  props: {
                    type: 'number',
                    label: 'Geblokkeerd (minuten)',
                    name: 'blockedDuration',
                  },
                },
                {
                  Component: TextField,
                  small: true,
                  props: {
                    type: 'number',
                    label: 'Overlap (minuten)',
                    name: 'overlapDuration',
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormRow>
  )
}

export const StaffMembersInputs = ({ formObject }) => {
  const { watch } = formObject
  const groupOptions = [
    {
      value: 'hairdressers',
      label: 'Kappers',
    },
    {
      value: 'cleaners',
      label: 'Schoonmakers',
    },
    {
      value: 'painters',
      label: 'Schilders',
    },
  ]
  return (
    <FormPaper>
      <FormRow icon={<PersonIcon />} helperText=''>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            {FormInput({
              formObject,
              Component: Switch,
              controlled: true,
              props: {
                name: 'allColleagues',
                defaultValue: true,
                label: 'Alle medewerkers mogen dienst uitvoeren',
              },
            })}
          </Grid>
        </Grid>
      </FormRow>

      {!watch('allColleagues') && (
        <>
          <FormRow
            icon=''
            helperText='Selecteer een groep met medewerkers die de dienst mogen uitvoeren.'
          >
            <Grid xs={12} item>
              {FormInput({
                formObject,
                Component: AutoSelect,
                controlled: true,
                props: {
                  name: 'colleagueGroups',
                  label: 'Medewerkers groepen',
                  options: groupOptions,
                },
              })}
            </Grid>
          </FormRow>
          <FormRow
            icon=''
            helperText='Naast een groep kunt u ook individuele medewerkers toevoegen die de dienst mogen uitvoeren.'
          >
            <Grid xs={12} item>
              <ColleagueSelector formObject={formObject} />
            </Grid>
          </FormRow>
        </>
      )}
    </FormPaper>
  )
}

export const ServiceFormInputs = ({ formObject }) => {
  // const { watch } = formObject
  return (
    <>
      <Grid container direction='column' spacing={2}>
        <GeneralInfoInputs formObject={formObject} />
        {/* <PackageInputs formObject={formObject} /> */}
        {/* {watch('package') ? (
          watch('customPackagePrice') && (
            <PricingInputs formObject={formObject} />
          )
        ) : (
          <>
            <PricingInputs formObject={formObject} />
            <FormPaper>
              <DurationInputs formObject={formObject} />
            </FormPaper>
          </>
        )}
        <StaffMembersInputs formObject={formObject} /> */}
      </Grid>
    </>
  )
}

import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Checkbox } from '@fivano/core'
import { useFirestore } from 'react-redux-firebase'
import { WorkFromHomeType } from './types'
import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'
import { useDataStandards } from 'hooks/useDataStandards'

interface WorkingHomeCheckboxType {
  /** ID of doc */
  id?: string
  /** The document data, also the defaultValue */
  docData?: WorkFromHomeType
  /** ID of the current person */
  personID: string
  /** Date of the working home day */
  date: Date
}

export const WorkingHomeCheckbox = ({
  docData,
  personID,
  date,
}: WorkingHomeCheckboxType) => {
  const formObject = useForm({ defaultValues: docData || undefined })
  const firestore = useFirestore()

  const isEditing = !!docData?.id

  useEffect(() => {
    if (docData) formObject.reset(docData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docData])

  const createDataStandards = useDataStandards()

  const onSubmit = data => {
    data.id = isEditing ? docData?.id : firestoreIDGenerator()
    const dataStandards = createDataStandards({
      data,
      dataName: '',
      editForm: isEditing,
    })

    const newData = { ...data, ...dataStandards }

    if (!isEditing) {
      newData.date = date
      newData.personID = personID
      firestore.collection('workingHome').doc(newData.id).set(newData)
    } else {
      firestore.collection('workingHome').doc(docData?.id).update(newData)
    }
  }

  return (
    <Checkbox
      name='workFromHome'
      label='Thuiswerken'
      size='small'
      formObject={formObject}
      onChange={() => formObject.handleSubmit(onSubmit)()}
    />
  )
}

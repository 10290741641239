export const initialParticipantValues = {
  idPers: '',
  personTypePers: '',
  firstNamePers: '',
  middleNamePers: '',
  lastNamePers: '',
  birthNamePers: '',
  givenNamesPers: '',
  initialsPers: '',
  genderPers: '',
  birthdatePers: null,
  addressCountryPers: { value: 'NL', label: 'Nederland' },
  addressZipPers: '',
  addressStreetPers: '',
  addressNumberPers: '',
  addressAdditionPers: '',
  addressCityPers: '',
  addressStatePers: '',
  emailPers: '',
  phoneMobilePers: '',
  phoneLinePers: '',
  phoneNoAnswerPers: '',
  textPers: '',
  createdByPers: '',
  accessIDsPers: [],
  keywordsPers: [],
  civilStatus: '',
  numberChildsPers: '',
  socialSecurityNumberPers: '',
  identificationNumberPers: '',
  identificationTypePers: '',
  identificationValidUntilPers: null,
  ibanPers: '',
  bicPers: '',
  bankPers: '',
  bankNamePers: '',
  avatarImagePers: false,
}

export const formSettings = {
  locationPers: {
    hide: false,
    rules: {
      required: true,
    },
  },
  initialsPers: {
    required: true,
    hide: false,
    toggleVisibility: false,
  },
  givenNamesPers: {
    hide: false,
    toggleVisibility: true,
  },
  birthNamePers: {
    hide: false,
    toggleVisibility: true,
  },
  relationPositionPers: {
    hide: true,
    toggleVisibility: false,
  },
  socialSecurityNumberPers: {
    hide: false,
    toggleVisibility: false,
  },
  birthdatePers: {
    required: true,
    hide: false,
    toggleVisibility: false,
    datepickerProps: { format: 'yyyy/MM/dd', placeholder: 'jjjj/mm/dd' },
  },
  identificationTypePers: {
    hide: false,
    toggleVisibility: true,
  },
  identificationNumberPers: {
    hide: false,
    toggleVisibility: true,
  },
  identificationValidUntilPers: {
    hide: false,
    toggleVisibility: true,
  },
  emailPers: {
    required: true,
    hide: false,
    toggleVisibility: false,
  },
  genderPers: {
    hide: false,
    toggleVisibility: false,
    options: [
      { label: 'Man', value: 'M' },
      { label: 'Vrouw', value: 'F' },
      { label: 'X', value: 'X' },
      { label: 'Onbekend', value: 'O' },
    ],
  },
  addressStreetPers: {
    required: true,
    hide: false,
    toggleVisibility: false,
  },
  addressNumberPers: {
    required: true,
    hide: false,
    toggleVisibility: false,
  },
  addressAdditionPers: {
    hide: false,
    toggleVisibility: false,
  },
  addressZipPers: {
    required: true,
    hide: false,
    toggleVisibility: false,
  },
  addressCityPers: {
    required: true,
    hide: false,
    toggleVisibility: false,
  },
  addressCountryPers: {
    required: false,
    hide: false,
    toggleVisibility: true,
  },
  addressStatePers: {
    required: false,
    hide: false,
    toggleVisibility: true,
  },
  phoneMobilePers: {
    required: true,
    hide: false,
    toggleVisibility: false,
  },
  phoneLinePers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  phoneNoAnswerPers: {
    required: false,
    hide: false,
    toggleVisibility: true,
  },
  educationPers: {
    hide: true,
    toggleVisibility: false,
  },
  martialStatusData: {
    hide: false,
    toggleVisibility: false,
  },
  bankData: {
    hide: false,
    toggleVisibility: false,
  },
  dossierNumber: {
    hide: false,
    toggleVisibility: true,
  },
  archiveData: {
    hide: false,
    toggleVisibility: true,
  },
}

import React, { useEffect } from 'react'
import { Grid, capitalize } from '@mui/material'
import { DatePicker } from '@fivano/core'
import { addDays, addMonths, addWeeks, addYears, subDays } from 'date-fns'
import { useSettings } from 'hooks/useSettings'

export const PeriodInput = ({ isEditing, moduleData, formObject }) => {
  const { watch, setValue } = formObject
  const settings = useSettings()
  const labels = settings.tracks.labels

  useEffect(() => {
    const startDateTrackValue = watch('startDateTrack')
    const endDateTrackValue = watch('endDateTrack')
    const amount = moduleData?.durationAmountModu
    const unit = moduleData?.durationUnitModu?.value

    if (amount && unit && !isEditing) {
      if (
        startDateTrackValue instanceof Date &&
        !isNaN(startDateTrackValue.getTime()) &&
        endDateTrackValue === null
      ) {
        if (unit === 'days') {
          setValue(
            'endDateTrack',
            addDays(startDateTrackValue, parseInt(amount) - 1),
          )
        } else if (unit === 'weeks') {
          setValue(
            'endDateTrack',
            subDays(addWeeks(startDateTrackValue, parseInt(amount)), 1),
          )
        } else if (unit === 'months') {
          setValue(
            'endDateTrack',
            subDays(addMonths(startDateTrackValue, parseInt(amount)), 1),
          )
        } else if (unit === 'years') {
          setValue(
            'endDateTrack',
            subDays(addYears(startDateTrackValue, parseInt(amount)), 1),
          )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('startDateTrack'), moduleData])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <DatePicker
          label={`${capitalize(labels.track)} startdatum`}
          name='startDateTrack'
          formObject={formObject}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          label={`${capitalize(labels.track)} eindatum`}
          name='endDateTrack'
          formObject={formObject}
          minDate={watch('startDateTrack')}
        />
      </Grid>
    </Grid>
  )
}

import { routeParts } from 'app/router/routeParts'
import { routeObjectType } from 'app/router/types'
import { RoleProfileForm } from './RoleProfileForm'
import { RoleProfilePage } from './RoleProfilePage'
import { UserSettingsPage } from './UserSettingsPage'
import { Workspaces } from './Workspaces'

export const authorizationRoutes: routeObjectType = {
  authorizationRoleProfiles: {
    path: `/${routeParts.settings.path}/${routeParts.roleProfiles.path}`,
    component: RoleProfilePage,
    claims: {},
  },
  authorizationRoleProfilesForm: {
    path: `/${routeParts.settings.path}/${routeParts.roleProfiles.path}/:roleProfileID`,
    component: RoleProfileForm,
    claims: {},
  },
  userSettings: {
    path: `/${routeParts.settings.path}/${routeParts.users.path}`,
    component: UserSettingsPage,
    claims: {},
  },
  workspaces: {
    path: `/${routeParts.settings.path}/${routeParts.workspaces.path}`,
    component: Workspaces,
    claims: {},
  },
}

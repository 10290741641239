export const firebaseAddError = (
  error: any,
  firestore: any,
  uid: string,
  pathname: string,
  enqueueSnackbar: any | undefined,
  errorMessage: string | undefined,
) => {
  const data: any = {}
  if (error?.name) {
    data.errorName = error.name
  }
  if (error?.code) {
    data.errorCode = error.code
  }
  if (error?.message) {
    data.errorMessage = error.message
  }
  if (error?.stack) {
    data.errorStack = error.stack
  }
  data.date = new Date()
  data.profileID = uid ? uid : 'noUID'
  data.pathname = pathname
  process.env.NODE_ENV === 'development' && console.log(error) // DONT REMOVE THIS CONSOLE.LOG
  firestore
    .collection('errorLogger')
    .add(data)
    .then(() => {
      enqueueSnackbar !== undefined &&
        enqueueSnackbar(
          errorMessage !== undefined
            ? errorMessage
            : `Er is iets fout gegaan! ${error}`,
          {
            variant: 'error',
          },
        )
    })
    .catch(error => {
      return error
    })
}

import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export const useModalRoute = () => {
  const history = useHistory()
  const location = useLocation()
  const idParam = new URLSearchParams(location.search).get('id')
  const formDefault = { open: false, id: '' }
  const [modalData, setModalData] = useState(formDefault)

  useEffect(() => {
    if (idParam !== null) {
      setModalData({
        open: true,
        id: idParam === 'toevoegen' ? '' : idParam,
      })
    }
  }, [idParam])

  const closeModal = () => {
    setModalData(formDefault)
    history.push({ pathname: location.pathname, search: '' })
  }

  const openModal = (id?: string) => {
    history.push({
      pathname: location.pathname,
      search: `?id=${id || 'toevoegen'}`,
    })
  }
  return {
    openModal,
    closeModal,
    modalData,
  }
}

import { defaultSettings, SettingsTypes } from 'defaultSettings'
import { useSelector } from 'hooks/useSelector/useSelector'
import { useEffect } from 'react'
import { useFirestore } from 'react-redux-firebase'
import { deepMerge } from '@fivano/models'

export const useSettings = (loadSettings: boolean = false): SettingsTypes => {
  const firestore = useFirestore()
  useEffect(() => {
    if (loadSettings) {
      firestore.get({
        collection: 'appSettings',
        doc: 'settings',
        storeAs: 'settings',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSettings])
  const settings = useSelector(state => state.firestore.ordered.settings?.[0])
  const mergedSettings: any = deepMerge(defaultSettings, settings)
  return mergedSettings
}

import React, { useEffect, useState } from 'react'
import { AudioWavesBaseTypes, AudioWavesBaseOptionTypes } from './types'
import { firestoreIDGenerator } from '../../utils/firestoreIDGenerator'

const defaultOptions: AudioWavesBaseOptionTypes = {
  barGap: 0,
  barHeight: 2,
  cursorWidth: 0,
  height: 200,
  hideScrollbar: true,
  progressColor: '#EC407A',
  responsive: true,
  waveColor: '#D1D6DA',
}

export const AudioWavesBase = (props: AudioWavesBaseTypes) => {
  const {
    wavesurfer,
    plugins = [],
    id,
    audioFile = '',
    options = defaultOptions,
    audioPeaks,
    onWaveformReady,
    children,
  } = props
  const waveID = `sound${id || firestoreIDGenerator()}`

  const [wavesurferObject, setWaveSurfer] = useState(null)

  useEffect(() => {
    const initWavesurfer = wavesurfer.create({
      // If there is an audioFile use the MediaElement
      ...(audioFile && { backend: 'MediaElement' }),
      container: `#${waveID}`,
      plugins,
      ...options,
    })

    if (audioFile) {
      // Create wavesurfer audio and add peaks/waves
      initWavesurfer.load(audioFile, audioPeaks)
    } else {
      // Create peaks/waves without audio
      initWavesurfer.load([0], audioPeaks)
    }

    onWaveformReady && onWaveformReady(initWavesurfer)

    setWaveSurfer(initWavesurfer)

    return () => {
      delete initWavesurfer.backend.buffer
      initWavesurfer.unAll()
      initWavesurfer.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const childrenWithProps =
    children &&
    React.Children.map(children, child =>
      React.cloneElement(child, {
        wavesurfer: wavesurferObject,
      }),
    )

  return <div id={waveID}>{wavesurferObject && childrenWithProps}</div>
}

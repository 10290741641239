import React, { useEffect, useState } from 'react'
import { CircularProgress, styled, useMediaQuery } from '@mui/material'
import { LicenseInfo, DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { DataGridProps, GridSettingsProps } from './types'
import { GridToolbar } from './GridToolbar/GridToolbar'
import { useSelector } from 'hooks/useSelector'
import useMongo from 'hooks/useMongo'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { useSnackbar } from '@fivano/core'

LicenseInfo.setLicenseKey(
  '612e007bf5cf7234e39cfaa012d6ffe3T1JERVI6MzAwNTYsRVhQSVJZPTE2NjQ0NDkzMjEwMDAsS0VZVkVSU0lPTj0x',
)

const DataGridWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'isEditing' && prop !== 'customHeight',
})<any>(({ isEditing, customHeight }) => ({
  height: customHeight,

  '& .dataGrid': {
    border: 0,
  },

  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: '0px 6px 0px 0px !important',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: !isEditing ? 'hidden' : 'visible',
  },
  '& .noHeader': {
    visibility: 'hidden',
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
}))

export const DataGridNew = ({
  name,
  rows,
  columns,
  columnsMobile,
  rowActions,
  editGridLayout,
  hideFooterRowCount,
  hideActionsMobile = false,
  onRowClick,
  onSubmitSuccess,
  isEditable = true,
  autoHeight = false,
  customHeight,
  ...dataGridProps
}: DataGridProps) => {
  const userID = useSelector(state => state.firebase.profile.uid)
  const [isEditing, setIsEditing] = useState(false)
  const { createDoc, updateDoc, useQueryDocs, useGetDoc } = useMongo()
  const errorLogger = useErrorLogger()
  const [settings, setSettings] = useState<any>(undefined)
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const { enqueueSnackbar } = useSnackbar()

  const actions: GridColDef = {
    field: 'actions',
    headerName: 'Acties',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    resizable: false,
    disableColumnMenu: true,
    filterable: false,
    headerClassName: 'noHeader',
    width: 50,
    ...(rowActions && rowActions),
  }

  // If rowActions has items, add to the columns
  const addListActions = columns => {
    if (rowActions) {
      columns = [...columns, actions]
    }

    return columns
  }

  /** Only get the ID of the doc if existing */
  const { docsData, status } = useQueryDocs({
    collection: 'gridSettings',
    query: {
      name: { $eq: name },
      userID: { $eq: userID ? userID : '' },
    },
  })

  const [gridID, setGridID] = useState(undefined)
  const data = useGetDoc({
    collection: 'gridSettings',
    docID: gridID ? gridID : '',
  })

  useEffect(() => {
    if (docsData.length) {
      setGridID(docsData[0]?._id)
    }
    if (!docsData.length) {
      setSettings({ desktop: columns, mobile: columnsMobile })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docsData])

  useEffect(() => {
    if (data) {
      getColumnSettings(data, columns, columnsMobile).then(columns => {
        setSettings(columns)
      })
    }
  }, [columns, columnsMobile, data])

  const submitGrid = async columns => {
    const data: GridColDef[] = await formatGridSettings(columns)
    const newData: GridSettingsProps = {
      userID: userID,
      name: name,
      desktop: !isMobile ? data : settings?.desktop,
      mobile: isMobile ? data : settings?.mobile,
    }

    if (!!!gridID) {
      return await createDoc({
        collection: 'gridSettings',
        data: newData,
      })
        .then(response => {
          setGridID(response.docs[0]._id)
          setIsEditing(false)
          setSettings({ desktop: newData.desktop, mobile: newData.mobile })
          enqueueSnackbar('Indeling opgeslagen', { variant: 'success' })
        })
        .catch(error => {
          errorLogger({ error })
        })
    } else {
      return await updateDoc({
        collection: 'gridSettings',
        docID: gridID,
        data: newData,
      })
        .then(() => {
          setIsEditing(false)
          setSettings({ desktop: newData.desktop, mobile: newData.mobile })
          enqueueSnackbar('Indeling opgeslagen', { variant: 'success' })
        })
        .catch(error => {
          errorLogger({ error })
        })
    }
  }

  const mobileColumns = settings?.mobile || []
  const desktopColumns = settings?.desktop || []

  if (!userID || status === 'loading') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: 600,
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    )
  }
  return (
    <DataGridWrapper
      isEditing={isEditing}
      {...(customHeight && { customHeight: customHeight })}
    >
      <DataGridPro
        autoHeight={true}
        disableSelectionOnClick
        rows={rows || []}
        columns={
          settings
            ? isMobile
              ? rowActions
                ? addListActions(mobileColumns)
                : mobileColumns
              : rowActions
              ? addListActions(desktopColumns)
              : desktopColumns
            : []
        }
        className='dataGrid'
        disableColumnReorder={!editGridLayout}
        hideFooterRowCount={hideFooterRowCount}
        onRowClick={onRowClick}
        components={{ Toolbar: GridToolbar }}
        getRowId={row => row._id}
        componentsProps={{
          toolbar: {
            isEditable,
            isEditing,
            onEdit: setIsEditing,
            onSubmit: submitGrid,
          },
        }}
        {...dataGridProps}
      />
    </DataGridWrapper>
  )
}

/** Merge DB columns with default columns, return the merged columns */
const getColumnSettings = async (docsData, columns, columnsMobile) => {
  const docData = docsData
  const columnsCopy = { ...docData }
  columnsCopy.desktop = await mergeColumns(docData.desktop, columns)
  columnsCopy.mobile = await mergeColumns(docData.mobile, columnsMobile)

  return { desktop: columnsCopy.desktop, mobile: columnsCopy.mobile }
}

const mergeColumns = async (columns, defaultColumns) =>
  await columns?.map(column => {
    const findData = defaultColumns?.find(x => x.field === column.field)
    return { ...findData, ...column }
  })

/** Format Datagrid columns before submitting, submit without the column state functions */
const formatGridSettings = async (columns: GridColDef[]) => {
  const newArray: GridColDef[] = []
  columns.forEach(item => {
    if (item.field !== 'actions') {
      newArray.push({
        field: item.field,
        ...(item.align && { align: item.align }),
        ...(item.disableColumnMenu && {
          disableColumnMenu: item.disableColumnMenu,
        }),
        ...(item.filterable && { filterable: item.filterable }),
        ...(item.headerName && { headerName: item.headerName }),
        ...(item.hide && { hide: item.hide }),
        ...(item.minWidth && { minWidth: item.minWidth }),
        ...(item.resizable && { resizable: item.resizable }),
        ...(item.sortable && { sortable: item.sortable }),
        ...(item.type && { type: item.type }),
        ...(item.width && { width: item.width }),
      })
    }
  })
  return newArray
}

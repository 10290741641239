type NameType = {
  firstNamePers?: string
  middleNamePers?: string
  lastNamePers?: string
}

export const personFullName = ({
  firstNamePers,
  middleNamePers,
  lastNamePers,
}: NameType) => {
  return `${firstNamePers ? firstNamePers : ''} ${
    middleNamePers ? `${middleNamePers} ` : ''
  }${lastNamePers}`
}

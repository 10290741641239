import React from 'react'
import { useSelector } from 'hooks/useSelector'
import {
  Card,
  Tooltip,
  CardContent,
  Typography,
  Grid,
  Chip,
  capitalize,
  styled,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import DescriptionIcon from '@mui/icons-material/Description'
import nl from 'date-fns/locale/nl'
import { formatDistanceToNow, format } from 'date-fns'
import PersonIcon from '@mui/icons-material/Person'
import { revokeDownloadToken } from 'utils/revokeDownloadToken'
import { PersonAvatar } from '../Persons/PersonAvatar'
import { useErrorLogger } from 'hooks/useErrorLogger'
import {
  useFilesDelete,
  IconButton,
  useConfirmModal,
  useSnackbar,
} from '@fivano/core'
import { convertToDate } from 'utils/convertToDate'

const CardStyled = styled(Card)(() => ({
  position: 'relative',
}))

const GridStyled = styled(Grid)(() => ({
  width: 40,
  marginRight: 16,
}))

const ActionGridStyled = styled(Grid)(() => ({
  display: 'block',
  position: 'absolute',
  width: 'auto',
  top: 4,
  right: 4,
}))

const FileChip = ({ file, handleClickFileChip }) => {
  return (
    <Grid item zIndex={1}>
      <Tooltip arrow title='Download'>
        <Chip
          {...(file.fullPath !== undefined && {
            onClick: event => {
              event.preventDefault()
              handleClickFileChip(event, file.fullPath)
            },
          })}
          variant='outlined'
          icon={<DescriptionIcon />}
          size='small'
          label={file.customMetadata.originalName}
        />
      </Tooltip>
    </Grid>
  )
}

export const ProgressBoardCard = ({
  onEdit,
  boardID,
  boardCardID,
  progressBoardCardID,
}) => {
  const firebase = useFirebase()
  const firestore = useFirestore()
  const { enqueueSnackbar } = useSnackbar()
  const errorLogger = useErrorLogger()
  const deleteFiles = useFilesDelete({ onError: errorLogger })

  const uid = useSelector(({ firebase: { auth } }) => auth.uid)
  const progressBoardCard = useSelector(
    ({ firestore: { data } }) =>
      data[`boardCards_${boardCardID}_progressBoardCards`]?.[
        progressBoardCardID
      ],
  )

  const date = format(
    convertToDate(progressBoardCard.dateTimePrbc),
    'dd-MM-yyyy HH:mm',
  )

  const handleClickFileChip = async (event, fullPath) => {
    event.preventDefault()
    firebase
      .storage()
      .ref(fullPath)
      .getDownloadURL()
      .then(response => {
        window.open(response, '_blank')
        revokeDownloadToken(fullPath)
      })
      .catch(error => {
        errorLogger({ error })
      })
  }

  const onDelete = async () => {
    await deleteFiles({
      filesToDelete: progressBoardCard.filesPrbc,
    })

    firestore
      .delete(
        `boards/${boardID}/boardCards/${boardCardID}/progressBoardCards/${progressBoardCardID}`,
      )
      .then(() => {
        enqueueSnackbar('Voortgang succesvol verwijderd.', {
          variant: 'success',
        })
      })
      .catch(error => {
        errorLogger({
          error,
          snackbarMessage: `Voortgang verwijderen mislukt. ${error}`,
        })
      })
  }

  const confirmModal = useConfirmModal()

  const handleDelete = () => {
    confirmModal({
      onConfirm: onDelete,
      cancelLabel: 'Annuleren',
      confirmLabel: 'Verwijderen',
      title: 'Weet je het zeker?',
      message:
        'Het voortgang item en de eventueel gekoppelde bijlages worden permanent verwijdert. Deze actie kan niet ongedaan gemaakt worden.',
    })
  }

  return (
    <>
      <CardStyled variant='outlined'>
        <CardContent>
          <Grid container spacing={1}>
            <GridStyled item>
              <PersonAvatar
                locationID='publicLocation'
                personID={progressBoardCard.createdByPrbc}
              />
            </GridStyled>
            <Grid item width='calc(100% - 56px)'>
              <Typography variant='body2'>
                {progressBoardCard.createdByNamePrbc}
              </Typography>
              <Tooltip
                arrow
                title={
                  progressBoardCard.dateTimePrbc &&
                  capitalize(
                    formatDistanceToNow(
                      convertToDate(progressBoardCard.dateTimePrbc),
                      { locale: nl, addSuffix: true },
                    ),
                  )
                }
              >
                <Typography
                  display='inline'
                  variant='body2'
                  color='textSecondary'
                >
                  {date}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                {progressBoardCard.descriptionPrbc}
              </Typography>
            </Grid>
            {progressBoardCard.tagsPrbc?.length > 0 &&
              progressBoardCard.tagsPrbc.map((tag, index) => {
                return (
                  <Grid item key={index}>
                    <Chip size='small' label={tag.label} />
                  </Grid>
                )
              })}
            {progressBoardCard.filesPrbc?.length > 0 &&
              progressBoardCard.filesPrbc.map((file, index) => (
                <FileChip
                  key={index}
                  file={file}
                  handleClickFileChip={handleClickFileChip}
                />
              ))}
            {Object.values(progressBoardCard.contactsPrbc).map((rel: any) =>
              rel.map(contact => (
                <Grid key={contact.value} item>
                  <Tooltip arrow title='Contactpersoon'>
                    <Chip
                      icon={<PersonIcon />}
                      variant='outlined'
                      size='small'
                      label={contact.label}
                    />
                  </Tooltip>
                </Grid>
              )),
            )}
            <ActionGridStyled item>
              <IconButton label='Bewerken' onClick={onEdit} size='large'>
                <EditIcon fontSize='small' />
              </IconButton>
              {progressBoardCard.createdByPrbc === uid && (
                <IconButton
                  label='Verwijderen'
                  onClick={handleDelete}
                  size='large'
                >
                  <DeleteIcon fontSize='small' />
                </IconButton>
              )}
            </ActionGridStyled>
          </Grid>
        </CardContent>
      </CardStyled>
    </>
  )
}

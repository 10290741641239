import React from 'react'
import { Grid, styled, SvgIconProps, Typography } from '@mui/material'

const GridStyled = styled(Grid)(() => ({
  maxWidth: 'calc(100% - 24px)',
  marginLeft: 8,
}))

type IconTextProps = {
  /**Visible label*/
  label?: string
  /**Value of the IconText*/
  value: string
  /**Icon that is used*/
  icon: SvgIconProps
}

export const IconText = ({ label, value, icon }: IconTextProps) => {
  return (
    <Grid
      container
      alignItems={label ? 'flex-end' : 'center'}
      spacing={1}
      wrap='nowrap'
    >
      <Grid item>{icon}</Grid>
      <GridStyled item>
        <Typography variant='caption' color='textSecondary' noWrap>
          {label}
        </Typography>
        <Typography gutterBottom noWrap>
          {value}
        </Typography>
      </GridStyled>
    </Grid>
  )
}

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { useForm } from 'react-hook-form'

import { Typography, Box, Button, InputAdornment } from '@mui/material'

import { TextField, IconButton, LoadingOverlay } from '@fivano/core'

import { AuthWrapper } from 'app/containers/Authentication/components/AuthWrapper'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useSnackbar } from '@fivano/core'

import { useErrorLogger } from 'hooks/useErrorLogger'

type AuthenticationProps = {
  resetCode: string
  newPassword: string
}

export function Authentication(props: AuthenticationProps) {
  const history = useHistory()
  const firebase = useFirebase()
  const { enqueueSnackbar } = useSnackbar()
  const errorLogger = useErrorLogger()
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [verified, setVerified] = useState(false)
  const [code, setCode] = useState('')
  const [mode, setMode] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [loadingAuthorization, setLoadingAuthorization] = useState(false)
  const formObject = useForm()
  const {
    handleSubmit,
    formState: { errors },
  } = formObject
  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    const currentUrl = document.location
    let params = new URL(`${currentUrl}`).searchParams
    setCode(`${params.get('oobCode')}`)
    setMode(`${params.get('mode')}`)
  }, [setCode, setMode])

  const auth = useSelector(state => state.firebase.auth)
  useEffect(() => {
    if (auth.emailVerified) {
      setVerified(true)
      // history.push('/')
    }
  }, [auth, history])

  // ==== EMAIL VERIFICATION ==== //
  const handleEmailVerification = () => {
    setLoadingAuthorization(true)
    firebase
      .auth()
      .applyActionCode(code)
      .then(response => {
        setMessage('Email en account geverifieerd. U kunt inloggen.')
        history.push('/')
      })
      .catch(error => {
        if (error.code === 'auth/user-not-found') {
          setError(
            'De gebruikers is niet gevonden. Neem contact op met uw organisatie.',
          )
        } else if (error.code === 'auth/expired-action-code') {
          setError(
            'De verificatie code is verlopen. Vraag een nieuwe aan via uw organisatie.',
          )
        } else if (error.code === 'auth/invalid-action-code') {
          setError(
            'De verificatie code is ongeldig. Vraag een nieuwe aan via uw organisatie.',
          )
        } else if (error.code === 'auth/user-disabled') {
          setError(
            'De gebruiker onder uw email adres is uitgeschakeld. Vraag uw organistatie om de gebruiker in te schakelen.',
          )
        } else {
          setError(
            'Fout bij verifieren, probeer opnieuw of neem contact op met uw organisatie.',
          )
        }
        errorLogger({ error })
      })
  }

  // ==== EMAIL VERIFICATION MAIL ==== //
  const handleSendEmailVerification = () => {
    setLoadingAuthorization(true)
    setError('')
    firebase
      .auth()
      .currentUser?.sendEmailVerification()
      .then(() => {
        enqueueSnackbar('Verificatie email verzonden.', {
          variant: 'success',
        })
      })
      .catch(error => {
        if (error === 'auth/missing-android-pkg-name') {
          setError(
            'Android fout pkg name ontbreekt, neem contact op met uw organisatie. ',
          )
        } else if (error === 'auth/missing-continue-uri') {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        } else if (error === 'auth/missing-ios-bundle-id') {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        } else if (error === 'auth/invalid-continue-uri') {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        } else if (error === 'auth/unauthorized-continue-uri') {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        } else {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        }
        errorLogger({ error })
      })
  }

  // ==== PASSWORD RESET ==== //
  const handlePasswordReset = data => {
    setLoadingAuthorization(true)
    firebase
      .auth()
      .confirmPasswordReset(code, data.newPassword)
      .then(() => {
        enqueueSnackbar('Wachtwoord herstel link verzonden.', {
          variant: 'success',
        })
        history.push('/inloggen')
      })
      .catch(error => {
        if (error === 'auth/expired-action-code') {
          setError(
            'Wachtwoord herstel link is verlopen. Stel uw wachtwoord nogmaals opnieuw in.',
          )
        } else if (error === 'auth/invalid-action-code') {
          setError(
            'Ongeldige wachtwoord herstel link. Stel uw wachtwoord nogmaals opnieuw in.',
          )
        } else if (error === 'auth/user-disabled') {
          setError(
            'Gebruikers account is uitgeschakeld. Niet mogelijk om wachtwoord te veranderen. Neem contact op met uw organisatie.',
          )
        } else if (error === 'auth/user-not-found') {
          setError('Gebruiker voor deze wachtwoord herstel link bestaat niet.')
        } else if (error === 'auth/weak-password') {
          setError(
            'Het nieuwe wachtwoord dat u wilt gebruiken is niet veilig genoeg. Gebruik een ander wachtwoord.',
          )
        } else {
          setError('Stel uw wachtwoord nogmaals opnieuw in.')
        }
        errorLogger({ error })
      })
  }

  return (
    <>
      <Helmet>
        <title>Authenticatie</title>
      </Helmet>
      <AuthWrapper title=''>
        {loadingAuthorization && (
          <LoadingOverlay
            label='Actie verwerken'
            customStyle={{ borderRadius: 4 }}
          />
        )}
        {verified ? (
          <>
            <Typography variant='h6' align='center' gutterBottom>
              Account geverifieerd
            </Typography>
            <Typography variant='body1'>
              Uw account is geverifieerd, u kunt de applicatie openen.
            </Typography>
            <Box p={2} />
            <Button
              onClick={() => history.push('/')}
              variant='contained'
              color='primary'
              disableElevation
              size='large'
              fullWidth
            >
              Applicatie openen
            </Button>
          </>
        ) : mode === 'verifyEmail' ? (
          <Box>
            <Typography variant='h6' align='center' gutterBottom>
              Email verifieren
            </Typography>
            <Typography variant='body1'>
              Klik op de onderstaande knop om uw email te verifieren.
            </Typography>
            <Typography variant='body1'>{message}</Typography>
            <Typography variant='body1' color='error'>
              {error}
            </Typography>
            <Box p={1} />
            {error === '' ? (
              <Button
                onClick={handleEmailVerification}
                variant='contained'
                color='primary'
                disableElevation
                size='large'
                fullWidth
              >
                Email verifieren
              </Button>
            ) : (
              <Button
                onClick={handleSendEmailVerification}
                disableElevation
                size='small'
                fullWidth
              >
                Verstuur nieuwe verificatie email
              </Button>
            )}
          </Box>
        ) : // <Redirect to='/bevestig-email' />
        mode === 'recoverEmail' ? (
          <Typography variant='body1' color='error'>
            Email wijzigen is niet mogelijk via deze applicatie.
          </Typography>
        ) : mode === 'resetPassword' ? (
          <form onSubmit={handleSubmit(handlePasswordReset)}>
            <Typography variant='h6' align='center' gutterBottom>
              Wachtwoord herstellen
            </Typography>
            <Typography variant='body1'>
              Klik op de onderstaande knop om uw email te verifieren.
            </Typography>
            <Typography variant='body1'>{message}</Typography>
            <Typography variant='body1' color='error'>
              {error}
            </Typography>
            <Box p={1} />
            <TextField
              name='newPassword'
              label='Nieuw wachtwoord'
              formObject={formObject}
              fullWidth
              variant='outlined'
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      label={
                        showPassword
                          ? 'Wachtwoord verbergen'
                          : 'Wachtwoord tonen'
                      }
                      aria-label='toggle password visibility'
                      onClick={handleShowPassword}
                      onMouseDown={handleShowPassword}
                      size='large'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={errors.newPassword !== undefined}
              helperText={
                errors.newPassword &&
                (errors.newPassword.type === 'required' ||
                  errors.newPassword.type === 'pattern') &&
                'Vul een geldig wachtwoord in, gebruik acht of meer tekens met een combinatie van letters, cijfers en symbolen'
              }
            />
            <Box p={1} />
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disableElevation
              size='large'
              fullWidth
            >
              Nieuw wachtwoord instellen
            </Button>
          </form>
        ) : (
          <Box>
            <Typography variant='h6' align='center' gutterBottom>
              Authenticatie fout
            </Typography>
            <Typography variant='body1'>
              Er is iets fout gegaan met het doorverwijzen naar de juiste
              pagina. Gebruik de onderstaande knop om naar de inlog pagina te
              navigeren.
            </Typography>
            <Box p={1} />
          </Box>
        )}
        <Box pb={5} />
        <Button
          onClick={() => history.push('/inloggen')}
          color='primary'
          disableElevation
          size='small'
          fullWidth
        >
          Naar inloggen
        </Button>
      </AuthWrapper>
    </>
  )
}

export function RegisterVerification() {
  const firebase = useFirebase()
  const errorLogger = useErrorLogger()
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')

  // send email verification to user email
  const handleSendEmailVerification = () => {
    firebase
      .auth()
      .currentUser?.sendEmailVerification()
      .then(() => {
        setMessage('Verificatie email opnieuw verzonden.')
      })
      .catch(error => {
        if (error === 'auth/missing-android-pkg-name') {
          setError(
            'Android fout pkg name ontbreekt, neem contact op met uw organisatie. ',
          )
        } else if (error === 'auth/missing-continue-uri') {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        } else if (error === 'auth/missing-ios-bundle-id') {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        } else if (error === 'auth/invalid-continue-uri') {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        } else if (error === 'auth/unauthorized-continue-uri') {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        }
        errorLogger({ error })
      })
  }
  return (
    <>
      <Typography variant='body1' gutterBottom>
        Uw account is geregistreerd. Voordat u kunt inloggen moet uw email
        worden bevestigd.
      </Typography>
      <Box p={1} />
      <Typography variant='body1'>
        Bevestig uw email via de link die naar uw mail is verzonden.
      </Typography>
      <Box p={1} />

      <Typography variant='body1' color='error'>
        {error}
      </Typography>
      <Box pb={12} />
      {message === '' ? (
        <Button
          onClick={handleSendEmailVerification}
          disableElevation
          fullWidth
          size='small'
        >
          Verificatie mail opnieuw versturen
        </Button>
      ) : (
        <Typography variant='body1'>{message}</Typography>
      )}
    </>
  )
}

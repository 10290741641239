import React from 'react'
import { styled } from '@mui/material'
import { AudioWavesBase } from './AudioWavesBase'
import { AudioWavesBaseOptionTypes, RegionType } from './types'

const Wave = styled('div')(() => ({
  width: '100%',
  position: 'relative',
  flexGrow: 1,
}))
const ActiveWave = styled('div')(() => ({
  position: 'absolute',
  zIndex: 1,
  height: '100%',
}))
const PreventClick = styled('div')(() => ({
  position: 'absolute',
  zIndex: 2,
  height: '100%',
}))

type AudioWavesProps = {
  wavesurfer: any
  audioFile: any
  audioPeaks: number[]
  audioPeaksSelection?: number[]
  region: RegionType
  duration: number
  wavesHeight?: number
  onPosChange?: any
  onClickWaves?: any
  onWavesReady?: any
  waveOptionsActive?: AudioWavesBaseOptionTypes
  waveOptionsDisabled?: AudioWavesBaseOptionTypes
  preventWaveClick?: boolean
}

const sharedOptions = {
  barHeight: 1,
  barGap: 2,
  barWidth: 2,
  cursorWidth: 0,
  cursorColor: '#000000',
  hideScrollbar: true,
  responsive: true,
}

export const AudioWaves = ({
  wavesurfer,
  audioFile,
  wavesHeight = 80,
  audioPeaks,
  audioPeaksSelection,
  region,
  duration,
  onClickWaves,
  onWavesReady,
  waveOptionsActive,
  waveOptionsDisabled,
  preventWaveClick = false,
}: AudioWavesProps) => {
  const { start, end } = region
  const percentageStart = (start / duration) * 100
  const percentageEnd = (end / duration) * 100
  const indexStart = Math.floor((audioPeaks?.length * percentageStart) / 100)
  const indexEnd = Math.floor((audioPeaks?.length * percentageEnd) / 100)

  const peaksSelection =
    audioPeaksSelection || audioPeaks?.slice(indexStart, indexEnd) || []

  const nullPeaks: number[] = []
  for (let i = 0; i < indexEnd - indexStart; i++) nullPeaks.push(0)

  const peaksDisabled =
    [
      ...audioPeaks?.slice(0, indexStart),
      ...nullPeaks,
      ...audioPeaks?.slice(indexEnd, audioPeaks.length),
    ] || []

  const handleWaveformReady = (wavesurfer: any) => {
    onWavesReady && onWavesReady(wavesurfer)
  }
  const optionsActive = {
    ...sharedOptions,
    height: wavesHeight,
    progressColor: '#fd7411',
    waveColor: '#4a4e60',
  }
  const optionsDisabled = {
    ...sharedOptions,
    height: wavesHeight,
    progressColor: '#D1D6DA',
    waveColor: '#D1D6DA',
  }
  return (
    <Wave>
      {preventWaveClick && (
        <PreventClick
          onClick={onClickWaves}
          style={{
            left: `${percentageStart}%`,
            right: `${100 - percentageEnd}%`,
          }}
        />
      )}
      <ActiveWave
        onClick={onClickWaves}
        style={{
          left: `${percentageStart}%`,
          right: `${100 - percentageEnd}%`,
        }}
      >
        <AudioWavesBase
          wavesurfer={wavesurfer}
          onWaveformReady={handleWaveformReady}
          audioFile={audioFile}
          audioPeaks={peaksSelection}
          options={{ ...optionsActive, ...waveOptionsActive }}
        />
      </ActiveWave>
      <AudioWavesBase
        audioFile={undefined}
        wavesurfer={wavesurfer}
        audioPeaks={peaksDisabled}
        options={{ ...optionsDisabled, ...waveOptionsDisabled }}
      />
    </Wave>
  )
}

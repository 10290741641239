import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone, FileRejection } from 'react-dropzone'

import { Typography, Box, LinearProgress, styled } from '@mui/material'
import { UseFormReturn, Controller } from 'react-hook-form'
import ReplayIcon from '@mui/icons-material/Replay'
import { useFilesUpload } from '../../hooks/useFilesUpload'
import { FileChips } from '../FileChips'
import { IconButton } from '../IconButton'
import { bytesConvert } from '../../utils/bytesConvert'
import { FileWithID } from '../Form/formTypes'

const UploadContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'error',
})<any>(({ error, theme }) => ({
  ...(error && {
    borderColor: theme.palette.error.main,
  }),
  padding: '8px 16px 6px 12px',
  minHeight: 48,
  background: `${theme.palette.background.paper}`,
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${
    theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.23)'
      : 'rgba(255, 255, 255, 0.23)'
  }`,
  '&:hover': {
    borderColor: `${theme.palette.text.primary}`,
    cursor: 'pointer',
  },
  disabledContainer: {
    borderColor: theme.palette.divider,
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.action.disabledBackground,
  },
}))

type FileInputProps = {
  /** Translations for labels. */
  labels?: FileInputLabels
  /** Label shown to the user in the input */
  label: string
  /** A function that handles the files upload errors. */
  onError: (props: { error: any; snackbarMessage?: string }) => void
  /** Firebase profile of user who is uploading the files. */
  profile: any
  /** State value and update function provided by Form which keeps track of files upload/delete. */
  fileChangesState: Array<any>
  /** Name of the input. */
  name: string
  /** formObject provided by Form. */
  formObject: UseFormReturn
  /** Max size of the files allowed (in bytes). */
  maxSize?: number
  /** Path where the files in storage and the reference doc in Firestore should be saved. */
  filesStorePath: string
  /** Max number of files allowed. */
  maxFiles?: number
  /** Which file extentions are allowed? */
  acceptedFileTypes?: string[]
  /** Is it required to upload a file? */
  required?: boolean
  /** Disable fileinput, so no files can be uploaded */
  disabled?: boolean
  /** Extra custom data which should be added  */
  customDocumentData?: any
  /** Set fileID instead of generated ID for the file */
  fileID?: string
  /** When true, a firestore document with the file data on the same path will also be saved. */
  firestoreDoc?: boolean
  /** DEPRECATED: doc extension which wel be added to all the keys of the firestore document */
  docExtension?: string
}

type FileInputLabels = {
  alreadyAdded: string
  maxFiles: (number: number) => string
  invalidFileType: string
  fileTooLarge: (maxSize: number) => string
  requiredField: string
  releaseToUpload: string
  dragOrSelectFiles: string
  undoDelete: string
  deletedFiles: string
  filesWillBeDeleted: string
  disabledWarning: string
}

const defaultLabels: FileInputLabels = {
  alreadyAdded: 'is al toegevoegd.',
  maxFiles: number => `Maximaal ${number} bestand${number > 1 ? 'en' : ''}.`,
  invalidFileType: `verkeerd bestandstype, kies een correct bestandstype`,
  fileTooLarge: maxSize =>
    `is te groot, uploaden bestanden tot ${Math.floor(maxSize)} mb.`,
  requiredField: 'Verplicht veld',
  releaseToUpload: 'Upload bestanden door los te laten',
  dragOrSelectFiles: 'Sleep bestanden naar upload of klik en selecteer',
  undoDelete: 'Terug zetten',
  deletedFiles: 'Verwijderde bestanden:',
  filesWillBeDeleted:
    'Deze bestanden zullen definitief verwijderd worden bij het opslaan.',
  disabledWarning: 'Veld om bestanden te uploaden is uitgeschakeld',
}

export const NEWFileInput = ({
  labels = defaultLabels,
  label,
  onError,
  profile,
  fileChangesState,
  name,
  maxSize = 25 * 1024 * 1024,
  maxFiles = 10,
  filesStorePath,
  acceptedFileTypes,
  formObject,
  required,
  disabled = false,
  customDocumentData,
  docExtension,
  fileID,
  firestoreDoc,
}: FileInputProps) => {
  const {
    setValue,
    watch,
    formState: { errors: formErrors },
  } = formObject
  const [errors, setErrors] = useState<String[]>([])
  const files: FileWithID[] = watch(name)
  const { uploadStatus, uploadFiles } = useFilesUpload({
    onError,
    profile,
  })

  const [fileChanges, setFileChanges] = fileChangesState

  const filesDeleted = fileChanges.filesToDelete.filter(
    file => file.inputName === name,
  )

  const handleDrop = useCallback(
    async droppedFiles => {
      if (disabled) {
        setErrors([labels.disabledWarning])
        return
      }

      setFileChanges(state => ({
        ...state,
        uploadingFilesFor: [...state.uploadingFilesFor, name],
      }))

      const newErrors: String[] = []
      const approvedFiles: FileWithID[] = []
      const totalFiles: FileWithID[] = [...files]

      const nameExists = (file, totalFiles) => {
        return totalFiles
          .map(file => file.customMetadata?.originalName || file.name)
          .some(name => name === file.name)
      }

      droppedFiles.forEach(file => {
        if (nameExists(file, totalFiles)) {
          newErrors.push(`${file.name} ${labels.alreadyAdded}`)
        } else if (totalFiles.length === maxFiles) {
          newErrors.push(labels.maxFiles(maxFiles))
        } else {
          approvedFiles.push(file)
          totalFiles.push(file)
        }
      })
      setErrors(newErrors)

      const filesUploaded = await uploadFiles({
        customDocumentData,
        addedFiles: approvedFiles,
        filesStorePath,
        docExtension,
        fileID,
        firestoreDoc,
      })

      setFileChanges(state => ({
        ...state,
        uploadingFilesFor: state.uploadingFilesFor.filter(val => val !== name),
        filesUploaded: [...state.filesUploaded, ...filesUploaded],
      }))
      setValue(name, [...filesUploaded, ...files], {
        shouldValidate: true,
        shouldDirty: true,
      })
    },
    [
      disabled,
      setFileChanges,
      files,
      filesStorePath,
      uploadFiles,
      setValue,
      name,
      labels,
      maxFiles,
      customDocumentData,
      docExtension,
      fileID,
      firestoreDoc,
    ],
  )

  const handleDropRejected = useCallback(
    (files: FileRejection[]) => {
      const errorCodes = {
        'too-many-files': labels.maxFiles(maxFiles),
        'file-too-large': labels.fileTooLarge(maxSize),
        'file-invalid-type': labels.invalidFileType,
      }
      const fileErrorMessage = (file: FileRejection) => {
        const filename = file.file.name
        const errorCode = file.errors[0].code
        return `${filename}: ${errorCodes[errorCode]}`
      }
      const errorsArray: any[] = []
      files.forEach(file => {
        const error = file.errors[0]
        if (error.code) {
          const errorMessage = fileErrorMessage(file)
          if (!errorsArray.includes(errorMessage)) {
            errorsArray.push(errorMessage)
          }
        }
      })
      setErrors(errorsArray)
    },
    [labels, maxSize, maxFiles],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDragEnter: undefined,
    onDragOver: undefined,
    onDragLeave: undefined,
    onDrop: handleDrop,
    onDropRejected: handleDropRejected,
    maxFiles,
    maxSize,
    accept: acceptedFileTypes,
    multiple: maxFiles !== 1,
  })

  const onDelete = fileToDelete => {
    setFileChanges(state => ({
      ...state,
      filesToDelete: [
        ...state.filesToDelete,
        { inputName: name, ...fileToDelete },
      ],
    }))

    const newFiles = files.filter(
      (file: FileWithID) => file.fileID !== fileToDelete.fileID,
    )

    setValue(name, newFiles, { shouldDirty: true })
  }

  const undoDelete = fileToReset => {
    setFileChanges(state => {
      return {
        ...state,
        filesToDelete: state.filesToDelete.filter(
          file => fileToReset.fileID !== file.fileID,
        ),
      }
    })
    delete fileToReset.inputName
    setValue(name, [...files, fileToReset], { shouldDirty: true })
  }

  useEffect(() => {
    if (formErrors?.[name]) {
      if (formErrors?.[name]?.type === 'required') {
        setErrors([labels.requiredField])
      }
    }
  }, [formErrors, labels.requiredField, name])

  return (
    <Controller
      name={name}
      control={formObject.control}
      defaultValue={[]}
      render={() => (
        <Box mb={1}>
          <UploadContainer error={errors} {...getRootProps()}>
            <Box height={48}>
              <Typography
                variant='body1'
                color={errors?.[name] ? 'error' : 'textSecondary'}
              >
                {label}
                {required && ' *'}
              </Typography>
              <Typography
                variant='caption'
                color={errors?.[name] ? 'error' : 'textSecondary'}
              >
                {isDragActive
                  ? labels.releaseToUpload
                  : labels.dragOrSelectFiles}
              </Typography>
            </Box>
            <input id={name} {...getInputProps()} disabled={disabled} />
          </UploadContainer>

          {errors?.map((error, index) => (
            <Typography
              variant='caption'
              color='error'
              key={index}
              style={{ marginLeft: 12 }}
            >
              {error}
            </Typography>
          ))}
          {files?.length > 0 && (
            <Box mt={1}>
              <FileChips
                files={files}
                onDelete={onDelete}
                fileDownloadLinkKey='fullPath'
              />
            </Box>
          )}
          {filesDeleted.length > 0 && (
            <Box marginTop={1}>
              <Typography>{labels.deletedFiles}</Typography>

              {filesDeleted.map(file => (
                <Typography key={file.fileID} component='div' variant='caption'>
                  - {file.name}{' '}
                  <IconButton
                    label={labels.undoDelete}
                    size='small'
                    onClick={() => undoDelete(file)}
                  >
                    <ReplayIcon fontSize='small' />
                  </IconButton>
                </Typography>
              ))}
              <Typography component='div' variant='caption'>
                {labels.filesWillBeDeleted}
              </Typography>
            </Box>
          )}

          {Object.entries(uploadStatus).map(
            ([fileID, { fileName, snap }]: any) => (
              <Box key={fileID} marginTop={2} width='100%'>
                <Typography>{fileName}</Typography>
                <LinearProgress
                  variant='determinate'
                  value={Math.round(
                    (snap?.bytesTransferred / snap?.totalBytes) * 100,
                  )}
                />
                <Typography variant='caption'>
                  {bytesConvert(snap?.bytesTransferred)} /
                  {bytesConvert(snap?.totalBytes)}
                </Typography>
              </Box>
            ),
          )}
        </Box>
      )}
    />
  )
}

import React from 'react'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { useParams } from 'react-router-dom'
import { Typography, Grid, styled } from '@mui/material'
import { TextField, AutoSelect, Form, FormRow, DatePicker } from '@fivano/core'
import { AttachFile } from '@mui/icons-material'
import TodayIcon from '@mui/icons-material/Today'
import MessageIcon from '@mui/icons-material/Message'
import PersoonAfwezig from 'app/icons/PersoonAfwezig'
import { useTrackLocationOptions } from 'hooks/useTrackLocationOptions'
import { PersonAvatar } from '../Persons/PersonAvatar'
import { RouteTypes } from 'types/RouteTypes'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { useDataStandards } from 'hooks/useDataStandards'
import { TrackType } from 'types/Track'
import {
  SickReportingBuildTypes,
  SickReportingDatastandardTypes,
  SickReportingLocationTypes,
  SickReportingTypes,
} from './SickReportingTypes'
import { personFullName } from 'model/person'
import { FileUpload } from 'app/components/FileUpload'

const AvatarColumnGridStyled = styled(Grid)(({ theme }) => ({
  width: 60,
  color: theme.palette.grey[500],
}))

export const SickReportingForm = ({
  person,
  onCloseForm,
  wrapInside,
  modalMaxWidth,
  editingDocID,
}) => {
  const firestore = useFirestore()
  const errorLogger = useErrorLogger()
  const createDataStandards = useDataStandards()
  const { participantID } = useParams<RouteTypes>()
  const profile = useSelector(state => state.firebase.profile)

  const trackOptions = useTrackLocationOptions({
    participantID,
    onlyActiveTracks: true,
  })

  const reportData: SickReportingTypes = useSelector(
    state =>
      state.firestore.data?.[`persons_${participantID}_sickReporting`]?.[
        editingDocID
      ],
  )
  const personTracks = useSelector(
    ({ firestore: { ordered } }) => ordered[`persons_${participantID}_tracks`],
  )
  const sickReportingFormConfig = {
    docLabel: 'Ziekteverzuim',
    defaultValues: {
      personIDSick: '',
      creatorFirstNameSick: '',
      creatorMiddleNameSick: '',
      creatorLastNameSick: '',
      trackSick: trackOptions.length === 1 ? trackOptions[0] : null,
      textSick: '',
      locationSick: { value: '', label: '' },
      startDateTimeSick: null,
      endDateTimeSick: null,
    },
  }
  const buildSickReportData = (
    data: SickReportingBuildTypes & SickReportingLocationTypes,
  ) => {
    data.personIDSick = participantID

    const dataStandards: SickReportingDatastandardTypes = createDataStandards({
      data,
      dataName: 'Sick',
      editForm: !!editingDocID,
    })
    data = { ...data, ...dataStandards }

    const trackSick: TrackType = personTracks.find(
      track => data.trackSick.value === track.id,
    )
    data.locationSick = trackSick.locationTrack
    data.locationIDSick = trackSick.locationTrack.value
    return data
  }

  const updateSickReportData = async data => {
    await firestore.update(
      `locations/${data.locationIDSick}/persons/${participantID}/sickReporting/${editingDocID}`,
      data,
    )
  }

  const createSickReportData = async data => {
    await firestore.add(
      `locations/${data.locationIDSick}/persons/${participantID}/sickReporting`,
      data,
    )
  }

  return (
    <Form
      docLabel={sickReportingFormConfig.docLabel}
      editingDocID={editingDocID}
      buildDocData={buildSickReportData}
      updateDoc={updateSickReportData}
      createDoc={createSickReportData}
      hasDetails={false}
      docData={
        !!editingDocID ? reportData : sickReportingFormConfig.defaultValues
      }
      wrapInside={wrapInside}
      modalMaxWidth={modalMaxWidth}
      onCloseForm={onCloseForm}
      onError={errorLogger}
      deleteFirestoreDoc
      formInputs={({ formObject, fileChangesState }) => {
        const trackSick = formObject.watch('trackSick')
        const selectedTrack = personTracks.find(
          track => trackSick?.value === track.id,
        )
        const filePath = `locations/${selectedTrack?.locationIDTrack}/persons/${participantID}/documents`
        return (
          <Grid container spacing={2}>
            <AvatarColumnGridStyled item>
              <PersonAvatar
                locationID='publicLocation'
                personID={participantID}
              />
            </AvatarColumnGridStyled>
            <Grid item width='calc(100% - 64px)' container spacing={1}>
              <Grid item xs={12}>
                <Typography variant='body1'>
                  {personFullName(person)} ziek melden.
                </Typography>
              </Grid>
            </Grid>

            <FormRow icon={<PersoonAfwezig />}>
              <AutoSelect
                name='trackSick'
                label='Ziekmelding traject'
                formObject={formObject}
                disabled={!!editingDocID}
                rules={{
                  required:
                    'Kies een traject waar de voortgang aan wordt gekoppeld',
                }}
                options={trackOptions}
                disableCloseOnSelect={false}
              />
            </FormRow>
            <FormRow icon={<TodayIcon />}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <DatePicker
                    name='startDateTimeSick'
                    label='Vanaf'
                    formObject={formObject}
                    rules={{ required: 'Verplicht veld' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    name='endDateTimeSick'
                    label='Tot en met'
                    formObject={formObject}
                    {...(!editingDocID && { minDate: new Date() })}
                  />
                </Grid>
              </Grid>
            </FormRow>
            <FormRow icon={<MessageIcon />}>
              <TextField
                name='textSick'
                formObject={formObject}
                multiline
                rows={3}
                label='Opmerking'
              />
            </FormRow>
            <FormRow icon={<AttachFile />}>
              <FileUpload
                name='filesSick'
                label='Voortgang document'
                profile={profile}
                filesStorePath={filePath}
                fileChangesState={fileChangesState}
                onError={errorLogger}
                formObject={formObject}
                firestoreDoc
                docExtension='Docu'
                maxFiles={30}
                customDocumentData={{
                  locationID:
                    selectedTrack?.locationIDTrack || 'publicLocation',
                  personID: participantID,
                  category: 'sickReport',
                }}
              />{' '}
            </FormRow>
          </Grid>
        )
      }}
    />
  )
}

import React, { useMemo } from 'react'
import { Grid, Typography, Hidden } from '@mui/material'
import { useTable } from 'react-table'
import {
  HeaderGridStyled,
  RowBoxStyled,
  RowGridStyled,
  ClickBoxStyled,
  ActionGridStyled,
  ButtonBaseStyled,
} from './ListViewStyles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { routeConstructor } from '../../utils/routeConstructor'
import { Link } from 'react-router-dom'

interface ListViewProps {
  /**Data that comes into the ListView*/
  data: any
  /**Array of element objects which depict the layout of the ListView*/
  columns: any
  /**Function that gets triggered when a ListView item is clicked*/
  onClick?: any
  /** Route to use instead of onClick when row is clicked, enableling open in new tab functionality.
   * Provide a string like: '/locaties/{locationIDPers}/deelnemers/{id}' and routeData:
   *{ locationIDPers: 'abc', id: '123' } returns string '/locaties/abc/deelnemers/123' */
  route?: any
}

export function ListView({ data, columns, onClick, route }: ListViewProps) {
  const listviewData = useMemo(() => data, [data])
  const listviewColums = useMemo(() => columns, [columns])
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: listviewColums,
      data: listviewData,
    })
  return (
    <Grid container {...getTableProps()}>
      <HeaderGridStyled item container>
        {headerGroups.map(headerGroup => (
          <Grid item container {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <Hidden
                key={column.id}
                // @ts-ignore
                {...column.hidden}
              >
                <Grid
                  item
                  {...column.getHeaderProps()}
                  // @ts-ignore
                  {...column.gridProps}
                >
                  <Typography variant='body2'>
                    {column.render('Header')}
                  </Typography>
                </Grid>
              </Hidden>
            ))}
          </Grid>
        ))}
      </HeaderGridStyled>
      <Grid item container {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <RowBoxStyled actionButtons {...row.getRowProps()}>
              <ButtonBaseStyled
                {...(onClick &&
                  !route && {
                    onClick: (event: any) => onClick(event, row),
                  })}
                {...(route && {
                  component: Link,
                  to: routeConstructor({
                    routeLink: route,
                    routeData: row.original,
                  }),
                })}
                disabled={!onClick && !route}
              >
                <RowGridStyled item container alignItems='center'>
                  {row.cells.map(cell => {
                    // @ts-ignore
                    return (
                      <Hidden
                        key={cell.column.id}
                        // @ts-ignore
                        {...cell.column.hidden}
                      >
                        <Grid
                          item
                          {...cell.getCellProps()}
                          // @ts-ignore
                          {...cell.column.gridProps}
                        >
                          {cell.column.id !== 'actions' &&
                            // @ts-ignore
                            (cell.render('Cell').type.name ===
                            'defaultRenderer' ? (
                              <Typography variant='body1' noWrap>
                                {cell.render('Cell')}
                              </Typography>
                            ) : (
                              cell.render('Cell')
                            ))}
                        </Grid>
                      </Hidden>
                    )
                  })}
                </RowGridStyled>
                {onClick && (
                  <ClickBoxStyled>
                    <ChevronRightIcon />
                  </ClickBoxStyled>
                )}
              </ButtonBaseStyled>
              <ActionGridStyled container>
                {row.cells.map(cell => {
                  return (
                    <Hidden
                      key={cell.column.id}
                      // @ts-ignore
                      {...cell.column.hidden}
                    >
                      <React.Fragment>
                        {cell.column.id === 'actions' && cell.render('Cell')}
                      </React.Fragment>
                    </Hidden>
                  )
                })}
              </ActionGridStyled>
            </RowBoxStyled>
          )
        })}
      </Grid>
    </Grid>
  )
}

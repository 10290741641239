import React from 'react'
import {
  Container,
  ContainerProps,
  ContainerTypeMap,
  styled,
} from '@mui/material'

const ContainerStyled = styled(Container, {
  shouldForwardProp: prop =>
    prop !== 'disableGuttersMobile' && prop !== 'classname',
})<{ disableGuttersMobile: boolean }>(({ theme, disableGuttersMobile }) => ({
  height: '100%',
  paddingBottom: 40,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: '64px',
    ...(disableGuttersMobile && {
      paddingLeft: 0,
      paddingRight: 0,
    }),
  },
}))

interface PageContainerProps extends ContainerProps {
  /**Maximum custom width of the PageContainer*/
  maxWidth?: ContainerTypeMap['props']['maxWidth']
  /**Boolean to set the maxWidth to the current breakpoint*/
  fixed?: boolean
  /**Boolean whether there is some padding on the bottom*/
  disableGutters?: boolean
  /**Boolean whether there is padding on the bottom when the screen is on mobile size*/
  disableGuttersMobile?: boolean
}

export function PageContainer(props: PageContainerProps) {
  const {
    maxWidth = 'lg',
    fixed,
    children,
    disableGutters = false,
    disableGuttersMobile = false,
  } = props
  return (
    <ContainerStyled
      fixed={fixed}
      maxWidth={maxWidth}
      disableGutters={disableGutters}
      disableGuttersMobile={disableGuttersMobile}
      {...props}
    >
      {children}
    </ContainerStyled>
  )
}

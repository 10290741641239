import { menuLayoutType } from '../../router/types'
import { servicesConfig } from '@fivano/models'
import { capitalize } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import PersonIcon from '@mui/icons-material/Person'
import DescriptionIcon from '@mui/icons-material/Description'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import LabelIcon from '@mui/icons-material/Label'
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import TimelineIcon from '@mui/icons-material/Timeline'
import AssignmentIcon from '@mui/icons-material/Assignment'
import RoomServiceIcon from '@mui/icons-material/RoomService'
import { MusicNote } from '@mui/icons-material'
import WallpaperIcon from '@mui/icons-material/Wallpaper'
import PaletteIcon from '@mui/icons-material/Palette'
import { settingRoutes } from './settingRoutes'

export const settingsMenu = () => {
  const menu: menuLayoutType[] = [
    {
      name: 'Algemene instellingen',
      icon: SettingsIcon,
      description: 'Algemene instellingen',
      route: settingRoutes.generalSettings,
    },
    {
      name: 'Logo instellingen',
      icon: WallpaperIcon,
      description: 'Applicatie Logo instellen',
      route: settingRoutes.logo,
    },
    {
      name: 'Documenten',
      icon: DescriptionIcon,
      description: 'Nieuwe document categorie',
      route: settingRoutes.documentCategory,
    },
    {
      name: 'Gebruikers',
      icon: PersonIcon,
      description: 'Nieuwe gebruikers',
      route: settingRoutes.userSettings,
    },
    {
      name: 'Gebruikers rollen profielen',
      icon: AccountTreeIcon,
      description: 'Gebruiker toegang',
      route: settingRoutes.userRoleProfile,
    },
    {
      name: 'Verplichte documenten',
      icon: AssignmentTurnedInIcon,
      description: 'Verplichte documenten',
      route: settingRoutes.documentRequired,
    },
    {
      name: 'CRM tags',
      icon: LabelIcon,
      description: 'CRM tags',
      route: settingRoutes.crmBoard,
    },
    {
      name: capitalize(servicesConfig.labels.plural),
      icon: RoomServiceIcon,
      description: 'Categorieën en andere opties.',
      route: settingRoutes.servicesSettings,
    },
    {
      name: 'Voortgang',
      icon: TimelineIcon,
      description: 'Nieuwe voortgang tag',
      route: settingRoutes.progressTags,
    },
    {
      name: 'Deelnemer tags',
      icon: LabelIcon,
      description: 'Nieuwe deelnemer tag',
      route: settingRoutes.personTags,
    },
    {
      name: 'Trajecten',
      icon: DynamicFeedIcon,
      description: 'Traject instellingen',
      route: settingRoutes.trackSettings,
    },
    {
      name: 'Vacature categorieën',
      icon: AssignmentIcon,
      description: 'Categorieën voor vacatures instellen',
      route: settingRoutes.jobOpeningCategorySettings,
    },
    {
      name: 'Genres instellen',
      icon: MusicNote,
      description: 'Genres voor track instellen',
      route: settingRoutes.genres,
    },
    {
      name: 'Keys instellen',
      icon: MusicNote,
      description: 'Genres voor track instellen',
      route: settingRoutes.audioKeys,
    },
    {
      name: 'Werkcodes',
      description: 'Werkcodes voor urenregistratie instellen',
      icon: AssignmentIcon,
      route: settingRoutes.workCodes,
    },
    {
      name: 'Kleur instellingen',
      icon: PaletteIcon,
      description: 'Applicatie kleuren instellen',
      route: settingRoutes.colorSettings,
    },
  ]
  return menu
}

import React from 'react'
import { Box } from '@mui/material'
import useMongo from 'hooks/useMongo'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import {
  FloatingButton,
  PageContainer,
  MenuButton,
  ActionMenu,
} from '@fivano/core'
import EditIcon from '@mui/icons-material/Edit'
import { CourseCompetencesForm } from './CourseCompetencesForm'
import { DataGridNew } from 'app/components/DataGridNew'
import { useModalRoute } from 'hooks/useModalRoutes'

const courseCompetencesColumnsDesktop = [
  {
    field: 'name',
    headerName: 'Competentie',
    width: 160,
    disableColumnMenu: true,
  },
  {
    field: 'description',
    headerName: 'Beschrijving',
    width: 300,
    disableColumnMenu: true,
  },
]

const courseCompetencesColumnsMobile = [
  {
    field: 'name',
    headerName: 'Competentie',
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: 'description',
    headerName: 'Beschrijving',
    width: 50,
    disableColumnMenu: true,
  },
]

export const CourseCompetencesList = () => {
  const { modalData, closeModal, openModal } = useModalRoute()
  const { useQueryDocs } = useMongo()

  const { docsData } = useQueryDocs({
    collection: 'courseCompetences',
    array: true,
  })

  return (
    <PageContainer maxWidth='lg'>
      <Breadcrumbs />
      <Box p={2}>
        {modalData.open && (
          <CourseCompetencesForm
            wrapInside='modal'
            modalMaxWidth='sm'
            onClose={() => {
              closeModal()
            }}
            editingDocID={modalData.id}
          />
        )}
        <Box marginTop={1}>
          <DataGridNew
            name='competences'
            rows={docsData}
            columns={courseCompetencesColumnsDesktop}
            columnsMobile={courseCompetencesColumnsMobile}
            hideFooterRowCount={true}
            onRowClick={rowData => openModal(rowData.row._id)}
            rowActions={{
              renderCell: params => (
                <ActionMenu
                  iconPosition='vertical'
                  minWidth={250}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  <MenuButton
                    label={`Bewerk competentie`}
                    icon={<EditIcon />}
                    onClick={() => openModal(params.id)}
                  />
                </ActionMenu>
              ),
            }}
          />
        </Box>
        <FloatingButton
          label='Competenties toevoegen'
          onClick={() => openModal()}
        />
      </Box>
    </PageContainer>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Paper,
  Box,
  Typography,
  Divider,
  useMediaQuery,
  Button,
} from '@mui/material'
import { startOfWeek, addDays } from 'date-fns'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { FormBlock } from './FormBlock'
import { Header } from './Header'
import { PageContainer } from '@fivano/core'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { useSelector } from 'hooks/useSelector'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { ProjectCodesForm } from './ProjectCodesForm'
import { OptionType } from 'types/CommonTypes'
import { TrackType } from 'types/Track'
import { CodeFormPropsType, ProjectCodeType, TimeItemProps } from './types'
import { pick } from 'utils/pick'

const createFullWeek = currentDate => {
  const week: Date[] = []
  for (var i = 0; i < 7; i++) {
    week.push(addDays(currentDate, i))
  }
  return week
}

export const DurationRegistration = () => {
  const firestore = useFirestore()
  const errorLogger = useErrorLogger()
  const profile = useSelector(state => state.firebase.profile)
  const viewportLG = useMediaQuery('(min-width:1200px)')
  const [selectedWeek, setSelectedWeek] = useState<Date[]>(
    createFullWeek(startOfWeek(new Date(), { weekStartsOn: 1 })),
  )
  const [showSkeleton, setShowSkeleton] = useState<string[] | undefined>(
    undefined,
  )
  const [locationCode, setLocationCode] = useState<OptionType | undefined>(
    undefined,
  )
  const [inputError, setInputError] = useState<string>('')
  const [showWeekend, setShowWeekend] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  // GET TRACKS AND TIMECODES BASED ON LOCATION ID CHANGE
  const [tracks, setTracks] = useState<TrackType[]>([])
  const [loadMoreTracks, setLoadMoreTracks] = useState(false)
  const [projectCodes, setProjectCodes] = useState<
    ProjectCodeType[] | undefined
  >(undefined)

  useFirestoreConnect([
    {
      collection: 'blockData',
      where: [
        ['dataType', '==', 'durationRegistration'],
        ['date', '==', selectedWeek[0]],
        ['locationID', '==', locationCode?.value || 'NOT_SET'],
        ['profile.uid', '!=', profile.uid],
      ],
      storeAs: `blockData`,
    },
    {
      collection: 'locations',
      where: [
        ['hasProjectCodesLoca', '==', true],
        ['archivedLoca', '==', false],
      ],
      storeAs: 'locations',
    },
    {
      collection: 'projectCodes',
      where: [['archived', '==', false]],
      storeAs: 'projectCodes',
    },
  ])
  const getPersonName = async (trackData: TrackType, location) =>
    await firestore
      .get(`/locations/${location.value}/persons/${trackData.personIDTrack}`)
      .then(async response =>
        pick(response.data(), [
          'avatarImagePers',
          'firstNamePers',
          'id',
          'lastNamePers',
          'middleNamePers',
        ]),
      )
  const getTrackDocsPerson = async (location, date, limit, personID) => {
    if (!personID) return
    const tracksWithoutEndDate: TrackType[] = []
    await firestore
      .get({
        collectionGroup: 'tracks',
        where: [
          // @ts-ignore
          ['archivedTrack', '==', false],
          // @ts-ignore
          ['locationIDTrack', '==', location.value],
          // @ts-ignore
          ['endDateTrack', '==', null],
          // @ts-ignore
          ...(personID ? [['personIDTrack', '==', personID]] : []),
        ],
      })
      .then(async (response: any) => {
        await response.docs?.forEach(async doc => {
          const data = doc.data()
          data.id = doc.id
          if (data?.participantTrack?.firstNamePers === undefined) {
            data.participantTrack = await getPersonName(data, location)
          }
          tracksWithoutEndDate.push(data)
        })
      })

    const tracksWithEndDate: TrackType[] = []
    await firestore
      .get({
        collectionGroup: 'tracks',
        where: [
          // @ts-ignore
          ['archivedTrack', '==', false],
          // @ts-ignore
          ['locationIDTrack', '==', location.value],
          // @ts-ignore
          ['endDateTrack', '>', date[0]],
          // @ts-ignore
          ...(personID ? [['personIDTrack', '==', personID]] : []),
        ],
      })
      .then(async (response: any) => {
        await response.docs?.forEach(async doc => {
          const data = doc.data()
          data.id = doc.id
          if (data?.participantTrack?.firstNamePers === undefined) {
            data.participantTrack = await getPersonName(data, location)
          }
          tracksWithEndDate.push(data)
        })
      })
    return [...tracksWithEndDate, ...tracksWithoutEndDate]
  }

  const getTrackDocs = async (location, date, limit) => {
    const tracksWithoutEndDate: TrackType[] = []
    await firestore
      .get({
        collectionGroup: 'tracks',
        where: [
          ['archivedTrack', '==', false],
          ['locationIDTrack', '==', location.value],
          ['endDateTrack', '==', null],
        ],
        limit,
      })
      .then(async (response: any) => {
        await response.docs?.forEach(async doc => {
          const data = doc.data()
          data.id = doc.id
          if (data?.participantTrack?.firstNamePers === undefined) {
            data.participantTrack = await getPersonName(data, location)
          }
          tracksWithoutEndDate.push(data)
        })
      })

    const tracksWithEndDate: TrackType[] = []
    await firestore
      .get({
        collectionGroup: 'tracks',
        where: [
          ['archivedTrack', '==', false],
          ['locationIDTrack', '==', location.value],
          ['endDateTrack', '>', date[0]],
        ],
        limit,
      })
      .then(async (response: any) => {
        await response.docs?.forEach(async doc => {
          const data = doc.data()
          data.id = doc.id
          if (data?.participantTrack?.firstNamePers === undefined) {
            data.participantTrack = await getPersonName(data, location)
          }
          tracksWithEndDate.push(data)
        })
      })
    setTracks([...tracksWithEndDate, ...tracksWithoutEndDate])
    setLoadMoreTracks(
      tracksWithEndDate?.length >= limit ||
        tracksWithoutEndDate?.length >= limit,
    )
  }

  const getProjectCodeDocs = location => {
    firestore
      .get({
        collection: 'projectCodes',
        where: [['location.value', '==', location.value]],
      })
      .then(async (response: any) => {
        const docs: ProjectCodeType[] = response.docs?.map((item: any) => {
          const data = item?.data()
          data.id = item.id
          return data
        })
        setProjectCodes(docs)
      })
  }

  const handleChangeLocation = async location => {
    setLoadingData(true)
    setInputError('')
    if (location) {
      getTrackDocs(location, selectedWeek, 25)
      getProjectCodeDocs(location)
      setLocationCode(location)
    } else {
      setTimeDocs([])
      setTracks([])
      setExpandTrackIDWeek([])
      setExpandTrackID([])
      setLocationCode(undefined)
    }
    setLoadingData(false)
  }
  const handleChangeGroup = async group => {
    setLoadingData(true)
    const groupPersonIDs = await firestore
      .get({
        collection: 'personGroups',
        doc: group.value,
      })
      .then((response: any) => response.data()?.groupPersons?.map(i => i.value))
    const tracksArray: TrackType[] = []
    await Promise.all(
      groupPersonIDs.map(async id => {
        const newTracks = await getTrackDocsPerson(
          locationCode,
          selectedWeek,
          20,
          id,
        )
        newTracks?.length && tracksArray.push(...newTracks)
      }),
    )
    setTracks(tracksArray)
    setLoadingData(false)
  }
  const breadcrumbArray = [
    { title: 'Home', path: '/' },
    { title: 'Urenregistratie', path: '/urenregistratie' },
  ]

  const [docCreate, setDocCreate] = useState<TimeItemProps | undefined>(
    undefined,
  )
  /** Exclude timeDocs as dependency, not necessary to call useEffect when timeDocs changed. Also exclude errorLogger as dependency to avoid infinite loop */
  useEffect(() => {
    if (docCreate) {
      setTimeDocs([...timeDocs, docCreate])
      firestore
        .collection('timeRegistrations')
        .doc(docCreate.id)
        .set(docCreate)
        .catch(error => {
          errorLogger({ error })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docCreate, firestore])

  const [docUpdate, setDocUpdate] = useState<TimeItemProps | undefined>(
    undefined,
  )
  /** Exclude timeDocs as dependency, not necessary to call useEffect when timeDocs changed. Also exclude errorLogger as dependency to avoid infinite loop */
  useEffect(() => {
    if (docUpdate) {
      const timeDocsCopy: any[] = [...timeDocs]
      const index = timeDocsCopy.findIndex(doc => doc.id === docUpdate.id)
      timeDocsCopy[index] = docUpdate
      setTimeDocs(timeDocsCopy)

      firestore
        .collection('timeRegistrations')
        .doc(docUpdate.id)
        .update(docUpdate)
        .then(() => {})
        .catch(error => {
          errorLogger({ error })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docUpdate, firestore])

  const [codesFormProps, setCodesFormProps] = useState<CodeFormPropsType>({
    open: false,
    trackID: undefined,
    personID: undefined,
    docData: undefined,
  })

  const openModal = (trackID, personID) => {
    const projectsByTrackID = tracks.find(
      (track: TrackType) => track.id === trackID,
    )?.personProjectsTrack

    const docData: any = {}
    projectsByTrackID?.forEach(item => {
      docData[`${item.projectCode.value}_${item.workCode.value}`] =
        item.showPreset
    })

    setCodesFormProps({
      open: true,
      trackID,
      personID,
      docData,
    })
  }

  const [timeDocs, setTimeDocs] = useState<TimeItemProps[]>([])
  const getTimeDocs = async (id, week) => {
    return new Promise((resolve, reject) => {
      firestore
        .get({
          collection: 'timeRegistrations',
          where: [
            ['trackID', '==', id],
            ['type', '==', 'durationRegistration'],
            ['time', '>=', week[0]],
            ['time', '<', week[6]],
          ],
        })
        .then(async (response: any) => {
          const docs = await response.docs?.map(doc => {
            const data = doc.data()
            data.id = doc.id
            if (data.time?.seconds)
              data.time = new Date(data.time.seconds * 1000)
            return data
          })
          resolve(docs)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const [expandTrackIDWeek, setExpandTrackIDWeek] = useState<string[]>([])
  const [expandTrackID, setExpandTrackID] = useState<string[]>([])
  const handleExpand = async (trackID, expanded) => {
    if (expanded) {
      setShowSkeleton([trackID])
      setExpandTrackID([...expandTrackID, trackID])

      if (!expandTrackIDWeek.find(id => id === trackID)) {
        setExpandTrackIDWeek([...expandTrackIDWeek, trackID])
      }

      if (!expandTrackIDWeek.find(id => id === trackID)) {
        const copyTimeDocs = [...timeDocs]
        await Promise.all(
          [trackID].map(async id => {
            await getTimeDocs(id, selectedWeek).then((response: any) => {
              response.forEach(doc => {
                copyTimeDocs.push(doc)
              })
            })
          }),
        )
        setTimeDocs(copyTimeDocs)
      }
      setShowSkeleton(undefined)
    } else {
      const copyExpandedTracks = [...expandTrackID]
      const index = copyExpandedTracks.findIndex(id => id === trackID)
      copyExpandedTracks.splice(index, 1)
      setExpandTrackID(copyExpandedTracks)
    }
  }

  const handleExpandAll = async (trackIDs, date) => {
    setExpandTrackIDWeek(expandTrackID)
    setShowSkeleton(trackIDs)
    const copyTimeDocs: TimeItemProps[] = []
    await Promise.all(
      trackIDs.map(async id => {
        await getTimeDocs(id, date).then((response: any) => {
          response.forEach(doc => {
            copyTimeDocs.push(doc)
          })
        })
      }),
    )
    setTimeDocs(copyTimeDocs)
    setShowSkeleton(undefined)
  }

  return (
    <PageContainer maxWidth='lg'>
      <Breadcrumbs breadcrumbs={breadcrumbArray} />
      <Paper>
        <Box p={2}>
          <Header
            loading={loadingData}
            onShowWeekend={() => setShowWeekend(!showWeekend)}
            onChangeLocation={handleChangeLocation}
            onChangeGroup={handleChangeGroup}
            onChangeDate={date => {
              if (date) {
                const week = createFullWeek(
                  startOfWeek(date, { weekStartsOn: 1 }),
                )
                setSelectedWeek(week)
                handleExpandAll(expandTrackID, week)
              }
            }}
          />
        </Box>

        {codesFormProps.open && (
          <ProjectCodesForm
            projectCodes={projectCodes}
            trackID={codesFormProps.trackID}
            locationCode={locationCode}
            personID={codesFormProps.personID}
            docData={codesFormProps.docData}
            onClose={() => {
              setCodesFormProps({
                open: false,
                trackID: undefined,
                personID: undefined,
                docData: undefined,
              })
            }}
            onSubmitSuccess={() => {
              handleChangeLocation(locationCode)
            }}
          />
        )}

        <Box py={2}>
          {locationCode && tracks.length > 0 ? (
            <>
              <FormBlock
                tracks={tracks}
                timeDocs={timeDocs}
                selectedWeek={selectedWeek}
                showWeekend={showWeekend}
                showSkeleton={showSkeleton}
                onHandleExpand={handleExpand}
                onCreateDoc={setDocCreate}
                onUpdateDoc={setDocUpdate}
                onOpenModal={openModal}
                viewportLG={viewportLG}
                onHandleErrorInput={setInputError}
                inputError={inputError}
              />
              {loadMoreTracks && (
                <Button
                  fullWidth
                  sx={{ marginTop: 2 }}
                  onClick={() => getTrackDocs(locationCode, selectedWeek, 300)}
                >
                  Alle personen laden
                </Button>
              )}
            </>
          ) : (
            <>
              <Divider />
              <Box p={2}>
                <Typography>
                  {!locationCode && tracks.length === 0
                    ? 'Selecteer een locatie'
                    : 'Er zijn geen tracks gekoppeld aan deze locatie'}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </PageContainer>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Card, ButtonBase, Typography, styled } from '@mui/material'

const ButtonBaseStyled = styled(ButtonBase)<any>(({ theme }) => ({
  minHeight: 110,
  width: '100%',
  padding: '4px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}))

type ShortcutCardProps = {
  label: String
  path: any
  icon?: any
}

export const ShortcutCard = (props: ShortcutCardProps) => {
  const { label, path, icon } = props
  const Icon = icon
  return (
    <Card>
      <ButtonBaseStyled component={Link} to={path}>
        <Grid container justifyContent='center' spacing={1}>
          {icon !== undefined && (
            <Grid item>
              <Icon fontSize='large' color='primary' />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant='body1' align='center'>
              {label}
            </Typography>
          </Grid>
        </Grid>
      </ButtonBaseStyled>
    </Card>
  )
}

import React, { useState } from 'react'
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form'
import {
  FormGroup,
  Typography,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  styled,
} from '@mui/material'
import { OptionType } from '../../types/CommonTypes'
import { ErrorMessage } from '@hookform/error-message'

const FormControlLabelStyled = styled(FormControlLabel, {
  shouldForwardProp: prop => prop !== 'outlined' && prop !== 'isChecked',
})<any>(({ theme, isChecked, outlined }) => ({
  ...(outlined && {
    ...(isChecked && {
      border: `1px solid ${theme.palette.primary.main}`,
    }),
    width: '100%',
    marginBottom: 2,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
  }),
}))

const TypographyStyled = styled(Typography, {
  shouldForwardProp: prop => prop !== 'hasError',
})<any>(({ hasError, theme }) => ({
  marginLeft: 16,
  fontSize: '0.75rem',
  width: '100%',
  ...(hasError && {
    color: theme.palette.error.main,
  }),
}))

type RadioGroupProps = {
  /**Visible label */
  label?: string
  /**Name of the RadioGroup*/
  name: string
  /**Array of option objects*/
  options: OptionType[]
  /**Boolean whether the direction of the RadioGroup is row or not*/
  row?: boolean
  /**Object with all the form methods from react-hook-form.*/
  formObject: UseFormReturn<any>
  /**Rules the RadioGroup has to abide to*/
  rules?: RegisterOptions
  /**Shown text when an error occurs*/
  helperText?: string
  /**Boolean if the RadioGroup has an outline style*/
  outlined?: boolean
}
export const RadioGroup = ({
  label,
  name,
  options,
  row,
  formObject,
  rules,
  helperText,
  outlined,
}: RadioGroupProps) => {
  const {
    control,
    formState: { errors },
  } = formObject
  const [checkedValues, setCheckedValues] = useState<OptionType>(
    formObject.getValues(name) || { value: '', label: '' },
  )
  const hasError = !!errors?.[name]
  row &&
    outlined &&
    console.warn(
      'Cannot use both row and outlined props in RadioGroup at the same time',
    )
  return (
    <FormControl>
      <FormGroup row={row}>
        {label && <FormLabel component='legend'>{label}</FormLabel>}
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={null}
          render={({ field: { onChange } }): any => {
            const handleCheck = (option: OptionType) => {
              onChange(option)
              setCheckedValues(option)
            }
            return options.map(option => {
              const isChecked = checkedValues.value === option.value
              return (
                <FormControlLabelStyled
                  key={option.label}
                  label={option.label}
                  value={option}
                  control={
                    <Radio
                      color='primary'
                      onChange={() => handleCheck(option)}
                      checked={isChecked}
                      required={!!rules?.required}
                    />
                  }
                />
              )
            })
          }}
        />

        <TypographyStyled
          variant='caption'
          color={hasError ? 'error' : 'inherit'}
        >
          {hasError ? <ErrorMessage name={name} errors={errors} /> : helperText}
        </TypographyStyled>
      </FormGroup>
    </FormControl>
  )
}

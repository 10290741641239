import { toDate } from 'date-fns'

export const convertTimestampDate = (date: any) => {
  if (date === undefined) {
    return null
  } else if (date instanceof Date) {
    return date
  } else if (date === null) {
    return null
  } else if (date?.seconds !== undefined) {
    return toDate(date.seconds * 1000)
  } else {
    return date
  }
}

import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'hooks/useSelector'
import { isEmpty, isLoaded, useFirestore } from 'react-redux-firebase'
import { AuthWrapper } from './containers/Authentication/components/AuthWrapper'
import { useSettings } from 'hooks/useSettings'
import { LoadingOverlay } from '@fivano/core'
import { UserAccount } from './legacy/Authentication'

export const PrivateRoute = ({ children, path, claims, ...rest }) => {
  const firestore = useFirestore()
  const auth = useSelector(state => state.firebase.auth)
  const profile = useSelector(state => state.firebase.profile)
  const settings = useSettings(true)
  const settingsLoad: any = useSelector(
    state => state.firestore.ordered.settings,
  )
  const [settingsLoaded, setSettingsLoaded] = useState(false)

  // Load appSettings/settings, only when it is loaded set settingLoaded to true (it is possible that appSettings/settings does not exists)
  useEffect(() => {
    firestore
      .get({ collection: 'appSettings', doc: 'settings', storeAs: 'settings' })
      .then(() => {
        setSettingsLoaded(true)
      })
      .catch(() => {
        setSettingsLoaded(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Route
      {...rest}
      exact
      path={path}
      render={({ location }) => {
        // If auth, profile and settings (when available) are loaded
        if (
          isLoaded(auth) &&
          isLoaded(profile) &&
          (isLoaded(settingsLoad) || settingsLoaded)
        ) {
          // If user is not authenticated and path is not already defaultSignInPath
          if (
            isEmpty(auth) &&
            location.pathname !== settings.auth.defaultSignInPath
          ) {
            return (
              <Redirect
                to={{
                  pathname: settings.auth.defaultSignInPath,
                  state: { from: location },
                }}
              />
            )
          }
          // If auth and profile are both loaded and not empty show container of route
          else if (
            !isEmpty(auth) &&
            !(profile.firstNamePers || profile.lastNamePers)
          ) {
            return <UserAccount />
          }
          // If auth and profile are both loaded and not empty show container of route
          else if (
            !isEmpty(auth) &&
            !isEmpty(profile) &&
            profile.firstNamePers &&
            profile.lastNamePers
          ) {
            return children
          }
          // If user is authenticated but does not have a profile navigate to UserAccount to create new profile
          else if (
            location.pathname !== '/account-controle' &&
            isLoaded(auth) &&
            isLoaded(profile) &&
            (isEmpty(profile) ||
              !profile.firstNamePers ||
              !profile.lastNamePers)
          ) {
            return (
              <Redirect
                to={{
                  pathname: '/account-controle',
                  state: { from: location },
                }}
              />
            )
          }
        }
        // If auth, profile and settings are loading show loading screen
        else {
          return (
            <AuthWrapper title='Account authenticeren'>
              <LoadingOverlay label='Bezig uw account te authenticeren. Een moment geduld.' />
            </AuthWrapper>
          )
        }
      }}
    />
  )
}

import React from 'react'
import { ButtonProps, styled } from '@mui/material'
import Popover, { PopoverOrigin } from '@mui/material/Popover'
import { MoreVert } from '@mui/icons-material'

import { IconButton } from '../IconButton'
import { firestoreIDGenerator } from '../../utils/firestoreIDGenerator'

type StyleTypes = {
  /**Minimal width of the popover*/
  minWidth?: number | string
  /**Maximum width of the popover*/
  maxWidth?: number | string
  /**Padding around the icon*/
  menuPadding?: number | string
}

const PopoverStyled = styled(Popover)(() => ({
  paper: { maxWidth: 'auto', width: 'auto' },
}))

const MenuItemsStyled = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'minWidth' && prop !== 'maxWidth' && prop !== 'menuPadding',
})<StyleTypes>(({ minWidth, maxWidth, menuPadding }) => ({
  minWidth: minWidth || 'auto',
  maxWidth: maxWidth || 'auto',
  padding: menuPadding || '0px',
}))

type ActionMenuProps = {
  /**Children elements of the component*/
  children: React.ReactNode
  /**The direction of the icon relative to the page*/
  iconPosition?: 'horizontal' | 'vertical' | undefined
  /**Point where the popover's anchorEl, will be attached to*/
  anchorOrigin?: PopoverOrigin
  /**This is the point on the popover which will attach to the anchor's origin */
  transformOrigin?: PopoverOrigin
  /**Custom button instead of default icon*/
  customButton?: ButtonProps
  /**Disabled the scrolling of the popover*/
  disableScrollLock?: boolean
  /**Id name of the component*/
  idIconButton?: string
} & StyleTypes

export const ActionMenu = ({
  children,
  iconPosition = 'horizontal',
  anchorOrigin = {
    horizontal: 'right',
    vertical: 'bottom',
  },
  transformOrigin = { horizontal: 'right', vertical: 'top' },
  customButton,
  minWidth,
  maxWidth,
  menuPadding = '8px 0px',
  disableScrollLock,
  idIconButton,
}: ActionMenuProps) => {
  // ==== OPEN SETTINGS ==== //
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const openFilter = Boolean(anchorEl)
  const menuID = openFilter ? firestoreIDGenerator() : undefined

  return (
    <>
      {customButton ? (
        <div onClick={e => handleClick(e)}>{customButton}</div>
      ) : (
        <IconButton
          style={{
            transform:
              iconPosition === 'horizontal' ? 'rotate(90deg)' : 'rotate(0deg)',
          }}
          label='Meer'
          onClick={e => {
            e.stopPropagation()
            handleClick(e)
          }}
          id={idIconButton}
          size='large'
        >
          <MoreVert />
        </IconButton>
      )}
      <PopoverStyled
        id={menuID}
        open={openFilter}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disableScrollLock={disableScrollLock}
      >
        <MenuItemsStyled
          minWidth={minWidth}
          maxWidth={maxWidth}
          menuPadding={menuPadding}
          onClick={handleClose}
        >
          {children}
        </MenuItemsStyled>
      </PopoverStyled>
    </>
  )
}

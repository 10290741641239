import React from 'react'
import {
  Card,
  Grid,
  Typography,
  Chip,
  Button,
  Avatar,
  styled,
} from '@mui/material'
import RoomIcon from '@mui/icons-material/Room'
import { IconText, Paragraph } from '@fivano/core'

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    height: 140,
    width: 140,
  },
  height: 200,
  width: 200,
  [theme.breakpoints.up('lg')]: {
    height: 250,
    width: 250,
  },
}))

const ContentGridStyled = styled(Grid)(({ theme }) => ({
  display: 'flex',
  padding: 16,
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100% - 140px)',
    width: 'calc(100% - 140px)',
  },
  height: 'calc(100% - 200px)',
  width: 'calc(100% - 200px)',
  [theme.breakpoints.up('lg')]: {
    height: 'calc(100% - 250px)',
    width: 'calc(100% - 250px)',
  },
}))

const TitleGridStyled = styled(Grid)(() => ({
  display: 'table',
  tableLayout: 'fixed',
  width: '100%',
}))

const TitleTypographyStyled = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const ChipStyled = styled(Chip)(() => ({
  backgroundColor: ' #e6c300',
  margin: '-2px 0 0 8px',
}))

const ParagraphGridStyled = styled(Grid)(() => ({
  minHeight: '7.5em',
}))

const ActionsGridStyled = styled(Grid)(() => ({
  marginTop: 8,
  display: 'flex',
  justifyContent: 'flex-end',
}))

const InfoButtonStyled = styled(Button)(({ theme }) => ({
  marginRight: 8,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

export const JobOpeningCard = ({ data }) => (
  <Grid container item component={Card}>
    <Grid item>
      <AvatarStyled
        variant='square'
        src='https://assets.ray-ban.com/is/image/RayBan/RX_0RX3447V__2945_Optics_Standard_shad_qt?$594$'
        alt=''
      />
    </Grid>
    <ContentGridStyled item container>
      <TitleGridStyled item xs={12}>
        <TitleTypographyStyled variant='h6' gutterBottom>
          {data.nameJoop}
        </TitleTypographyStyled>
      </TitleGridStyled>
      <Grid item>
        <IconText
          icon={<RoomIcon color='disabled' />}
          value={data.locationJoop.label}
        />
      </Grid>
      <Grid item>
        <ChipStyled label='Goud' />
      </Grid>
      <ParagraphGridStyled item xs={12}>
        <Paragraph lines={2} variant='body1'>
          {data.descriptionJoop}
        </Paragraph>
      </ParagraphGridStyled>

      <ActionsGridStyled item xs={12}>
        <InfoButtonStyled>Meer info</InfoButtonStyled>
        <Button disableElevation color='primary' variant='contained'>
          Aanmelden
        </Button>
      </ActionsGridStyled>
    </ContentGridStyled>
  </Grid>
)

import React from 'react'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'

import { Grid } from '@mui/material'
import { Subject } from '@mui/icons-material'
import TitleIcon from '@mui/icons-material/Title'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import CategoryIcon from '@mui/icons-material/Category'

import { TextField, FormRow, Form, AutoSelect } from '@fivano/core'
import { AutoComplete } from 'app/components/AutoComplete'

import { useSelector } from 'hooks/useSelector'
import { useDataStandards } from 'hooks/useDataStandards'
import { optionsCreator } from 'utils/optionsCreator'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { OptionType } from 'types/CommonTypes'
import {
  JobOpeningBuildTypes,
  JobOpeningDatastandardTypes,
  JobOpeningFormTypes,
} from './JobOpeningTypes'

export const JobOpeningForm = ({
  onCloseForm,
  wrapInside,
  modalMaxWidth,
  editingDocID,
}) => {
  const firestore = useFirestore()
  const isEditing = !!editingDocID

  const vacancyData: JobOpeningFormTypes = useSelector(
    ({ firestore: { data } }) => {
      if (editingDocID) {
        return data.vacancies?.[editingDocID]
      }
    },
  )

  useFirestoreConnect([
    {
      collection: 'appSettings',
      doc: 'jobOpeningCategories',
      storeAs: 'categories',
    },
  ])

  const categoryOptions: Array<OptionType> = useSelector(state =>
    optionsCreator(
      state.firestore.ordered['categories']?.[0]?.options,
      'nameJoca',
      'idJoca',
    ),
  )
  const vacanciesFormConfig = {
    docLabel: 'Vacature',
    defaultValues: {
      descriptionJoop: '',
      nameJoop: '',
      archivedJoop: false,
    },
  }

  const createDataStandards = useDataStandards()

  const buildVacanyData = (data: JobOpeningBuildTypes) => {
    const dataStandards: JobOpeningDatastandardTypes = createDataStandards({
      data,
      dataName: 'Joop',
      editForm: isEditing,
      keywords: [data.nameJoop],
    })
    const newDataArray: string[] = []
    data.categoriesJoop.forEach(item => newDataArray.push(item.value))
    data.categoriesIDsJoop = newDataArray

    return { ...data, ...dataStandards }
  }

  const updateModule = async data => {
    await firestore.update(`jobOpenings/${editingDocID}`, data)
  }

  const createModule = async data => {
    await firestore.add('jobOpenings', data)
  }
  const errorLogger = useErrorLogger()

  return (
    <Form
      docLabel={vacanciesFormConfig.docLabel}
      editingDocID={editingDocID}
      buildDocData={buildVacanyData}
      updateDoc={updateModule}
      createDoc={createModule}
      hasDetails={false}
      wrapInside={wrapInside}
      docData={isEditing ? vacancyData : vacanciesFormConfig.defaultValues}
      modalMaxWidth={modalMaxWidth}
      onCloseForm={onCloseForm}
      onError={errorLogger}
      formInputs={({ formObject }) => {
        const {
          formState: { errors },
        } = formObject
        return (
          <Grid container spacing={1}>
            <FormRow icon={<TitleIcon />}>
              <Grid container>
                <TextField
                  autoFocus
                  label='Vacature naam'
                  name='nameJoop'
                  rules={{
                    required: 'Verplicht veld',
                    maxLength: { value: 100, message: 'Max 100 karakters' },
                  }}
                  formObject={formObject}
                />
              </Grid>
            </FormRow>
            <FormRow icon={<Subject />}>
              <TextField
                label='Vacature beschrijving'
                name='descriptionJoop'
                rules={{ required: 'Verplicht veld' }}
                rows={4}
                maxRows={20}
                multiline
                formObject={formObject}
              />
            </FormRow>
            <FormRow icon={<AddLocationIcon />}>
              <AutoComplete
                label='Locatie vacature'
                name='locationJoop'
                formObject={formObject}
                collection='locations'
                required
                labelKeys={['nameLoca']}
                where={[['archivedLoca', '==', false]]}
                searchKey='keywordsLoca'
                error={errors.locationJoop !== undefined}
                helperText={
                  errors.locationJoop &&
                  errors.locationJoop.type === 'required' &&
                  'Verplicht veld'
                }
              />
            </FormRow>
            <FormRow icon={<CategoryIcon />}>
              <AutoSelect
                label='Categorieën'
                multiple
                formObject={formObject}
                name='categoriesJoop'
                options={categoryOptions}
              />
            </FormRow>
          </Grid>
        )
      }}
    />
  )
}

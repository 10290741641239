import React, { useState, useEffect } from 'react'
import { addDays, addHours, addMinutes, format, startOfWeek } from 'date-fns'
import {
  ActionMenu,
  MenuButton,
  useConfirmModal,
  useSnackbar,
} from '@fivano/core'
import {
  ClickAwayListener,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'

import { TimeRegistrationColumn } from './TimeRegistrationColumn'

import { PageContainer } from '@fivano/core'
import { Breadcrumbs } from 'app/components/Breadcrumbs'

import { RowStateProps, TimeDocProps } from './types'

import { useSelector } from 'hooks/useSelector'
import { useErrorLogger } from 'hooks/useErrorLogger'

import { compareArraysAndObjects } from 'utils/compareArraysAndObjects'
import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'
import { timeStringToSeconds } from 'utils/timeToSeconds'

import WeekendCalender from 'app/icons/WeekendCalender'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import { NavigationPrompt } from '@fivano/core'
import { MINUTES_IN_DAY } from 'utils/variables'
import { secondsToCustomTimeString } from 'utils/secondsToTime'
import { convertToDate } from 'utils/convertToDate'
import { DateNavigation } from '@fivano/core'
import { Beforeunload } from 'app/components/Beforeunload'

const defaultLabels = {
  closingAction: 'Verlaten',
  stayingAction: 'Wijzigingen opslaan',
  onCloseText: 'Pagina verlaten?',
  closeWarning:
    'De huidige rij die wordt bewerkt zal niet worden opgeslagen, weet je zeker dat je de pagina wil verlaten?',
}

const createFullWeek = currentDate => {
  const week: Date[] = []
  for (var i = 0; i < 7; i++) {
    week.push(addDays(currentDate, i))
  }
  return week
}

const breadcrumbArray = [
  { title: 'Home', path: '/' },
  { title: 'Tijdregistratie', path: `/tijdregistratie` },
]

export const addRow = async item => {
  const endTimeInMinutes =
    timeStringToSeconds(format(item.endTime, 'HH:mm')) / 60

  if (endTimeInMinutes !== 0) {
    let calculateDuration: number = 60
    if (endTimeInMinutes + 60 > MINUTES_IN_DAY) {
      calculateDuration = MINUTES_IN_DAY - endTimeInMinutes
    }
    const id = firestoreIDGenerator()
    const newTimeItemRow = {
      id: id,
      emptyRow: true,
      companyCode: item.companyCode,
      projectCode: item.projectCode,
      workCode: item.workCode,
      startTime: item.endTime,
      duration: calculateDuration,
      endTime: addMinutes(item.endTime, calculateDuration),
    }
    return newTimeItemRow
  }
}

const createDefaultValues = date => {
  const defaultValues = {
    id: firestoreIDGenerator(),
    emptyRow: true,
    companyCode: undefined,
    projectCode: undefined,
    workCode: undefined,
    startTime: addHours(date, 8),
    duration: 60,
    endTime: addHours(date, 9),
  }
  return defaultValues
}

const comparer = otherArray => {
  return function (current) {
    return otherArray.filter(other => other.id === current.id).length === 0
  }
}

const compareSpecificObject = array => {
  return function (current) {
    return (
      array.filter(
        other =>
          other.id === current.id && compareArraysAndObjects(other, current),
      ).length === 0
    )
  }
}

export const TimeRegistration = () => {
  const firestore = useFirestore()
  const errorLogger = useErrorLogger()
  const [showWeekend, setShowWeekend] = useState<boolean>(false)
  const [initialData, setInitialData] = useState<TimeDocProps[]>([])
  const theme = useTheme()
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'))
  const [currentWeek, setCurrentWeek] = useState<Date[]>(
    createFullWeek(startOfWeek(new Date(), { weekStartsOn: 1 })),
  )
  const [data, setData] = useState<TimeDocProps[]>([])
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)
  const profile = useSelector(state => state.firebase.profile)
  const { enqueueSnackbar } = useSnackbar()

  const [rowState, setRowState] = useState<RowStateProps>({
    editRow: undefined,
    submitRow: undefined,
    changeToRow: undefined,
    addRow: undefined,
  })

  useFirestoreConnect([
    {
      collection: 'locations',
      where: [
        ['hasProjectCodesLoca', '==', true],
        ['archivedLoca', '==', false],
      ],
      storeAs: 'locations',
    },
    {
      collection: 'projectCodes',
      where: [['archived', '==', false]],
      storeAs: 'projectCodes',
    },
    {
      collection: 'workingHome',
      where: [
        ['date', '>=', currentWeek[0]],
        ['date', '<', currentWeek[6]],
      ],
      storeAs: 'workingHome',
    },
  ])

  const locationsData = useSelector(
    ({ firestore: { ordered } }) => ordered[`locations`],
  )

  const projectCodes = useSelector(
    ({ firestore: { ordered } }) => ordered[`projectCodes`],
  )

  const workingHomeData = useSelector(({ firestore: { ordered } }) => {
    const docData = ordered[`workingHome`]
    const docDataCopy: any = []

    docData?.forEach(item => {
      const itemCopy = { ...item }
      itemCopy.date = convertToDate(item.date)
      docDataCopy.push(itemCopy)
    })

    return docDataCopy
  })
  useEffect(() => {
    setShowSkeleton(true)
    setWeekTotal(0)
    firestore
      .get({
        collection: 'timeRegistrations',
        where: [
          ['personID', '==', profile.uid],
          ['type', '==', 'timeRegistration'],
          ['startTime', '>=', currentWeek[0]],
          ['startTime', '<', currentWeek[6]],
          ['type', '==', 'timeRegistration'],
        ],
        orderBy: ['startTime', 'asc'],
        storeAs: 'timeRegistrations',
      })
      .then(async (response: any) => {
        const dataArray: TimeDocProps[] = []
        response.forEach(item => {
          const row: TimeDocProps = { ...item.data() }
          if (row.startTime?.seconds)
            row.startTime = new Date(row.startTime.seconds * 1000)
          if (row.endTime?.seconds)
            row.endTime = new Date(row.endTime.seconds * 1000)

          dataArray.push(row)
        })
        setData(dataArray)
        calculateWeekTotal(dataArray)
        setInitialData(dataArray)
        setShowSkeleton(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeek])

  const createEmptyRow = async date => {
    const prevRow = data
      .filter(
        item =>
          new Date(format(item.startTime, 'dd-MMMM-yyyy')).getTime() ===
          date.getTime(),
      )
      .sort((a, b) => b.startTime.getTime() - a.startTime.getTime())[0]

    let emptyRow: any = {}
    if (prevRow) {
      const endTimeInMinutes =
        timeStringToSeconds(format(prevRow.endTime, 'HH:mm')) / 60

      if (endTimeInMinutes !== 0) {
        emptyRow = await addRow(prevRow)

        setData([...data, emptyRow])
        calculateWeekTotal([...data, emptyRow])
        setRowState({
          editRow: emptyRow?.id,
          submitRow: undefined,
          changeToRow: undefined,
          addRow: undefined,
        })
      }
    } else {
      emptyRow = createDefaultValues(date)
      setData([...data, emptyRow])
      setRowState({
        editRow: emptyRow?.id,
        submitRow: undefined,
        changeToRow: undefined,
        addRow: undefined,
      })
    }
  }

  const [createItem, setCreateItem] = useState<TimeDocProps | undefined>(
    undefined,
  )
  const createRow = async newData => {
    delete newData.emptyRow
    setCreateItem(newData)
  }
  useEffect(() => {
    if (createItem) {
      const copyData: TimeDocProps[] = [...data]
      const index = data.findIndex(data => data.id === createItem.id)

      if (index > -1) {
        copyData[index] = createItem
        setData(copyData)
        calculateWeekTotal(copyData)
      } else {
        setData([...data, createItem])
        calculateWeekTotal([...data, createItem])
        setRowState({
          editRow: createItem.id,
          submitRow: undefined,
          changeToRow: undefined,
          addRow: undefined,
        })
      }

      firestore
        .collection('timeRegistrations')
        .doc(createItem.id)
        .set(createItem)
        .then(() => {
          enqueueSnackbar('Item toegevoegd', {
            variant: 'success',
          })
        })
        .catch(error => {
          errorLogger({ error })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createItem])

  const [updateItem, setUpdateItem] = useState<TimeDocProps | undefined>(
    undefined,
  )
  const updateRow = async newData => {
    setUpdateItem(newData)
  }
  useEffect(() => {
    if (updateItem) {
      const copyData: TimeDocProps[] = [...data]
      const index = data.findIndex(data => data.id === updateItem.id)
      copyData[index] = updateItem
      setData(copyData)
      calculateWeekTotal(copyData)

      firestore
        .collection('timeRegistrations')
        .doc(updateItem.id)
        .update(updateItem)
        .then(() => {
          enqueueSnackbar('Item aangepast', {
            variant: 'success',
          })
        })
        .catch(error => {
          errorLogger({ error })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateItem])

  const confirmModal = useConfirmModal()
  const deleteRow = async newData => {
    confirmModal({
      title: 'Gegevens verwijderen',
      confirmLabel: 'Verwijderen',
      cancelLabel: 'Annuleren',
      message:
        'De huidige rij die wordt bewerkt zal niet worden opgeslagen, weet je zeker dat je de pagina wil verlaten?',
      onConfirm: () => setDeleteItem(newData),
    })
  }

  const [deleteItem, setDeleteItem] = useState<TimeDocProps | undefined>(
    undefined,
  )

  useEffect(() => {
    if (deleteItem) {
      const copyData: TimeDocProps[] = [...data]
      const index = data.findIndex(data => data.id === deleteItem.id)
      copyData.splice(index, 1)
      setData(copyData)
      calculateWeekTotal(copyData)

      firestore
        .delete({
          collection: 'timeRegistrations',
          doc: deleteItem.id,
        })
        .then(() => {
          enqueueSnackbar('Item verwijderd', {
            variant: 'success',
          })
          if (
            rowState.editRow === deleteItem.id &&
            rowState.submitRow === undefined
          ) {
            setRowState({
              editRow: undefined,
              submitRow: undefined,
              changeToRow: undefined,
              addRow: undefined,
            })
          } else {
            setRowState({
              editRow: rowState.editRow,
              submitRow: undefined,
              changeToRow: undefined,
              addRow: undefined,
            })
          }
        })
        .catch(error => {
          errorLogger({ error })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItem])

  const resetToInitialData = (initialData, data) => {
    confirmModal({
      title: 'Aanpassingen verwijderen',
      confirmLabel: 'Verwijderen',
      cancelLabel: 'Annuleren',
      message:
        'Let op: alle aanpassingen zullen worden verwijderd, weet je zeker dat je alle aanpassingen wilt verwijderen?',
      onConfirm: () => resetData(initialData, data),
    })
  }

  /** WERKT NOG NIET HELEMAAL */
  const resetData = async (initialData, data) => {
    const onlyInInitialData = initialData.filter(comparer(data))
    const onlyInNewData = data.filter(comparer(initialData))
    const differenceInData = initialData.filter(compareSpecificObject(data))
    if (onlyInInitialData?.length)
      await onlyInInitialData.forEach(data => {
        firestore.collection('timeRegistrations').doc(data.id).set(data)
      })
    if (onlyInNewData)
      await onlyInNewData.forEach(data => {
        firestore
          .delete({
            collection: 'timeRegistrations',
            doc: data.id,
          })
          .catch(error => {
            errorLogger({ error })
          })
      })
    if (differenceInData)
      await differenceInData.forEach(data => {
        firestore
          .collection('timeRegistrations')
          .doc(data.id)
          .update(data)
          .catch(error => {
            errorLogger({ error })
          })
      })

    enqueueSnackbar('Aanpassingen verwijderd', {
      variant: 'success',
    })

    setRowState({
      editRow: undefined,
      submitRow: undefined,
      changeToRow: undefined,
      addRow: undefined,
    })
    setCreateItem(undefined)
    setUpdateItem(undefined)
    setDeleteItem(undefined)
    setData(initialData)
  }

  const [weekTotal, setWeekTotal] = useState<number>(0)
  const calculateWeekTotal = timeDocs => {
    const weekTotal = timeDocs.reduce((sum, item) => {
      return sum + item.duration * 60
    }, 0)

    setWeekTotal(weekTotal)
  }

  return (
    <Beforeunload addListener={!!rowState.editRow}>
      <PageContainer maxWidth='lg'>
        <Breadcrumbs breadcrumbs={breadcrumbArray} />
        <NavigationPrompt
          translations={defaultLabels}
          open={!!rowState.editRow}
        />
        <Paper>
          <Box p={1}>
            <Grid container alignItems='center'>
              <DateNavigation
                name='date'
                minDate='01-01-1970'
                debounce={300}
                onChange={date =>
                  date &&
                  setCurrentWeek(
                    createFullWeek(startOfWeek(date, { weekStartsOn: 1 })),
                  )
                }
              />
              <Grid item marginLeft='auto'>
                <ActionMenu iconPosition='vertical'>
                  <MenuButton
                    label={`${showWeekend ? 'Verberg' : 'Toon'} weekend`}
                    icon={<WeekendCalender />}
                    onClick={() => setShowWeekend(!showWeekend)}
                  />
                  <MenuButton
                    label='Aanpassingen verwijderen'
                    icon={<HighlightOffIcon />}
                    disabled={compareArraysAndObjects(initialData, data)}
                    onClick={() => resetToInitialData(initialData, data)}
                  />
                </ActionMenu>
              </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent='flex-end' sx={{ padding: 2 }}>
            <Grid item>
              <Typography>
                Weektotaal: {secondsToCustomTimeString([weekTotal])}
              </Typography>
            </Grid>
          </Grid>
          <ClickAwayListener
            onClickAway={() => {
              setRowState({
                editRow: undefined,
                submitRow: rowState.editRow,
                changeToRow: undefined,
                addRow: undefined,
              })
            }}
            {...(!rowState.editRow && { mouseEvent: false })}
          >
            <div>
              {currentWeek.map((date, index) => {
                const showDays = showWeekend ? 7 : 5
                return (
                  index < showDays && (
                    <TimeRegistrationColumn
                      key={format(date, 'ddmmyyyy')}
                      data={data
                        .filter(
                          row =>
                            new Date(
                              format(row?.startTime, 'dd-MMMM-yyyy'),
                            ).getTime() === date.getTime(),
                        )
                        .sort((a, b) => a.startTime - b.startTime)}
                      date={date}
                      workingHomeData={workingHomeData}
                      onCreateEmptyRow={date => createEmptyRow(date)}
                      personID={profile.uid}
                      onCreateRow={createRow}
                      onUpdateRow={updateRow}
                      onDeleteRow={deleteRow}
                      setRowState={setRowState}
                      rowState={rowState}
                      newRow={date?.getTime() === rowState?.addRow?.getTime()}
                      showSkeleton={showSkeleton}
                      locationsData={locationsData}
                      projectCodes={projectCodes}
                      matchesMdDown={matchesMdDown}
                    />
                  )
                )
              })}
            </div>
          </ClickAwayListener>
        </Paper>
      </PageContainer>
    </Beforeunload>
  )
}

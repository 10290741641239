import React from 'react'
import { Grid } from '@mui/material'
import { TextField } from '@fivano/core'
import { FormRow, Switch } from '@fivano/core'
import { SettingsForm } from 'app/legacy/SettingsBlock/SettingsForm'
import { SlugInput } from '../SlugInput'
import { useFirestore } from 'react-redux-firebase'

export const GenreForm = props => {
  const firestore = useFirestore()
  const { firestoreCode } = props
  const id = props.formProps.formID

  return (
    <SettingsForm {...props}>
      {({ formObject, showAllFields }) => {
        const {
          formState: { errors },
        } = formObject
        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label='Genre'
                name={`name${firestoreCode}`}
                formObject={formObject}
                rules={{ required: true }}
                required
                error={errors.descriptionPrta !== undefined}
                helperText={
                  errors[`name${firestoreCode}`] &&
                  errors[`name${firestoreCode}`].type === 'required' &&
                  'Verplicht veld'
                }
              />
              <SlugInput
                formObject={formObject}
                name='slugGenre'
                sourceSlug='nameGenre'
                rootSlug='https://songstarter.com'
                rules={{
                  validate: {
                    duplicate: async value =>
                      !!id ||
                      (await firestore
                        .get('appSettings/genres')
                        .then((response: any) =>
                          response?.data() === undefined
                            ? true
                            : response
                                ?.data()
                                ?.options.findIndex(
                                  g => g.slugGenre === value,
                                ) === -1,
                        )
                        .catch(() => false)) ||
                      'Er bestaat al een genre onder deze link, kies een andere link',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label='Omschrijving'
                name={`description${firestoreCode}`}
                multiline
                rows={4}
                maxRows={20}
                formObject={formObject}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Genre (Spaans)'
                name={`nameES${firestoreCode}`}
                formObject={formObject}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Omschrijving (Spaans)'
                name={`descriptionES${firestoreCode}`}
                multiline
                rows={4}
                maxRows={20}
                formObject={formObject}
              />
            </Grid>
            <FormRow showAllFields={showAllFields}>
              <TextField
                name={`id${firestoreCode}`}
                formObject={formObject}
                readOnly={!!id}
                label='Genre ID'
              />
              <Switch
                name={`archived${props.firestoreCode}`}
                formObject={formObject}
                label={`Genre archiveren`}
                defaultValue={false}
              />
            </FormRow>
          </Grid>
        )
      }}
    </SettingsForm>
  )
}

import { Grid, Chip } from '@mui/material'
import React, { useState } from 'react'
import { SettingsPage } from '../SettingsBlock/SettingsPage'
import { JobSettingsCategoryForm } from './JobSettingsCategoryForm'

const breadcrumbs = [
  { title: 'Instellingen', path: '/instellingen' },
  {
    title: 'Vacature categorieën',
    path: '/instellingen/vacature-categorieën',
  },
]
export const JobOpeningCategorySettings = () => {
  const [formProps, setFormProps] = useState({
    formID: null,
    open: false,
  })
  const firestoreCode = 'Joca'
  const firestoreSettingsPath = 'jobOpeningCategories'
  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          accessor: `name${firestoreCode}`,
          gridProps: {
            xs: 9,
            sm: 9,
            md: 9,
          },
        },
        {
          Header: 'Gearchiveerd',
          accessor: `archived${firestoreCode}`,
          gridProps: {
            xs: 3,
            sm: 3,
            md: 3,
          },
          Cell: data => (
            <Grid item>
              {data.row.original[`archived${firestoreCode}`] && (
                <Chip label='Gearchiveerd' />
              )}
            </Grid>
          ),
        },
      ],
    },
  ]

  return (
    <>
      <JobSettingsCategoryForm
        firestoreCode={firestoreCode}
        firestoreSettingsPath={firestoreSettingsPath}
        labelSingle='Categorie'
        formProps={formProps}
        onClose={() =>
          setFormProps({
            formID: null,
            open: false,
          })
        }
      />
      <SettingsPage
        onAdd={() => setFormProps({ formID: null, open: true })}
        onEdit={(event, rowData) =>
          setFormProps({
            formID: rowData.original[`id${firestoreCode}`],
            open: true,
          })
        }
        labelMultiple='vacature categorieën'
        labelSingle='categorie'
        breadcrumbs={breadcrumbs}
        firestoreSettingsPath={firestoreSettingsPath}
        firestoreCode={firestoreCode}
        listColumns={listColumns}
      />
    </>
  )
}

import React from 'react'
import { useFirestore } from 'react-redux-firebase'

export function DevelopPage() {
  const firestore = useFirestore()
  const getAllTimeRegistrations = () => {
    firestore
      .get({
        collection: 'timeRegistrations',
        orderBy: ['startTime', 'asc'],
        storeAs: 'timeRegistrations',
      })
      .then(response => {
        const docs = []
        // @ts-ignore
        response.docs.forEach(doc => docs.push(doc.data()))
        console.log(JSON.stringify(docs))
      })
  }
  return (
    <div>
      <button onClick={() => getAllTimeRegistrations()}>get times</button>
    </div>
  )
}

import { useEffect } from 'react'

/** Hook to trigger a beforeunload prompt when addListener is true. */
export const useBeforeunload = (addListener: boolean) => {
  useEffect(() => {
    const unloadCallback = event => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }

    if (addListener) {
      window.addEventListener('beforeunload', unloadCallback)
    }
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [addListener])
}

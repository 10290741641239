import React from 'react'
import { Grid } from '@mui/material'

import { TextField, Switch, FormRow } from '@fivano/core'
import { SettingsForm } from '../SettingsBlock/SettingsForm'

export const JobSettingsCategoryForm = props => {
  return (
    <SettingsForm {...props}>
      {({ formObject, showAllFields, isEditing }) => {
        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label='Categorie naam'
                name={`name${props.firestoreCode}`}
                rules={{
                  required: 'Verplicht veld',
                  maxLength: { value: 100, message: 'Max 100 karakters' },
                }}
                formObject={formObject}
                readOnly={isEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Omschrijving'
                name={`description${props.firestoreCode}`}
                rows={4}
                maxRows={10}
                multiline
                formObject={formObject}
              />
            </Grid>
            <FormRow showAllFields={showAllFields}>
              <TextField
                label='Categorie ID'
                name={`id${props.firestoreCode}`}
                formObject={formObject}
                readOnly={isEditing}
              />
              <Switch
                name={`archived${props.firestoreCode}`}
                formObject={formObject}
                label={`Categorie archiveren`}
              />
            </FormRow>
          </Grid>
        )
      }}
    </SettingsForm>
  )
}

import React, { memo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Timepicker } from 'app/components/Timepicker'
import { format } from 'date-fns'
import { styled } from '@mui/material'
import { useEffect } from 'react'
import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'
import { MINUTES_IN_DAY } from 'utils/variables'
import { timeStringToSeconds } from 'utils/timeToSeconds'
import { compareArraysAndObjects } from 'utils/compareArraysAndObjects'
import { nl } from 'date-fns/locale'
import { useDataStandards } from 'hooks/useDataStandards'
import { ProjectRowInputProps } from './types'
import { isEmptyObject } from 'utils/isEmptyObject'

const calculateTotal = (data, doc, total) => {
  const formatDataTime = timeStringToSeconds(format(data.time, 'HH:mm')) / 60

  const calculateDifferenceInTime = doc?.time
    ? formatDataTime - timeStringToSeconds(format(doc.time, 'HH:mm')) / 60
    : formatDataTime

  return total + calculateDifferenceInTime
}

const StyledTimepicker = styled(Timepicker, {
  shouldForwardProp: prop => prop !== 'error' && prop !== 'viewportLGDown',
})<any>(({ error, viewportLGDown, theme }) => ({
  zIndex: error ? 100 : 0,
  borderRight: !viewportLGDown ? `1px solid ${theme.palette.divider}` : 0,
  '& .MuiOutlinedInput-notchedOutline': {
    [theme.breakpoints.up('lg')]: {
      borderColor: 'rgba(0,0,0,0)',
    },
  },
  '& .textFieldRoot': {
    borderRadius: viewportLGDown ? 4 : 0,
    padding: '10px 1px 10px 0px',
    width: '100%',
  },
  '& .root': {
    width: '100%',
  },
}))

export const ProjectRowInput = memo(
  ({
    doc,
    project,
    day,
    total,
    viewportLG,
    onCreateDoc,
    onUpdateDoc,
    onHandleErrorInput,
  }: ProjectRowInputProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const createDataStandards = useDataStandards()

    const formObject = useForm({
      defaultValues: { time: doc ? doc.time : day },
    })

    useEffect(() => {
      formObject.reset({ time: doc ? doc.time : day })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doc])

    const isEditing = !!doc?.id

    const onSubmit = data => {
      if (data.time) {
        if (calculateTotal(data, doc, total) < MINUTES_IN_DAY) {
          const dataStandards = createDataStandards({
            data,
            dataName: '',
            editForm: isEditing,
          })

          const newData = { ...data, ...project, ...dataStandards }
          newData.type = 'durationRegistration'
          !isEditing
            ? (newData.id = firestoreIDGenerator())
            : (newData.id = doc.id)

          if (isEditing) {
            if (newData.time.getTime() !== doc.time.getTime())
              onUpdateDoc(newData)
          } else {
            if (format(data.time, 'HH:mm') !== '00:00') onCreateDoc(newData)
          }
          onHandleErrorInput('')
        } else {
          inputRef.current?.focus()
          formObject.setError('time', { type: 'manual' })
          onHandleErrorInput(project.projectCode.value)
        }
      } else {
        inputRef.current?.focus()
        formObject.setError('time', { type: 'manual' })
      }
    }

    return (
      <StyledTimepicker
        name='time'
        variant='outlined'
        size='small'
        formObject={formObject}
        currentDate={day}
        {...(!viewportLG && {
          label: format(day, 'E dd MMMM', { locale: nl }),
        })}
        onBlur={() => {
          formObject.handleSubmit(onSubmit)()
        }}
        onChange={value => {
          if (typeof value !== 'string') {
            formObject.trigger('time')
            if (calculateTotal({ time: value }, doc, total) < MINUTES_IN_DAY) {
              onHandleErrorInput('')
            } else {
              onHandleErrorInput(project.trackID)
              formObject.setError('time', { type: 'manual' })
            }
          }
        }}
        fullWidth
        noMargin
        viewportLGDown={!viewportLG}
        error={!isEmptyObject(formObject.formState.errors)}
        InputProps={{
          className: 'textFieldRoot',
          endAdornment: <div></div>,
        }}
        inputProps={{
          style: { padding: '5px 0px 5px 14px' },
        }}
        classesProp={{
          root: { width: '100%' },
          inputBase: { root: { display: 'none' } },
        }}
        disableHelperText={true}
        inputRef={inputRef}
      />
    )
  },
  (prevProps, nextProps) => {
    return (
      compareArraysAndObjects(prevProps.doc, nextProps.doc) &&
      compareArraysAndObjects(prevProps.project, nextProps.project) &&
      prevProps.day === nextProps.day &&
      prevProps.total === nextProps.total &&
      prevProps.viewportLG === nextProps.viewportLG
    )
  },
)

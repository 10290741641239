import React, { memo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'hooks/useSelector'
import {
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase'
import { Paper, Grid, Box, Typography, Button, styled } from '@mui/material'
import { useHistory } from 'react-router'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import {
  Checkbox,
  ActionMenu,
  MenuButton,
  FloatingButton,
  PageContainer,
  ListView,
} from '@fivano/core'
import EditIcon from '@mui/icons-material/Edit'
import { relationsSettings } from './relationsSettings'
import { SearchFilter } from 'app/components/SearchFilter'
import { useFilters } from 'hooks/useFilters'
import { Archive } from '@mui/icons-material'
import { useArchiveDoc } from 'hooks/useArchiveDoc'
import { RelationForm } from './RelationForm'
import { Relation } from 'types/Relation'

const PaperStyled = styled(Paper)(() => ({
  marginBottom: '80px',
}))

const breadcrumbArray = [
  { title: 'Home', path: '/' },
  { title: 'Bedrijven', path: '/bedrijven' },
]

export const Relations = memo(props => {
  const history = useHistory()
  const [relationModalProps, setRelationModalProps] = useState({
    open: false,
    relationID: undefined,
  })
  const [filters, setFilters] = useFilters({
    searchField: {
      value: '',
      label: 'Zoek op:',
      param: true,
    },
    archivedRela: {
      value: false,
      label: 'Toon gearchiveerd',
      param: true,
    },
  })
  const handleFilter = data => {
    const newFilters = { ...filters }
    newFilters.searchField.value = data.searchField
    newFilters.archivedRela.value = data.archivedRela

    setFilters(newFilters)
  }
  // ==== LOAD MORE ==== //
  const [relationLimit, setRelationLimit] = useState(
    relationsSettings.listviewLimit,
  )

  // ==== GET RELATIONS ==== //
  const relationQuery = (
    { searchField, archivedRela },
    relationLimit,
  ): ReduxFirestoreQuerySetting => ({
    collection: 'relations',
    limit: relationLimit,
    orderBy: ['nameRela', 'asc'],
    where: [
      ['keywordsRela', 'array-contains', searchField.value.toLowerCase()],
      ['archivedRela', '==', archivedRela.value],
    ],
    storeAs: 'relations',
  })
  useFirestoreConnect([relationQuery(filters, relationLimit)])

  const relationsData: Relation[] = useSelector(
    ({ firestore: { ordered } }) => ordered.relations,
  )

  const archiveDoc: any = useArchiveDoc()
  const handleArchive = data => {
    archiveDoc({
      docType: 'Bedrijf',
      path: `relations/${data.id}`,
      archived: !data?.archivedRela,
      extension: 'Rela',
    })
  }

  // ==== RELATION LISTVIEW STRUCTURE ==== //
  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          accessor: 'nameRela',
          gridProps: {
            xs: 12,
            sm: 6,
            md: 5,
          },
          Cell: data => (
            <Grid item>
              <Typography variant='body1' align='left'>
                {data.row.original.nameRela}
              </Typography>
            </Grid>
          ),
        },
        {
          Header: 'Email',
          accessor: 'mailEmailRela',
          hidden: { only: ['xs'] },
          gridProps: {
            sm: 3,
            md: 3,
          },
          Cell: data => (
            <Grid item>
              <Typography variant='body1' align='left'>
                {data.row.original.mailEmailRela}
              </Typography>
            </Grid>
          ),
        },
        {
          Header: 'Telefoon',
          accessor: 'phoneRela',
          hidden: { only: ['xs', 'sm'] },
          gridProps: {
            md: 2,
          },
          Cell: data => (
            <Typography variant='body1' align='left'>
              {data.row.original.phoneRela}
            </Typography>
          ),
        },
        {
          id: 'actions',
          Header: ' ',
          // hidden: { only: ['xs', 'sm'] },
          Cell: data => (
            <Grid item>
              <ActionMenu
                iconPosition='vertical'
                minWidth={250}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <MenuButton
                  label={`Bewerk bedrijf`}
                  icon={<EditIcon />}
                  onClick={() =>
                    setRelationModalProps({
                      open: true,
                      relationID: data.row.original.id,
                    })
                  }
                />
                <MenuButton
                  label={
                    data.row.original?.archivedRela
                      ? 'Bedrijf dearchiveren'
                      : 'Bedrijf archiveren'
                  }
                  icon={<Archive />}
                  onClick={() => handleArchive(data.row.original)}
                />
              </ActionMenu>
            </Grid>
          ),
        },
      ],
    },
  ]

  return (
    <>
      <Helmet>
        <title>Bedrijven</title>
      </Helmet>
      {relationModalProps.open && (
        <RelationForm
          relationID={relationModalProps.relationID}
          onCloseForm={() =>
            setRelationModalProps({
              open: false,
              relationID: undefined,
            })
          }
        />
      )}
      <PageContainer maxWidth='lg'>
        <Breadcrumbs breadcrumbs={breadcrumbArray} />

        <PaperStyled>
          <Box p={2}>
            <Grid container>
              <Grid item xs={12}>
                <SearchFilter
                  placeholder='Zoeken in bedrijven'
                  onSubmit={handleFilter}
                  filterValues={filters}
                  filters={({ formObject }) => {
                    return (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Checkbox
                              label='Toon gearchiveerde bedrijven'
                              formObject={formObject}
                              name='archivedRela'
                            />
                          </Grid>
                        </Grid>
                      </>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <FloatingButton
            label='Bedrijf toevoegen'
            onClick={() => {
              setRelationModalProps({ open: true, relationID: undefined })
            }}
          />
          {relationsData?.length > 0 ? (
            <>
              <ListView
                data={relationsData}
                columns={listColumns}
                onClick={data => history.push(`/bedrijven/${data.original.id}`)}
                route={'/bedrijven/{id}'}
              />
              {relationsData.length === relationLimit && (
                <Button
                  fullWidth
                  onClick={() =>
                    setRelationLimit(
                      relationLimit + relationsSettings.listviewLoadMore,
                    )
                  }
                >
                  Meer bedrijven laden
                </Button>
              )}
            </>
          ) : (
            <Box p={2}>
              <Typography variant='body1'>
                Geen bedrijven gevonden. Voeg een nieuwe bedrijf toe
              </Typography>
            </Box>
          )}
        </PaperStyled>
      </PageContainer>
    </>
  )
})

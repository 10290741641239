import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { getThemeModeFromStorage, saveThemeMode } from './utils'
import { RootState } from 'types'

export const initialState = {
  darkMode: getThemeModeFromStorage() || false,
}
const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeThemeMode(state, action: PayloadAction<boolean>) {
      saveThemeMode(action.payload)
      state.darkMode = action.payload
    },
  },
})

export const selectThemeMode = createSelector(
  [
    (state: RootState) =>
      //@ts-ignore
      ({ darkMode: getThemeModeFromStorage() } || state?.theme || initialState),
  ],
  theme => theme.darkMode,
)

export const { changeThemeMode } = themeSlice.actions
export const reducer = themeSlice.reducer
export const themeSliceKey = themeSlice.name

import { lazy } from 'react'
import { routeObjectType } from './types'
import { DevelopPage } from 'app/legacy/DevelopPage'
import { SettingsOverview } from 'app/legacy/ApplicationSettings/SettingsOverview/SettingsOverview'
import { servicesRoutes } from 'app/containers/Services/servicesRoutes'
import { exampleRoutes } from './exampleRoutes'
import { settingRoutes } from 'app/legacy/ApplicationSettings/settingRoutes'
import { songstarterRoutes } from 'app/legacy/MusicTracks/songstarterRoutes'
import { HomeSkeleton } from 'app/containers/Home/Home'
import { productsRoutes } from 'app/containers/Products/productRoutes'
import { courseTrackingRoutes } from 'app/containers/CourseTracking/courseTrackingRoutes'
import { authorizationRoutes } from 'app/containers/Authorization/authorizationRoutes'
import { pointOfSaleRoutes } from 'app/containers/PointOfSale/pointOfSaleRoutes'
import { personRoutes } from 'app/containers/Persons/personRoutes'

// const Home = lazy(() => import('app/containers/Home/Home'))
const Home = lazy(() => {
  return new Promise(resolve => {
    // @ts-ignore
    setTimeout(() => resolve(import('app/containers/Home/Home')), 500)
  })
})

export const routes: routeObjectType = {
  home: {
    path: '/',
    component: Home,
    skeleton: HomeSkeleton,
    claims: { u: 1 },
  },
  settings: {
    path: '/instellingen',
    component: SettingsOverview,
    claims: { s: 1 },
  },
  development: {
    path: '/development',
    component: DevelopPage,
    claims: { s: 5 },
  },
  ...songstarterRoutes,
  ...settingRoutes,
  ...servicesRoutes,
  ...productsRoutes,
  ...exampleRoutes,
  ...pointOfSaleRoutes,
  ...courseTrackingRoutes,
  ...authorizationRoutes,
  ...personRoutes,
}

import useMongo from 'hooks/useMongo'
import { useState } from 'react'
import { SortableContainer } from './SortableContainer'
import { SortableItem } from './SortableItem'

export const CategoriesLayout = ({
  layoutDoc,
  items,
  activeID,
  onActiveID,
  onDrag,
  onRefetch,
}) => {
  const { updateDoc } = useMongo()

  const [draggingID, setDraggingID] = useState<any>(null)
  const activeIndex = draggingID
    ? items.findIndex(item => item?.id === draggingID)
    : -1

  const createCategoryCard = data => {
    // onChangeItems([...items, data])
  }

  const onDragEnd = data => {
    const layoutDocCopy = { ...layoutDoc }

    layoutDocCopy.categories = data

    onDrag(data)
    updateDoc({
      collection: 'pointOfSaleLayout',
      docID: layoutDocCopy._id,
      data: layoutDocCopy,
    }).then(() => {
      onRefetch(layoutDocCopy._id)
    })
  }

  const deleteCategoryCard = item => {
    const layoutDocCopy = { ...layoutDoc }
    const categories = [...layoutDocCopy.categories]
    const index = categories.findIndex(category => category.id === item.id)

    const deletedItem = categories[index]
    categories.splice(index, 1)

    layoutDocCopy.categories = categories

    updateDoc({
      collection: 'pointOfSaleLayout',
      docID: layoutDocCopy._id,
      data: layoutDocCopy,
    }).then(() => {
      onRefetch(layoutDocCopy._id)
    })

    if (deletedItem?.id === activeID) {
      setDraggingID(undefined)
      onActiveID(undefined)
    }
  }

  return (
    <SortableContainer
      onRefetch={onRefetch}
      layoutDoc={layoutDoc}
      items={items || []}
      draggingID={draggingID}
      activeID={activeID}
      onCreate={createCategoryCard}
      setDraggingID={setDraggingID}
      onDragEnd={onDragEnd}
    >
      {items?.map((item, index) => (
        <SortableItem
          key={item.id}
          item={item}
          activeID={activeID}
          activeIndex={activeIndex}
          onDelete={deleteCategoryCard}
          onClick={onActiveID}
        />
      ))}
    </SortableContainer>
  )
}

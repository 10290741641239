import React, { useState } from 'react'
import { AudioKeyForm } from './AudioKeyForm'
import { SettingsPage } from 'app/legacy/SettingsBlock'
import { Chip, Grid } from '@mui/material'

const breadcrumbArray = [
  { title: 'Instellingen', path: '/instellingen' },
  { title: 'Keys', path: '/instellingen/audio-keys' },
]

export const AudioKeys = () => {
  const [modalProps, setModalProps] = useState({
    formID: null,
    open: false,
  })
  const firestoreCode = 'Key'
  const firestoreSettingsPath = 'keys'

  return (
    <>
      <AudioKeyForm
        firestoreCode={firestoreCode}
        firestoreSettingsPath={firestoreSettingsPath}
        labelSingle='Key'
        formProps={modalProps}
        onClose={() =>
          setModalProps({
            formID: null,
            open: false,
          })
        }
      />
      <SettingsPage
        onAdd={() => setModalProps({ formID: null, open: true })}
        onEdit={(event, rowData) =>
          setModalProps({
            formID: rowData.original[`id${firestoreCode}`],
            open: true,
          })
        }
        listColumns={[
          {
            Header: ' ',
            canGroupBy: false,
            canSort: false,
            columns: [
              {
                Header: 'Naam',
                accessor: `name${firestoreCode}`,
                gridProps: {
                  xs: 9,
                  sm: 9,
                  md: 9,
                },
              },
              {
                Header: 'Gearchiveerd',
                accessor: `archived${firestoreCode}`,
                gridProps: {
                  xs: 3,
                  sm: 3,
                  md: 3,
                },
                Cell: data => (
                  <Grid item>
                    {data.row.original[`archived${firestoreCode}`] && (
                      <Chip label='Gearchiveerd' />
                    )}
                  </Grid>
                ),
              },
            ],
          },
        ]}
        labelMultiple='keys'
        labelSingle='key'
        breadcrumbs={breadcrumbArray}
        firestoreSettingsPath={firestoreSettingsPath}
        firestoreCode={firestoreCode}
      />
    </>
  )
}

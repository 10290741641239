// @ts-ignore
const audioContext = new (window.AudioContext || window?.webkitAudioContext)()

type slideAudioBufferTypes = {
  /** AudioBuffer to slice */
  audioBuffer: AudioBuffer
  /** Starttime in seconds */
  start: number
  /** Endtime in seconds */
  end: number
}

export const sliceAudioBuffer = ({
  audioBuffer,
  start,
  end,
}: slideAudioBufferTypes) => {
  const duration = audioBuffer.duration
  const channels = audioBuffer.numberOfChannels
  const rate = audioBuffer.sampleRate

  if (start < 0) throw new RangeError('start time must be greater than 0')
  if (end > duration) throw RangeError(`End time must be less than ${duration}`)

  const startOffset = rate * start
  const endOffset = rate * end
  const frameCount = endOffset - startOffset
  const newArrayBuffer = audioContext.createBuffer(
    channels,
    endOffset - startOffset,
    rate,
  )
  const anotherArray = new Float32Array(frameCount)
  const offset = 0

  for (var channel = 0; channel < channels; channel++) {
    audioBuffer.copyFromChannel(anotherArray, channel, startOffset)
    newArrayBuffer.copyToChannel(anotherArray, channel, offset)
  }

  return newArrayBuffer
}

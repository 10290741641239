import { useFirebase, useFirestore } from 'react-redux-firebase'
import { onError } from '../../components/Form'

type deleteLabels = {
  deleteFilesFailed: string
}
type deleteFilesType = {
  filesToDelete: any[]
  deleteFirestoreDoc?: boolean
}
const defaultLabels: deleteLabels = {
  deleteFilesFailed: 'Bestanden verwijderen mislukt.',
}

export const useFilesDelete = ({
  onError,
  labels = defaultLabels,
}: { labels?: deleteLabels } & onError) => {
  const firebase = useFirebase()
  const firestore = useFirestore()
  return async ({
    filesToDelete,
    deleteFirestoreDoc = true,
  }: deleteFilesType) => {
    try {
      const filesDeleted: Array<any> = []
      const deleteFile = async file => {
        await firebase.storage().ref(file.fullPath).delete()
        if (deleteFirestoreDoc) {
          const documentPath =
            file.customMetadata?.filesStorePath || file.filesStorePath

          const documentToDelete = await firestore
            .collection(documentPath)
            .where('fullPathDocu', '==', file.fullPathDocu || file.fullPath)
            .get()

          if (!documentToDelete.empty) {
            let deleteDocID
            documentToDelete.docs.forEach(doc => {
              deleteDocID = doc.id
            })
            await firestore.delete(`${documentPath}/${deleteDocID}`)
          }
        }
        filesDeleted.push(file)
      }

      await Promise.all(
        filesToDelete.map(async file => {
          await deleteFile(file)
          console.info(`file named: '${file.name}' deleted`)
        }),
      )
      console.info(`Total files deleted: ${filesDeleted.length}`)
    } catch (error) {
      onError({
        error,
        snackbarMessage: `${labels.deleteFilesFailed} ${error}`,
      })
    }
  }
}

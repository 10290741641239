import { menuLayoutType } from 'app/router/types'
import { pointOfSaleRoutes } from './pointOfSaleRoutes'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'

export const pointOfSaleMenu: menuLayoutType[] = [
  {
    name: 'Kassa',
    icon: PointOfSaleIcon,
    route: pointOfSaleRoutes.pointOfSalePage,
    subMenu: [
      {
        name: 'Indeling bewerken',
        icon: PointOfSaleIcon,
        route: pointOfSaleRoutes.pointOfSaleLayout,
      },
    ],
  },
]

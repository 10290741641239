import React from 'react'
import { FormPaper, PageContainer } from '@fivano/core'
import { capitalize } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { ServicesList } from './ServicesList'
import { FloatingButton } from '@fivano/core'
import { servicesConfig } from '@fivano/models'

export const ServicesPage = () => {
  const history = useHistory()
  const pluralLabel = servicesConfig.labels.plural

  const breadcrumbArray = [
    { title: 'Home', path: '/' },
    { title: `${capitalize(pluralLabel)}`, path: `/${pluralLabel}` },
  ]

  return (
    <PageContainer maxWidth='lg'>
      <Breadcrumbs breadcrumbs={breadcrumbArray} />
      <FormPaper>
        <ServicesList
          onClick={(_, data) => {
            history.push(`/${pluralLabel}/${data.original._id}/formulier`)
          }}
        />
        <FloatingButton
          label='Dienst toevoegen'
          onClick={() => {
            history.push(`/diensten/formulier`)
          }}
        />
      </FormPaper>
    </PageContainer>
  )
}

import React from 'react'
import { useFirestore } from 'react-redux-firebase'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Form, CheckboxGroup } from '@fivano/core'
import { Box, Grid, Typography, Button } from '@mui/material'
import { AutoComplete } from 'app/components/AutoComplete'
import { OptionType } from 'types/CommonTypes'
import { useSelector } from 'hooks/useSelector'
import { functions } from 'index'
import { useErrorLogger } from 'hooks/useErrorLogger'

type UserSettingsProps = {
  id?: string
  claims: any
  locations: Array<any>
  locationsAccess: Array<string>
  updatedAt?: any
  createdAt?: any
  startDate: number | Date | string
  endDate: number | Date | string
  rolesUser: Array<OptionType>
  roles: Array<OptionType>
  uid: string
}

type UserSettingsFormProps = {
  onSubmit?: any
  onClose?: any
  defaultValues?: any
}

export const UserSettingsForm = ({
  onSubmit,
  onClose,
  defaultValues,
}: UserSettingsFormProps) => {
  const errorLogger = useErrorLogger()
  const firestore = useFirestore()

  const profile = useSelector(state => state.firebase.profile)
  const isSuperAdmin =
    profile.emailPers === 'twan.keizer@fivano.com' ||
    profile.uid === '9Swr9lIXyzZRdTpuNXPzMgIX8eYV'

  useFirestoreConnect([
    {
      collection: 'appSettings/roleProfiles/roleProfiles',
      storeAs: 'roleProfiles',
    },
  ])
  const roleProfiles = useSelector(state => state.firestore.data.roleProfiles)

  const getClaims = uid => {
    functions
      .httpsCallable('getClaims')(uid)
      .then(response => {
        console.warn(response)
      })
  }

  const updateUserSettings = async (data: UserSettingsProps) => {
    const locationsAccess: Array<string> = ['publicLocation']
    data.locations.forEach(location => {
      locationsAccess.push(location.value)
    })
    data.locationsAccess = locationsAccess
    await firestore.update(`users/${data.uid}`, data)
    // collect roles to select claims
    const roles: Array<string> = []
    data.roles.forEach(role => {
      roles.push(role.value)
    })

    // create claimObject based on roles and roleprofiles with all claims and level a user has access to
    const claimObject = {}
    Object.entries(roleProfiles).forEach(([key, value]: any) => {
      if (roles.includes(value.valueRopr)) {
        Object.entries(value.claimsObjectRopr).forEach(([k, v]: any) => {
          if (claimObject[k] && v > claimObject[k]) {
            claimObject[k] = v
          } else if (!claimObject[k]) {
            claimObject[k] = v
          }
        })
      }
    })
    data.claims = claimObject

    functions
      .httpsCallable('setClaims')({
        uid: defaultValues.uid,
        claims: data.claims,
      })
      .then(response => {
        if (response.data.status === 'success') {
          firestore.update({ collection: 'users', doc: defaultValues.id }, data)

          onClose()
        }
      })
  }

  return (
    <>
      <Form
        docLabel='Gebruiker instellingen'
        editingDocID={defaultValues?.id}
        updateDoc={updateUserSettings}
        createDoc={updateUserSettings}
        docData={defaultValues}
        hasDetails={true}
        wrapInside='modal'
        modalMaxWidth='sm'
        onCloseForm={onClose}
        onError={errorLogger}
        formInputs={({ formObject }) => (
          <>
            <Grid container>
              <Grid item>
                <Typography variant='body1' gutterBottom>
                  {defaultValues.firstNamePers} {defaultValues.middleNamePers}{' '}
                  {defaultValues.lastNamePers}
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  Gebruiker ID: {defaultValues.uid}
                </Typography>
              </Grid>
              <Grid item style={{ marginLeft: 'auto' }}>
                <Button
                  size='small'
                  onClick={() => getClaims({ uid: defaultValues.uid })}
                >
                  LOG CLAIMS
                </Button>
              </Grid>
            </Grid>
            <Box p={1} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='body1'>Rollen instellen</Typography>
                <Typography variant='body2' color='textSecondary' gutterBottom>
                  De rollen van een gebruiker geven toegang tot bepaalde
                  deelnemer gegevens en bepaalde applicatie onderdelen.
                </Typography>
                <CheckboxGroup
                  name='roles'
                  row
                  formObject={formObject}
                  options={
                    roleProfiles
                      ? Object.entries(roleProfiles).reduce(
                          (total: any, [key, value]: any) => {
                            if (value.valueRopr !== 'dev' || isSuperAdmin) {
                              total.push({
                                value: value.valueRopr,
                                label: value.nameRopr,
                              })
                            }
                            return total
                          },
                          [],
                        )
                      : []
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>Locaties instellen</Typography>
                <Typography variant='body2' color='textSecondary' gutterBottom>
                  Een gebruiker krijgt toegang tot alle deelnemers geplaatst
                  onder de locaties die zijn toegewezen aan de gebruiker. De
                  rollen bepalen welke gegevens de gebruiker van een deelnemer
                  mag zien.
                </Typography>
                <Box m={1} />
                <AutoComplete
                  multiple
                  multipleLimit={9}
                  name='locations'
                  formObject={formObject}
                  collection='locations'
                  labelKeys={['nameLoca']}
                  searchKey='keywordsLoca'
                  rules={{
                    required: 'Verplicht veld',
                    validate: {
                      minLength: value => {
                        return value.length < 10
                          ? true
                          : 'Maximaal 9 locaties per gebruiker'
                      },
                    },
                  }}
                  error={formObject.formState.errors.locations !== undefined}
                />
              </Grid>
            </Grid>
          </>
        )}
      />
    </>
  )
}

import React from 'react'
import { menuItemType } from 'app/router/types'
import NestedMenuItem from './NestedMenuItem'
import { MenuItemLink } from './MenuItemLink'

export const MenuItem = ({
  menuItem,
  open,
  onChangeRoute,
  currentRoute,
}: menuItemType) => {
  const isParentActive = menuItem.route.path === currentRoute
  const isChildActive =
    menuItem?.subMenu &&
    menuItem.subMenu.filter(i => i?.route?.path === currentRoute).length > 0

  const handleRoute = () => onChangeRoute(menuItem.route.path)
  return menuItem?.subMenu?.length ? (
    /** Opened menu with MenuItem that has subMenu */
    open ? (
      /** When parent or child route is current route display child menu items under main menu item */
      isParentActive || isChildActive ? (
        <>
          <MenuItemLink
            path={menuItem.route.path}
            icon={menuItem?.icon}
            label={menuItem.name}
            open={open}
            hasMenu
            isParentActive={isParentActive}
            isChildActive={isChildActive}
            onClick={handleRoute}
          />
          {(isParentActive || isChildActive) &&
            menuItem.subMenu.map(item => (
              <MenuItemLink
                key={`${item.route.path}${item.name}`}
                path={item.route.path}
                label={item.name}
                subMenu
                open
                isParentActive={item.route.path === currentRoute}
                onClick={() => onChangeRoute(item.route.path)}
              />
            ))}
        </>
      ) : (
        /** When parent or child route is NOT current route display nested menu items under main menu item */
        <NestedMenuItem
          menuItemContent={
            <MenuItemLink
              path={menuItem.route.path}
              icon={menuItem?.icon}
              label={menuItem.name}
              open={open}
              hasMenu
              disableTooltip
              isParentActive={isParentActive}
              isChildActive={isChildActive}
              onClick={handleRoute}
            />
          }
          onClick={handleRoute}
          parentMenuOpen={true}
        >
          <MenuItemLink
            path={menuItem.route.path}
            icon={menuItem?.icon}
            label={menuItem.name}
            disableTooltip
            open
            onClick={handleRoute}
          />
          {menuItem.subMenu.map(item => (
            <MenuItemLink
              key={`${item.route.path}${item.name}`}
              path={item.route.path}
              icon={item.icon}
              label={item.name}
              disableTooltip
              open
              onClick={() => onChangeRoute(item.route.path)}
            />
          ))}
        </NestedMenuItem>
      )
    ) : (
      /** Collapsed menu with MenuItem that has subMenu */
      <NestedMenuItem
        menuItemContent={
          <MenuItemLink
            path={menuItem.route.path}
            icon={menuItem?.icon}
            label={menuItem.name}
            open={open}
            disableTooltip
            isParentActive={isParentActive}
            onClick={handleRoute}
          />
        }
        onClick={handleRoute}
        parentMenuOpen={true}
      >
        <MenuItemLink
          path={menuItem.route.path}
          icon={menuItem?.icon}
          label={menuItem.name}
          disableTooltip
          open
          onClick={handleRoute}
        />
        {menuItem?.subMenu?.map(item => (
          <MenuItemLink
            key={`${item.route.path}${item.name}`}
            path={item.route.path}
            icon={item?.icon}
            label={item.name}
            disableTooltip
            open
            onClick={handleRoute}
          />
        ))}
      </NestedMenuItem>
    )
  ) : (
    /** When there is no submenu render menu item*/
    <MenuItemLink
      path={menuItem.route.path}
      icon={menuItem?.icon}
      label={menuItem.name}
      open={open}
      isParentActive={isParentActive}
      onClick={handleRoute}
    />
  )
}

import React from 'react'
import useMongo from 'hooks/useMongo'
import { courseCompetenceScoresConfig } from '@fivano/models'
import { capitalize, Grid } from '@mui/material'
import { Form, TextField } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { useLocation } from 'react-router-dom'

const docLabel = capitalize(courseCompetenceScoresConfig.labels.single)

export const CourseCompetenceScoresForm = ({
  onClose,
  wrapInside,
  modalMaxWidth,
}) => {
  const errorLogger = useErrorLogger()
  const { updateDoc, createDoc, useRefetchDoc } = useMongo()
  const location = useLocation()
  const courseCompentenceScoreID: any = new URLSearchParams(
    location.search,
  ).get('id')
  const createCompetenceScore = async data => {
    return await createDoc({ collection: 'courseCompetenceScores', data })
  }

  const updateCompetenceScore = async data => {
    return await updateDoc({
      collection: 'courseCompetenceScores',
      docID: courseCompentenceScoreID,
      data,
    })
  }

  const { status, docData } = useRefetchDoc({
    collection: 'courseCompetenceScores',
    docID: courseCompentenceScoreID,
  })

  if (status === 'loading') return <p>Loading...</p>

  return (
    <Form
      onCloseForm={onClose}
      wrapInside={wrapInside}
      modalMaxWidth={modalMaxWidth}
      modelConfig={courseCompetenceScoresConfig}
      docLabel={docLabel}
      editingDocID={courseCompentenceScoreID}
      hasDetails={false}
      updateDoc={updateCompetenceScore}
      createDoc={createCompetenceScore}
      docData={docData}
      onError={errorLogger}
      formInputs={({ formObject }) => (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              formObject={formObject}
              name='name'
              label='Naam score'
              rules={{ required: 'Verplicht veld' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              formObject={formObject}
              name='description'
              label='Score omschrijving'
              rules={{ required: 'Verplicht veld' }}
            />
          </Grid>
          {/**
          <Grid item xs={12}>
            <Image/Logopicker
             * name='image'
             * label='score logo'
             * formObject={formObject}
             *  /> 
          </Grid>
          */}
        </Grid>
      )}
    />
  )
}

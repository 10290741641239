import { useDndContext } from '@dnd-kit/core'
import { CategoryCard } from './CategoryCard'
import { Position } from './SortableContainer'
import { SortableItemContainer } from './SortableItem'

export const SortableOverlay = ({
  items,
  activeID,
  draggingID,
  activeIndex,
  ...props
}) => {
  const { activatorEvent, over } = useDndContext()
  const isKeyboardSorting = activatorEvent instanceof KeyboardEvent
  const overIndex = over?.id
    ? items.findIndex(item => item.id === over?.id)
    : -1

  return (
    <SortableItemContainer
      id={draggingID}
      {...props}
      clone
      insertPosition={
        isKeyboardSorting && overIndex !== activeIndex
          ? overIndex > activeIndex
            ? Position.After
            : Position.Before
          : undefined
      }
    >
      <div style={{ width: 130, height: 130 }}>
        <CategoryCard
          active={items?.find(item => item.id === activeID)?.id === draggingID}
          data={items.find(item => item.id === draggingID)}
        />
      </div>
    </SortableItemContainer>
  )
}

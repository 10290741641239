import React from 'react'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from '@mui/material'

const AccordionStyled = styled(Accordion)(() => ({
  width: '100%',
  height: 'auto',
  boxShadow: 'none',
  margin: '0px 0px !important',
  '& .Mui-expanded': {
    margin: '0px 0px !important',
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .root': {
    display: 'none',
    '&$expanded': {
      margin: 0,
    },
  },
  '& .content': {
    display: 'none',
  },
}))

const AccordionSummaryStyled = styled(AccordionSummary)(() => ({
  minHeight: '0px !important',
  padding: '0px 0px',
  cursor: 'auto',
  '& .Mui-expanded': {
    margin: '0px 0px',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px 0px',
    display: 'block',
  },
}))

const AccordionDetailsStyled = styled(AccordionDetails)(() => ({
  minHeight: 0,
  padding: 0,
  display: 'block',
}))

type AccordionBaseProps = {
  /**Content which is always visible on screen.*/
  visibleContent: JSX.Element
  /**Content which is shown/hidden depending on expanded.*/
  expandableContent: JSX.Element
  /**Boolean that sets expandableContent to be visible or not.*/
  expanded?: boolean
}

export function AccordionBase({
  visibleContent,
  expandableContent,
  expanded = false,
}: AccordionBaseProps) {
  return (
    <AccordionStyled expanded={expanded}>
      <AccordionSummaryStyled tabIndex={-1}>
        {visibleContent}
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>{expandableContent}</AccordionDetailsStyled>
    </AccordionStyled>
  )
}

import { useSelector } from 'hooks/useSelector'
import { convertToDate } from 'utils/convertToDate'
import { keywordsCreator } from '../../utils/keywordsCreator'

type DataStandardTypes = {
  /** Data wherefor datastandards are created */
  data: any
  /** Data extension name (example firstNamePers dataName: Pers) */
  dataName: string
  /** Is the data edited of new, default: false */
  editForm?: boolean
  /** uid of signed in user */
  uid?: string
  /** Array of string keywords that should be searchable */
  keywords?: string[]
  /** Adds archived${dataName} to data which defaults to false, set this to true to archive when editing form */
  archived?: boolean
}

/** React hook to create data standards that every doc should use */
export const useDataStandards = () => {
  const profile = useSelector(state => state.firebase.profile)
  /** React hook functions to create data standards that every doc should use  */
  const createDataStandards = ({
    data,
    dataName,
    editForm = false,
    uid,
    keywords,
    archived,
  }: DataStandardTypes) => {
    const newObject: any = {}
    if (archived !== undefined) {
      newObject[`archived${dataName}`] = archived
    }

    if (!data?.[`archived${dataName}`]) {
      newObject[`untilDate${dataName}`] = ''
    } else {
      newObject[`untilDate${dataName}`] = new Date()
    }
    // updatedAt and updatedBy should always be created (this enables easier sorting)
    newObject[`updatedAt${dataName}`] = new Date()
    newObject[`updatedBy${dataName}`] = uid || profile.uid || 'NO_UID_FOUND'

    if (!editForm) {
      // new documents should have a createdAt, createdBy and fromData
      newObject[`createdAt${dataName}`] = new Date()
      newObject[`createdBy${dataName}`] = uid ? uid : profile.uid
    } else {
      // fix createdAt function inside object bug
      if (data[`createdAt${dataName}`]) {
        newObject[`createdAt${dataName}`] = convertToDate(
          data[`createdAt${dataName}`],
        )
      }
    }
    if (data[`fromDate${dataName}`]) {
      // fix fromDate function inside object bug
      newObject[`fromDate${dataName}`] = convertToDate(
        data[`fromDate${dataName}`],
      )
    }
    if (keywords) {
      newObject[`keywords${dataName}`] = keywordsCreator(keywords)
    }
    return newObject
  }
  return createDataStandards
}

import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { styled } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { IconButton } from '../IconButton'

const NavigationButton = styled('div')(({ theme }) => ({
  boxShadow: theme.shadows[2],
  color: 'black',
  position: 'absolute',
  transform: 'translateY(-50%)',
  bottom: '60px',
  zIndex: 1000,
  backgroundColor: 'white',
  borderRadius: 25,
}))

const IconButtonLeft = styled(NavigationButton)(({ theme }) => ({
  left: '0px',
  [theme.breakpoints.down('sm')]: {
    left: '8px',
  },
}))

const IconButtonRight = styled(NavigationButton)(({ theme }) => ({
  right: '0px',
  [theme.breakpoints.down('sm')]: {
    right: '8px',
  },
}))

const DivStyled = styled('div')(() => ({
  position: 'relative',
  width: '100%',
}))

type CarouselProps = {
  /**Array of items that are visible*/
  slides: Array<any>
  /**Number of how many items are visible per slide*/
  slidesPerView: number
  /**Boolean whether the numbers of the slides are visible*/
  showNumbers?: boolean
  /**Extra options*/
  sliderOptions?: any
  /**Distance in pixels between items*/
  spaceBetween?: number
}

export const Carousel = ({
  slides,
  showNumbers = false,
  slidesPerView,
  ...sliderOptions
}: CarouselProps) => {
  const [swiper, setSwiper] = useState<any>(null)
  const [slideIndex, setSlideIndex] = useState<number>(0)
  const slideTo = (index: number) => swiper?.slideTo(index)
  const handleClick = (changeWith: number) => {
    slideTo(slideIndex + changeWith)
  }
  const handleSlideChange = (slider: any) => {
    setSlideIndex(slider.activeIndex)
  }
  if (process.env.NODE_ENV === 'production') {
    console.warn(
      `Carousel warning (Fivano): remember to import the swiper.styles.css seperatly, 
      NextJS cannot consume packages where stylesheets are loaded. 
      Use import 'swiper/swiper.min.css' to import the styles`,
    )
  }

  return (
    <>
      <Swiper
        onSlideChange={handleSlideChange}
        onSwiper={setSwiper}
        {...sliderOptions}
      >
        {slides.map((slide: any, index: number) => (
          <SwiperSlide
            style={{ display: 'flex', width: '100%' }}
            key={`slide${index}`}
          >
            {slide}
          </SwiperSlide>
        ))}
      </Swiper>
      {slides.length > slidesPerView && (
        <DivStyled>
          {!(slideIndex <= 0) && (
            <IconButtonLeft>
              <IconButton
                label='Vorige'
                onClick={() => handleClick(-1)}
                size='large'
              >
                <NavigateBefore />
              </IconButton>
            </IconButtonLeft>
          )}
          {!(slideIndex >= slides.length - 1) && (
            <IconButtonRight>
              <IconButton
                label='Volgende'
                onClick={() => handleClick(1)}
                size='large'
              >
                <NavigateNext />
              </IconButton>
            </IconButtonRight>
          )}
        </DivStyled>
      )}
    </>
  )
}

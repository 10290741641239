import React from 'react'
import { Box } from '@mui/material'
import useMongo from 'hooks/useMongo'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import {
  FloatingButton,
  PageContainer,
  ActionMenu,
  MenuButton,
} from '@fivano/core'
import EditIcon from '@mui/icons-material/Edit'
import { CourseCompetenceScoresForm } from './CourseCompetenceScoresForm'

import { DataGridNew } from 'app/components/DataGridNew'
import { useModalRoute } from 'hooks/useModalRoutes'

const courseCompetenceScoresColumnsDesktop = [
  {
    field: 'name',
    headerName: 'Score naam',
    width: 160,
    disableColumnMenu: true,
  },
  {
    field: 'description',
    headerName: 'Beschrijving',
    width: 300,
    disableColumnMenu: false,
  },
  {
    field: 'image',
    headerName: 'Score logo',
    width: 300,
    disableColumnMenu: true,
  },
]

const courseCompetenceScoresColumnsMobile = [
  {
    field: 'name',
    headerName: 'Competentie score',
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: 'description',
    headerName: 'Beschrijving',
    width: 50,
    disableColumnMenu: true,
  },
  {
    field: 'image',
    headerName: 'Score foto',
    width: 50,
    disableColumnMenu: true,
  },
]

export const CourseCompetenceScoresList = () => {
  const { modalData, closeModal, openModal } = useModalRoute()
  const { useQueryDocs } = useMongo()

  const { docsData } = useQueryDocs({
    collection: 'courseCompetenceScores',
    array: true,
  })

  return (
    <PageContainer maxWidth='lg'>
      <Breadcrumbs />
      <Box p={2}>
        {modalData.open && (
          <CourseCompetenceScoresForm
            wrapInside='modal'
            modalMaxWidth='sm'
            onClose={() => {
              closeModal()
            }}
          />
        )}
        <Box marginTop={1}>
          <DataGridNew
            name='competence scores'
            rows={docsData}
            columns={courseCompetenceScoresColumnsDesktop}
            columnsMobile={courseCompetenceScoresColumnsMobile}
            hideFooterRowCount={true}
            onRowClick={rowData => openModal(rowData.row._id)}
            rowActions={{
              renderCell: params => (
                <ActionMenu
                  iconPosition='vertical'
                  minWidth={250}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  <MenuButton
                    label={`Bewerk competentie score`}
                    icon={<EditIcon />}
                    onClick={() => openModal(params.id)}
                  />
                  {/* <MenuButton
                        label={
                          params.row?.archivedPers
                            ? 'Deelnemer dearchiveren'
                            : 'Deelnemer archiveren'
                        }
                        icon={<ArchiveIcon />}
                        onClick={() => handleArchive(params.row)}
                      /> */}
                </ActionMenu>
              ),
            }}
          />
        </Box>
        <FloatingButton
          label='Competentie score toevoegen'
          onClick={() => openModal()}
        />
      </Box>
    </PageContainer>
  )
}

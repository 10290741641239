import React from 'react'
import { Chip, Tooltip, Grid } from '@mui/material'
import { useFirebase } from 'react-redux-firebase'

type FileChipsProps = {
  files: Array<any>
  fileDownloadLinkKey?: string
  onDelete: any
}

export function FileChips({
  files,
  fileDownloadLinkKey,
  onDelete,
}: FileChipsProps) {
  const firebase = useFirebase()
  const handleClickDocument = (event, fullPath) => {
    event.preventDefault()
    firebase
      .storage()
      .ref(fullPath)
      .getDownloadURL()
      .then(response => {
        //@ts-ignore
        window.open(response, '_blank')
      })
  }
  return (
    <Grid container spacing={1}>
      {files?.length > 0 &&
        files.map((file, index) => {
          const noLink = !!(
            fileDownloadLinkKey && file[fileDownloadLinkKey] === undefined
          )
          return (
            <Grid item key={`file${file.name}${index}`} id={file.name}>
              <Tooltip
                title='Download'
                disableHoverListener={noLink}
                disableTouchListener={noLink}
                disableFocusListener={noLink}
              >
                <Chip
                  {...(!noLink && {
                    onClick: event => {
                      event.preventDefault()
                      handleClickDocument(event, file.fullPath)
                    },
                    variant: 'outlined',
                    color: 'primary',
                  })}
                  label={
                    noLink
                      ? file.name
                      : file?.customMetadata?.originalName || file.name
                  }
                  onDelete={() => onDelete(file)}
                />
              </Tooltip>
            </Grid>
          )
        })}
    </Grid>
  )
}

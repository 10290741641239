import React, { useEffect } from 'react'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { useFirestore } from 'react-redux-firebase'
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol'
import {
  Category,
  Close,
  Image,
  MusicNote,
  Subject,
  Title,
} from '@mui/icons-material'
import { FileInput } from 'app/components/FileInput'
import { MusicTrackWaveMaker } from './MusicTrackWaveMaker'
import { musicFilesFormat } from './MusicTrackForm'
import { SlugInput } from './SlugInput'
import { useSelector } from 'hooks/useSelector'
import { optionsCreator } from 'utils/optionsCreator'
import {
  TextField,
  FormRow,
  AvatarPicker,
  Slider,
  AutoSelect,
  IconButton,
  useConfirmModal,
} from '@fivano/core'
import { useFieldArray } from 'react-hook-form'
import { useMusicTrackDelete } from './useMusicTrackDelete'

const bpmMarks = (min, max, step) =>
  Array.from(Array(Math.floor((max - min) / step) + 1)).map((v, i) => ({
    label: `${min + i * step}`,
    value: min + i * step,
  }))

const FormPaper = ({ children }) => {
  return (
    <Grid item>
      <Paper style={{ padding: 16 }}>
        <Grid container spacing={1}>
          {children}
        </Grid>
      </Paper>
    </Grid>
  )
}

export const createImageURL = (fileObject: any) =>
  fileObject
    ? `https://firebasestorage.googleapis.com/v0/b/${
        fileObject.bucket
      }/o/${fileObject.fullPath.replace(/\//g, '%2F')}?alt=media`
    : ''

export const MusicTrackFormInputs = ({
  formObject,
  fileChangesState,
  docData,
}) => {
  const musicTrackID = docData[0]?._id
  const firestore = useFirestore()

  useEffect(() => {
    firestore.get({
      collection: 'appSettings',
      doc: 'keys',
      storeAs: 'musicKeys',
    })
    firestore.get({
      collection: 'playlists',
      storeAs: 'playlists',
    })
  }, [firestore])

  const keys = useSelector(state =>
    optionsCreator(
      state.firestore.ordered['musicKeys']?.[0]?.options,
      'nameKey',
      'idKey',
    ),
  )

  const { fields, append, remove } = useFieldArray({
    control: formObject.control,
    name: 'playlists',
  })

  const allPlaylists = useSelector(state =>
    optionsCreator(
      state.firestore.ordered.playlists,
      'namePlaylist',
      'idPlaylist',
    ),
  )
  const selectedPlaylists = formObject.watch('playlists')
  const playlistOption = allPlaylists.filter(
    playlist =>
      !selectedPlaylists.find(
        selectedPlaylist =>
          selectedPlaylist?.playlist?.value === playlist.value,
      ),
  )
  const confirmModal = useConfirmModal()

  const { deleteMusicTrack } = useMusicTrackDelete()
  return (
    <Grid container direction='column' spacing={2}>
      {/** GENERAL PRODUCT FIELDS */}
      <FormPaper>
        <FormRow helperText='' icon={<Title />}>
          <TextField
            name='nameTrack'
            label='Naam'
            formObject={formObject}
            rules={{ required: 'Voer een naam in' }}
          />
          <SlugInput
            formObject={formObject}
            name='slug'
            sourceSlug='nameTrack'
            rootSlug='https://songstarter.com/tracks'
            rules={{
              validate: {
                duplicate: async value =>
                  !!musicTrackID ||
                  (await firestore
                    .get({
                      collection: 'musicTracks',
                      where: ['slugTrack', '==', value],
                    })
                    .then((response: any) => response.docs.length === 0)
                    .catch(() => false)) ||
                  'Er bestaat al een track onder deze link, kies een andere link',
              },
            }}
          />
        </FormRow>

        <FormRow helperText='' icon={<Subject />}>
          <TextField
            name='description'
            label='Omschrijving'
            rows={4}
            formObject={formObject}
            maxRows={20}
            multiline
            rules={{ required: true }}
          />
        </FormRow>

        <FormRow helperText='' icon=''>
          <Typography>Spaans</Typography>
        </FormRow>

        <FormRow helperText='' icon={<Title />}>
          <TextField
            name='nameES'
            formObject={formObject}
            label='Naam (Spaans)'
          />
        </FormRow>

        <FormRow helperText='' icon={<Subject />}>
          <TextField
            label='Omschrijving (Spaans)'
            name='descriptionES'
            formObject={formObject}
            rows={4}
            maxRows={20}
            multiline
          />
        </FormRow>
      </FormPaper>

      {/** IMAGE PICKER */}
      <FormPaper>
        <FormRow helperText='' icon={<Image />}>
          <AvatarPicker
            name='images'
            title='Afbeelding instellen'
            imageURL={createImageURL(docData[0]?.images?.[0])}
            control={formObject.control}
            size={512}
            previewSize={256}
            avatarBorderRadius={0}
            returnFile
          />
        </FormRow>
      </FormPaper>

      {/** MP3 AND WAVES EDITOR */}
      <FormPaper>
        <FormRow helperText='' icon={<MusicNote />}>
          <MusicTrackWaveMaker formObject={formObject} />
        </FormRow>
      </FormPaper>

      {/** Playlist */}
      <FormPaper>
        <FormRow helperText='' icon={<Category />}>
          <Box
            sx={{
              paddingTop: 2,
              padding: 1,
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: theme =>
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              borderRadius: '4px',
              marginBottom: 2,
            }}
          >
            {fields.map((item, index) => {
              return (
                <Grid container spacing={1} key={item.id}>
                  <Grid item flexGrow={1}>
                    <AutoSelect
                      name={`playlists.${index}.playlist`}
                      label='Selecteer playlist'
                      rules={{ required: 'Verplicht veld' }}
                      formObject={formObject}
                      options={playlistOption}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name={`playlists.${index}.quality`}
                      label='Ranking'
                      rules={{ required: 'Verplicht veld' }}
                      formObject={formObject}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      label='Verwijderen'
                      type='button'
                      onClick={() => remove(index)}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            })}
            <Button
              variant='outlined'
              fullWidth
              onClick={() => {
                append({ playlist: null, quality: undefined })
              }}
            >
              Playlist toevoegen
            </Button>
          </Box>
        </FormRow>

        <FormRow helperText='' icon=''>
          <AutoSelect
            label='Key'
            name='keyTrack'
            rules={{ required: 'Verplicht veld' }}
            formObject={formObject}
            options={keys}
          />
        </FormRow>

        <FormRow helperText='' icon=''>
          <Slider
            name='bpm'
            label='BPM'
            formObject={formObject}
            defaultValue={100}
            min={50}
            max={180}
            rules={{ required: 'Verplicht veld' }}
            options={bpmMarks(50, 180, 10)}
            showInput
          />
        </FormRow>
      </FormPaper>

      {/** AUDIO FIELDS */}
      <FormPaper>
        <FormRow helperText='' icon={<EuroSymbolIcon />}>
          <TextField
            label='Credits'
            name='credits'
            formObject={formObject}
            rules={{ required: 'Verplicht veld' }}
            type='number'
          />
        </FormRow>
      </FormPaper>

      {/** DOWNLOAD FIEDS */}
      <FormPaper>
        <FormRow helperText='' icon=''>
          {musicFilesFormat.map(format => (
            <FileInput
              required
              key={format.id}
              name={format.id}
              maxSize={5000}
              formObject={formObject}
              acceptedFileTypes={format.fileType}
              maxFiles={1}
              fileInputTitle={format.label}
            />
            // <NEWFileInput
            //   required
            //   key={format.id}
            //   name={format.id}
            //   maxSize={5000 * 1024 * 1024}
            //   formObject={formObject}
            //   acceptedFileTypes={format.fileType}
            //   maxFiles={1}
            //   label={format.label}
            //   onError={errorLogger}
            //   fileChangesState={fileChangesState}
            //   profile={profile}
            //   filesStorePath="???????????"
            // />
          ))}
        </FormRow>
      </FormPaper>

      {/** DELETE TRACKS */}
      {!!musicTrackID && (
        <FormPaper>
          <FormRow helperText='' icon=''>
            <Button
              color='error'
              variant='contained'
              onClick={() =>
                confirmModal({
                  onConfirm: () => deleteMusicTrack(musicTrackID),
                  cancelLabel: 'Annuleren',
                  confirmLabel: 'Verwijderen',
                  title: 'Track verwijderen?',
                  message:
                    'De track en bijbehorende bestanden worden definitief verwijdert',
                })
              }
            >
              Track verwijderen
            </Button>
          </FormRow>
        </FormPaper>
      )}
    </Grid>
  )
}

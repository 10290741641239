import React from 'react'
import { Box, Divider, Grid, Skeleton } from '@mui/material'

export const TimeRegistrationSkeleton = ({ number = 2 }) => {
  return (
    <>
      {[...Array(number)].map((_, index) => (
        <Grid key={index} container>
          <Grid item xs={11}>
            <Box p='0px 16px 0px 16px'>
              <Grid container spacing={1}>
                <Grid item xs={12} md={7}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <Skeleton animation='wave' width='50%' height={48} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Skeleton animation='wave' width='50%' height={48} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Skeleton animation='wave' width='50%' height={48} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Grid container spacing={1}>
                    <Grid item xs={3} md={4}>
                      <Skeleton animation='wave' width='60%' height={48} />
                    </Grid>
                    <Grid item xs={3} md={4}>
                      <Skeleton animation='wave' width='60%' height={48} />
                    </Grid>
                    <Grid item xs={3} md={4}>
                      <Skeleton animation='wave' width='60%' height={48} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      ))}
    </>
  )
}

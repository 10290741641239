import React from 'react'
import { AutoSelect } from '@fivano/core'
import { Grid, Typography } from '@mui/material'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { Person } from 'types'
import { useDataStandards } from 'hooks/useDataStandards'
import { Form } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'

export const ParticipantLocationAssignForm = ({
  onCloseForm,
  modalMaxWidth,
  wrapInside,
  editingDocID,
}) => {
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const createDataStandards = useDataStandards()
  const personData = useSelector(
    state => state.firestore.ordered[`persons_${editingDocID}`]?.[0],
  )
  const buildLocationAssignData = data => {
    const newPersonData: Person = { ...personData }
    const dataStandards = createDataStandards({
      data: personData,
      dataName: 'Pers',
      editForm: true, // use editForm to add updatedAtPers and updatedByPers
    })

    newPersonData.locationPers = data.locationPerson
    const newLocationsPers = [
      ...(newPersonData.locationsPers ? newPersonData.locationsPers : []),
    ]
    newLocationsPers?.push(data.locationPerson)
    newPersonData.locationIDPers = data.locationPerson.value
    return { ...newPersonData, ...dataStandards }
  }

  const updateLocationAssign = async data => {
    console.error('ParticipantLocationAssignForm should not use update', data)
  }

  const createLocationAssign = async data => {
    await firestore.set(
      `locations/${data.locationIDPers}/persons/${editingDocID}`,
      data,
    )
  }

  const errorLogger = useErrorLogger()

  return (
    <Form
      docLabel='Locatie'
      buildDocData={buildLocationAssignData}
      updateDoc={updateLocationAssign}
      createDoc={createLocationAssign}
      hasDetails={true}
      wrapInside={wrapInside}
      modalMaxWidth={modalMaxWidth}
      docData={{ personLocation: '' }}
      onCloseForm={onCloseForm}
      onError={errorLogger}
      formInputs={({ formObject }) => (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AutoSelect
              label='Nieuwe locatie persoon'
              formObject={formObject}
              name='locationPerson'
              options={profile.locations}
              disableCloseOnSelect={false}
              rules={{
                required: 'Verplicht veld',
                validate: {
                  duplicate: async location =>
                    (await firestore
                      .get(
                        `locations/${location?.value}/persons/${editingDocID}`,
                      )
                      .then(response => {
                        return !response.exists
                      })) ||
                    'Deze persoon bestaat al onder deze locatie, kies een andere locatie',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2' color='textSecondary' gutterBottom>
              De persoon wordt toegevoegd onder de geselecteerde locatie. Alleen
              persoongegevens worden verplaatst, andere gegevens zoals
              voortgang, traject, document en andere gegevens worden niet naar
              de locatie verplaatst. Om andere gebruikers toegang te geven tot
              deze data moeten zij deze locatie toegewezen hebben.
            </Typography>
          </Grid>
        </Grid>
      )}
    />
  )
}

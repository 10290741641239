import React, { useState } from 'react'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { Grid, Typography, Button, Box, styled } from '@mui/material'
import { ProgressBoardCardForm } from './ProgressBoardCardForm'
import { ProgressBoardCard } from './ProgressBoardCard'
import { SearchFilter } from 'app/components/SearchFilter'

const ButtonStyled = styled(Button)(() => ({
  height: 40,
  marginLeft: 8,
}))

export const ProgressBoardCards = ({ boardCardID, boardID, archivedBoca }) => {
  const [progressBoardProps, setProgressBoardProps] = useState({
    progressBoardCardID: undefined,
    open: false,
  })

  useFirestoreConnect([
    {
      collection: 'boards',
      doc: boardID,
      subcollections: [
        {
          collection: 'boardCards',
          doc: boardCardID,
          subcollections: [
            {
              collection: 'progressBoardCards',
            },
          ],
        },
      ],
      storeAs: `boardCards_${boardCardID}_progressBoardCards`,
    },
  ])

  const [search, setSearch] = useState('')

  const progressListData = useSelector(({ firestore: { ordered } }) =>
    ordered[`boardCards_${boardCardID}_progressBoardCards`]
      ?.slice()
      .sort((a, b) => b.dateTimePrbc - a.dateTimePrbc)
      .filter(
        progress =>
          progress.tagsPrbc.some(tag =>
            tag.label.toLowerCase().includes(search.toLowerCase()),
          ) ||
          progress.descriptionPrbc.toLowerCase().includes(search.toLowerCase()),
      ),
  )

  return (
    <>
      {progressBoardProps.open && (
        <ProgressBoardCardForm
          wrapInside='modal'
          boardID={boardID}
          boardCardID={boardCardID}
          editingDocID={progressBoardProps.progressBoardCardID}
          onCloseForm={() => {
            setProgressBoardProps({
              progressBoardCardID: undefined,
              open: false,
            })
          }}
        />
      )}

      <Grid container alignItems='center' wrap='nowrap'>
        <Grid item xs={12}>
          <Box py={1}>
            {progressListData?.length > 0 || search !== '' ? (
              <SearchFilter
                placeholder='Zoeken in notities'
                onSubmit={data => setSearch(data.searchField)}
              />
            ) : (
              <Typography>
                Er zijn nog geen voortgang items geschreven.
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item>
          <ButtonStyled
            variant='contained'
            disabled={archivedBoca}
            disableElevation
            color='primary'
            onClick={() =>
              setProgressBoardProps({
                progressBoardCardID: undefined,
                open: true,
              })
            }
          >
            Toevoegen
          </ButtonStyled>
        </Grid>
      </Grid>

      <Grid container direction='column' spacing={1}>
        {progressListData?.map(item => (
          <Grid key={item.id} item>
            <ProgressBoardCard
              onEdit={() => {
                setProgressBoardProps({
                  progressBoardCardID: item.id,
                  open: true,
                })
              }}
              boardID={boardID}
              progressBoardCardID={item.id}
              boardCardID={boardCardID}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid } from '@mui/material'
import { AutoSelect } from '@fivano/core'
import { useSelector } from 'hooks/useSelector'
import WeekendCalender from 'app/icons/WeekendCalender'
import { useForm } from 'react-hook-form'
import { HeaderType } from './types'
import { DateNavigation, ActionMenu, MenuButton } from '@fivano/core'
import { useFirestore } from 'react-redux-firebase'

export const Header = ({
  date,
  onChangeDate,
  onShowWeekend,
  onChangeLocation,
  onChangeGroup,
  loading,
}: HeaderType) => {
  const firestore = useFirestore()
  const formObject = useForm({ defaultValues: { datepicker: date } })
  const profile = useSelector(state => state.firebase.profile)
  const [showWeekend, setShowWeekend] = useState(false)

  // @ts-ignore
  const location: any = formObject.watch('location')
  useEffect(() => {
    if (location?.value) {
      firestore.get({
        collection: 'personGroups',
        where: [['locationID', '==', location?.value]],
        storeAs: 'personGroupOptions',
      })
    }
  }, [firestore, location])

  const personGroupOptions = useSelector(state =>
    state.firestore?.ordered?.personGroupOptions?.map(i => ({
      label: i.name,
      value: i.id,
    })),
  )
  return (
    <Grid container alignItems='center'>
      <DateNavigation
        name='date'
        minDate='01-01-1970'
        onChange={onChangeDate}
      />
      <Grid item marginLeft='10px'>
        <AutoSelect
          label='Locatie'
          formObject={formObject}
          name='location'
          options={profile.locations}
          disableCloseOnSelect={false}
          rules={{
            required: 'Verplicht veld',
          }}
          style={{ width: 200 }}
          onChange={(_, value) => onChangeLocation(value)}
        />
      </Grid>
      <Grid item marginLeft='10px'>
        <AutoSelect
          label='Groep'
          formObject={formObject}
          name='group'
          options={personGroupOptions || []}
          disableCloseOnSelect={false}
          rules={{
            required: 'Verplicht veld',
          }}
          style={{ width: 200 }}
          onChange={(_, value) => onChangeGroup(value)}
        />
      </Grid>
      <Grid item marginLeft='10px'>
        {loading && <CircularProgress />}
      </Grid>
      <Grid marginLeft='auto'>
        <ActionMenu iconPosition='vertical'>
          <MenuButton
            label={`${showWeekend ? 'Verberg' : 'Toon'} weekend`}
            icon={<WeekendCalender />}
            onClick={() => {
              setShowWeekend(!showWeekend)
              onShowWeekend()
            }}
          />
        </ActionMenu>
      </Grid>
    </Grid>
  )
}

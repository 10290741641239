import React, { useState } from 'react'
import {
  Grid,
  Typography,
  Box,
  Divider,
  capitalize,
  Theme,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { addDays, getHours, getMinutes } from 'date-fns'
import { useSelector } from 'hooks/useSelector'
import { useFirestore } from 'react-redux-firebase'
import { useDataStandards } from 'hooks/useDataStandards'

import LocationIcon from 'app/icons/LocationIcon'
import EventNoteIcon from '@mui/icons-material/EventNote'
import SubjectIcon from '@mui/icons-material/Subject'

import { checkTimeOverlap } from 'utils/checkTimeOverlap'
import { formatTimeStamp } from 'utils/formatTimeStamp'
import { roundOffSecondsToHours } from 'utils/roundOffSecondsToHours'
import { Form, TextField } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { DateTimeRow } from '../DateTimeRow'
import { GridSettingsProps, InputSettingProps } from '../types'

type OverlapErrorType = {
  index: number
  items: any[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconSection: {
      width: 60,
      color: theme.palette.grey[500],
    },
    icon: {
      margin: '8px 0px 0px 12px',
    },
    iconInputSection: {
      width: 'calc(100% - 64px)',
    },
    inputSection: {
      paddingLeft: 64,
    },
    datepicker: {
      marginBottom: 5,
    },
  }),
)

const gridSettings: GridSettingsProps = {
  datepicker: {
    xs: 12,
  },
  starttime: {
    xs: 12,
    md: 4,
  },
  duration: {
    xs: 12,
    md: 4,
  },
  endtime: {
    xs: 12,
    md: 4,
  },
}

const inputSettings: InputSettingProps = {
  datepickerProps: {
    name: 'startDateTire',
    label: 'Datum',
    variant: 'outlined',
    fullWidth: true,
  },
  starttimeProps: {
    name: 'startTimeTire',
    label: 'Starttijd',
    size: 'small',
    variant: 'outlined',
    fullWidth: true,
  },
  durationProps: {
    name: 'durationTire',
    label: 'Duur',
    size: 'small',
    variant: 'outlined',
  },
  endtimeProps: {
    name: 'endTimeTire',
    label: 'Eindtijd',
    size: 'small',
    variant: 'outlined',
    fullWidth: true,
  },
}

const TimeRegistrationWidgetFormConfig = {
  docLabel: 'Tijdregistratie',
  defaultValues: {
    startDateTire: new Date(new Date().setHours(0, 0, 0, 0)),
    startTimeTire: '',
    endTimeTire: '',
    textTire: '',
    durationTire: '',
    locationIDTire: '',
    personIDTire: '',
  },
}

export const TimeRegistrationWidgetForm = ({
  formProps: { timeRegistrationID, trackData, docData },
  onDataChanged,
  editingDocID,
  wrapInside,
  modalMaxWidth,
  onCloseForm,
}) => {
  const classes = useStyles()
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const isEditing = !!editingDocID
  const createDataStandards = useDataStandards()
  const [overlapError, setOverlapError] = useState<
    OverlapErrorType | undefined
  >(undefined)

  const buildTimeRegistrationData = async data => {
    setOverlapError(undefined)
    const dataStandards = createDataStandards({
      data,
      dataName: 'Tire',
      editForm: isEditing,
    })
    data.trackIDTire = trackData.id
    data.durationTire = parseInt(data.durationTire)
    data.personIDTire = profile.uid
    data.type = 'timeRegistrationWidget'
    data.companyCodeTire = trackData.locationTrack
    data.projectCodeTire = trackData.moduleTrack
    data.workCodeTire = { label: 'Werken', id: 'werken' }

    const nextDay = getHours(data.endTimeTire) + getMinutes(data.endTimeTire)
    if (nextDay === 0) data.endTimeTire = addDays(data.endTimeTire, 1)

    const newData = { ...data, ...dataStandards }

    return await new Promise(async (resolve, reject) => {
      const timeItems: any[] = []
      let overlapError: any = undefined
      await firestore
        .collection('timeRegistrations')
        .where('personIDTire', '==', profile.uid)
        .where('startTimeTire', '>=', newData.startTimeTire)
        .where('startTimeTire', '<=', addDays(newData.startTimeTire, 1))
        .get()
        .then(response => {
          response.docs.forEach((response, index) => {
            const item = response.data()
            item.id = response.id
            timeItems.push(item)
            const checkForError: any = checkTimeOverlap(
              timeRegistrationID,
              item.id,
              newData.startTimeTire.getTime() / 1000,
              newData.endTimeTire.getTime() / 1000,
              item.startTimeTire.seconds,
              item.endTimeTire.seconds,
              index,
            )
            if (checkForError?.error !== undefined) {
              overlapError = checkForError
            }
          })
          if (overlapError) {
            setOverlapError({ index: overlapError.index, items: timeItems })
            reject('Er zijn al uren geboekt op deze tijdstippen')
          } else {
            delete data.startDateTire
            onDataChanged(true)
            resolve(newData)
          }
        })
    })
  }

  const createTimeRegistrationData = async promise => {
    const data = await promise
    await firestore
      .collection('timeRegistrations')
      .add(data)
      .then(() => {
        const trackTotalDuration =
          (trackData.totalMinutesTrack ? trackData.totalMinutesTrack : 0) +
          data.durationTire
        firestore.update(
          `locations/${trackData.locationIDTrack}/persons/${data.personIDTire}/tracks/${trackData.id}`,
          { totalMinutesTrack: trackTotalDuration },
        )
      })
  }

  const updateTimeRegistrationData = async promise => {
    const data = await promise
    await firestore
      .update(`timeRegistrations/${timeRegistrationID}`, data)
      .then(() => {
        if (docData.durationTire !== data.durationTire) {
          const newDuration =
            trackData.totalMinutesTrack +
            (data.durationTire - docData.durationTire)
          firestore.update(
            `locations/${trackData.locationIDTrack}/persons/${data.personIDTire}/tracks/${trackData.id}`,
            { totalMinutesTrack: newDuration },
          )
        }
      })
  }
  const errorLogger = useErrorLogger()
  return (
    <Form
      docLabel={TimeRegistrationWidgetFormConfig.docLabel}
      editingDocID={editingDocID}
      buildDocData={buildTimeRegistrationData}
      updateDoc={updateTimeRegistrationData}
      createDoc={createTimeRegistrationData}
      docData={docData || TimeRegistrationWidgetFormConfig.defaultValues}
      wrapInside={wrapInside}
      modalMaxWidth={modalMaxWidth}
      onCloseForm={onCloseForm}
      hasDetails={false}
      onError={errorLogger}
      formInputs={({ formObject }) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item className={classes.iconSection}>
                  <LocationIcon className={classes.icon} />
                </Grid>
                <Grid item className={classes.iconInputSection}>
                  <Typography variant='h6'>
                    {trackData.locationTrack.label}
                  </Typography>
                  <Typography variant='body1'>
                    {trackData.moduleTrack.label}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item className={classes.iconSection}>
                  <EventNoteIcon className={classes.icon} />
                </Grid>
                <Grid item className={classes.iconInputSection}>
                  <Grid container spacing={2}>
                    <DateTimeRow
                      formObject={formObject}
                      inputSettings={inputSettings}
                      gridSettings={gridSettings}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.inputSection}>
                {overlapError?.items && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Box pb={1}>
                        <Grid item xs={12}>
                          <Typography variant='caption' color='error'>
                            Er bestaan al uren op of tussen deze tijdstippen.
                          </Typography>
                        </Grid>
                      </Box>
                      {overlapError?.items?.map((item, index) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            key={`${item.trackIDTire}_${index}`}
                          >
                            {index === 0 && (
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                            )}
                            <Grid container spacing={1}>
                              <Grid item>
                                <Typography
                                  variant='caption'
                                  color={
                                    overlapError.index === index
                                      ? 'error'
                                      : 'textPrimary'
                                  }
                                >
                                  {capitalize(
                                    formatTimeStamp(
                                      item.startTimeTire.seconds,
                                      'EEEEEE dd MMM yyyy',
                                    ),
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant='caption'
                                  display='inline'
                                  color={
                                    overlapError.index === index
                                      ? 'error'
                                      : 'textPrimary'
                                  }
                                >
                                  {formatTimeStamp(item.startTimeTire, 'HH:mm')}
                                  {' - '}
                                  {formatTimeStamp(item.endTimeTire, 'HH:mm')}
                                </Typography>
                                <Typography
                                  variant='caption'
                                  display='inline'
                                  color={
                                    overlapError.index === index
                                      ? 'error'
                                      : 'textPrimary'
                                  }
                                >
                                  {' '}
                                  ({roundOffSecondsToHours(item.durationTire)})
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item className={classes.iconSection}>
                  <SubjectIcon className={classes.icon} />
                </Grid>
                <Grid item className={classes.iconInputSection}>
                  <TextField
                    name='textTire'
                    formObject={formObject}
                    multiline
                    rows={4}
                    label='Opmerking'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

import React from 'react'
import { styled } from '@mui/material'
import Button, { ButtonTypeMap } from '@mui/material/Button'

const FloatingButtonWrapper = styled('div')(({ theme }) => ({
  top: 'calc(100% - 116px)',
  display: 'flex',
  zIndex: theme.zIndex.mobileStepper,
  position: 'sticky',
  justifyContent: 'flex-end',
}))

type FloatingButtonProps = {
  /**The visible label*/
  label: String
  /**Property that gets set with an Icon if the FloatingButton needs it*/
  icon?: any
  /**Function that gets triggered when the button is clicked*/
  onClick: () => void
  /**Boolean whether the button is disabled or not*/
  disabled?: boolean
  /**Possible layout variants for the button*/
  variant?: ButtonTypeMap['props']['variant']
  /**Custom color for the button*/
  color?: ButtonTypeMap['props']['color']
  customMargin?: string | number
}

export const FloatingButton = (props: FloatingButtonProps) => {
  const { label, icon, onClick, disabled, variant, customMargin, color } = props
  const Icon = icon
  return (
    <FloatingButtonWrapper
      style={{
        margin: customMargin
          ? `${customMargin}`
          : '16px 8px -56px calc(100% - 250px)',
      }}
    >
      <Button
        variant={variant ? variant : 'contained'}
        color={color ? color : 'primary'}
        size='large'
        disabled={disabled}
        startIcon={icon && <Icon />}
        onClick={onClick}
      >
        {label}
      </Button>
    </FloatingButtonWrapper>
  )
}

import React from 'react'
import { Typography, Grid, Box } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useFirestoreConnect } from 'react-redux-firebase'
import { SettingsContainer } from 'app/components/SettingsContainer'
import { AutoSelect } from '@fivano/core'
import { useDataStandards } from 'hooks/useDataStandards'
import { useHistory } from 'react-router-dom'
import { Form } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'

export const GeneralSettings = () => {
  const history = useHistory()
  useFirestoreConnect([
    {
      collection: 'appSettings',
      doc: 'applicationLogo',
      storeAs: 'applicationLogo',
    },
  ])

  const breadcrumbArray = [
    { title: 'Instellingen', path: '/instellingen' },
    { title: 'Algemeen', path: '/instellingen' },
  ]

  const createDataStandards = useDataStandards()

  const settingsFormConfig = {
    docLabel: 'Instellingen',
    defaultValues: {
      applicationName: '',
      microsoftAuth: false,
      microsoftTenantID: '',
      defaultSignInMethod: 'email',
    },
  }
  const buildSettings = data => {
    const dataStandards = createDataStandards({
      data,
      dataName: 'Settings',
      editForm: false,
      keywords: [data.applicationName],
    })
    return { ...data, ...dataStandards }
  }
  const updateSettings = async data => {
    // await firestore.set('appSettings/settings', data)
  }

  const createSettings = async data => {
    // await firestore.set('appSettings/settings', data)
  }
  const errorLogger = useErrorLogger()

  return (
    <>
      <Helmet>
        <title>Algemeen - Instellingen</title>
        <meta name='description' content='Algemeen - instellingen' />
      </Helmet>
      <SettingsContainer breadcrumbs={breadcrumbArray} showTitle={false}>
        <Box py={2} px={1}>
          <Grid container spacing={2}>
            <Box maxWidth={600}>
              <Form
                docLabel={settingsFormConfig.docLabel}
                buildDocData={buildSettings}
                updateDoc={updateSettings}
                createDoc={createSettings}
                docData={settingsFormConfig.defaultValues}
                onCloseForm={() => history.push('/instellingen')}
                wrapInside='page'
                modalMaxWidth='xs'
                hasDetails={false}
                onError={errorLogger}
                formInputs={({ formObject }) => (
                  <Box maxWidth={600}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant='subtitle1'>
                          Taal instellingen
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <AutoSelect
                          name='applicationLanguage'
                          label='Taal'
                          formObject={formObject}
                          disabled
                          defaultValue={{ value: 'dutch', label: 'Nederlands' }}
                          options={[
                            { value: 'dutch', label: 'Nederlands' },
                            { value: 'english', label: 'Engels' },
                            { value: 'german', label: 'Duits' },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AutoSelect
                          name='applicationRegion'
                          label='Regio'
                          formObject={formObject}
                          disabled
                          defaultValue={{ value: 'dutch', label: 'Nederland' }}
                          options={[
                            { value: 'dutch', label: 'Nederland' },
                            { value: 'english', label: 'Engeland' },
                            { value: 'german', label: 'Duitsland' },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AutoSelect
                          name='applicationTimeZone'
                          label='Tijdzone'
                          formObject={formObject}
                          disabled
                          defaultValue={{
                            value: 'dutch',
                            label:
                              '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
                          }}
                          options={[
                            {
                              value: 'dutch',
                              label:
                                '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
                            },
                            {
                              value: 'english',
                              label:
                                '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
                            },
                            {
                              value: 'german',
                              label:
                                '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AutoSelect
                          name='applicationCurrencies'
                          label='Valuta'
                          formObject={formObject}
                          disabled
                          defaultValue={{ value: 'euro', label: 'Euro' }}
                          options={[
                            { value: 'euro', label: 'Euro' },
                            { value: 'dollar', label: 'Dollar' },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              />
            </Box>
            <Grid item xs={12}>
              <Typography variant='body2' color='textSecondary'>
                {process.env.REACT_APP_NAME} {process.env.REACT_APP_ENV}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </SettingsContainer>
    </>
  )
}

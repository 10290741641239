import React, { useEffect, useState } from 'react'
import { isLoaded, useFirebase, useFirestore } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import { bytesConvert } from '@fivano/core'
import { RouteTypes } from 'types/RouteTypes'
import { MusicTrackFormInputs } from './MusicTrackFormInputs'
import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'
import { useUpload } from 'hooks/useUpload'
import { Form, LoadingOverlay, Modal } from '@fivano/core'
import { Box, LinearProgress, Typography } from '@mui/material'
import { useSelector } from 'hooks/useSelector'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { useDataStandards } from 'hooks/useDataStandards'

const zipMimes = [
  'application/zip',
  'application/octet-stream',
  'application/x-zip-compressed',
  'multipart/x-zip',
]

type FileFormat = {
  id: string
  label: string
  fileType: string[]
  uploadPath?: string
}

export const musicFilesFormat: FileFormat[] = [
  { id: 'wavFile', label: 'WAV bestand', fileType: ['audio/wav'] },
  { id: 'stemsFile', label: 'Stems bestand', fileType: zipMimes },
  { id: 'midiFile', label: 'MIDI bestand', fileType: zipMimes },
]

export const removeUndefined = (object: object) => {
  const newObject = {}
  Object.entries(object).forEach(([key, value]) => {
    if (value !== undefined) newObject[key] = value
  })
  return newObject
}

export const MusicTrackForm = () => {
  const history = useHistory()
  const { musicTrackID } = useParams<RouteTypes>()
  const isEditing = !!musicTrackID
  const firestore = useFirestore()
  const firebase = useFirebase()
  const { upload, status } = useUpload()

  useEffect(() => {
    firestore.get({
      doc: `musicTracks/${musicTrackID}`,
      storeAs: `musicTracks_${musicTrackID}`,
    })
  }, [firestore, musicTrackID])

  const docData: any[] = useSelector(
    state => state.firestore.ordered[`musicTracks_${musicTrackID}`],
  )

  const [loadingMessage, setLoadingMessage] = useState<string>('')

  const createDataStandards = useDataStandards()
  const buildTrackData = async data => {
    const dataStandards = createDataStandards({
      data,
      dataName: '',
      editForm: isEditing,
    })
    // create idTrack if there is no idTrack defined
    const createID = isEditing ? musicTrackID : firestoreIDGenerator()
    data.idTrack = createID
    data._id = createID

    // Create playlistIDs array to enable filtering by playlist in Firestore with array contains
    data.playlistIDs = data.playlists.map(playlist => playlist.playlist.value)
    setLoadingMessage('Bezig met product opslaan')
    const fileUploads = [
      ...musicFilesFormat,
      { id: 'previewFile', label: 'Preview MP3' },
      { id: 'previewFileOriginal', label: 'WAV bestand' },
      {
        id: 'images',
        label: 'Track afbeelding',
        uploadPath: 'productFiles',
      },
    ].map(async format => {
      const name = format.id
      // Check if file exists
      if (data[name]) {
        const file = data[name]
        // If file is already uploaded return from loop
        if (file[0]?.bucket) return
        // Check files to delete and delete them
        if (
          file[0] &&
          docData[0]?.[name] &&
          JSON.stringify(file[0]) !== JSON.stringify(docData[0]?.[name])
        ) {
          setLoadingMessage(`Verwijder ${format.label}`)
          const fileRef = firebase
            .storage()
            .ref(
              `/tracks/${data._id}/${format.id}/${docData[0]?.[name]?.[0].name}`,
            )
          await fileRef.delete().then(console.info).catch(console.warn)
        }
        // If file is not uploaded start uploading file
        setLoadingMessage(`Upload ${format.label}`)
        await upload({
          file: file[0],
          path: format.uploadPath || `/tracks/${data._id}/${format.id}/`,
          filename: file[0]?.name,
        }).then((response: any) => {
          data[name] = [removeUndefined(response.metadata)]
        })
      } else {
        data[name] = []
      }
    })
    await Promise.all(fileUploads)
    return { ...data, ...dataStandards }
  }

  const createTrack = async data => {
    await firestore.set(`musicTracks/${data._id}`, data)
    setLoadingMessage('')
  }

  const updateTrack = async data => {
    await firestore.update(`musicTracks/${data._id}`, data)
    setLoadingMessage('')
  }

  const errorLogger = useErrorLogger()

  return (
    <>
      <Modal maxWidth='xs' open={loadingMessage !== ''}>
        <Box width='100%' height='400px'>
          <LoadingOverlay label={loadingMessage}>
            {status && (
              <Box width='100%'>
                <Typography>
                  {bytesConvert(status.bytesTransferred)} /
                  {bytesConvert(status.totalBytes)}
                </Typography>
                <LinearProgress
                  variant='determinate'
                  value={Math.round(
                    (status.bytesTransferred / status.totalBytes) * 100,
                  )}
                />
              </Box>
            )}
          </LoadingOverlay>
        </Box>
      </Modal>
      {isLoaded(docData) && (
        <Form
          onCloseForm={() => history.push('/tracks')}
          wrapInside='page'
          docLabel='Track'
          hasDetails={false}
          onCreateSuccess={() => history.push('/tracks')}
          onUpdateSuccess={() => history.push('/tracks')}
          buildDocData={buildTrackData}
          updateDoc={updateTrack}
          createDoc={createTrack}
          editingDocID={musicTrackID}
          docData={
            isEditing
              ? docData?.[0]
              : {
                  credits: 1,
                  startPreview: 0,
                  endPreview: 30,
                  playlists: [{ playlist: null, quality: undefined }],
                }
          }
          onError={errorLogger}
          formInputs={({ formObject, fileChangesState }) => (
            <MusicTrackFormInputs
              formObject={formObject}
              fileChangesState={fileChangesState}
              docData={docData}
            />
          )}
        />
      )}
    </>
  )
}

import React from 'react'
import { Grid, Link, SvgIconProps, TypographyProps } from '@mui/material'
import { IconButton } from '../IconButton'

type IconLinkProps = {
  /**String that defines the endpoint*/
  href?: string
  /**Function that gets triggered when the link is clicked*/
  onClick?: React.MouseEventHandler<HTMLSpanElement>
  /**Visible label*/
  label: string
  /**The icon used for the link*/
  icon: SvgIconProps
  /**Boolean to open a new tab or not when the link is clicked*/
  newTab?: boolean
  /**Custom colors*/
  color?: TypographyProps['color']
  /**Data for inline styling*/
  style?: React.CSSProperties
  /**label of the button*/
  tooltip: string
}

export const IconLink = ({
  href,
  onClick,
  label,
  icon,
  newTab,
  color,
  style,
  tooltip,
}: IconLinkProps) => {
  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item>
        <IconButton
          {...(href !== undefined && {
            onClick: e => {
              e.stopPropagation()
              window.open(href, newTab ? '_blank' : '_self')
            },
          })}
          label={tooltip}
          style={style}
          size='large'
        >
          {icon}
        </IconButton>
      </Grid>
      <Grid item>
        <Link
          color={color}
          underline='hover'
          variant='body1'
          href={href}
          {...(newTab && { target: '_blank' })}
          {...(href !== undefined && { onClick: onClick })}
        >
          {label}
        </Link>
      </Grid>
    </Grid>
  )
}

import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Grid, Paper, Skeleton } from '@mui/material'
import { Group, Apps } from '@mui/icons-material'
import { PageContainer } from '@fivano/core'
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader'

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <PageContainer maxWidth='lg'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <WidgetHeader title='Personen' icon={Group} path='/' />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Grid container>
                  <WidgetHeader title='Snelkoppelingen' icon={Apps} />
                </Grid>
                <Grid container spacing={1}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </>
  )
}
export default Home

export const HomeSkeleton = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <PageContainer maxWidth='lg'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Skeleton
              height={40}
              width='70%'
              sx={{ marginTop: 3, marginBottom: 1 }}
            />
            <Paper variant='outlined' sx={{ padding: '8px 0px' }}>
              <SkeletonAvatarListItem />
              <SkeletonAvatarListItem />
              <SkeletonAvatarListItem />
              <SkeletonAvatarListItem />
            </Paper>
            <Skeleton
              height={40}
              width='70%'
              sx={{ marginTop: 3, marginBottom: 1 }}
            />
            <Paper variant='outlined' sx={{ padding: '8px 0px' }}>
              <SkeletonListItem />
              <SkeletonListItem />
              <SkeletonListItem />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton
              height={40}
              width='70%'
              sx={{ marginTop: 3, marginBottom: 1 }}
            />
            <Paper variant='outlined' sx={{ padding: '8px 0px' }}>
              <SkeletonListItem />
              <SkeletonListItem />
              <SkeletonListItem />
              <SkeletonListItem />
              <SkeletonListItem />
            </Paper>
          </Grid>
        </Grid>
      </PageContainer>
    </>
  )
}

const SkeletonAvatarListItem = () => (
  <Grid container padding='8px 16px'>
    <Grid>
      <Skeleton variant='circular' width={40} height={40} />
    </Grid>
    <Grid item width='calc(100% - 40px)' padding='0px 0px 0px 16px'>
      <Skeleton height={18} sx={{ margin: '1px 0px' }} />
      <Skeleton height={18} width='80%' />
    </Grid>
  </Grid>
)
const SkeletonListItem = () => (
  <Grid container padding='8px 16px'>
    <Grid item xs={12}>
      <Skeleton height={18} sx={{ margin: '1px 0px' }} />
      <Skeleton height={18} width='80%' />
    </Grid>
  </Grid>
)

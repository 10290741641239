import React, { useState } from 'react'
import {
  useFirestoreConnect,
  ReduxFirestoreQuerySetting,
  useFirestore,
} from 'react-redux-firebase'
import { Box, Grid, Paper, Typography, Button } from '@mui/material'
import CategoryIcon from '@mui/icons-material/Category'
import ArchiveIcon from '@mui/icons-material/Archive'
import { JobOpeningForm } from './JobOpeningForm'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { useArchiveDoc } from 'hooks/useArchiveDoc'
import { useSelector } from 'hooks/useSelector'
import { useFilters } from 'hooks/useFilters'
import { JobSettingsCategoryForm } from './JobSettingsCategoryForm'
import DeleteIcon from '@mui/icons-material/Delete'

import { useErrorLogger } from 'hooks/useErrorLogger'
import {
  AutoSelect,
  Checkbox,
  FloatingButton,
  useConfirmModal,
  ActionMenu,
  MenuButton,
  ListView,
  PageContainer,
  useSnackbar,
} from '@fivano/core'
import { SearchFilter } from 'app/components/SearchFilter'

const breadcrumbArray = [
  { title: 'Home', path: '/' },
  { title: 'Vacatures', path: '/vacatures' },
]

export const JobOpenings = () => {
  const profile = useSelector(state => state.firebase.profile)
  const firestore = useFirestore()
  const { enqueueSnackbar } = useSnackbar()
  const errorLogger = useErrorLogger()
  const [vacanciesLimit, setVacanciesLimit] = useState(5)
  const [vacanciesFormProps, setVacanciesFormProps] = useState({
    vacancyID: null,
    open: false,
  })

  const [categoriesFormProps, setCategoriesFormProps] = useState({
    categoryID: null,
    open: false,
  })

  const [filters, setFilters] = useFilters({
    searchField: {
      value: '',
      label: 'Zoek op:',
      param: true,
    },
    archivedJoop: {
      value: false,
      label: 'Toon gearchiveerd',
      param: true,
    },
    searchLocationJoop: {
      value: [],
      label: 'Locaties:',
      cookie: true,
    },
  })

  const handleFilter = data => {
    const newFilters = { ...filters }
    newFilters.searchField.value = data.searchField
    newFilters.archivedJoop.value = data.archivedJoop
    newFilters.searchLocationJoop.value = data.searchLocationJoop
    setFilters(newFilters)
  }

  const vacancyQuery = ({
    searchField,
    archivedJoop,
    searchLocationJoop,
  }): ReduxFirestoreQuerySetting => ({
    collection: 'jobOpenings',
    orderBy: ['nameJoop', 'asc'],
    where: [
      [
        'locationJoop.value',
        'in',
        searchLocationJoop.value?.length > 0
          ? searchLocationJoop.value.map(i => i.value)
          : profile.locations.map(i => i.value),
      ],
      ['keywordsJoop', 'array-contains', searchField.value],
      ['archivedJoop', '==', archivedJoop.value],
    ],
    limit: vacanciesLimit,
    storeAs: 'vacancies',
  })

  useFirestoreConnect([vacancyQuery(filters)])

  const vacanciesData = useSelector(
    ({ firestore: { ordered } }) => ordered.vacancies,
  )

  const vacanciesLength = useSelector(
    ({ firestore: { ordered } }) => ordered?.vacancies?.length,
  )

  const archiveDoc: any = useArchiveDoc()
  const handleArchive = data => {
    archiveDoc({
      docType: 'Vacature',
      path: `jobOpenings/${data.id}`,
      archived: !data?.archivedJoop,
      extension: 'Joop',
    })
  }

  const onDelete = jobOpeningID => {
    firestore
      .delete(`jobOpenings/${jobOpeningID}`)
      .then(() => {
        enqueueSnackbar('Vacature succesvol verwijderd.', {
          variant: 'success',
        })
      })
      .catch(error => {
        errorLogger({
          error,
          snackbarMessage: `Vacature verwijderen mislukt. ${error}`,
        })
      })
  }

  const confirmModal = useConfirmModal()

  const handleDelete = jobOpeningID => {
    confirmModal({
      onConfirm: () => onDelete(jobOpeningID),
      cancelLabel: 'Annuleren',
      confirmLabel: 'Verwijderen',
      title: 'Weet je het zeker?',
      message:
        'De vacature wordt permanent verwijdert. Deze actie kan niet ongedaan gemaakt worden.',
    })
  }

  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          accessor: 'nameJoop',
          gridProps: {
            xs: 7,
            sm: 7,
            md: 7,
          },
          Cell: data => (
            <Grid item>
              <Typography variant='body1'>
                {data.row.original.nameJoop}
              </Typography>
            </Grid>
          ),
        },
        {
          Header: 'Locatie',
          accessor: 'locationJoop',
          gridProps: {
            xs: 2,
            sm: 2,
            md: 2,
          },
          hidden: { only: ['xs'] },
          Cell: data => (
            <Grid item>
              <Typography variant='body1'>
                {data.row.original.locationJoop.label}
              </Typography>
            </Grid>
          ),
        },
        {
          Header: 'Categorie',
          accessor: 'categoriesJoop',
          gridProps: {
            xs: 3,
            sm: 3,
            md: 3,
          },
          hidden: { only: ['xs'] },
          Cell: data => (
            <Grid container>
              {data.row.original.categoriesJoop.map(category => (
                <Typography
                  variant='body1'
                  key={category.label}
                  paddingRight='16px'
                >
                  {category.label}
                </Typography>
              ))}
            </Grid>
          ),
        },
        {
          id: 'actions',
          Header: ' ',
          Cell: data => (
            <Grid item>
              <ActionMenu
                iconPosition='vertical'
                minWidth={250}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <MenuButton
                  label={
                    data.row.original?.archivedJoop
                      ? 'Vacature dearchiveren'
                      : 'Vacature archiveren'
                  }
                  icon={<ArchiveIcon />}
                  onClick={() => handleArchive(data.row.original)}
                />
                <MenuButton
                  label='Vacature verwijderen'
                  icon={<DeleteIcon />}
                  onClick={() => handleDelete(data.row.original.id)}
                />
              </ActionMenu>
            </Grid>
          ),
        },
      ],
    },
  ]

  return (
    <>
      {vacanciesFormProps.open && (
        <JobOpeningForm
          wrapInside='modal'
          modalMaxWidth='sm'
          onCloseForm={() => {
            setVacanciesFormProps({ vacancyID: null, open: false })
          }}
          editingDocID={vacanciesFormProps.vacancyID}
        />
      )}
      <JobSettingsCategoryForm
        firestoreCode='Joca'
        firestoreSettingsPath='jobOpeningCategories'
        labelMultiple='categorieën'
        labelSingle='categorie'
        formProps={categoriesFormProps}
        onClose={() =>
          setCategoriesFormProps({
            categoryID: null,
            open: false,
          })
        }
      />
      <PageContainer>
        <Breadcrumbs breadcrumbs={breadcrumbArray} />
        <Paper>
          <Box p={2}>
            <Grid container>
              <Grid item width='calc(100% - 48px)'>
                <SearchFilter
                  placeholder='Vacatures zoeken'
                  onSubmit={handleFilter}
                  filterValues={filters}
                  filters={({ formObject }) => {
                    return (
                      <>
                        <Grid item xs={12}>
                          <AutoSelect
                            name='searchLocationJoop'
                            label='Locatie vacature'
                            formObject={formObject}
                            multiple
                            size='medium'
                            options={profile.locations}
                            disableCloseOnSelect={false}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Checkbox
                            name='archivedJoop'
                            label='Toon gearchiveerde vacatures'
                            formObject={formObject}
                          />
                        </Grid>
                      </>
                    )
                  }}
                />
              </Grid>

              <Grid item marginLeft='auto'>
                <ActionMenu
                  iconPosition='vertical'
                  minWidth={250}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  <MenuButton
                    label='Categorie toevoegen'
                    icon={<CategoryIcon />}
                    onClick={() =>
                      setCategoriesFormProps({ categoryID: null, open: true })
                    }
                  />
                </ActionMenu>
              </Grid>
            </Grid>
          </Box>
          <FloatingButton
            label={'Vacature toevoegen'}
            onClick={() => {
              setVacanciesFormProps({ vacancyID: null, open: true })
            }}
          />
          {vacanciesData?.length > 0 ? (
            <>
              <ListView
                data={vacanciesData}
                columns={listColumns}
                onClick={(event, data) =>
                  setVacanciesFormProps({
                    vacancyID: data.original.id,
                    open: true,
                  })
                }
              />
              {vacanciesLength === vacanciesLimit && (
                <Button
                  fullWidth
                  onClick={() => setVacanciesLimit(vacanciesLimit + 5)}
                >
                  Meer vacatures laden
                </Button>
              )}
            </>
          ) : (
            <Box p={2}>
              <Typography variant='body1'>
                Geen vacatures gevonden. Voeg een nieuwe vacature toe
              </Typography>
            </Box>
          )}
        </Paper>
      </PageContainer>
    </>
  )
}

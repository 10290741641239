import React from 'react'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { Grid } from '@mui/material'
import { TextField, Switch, FormRow, Form, AutoSelect } from '@fivano/core'
import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'

import { useDataStandards } from 'hooks/useDataStandards'
import { useErrorLogger } from 'hooks/useErrorLogger'
import {
  PhaseBuildTypes,
  PhaseDatastandardTypes,
  PhaseFormTypes,
} from './BoardTypes'

export const PhaseForm = ({
  onCloseForm,
  boardID,
  editingDocID,
}: {
  onCloseForm: any
  boardID: string
  editingDocID?: string
}) => {
  const createDataStandards = useDataStandards()
  const docLabel = 'Fase'
  const defaultValues = {
    namePhase: '',
    descriptionPhase: '',
    archivedPhase: false,
  }
  const isEditing = !!editingDocID
  const uid = useSelector(({ firebase: { auth } }) => auth.uid)
  const firestore = useFirestore()

  const boardData = useSelector(
    ({ firestore: { data } }) => data[`boards_${boardID}`],
  )

  const getPhaseIndex = (boardData, phaseID) => {
    if (phaseID) {
      return boardData.phasesBoard.map(el => el.phaseID).indexOf(phaseID)
    }
  }

  const phaseData: PhaseFormTypes =
    boardData.phasesBoard[getPhaseIndex(boardData, editingDocID)]
  const phaseIndex = getPhaseIndex(boardData, editingDocID)
  const updatePhase = async data => {
    const newPhasesBoard = boardData.phasesBoard.slice()
    const { position, ...newData } = data
    const newPhaseData = { ...newPhasesBoard[phaseIndex], ...newData }

    newPhasesBoard.splice(phaseIndex, 1)
    newPhasesBoard.splice(data.position.value, 0, newPhaseData)

    await firestore.update(`boards/${boardID}`, {
      phasesBoard: newPhasesBoard,
    })
  }

  const createPhase = async data => {
    await firestore.update(`boards/${boardID}`, {
      phasesBoard: firestore.FieldValue.arrayUnion(data),
    })
  }

  const buildPhaseData = (data: PhaseBuildTypes) => {
    const dataStandards: PhaseDatastandardTypes = createDataStandards({
      data,
      dataName: 'Phase',
      editForm: isEditing,
      uid: uid,
    })
    if (!editingDocID) {
      data.boardCardIDs = []
      data.phaseID = firestoreIDGenerator()
    }
    return { ...data, ...dataStandards }
  }

  const positionOptions = boardData?.phasesBoard
    ?.map((phase, index) => {
      return {
        label: phase.namePhase,
        value: index,
        archived: phase.archivedPhase,
        phaseID: phase.phaseID,
      }
    })
    .filter(option => option.archived === phaseData?.archivedPhase)

  const errorLogger = useErrorLogger()

  return (
    <Form
      wrapInside='modal'
      modalMaxWidth='sm'
      docLabel={docLabel}
      buildDocData={buildPhaseData}
      updateDoc={updatePhase}
      createDoc={createPhase}
      editingDocID={editingDocID}
      hasDetails={true}
      docData={!!editingDocID ? phaseData : defaultValues}
      onCloseForm={onCloseForm}
      onError={errorLogger}
      formInputs={({ formObject, showAllFields }) => (
        <>
          <Grid spacing={1} container>
            <Grid item xs={12}>
              <TextField
                rules={{ required: 'Voer een naam in voor de fase' }}
                name='namePhase'
                formObject={formObject}
                label='Naam fase'
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label='Omschrijving fase'
                name='descriptionPhase'
                rows={4}
                maxRows={20}
                multiline
                formObject={formObject}
              />
            </Grid>
            {editingDocID && (
              <Grid item xs={6}>
                <AutoSelect
                  label='Fase verplaatsen naar'
                  formObject={formObject}
                  rules={{ required: 'Verplicht veld' }}
                  defaultValue={positionOptions.find(
                    option => option.phaseID === editingDocID,
                  )}
                  name='position'
                  options={positionOptions}
                />
              </Grid>
            )}
          </Grid>
          <FormRow showAllFields={showAllFields}>
            <Switch
              name='archivedPhase'
              formObject={formObject}
              label={`Fase archiveren`}
              defaultValue={false}
            />
          </FormRow>
        </>
      )}
    />
  )
}

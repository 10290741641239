import React from 'react'
import { styled } from '@mui/material'
import { useHistory } from 'react-router-dom'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import AppsIcon from '@mui/icons-material/Apps'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { menuLayoutType } from 'app/router/types'
import { hasClaimLevel } from 'utils/hasClaimLevel'
import { useSelector } from 'hooks/useSelector'

const BottomNavigationStyled = styled(BottomNavigation)(({ theme }) => ({
  width: '100%',
  position: 'fixed',
  display: 'flex',
  flexWrap: 'wrap',
  bottom: 0,
  boxShadow: theme.shadows[6],
  height: 52,
  zIndex: theme.zIndex.appBar,
}))

const BottomNavigationActionStyled = styled(BottomNavigationAction)(() => ({
  padding: '0px 0px 0px 0px',
  width: '20%',
  minWidth: '20%',
  minHeight: 60,
}))

const EmptyBottomNavigationActionStyled = styled(BottomNavigationAction)(
  () => ({
    width: '20%',
    minWidth: '20%',
  }),
)

export const MobileNavigation = ({
  menuData,
}: {
  menuData: menuLayoutType[]
}) => {
  const history = useHistory()
  const profile = useSelector(state => state.firebase.profile)
  const [more, setMore] = React.useState(false)
  const handleNavigate = path => {
    history.push(path)
    setMore(false)
  }
  const accessMenu: menuLayoutType[] = []
  menuData.forEach(
    i => hasClaimLevel(profile, i.route.claims) && accessMenu.push(i),
  )
  return (
    <BottomNavigationStyled
      style={{ height: more ? Math.ceil(accessMenu.length / 5) * 70 : 56 }}
    >
      {accessMenu?.slice(0, 4).map((menuItem, index) => (
        <BottomNavigationActionStyled
          key={menuItem.route.path}
          label={menuItem.name}
          value={menuItem.route.path}
          onClick={() => handleNavigate(menuItem.route.path)}
          icon={<menuItem.icon />}
          showLabel
        />
      ))}
      <BottomNavigationActionStyled
        label={more ? 'Inklappen' : 'Meer'}
        onClick={() => setMore(!more)}
        icon={more ? <ExpandMoreIcon /> : <AppsIcon />}
        showLabel
      />
      {accessMenu?.slice(4, 99).map((menuItem, index) => (
        <BottomNavigationActionStyled
          key={menuItem.route.path}
          label={menuItem.name}
          value={menuItem.route.path}
          onClick={() => handleNavigate(menuItem.route.path)}
          icon={<menuItem.icon />}
          showLabel
        />
      ))}
      <EmptyBottomNavigationActionStyled disabled />
      <EmptyBottomNavigationActionStyled disabled />
      <EmptyBottomNavigationActionStyled disabled />
      <EmptyBottomNavigationActionStyled disabled />
    </BottomNavigationStyled>
  )
}

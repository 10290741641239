import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { RouteTypes } from 'types/RouteTypes'
import { ServiceFormInputs } from './ServiceFormInputs'
import useMongo from 'hooks/useMongo'
import { servicesConfig } from '@fivano/models'
import { Button, capitalize } from '@mui/material'
import { Form } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'

const docLabel = capitalize(servicesConfig.labels.single)

export const ServiceForm = () => {
  const history = useHistory()
  const errorLogger = useErrorLogger()
  const { serviceID } = useParams<RouteTypes>()
  const { updateDoc, useRefetchDoc, createDoc, deleteDoc } = useMongo()

  const createService = async data => {
    return await createDoc({ collection: 'services', data })
  }

  const updateService = async data => {
    return await updateDoc({ collection: 'services', docID: serviceID, data })
  }

  const deleteService = async () => {
    await deleteDoc({ collection: 'services', docID: serviceID })
    history.push(`/diensten`)
  }

  const { status, docData } = useRefetchDoc({
    collection: 'services',
    docID: serviceID,
    disabled: !serviceID,
  })

  return (
    <Form
      onCloseForm={() => history.push(`/diensten`)}
      wrapInside='page'
      modelConfig={servicesConfig}
      docLabel={docLabel}
      hasDetails={false}
      loading={status === 'loading'}
      updateDoc={updateService}
      createDoc={createService}
      onCreateSuccess={response =>
        history.push(`/diensten/${response?.docs[0]._id}/formulier`)
      }
      editingDocID={serviceID}
      docData={docData}
      onError={errorLogger}
      formInputs={({ formObject }) => (
        <>
          <ServiceFormInputs formObject={formObject} />
          <Button onClick={deleteService}>Delete Service</Button>
        </>
      )}
    />
  )
}

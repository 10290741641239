import React, { useState, useEffect } from 'react'
import { AccordionBase, ListItemChevron } from '@fivano/core'
import {
  Box,
  Button,
  capitalize,
  Divider,
  Grid,
  styled,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import { nl } from 'date-fns/locale'

import { TimeRegistrationRowHeader } from './TimeRegistrationRowHeader'
import { TimeRegistrationRow } from './TimeRegistrationRow'
import { TimeRegistrationSkeleton } from './TimeRegistrationSkeleton'
import { WorkingHomeCheckbox } from './WorkingHomeCheckbox'

import { isEmptyObject } from 'utils/isEmptyObject'
import { secondsToTimeString } from 'utils/secondsToTime'

import { TimeRegistrationColumnProps } from './types'
import { MINUTES_IN_DAY } from 'utils/variables'

const ColumnHeader = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  borderBottom: `1px solid ${theme.palette.action.selected}`,
  width: '100%',
  justifyContent: 'flex-start',
  padding: '4px 0px',
  '&:hover': {
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.action.selected,
    },
  },
}))

export const TimeRegistrationColumn = ({
  personID,
  date,
  data,
  workingHomeData,
  newRow,
  showSkeleton,
  locationsData,
  projectCodes,
  matchesMdDown,
  rowState,
  setRowState,
  onCreateRow,
  onCreateEmptyRow,
  onUpdateRow,
  onDeleteRow,
}: TimeRegistrationColumnProps) => {
  const [expanded, setExpanded] = useState<boolean>(true)

  useEffect(() => {
    if (!rowState.editRow && !rowState.submitRow && newRow) {
      onCreateEmptyRow(date)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowState, newRow])

  const [dayTotal, setDayTotal] = useState<string>('')
  useEffect(() => {
    const durations: Array<number> = []
    data?.forEach(item => {
      durations.push(item.duration * 60)
    })
    const secondsCombined = durations.reduce((a, b) => a + b, 0)
    if (data.length > 0 && secondsCombined / 60 === MINUTES_IN_DAY) {
      setDayTotal('24:00')
    } else {
      setDayTotal(secondsToTimeString(secondsCombined))
    }
  }, [data])

  const clickRow = item => {
    if (rowState?.editRow !== undefined && rowState?.editRow !== item.id) {
      setRowState({
        editRow: undefined,
        submitRow: rowState.editRow,
        changeToRow: item.id,
        addRow: rowState.addRow,
      })
    }
    if (!rowState.editRow && !rowState.submitRow) {
      setRowState({
        editRow: item.id,
        submitRow: undefined,
        changeToRow: undefined,
        addRow: rowState.addRow,
      })
    }
  }
  console.log(rowState)
  return (
    <AccordionBase
      expanded={expanded}
      visibleContent={
        <ColumnHeader>
          <ListItemChevron
            title={capitalize(format(date, 'EEEE d MMMM', { locale: nl }))}
            onHandleClick={() => setExpanded(!expanded)}
            listItemContent={
              <Grid item style={{ marginLeft: 'auto' }}>
                <Box px={2}>
                  <Grid container alignItems='center'>
                    <Grid
                      item
                      onClick={e => e.stopPropagation()}
                      sx={{ paddingRight: 1 }}
                    >
                      <WorkingHomeCheckbox
                        docData={workingHomeData.find(
                          x => x.date.getTime() === date.getTime(),
                        )}
                        personID={personID}
                        date={date}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>{dayTotal}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            }
          />
        </ColumnHeader>
      }
      expandableContent={
        <Grid container>
          <Grid item xs={12}>
            {!matchesMdDown && <TimeRegistrationRowHeader />}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {data.length === 0 ? (
            showSkeleton ? (
              <TimeRegistrationSkeleton number={matchesMdDown ? 1 : 2} />
            ) : (
              <Grid container justifyContent='center'>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    onClick={async () => {
                      setRowState({
                        editRow: undefined,
                        submitRow: rowState.editRow,
                        changeToRow: undefined,
                        addRow: date,
                      })
                    }}
                  >
                    Rij toevoegen
                  </Button>
                </Grid>
              </Grid>
            )
          ) : (
            data.map((item, index) => {
              return (
                !isEmptyObject(item) && (
                  <div key={item.id} style={{ width: '100%' }}>
                    <TimeRegistrationRow
                      personID={personID}
                      defaultValues={item}
                      docs={data}
                      date={date}
                      onClickRow={() => clickRow(item)}
                      onCreateRow={onCreateRow}
                      onUpdateRow={onUpdateRow}
                      locationsData={locationsData}
                      projectCodes={projectCodes}
                      showLabels={matchesMdDown}
                      hasItem={index + 1 === data.length}
                      editMode={
                        rowState.editRow === item.id &&
                        rowState.submitRow === undefined
                      }
                      submitMode={rowState.submitRow === item.id}
                      addMode={rowState.addRow}
                      onSubmitRow={() => {
                        setRowState({
                          editRow: undefined,
                          submitRow: rowState.editRow,
                          changeToRow: undefined,
                          addRow: undefined,
                        })
                      }}
                      onCreateEmptyRow={date => {
                        setRowState({
                          editRow: undefined,
                          submitRow: rowState.editRow,
                          changeToRow: undefined,
                          addRow: date,
                        })
                      }}
                      onDeleteRow={onDeleteRow}
                      onSubmitSuccess={() => {
                        setRowState({
                          editRow: rowState.changeToRow,
                          submitRow: undefined,
                          changeToRow: undefined,
                          addRow: rowState.addRow,
                        })
                      }}
                      onSubmitError={() => {
                        setRowState({
                          editRow: rowState.submitRow || rowState.editRow,
                          submitRow: undefined,
                          changeToRow: undefined,
                          addRow: undefined,
                        })
                      }}
                    />
                  </div>
                )
              )
            })
          )}
        </Grid>
      }
    />
  )
}

import React from 'react'
import { AutoComplete } from 'app/components/AutoComplete'
import { Grid } from '@mui/material'
import { PersonsSelector } from 'app/legacy/Board/PersonsSelector'
import { UseFormReturn } from 'react-hook-form'

type RelationAndContactInputProps = {
  formObject: UseFormReturn
  relationName: string
  contactsName: string
  label: string
  multipleContacts?: boolean
  required?: boolean
  filterRelations?: any
  rules?: any
}

export const RelationAndContactInput = ({
  formObject,
  relationName,
  contactsName,
  label,
  filterRelations,
  multipleContacts = false,
  required = false,
  rules,
}: RelationAndContactInputProps) => {
  const { watch, setValue } = formObject
  return (
    <Grid item container spacing={1}>
      <Grid item xs={12} md={6}>
        <AutoComplete
          label={label}
          name={relationName}
          required={required}
          formObject={formObject}
          collection='relations'
          where={[['archivedRela', '==', false]]}
          labelKeys={['nameRela']}
          searchKey='keywordsRela'
          afterChange={() =>
            setValue(contactsName, multipleContacts ? [] : null)
          }
          rules={rules}
          filterValues={filterRelations}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PersonsSelector
          name={contactsName}
          formObject={formObject}
          multiple={multipleContacts}
          relation={watch(relationName)}
        />
      </Grid>
    </Grid>
  )
}

import { functionsURL } from 'utils/emulator'

export interface SettingsTypes {
  applicationName: string
  auth: {
    defaultSignInPath: string
    emailAuth: boolean
    microsoftAuth: boolean
    microsoftTenantID: string
    enableUserEmailInvite: boolean
  }
  faviconURL: string
  functionsRoot: string
  person: {
    participantAccountEnabled: boolean
    locationAssignEnabled: boolean
    birthDateFormat: string
  }
  personTags: {
    labels: {
      personTags1: string
    }
  }
  jobOpenings: {
    jobOpeningsEnabled: boolean
  }
  progress: {
    cardDateDistance: boolean
    cardDateFormat: string
  }
  theme: any
  tracks: any
  users: any
}

export const defaultSettings: SettingsTypes = {
  applicationName: 'Fivano',
  faviconURL: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FB_PROJECT_ID}.appspot.com/o/logos%2Ffavicon.ico`,
  functionsRoot: functionsURL(),
  auth: {
    defaultSignInPath: '/inloggen',
    emailAuth: true,
    microsoftAuth: false,
    microsoftTenantID: '',
    enableUserEmailInvite: true,
  },
  theme: {
    palette: {
      primary: {
        main: '#fb8c00',
        light: '#ffac33',
        dark: '#b26a00',
        contrastText: '#fff',
      },
      secondary: {
        main: '#f44336',
        light: '#ff7961',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
    fivano: {
      palette: {
        backgroundColorDark: 'rgba(15,8,0,1)',
        backgroundColorLight: 'rgba(251, 138, 0, 0.1)',
      },
    },
  },
  progress: {
    cardDateDistance: false,
    cardDateFormat: 'dd MMMM yyyy HH:mm',
  },
  person: {
    participantAccountEnabled: false,
    locationAssignEnabled: false, // enabled assignment of extra location inside ParticipantDetails
    birthDateFormat: 'dd/MM/yyyy',
  },
  jobOpenings: {
    jobOpeningsEnabled: false,
  },
  personTags: {
    labels: {
      personTags1: 'deelnemer registraties',
    },
  },
  tracks: {
    enableTimeRegistration: false,
    labels: {
      track: 'traject',
      tracks: 'trajecten',
      jobcoach: 'jobcoach',
    },
    trackAssign: {
      submissionDateTrack: {
        hide: false,
      },
      approvedDateTrack: {
        hide: false,
      },
      rejectedDateTrack: {
        hide: false,
      },
      workSchedule: {
        hide: false,
      },
      subsidizedValues: {
        hide: false,
      },
      notition: {
        hide: false,
      },
      trackTypeTrack: {
        hide: true,
      },
    },
  },
  users: {
    enableUserEmailInvite: false,
  },
}

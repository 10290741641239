import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import FivanoLogo from 'app/icons/FivanoLogo'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { styled } from '@mui/material'

const logoStyles = { width: 'auto', height: 52 }

const DivStyled = styled('div')(() => ({
  cursor: 'pointer',
}))

const ImgStyled = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: 28,
  },
  ...logoStyles,
}))

const FivanoLogoStyled = styled(FivanoLogo)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: 28,
  },
  ...logoStyles,
}))

export const Logo = () => {
  const firebase = useFirebase()
  const firestore = useFirestore()
  const history = useHistory()

  const [logoUrl, setLogoUrl] = useState('')
  useEffect(() => {
    firestore
      .collection('appSettings')
      .doc('applicationLogo')
      .get()
      .then(response => {
        if (response.exists) {
          const logoData: any = response.data()
          firebase
            .storage()
            .ref(logoData.file?.[0]?.fullPath)
            .getDownloadURL()
            .then(response => {
              setLogoUrl(response)
            })
            .catch(error => {})
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DivStyled onClick={() => history.push('/')}>
      {logoUrl ? <ImgStyled alt='logo' src={logoUrl} /> : <FivanoLogoStyled />}
    </DivStyled>
  )
}

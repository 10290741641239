import { Form } from '@fivano/core'
import { FormInputsBuilder } from 'app/components/Form/FormInputsBuilder'
import { useErrorLogger } from 'hooks/useErrorLogger'
import useMongo from 'hooks/useMongo'

export const ProductCategoryForm = ({ onCloseForm, editingDocID }) => {
  const errorLogger = useErrorLogger()
  const { createDoc, updateDoc, useRefetchDoc } = useMongo()
  const categoryID = editingDocID

  const createCategory = async data => {
    return await createDoc({ collection: 'productCategories', data })
  }

  const updateCategory = async data => {
    return await updateDoc({
      collection: 'productCategories',
      docID: editingDocID,
      data,
    })
  }

  const { status, docData } = useRefetchDoc({
    collection: 'productCategories',
    docID: categoryID,
    disabled: !categoryID,
  })

  return (
    <Form
      onCloseForm={onCloseForm}
      loading={status === 'loading'}
      wrapInside='modal'
      modalMaxWidth='sm'
      hasDetails={false}
      docLabel='Categorie'
      updateDoc={updateCategory}
      createDoc={createCategory}
      editingDocID={editingDocID}
      docData={docData}
      onError={errorLogger}
      formInputs={({ formObject, showAllFields }) => {
        const inputsConfig = [
          {
            type: 'FormRow',
            content: [
              {
                type: 'TextField',
                props: {
                  name: 'name',
                  label: 'Naam categorie',
                  rules: { required: 'Verplicht veld' },
                },
              },
            ],
          },
          {
            type: 'FormRow',
            content: [
              {
                type: 'TextField',
                props: {
                  name: 'description',
                  label: 'Omschrijving',
                  rows: 4,
                  multiline: true,
                },
              },
            ],
          },
        ]
        return (
          <FormInputsBuilder
            inputsConfig={inputsConfig}
            formObject={formObject}
          />
        )
      }}
    />
  )
}

import React from 'react'
import { Grid } from '@mui/material'

import { TextField, Switch, FormRow } from '@fivano/core'

import { SettingsForm } from 'app/legacy/SettingsBlock/SettingsForm'

export const TagsProgressBoardForm = props => {
  return (
    <SettingsForm {...props}>
      {({ formObject, showAllFields, isEditing }) => {
        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                rules={{ required: 'Voer een tag label in' }}
                name={`description${props.firestoreCode}`}
                formObject={formObject}
                readOnly={isEditing}
                label='Tag label'
              />
            </Grid>

            <FormRow showAllFields={showAllFields}>
              <TextField
                name={`id${props.firestoreCode}`}
                formObject={formObject}
                readOnly={isEditing}
                label='Tag ID'
              />
              <Switch
                name={`archived${props.firestoreCode}`}
                formObject={formObject}
                label={`CRM-tag archiveren`}
              />
            </FormRow>
          </Grid>
        )
      }}
    </SettingsForm>
  )
}

import React, { useState } from 'react'
import { Grid, Paper, capitalize } from '@mui/material'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { useSelector } from 'hooks/useSelector'
import {
  TextField,
  FormRow,
  Form,
  DateTimePicker,
  AutoSelect,
} from '@fivano/core'
import { RouteTypes } from 'types/RouteTypes'
import { useTrackLocationOptions } from 'hooks/useTrackLocationOptions'
import { optionsCreator } from 'utils/optionsCreator'
import { useSettings } from 'hooks/useSettings'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { useDataStandards } from 'hooks/useDataStandards'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { OptionType } from 'types/CommonTypes'
import { TrackType } from 'types/Track'
import { ProgressItemTypes } from './ProgressTypes'
import { convertToDate } from 'utils/convertToDate'
import { FileUpload } from 'app/components/FileUpload'

export const ProgressForm = ({
  onCloseForm,
  wrapInside,
  modalMaxWidth,
  editingDocID,
}) => {
  const { participantID } = useParams<RouteTypes>()
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const [timeChanged, setTimeChanged] = useState(false)
  const createDataStandards = useDataStandards()
  const settings = useSettings()
  const labels = settings.tracks.labels

  const locationQuery = useLocationQuery()
  useFirestoreConnect([
    {
      collection: 'appSettings',
      doc: 'progressTags',
      storeAs: 'progressTags',
    },
    {
      collectionGroup: 'tracks',
      limit: 50,
      where: [
        ...locationQuery('locationIDTrack'),
        ['personIDTrack', '==', participantID],
      ],
      storeAs: `persons_${participantID}_tracks`,
    },
  ])
  const personTracks = useSelector(
    state => state.firestore.ordered[`persons_${participantID}_tracks`],
  )
  const tagOptions: Array<OptionType> = useSelector(state =>
    optionsCreator(
      state.firestore.ordered['progressTags']?.[0]?.options,
      'descriptionPrta',
      'idPrta',
    ),
  )

  const trackOptions: Array<OptionType> = useTrackLocationOptions({
    participantID,
    onlyActiveTracks: true,
  })

  const buildProgressData = (data: ProgressItemTypes) => {
    const dataStandards = createDataStandards({
      data,
      dataName: 'Prog',
      editForm: !!editingDocID,
    })
    data.personIDProg = participantID
    data.creatorFirstNameProg = profile.firstNamePers
    data.creatorMiddleNameProg = profile.middleNamePers
    data.creatorLastNameProg = profile.lastNamePers
    data.privateProg = false
    const trackProg: TrackType = personTracks.find(
      track => data.trackProg.value === track.id,
    )
    data.locationProg = trackProg.locationTrack
    data.locationIDProg = trackProg.locationTrack.value
    if (data.tagsProg && data.tagsProg.length > 0) {
      const keywords: any = []
      data.tagsProg.forEach(tagObject => {
        keywords.push(tagObject.value)
      })
      data.keywordsProg = keywords
    } else {
      data.keywordsProg = []
    }

    if (data.dateTimeProg && data.timeProg && timeChanged) {
      let minutes = parseInt(data.timeProg.substring(3, 5))
      data.dateTimeProg.setHours(
        parseInt(data.timeProg.substring(0, 2)),
        minutes > 0 && minutes < 60 ? minutes : 0,
      )
    } else if (!editingDocID) {
      const dateNow = new Date()
      data.dateTimeProg.setHours(dateNow.getHours(), dateNow.getMinutes())
    }
    delete data.timeProg
    return { ...data, ...dataStandards }
  }

  const progressData: ProgressItemTypes = useSelector(
    ({ firestore: { data } }) => {
      if (editingDocID) {
        const rawData = data[`persons_${participantID}_progress`][editingDocID]
        return {
          ...rawData,
          ...{
            dateTimeProg: convertToDate(rawData.dateTimeProg),
            timeProg: format(convertToDate(rawData.dateTimeProg), 'HH:mm'),
          },
        }
      }
    },
  )
  const progressFormConfig = {
    docLabel: 'Voortgang',
    defaultValues: {
      personIDProg: '',
      creatorFirstNameProg: '',
      creatorMiddleNameProg: '',
      creatorLastNameProg: '',
      trackProg: trackOptions.length === 1 ? trackOptions[0] : null,
      textProg: '',
      privateProg: false,
      filesProg: [],
      tagsProg: [],
      durationProg: 0,
      dateTimeProg: new Date(),
      timeProg: format(new Date(), 'HH:mm'),
    },
  }

  const updateProgress = async data => {
    await firestore.update(
      `locations/${data.locationIDProg}/persons/${participantID}/progress/${editingDocID}`,
      data,
    )
  }

  const createProgress = async data => {
    await firestore.add(
      `locations/${data.locationIDProg}/persons/${participantID}/progress`,
      data,
    )
  }
  const errorLogger = useErrorLogger()

  return (
    <Form
      docLabel={progressFormConfig.docLabel}
      editingDocID={editingDocID}
      buildDocData={buildProgressData}
      updateDoc={updateProgress}
      createDoc={createProgress}
      docData={!!editingDocID ? progressData : progressFormConfig.defaultValues}
      hasDetails={true}
      wrapInside={wrapInside}
      modalMaxWidth={modalMaxWidth}
      onCloseForm={onCloseForm}
      onError={errorLogger}
      deleteFirestoreDoc
      formInputs={({ formObject, showAllFields, fileChangesState }) => {
        const trackProg = formObject.watch('trackProg')
        const selectedTrack = personTracks.find(
          track => trackProg?.value === track.id,
        )
        const filePath = `locations/${selectedTrack?.locationIDTrack}/persons/${participantID}/documents`
        console.log(fileChangesState)
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <AutoSelect
                name='trackProg'
                label={`${capitalize(labels.track)} voortgang`}
                disabled={!!editingDocID}
                formObject={formObject}
                options={trackOptions}
                disableCloseOnSelect={false}
                rules={{ required: 'Verplicht veld' }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                name='durationProg'
                label='Duur (minuten)'
                type='number'
                formObject={formObject}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={0}>
                <TextField
                  name='textProg'
                  label='Voortgang text'
                  autoFocus
                  rules={{
                    required: 'Schrijf een paar woorden voortgang',
                    maxLength: {
                      value: 20000,
                      message:
                        'De voortgang tekst die u schrijft is te lang. Gebruik maximaal 20.000 karakters',
                    },
                  }}
                  formObject={formObject}
                  multiline
                  maxRows={20}
                  rows={5}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <FileUpload
                name='filesProg'
                label='Voortgang document'
                profile={profile}
                filesStorePath={filePath}
                fileChangesState={fileChangesState}
                onError={errorLogger}
                formObject={formObject}
                firestoreDoc
                docExtension='Docu'
                customDocumentData={{
                  locationID:
                    selectedTrack?.locationIDTrack || 'publicLocation',
                  personID: participantID,
                  category: 'progress',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <AutoSelect
                name='tagsProg'
                label='Tags'
                multiple
                formObject={formObject}
                options={tagOptions}
              />
            </Grid>
            <FormRow showAllFields={showAllFields}>
              <DateTimePicker
                setTimeChanged={setTimeChanged}
                formObject={formObject}
                dateName='dateTimeProg'
                timeName='timeProg'
              />
            </FormRow>
          </Grid>
        )
      }}
    />
  )
}

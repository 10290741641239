import React, { useEffect, useState } from 'react'
import { Box, Grid, styled, Typography } from '@mui/material'

import { ProjectRowInput } from './ProjectRowInput'
import { format } from 'date-fns'
import { secondsToCustomTimeString } from 'utils/secondsToTime'
import { ProjectRowProps } from './types'

const RowWrapper = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const GridItemTotal = styled(Grid, {
  shouldForwardProp: prop => prop !== 'viewportLG',
})<{ viewportLG: boolean }>(({ viewportLG, theme }) => ({
  width: '100%',
  padding: '11px 5px 10px 0px',
  backgroundColor: viewportLG
    ? theme.palette.action.hover
    : theme.palette.common.white,
}))

export const ProjectRow = ({
  timeDocs,
  project,
  selectedWeek,
  showWeekend,
  weekTotals,
  viewportLG,
  onCreateDoc,
  onUpdateDoc,
  onHandleErrorInput,
}: ProjectRowProps) => {
  const [projectTotals, setProjectTotals] = useState<number>(0)
  const showDays = showWeekend ? 7 : 5

  useEffect(() => {
    const total = timeDocs?.reduce((sum, doc) => {
      return sum + (doc.time.getHours() * 3600 + doc.time.getMinutes() * 60)
    }, 0)
    setProjectTotals(total)
  }, [timeDocs])

  return (
    <RowWrapper container alignItems='center'>
      <Grid item xs={12} lg={showWeekend ? 4 : 6}>
        <Box pl='14px'>
          {!viewportLG && <Typography variant='caption'>Werkcode</Typography>}
          <Typography>{project.workCode.label}</Typography>
        </Box>
      </Grid>
      {selectedWeek.map((day, index) => {
        const doc = timeDocs.find(
          doc =>
            format(doc.time, 'dd-MMMM-yyyy') === format(day, 'dd-MMMM-yyy'),
        )
        return (
          index < showDays && (
            <Grid
              key={index}
              item
              xs={6}
              md={3}
              lg={1}
              {...(!viewportLG && { sx: { padding: '6px 12px' } })}
            >
              <ProjectRowInput
                doc={doc}
                project={project}
                day={day}
                total={
                  weekTotals.find(x => x.day.getTime() === day.getTime())?.total
                }
                onCreateDoc={onCreateDoc}
                onUpdateDoc={onUpdateDoc}
                onHandleErrorInput={onHandleErrorInput}
                viewportLG={viewportLG}
              />
            </Grid>
          )
        )
      })}
      <GridItemTotal item lg={1} viewportLG={viewportLG}>
        <Box py='4px' px='14px'>
          {!viewportLG && <Typography variant='caption'>Totaal</Typography>}
          <Typography>{secondsToCustomTimeString([projectTotals])}</Typography>
        </Box>
      </GridItemTotal>
    </RowWrapper>
  )
}

import React from 'react'
import { Avatar } from 'app/components/Avatar'
import { Typography, Grid, DialogActions, Button, styled } from '@mui/material'

import MailIcon from '@mui/icons-material/Mail'
import { IconButton } from '@fivano/core'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import SmsIcon from '@mui/icons-material/Sms'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import CloseIcon from '@mui/icons-material/Close'
import { Person } from '../../../../types/Person'
import { ListItemBase } from 'app/components/ListItems/ListItemBase'
import { Place } from '@mui/icons-material'

const DialogActionsStyled = styled(DialogActions)(() => ({
  padding: '8px 16px',
}))

const DivStyled = styled('div')(() => ({
  padding: '8px 0px 8px 16px',
}))

const DialogHeader = styled(Grid)(() => ({
  padding: '16px 16px 16px 16px',
  margin: 'auto',
}))

const StyledGridItem = styled(Grid)(() => ({
  position: 'absolute',
  top: 8,
  right: 8,
}))

type PersonDetailModalProps = {
  /**Data of a specific person*/
  personData: Person
  /**Function that gets triggered when the modal is closed*/
  onClose?: () => void
  /**Boolean if the form has a closing button or not*/
  closeButton?: boolean
  /**Function that gets triggered when you finalize the editing*/
  onEdit?: (personData: Person) => void
}
export const PersonDetailModal = (props: PersonDetailModalProps) => {
  const { personData, onClose, onEdit } = props

  if (personData) {
    return (
      <>
        <DialogHeader>
          <Avatar centerAvatar personData={personData} size='large' />
          <Typography variant='h5' align='center' gutterBottom>
            {personData.firstNamePers} {personData.middleNamePers}{' '}
            {personData.lastNamePers}
          </Typography>
          {personData.relationPositionPers && (
            <Typography variant='body1' align='center' gutterBottom>
              {personData.relationPositionPers}
            </Typography>
          )}
          <StyledGridItem item>
            <IconButton label='Aanpassen' onClick={onClose} size='large'>
              <CloseIcon />
            </IconButton>
          </StyledGridItem>
        </DialogHeader>
        {personData?.phoneMobilePers && (
          <ListItemBase
            showActions
            listItemActions={
              <>
                <IconButton
                  label='Whatsapp openen'
                  onClick={() => {
                    window.open(
                      `https://api.whatsapp.com/send?phone=${personData.phoneMobilePers}`,
                      '_blank',
                    )
                  }}
                  size='large'
                >
                  <WhatsAppIcon />
                </IconButton>
                <IconButton
                  label='SMS bericht'
                  onClick={() => {
                    window.open(`sms:${personData.phoneMobilePers}`, '_self')
                  }}
                  size='large'
                >
                  <SmsIcon />
                </IconButton>
                <IconButton
                  label='Telefoon openen'
                  onClick={() => {
                    window.open(`tel:${personData.phoneMobilePers}`, '_self')
                  }}
                  size='large'
                >
                  <PhoneEnabledIcon />
                </IconButton>
              </>
            }
          >
            <DivStyled>
              <Typography
                variant='caption'
                color='textSecondary'
                display='block'
              >
                Mobiele telefoon
              </Typography>
              <Typography variant='body1'>
                {personData.phoneMobilePers}
              </Typography>
            </DivStyled>
          </ListItemBase>
        )}
        {personData?.phoneLinePers && (
          <ListItemBase
            showActions
            listItemActions={
              <IconButton
                label='Telefoon openen'
                onClick={() => {
                  window.open(`tel:${personData.phoneLinePers}`, '_self')
                }}
                size='large'
              >
                {<PhoneEnabledIcon />}
              </IconButton>
            }
          >
            <DivStyled>
              <Typography
                variant='caption'
                color='textSecondary'
                display='block'
              >
                Vaste telefoon
              </Typography>
              <Typography variant='body1'>
                {personData?.phoneLinePers}
              </Typography>
            </DivStyled>
          </ListItemBase>
        )}
        {personData?.emailPers && (
          <ListItemBase
            showActions
            listItemActions={
              <IconButton
                label='Email openen'
                onClick={() => {
                  window.open(`mailto:${personData.emailPers}`, '_self')
                }}
                size='large'
              >
                {<MailIcon />}
              </IconButton>
            }
          >
            <DivStyled>
              <Typography
                variant='caption'
                color='textSecondary'
                display='block'
              >
                Email
              </Typography>
              <Typography variant='body1'>{personData?.emailPers}</Typography>
            </DivStyled>
          </ListItemBase>
        )}
        {personData?.addressStreetPers && (
          <ListItemBase
            showActions
            listItemActions={
              <IconButton
                label='Navigatie openen'
                onClick={() => {
                  window.open(
                    `http://maps.google.com/?q=${personData?.addressStreetPers} ${personData?.addressNumberPers} ${personData?.addressAdditionPers} ${personData?.addressCityPers}`,
                    '_blank',
                  )
                }}
                size='large'
              >
                {<Place />}
              </IconButton>
            }
          >
            <DivStyled>
              <Typography
                variant='caption'
                color='textSecondary'
                display='block'
              >
                Adres
              </Typography>
              <Typography variant='body1'>
                {personData?.addressStreetPers} {personData?.addressNumberPers}
                {personData?.addressAdditionPers}
              </Typography>
              <Typography variant='body1'>
                {personData?.addressZipPers} {personData?.addressCityPers}
              </Typography>
            </DivStyled>
          </ListItemBase>
        )}
        <DivStyled></DivStyled>

        {onEdit && (
          <DialogActionsStyled>
            <Button
              onClick={() => onEdit(personData)}
              color='primary'
              disableElevation
            >
              Bewerken
            </Button>
          </DialogActionsStyled>
        )}
      </>
    )
  } else {
    return null
  }
}

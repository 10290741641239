import React from 'react'
import { SettingsContainer } from 'app/components/SettingsContainer'
import { useSelector } from 'hooks/useSelector'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { Form } from '@fivano/core'
import { useHistory } from 'react-router-dom'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { FormInputsBuilder } from 'app/components/Form/FormInputsBuilder'

export const LogoSettings = () => {
  const breadcrumbArray = [
    { title: 'Instellingen', path: '/instellingen' },
    { title: 'Logo instellingen', path: '/instellingen/logo-instellingen' },
  ]
  const history = useHistory()
  const firestore = useFirestore()

  const errorLogger = useErrorLogger()
  const profile = useSelector(state => state.firebase.profile)

  const inputsConfig = ({ fileChangesState }) => {
    return [
      {
        type: 'FormRow',
        content: [
          {
            type: 'FileInput',
            props: {
              name: 'file',
              label: 'Logo',
              required: true,
              onError: errorLogger,
              profile,
              fileChangesState,
              filesStorePath: `appSettings`,
              fileID: 'applicationLogo',
              firestoreDoc: false,
              maxFiles: 1,
              acceptedFileTypes: 'image/jpeg, image/png, image/svg+xml',
              maxSize: 200000,
            },
          },
        ],
      },
    ]
  }

  useFirestoreConnect([
    {
      collection: 'appSettings',
      doc: 'applicationLogo',
      storeAs: `appSettings_applicationLogo`,
    },
  ])
  const applicationLogoData = useSelector(({ firestore: { data } }) => {
    return data?.[`appSettings_applicationLogo`]
  })

  const overwriteLogo = async data => {
    await firestore.collection('appSettings').doc('applicationLogo').set(data)
  }

  return (
    <SettingsContainer breadcrumbs={breadcrumbArray} showTitle={false}>
      {!applicationLogoData ? (
        <div>
          <p>Loading...</p>
          <p>
            (Takes to long? Make sure the document appSettings/applicationLogo
            exists in firestore)
          </p>
        </div>
      ) : (
        <Form
          wrapInside='page'
          onCloseForm={() => history.push(`/instellingen`)}
          docLabel='Logo'
          editingDocID='ENABLE_EDITING'
          hasDetails={false}
          docData={applicationLogoData}
          updateDoc={overwriteLogo}
          createDoc={overwriteLogo}
          onError={errorLogger}
          formInputs={({ formObject, fileChangesState }) => (
            <FormInputsBuilder
              inputsConfig={inputsConfig({ fileChangesState })}
              formObject={formObject}
            />
          )}
        />
      )}
    </SettingsContainer>
  )
}

import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function Colleague(props) {
  return (
    <SvgIcon {...props}>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M12 3C14.21 3 16 4.79 16 7S14.21 11 12 11 8 9.21 8 7 9.79 3 12 3M16 13.54C16 14.6 15.72 17.07 13.81 19.83L13 15L13.94 13.12C13.32 13.05 12.67 13 12 13S10.68 13.05 10.06 13.12L11 15L10.19 19.83C8.28 17.07 8 14.6 8 13.54C5.61 14.24 4 15.5 4 17V21H20V17C20 15.5 18.4 14.24 16 13.54Z'
      />
    </SvgIcon>
  )
}

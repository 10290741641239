export const authErrorCodeCheck = (errorCode: string) => {
  let errorMessage =
    'Onverwachte authenticatie fout, herlaad de pagina en probeer opnieuw.'

  switch (errorCode) {
    /** Sign in error codes: */
    case 'auth/invalid-email':
      errorMessage = 'Ongeldig email adres. Vul een geldig email adres in.'
      break
    case 'auth/weak-password':
      errorMessage =
        'Het wachtwoord dat u heeft gekozen is niet sterk genoeg, kies een sterker wachtwoord.'
      break
    case 'auth/user-not-found':
      errorMessage =
        'Gebruikersnaam niet gevonden, gebruik het juiste email adres om in te loggen.'
      break
    case 'auth/wrong-password':
      errorMessage =
        'U heeft een verkeerd wachtwoord ingevoerd. Probeer het nogmaals.'
      break
    case 'auth/user-disabled':
      errorMessage =
        'Uw gebruikersnaam en email zijn uitgeschakeld door uw organisatie. Vraag opnieuw toestemming via uw organisatie.'
      break
    /** Register error codes: */
    case 'auth/email-already-in-use':
      errorMessage =
        'Email wordt al gebruikt, login of kies een ander email om een account te registreren.'
      break
    case 'auth/operation-not-allowed':
      errorMessage =
        'Registreren met email en wachtwoord is niet mogelijk op dit moment, neem contact op met de applicatie admin om email registratie in te schakelen.'
      break
  }
  return errorMessage
}

import React from 'react'
import { Box } from '@mui/material'

import useMongo from 'hooks/useMongo'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { FloatingButton, PageContainer } from '@fivano/core'
import EditIcon from '@mui/icons-material/Edit'

import { ActionMenu } from '@fivano/core'
import { MenuButton } from '@fivano/core'
import { useHistory } from 'react-router-dom'
import { DataGridNew } from 'app/components/DataGridNew'
import { CourseGroupForm } from './CourseGroupForm'
import { useModalRoute } from 'hooks/useModalRoutes'

const courseGroupsColumnsDesktop = [
  {
    field: 'name',
    headerName: 'Lesgroep naam',
    width: 160,
    disableColumnMenu: true,
  },
]

const courseGroupsColumnsMobile = [
  {
    field: 'name',
    headerName: 'Lesgroep naam',
    width: 150,
    disableColumnMenu: true,
  },
]

export const CourseGroupsList = () => {
  const history = useHistory()
  const { useQueryDocs } = useMongo()
  const { modalData, closeModal, openModal } = useModalRoute()

  const { docsData } = useQueryDocs({
    collection: 'courseGroups',
    array: true,
  })

  return (
    <PageContainer maxWidth='lg'>
      {modalData.open && (
        <CourseGroupForm
          onCloseForm={() => {
            closeModal()
          }}
          editingDocID={modalData.id}
        />
      )}
      <Breadcrumbs />
      <Box p={2}>
        <Box marginTop={1}>
          <DataGridNew
            name='groups'
            rows={docsData}
            columns={courseGroupsColumnsDesktop}
            columnsMobile={courseGroupsColumnsMobile}
            hideFooterRowCount={true}
            onRowClick={rowData => history.push(`lesgroepen/${rowData.id}`)}
            rowActions={{
              renderCell: params => (
                <ActionMenu
                  iconPosition='vertical'
                  minWidth={250}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  <MenuButton
                    label={`Bewerk lesgroep`}
                    icon={<EditIcon />}
                    onClick={() => openModal(params.id)}
                  />
                </ActionMenu>
              ),
            }}
          />
        </Box>
        <FloatingButton
          label='Lesgroep toevoegen'
          onClick={() => openModal()}
        />
      </Box>
    </PageContainer>
  )
}

import React, { useState } from 'react'
import { ProgressTagsForm } from './ProgressTagsForm'
import { SettingsPage } from 'app/legacy/SettingsBlock'

const breadcrumbArray = [
  { title: 'Instellingen', path: '/instellingen' },
  { title: 'Voortgang', path: '/instellingen/voortgang' },
]

export const ProgressTags = () => {
  const [tagModalProps, setTagModalProps] = useState({
    formID: null,
    open: false,
  })
  const firestoreCode = 'Prta'
  const firestoreSettingsPath = 'progressTags'

  return (
    <>
      <ProgressTagsForm
        firestoreCode={firestoreCode}
        firestoreSettingsPath={firestoreSettingsPath}
        labelSingle='Tag'
        formProps={tagModalProps}
        onClose={() =>
          setTagModalProps({
            formID: null,
            open: false,
          })
        }
      />
      <SettingsPage
        onAdd={() => setTagModalProps({ formID: null, open: true })}
        onEdit={(event, rowData) =>
          setTagModalProps({
            formID: rowData.original[`id${firestoreCode}`],
            open: true,
          })
        }
        labelMultiple='voortgang tags'
        labelSingle='tag'
        breadcrumbs={breadcrumbArray}
        firestoreSettingsPath={firestoreSettingsPath}
        firestoreCode={firestoreCode}
      />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { useSnackbar } from '@fivano/core'
import { Box, Typography, Button, Grid } from '@mui/material'
import { AuthWrapper } from 'app/containers/Authentication/components/AuthWrapper'
import { PasswordTextField } from './PasswordTextfield'
import { TextField, LoadingOverlay } from '@fivano/core'
import { emailRegex } from 'utils/regex'
import { useErrorLogger } from 'hooks/useErrorLogger'

type UserAuthenticaiton = {
  email: string
  password: string
}

export const EmailSignIn = () => {
  const history = useHistory()
  const errorLogger = useErrorLogger()
  const firebase = useFirebase()
  const { enqueueSnackbar } = useSnackbar()
  const auth = useSelector(state => state.firebase.auth)
  useEffect(() => {
    if (auth.isLoaded && !auth.isEmpty && auth.uid !== undefined) {
      history.push('/')
    }
  }, [auth, history])
  const formObject = useForm()
  const { handleSubmit } = formObject
  const handleRoute = route => {
    history.push(route)
  }
  const [loadingAuthorization, setLoadingAuthorization] = useState(false)
  const [error, setError] = useState('')

  const handleLogin = (data: UserAuthenticaiton) => {
    setLoadingAuthorization(true)
    firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .then(() => {
        setLoadingAuthorization(false)
        enqueueSnackbar(`Ingelogd met ${data.email}`, {
          variant: 'success',
        })
        history.push('/')
      })
      .catch(error => {
        setLoadingAuthorization(false)
        if (error.code === 'auth/invalid-email') {
          setError('Ongeldig email adres. Vul een geldig email adres in.')
        } else if (error.code === 'auth/user-disabled') {
          setError(
            'Uw gebruikersnaam en email zijn uitgeschakeld door uw organisatie. Vraag opnieuw toestemming via uw organisatie.',
          )
        } else if (error.code === 'auth/user-not-found') {
          setError(
            'Gebruikersnaam niet gevonden, gebruik het juiste email adres om in te loggen.',
          )
        } else if (error.code === 'auth/wrong-password') {
          setError(
            'Uw heeft een verkeerd wachtwoord ingevoerd. Probeer het nogmaals.',
          )
        } else {
          setError('Fout bij inloggen, probeer opnieuw.')
        }
        errorLogger({ error })
      })
  }
  const [passwordValid, setPasswordValid] = useState(false)

  return (
    <>
      <Helmet>
        <title>Inloggen met email</title>
        <meta name='description' content='Inloggen met email' />
      </Helmet>
      <AuthWrapper title='Inloggen met email'>
        {loadingAuthorization && (
          <LoadingOverlay
            label='Gegevens valideren en inloggen'
            customStyle={{ borderRadius: 4 }}
          />
        )}
        <form onSubmit={handleSubmit(handleLogin)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='body1' color='error'>
                {error}
              </Typography>
              <Box p={1} />
              <TextField
                name='email'
                formObject={formObject}
                rules={{
                  required: 'Dit is een verplicht veld',
                  pattern: {
                    value: emailRegex,
                    message: 'Vul een geldig email adres in',
                  },
                }}
                label='E-mail'
                size='medium'
                autoComplete='on'
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordTextField
                formObject={formObject}
                label='Wachtwoord'
                helperText='Uw wachtwoord bevat minimaal 8 karakters, waarvan 1 hoofdletter, 1 cijfer en 1 speciaal karakter.'
                instructionText='Wachtwoord onjuist, uw wachtwoord bevat minimaal:'
                onValidate={setPasswordValid}
                showOnError
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type='submit'
                size='large'
                variant='contained'
                color='primary'
                disableElevation
                disabled={!passwordValid}
              >
                Inloggen
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color='primary'
                size='small'
                onClick={() => handleRoute('/wachtwoord-vergeten')}
              >
                Wachtwoord vergeten?
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color='primary'
                size='small'
                onClick={() => handleRoute('/registreren-met-email')}
              >
                Nog geen account? Registreer
              </Button>
            </Grid>
          </Grid>
        </form>
      </AuthWrapper>
    </>
  )
}

export function pick(object, keys) {
  return keys.reduce((obj, key) => {
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key]
    }
    return obj
  }, {})
}

export function pickArray(array, keys) {
  return keys.reduce((arr, key) => {
    arr.push(array.find(x => x._id === key))
    return arr
  }, [])
}

import { routeObjectType } from './types'
import { ExampleFormPage, ExampleList } from 'app/containers/Example'

export const exampleRoutes: routeObjectType = {
  exampleList: {
    path: '/examples',
    component: ExampleList,
    claims: { dev: 5 },
  },
  exampleListNew: {
    path: '/examples/formulier',
    component: ExampleFormPage,
    claims: { dev: 5 },
  },
  exampleListEdit: {
    path: '/examples/:exampleID/formulier',
    component: ExampleFormPage,
    claims: { dev: 5 },
  },
}

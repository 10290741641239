import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { RouteTypes } from 'types/RouteTypes'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { ExampleFormInputs } from './ExampleFormInputs'
import { Form } from '@fivano/core'
import useMongo from 'hooks/useMongo'

export const ExampleForm = ({
  wrapInside,
  exampleID,
  onCloseForm,
  onCreateSuccess,
}: {
  wrapInside: 'modal' | 'page' | 'formElement'
  exampleID?: string
  onCloseForm: (e: any) => void
  onCreateSuccess?: (e: any) => void
}) => {
  const errorLogger = useErrorLogger()
  const { updateDoc, useRefetchDoc, createDoc } = useMongo()

  const { status, docData: exampleData } = useRefetchDoc({
    collection: 'examples',
    docID: exampleID,
    disabled: !exampleID,
  })

  return (
    <Form
      onCloseForm={onCloseForm}
      wrapInside={wrapInside}
      docLabel='Example'
      hasDetails={false}
      loading={status === 'loading'}
      updateDoc={async data =>
        updateDoc({ collection: 'examples', docID: exampleID, data })
      }
      createDoc={async data => createDoc({ collection: 'examples', data })}
      onCreateSuccess={onCreateSuccess}
      editingDocID={exampleID}
      docData={exampleData}
      onError={errorLogger}
      formInputs={({ formObject }) => (
        <ExampleFormInputs formObject={formObject} />
      )}
    />
  )
}

export const ExampleFormPage = () => {
  const { exampleID } = useParams<RouteTypes>()
  const history = useHistory()

  return (
    <ExampleForm
      wrapInside='page'
      onCreateSuccess={response => {
        history.push(`/examples/${response.docs[0]._id}/formulier`)
      }}
      exampleID={exampleID}
      onCloseForm={() => history.push(`/examples`)}
    />
  )
}

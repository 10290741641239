import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { addDays, getHours, getMinutes } from 'date-fns'

import { AutoSelect } from '@fivano/core'

import { useDataStandards } from 'hooks/useDataStandards'

import { compareArraysAndObjects } from 'utils/compareArraysAndObjects'
import { isEmptyObject } from 'utils/isEmptyObject'
import { optionsCreator } from 'utils/optionsCreator'

import {
  GridSettingsProps,
  InputSettingProps,
  ProjectCodeProps,
  TimeRowInputProps,
} from './types'
import { DateTimeRow } from './DateTimeRow'

const gridSettings: GridSettingsProps = {
  starttime: {
    xs: 3,
    md: 4,
  },
  duration: {
    xs: 3,
    md: 4,
  },
  endtime: {
    xs: 3,
    md: 4,
  },
}

const overlapCheck = (row, data) => {
  let errorCheck = ''
  data?.forEach(item => {
    if (item.id !== row.id) {
      const error = checkOverlap(
        item?.startTime?.getTime(),
        item?.endTime?.getTime(),
        row?.startTime?.getTime(),
        row?.endTime?.getTime(),
      )
      if (error) {
        errorCheck = error
        return true
      }
    }
  })
  return errorCheck
}

const checkOverlap = (startTimeA, endTimeA, startTimeB, endTimeB) => {
  const checkStartTimeOverlap = startTimeB > startTimeA && startTimeB < endTimeA
  const checkEndTimeOverlap = endTimeB > startTimeA && endTimeB < endTimeA

  const checkSameOverlap = startTimeB === startTimeA && endTimeB === endTimeA

  const checkStartEndInsideData =
    (startTimeB <= startTimeA && endTimeB >= endTimeA) ||
    (startTimeA <= startTimeB && endTimeA >= endTimeB)

  if (checkStartTimeOverlap && checkEndTimeOverlap)
    return 'Starttijd en eindtijd overlappen met de begintijd van een andere rij'
  if (checkStartTimeOverlap)
    return 'Starttijd overlapt met de eindtijd van de vorige rij'
  if (checkEndTimeOverlap)
    return 'Eindtijd overlapt met de begintijd van de volgende rij'
  if (checkStartEndInsideData) return 'Andere rij valt binnen deze rij'
  if (checkSameOverlap)
    return 'Begin en eindtijd zijn hetzelfde als de volgende rij'
}

const checkValueChanged = (data, defaultValues) => {
  const dataCopy = { ...data }

  delete dataCopy.createdAt
  delete dataCopy.updatedAt

  const defaultValuesCopy = { ...defaultValues }
  delete defaultValuesCopy.createdAt
  delete defaultValuesCopy.updatedAt

  return !compareArraysAndObjects(dataCopy, defaultValuesCopy)
}

export const TimeRegistrationRowInputs = React.memo(
  ({
    personID,
    editMode = false,
    submitMode = false,
    date,
    docs,
    defaultValues,
    showLabels,
    onCreateRow,
    onUpdateRow,
    onSubmitSuccess,
    onSubmitError,
    locationsData,
    projectCodes,
  }: TimeRowInputProps) => {
    const createDataStandards = useDataStandards()
    const formObject = useForm({
      defaultValues: defaultValues,
      shouldFocusError: false,
    })
    const [error, setError] = useState<string>('')
    const watchCompanyCode = formObject.watch('companyCode')
    const watchProjectCode = formObject.watch('projectCode')

    const inputSettings: InputSettingProps = {
      starttimeProps: {
        name: 'startTime',
        label: showLabels ? 'Starttijd' : '',
        variant: 'standard',
        fullWidth: true,
      },
      durationProps: {
        name: 'duration',
        label: showLabels ? 'Duur' : '',
        variant: 'standard',
      },
      endtimeProps: {
        name: 'endTime',
        label: showLabels ? 'Eindtijd' : '',
        variant: 'standard',
        fullWidth: true,
      },
    }

    useEffect(() => {
      formObject.reset(defaultValues)
      getProjectCodesByID(defaultValues?.companyCode?.value)
      getWorkCodesByID(defaultValues?.projectCode?.value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues])

    useEffect(() => {
      if (!isEmptyObject(formObject.formState.errors)) {
        onSubmitError()
      }
      if (submitMode && isEmptyObject(formObject.formState.errors)) {
        formObject.handleSubmit(onSubmit)()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitMode])

    useEffect(() => {
      if (!isEmptyObject(formObject.formState.errors)) {
        onSubmitError()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formObject])

    const onSubmit = async data => {
      const nextDay = getHours(data.endTime) + getMinutes(data.endTime)
      if (nextDay === 0) data.endTime = addDays(data.endTime, 1)
      data.id = defaultValues?.id
      data.personID = personID
      data.type = 'timeRegistration'

      const hasError = await overlapCheck(data, docs)
      if (hasError) {
        onSubmitError()
        setError(hasError)
      } else {
        const dataStandards = createDataStandards({
          data,
          dataName: '',
          editForm: !defaultValues?.emptyRow,
        })
        const newData = {
          ...data,
          ...dataStandards,
        }
        if (defaultValues?.emptyRow) {
          onCreateRow(newData)
          setError('')
          onSubmitSuccess()
        } else {
          if (checkValueChanged(data, defaultValues)) {
            onUpdateRow && onUpdateRow(newData)
            setError('')
          } else {
            setError('')
          }
          onSubmitSuccess()
        }
      }
    }

    const [projCodes, setProjCodes] = useState([])
    const getProjectCodesByID = locationID => {
      const filtered = projectCodes.filter(x => x.location.value === locationID)

      setProjCodes(filtered)
    }

    const [workCodes, setWorkCodes] = useState([])
    const getWorkCodesByID = projectCodeID => {
      if (projectCodeID) {
        const projectcode: any = projectCodes?.find(
          (projectCode: ProjectCodeProps) => projectCode.id === projectCodeID,
        )
        setWorkCodes(projectcode?.currentWorkCodes)
      }
    }

    return (
      <Grid container>
        <Grid item xs={12} md={11}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={7}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  {editMode || submitMode ? (
                    <AutoSelect
                      label={showLabels ? 'Bedrijf/locatie' : ''}
                      name='companyCode'
                      variant='standard'
                      autoComplete='off'
                      options={
                        optionsCreator(locationsData, 'nameLoca', 'id') || []
                      }
                      formObject={formObject}
                      rules={{ required: 'Dit veld is verplicht.' }}
                      onChange={(_, v) => {
                        getProjectCodesByID(v?.value)
                        formObject.setValue('projectCode', null)
                        formObject.setValue('workCode', null)
                      }}
                    />
                  ) : (
                    <>
                      {showLabels && (
                        <Typography variant='caption' color='textSecondary'>
                          Bedrijf/locatie
                        </Typography>
                      )}
                      <Typography>
                        {defaultValues?.companyCode?.label}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  {editMode || submitMode ? (
                    <AutoSelect
                      name='projectCode'
                      label={showLabels ? 'Project' : ''}
                      variant='standard'
                      autoComplete='off'
                      options={optionsCreator(projCodes, 'name', 'id') || []}
                      formObject={formObject}
                      disabled={!watchCompanyCode}
                      rules={{ required: 'Dit veld is verplicht' }}
                      onChange={(_, v) => {
                        getWorkCodesByID(v?.value)
                        formObject.setValue('workCode', null)
                      }}
                    />
                  ) : (
                    <>
                      {showLabels && (
                        <Typography variant='caption' color='textSecondary'>
                          Project
                        </Typography>
                      )}
                      <Typography>
                        {defaultValues?.projectCode?.label}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  {editMode || submitMode ? (
                    <AutoSelect
                      name='workCode'
                      label={showLabels ? 'Werkcode' : ''}
                      variant='standard'
                      autoComplete='off'
                      options={workCodes || []}
                      disabled={!watchCompanyCode || !watchProjectCode}
                      formObject={formObject}
                      rules={{ required: 'Dit veld is verplicht' }}
                    />
                  ) : (
                    <>
                      {showLabels && (
                        <Typography variant='caption' color='textSecondary'>
                          Werkcode
                        </Typography>
                      )}
                      <Typography>{defaultValues?.workCode?.label}</Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={1}>
                <DateTimeRow
                  formObject={formObject}
                  inputSettings={inputSettings}
                  gridSettings={gridSettings}
                  generalSettings={{
                    hideDatepicker: true,
                    showLabels: true,
                    starttimeDate: date,
                    endtimeDate: date,
                  }}
                  readOnly={!(editMode || submitMode)}
                />
              </Grid>
            </Grid>
          </Grid>
          {error && (
            <Grid item>
              <Typography variant='caption' color='error'>
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  },
  (prevProps, nextProps) => {
    return (
      compareArraysAndObjects(
        prevProps.defaultValues,
        nextProps.defaultValues,
      ) &&
      prevProps.date === nextProps.date &&
      prevProps.editMode === nextProps.editMode &&
      prevProps.submitMode === nextProps.submitMode
    )
  },
)

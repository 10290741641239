import { Grid } from '@mui/material'
import React from 'react'
import { Form, FormRow, AutoSelect, TextField } from '@fivano/core'
import { useFirestore } from 'react-redux-firebase'
import { useErrorLogger } from 'hooks/useErrorLogger'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import EmailIcon from '@mui/icons-material/Email'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import BedrijfLocatie from 'app/icons/BedrijfLocatie'
import { emailRegex } from 'utils/regex'
import { useDataStandards } from 'hooks/useDataStandards'
import { useSelector } from 'hooks/useSelector'

type LocationFormProps = {
  /** ID of location */
  locationID?: string
  /** ID of relation */
  relationID: string
  /** Function triggered when closing the form */
  onCloseForm: (id: string) => void
}

export const LocationForm = ({
  locationID,
  relationID,
  onCloseForm,
}: LocationFormProps) => {
  const firestore = useFirestore()
  const errorLogger = useErrorLogger()

  const docData = useSelector(
    ({ firestore: { ordered } }) =>
      relationID &&
      locationID &&
      ordered[`locations_${relationID}`].find(item => item.id === locationID),
  )

  const createDataStandards = useDataStandards()
  const buildLocationData = data => {
    const dataStandards = createDataStandards({
      data,
      dataName: 'Loca',
      editForm: !!locationID,
      keywords: [data.nameLoca],
    })
    return { ...data, ...dataStandards }
  }

  const createLocationData = async data => {
    data.relationLoca = relationID
    data.archivedLoca = false
    return await firestore.collection('locations').add(data)
  }

  const updateLocationData = async data => {
    return await firestore.update(`locations/${locationID}`, data)
  }

  return (
    <Form
      docLabel='Locatie'
      docData={docData}
      buildDocData={buildLocationData}
      createDoc={createLocationData}
      updateDoc={updateLocationData}
      wrapInside='modal'
      editingDocID={locationID}
      hasDetails={true}
      onCloseForm={onCloseForm}
      onError={errorLogger}
      modalMaxWidth='sm'
      formInputs={({ formObject, showAllFields }) => (
        <Grid container spacing={1}>
          <FormRow icon={<BedrijfLocatie />}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  required
                  rules={{ required: 'Voer een locatie naam in' }}
                  name='nameLoca'
                  formObject={formObject}
                  multiline
                  label='Naam locatie'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='nameAddresseeLoca'
                  formObject={formObject}
                  multiline
                  label='Naam geadresseeerde'
                />
              </Grid>
            </Grid>
          </FormRow>
          <FormRow icon={<LocationOnIcon />}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  rules={{ required: 'Voer een straatnaam in' }}
                  name='addressStreetLoca'
                  formObject={formObject}
                  multiline
                  label='Straatnaam'
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  required
                  rules={{ required: 'Voer een huisnummer in' }}
                  name='addressNumberLoca'
                  formObject={formObject}
                  multiline
                  label='Nummer'
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  formObject={formObject}
                  name='addressAdditionLoca'
                  multiline
                  label='Toevoeging'
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  rules={{ required: 'Voer een postcode in' }}
                  name='addressZipLoca'
                  formObject={formObject}
                  multiline
                  label='Postcode'
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  required
                  rules={{ required: 'Voer een plaatsnaam in' }}
                  name='addressCityLoca'
                  formObject={formObject}
                  multiline
                  label='Plaatsnaam'
                />
              </Grid>
              {showAllFields && (
                <>
                  <Grid item xs={6}>
                    <AutoSelect
                      disabled
                      formObject={formObject}
                      label='Land'
                      name='countryLoca'
                      defaultValue={{ value: 'NL', label: 'Nederlands' }}
                      options={[{ value: 'NL', label: 'Nederland' }]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name='addressStateLoca'
                      formObject={formObject}
                      label='Provincie'
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </FormRow>
          <FormRow icon={<EmailIcon />}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  required
                  formObject={formObject}
                  rules={{
                    required: 'Verplicht veld',
                    pattern: {
                      value: emailRegex,
                      message: 'Vul een geldig email adres in',
                    },
                  }}
                  name='emailLoca'
                  multiline
                  label='Email'
                />
              </Grid>
            </Grid>
          </FormRow>
          <FormRow icon={<PhoneEnabledIcon />}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  formObject={formObject}
                  name='phoneLoca'
                  multiline
                  label='Telefoon'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  formObject={formObject}
                  name='descriptionLoca'
                  multiline
                  label='Omschrijving'
                  maxRows={20}
                  rows={5}
                />
              </Grid>
            </Grid>
          </FormRow>
        </Grid>
      )}
    />
  )
}

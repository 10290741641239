import React from 'react'
import { Drawer, List, styled } from '@mui/material'
import { NavigationLogo } from './NavigationLogo'
import { NavigationMenu } from './NavigationMenu'
import { NavigationFooter } from './NavigationFooter'
import { menuLayoutType } from 'app/router/types'

export const DRAWER_WIDTH_OPEN = 260
export const DRAWER_WIDTH_CLOSED = 60
const DrawerStyled = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'opened',
})<any>(({ theme, opened }) => ({
  width: opened ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSED,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  '& .MuiPaper-root': {
    width: `${opened ? DRAWER_WIDTH_OPEN : 60}px !important`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('xs')]: {
      width: opened ? DRAWER_WIDTH_OPEN : parseInt(theme.spacing(7)) + 2,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

const StyledList = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  padding: 0,
}))

type AppMenuProps = {
  onClickMenu?: any
  open: boolean
  mobileMenu?: boolean
  menuData: menuLayoutType[]
}

export const Navigation = ({
  onClickMenu,
  open,
  mobileMenu,
  menuData,
}: AppMenuProps) => {
  return (
    <DrawerStyled
      anchor='left'
      open={open}
      variant={mobileMenu ? 'temporary' : 'permanent'}
      opened={open}
    >
      <StyledList>
        <NavigationLogo onClickMenu={onClickMenu} open={open} />
        <NavigationMenu menuData={menuData} open={open} />
        <NavigationFooter open={open} />
      </StyledList>
    </DrawerStyled>
  )
}

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useSelector } from 'hooks/useSelector'
import { useFirestore } from 'react-redux-firebase'
import { format } from 'date-fns'
import {
  Grid,
  Paper,
  Box,
  Button,
  Chip,
  Typography,
  Avatar,
  capitalize,
  styled,
} from '@mui/material'
import { useHistory } from 'react-router'

import { PageContainer, Checkbox, ListView } from '@fivano/core'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { Searchbar } from 'app/components/Searchbar'
import { useSettings } from 'hooks/useSettings'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { TrackType } from 'types/Track'
import { convertToDate } from 'utils/convertToDate'

const PaperStyled = styled(Paper)(() => ({
  marginBottom: 80,
}))

export const TrackAssignOverview = () => {
  const history = useHistory()
  const firestore = useFirestore()
  const settings = useSettings()
  const labels = settings.tracks.labels

  const [tracksLimit, setTracksLimit] = useState(25)
  const [searchValue, setSearchValue] = useState('')
  const [showEmptyEnd, setShowEmptyEnd] = useState(false)

  const handleSearchValue = data => {
    setSearchValue(data.value)
  }
  const handleSearchClear = () => {
    setSearchValue('')
  }
  const handleLoadMore = () => {
    setTracksLimit(tracksLimit + 15)
  }
  const breadcrumbArray = [
    { title: 'Home', path: '/' },
    { title: `Toegewezen ${labels.tracks}`, path: '/toegewezen-trajecten' },
  ]
  const profile = useSelector(state => state.firebase.profile)
  const locationQuery = useLocationQuery()
  const [allTracks, setAllTracks] = useState<TrackType[]>([])

  useEffect(() => {
    const buildQuery = (searchValue, showEmptyEnd) => {
      let where = [...locationQuery('locationIDTrack')]
      let orderBy: any = ['endDateTrack', 'asc']
      if (searchValue !== '' && searchValue != null) {
        where.push(['moduleTrack.value', '==', searchValue])
      }
      if (showEmptyEnd === true) {
        // @ts-ignore
        where.push(['endDateTrack', '==', null])
        orderBy = undefined
      } else {
        // @ts-ignore
        where.push(['endDateTrack', '>', new Date()])
      }

      const queryObject: any = {
        collectionGroup: 'tracks',
        // @ts-ignore
        where: where,
        limit: tracksLimit,
      }
      if (orderBy !== undefined) {
        queryObject.orderBy = orderBy
      }
      return queryObject
    }
    const query = buildQuery(searchValue, showEmptyEnd)

    firestore.get(query).then(async response => {
      const trackItems: Array<any> = []
      // create promise function to that resolves after add mergeData to trackItems array
      const getTrackParent = doc => {
        return new Promise((resolve, reject) => {
          const parentPath = doc.ref.path.split('/tracks')[0]
          if (!trackItems.some(item => item.personIDPers === doc.data().id)) {
            firestore
              .get(parentPath)
              .then(async response => {
                trackItems.push({ ...doc.data(), ...response.data() })
                resolve(response)
              })
              .catch(error => {
                reject(error)
              })
          }
        })
      }
      // await all resolves of promise function on response.docs array
      await Promise.all(
        // @ts-ignore
        response.docs.map(async doc => {
          await getTrackParent(doc)
        }),
      )
      // setAllTracks state after trackItems has mergeData with every track and parent
      // @ts-ignore
      setAllTracks(trackItems)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracksLimit, profile, searchValue, showEmptyEnd])

  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          gridProps: {
            xs: 12,
            sm: 6,
            md: 5,
          },
          Cell: data => {
            return (
              <>
                <Grid container wrap='nowrap' spacing={2} alignItems='center'>
                  <Grid item>
                    <Avatar
                      src={
                        data.row.original.avatarImagePers
                          ? `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FB_PROJECT_ID}.appspot.com/o/locations%2FpublicLocation%2Fpersons%2F${data.row.original.id}%2Favatar%2F${data.row.original.id}?alt=media`
                          : undefined
                      }
                    >{`${data.row.original.firstNamePers.charAt(
                      0,
                    )}${data.row.original.lastNamePers.charAt(0)}`}</Avatar>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant='body1'
                      display='inline'
                    >{`${data.row.original.firstNamePers} ${data.row.original.middleNamePers} ${data.row.original.lastNamePers} `}</Typography>
                    {data.row.original.archivedPers && (
                      <Chip label='Gearchiveerd' size='small' />
                    )}
                  </Grid>
                </Grid>
              </>
            )
          },
        },
        {
          Header: `Datum ${labels.tracks}`,
          gridProps: {
            xs: 4,
            sm: 3,
            md: 2,
          },
          Cell: data => {
            return (
              <div>
                <Typography variant='body1'>
                  {`Eind ${
                    data.row.original?.endDateTrack &&
                    format(
                      convertToDate(data.row.original.endDateTrack),
                      'dd-MM-yyyy',
                    )
                  }`}
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {`Start ${
                    data.row.original?.startDateTrack &&
                    format(
                      convertToDate(data.row.original.startDateTrack),
                      'dd-MM-yyyy',
                    )
                  }`}
                </Typography>
              </div>
            )
          },
        },
        {
          Header: `${capitalize(labels.track)}`,
          gridProps: {
            xs: 8,
            sm: 3,
            md: 5,
          },
          Cell: data => (
            <div>
              <Typography variant='body2' display='inline' gutterBottom>
                {data.row.original?.moduleTrack?.label}{' '}
                <Chip
                  size='small'
                  label={data.row.original?.moduleCodeTrack?.label}
                />
              </Typography>
            </div>
          ),
        },
      ],
    },
  ]

  const handleClick = (event, data) => {
    if (data.original.locationIDPers)
      history.push(
        `/locatie/${data.original?.locationIDPers}/deelnemers/${data.original?.personIDTrack}`,
      )
  }

  // ==== FILTER FUNCTIONALITY ====//
  const formObject = useForm()
  const { handleSubmit } = formObject
  const handleFilterSubmit = data => {
    setShowEmptyEnd(data.showEmptyEnd)
  }

  return (
    <>
      <Helmet>
        <title>{`${capitalize(labels.tracks)} overzicht`}</title>
      </Helmet>
      <PageContainer maxWidth='lg'>
        <Breadcrumbs breadcrumbs={breadcrumbArray} />
        <PaperStyled>
          <Box p={2}>
            <Grid container>
              <Grid item xs={6}>
                <Searchbar
                  placeholder={`Zoeken in ${capitalize(labels.tracks)}`}
                  value={searchValue}
                  elevation={0}
                  onClear={handleSearchClear}
                  onSubmit={handleSearchValue}
                  autoComplete={{
                    autoFocus: true,
                    hideBorder: true,
                    placeholder: `${capitalize(labels.track)} naam of code`,
                    required: true,
                    collection: 'modules',
                    where: [['archivedModu', '==', false]],
                    labelKeys: ['nameModu', 'codeModu'],
                    searchKey: 'keywordsModu',
                  }}
                  filter={
                    <form onSubmit={handleSubmit(handleFilterSubmit)}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Checkbox
                            formObject={formObject}
                            label={`${capitalize(
                              labels.tracks,
                            )} zonder einddatum`}
                            name='showEmptyEnd'
                            defaultValue={showEmptyEnd}
                          />
                        </Grid>
                      </Grid>
                      <Grid container justifyContent='flex-end'>
                        <Grid item>
                          <Button
                            disableElevation
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Zoeken
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  }
                />
              </Grid>
            </Grid>
          </Box>
          {allTracks?.length > 0 ? (
            <>
              <ListView
                data={allTracks}
                columns={listColumns}
                onClick={handleClick}
              />
              {allTracks.length === tracksLimit && (
                <Button fullWidth onClick={handleLoadMore}>
                  {`Meer ${labels.track} laden`}
                </Button>
              )}
            </>
          ) : (
            <Box p={2}>
              <Typography variant='body1'>
                {`Geen ${labels.track} gevonden. Voeg een nieuw ${labels.track} toe`}
              </Typography>
            </Box>
          )}
        </PaperStyled>
      </PageContainer>
    </>
  )
}

import useMongo from 'hooks/useMongo'
import { useEffect } from 'react'
import { useState } from 'react'
import { CategoriesLayout } from './CategoriesLayout/CategoriesLayout'
import { ProductsLayout } from './ProductsLayout/ProductsLayout'

export const POSLayout = () => {
  const [layout, setLayout] = useState<any>({})
  const [activeID, setActiveID] = useState<any>(undefined)
  const [categories, setCategories] = useState<any>([])
  const [products, setProducts] = useState<any>([])
  const [layoutID, setLayoutID] = useState(undefined)
  const { useQueryDocs, useRefetchDoc } = useMongo()

  const { docsData } = useQueryDocs({
    collection: 'pointOfSaleLayout',
    query: {
      name: { $eq: 'test' },
    },
  })

  const { status, docData } = useRefetchDoc({
    collection: 'pointOfSaleLayout',
    docID: layoutID,
  })

  useEffect(() => {
    if (status === 'success' && docData) {
      setLayoutID(undefined)
      setCategories(docData.categories)
      setLayout(docData)

      if (activeID) {
        const category = docData.categories.find(x => x.id === activeID)
        setProducts(category.products)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, docData])

  useEffect(() => {
    if (docsData.length) {
      setLayout(docsData[0])
      setCategories(docsData[0].categories)
    }
  }, [docsData])

  const handleActiveItem = id => {
    setActiveID(id)
    const activeProducts = layout.categories.find(x => x.id === id)?.products
    setProducts(activeProducts || [])
  }

  return (
    <div style={{ padding: '20px 18px 20px 10px' }}>
      <h1>{docsData?.[0]?.name}</h1>
      <CategoriesLayout
        layoutDoc={layout}
        items={categories}
        activeID={activeID}
        onActiveID={handleActiveItem}
        onDrag={setCategories}
        onRefetch={setLayoutID}
      />
      {activeID && (
        <ProductsLayout
          layoutDoc={layout}
          items={products}
          activeID={activeID}
          rows={4}
          columns={6}
          onDragEnd={setProducts}
          onRefetch={setLayoutID}
        />
      )}
    </div>
  )
}

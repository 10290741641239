import { menuLayoutType } from 'app/router/types'
import { authorizationRoutes } from './authorizationRoutes'

export const authorizationMenu: menuLayoutType[] = [
  {
    name: 'Gebruikers',
    route: authorizationRoutes.userSettings,
  },
  {
    name: 'Workspaces',
    route: authorizationRoutes.workspaces,
  },
  {
    name: 'Rollen Profielen',
    route: authorizationRoutes.authorizationRoleProfiles,
  },
]

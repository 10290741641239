import React, { ReactNode } from 'react'
import {
  MenuItem as MenuItemMUI,
  styled,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'

const LinkStyled = styled(Link)(() => ({
  width: '100%',
  textDecoration: 'none',
  color: 'inherit',
}))

export const MenuItemStyled = styled(MenuItemMUI, {
  shouldForwardProp: prop => prop !== 'subMenu' && prop !== 'isActive',
})<{ subMenu?: boolean; isActive?: boolean }>(
  ({ theme, subMenu, isActive }) => ({
    minHeight: 40,
    ...(subMenu && {
      minWidth: 200,
    }),
    [theme.breakpoints.up('sm')]: {
      minHeight: 40,
    },
    borderRadius: 4,
    padding: '4px',
    margin: subMenu ? '1px 6px 1px 42px' : '1px 6px',
    backgroundColor: isActive
      ? theme.fivano.palette.backgroundColor
      : theme.palette.background.default,
    '& .MuiListItemText-root': {
      paddingTop: '1px',
      color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
    },
    '& svg': {
      ...(isActive && {
        color: theme.palette.primary.main,
      }),
    },
    '&:hover': {
      backgroundColor: theme.fivano.palette.backgroundColor,
      '& .MuiListItemText-root': {
        color: theme.palette.primary.main,
      },
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  }),
)
const IconWrapper = styled(ListItemIcon)(() => ({
  padding: '4px 12px 4px 8px',
}))

export const MenuItemLink = ({
  path,
  icon,
  label,
  open = false,
  hasMenu = false,
  subMenu = false,
  disableTooltip = false,
  isParentActive = false,
  isChildActive = false,
  onClick,
  children,
}: {
  label: string
  path?: string
  icon?: any
  open?: boolean
  hasMenu?: boolean
  subMenu?: boolean
  disableTooltip?: boolean
  isParentActive?: boolean
  isChildActive?: boolean
  onClick?: (event: any) => void
  children?: ReactNode
}) => {
  const Icon = icon
  const tooltipDisabled = disableTooltip || open || subMenu

  return (
    <NavigationLink to={path}>
      <Tooltip
        title={label}
        placement='right'
        arrow
        disableFocusListener={tooltipDisabled}
        disableHoverListener={tooltipDisabled}
        disableInteractive={tooltipDisabled}
        disableTouchListener={tooltipDisabled}
      >
        <MenuItemStyled
          isActive={isParentActive}
          subMenu={subMenu}
          onClick={event => onClick && onClick({ event, path })}
        >
          {children || (
            <>
              {Icon && (
                <IconWrapper>
                  <Icon />
                </IconWrapper>
              )}
              {open && <ListItemText>{label}</ListItemText>}
              {hasMenu &&
                (isParentActive || isChildActive ? (
                  <KeyboardArrowDown color='action' />
                ) : (
                  <KeyboardArrowRight color='action' />
                ))}
            </>
          )}
        </MenuItemStyled>
      </Tooltip>
    </NavigationLink>
  )
}

const NavigationLink = ({
  children,
  to,
}: {
  children: ReactNode
  to?: string
}) =>
  to === undefined ? (
    <div>{children}</div>
  ) : (
    <LinkStyled to={to}>{children}</LinkStyled>
  )

import React, { useState } from 'react'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Helmet } from 'react-helmet-async'
import { Box, Button, Typography } from '@mui/material'
import { useSelector } from 'hooks/useSelector'
import { UserRolesProfileForm } from './UserRolesProfileForm'
import { SettingsContainer } from 'app/components/SettingsContainer'
import { ListView } from '@fivano/core'

const breadcrumbArray = [
  { title: 'Instellingen', path: '/instellingen' },
  {
    title: 'Gebruikers rollen profielen',
    path: '/instellingen/gebruikers-rollen-profielen',
  },
]
const listColumns = [
  {
    Header: ' ',
    canGroupBy: false,
    canSort: false,
    columns: [
      {
        Header: 'Naam',
        accessor: 'nameRopr',
        gridProps: {
          xs: 12,
        },
      },
    ],
  },
]
export function UserRolesProfile() {
  useFirestoreConnect([
    {
      collection: 'appSettings/roleProfiles/roleProfiles',
      storeAs: 'roleProfiles',
      limit: 100,
    },
  ])

  const rolesProfiles = useSelector(
    state => state.firestore.ordered.roleProfiles,
  )
  const [roleProfileForm, setRoleProfileForm] = useState({
    valueRopr: null,
    nameRopr: '',
    open: false,
  })

  return (
    <>
      <Helmet>
        <title>Gebruikers rollen profielen - Instellingen</title>
        <meta
          name='description'
          content='Gebruikers rollen profielen - instellingen'
        />
      </Helmet>
      {roleProfileForm.open && (
        <UserRolesProfileForm
          wrapInside='modal'
          modalMaxWidth='md'
          onCloseForm={() => {
            setRoleProfileForm({
              valueRopr: null,
              nameRopr: '',
              open: false,
            })
          }}
          editingDocID={roleProfileForm.valueRopr}
        />
      )}
      <SettingsContainer breadcrumbs={breadcrumbArray}>
        <Box py={2} px={3} maxWidth={600}>
          <Typography gutterBottom variant='h6'>
            Gebruikers rollen profiel
          </Typography>
          <Typography gutterBottom variant='body2' color='textSecondary'>
            Gebruikers in de applicatie hebben één of meerdere rollen profielen
            toegewezen. Een rollen profiel geeft een gebruiker lees, bewerk,
            toevoeg of verwijder rechten in onderdelen van de applicatie.
          </Typography>
          <Button
            variant='contained'
            color='primary'
            onClick={() =>
              setRoleProfileForm({
                valueRopr: null,
                nameRopr: '',
                open: true,
              })
            }
          >
            Rollen profiel toevoegen
          </Button>
        </Box>
        {rolesProfiles?.length > 0 && (
          <ListView
            data={rolesProfiles}
            columns={listColumns}
            onClick={(event, data) =>
              setRoleProfileForm({
                valueRopr: data.original.valueRopr,
                nameRopr: data.original.nameRopr,
                open: true,
              })
            }
          />
        )}
      </SettingsContainer>
    </>
  )
}

import React, { useState } from 'react'
import { Box, Button, Paper } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { PageContainer } from '@fivano/core'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { ExampleForm } from './ExampleForm'
import { DataGridNew } from 'app/components/DataGridNew'
import useMongo from 'hooks/useMongo'
import { GridColDef } from '@mui/x-data-grid-pro'

const exampleColumns: GridColDef[] = [{ field: 'name', headerName: 'Naam' }]

const breadcrumbArray = [
  { title: 'Home', path: '/' },
  { title: `Example`, path: `/example` },
]

export const ExampleList = () => {
  const { useQueryDocs } = useMongo()

  const history = useHistory()
  const [modalForm, setModalForm] = useState({
    exampleID: undefined,
    open: false,
  })

  const { docsData: examplesData } = useQueryDocs({
    collection: 'examples',
    array: true,
  })

  return (
    <>
      {modalForm.open && (
        <ExampleForm
          wrapInside='modal'
          exampleID={modalForm.exampleID}
          onCloseForm={() =>
            setModalForm({ exampleID: undefined, open: false })
          }
        />
      )}
      <PageContainer maxWidth='lg'>
        <Breadcrumbs breadcrumbs={breadcrumbArray} />
        <Paper>
          <Box p={2}>
            <DataGridNew
              name='examples'
              rows={examplesData || []}
              columns={exampleColumns}
              columnsMobile={[]}
              isEditable={true}
              localeText={{
                noRowsLabel: 'Er zijn nog geen voorbeelden toegevoegd',
              }}
              onRowClick={params => {
                history.push(`/examples/${params.row._id}/formulier`)
              }}
            />
          </Box>
          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={() => {
              history.push(`/examples/formulier`)
            }}
          >
            Page Form
          </Button>

          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={() => {
              setModalForm({ exampleID: undefined, open: true })
            }}
          >
            Modal Form
          </Button>
        </Paper>
      </PageContainer>
    </>
  )
}

import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { emailRegex } from 'utils/regex'
import { Grid, Button } from '@mui/material'
import { Form } from '@fivano/core'
import { TextField } from '@fivano/core'
import { PasswordTextField } from './PasswordTextfield'
import { useErrorLogger } from 'hooks/useErrorLogger'
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth'

export const Register = () => {
  const auth = getAuth()

  const errorLogger = useErrorLogger()

  const createDoc = async ({ email, password }) => {
    const response = await createUserWithEmailAndPassword(auth, email, password)
    await sendEmailVerification(response.user)
  }
  const [passwordValid, setPasswordValid] = useState(false)

  return (
    <>
      <Helmet>
        <title>Registreren</title>
      </Helmet>
      <Form
        docLabel='Registreer'
        modalMaxWidth='sm'
        wrapInside='formElement'
        hasDetails={false}
        createDoc={createDoc}
        onError={errorLogger}
        docData={{}}
        updateDoc={async () => {}}
        onCloseForm={() => {}}
        formInputs={({ formObject, onSubmit }) => (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name='email'
                formObject={formObject}
                rules={{
                  required: 'Dit is een verplicht veld',
                  pattern: {
                    value: emailRegex,
                    message: 'Vul een geldig email adres in',
                  },
                }}
                label='E-mail'
                size='medium'
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordTextField
                formObject={formObject}
                label='Wachtwoord'
                name='password'
                onValidate={setPasswordValid}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type='submit'
                size='large'
                variant='contained'
                color='primary'
                onClick={onSubmit}
                disableElevation
                disabled={!passwordValid}
              >
                Account registreren
              </Button>
            </Grid>
          </Grid>
        )}
      />
    </>
  )
}

import React from 'react'
import {
  Paper,
  List,
  Grid,
  Typography,
  Box,
  Hidden,
  styled,
} from '@mui/material'
import { PageContainer } from '@fivano/core'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { IconButton } from '@fivano/core'
import { ArrowBack } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { settingsMenu } from 'app/legacy/ApplicationSettings/settingsMenu'
import { hasClaimLevel } from 'utils/hasClaimLevel'
import { useSelector } from 'hooks/useSelector'
import { MenuItemLink } from 'app/containers/Navigation/MenuItemLink'

const GridContentWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}))
const BoxSettingsHeader = styled(Box)(() => ({
  padding: '8px 8px',
  maxWidth: 600,
}))

type SettingsContainerProps = {
  children: any
  breadcrumbs: any
  showTitle?: boolean
}
export const SettingsContainer = ({
  children,
  breadcrumbs,
  showTitle = true,
}: SettingsContainerProps) => {
  const history = useHistory()
  const profile = useSelector(state => state.firebase.profile)
  const location = history.location.pathname
  const settingsMenuList = settingsMenu()
  return (
    <PageContainer maxWidth='lg' disableGuttersMobile>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Paper>
        <Grid container>
          <Hidden mdDown>
            <Grid item md={3}>
              <List>
                {settingsMenuList.map(
                  menuItem =>
                    hasClaimLevel(profile, menuItem.route.claims) && (
                      <MenuItemLink
                        key={menuItem.route.path}
                        path={menuItem.route.path}
                        icon={menuItem?.icon}
                        label={menuItem.name}
                        open
                      />
                    ),
                )}
              </List>
            </Grid>
          </Hidden>
          {settingsMenuList.map(
            route =>
              route.route.path === location && (
                <GridContentWrapper key={route.name} item xs={12} md={9}>
                  {showTitle && (
                    <BoxSettingsHeader>
                      <Grid container alignItems='center' spacing={1}>
                        <Grid item>
                          <IconButton
                            label='Terug naar instellingen'
                            onClick={() => history.push('/instellingen')}
                            size='large'
                          >
                            <ArrowBack />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <Typography variant='h5'>{route.name}</Typography>
                        </Grid>
                      </Grid>
                    </BoxSettingsHeader>
                  )}

                  {children}
                </GridContentWrapper>
              ),
          )}
        </Grid>
      </Paper>
    </PageContainer>
  )
}

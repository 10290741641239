import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useSnackbar } from '@fivano/core'
import { Box, Typography, Button, Grid } from '@mui/material'
import { PasswordTextField } from './PasswordTextfield'
import { TextField, LoadingOverlay } from '@fivano/core'
import { emailRegex } from 'utils/regex'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { authErrorCodeCheck } from './authErrorCodeCheck'

type UserAuthenticaiton = {
  email: string
  password: string
}

export const EmailLogin = () => {
  const auth = getAuth()
  const history = useHistory()
  const formObject = useForm()
  const errorLogger = useErrorLogger()
  const { enqueueSnackbar } = useSnackbar()
  const [loadingMessage, setLoadingMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [passwordValid, setPasswordValid] = useState(false)

  const handleLogin = (data: UserAuthenticaiton) => {
    setLoadingMessage('Bezig met inloggen')

    signInWithEmailAndPassword(auth, data.email, data.password)
      .then(response => {
        console.info(response)
        /** GET USER FROM MONGODB AND REMEMBER IT IN REDUX */
        enqueueSnackbar(`Ingelogd met ${data.email}`, {
          variant: 'success',
        })
        setLoadingMessage('')
      })
      .catch(error => {
        errorLogger(error)
        const errorMessage = authErrorCodeCheck(error.code)
        setErrorMessage(errorMessage)
        setLoadingMessage('')
      })
  }

  return (
    <>
      <Helmet>
        <title>Inloggen met email</title>
        <meta name='description' content='Inloggen met email' />
      </Helmet>
      <>
        {loadingMessage && <LoadingOverlay label={loadingMessage} />}
        <form onSubmit={formObject.handleSubmit(handleLogin)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='body1' color='error'>
                {errorMessage}
              </Typography>
              <Box p={1} />
              <TextField
                name='email'
                formObject={formObject}
                rules={{
                  required: 'Dit is een verplicht veld',
                  pattern: {
                    value: emailRegex,
                    message: 'Vul een geldig email adres in',
                  },
                }}
                label='E-mail'
                size='medium'
                autoComplete='on'
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordTextField
                formObject={formObject}
                label='Wachtwoord'
                helperText='Uw wachtwoord bevat minimaal 8 karakters, waarvan 1 hoofdletter, 1 cijfer en 1 speciaal karakter.'
                instructionText='Wachtwoord onjuist, uw wachtwoord bevat minimaal:'
                onValidate={setPasswordValid}
                showOnError
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type='submit'
                size='large'
                variant='contained'
                color='primary'
                disableElevation
                disabled={!passwordValid}
              >
                Inloggen
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color='primary'
                size='small'
                onClick={() => history.push('/auth/wachtwoord-vergeten')}
              >
                Wachtwoord vergeten?
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    </>
  )
}

import React from 'react'
import { Box } from '@mui/material'
import useMongo from 'hooks/useMongo'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import {
  FloatingButton,
  PageContainer,
  ActionMenu,
  MenuButton,
} from '@fivano/core'
import { DataGrid } from 'app/components/DataGrid'
import EditIcon from '@mui/icons-material/Edit'
import { CourseModulesForm } from './CourseModulesForm'
import { useModalRoute } from 'hooks/useModalRoutes'

const courseModulesColumnsDesktop = [
  {
    field: 'name',
    headerName: 'Module naam',
    width: 160,
    disableColumnMenu: true,
  },
  {
    field: 'description',
    headerName: 'Beschrijving',
    width: 300,
    disableColumnMenu: false,
  },
]

const courseModulesColumnsMobile = [
  {
    field: 'name',
    headerName: 'Module score',
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: 'description',
    headerName: 'Beschrijving',
    width: 50,
    disableColumnMenu: true,
  },
]

export const CourseModulesList = () => {
  const { useQueryDocs } = useMongo()
  const { modalData, closeModal, openModal } = useModalRoute()

  const { docsData } = useQueryDocs({
    collection: 'courseModules',
    array: true,
  })

  return (
    <PageContainer maxWidth='lg'>
      <Breadcrumbs />
      <Box p={2}>
        {modalData.open && (
          <CourseModulesForm
            onCloseForm={() => closeModal()}
            editingDocID={modalData.id}
          />
        )}
        <Box marginTop={1}>
          <DataGrid
            gridName='course modules'
            rows={docsData}
            columns={courseModulesColumnsDesktop}
            columnsMobile={courseModulesColumnsMobile}
            hideFooterRowCount={true}
            onRowClick={rowData => openModal(rowData.row._id)}
            rowActions={{
              renderCell: params => {
                return (
                  <ActionMenu
                    iconPosition='vertical'
                    minWidth={250}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    <MenuButton
                      label={`Bewerk module`}
                      icon={<EditIcon />}
                      onClick={() => openModal(params.id)}
                    />
                  </ActionMenu>
                )
              },
            }}
          />
        </Box>
        <FloatingButton
          label='Les module toevoegen'
          onClick={() => openModal()}
        />
      </Box>
    </PageContainer>
  )
}

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useFirebase } from 'react-redux-firebase'

import { Grid, Typography, styled } from '@mui/material'
import { PasswordTextField } from '../Authentication/PasswordTextfield'
import { Form, LoadingOverlay } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { useHistory } from 'react-router-dom'

const DivStyled = styled('div')(() => ({
  position: 'relative',
}))

type PasswordResetProps = {
  currentPassword: string
  newPassword: string
  newPasswordConfirm: string
}
export function PasswordResetForm() {
  const [loadingMessage, setLoadingMessage] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [passwordSuccess, setPasswordSuccess] = useState('')
  const errorLogger = useErrorLogger()
  const history = useHistory()

  const formObject = useForm()
  const { reset, setError } = formObject
  const firebase: any = useFirebase()
  const reauthenticate = currentPassword => {
    const user = firebase.auth().currentUser

    const credential = firebase.auth.EmailAuthProvider.credential(
      user?.email,
      currentPassword,
    )
    return user?.reauthenticateWithCredential(credential)
  }
  const handleUpdatePassword = async (data: PasswordResetProps) => {
    setLoadingMessage('Wachtwoord wijzigen')
    data?.currentPassword &&
      reauthenticate &&
      firebase &&
      reauthenticate(data?.currentPassword)
        .then(() => {
          const user = firebase.auth().currentUser

          user
            .updatePassword(data.newPassword)
            .then(() => {
              setPasswordSuccess('Uw wachtwoord is gewijzigd')
              setLoadingMessage('')
              reset()
            })
            .catch(error => {
              setPasswordError(
                'Uw wachtwoord kan niet worden gewijzigd. Probeer het later opnieuw.',
              )
              errorLogger({ error })
              setLoadingMessage('')
            })
        })
        .catch(error => {
          let errorMessage =
            'Uw wachtwoord kan niet worden gewijzigd. Probeer het later opnieuw.'
          if (error.code === 'auth/wrong-password') {
            errorMessage =
              'Uw huidige wachtwoord is niet correct, vul het juiste huidige wachtwoord in.'
          } else if (error.code === 'auth/wrong-password') {
            errorMessage =
              'Uw huidige wachtwoord is niet correct, vul het juiste huidige wachtwoord in.'
          } else if (error.code === 'auth/user-disabled') {
            errorMessage =
              'Uw gebruikersnaam en email zijn uitgeschakeld door uw organisatie. Vraag opnieuw toestemming via uw organisatie.'
          }
          setPasswordError(errorMessage)
          setError('currentPassword', { type: 'manual', message: errorMessage })
          errorLogger({ error })
          setLoadingMessage('')
        })
  }

  return (
    <DivStyled>
      {loadingMessage && (
        <LoadingOverlay
          label={loadingMessage}
          customStyle={{ borderRadius: 4 }}
        />
      )}
      <Form
        docLabel='Wachtwoord'
        updateDoc={async () => {}}
        createDoc={handleUpdatePassword}
        docData={{}}
        hasDetails={false}
        wrapInside='page'
        modalMaxWidth='md'
        onError={errorLogger}
        onCloseForm={() => history.push('/')}
        formInputs={({ formObject }) => {
          return (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PasswordTextField
                  formObject={formObject}
                  name='currentPassword'
                  label='Huidige wachtwoord'
                  disableValidation
                  size='small'
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordTextField
                  formObject={formObject}
                  name='newPassword'
                  label='Nieuw wachtwoord'
                  showOnError
                  helperText='Uw wachtwoord bevat minimaal 8 karakters, met 1 hoofdletter, 1 cijfers en 1 speciaal karakter.'
                  size='small'
                />
              </Grid>
              <Grid item xs={12}>
                {passwordError !== '' && (
                  <Typography variant='body1' color='error'>
                    {passwordError}
                  </Typography>
                )}
                {passwordSuccess !== '' && (
                  <Typography variant='body1'>{passwordSuccess}</Typography>
                )}
              </Grid>
            </Grid>
          )
        }}
      />
    </DivStyled>
  )
}

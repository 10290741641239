import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function FivanoLogo(props) {
  return (
    <SvgIcon {...props} viewBox='0 0 1050 204'>
      <path d='M909 2C964.23 2 1009 46.77 1009 102C1009 157.23 964.23 202 909 202C853.77 202 809 157.23 809 102C809 46.77 853.77 2 909 2ZM839 102C839 140.66 870.34 172 909 172C947.66 172 979 140.66 979 102C979 63.34 947.66 32 909 32C870.34 32 839 63.34 839 102Z'></path>
      <path d='M631 1L661 1L661 201L631 201L631 1ZM631 0.84L669.34 0.84L669.34 31.91L631 31.91L631 0.84ZM751 171.37L788 171.37L788 201.37L751 201.37L751 171.37ZM759 1L789 1L789 201L759 201L759 1ZM642.73 16.11L668.41 1.18L775.92 186.07L750.24 201L642.73 16.11Z'></path>
      <path d='M495.9 1.01L611.34 200.96L577.43 200.96L495.89 59.72L414.34 200.96L380.47 200.96L495.9 1.01Z'></path>
      <path d='M350.63 201.33L235.19 1.39L269.1 1.39L350.64 142.62L432.19 1.39L466.07 1.39L350.63 201.33Z'></path>
      <path d='M175 1L205 1L205 201L175 201L175 1Z'></path>
      <path d='M1 87L96.34 87L96.34 117L1 117L1 87ZM1 1L31 1L31 201L1 201L1 1ZM1 1L146.41 1L146.41 31L1 31L1 1Z'></path>
    </SvgIcon>
  )
}

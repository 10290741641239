import React from 'react'
import { Grid } from '@mui/material'
import { TextField, Switch, FormRow } from '@fivano/core'
import { SettingsForm } from 'app/legacy/SettingsBlock/SettingsForm'

export const DocumentCategoryForm = props => {
  return (
    <SettingsForm {...props}>
      {({ formObject, showAllFields, isEditing }) => {
        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                formObject={formObject}
                name={`description${props.firestoreCode}`}
                rules={{ required: 'Voer een document categorie in' }}
                readOnly={isEditing}
                variant='outlined'
                label='Document categorie'
              />
            </Grid>
            <FormRow showAllFields={showAllFields}>
              <TextField
                name={`id${props.firestoreCode}`}
                formObject={formObject}
                readOnly={isEditing}
                label='Categorie ID'
              />
              <Switch
                name={`archived${props.firestoreCode}`}
                formObject={formObject}
                label={`Document categorie archiveren`}
              />
            </FormRow>
          </Grid>
        )
      }}
    </SettingsForm>
  )
}

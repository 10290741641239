export const initialUserProfileFormValues = {
  idPers: '',
  personTypePers: '',
  firstNamePers: '',
  middleNamePers: '',
  lastNamePers: '',
  birthNamePers: '',
  givenNamesPers: '',
  initialsPers: '',
  genderPers: '',
  birthdatePers: null,
  countryPers: '',
  addressZipPers: '',
  addressStreetPers: '',
  addressNumberPers: '',
  addressAdditionPers: '',
  addressCityPers: '',
  addressStatePers: '',
  avatarImagePers: false,
  emailPers: '',
  phoneMobilePers: '',
  phoneLinePers: '',
  phoneNoAnswerPers: '',
  textPers: '',
  createdByPers: '',
  keywordsPers: [],
  civilStatus: '',
  numberChildsPers: '',
  socialSecurityNumberPers: '',
  identificationNumberPers: '',
  identificationTypePers: '',
  identificationValidUntilPers: '',
  ibanPers: '',
  bicPers: '',
  bankPers: '',
  bankNamePers: '',
  relationID: '',
  relationPosition: '',
  dossierNumberPers: '',
  locationPers: { value: 'publicLocation', label: 'Openbare locatie' },
}

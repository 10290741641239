import React from 'react'
import TitleIcon from '@mui/icons-material/Title'
import { FormPaper, FormRow, TextField } from '@fivano/core'
import { Grid } from '@mui/material'

export const ExampleFormInputs = ({ formObject }) => {
  return (
    <>
      <Grid container direction='column' spacing={2}>
        <FormPaper>
          <FormRow helperText='' icon={<TitleIcon />}>
            <TextField
              name='name'
              formObject={formObject}
              label='Naam'
              rules={{ required: 'Voer een naam in' }}
            />
          </FormRow>
        </FormPaper>
      </Grid>
    </>
  )
}

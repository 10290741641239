import { useFirestore } from 'react-redux-firebase'
type getPersonTypes = {
  uid: string
  locationID?: string
}
export const usePerson = () => {
  const firestore = useFirestore()
  const getPerson = ({
    uid,
    locationID = 'publicLocation',
  }: getPersonTypes) => {
    firestore.get(`/locations/${locationID}/persons/${uid}`)
  }
  return { getPerson }
}

import { secondsToTimeString } from '../secondsToTime/secondsToTime'

// ==== ROUND OFF TO FULL HOURS, HOURS AND A HALF OR HH:MM ==== //
export const roundOffSecondsToHours = minutes => {
  var h = Math.floor(minutes / 60)
  var m = Math.floor(minutes % 60)
  if (m === 0) {
    return `${h} uur`
  } else if (m === 30) {
    return `${h},5 uur`
  } else {
    return secondsToTimeString(minutes * 60)
  }
}

import React from 'react'
import { useFirestore } from 'react-redux-firebase'
import { Grid } from '@mui/material'
import { TextField } from '@fivano/core'
import { WorkCodeTypes } from 'types/WorkCodes'
import { useDataStandards } from 'hooks/useDataStandards'
import { Form } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'

type WorkCodesFormProps = {
  optionsData: WorkCodeTypes[]
  /** Workcodes data */
  itemData: WorkCodeTypes
  /** Form props */
  formProps: any
  /** Function to close the form */
  onClose: () => void
}

const duplicateNameCheck = (docData: WorkCodeTypes[], field: string) => {
  return docData?.find(
    workCode => workCode.label.toLowerCase() === field.toLowerCase(),
  )
}

export const WorkCodesForm = ({
  optionsData,
  itemData,
  formProps,
  onClose,
}: WorkCodesFormProps) => {
  const { workCodeID } = formProps
  const createDataStandards = useDataStandards()
  const firestore = useFirestore()

  const buildDocData = data => {
    data.id = workCodeID
    data.createdAt = itemData.createdAt
    data.createdBy = itemData.createdBy
    data.untilDate = itemData.untilDate
    data.archived = itemData.archived

    const dataStandards = createDataStandards({
      data,
      dataName: '',
      editForm: true,
    })

    const newData = { ...data, ...dataStandards }
    return newData
  }

  const createDocData = data => {
    return data
  }

  const updateSettings = async data => {
    const optionArray = [...optionsData]
    const replaceIndex = optionArray.findIndex(item => item.id === data.id)

    optionArray[replaceIndex] = data

    await firestore.update(`appSettings/workCodes`, {
      options: optionArray,
    })
  }

  const errorLogger = useErrorLogger()

  return (
    <Form
      docLabel='Werkcode'
      buildDocData={buildDocData}
      createDoc={createDocData}
      updateDoc={updateSettings}
      editingDocID={workCodeID}
      docData={itemData}
      onCloseForm={onClose}
      wrapInside='modal'
      modalMaxWidth='sm'
      hasDetails={false}
      onError={errorLogger}
      formInputs={({ formObject }) => {
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <TextField
                required
                label='Naam'
                name='label'
                formObject={formObject}
                rules={{
                  required: 'Verplicht veld',
                  validate: {
                    validateDuplicate: value =>
                      duplicateNameCheck(optionsData, value)
                        ? 'Naam is al in gebruik'
                        : true,
                  },
                }}
              />
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

import { useSnackbar } from '@fivano/core'
import { useFirestore } from 'react-redux-firebase'
import { useErrorLogger } from './useErrorLogger'

type ArchivedDocTypes = {
  /** Type doc for snackbar message */
  docType: string
  /** Path to doc in firebase */
  path: string
  /** Set doc to archived or unarchive doc */
  archived: boolean
  /** Doc extension */
  extension: string
  /** Show snackbar when doc is updated */
  showSnackbar?: boolean
}

/** React hook to archive docs */
export const useArchiveDoc = () => {
  const firestore = useFirestore()
  const { enqueueSnackbar } = useSnackbar()
  const errorLogger = useErrorLogger()
  return ({
    docType,
    path,
    archived,
    extension,
    showSnackbar = true,
  }: ArchivedDocTypes) =>
    new Promise((resolve, reject) => {
      const message = archived
        ? `${docType} verplaatst naar archief`
        : `${docType} verplaatst uit archief`
      firestore
        .update(path, { [`archived${extension}`]: archived })
        .then(response => {
          if (showSnackbar) {
            enqueueSnackbar(message, {
              variant: 'success',
            })
          }
          resolve(response)
        })
        .catch(error => {
          errorLogger({
            error,
            snackbarMessage: `Archiveren mislukt. ${error}`,
          })

          reject(error)
        })
    })
}

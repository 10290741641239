import { useState } from 'react'
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import {
  restrictToHorizontalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import { styled, Typography } from '@mui/material'
import { SortableOverlay } from './SortableOverlay'
import { CategoryForm } from './CategoryForm'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

type SortableContainerProps = {
  onRefetch: any
  layoutDoc: any
  items: any[]
  draggingID: any
  setDraggingID: any
  activeID: any
  onCreate: (item: any) => void
  onDragEnd: (items: any) => void
  children: React.ReactFragment
}

const SortableWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  overflowY: 'hidden',
  marginTop: 20,
}))

const OuterContainer = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'scroll',
  overflowY: 'hidden',
  width: 'calc(100% - 130px)',
  height: 150,
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))

const InnerContainer = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  overflowY: 'hidden',
  flexDirection: 'row',
  paddingLeft: 10,
  paddingTop: 4,
  paddingBottom: 4,
}))

export enum Position {
  Before = -1,
  After = 1,
}

export const SortableContainer = ({
  onRefetch,
  layoutDoc,
  items,
  draggingID,
  activeID,
  setDraggingID,
  onDragEnd,
  onCreate,
  children,
}: SortableContainerProps) => {
  const activeIndex = draggingID
    ? items.findIndex(item => item?.id === draggingID)
    : -1
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 250, tolerance: 5 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = ({ over }: any) => {
    if (over) {
      const overIndex = items.findIndex(item => item.id === over.id)

      if (activeIndex !== overIndex) {
        const newIndex = overIndex

        const newPositions = arrayMove(items, activeIndex, newIndex)

        onDragEnd(newPositions)
      }
    }

    setDraggingID(null)
  }

  const handleScroll = () => {
    setTimeout(() => {
      const elementInner: any = document.querySelector('.innerContainer')
      const elementOuter: any = document.querySelector('.outerContainer')

      elementOuter.scrollTo({
        left: elementInner.getBoundingClientRect().width,
        top: 0,
        behavior: 'smooth',
      })
    }, 200)
  }

  const [modal, setModal] = useState(false)

  return (
    <>
      {modal && (
        <CategoryForm
          layoutDoc={layoutDoc}
          onClose={() => setModal(false)}
          onSubmitSucces={(_, layoutID) => {
            onRefetch(layoutID)
            handleScroll()
            setModal(false)
          }}
        />
      )}
      <DndContext
        onDragStart={({ active }) => {
          setDraggingID(active.id)
        }}
        onDragEnd={handleDragEnd}
        onDragCancel={() => setDraggingID(null)}
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToHorizontalAxis, restrictToWindowEdges]}
      >
        <SortableWrapper>
          <OuterContainer className='outerContainer'>
            <InnerContainer className='innerContainer'>
              <SortableContext
                strategy={horizontalListSortingStrategy}
                items={items}
              >
                {children}
              </SortableContext>
              <DragOverlay>
                {draggingID ? (
                  <SortableOverlay
                    items={items}
                    activeID={activeID}
                    draggingID={draggingID}
                    activeIndex={activeIndex}
                  />
                ) : null}
              </DragOverlay>
            </InnerContainer>
          </OuterContainer>
          <div
            style={{ marginTop: 4, marginLeft: 10, width: 130, height: 130 }}
          >
            <EmptyCard
              onClick={async () => {
                setModal(true)
              }}
            />
          </div>
        </SortableWrapper>
      </DndContext>
    </>
  )
}

const EmptyDroppableCard = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 700,
  padding: 20,
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  borderRadius: 4,
  color: 'rgba(0,0,0,0.4)',
}))

export const EmptyCard = ({ onClick }) => {
  return (
    <EmptyDroppableCard onClick={onClick}>
      <AddCircleOutlineIcon />
      <Typography variant='h6'>Toevoegen</Typography>
    </EmptyDroppableCard>
  )
}

import React from 'react'
import { Box, Paper, Typography } from '@mui/material'

type EmptyContentProps = {
  /**Visible text*/
  text?: string
  /**Children of the component */
  children?: any
}

export const EmptyContent = ({ text, children }: EmptyContentProps) => {
  return (
    <Paper>
      <Box p={2}>
        {children || <Typography variant='body1'>{text}</Typography>}
      </Box>
    </Paper>
  )
}

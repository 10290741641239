import React, { ReactElement } from 'react'
import {
  Avatar,
  Typography,
  Grid,
  DialogActions,
  Button,
  styled,
  SvgIconProps,
} from '@mui/material'

import { Location } from '../../../../types/Location'
import { IconButton } from '@fivano/core'
import { ListItemBase } from '../../ListItems/ListItemBase'

import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CloseIcon from '@mui/icons-material/Close'
import MailIcon from '@mui/icons-material/Mail'
import BedrijfLocatie from '../../../icons/BedrijfLocatie'

const AvatarStyled = styled(Avatar)(() => ({
  backgroundColor: '#e0e0e0',
  width: 128,
  height: 128,
  margin: 'auto',
}))

const BedrijfLocatieStyled = styled(BedrijfLocatie)(() => ({
  fontSize: 48,
}))

const DivStyled = styled('div')(() => ({
  padding: '8px 0px 8px 24px',
}))

const DialogActionsStyled = styled(DialogActions)(() => ({
  padding: '8px 16px',
}))

const DialogHeader = styled(Grid)(() => ({
  padding: '16px 16px 16px 16px',
}))

const StyledGridItem = styled(Grid)(() => ({
  position: 'absolute',
  top: 8,
  right: 8,
}))

type LocationDetailModalProps = {
  /**Data of a specific location*/
  locationData: Location
  /**Function that gets triggered when the modal is closed*/
  onClose?: () => void
  /**Boolean if the modal has a specific closing button or not*/
  closeButton?: boolean
  /**Function that gets triggered when you finalize the editing*/
  onEdit?: any
}

type IconButtonProps = {
  label: string
  icon?: SvgIconProps
  size?: 'small' | 'medium' | 'large' | undefined
  onClick: (e: any) => void
}

type ListItemProps = {
  title: string
  body?: string | ReactElement<any>
  iconButton?: IconButtonProps
}

export const LocationDetailModal = ({
  locationData,
  onClose,
  onEdit,
}: LocationDetailModalProps) => {
  const fields: ListItemProps[] = [
    {
      title: 'Naam geadresseerde',
      body: locationData.nameAddresseeLoca,
    },
    {
      title: 'Telefoon',
      body: locationData.phoneLoca,
      iconButton: {
        label: 'Telefoon bellen',
        icon: <PhoneEnabledIcon />,
        size: 'large',
        onClick: e => {
          e.stopPropagation()
          window.open(`tel:${locationData?.phoneLoca}`, '_blank')
        },
      },
    },
    {
      title: 'E-mail',
      body: locationData.emailLoca,
      iconButton: {
        label: 'Telefoon bellen',
        icon: <MailIcon />,
        size: 'large',
        onClick: e => {
          e.stopPropagation()
          window.open(`tel:${locationData?.phoneLoca}`, '_blank')
        },
      },
    },
    {
      title: 'Locatie',
      body: locationData?.addressStreetLoca ? (
        <>
          {`${locationData?.addressStreetLoca} ${locationData?.addressNumberLoca} ${locationData?.addressAdditionLoca}`}
          <br />
          {`${locationData?.addressZipLoca} ${locationData?.addressCityLoca}`}
        </>
      ) : (
        ''
      ),
      iconButton: {
        label: 'Locatie openen',
        icon: <LocationOnIcon />,
        size: 'large',
        onClick: e => {
          e.stopPropagation()
          window.open(
            `http://maps.google.com/?q=${locationData?.addressStreetLoca} ${locationData?.addressNumberLoca} ${locationData?.addressAdditionLoca} ${locationData?.addressZipLoca} ${locationData?.addressCityLoca}`,
            '_blank',
          )
        },
      },
    },
    {
      title: 'Beschrijving',
      body: locationData?.descriptionLoca,
    },
  ]

  return (
    <>
      <DialogHeader>
        <AvatarStyled>
          <BedrijfLocatieStyled />
        </AvatarStyled>
        <Typography variant='h5' align='center'>
          {locationData?.nameLoca}
        </Typography>
        <StyledGridItem item>
          <IconButton label='Sluiten' onClick={onClose} size='large'>
            <CloseIcon />
          </IconButton>
        </StyledGridItem>
      </DialogHeader>
      {fields.map(field => {
        const iconButton = field.iconButton
        if (field.body) {
          return (
            <ListItemBase
              key={field.title}
              {...(iconButton && {
                listItemActions: (
                  <IconButton
                    label={iconButton.label}
                    onClick={iconButton.onClick}
                    size={iconButton.size}
                  >
                    {iconButton.icon}
                  </IconButton>
                ),
              })}
            >
              <DivStyled>
                <Typography
                  variant='caption'
                  color='textSecondary'
                  display='block'
                >
                  {field.title}
                </Typography>
                <Typography variant='body1'>{field.body}</Typography>
              </DivStyled>
            </ListItemBase>
          )
        } else return null
      })}
      <DialogActionsStyled>
        <Button onClick={onClose} color='primary' disableElevation>
          Annuleren
        </Button>
        <Button
          onClick={() => onEdit(locationData.id)}
          color='primary'
          disableElevation
        >
          Bewerken
        </Button>
      </DialogActionsStyled>
    </>
  )
}

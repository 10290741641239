import React from 'react'
import { Grid } from '@mui/material'

import { TextField, AutoSelect, Form, FormRow } from '@fivano/core'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { optionsCreator } from 'utils/optionsCreator'

import DomainIcon from '@mui/icons-material/Domain'
import PlaceIcon from '@mui/icons-material/Place'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { useDataStandards } from 'hooks/useDataStandards'
import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'
import { useErrorLogger } from 'hooks/useErrorLogger'
import BedrijfLocatie from 'app/icons/BedrijfLocatie'
import {
  Relation,
  RelationBuildTypes,
  RelationDatastandardTypes,
} from 'types/Relation'

const mapper = {
  addressAdditionRela: 'addressAdditionLoca',
  addressCityRela: 'addressCityLoca',
  addressNumberRela: 'addressNumberLoca',
  addressStreetRela: 'addressStreetLoca',
  addressZipRela: 'addressZipLoca',
  archivedRela: 'archivedLoca',
  countryRela: 'countryLoca',
  createdAtRela: 'createdAtLoca',
  createdByRela: 'createdByLoca',
  keywordsRela: 'keywordsLoca',
  fromDateRela: 'fromDateLoca',
  mailEmailRela: 'emailLoca',
  nameRela: 'nameLoca',
  untilDateRela: 'untilDateLoca',
  phoneRela: 'phoneLoca',
  idRela: 'relationLoca',
  textRela: 'descriptionLoca',
}

type RelationFormProps = {
  /** ID of relation */
  relationID?: string
  /** Function triggered when closing the form */
  onCloseForm: () => void
}

export const RelationForm = ({
  onCloseForm,
  relationID,
}: RelationFormProps) => {
  const firestore = useFirestore()
  const errorLogger = useErrorLogger()
  const createDataStandards = useDataStandards()

  useFirestoreConnect([
    {
      collection: 'appSettings',
      doc: 'kindOfRelation',
      storeAs: `appSettings_kindOfRelation`,
    },
    {
      collection: 'locations',
      where: [
        ['relationLoca', '==', relationID ? relationID : ''],
        ['relaLocationTypeLoca', '==', 'visitLoca'],
      ],
      storeAs: 'locations_visitLoca',
    },
  ])

  const kindOfRelations = useSelector(
    ({ firestore: { ordered } }) => ordered[`appSettings_kindOfRelation`],
  )

  const docData: Relation = useSelector(({ firestore: { data } }) =>
    relationID && data[`relations_${relationID}`]
      ? data[`relations_${relationID}`]
      : // @ts-ignore
        data?.relations?.[relationID],
  )

  const visitLocation = useSelector(
    ({ firestore: { ordered } }) => ordered[`locations_visitLoca`]?.[0],
  )

  const buildRelationData = (data: RelationBuildTypes) => {
    const dataStandards: RelationDatastandardTypes = createDataStandards({
      data,
      dataName: 'Rela',
      editForm: !!relationID,
      keywords: [data.nameRela],
      archived: false,
    })
    return { ...data, ...dataStandards }
  }

  const createRelationData = async data => {
    data.idRela = firestoreIDGenerator()

    return await firestore
      .collection('relations')
      .doc(data.idRela)
      .set(data)
      .then(async () => {
        const locationObject = await createLocationData(data, mapper)
        locationObject.relaLocationTypeLoca = 'visitLoca'
        await firestore.collection('locations').add(locationObject)
      })
  }

  /** Create location object based on the relation data.
   * With 'newFields' empty fields can be added that are not existing in the relation data
   */
  const createLocationData = (data, mapper) => {
    const locationObject = Object.assign(
      {},
      ...Object.entries(data).map(([key, value]) => {
        if (mapper[key] !== undefined) return { [mapper[key]]: value }
        else return false
      }),
    )

    return locationObject
  }

  const updateRelationData = async data => {
    data.idRela = relationID

    return await firestore
      .update(`relations/${relationID}`, data)
      .then(async () => {
        if (visitLocation?.id) {
          const locationObject = await createLocationData(data, mapper)
          locationObject.relaLocationTypeLoca = 'visitLoca'
          await firestore.update(
            `locations/${visitLocation.id}`,
            locationObject,
          )
        }
      })
  }

  if (!docData && !!relationID) {
    return <div>Loading...</div>
  }
  return (
    <Form
      docLabel='Relatie'
      docData={
        !!relationID
          ? docData
          : {
              countryRela: { value: 'NL', label: 'Nederland' },
              languageRela: { value: 'NL', label: 'Nederland' },
              valutaRela: { value: 'EUR', label: 'Euro' },
            }
      }
      buildDocData={buildRelationData}
      createDoc={createRelationData}
      updateDoc={updateRelationData}
      wrapInside='modal'
      editingDocID={relationID}
      hasDetails={true}
      onCloseForm={onCloseForm}
      onError={error => {
        console.log(error)
        errorLogger(error)
      }}
      modalMaxWidth='sm'
      formInputs={({ formObject, showAllFields }) => (
        <Grid container spacing={1}>
          <FormRow icon={<BedrijfLocatie />}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  required
                  name='nameRela'
                  label='Bedrijfsnaam'
                  formObject={formObject}
                  rules={{ required: 'Dit veld is verplicht' }}
                />
              </Grid>
              <Grid item xs={12}>
                <AutoSelect
                  name='kindOfRelationshipRela'
                  label='Soort bedrijf'
                  formObject={formObject}
                  options={optionsCreator(
                    kindOfRelations,
                    'descriptionKior',
                    'idKior',
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='textRela'
                  label='Omschrijving'
                  formObject={formObject}
                  margin='dense'
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </FormRow>
          <FormRow icon={<DomainIcon />}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  name='kvkRela'
                  label='KvK nummer'
                  type='tel'
                  formObject={formObject}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name='vatNumberRela'
                  label='BTW nummer'
                  formObject={formObject}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='websiteRela'
                  label='Website'
                  formObject={formObject}
                />
              </Grid>
            </Grid>
          </FormRow>
          <FormRow icon={<PlaceIcon />}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  name='addressStreetRela'
                  label='Straatnaam'
                  formObject={formObject}
                  rules={{ required: 'Dit veld is verplicht' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  name='addressNumberRela'
                  label='Nummer'
                  type='number'
                  formObject={formObject}
                  rules={{ required: 'Dit veld is verplicht' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name='addressAdditionRela'
                  label='Toevoeging'
                  formObject={formObject}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  name='addressZipRela'
                  label='Postcode'
                  formObject={formObject}
                  rules={{ required: 'Dit veld is verplicht' }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  required
                  name='addressCityRela'
                  label='Plaatsnaam'
                  formObject={formObject}
                  rules={{ required: 'Dit veld is verplicht' }}
                />
              </Grid>
              {showAllFields && (
                <>
                  <Grid item xs={12} md={6}>
                    <AutoSelect
                      name='countryRela'
                      label='Land'
                      formObject={formObject}
                      options={[{ value: 'NL', label: 'Nederland' }]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name='addressStateRela'
                      label='Provincie'
                      formObject={formObject}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </FormRow>
          <FormRow icon={<PhoneIcon />}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  name='phoneRela'
                  label='Algemeen telefoonnummer'
                  type='tel'
                  formObject={formObject}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name='invoicePhoneRela'
                  label='Factuur telefoonnummer'
                  type='tel'
                  formObject={formObject}
                />
              </Grid>
            </Grid>
          </FormRow>
          <FormRow icon={<EmailIcon />}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  required
                  name='mailEmailRela'
                  label='Algemeen emailadres'
                  type='email'
                  formObject={formObject}
                  rules={{ required: 'Dit veld is verplicht' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='invoiceEmailRela'
                  label='Factuur emailadres'
                  type='email'
                  formObject={formObject}
                />
              </Grid>
            </Grid>
          </FormRow>
          <FormRow icon={<CreditCardIcon />}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  name='banknameRela'
                  label='Bank naam'
                  formObject={formObject}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name='bankBicCodeRela'
                  label='BIC'
                  formObject={formObject}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name='ibanNumberRela'
                  label='IBAN nummer'
                  formObject={formObject}
                />
              </Grid>
            </Grid>
          </FormRow>
          {showAllFields && (
            <FormRow icon={<CreditCardIcon />}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name='debtorNumberRela'
                    label='Debit nummer'
                    formObject={formObject}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name='creditorNumberRela'
                    label='Credit nummer'
                    formObject={formObject}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AutoSelect
                    name='languageRela'
                    label='Taal'
                    formObject={formObject}
                    options={[{ value: 'NL', label: 'Nederlands' }]}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AutoSelect
                    name='valutaRela'
                    label='Valuta'
                    formObject={formObject}
                    options={[{ value: 'EUR', label: 'Euro' }]}
                  />
                </Grid>
              </Grid>
            </FormRow>
          )}
        </Grid>
      )}
    />
  )
}

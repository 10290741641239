import React from 'react'
import { Grid, Button } from '@mui/material'
import { Form } from '@fivano/core'
import { TextField } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { useApiRequest } from 'hooks/useApiRequest'

export const CreatePerson = ({ setAuthPerson }) => {
  const makeRequest = useApiRequest()
  const errorLogger = useErrorLogger()

  const createDoc = async data =>
    makeRequest({
      route: 'functions/createAuthPerson',
      requestBody: data,
    })

  return (
    <Form
      docLabel='Persoonsgegevens'
      modalMaxWidth='sm'
      wrapInside='formElement'
      hasDetails={false}
      createDoc={createDoc}
      onError={errorLogger}
      onCreateSuccess={response => {
        setAuthPerson({ status: 'success', person: response.person })
      }}
      docData={{}}
      updateDoc={async () => {}}
      onCloseForm={() => {}}
      formInputs={({ formObject, onSubmit }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label='Voornaam'
              name='firstName'
              formObject={formObject}
              rules={{
                required: 'Dit is een verplicht veld',
              }}
              size='medium'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Achternaam'
              name='lastName'
              formObject={formObject}
              rules={{
                required: 'Dit is een verplicht veld',
              }}
              size='medium'
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              type='submit'
              size='large'
              variant='contained'
              color='primary'
              onClick={onSubmit}
              disableElevation
            >
              Opslaan
            </Button>
          </Grid>
        </Grid>
      )}
    />
  )
}

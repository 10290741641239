import { PageContainer } from '@fivano/core'
import { Grid, Paper, Typography } from '@mui/material'
import { differenceInYears, parseISO } from 'date-fns'
import useMongo from 'hooks/useMongo'
import { useParams } from 'react-router-dom'
import { RouteTypes } from 'types/RouteTypes'

export const CourseStudentPage = () => {
  const { personID } = useParams<RouteTypes>()
  const { useRefetchDoc } = useMongo()

  const { status, docData } = useRefetchDoc({
    collection: 'persons',
    docID: personID,
    disabled: !personID,
  })
  const age = differenceInYears(new Date(), parseISO(docData?.birthdate))

  if (status === 'loading') return <p>Loading...</p>
  return (
    <PageContainer maxWidth='lg'>
      {docData && (
        <Paper sx={{ padding: 1 }}>
          <Grid container>
            <Grid item>
              <Typography variant='h5'>
                {docData.firstName} {docData.lastName}
              </Typography>

              <Typography variant='subtitle1'>Leeftijd: {age}</Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </PageContainer>
  )
}

import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function MicrosoftIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect x='2' y='2' width='9' height='9' fill='#f25022' />
      <rect x='2' y='12' width='9' height='9' fill='#00a4ef' />
      <rect x='12' y='2' width='9' height='9' fill='#7fba00' />
      <rect x='12' y='12' width='9' height='9' fill='#ffb900' />
    </SvgIcon>
  )
}

import { Avatar as MaterialAvatar, colors, styled } from '@mui/material'
import { format } from 'date-fns'
import { convertTimestampDate } from '@fivano/core'
import { Person } from 'types'

const MaterialAvatarStyled = styled(MaterialAvatar, {
  shouldForwardProp: prop =>
    prop !== 'color' &&
    prop !== 'centerAvatar' &&
    prop !== 'button' &&
    prop !== 'size',
})<any>(({ theme, color, centerAvatar, button, size }) => ({
  ...(centerAvatar && {
    margin: '0 auto',
  }),
  ...(color && {
    color: theme.palette.getContrastText(color),
    backgroundColor: color,
  }),
  ...(button && {
    cursor: 'pointer',
    '& :hover': {
      border: `2px solid ${theme.palette.primary}`,
    },
  }),
  ...(size === 'small' && { width: 30, height: 30, fontSize: 16 }),
  ...(size === 'medium' && { width: 64, height: 64, fontSize: 28 }),
  ...(size === 'large' && { width: 128, height: 128, fontSize: 48 }),
}))

const colorOptions = Object.keys(colors)

const stringToColor = string => {
  let hash = 0
  for (const i in string) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }
  const float = parseFloat(`0.${Math.abs(hash)}`)
  const index = Math.ceil(float * (colorOptions.length - 1))
  return colors[colorOptions[index]][100] || '#303030'
}

type AvatarProps = {
  /** personData object from Person or Profile to destructure Avatar letters from and set Avatar Image URL*/
  personData: Partial<Person>
  size?: 'small' | 'medium' | 'large'
  onClick?: any
  centerAvatar?: boolean
}

export const Avatar = ({
  size,
  personData,
  onClick,
  centerAvatar = false,
}: AvatarProps) => {
  const srcURL = personData?.updatedAtPers
    ? `https://firebasestorage.googleapis.com/v0/b/${
        process.env.REACT_APP_FB_PROJECT_ID
      }.appspot.com/o/locations%2FpublicLocation%2Fpersons%2F${
        personData?.id
      }%2Favatar%2F${personData?.id}?alt=media&date=${
        personData?.updatedAtPers instanceof Date
          ? format(
              convertTimestampDate(personData?.updatedAtPers),
              'ddMMyyyyhhmmss',
            )
          : 'today'
      }`
    : `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FB_PROJECT_ID}.appspot.com/o/locations%2FpublicLocation%2Fpersons%2F${personData?.id}%2Favatar%2F${personData?.id}?alt=media`

  return (
    <MaterialAvatarStyled
      onClick={onClick}
      size={size}
      src={personData?.avatarImagePers ? srcURL : undefined}
      color={stringToColor(
        `${personData?.id || ''}${personData?.firstNamePers || ''}${
          personData?.lastNamePers || ''
        }`,
      )}
      centerAvatar={centerAvatar}
      button={onClick}
    >
      {personData?.firstNamePers?.charAt(0)}
      {personData?.lastNamePers?.charAt(0)}
    </MaterialAvatarStyled>
  )
}

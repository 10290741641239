import { useHistory, useLocation } from 'react-router-dom'
export const useQueryParams = () => {
  const location = useLocation()
  const history = useHistory()

  /** Set URL search params to paramsObject that looks like:
   * { arhivedPers: 'true' , searchField: 'ben' }
   * where value should always be a string */
  const setParams = paramsObject => {
    history.push({ search: new URLSearchParams(paramsObject).toString() })
  }

  /** Get URL search params from current url, return params object like:
   * { arhivedPers: 'true' , searchField: 'ben' }
   * where value is always a string */
  const getParams = () =>
    Object.fromEntries(new URLSearchParams(location.search))

  /** Deletes all params */
  const deleteParams = () => {
    history.push({ search: new URLSearchParams().toString() })
  }
  return { setParams, getParams, deleteParams }
}

import React, { SyntheticEvent, useState } from 'react'
import { useController, UseFormReturn } from 'react-hook-form'
import { Autocomplete, TextField } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { ErrorMessage } from '@hookform/error-message'

type AutoCompleteProps = {
  /** Label of AutoSelect */
  label?: string
  /** Name for React Hook Form */
  name: string
  /** React Hook Form object to connect component to form */
  formObject: UseFormReturn<any>
  /** Set to true if you want to select more than 1 value */
  multiple?: boolean
  /** Array with available options */
  options: Array<any>
  /** The default value for the component */
  defaultValue?: any
  /** Sets the component disabled or not */
  disabled?: boolean
  /** Sets the size of the component */
  size?: 'small' | 'medium' | undefined
  /** Boolean that sets the clear button on the component or not */
  disableClearable?: boolean
  /** Text that shows when a search returns nothing */
  noOptionsText?: string
  /** The max amount of return items */
  limitTags?: number
  /** Boolean that says whether or not the AutoSelect is immediatly focused when the page is rendered */
  autoFocus?: boolean
  /** Placeholder text */
  placeholder?: string
  /** Function that triggers on changes, return an event and value (event, value) => (event, value) */
  onChange?: any
  /** Function that triggers on blur, return an event (event) => (event) */
  onBlur?: (event: SyntheticEvent) => void
  /** Shows loading indicator if true */
  loading?: boolean
  /** Boolean that closes the AutoSelect if true */
  disableCloseOnSelect?: boolean
  /** Variant layout options */
  variant?: 'standard' | 'outlined' | 'filled' | undefined
  /**Name for the styling class */
  className?: string
  /**Sets inline styling for the element */
  style?: any
  /**Rules that the component needs to abide to */
  rules?: any
  /** native autoComplete functionality, use 'street' to tell the browser
   * to use this field as a streed, set to 'off' to disable */
  autoComplete?: string
  /** HelperText to display under AutoSelect */
  helperText?: string
}

export const AutoSelect = ({
  name,
  formObject,
  multiple = false,
  label,
  defaultValue,
  disabled,
  placeholder,
  size = 'small',
  variant = 'outlined',
  disableClearable = false,
  noOptionsText = 'Geen opties of zoekresultaten',
  limitTags = 5,
  autoFocus,
  options,
  onChange,
  onBlur,
  loading = false,
  disableCloseOnSelect = false,
  className,
  style,
  rules,
  autoComplete,
  helperText,
}: AutoCompleteProps) => {
  const [search, setSearch] = useState('')
  if (disabled) {
    console.warn('AutoSelect disabled excludes data from react-hook-form')
  }

  const {
    field: { value, onChange: onChangeRHF, onBlur: onBlurRHF },
  } = useController({
    name,
    control: formObject.control,
    rules,
    defaultValue: defaultValue ? defaultValue : multiple ? [] : null,
  })

  const hasError = Boolean(formObject.formState.errors?.[name])
  return (
    <Autocomplete
      loading={loading}
      value={value || ''}
      style={style}
      onChange={(event, values) => {
        onChangeRHF(values)
        onChange && onChange(event, values)
        if (!multiple) {
          setSearch('')
        }
      }}
      onBlur={event => {
        onBlurRHF()
        onBlur && onBlur(event)
      }}
      onClose={() => {
        setSearch('')
      }}
      options={options}
      getOptionLabel={option => (option?.label ? option.label : '')}
      isOptionEqualToValue={(option, value) =>
        option.value === value?.value || value?.value === undefined
      }
      size={size}
      limitTags={limitTags}
      filterSelectedOptions={multiple}
      multiple={multiple}
      disableClearable={disableClearable}
      noOptionsText={noOptionsText}
      loadingText='Laden...'
      disableCloseOnSelect={disableCloseOnSelect && multiple}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          disabled={disabled}
          name={name}
          label={label}
          autoFocus={autoFocus}
          className={className}
          variant={variant}
          value={search}
          size={size}
          required={!!rules?.required}
          placeholder={placeholder}
          error={hasError}
          helperText={
            formObject.formState.errors?.[name] ? (
              <ErrorMessage name={name} errors={formObject.formState.errors} />
            ) : (
              helperText
            )
          }
          onChange={event => {
            setSearch(event.target.value)
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: autoComplete,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

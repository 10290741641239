// ==== COMPARES ARRAYS OR OBJECTS WITH EACHOTHER, RETURNS BOOLEAN ==== //
export const compareArraysAndObjects = (object1, object2) => {
  let bool = false
  if (object1 instanceof Array && object2 instanceof Array) {
    if (object1.length !== object2.length) return false
    bool = true
  } else if (object1 instanceof Object && object2 instanceof Object) {
    const keysObject1 = Object.keys(object1).sort()
    const keysObject2 = Object.keys(object2).sort()
    if (keysObject1.length !== keysObject2.length) return false
    bool = true
  }
  if (bool) {
    for (var p in object1) {
      if (typeof object1[p] === 'function') {
        if (!(typeof object2[p] === 'function')) return false
        if (object1[p].toString() !== object2[p].toString()) return false
      } else if (object1[p] instanceof Array) {
        if (!(object2[p] instanceof Array)) return false
        if (!compareArraysAndObjects(object1[p], object2[p])) return false
      } else if (object1[p] instanceof Date) {
        if (!(object2[p] instanceof Date)) return false
        if (object1[p].getTime() !== object2[p].getTime()) return false
      } else if (object1[p] instanceof Object) {
        if (!(object2[p] instanceof Object)) return false
        if (!compareArraysAndObjects(object1[p], object2[p])) return false
      } else {
        if (object1[p] !== object2[p]) return false
      }
    }
  }

  return true
}

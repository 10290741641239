import React, { useEffect, useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import { useFirestore, WhereOptions } from 'react-redux-firebase'
import { Autocomplete, styled } from '@mui/material'
import { Button, Paper } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { TextField } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'

const TextFieldStyled = styled(TextField, {
  shouldForwardProp: prop => prop !== 'hideBorder',
})<any>(({ hideBorder }) => ({
  margin: '0px 0px 6px 0px',
  ...(hideBorder && {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
  }),
}))

type LoadMoreButtonProps = {
  showButton?: boolean
  children?: any
  onLoadMore: any
  label: string
}
const LoadMoreButton = ({
  showButton = true,
  onLoadMore,
  label,
  children,
  ...rest
}: LoadMoreButtonProps) => {
  const handleClick = event => {
    event.preventDefault()
    onLoadMore()
  }
  return (
    <Paper {...rest}>
      {children}
      {showButton && (
        <Button fullWidth onMouseDown={handleClick}>
          {label}
        </Button>
      )}
    </Paper>
  )
}

const optionsCreator = (options, labels) => {
  const optionsArray: any = []
  options.docs.forEach(doc => {
    const optionData = doc.data()
    let combineLabel = ''
    labels.forEach((label, index) => {
      if (optionData[label]?.length > 0) {
        combineLabel =
          combineLabel +
          optionData[label] +
          (labels?.length - 1 === index ? '' : ' ')
      }
    })
    optionsArray.push({ value: doc.id, label: combineLabel })
  })
  return optionsArray
}

type Refs<T> = {
  current: T
}

type AutoCompleteProps = {
  name: string

  multiple?: boolean
  label?: string
  required?: boolean
  helperText?: boolean | string
  error?: boolean
  inputRef?: any
  defaultValue?: any
  disabled?: boolean
  size?: 'small' | 'medium' | undefined
  disableClearable?: boolean
  noOptionsText?: string
  collection: string
  labelKeys: Array<string>
  where?: Array<any>
  limitQuery?: number
  searchKey: string
  limitTags?: number
  queryTimeout?: number
  getValuesInitially?: boolean
  autoFocus?: boolean
  placeholder?: string
  hideBorder?: boolean
  options?: Array<any>
  filterValues?: Array<any>
  afterChange?: any
  multipleLimit?: number
  formObject?: any
  rules?: any
  collectionGroup?: string
  orderBy?: any
}

export const AutoComplete = ({
  name,
  multiple = false,
  multipleLimit,
  label,
  required,
  error,
  helperText,
  defaultValue,
  disabled,
  size = 'small',
  disableClearable = false,
  noOptionsText = 'Geen opties of zoekresultaten',
  collection,
  collectionGroup,
  orderBy,
  labelKeys,
  where,
  limitQuery = 7,
  limitTags = 5,
  searchKey,
  queryTimeout = 400,
  getValuesInitially = true,
  autoFocus,
  options,
  placeholder,
  hideBorder,
  filterValues,
  afterChange,
  formObject,
  rules,
}: AutoCompleteProps) => {
  const errorLogger = useErrorLogger()
  const firestore = useFirestore()
  const [loadOnRender, setLoadOnRender] = useState(getValuesInitially)
  const [valuesMultiple, setValuesMultiple] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [search, setSearch] = useState('')
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([])
  const [queryLimit, setQueryLimit] = useState(limitQuery)

  // remember previous where array to compare it later
  const prevWhereRef: Refs<any | undefined> = useRef()
  useEffect(() => {
    prevWhereRef.current = where
  })
  const prevWhere = prevWhereRef.current

  // remember previous search array to compare it later
  const prevSearchRef: Refs<string | undefined> = useRef()
  useEffect(() => {
    prevSearchRef.current = search
  })
  const prevSearch = prevSearchRef.current

  // remember previous search array to compare it later
  const prevLimitRef: Refs<number | undefined> = useRef()
  useEffect(() => {
    prevLimitRef.current = queryLimit
  })
  const prevLimit = prevLimitRef.current

  // HANDLE NEW SEARCH, WHERE OR QUERYLIMIT
  useEffect(() => {
    let whereChanged = false
    if (
      prevWhere &&
      where &&
      prevWhere.length > 0 &&
      where.length > 0 &&
      (options === undefined || options.length === 0)
    ) {
      if (prevWhere.length !== where.length) {
        // check if when of prevWhere is different than where
        whereChanged = true
      } else {
        prevWhere.forEach((item, index) => {
          // check if keys are diffent
          if (item !== null && where[index] !== null) {
            if (item[0] !== where[index][0]) {
              whereChanged = true
            } else if (item[2] !== where[index][2]) {
              whereChanged = true
            } else {
              return
            }
          }
        })
      }
    }

    let searchChanged = false
    if (search !== prevSearch) {
      searchChanged = true
    }

    let queryLimitChange = false
    if (queryLimit !== prevLimit) {
      queryLimitChange = true
    }
    if (
      (loadOnRender || whereChanged || searchChanged || queryLimitChange) &&
      !disabled
    ) {
      // handle loading before timeout
      if (multiple) {
        setLoadingMore(true)
      } else {
        setLoading(true)
      }

      // set limit before timeout
      let loadLimit = queryLimit
      if (valuesMultiple.length > 0) {
        loadLimit = queryLimit + valuesMultiple.length
      }
      // copy where arrays if not undefined to use in firestore
      const whereCopy: WhereOptions[] = []
      if (where) {
        where.forEach(w => {
          if (w !== null) {
            whereCopy.push(w)
          }
        })
      }

      const searchHandler = setTimeout(() => {
        // only get values when not disabled ....
        if (!disabled) {
          if (search === '') {
            whereCopy.push([searchKey, 'array-contains', ''])
            firestore
              .get({
                ...(collectionGroup ? { collectionGroup } : { collection }),
                where: whereCopy,
                limit: loadLimit,
                ...(orderBy && { orderBy: orderBy }),
              })
              .then(response => {
                setAutoCompleteOptions(optionsCreator(response, labelKeys))
                setLoading(false)
                setLoadingMore(false)
                setLoadOnRender(false)
              })
              .catch(error => {
                setLoading(false)
                setLoadingMore(false)
                setLoadOnRender(false)
                errorLogger({ error })
              })
          } else {
            const searchLowercase = search.toLowerCase()
            // add search to where, no where check needed

            whereCopy.push([searchKey, 'array-contains', searchLowercase])
            firestore
              .get({
                ...(collectionGroup ? { collectionGroup } : { collection }),
                where: whereCopy,
                limit: loadLimit,
                ...(orderBy && { orderBy: orderBy }),
              })
              .then(response => {
                setAutoCompleteOptions(optionsCreator(response, labelKeys))
                setLoadOnRender(false)
                setLoadingMore(false)
                if (!multiple) {
                  setLoading(false)
                }
              })
              .catch(error => {
                setLoadOnRender(false)
                setLoadingMore(false)
                if (!multiple) {
                  setLoading(false)
                }
                errorLogger({ error })
              })
          }
        }
      }, queryTimeout)
      return () => {
        clearTimeout(searchHandler)
      }
    } else {
      setLoading(false)
      setLoadingMore(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, valuesMultiple, queryLimit, disabled, where, loadOnRender])

  const filteredOptions = filterValues
    ? autoCompleteOptions.filter(
        (option: { label: string; value: string }) =>
          !filterValues.includes(option.value),
      )
    : autoCompleteOptions

  const {
    field: { value, onChange, onBlur },
  } = useController({
    name,
    control: formObject.control,
    rules,
    defaultValue: defaultValue ? defaultValue : multiple ? [] : null,
  })

  return (
    <Autocomplete
      loading={loading || loadingMore}
      value={value}
      onChange={(event, values) => {
        onChange(values)
        if (multiple) {
          setValuesMultiple(values)
        } else {
          setSearch('')
        }
        if (afterChange) {
          afterChange()
        }
      }}
      onClose={() => {
        setSearch('')
        setQueryLimit(limitQuery)
      }}
      options={loading ? [] : filteredOptions}
      getOptionLabel={option => (option?.label ? option.label : '')}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      PaperComponent={loadMoreProps => {
        return (
          <LoadMoreButton
            showButton={
              filteredOptions.length >= queryLimit + valuesMultiple?.length
                ? true
                : false
            }
            label='Meer laden'
            onLoadMore={() => {
              setQueryLimit(queryLimit + 3)
            }}
            {...loadMoreProps}
          />
        )
      }}
      renderInput={params => (
        <TextFieldStyled
          name={name}
          formObject={formObject}
          {...params}
          disabled={false} // AutoSelect Textfield should never be disabled so react-hook-form can retain the value
          label={label}
          placeholder={placeholder}
          autoFocus={autoFocus}
          variant='outlined'
          value={search}
          size={size}
          required={required}
          fullWidth
          rules={rules}
          hideBorder={hideBorder}
          onChange={event => {
            setSearch(event.target.value)
          }}
          InputProps={{
            ...params.InputProps,
            readOnly: disabled,
            endAdornment: (
              <>
                {loading || loadingMore ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      limitTags={limitTags}
      filterSelectedOptions={multiple}
      multiple={multiple}
      onBlur={onBlur}
      disableClearable={disableClearable}
      noOptionsText={noOptionsText}
      loadingText='Laden...'
      disableCloseOnSelect={multiple}
    />
  )
}

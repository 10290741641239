// index.tsx this is the entry file for the application, only setup and boilerplate
// ===== import packages ===== //
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import * as serviceWorker from 'serviceWorker'
import { HelmetProvider } from 'react-helmet-async'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// ===== import project code configuration ===== //
import { App } from 'app'
import './locales/i18n'
import { configureAppStore } from 'store/configureStore'
import { ThemeProvider } from 'styles/theme/ThemeProvider'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider } from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import nl from 'date-fns/locale/nl'
import { isEmulating } from 'utils/emulator'

// ===== firebase authentication and store ===== //
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'

// ===== react-redux-firebase config ===== //
const reactReduxFirebaseConfig = {
  userProfile: 'users', // path to firestore collection to store user documents
  useFirestoreForProfile: true, // enabled to use firestore to save user documents
  useFirestoreForStorageMeta: true, // enabled to use firestore to save upload meta documents
  enableClaims: true, // enabled to use firebase authentication claims for role access
}

// ===== initialize firebase services ===== //
const REGION = process.env.REACT_APP_FB_REGION
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  appId: process.env.REACT_APP_FB_APP_ID,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  authDomain: `${process.env.REACT_APP_FB_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FB_PROJECT_ID}.firebaseio.com`,
  storageBucket: `${process.env.REACT_APP_FB_PROJECT_ID}.appspot.com`,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
}

if (process.env.REACT_APP_SENTRY === 'on') {
  Sentry.init({
    dsn: 'https://980413d80dc14b79842c995a0eff4a71@o526158.ingest.sentry.io/5641343',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
    environment: firebaseConfig.projectId,
  })
}

firebase.initializeApp(firebaseConfig)
firebase.firestore()

const auth = firebase.auth()
const firestore = firebase.firestore()
const storage = firebase.storage()

export const functions = firebase.app().functions(REGION)

if (isEmulating()) {
  console.info(
    `==== INITIALIZED FIREBASE EMULATOR: ${firebaseConfig.projectId} ====`,
  )
  firebase.firestore().settings({ experimentalForceLongPolling: true })
  // @ts-ignore
  auth.useEmulator('http://localhost:9099/', { disableWarnings: true })
  firestore.useEmulator('localhost', 8080)
  functions.useEmulator('localhost', 5001)
  storage.useEmulator('localhost', 9199)
} else {
  console.info(
    `==== INITIALIZED FIREBASE PROJECT: ${firebaseConfig.projectId} ====`,
  )
}

const store = configureAppStore()
const MOUNT_NODE = document.getElementById('root') as HTMLElement

interface Props {
  Component: typeof App
}
const reactReduxFirebaseProps = {
  firebase,
  config: reactReduxFirebaseConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

const ConnectedApp = ({ Component }: Props) => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={nl}>
              <HelmetProvider>
                <React.StrictMode>
                  <Component />
                </React.StrictMode>
              </HelmetProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}

const render = (Component: typeof App) => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE)
}
// @ts-ignore
if (module.hot) {
  // Hot reloadable translation json files and app modules.hot.accept does not accept dynamic dependencies, have to be constants at compile-time
  // @ts-ignore
  module.hot.accept(['./app', './locales/i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    const App = require('./app').App
    render(App)
  })
}
render(App)

// If you want your app to work offline and load faster, you can change unregister() to register() below. Note this comes with some pitfalls. Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import { isValid } from 'date-fns'

export const convertToDate = date => {
  if (isValid(new Date(date))) {
    return new Date(date)
  } else if (isValid(date)) {
    if (typeof date !== 'string') {
      return date
    } else {
      return null
    }
  } else if (typeof date === 'object') {
    if (date.seconds) {
      return new Date(date.seconds * 1000)
    } else {
      return date.toDate()
    }
  } else {
    return null
  }
}

import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'hooks/useSelector'
import { useParams } from 'react-router-dom'
import {
  useFirestore,
  useFirebase,
  useFirestoreConnect,
} from 'react-redux-firebase'

import {
  Grid,
  Typography,
  Box,
  Paper,
  Chip,
  Button,
  capitalize,
} from '@mui/material'
import { DocumentRequiredCard } from './DocumentRequiredCard'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { DeleteOutline } from '@mui/icons-material'
import {
  Dialog,
  FloatingButton,
  bytesConvert,
  PageContainer,
  IconButton,
  ListView,
  useFilesDelete,
} from '@fivano/core'
import { format } from 'date-fns'
import { RouteTypes } from 'types/RouteTypes'
import { hasClaimLevel } from 'utils/hasClaimLevel'
import { useSettings } from 'hooks/useSettings'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { personFullName } from 'model/person'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { SearchFilter } from 'app/components/SearchFilter'
import { useFilters } from 'hooks/useFilters'
import { ParticipantDocumentForm } from './ParticipantDocumentForm'
import { convertToDate } from 'utils/convertToDate'

export const ParticipantDocuments = () => {
  const [participantName, setParticipantName] = useState('Laden')
  const [personDocumentLimit, setPersonDocumentLimit] = useState(50)
  const [filters, setFilters] = useFilters({
    searchField: {
      value: '',
      label: 'Zoek op:',
      param: true,
    },
  })
  const { locationID, participantID } = useParams<RouteTypes>()
  const firestore = useFirestore()
  const errorLogger = useErrorLogger()
  const firebase = useFirebase()
  const profile = useSelector(state => state.firebase.profile)

  const settings = useSettings()
  const labels = settings.tracks.labels

  const breadcrumbArray = [
    { title: 'Home', path: '/' },
    { title: 'Deelnemers', path: '/deelnemers' },
    {
      title: participantName,
      path: `/locatie/${locationID}/deelnemers/${participantID}`,
    },
    {
      title: 'Documenten',
      path: `/locatie/${locationID}/deelnemers/${participantID}/documenten`,
    },
  ]

  // ==== PARTICIPANT LOADING AND LOGIC ==== //
  const participantDetails = useSelector(
    ({ firestore: { ordered } }) => ordered[`persons_${participantID}`]?.[0],
  )
  if (participantDetails === undefined) {
    firestore.get({
      collection: `locations/${locationID}/persons`,
      doc: participantID,
      storeAs: `persons_${participantID}`,
    })
  }
  useEffect(() => {
    participantDetails && setParticipantName(personFullName(participantDetails))
  }, [participantDetails])

  // ==== DOCUMENT AND DOCUMENT TYPES LISTENERS ==== //
  const locationQuery = useLocationQuery()

  useFirestoreConnect([
    {
      collectionGroup: 'documents',
      where: [
        ['personIDDocu', '==', participantID],
        [
          'keywordsDocu',
          'array-contains',
          filters?.searchField?.value?.toLowerCase(),
        ],
        ...locationQuery('locationIDDocu'),
      ],
      orderBy: ['createdAtDocu', 'desc'],
      limit: personDocumentLimit,
      storeAs: `persons_${participantID}_documents`,
    },
    {
      collection: 'appSettings',
      doc: 'documentRequiredTypes',
      storeAs: 'documentRequiredTypes',
    },
  ])

  const personDocuments = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[`persons_${participantID}_documents`],
  )

  const documentRequiredTypesSelect = useSelector(
    ({ firestore: { ordered } }) => ordered.documentRequiredTypes?.[0]?.options,
  )
  const documentRequiredTypes = documentRequiredTypesSelect?.filter(
    item => item.archivedDoty === false,
  )

  // ==== DELETE FILE AND DOCUMENT ==== //
  const deleteFiles = useFilesDelete({ onError: errorLogger })

  const [deleteModal, setDeleteModal] = useState<any>({
    id: '',
    data: {},
    message: '',
  })

  const handleDeleteModal = data => {
    setDeleteModal({
      id: data.id,
      data: data,
      message: `${data.nameDocu} verwijderen`,
    })
  }

  const handleDeleteFirestore = data => {
    const fileData = {
      name: data.nameDocu,
      fullPath: data.fullPathDocu,
      fileID: data.id,
      filesStorePath:
        data?.fileMetaDataDocu?.filesStorePath ||
        data?.fileMetaData?.filesStorePath ||
        `locations/${data.locationIDDocu}/persons/${data.personIDDocu}/documents`,
    }

    deleteFiles({
      filesToDelete: [fileData],
      deleteFirestoreDoc: true,
    })
  }

  // ==== OPEN OR DOWNLOAD FILE WHEN DOC CLICKED ==== //
  const handleFileClick = (event, row) => {
    event.preventDefault()
    firebase
      .storage()
      .ref(row.original.fullPathDocu)
      .getDownloadURL()
      .then(response => {
        window.open(response, '_blank')
      })
      .catch(error => {
        errorLogger({ error })
      })
  }

  const handleLoadMore = () => {
    setPersonDocumentLimit(personDocumentLimit + 50)
  }

  const handleFilter = data => {
    const newFilters = { ...filters }
    newFilters.searchField.value = data.searchField

    setFilters(newFilters)
  }

  // ==== DOCUMENT LISTVIEW DEFINITIONS ==== //
  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          accessor: 'originalName',
          gridProps: {
            xs: 12,
            sm: 8,
            md: 6,
          },
          Cell: ({
            row: {
              original: { nameDocu, categoryDocu },
            },
          }) => (
            <>
              <Grid container wrap='nowrap' spacing={1} alignItems='center'>
                <Grid item>
                  <Typography variant='body1' display='inline'>
                    {nameDocu}
                  </Typography>
                </Grid>
                <Grid item>
                  {categoryDocu !== 'document' && (
                    <Chip
                      size='small'
                      label={
                        categoryDocu === 'progress'
                          ? 'Voortgang'
                          : categoryDocu === 'sickReport'
                          ? 'Ziekte en verzuim'
                          : categoryDocu === 'documentRequired'
                          ? 'Verplicht document'
                          : categoryDocu === 'trackAssign'
                          ? `${capitalize(labels.track)} document`
                          : 'Overig document'
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </>
          ),
        },
        {
          Header: 'Toevoeg datum',
          accessor: 'createdAtDocu',
          hidden: { only: ['xs'] },
          gridProps: {
            sm: 2,
            md: 2,
          },
          Cell: data => (
            <>
              <Grid container wrap='nowrap' spacing={2} alignItems='center'>
                <Grid item>
                  <Typography variant='body2'>
                    {data.row.original?.createdAtDocu &&
                      format(
                        convertToDate(data.row.original.createdAtDocu),
                        'dd-MM-yyyy',
                      )}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ),
        },
        {
          Header: 'Uploader',
          accessor: 'createdByNameDocu',
          hidden: { only: ['xs'] },
          gridProps: {
            sm: 2,
            md: 2,
          },
          Cell: data => (
            <>
              <Grid container wrap='nowrap' spacing={2} alignItems='center'>
                <Grid item>
                  <Typography variant='body2'>{`${data.row.original.createdByNameDocu}`}</Typography>
                </Grid>
              </Grid>
            </>
          ),
        },
        {
          Header: 'Grootte',
          accessor: 'size',
          hidden: { only: ['xs', 'sm'] },
          gridProps: {
            md: 1,
          },
          Cell: data => (
            <>
              <Grid container wrap='nowrap' spacing={2} alignItems='center'>
                <Grid item>
                  <Typography variant='body2'>
                    {bytesConvert(
                      data.row.original.fileMetadata?.size ||
                        data.row.original.fileMetadataDocu?.size ||
                        0,
                      2,
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ),
        },
        {
          id: 'actions',
          Header: ' ',
          Cell: data => (
            <Grid item>
              {data.row.original?.categoryDocu === 'document' &&
                hasClaimLevel(profile, { d: 5 }) && (
                  <IconButton
                    label='Verwijderen'
                    onClick={() => handleDeleteModal(data.row.original)}
                    size='large'
                  >
                    <DeleteOutline />
                  </IconButton>
                )}
            </Grid>
          ),
        },
      ],
    },
  ]
  const [documentFormProps, setDocumentFormProps] = useState({
    open: false,
  })

  return (
    <>
      {documentFormProps.open && (
        <ParticipantDocumentForm
          participantID={participantID}
          onCloseForm={() => {
            setDocumentFormProps({ open: false })
          }}
        />
      )}
      <Helmet>
        <title>Documenten {`${participantName}`}</title>
      </Helmet>
      <PageContainer maxWidth='lg'>
        <Dialog
          id={deleteModal.id}
          data={deleteModal.data}
          deleteTitle={deleteModal.message}
          open={deleteModal.id !== ''}
          onClose={() => setDeleteModal({ id: '', data: {}, message: '' })}
          onCancel={() => setDeleteModal({ id: '', data: {}, message: '' })}
          onDelete={handleDeleteFirestore}
        />
        <Breadcrumbs breadcrumbs={breadcrumbArray} key={participantName} />
        <Grid container spacing={1} style={{ marginBottom: 16 }}>
          {documentRequiredTypes?.length > 0 ? (
            documentRequiredTypes.map((item, index) => {
              return (
                <Grid item xs={6} sm={4} lg={3} key={index}>
                  <DocumentRequiredCard documentRequiredType={item} />
                </Grid>
              )
            })
          ) : (
            <Paper style={{ width: '100%' }}>
              <Box p={2}>
                <Typography variant='body1'>
                  Geen verplichte documenten gevonden, maak een verplicht
                  document aan in de applicatie instellingen.
                </Typography>
              </Box>
            </Paper>
          )}
        </Grid>
        <Paper>
          <Box p={2} pb={0}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <SearchFilter
                  placeholder='Zoeken in documenten'
                  filterValues={filters}
                  onSubmit={handleFilter}
                />
              </Grid>
            </Grid>
          </Box>
          <FloatingButton
            label='Document toevoegen'
            onClick={() => setDocumentFormProps({ open: true })}
          />
          {personDocuments?.length > 0 ? (
            <>
              <ListView
                data={personDocuments}
                columns={listColumns}
                onClick={handleFileClick}
              />
              {personDocuments.length === personDocumentLimit && (
                <Button fullWidth onClick={handleLoadMore}>
                  Meer documenten laden
                </Button>
              )}
            </>
          ) : (
            <Box p={2}>
              <Typography variant='body1'>Geen documenten gevonden</Typography>
            </Box>
          )}
        </Paper>
      </PageContainer>
    </>
  )
}

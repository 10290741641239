import { useFirestore } from 'react-redux-firebase'
import { useFilesDelete, useSnackbar } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { useHistory } from 'react-router-dom'

export const useMusicTrackDelete = () => {
  const firestore = useFirestore()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const errorLogger = useErrorLogger()
  const deleteFiles = useFilesDelete({ onError: errorLogger })

  const deleteMusicTrack = async (musicTrackID: string) => {
    const trackData: any = await firestore
      .collection('musicTracks')
      .doc(musicTrackID)
      .get()
      .then((response: any) => response.data())

    await deleteFiles({
      filesToDelete: [
        trackData?.wavFile[0],
        trackData?.midiFile[0],
        trackData?.stemsFile[0],
        trackData?.images[0],
        trackData?.previewFile[0],
        trackData?.previewFileOriginal[0],
      ],
      deleteFirestoreDoc: false,
    })
      .then(async () => {
        await firestore.delete(`musicTracks/${musicTrackID}`)

        enqueueSnackbar('Track en bestanden verwijdert', {
          variant: 'success',
        })
        history.push('/tracks')
      })
      .catch(errorLogger)
  }
  return { deleteMusicTrack }
}

import React, { useState } from 'react'
import { RelationAndContactInput } from 'app/components/Form'
import { useSelector } from 'hooks/useSelector'
import { useSettings } from 'hooks/useSettings'
import { useDataStandards } from 'hooks/useDataStandards'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase'
import { Grid, capitalize } from '@mui/material'
import { pick } from 'utils/pick'
import PlaceIcon from '@mui/icons-material/Place'
import BusinessIcon from '@mui/icons-material/Business'
import TodayIcon from '@mui/icons-material/Today'
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed'
import Colleague from 'app/icons/Colleague'
import SubjectIcon from '@mui/icons-material/Subject'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import { AutoComplete } from 'app/components/AutoComplete'
import { TextField, FormRow, Form, DatePicker, AutoSelect } from '@fivano/core'
import { secondsToTimeString } from 'utils/secondsToTime'
import { timeStringToSeconds } from 'utils/timeToSeconds'
import { weekdayLabels, WeekHoursInput } from './WeekHoursInput'
import { PeriodInput } from './PeriodInput'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { Person } from 'types'
import { TrackType } from 'types/Track'
import { ModuleFormTypes } from '../Modules/ModuleTypes'
import { createNotification } from 'utils/createNotification'
import { FileUpload } from 'app/components/FileUpload'
import { convertToDate } from 'utils/convertToDate'

const processRawData = data => {
  const workdaySeconds = data?.workdayDurationsTrack
  const workdayTimeStrings = {}
  if (workdaySeconds) {
    weekdayLabels?.forEach((day, index) => {
      workdayTimeStrings[`${day.value}`] =
        typeof workdaySeconds[index] === 'number' && workdaySeconds[index]
          ? secondsToTimeString(workdaySeconds[index])
          : '00:00'
    })
  }
  return { ...data, ...workdayTimeStrings }
}

export const TrackAssignForm = ({
  editingDocID,
  participantID,
  onCloseForm,
}) => {
  const isEditing = !!editingDocID
  const firestore = useFirestore()
  const createDataStandards = useDataStandards()
  const profile = useSelector(state => state.firebase.profile)
  const participant: Person = useSelector(
    ({ firestore: { ordered } }) => ordered[`persons_${participantID}`]?.[0],
  )
  const [moduleData, setModuleData] = useState<ModuleFormTypes>()
  const settings = useSettings()
  const labels = settings.tracks.labels

  const TrackAssignFormConfig = {
    docLabel: capitalize(labels.track),
    defaultValues: {
      moduleTrack: '',
      moduleCodeTrack: '',
      employerTrack: null,
      employerPersonTrack: null,
      payerTrack: null,
      payerPersonTrack: null,
      referrerTrack: null,
      referrerPersonTrack: null,
      statusTrack: '',
      startDateTrack: null,
      endDateTrack: null,
      submissionDateTrack: null,
      approvedDateTrack: null,
      rejectedDateTrack: null,
      locationTrack: '',
      jobcoachTrack: '',
      hoursPerWeekTrack: '',
      costTrack: '',
      hoursPerWeekSubsidizedTrack: '',
      hourlyRateSubsidizedTrack: '',
      filesTrack: [],
    },
  }
  const {
    tracks: { trackAssign: trackFormSettings },
  } = useSettings()
  const locationQuery = useLocationQuery()
  useFirestoreConnect([
    {
      collectionGroup: 'tracks',
      limit: 50,
      where: [
        ['personIDTrack', '==', participantID],
        ...locationQuery('locationIDTrack'),
      ],
      orderBy: ['endDateTrack', 'desc'],
      storeAs: `persons_${participantID}_tracks`,
    },
  ])
  const trackAssignData: TrackType = useSelector(state => {
    if (!isEditing) return TrackAssignFormConfig.defaultValues
    return processRawData({
      ...state.firestore.data[`persons_${participantID}_tracks`][editingDocID],
    })
  })

  const buildTrackAssignData = (data: TrackType) => {
    const dataStandards = createDataStandards({
      data,
      dataName: 'Track',
      editForm: isEditing,
    })

    data.startDateTrack = data.startDateTrack
      ? convertToDate(data.startDateTrack)
      : null

    data.endDateTrack = data.endDateTrack
      ? convertToDate(data.endDateTrack)
      : null

    const workdayDurations: Array<number> = []
    weekdayLabels.forEach(day => {
      workdayDurations.push(timeStringToSeconds(data[day.value]))
      delete data[day.value]
    })
    data.workdayDurationsTrack = workdayDurations

    if (
      data.approvedDateTrack !== null &&
      data.approvedDateTrack instanceof Date
    ) {
      data.statusTrack = { label: 'Goedgekeurd', value: 'approvedTrack' }
      // status rejected if rejectedDate is not null and is a date
    } else if (
      data.rejectedDateTrack !== null &&
      data.rejectedDateTrack instanceof Date
    ) {
      data.statusTrack = { label: 'Afgekeurd', value: 'rejectedTrack' }
    } else if (data.approvedDateTrack === null) {
      data.statusTrack = { label: 'Afwachtend', value: 'awaitingTrack' }
    }

    return { ...data, ...dataStandards }
  }

  const createTrackAssign = async data => {
    const locationIDTrack = data.locationTrack.value

    const moduleCodeTrack = {
      value: data.moduleTrack.value,
      label: moduleData?.codeModu,
    }

    data.moduleTrack.label = moduleData?.nameModu
    data.moduleCodeTrack = moduleCodeTrack
    data.participantTrack = pick(participant, [
      'avatarImagePers',
      'firstNamePers',
      'lastNamePers',
      'middleNamePers',
      'id',
    ])

    data.locationIDTrack = locationIDTrack
    data.personIDTrack = participantID
    data.archivedTrack = false
    const personData = { ...participant }
    personData.personIDPers = participantID
    personData.locationIDPers = locationIDTrack
    personData.locationPers = data.locationTrack

    const addTrack = async () => {
      await firestore
        .add(
          {
            collection: 'locations',
            doc: locationIDTrack,
            subcollections: [
              {
                collection: 'persons',
                doc: participantID,
                subcollections: [{ collection: 'tracks' }],
              },
            ],
          },
          data,
        )
        .then(() => {
          createNotification(
            firestore,
            data.locationTrack.value,
            `Traject toegewezen`,
            `Traject ${data.moduleTrack.label} is toegewezen aan ${data?.participantTrack?.firstNamePers} ${data?.participantTrack?.middleNamePers} ${data?.participantTrack?.lastNamePers}`,
            'track',
            `/locatie/${data.locationTrack.value}/deelnemers/${data.personIDTrack}`,
          )
        })
    }

    const participantPath = {
      collection: 'locations',
      doc: locationIDTrack,
      subcollections: [
        {
          collection: 'persons',
          doc: participantID,
        },
      ],
    }

    await firestore.get(participantPath).then(async response => {
      if (response.exists) {
        // when person exists in location add track under location
        await addTrack()
      } else {
        // when person does not exists in location first add person under location then add track
        await firestore.set(participantPath, personData).then(() => {
          addTrack()
        })
      }
    })
  }

  const updateTrackAssign = async data => {
    const locationID = data.locationTrack.value
    data.rejectedDateTrack =
      data.rejectedDateTrack === undefined ? null : data.rejectedDateTrack

    firestore
      .update(
        `locations/${locationID}/persons/${participantID}/tracks/${editingDocID}`,
        data,
      )
      .then(() => {
        createNotification(
          firestore,
          data.locationTrack.value,
          `Toegewezen traject aangepast`,
          `Toegewezen traject ${data.moduleTrack.label} is aangepast bij ${data?.participantTrack?.firstNamePers} ${data?.participantTrack?.middleNamePers} ${data?.participantTrack?.lastNamePers}`,
          'track',
          `/locatie/${data.locationTrack.value}/deelnemers/${data.personIDTrack}`,
        )
      })
  }
  const errorLogger = useErrorLogger()

  return (
    <Form
      docLabel={TrackAssignFormConfig.docLabel}
      editingDocID={editingDocID}
      buildDocData={buildTrackAssignData}
      updateDoc={updateTrackAssign}
      createDoc={createTrackAssign}
      hasDetails={true}
      wrapInside='modal'
      modalMaxWidth='sm'
      docData={
        !!editingDocID ? trackAssignData : TrackAssignFormConfig.defaultValues
      }
      onCloseForm={onCloseForm}
      onError={errorLogger}
      deleteFirestoreDoc
      formInputs={({ formObject, showAllFields, fileChangesState }) => {
        const { watch } = formObject
        const trackLocation = formObject.watch('locationTrack')
        const filePath = `locations/${trackLocation?.value}/persons/${participantID}/documents`
        const moduleID = watch('moduleTrack')?.value
        if (moduleID && moduleData?.id !== moduleID) {
          firestore
            .collection('modules')
            .doc(moduleID)
            .get()
            .then(response => {
              if (response.exists) {
                const data: any = response.data()
                data.id = response.id
                setModuleData(data)
              }
            })
        }

        return (
          <Grid container spacing={1}>
            <FormRow icon={<DynamicFeedIcon />}>
              <Grid container spacing={1}>
                <Grid item xs={trackFormSettings.trackTypeTrack.hide ? 12 : 9}>
                  <AutoComplete
                    name='moduleTrack'
                    autoFocus
                    label={`${capitalize(labels.track)} naam of code`}
                    formObject={formObject}
                    disabled={isEditing}
                    required
                    collection='modules'
                    where={[['archivedModu', '==', false]]}
                    labelKeys={['nameModu', 'codeModu']}
                    searchKey='keywordsModu'
                    rules={{ required: `Selecteer een ${labels.track}` }}
                  />
                </Grid>
                {!trackFormSettings.trackTypeTrack.hide && (
                  <Grid item xs={3}>
                    <AutoSelect
                      name='trackTypeTrack'
                      formObject={formObject}
                      label='Type'
                      disabled={isEditing}
                      options={[
                        { value: 'goud', label: 'Goud' },
                        { value: 'zilver', label: 'Zilver' },
                        { value: 'brons', label: 'Brons' },
                        { value: 'light', label: 'Light' },
                        { value: 'mdtOekraine', label: 'MDT Oekraïne' },
                        { value: 'mdtKort50uur', label: 'MDT Kort' },
                      ]}
                      rules={{ required: 'Verplicht veld' }}
                    />
                  </Grid>
                )}
              </Grid>
            </FormRow>
            <FormRow icon={<PlaceIcon />}>
              {
                /** When someone has access to all locations enable assigning all locations */
                profile.claims.al >= 1 ? (
                  <AutoComplete
                    name='locationTrack'
                    required
                    formObject={formObject}
                    label={`Locatie ${labels.track}`}
                    disabled={isEditing}
                    collection='locations'
                    labelKeys={['nameLoca']}
                    searchKey='keywordsLoca'
                    error={
                      formObject.formState.errors.locationTrack !== undefined
                    }
                    rules={{ required: 'Verplicht veld' }}
                    helperText={`Gebruikers met toegang tot deze locatie krijgen toegang tot de gekoppelde gegevens bij dit ${labels.track}`}
                  />
                ) : (
                  <AutoSelect
                    name='locationTrack'
                    formObject={formObject}
                    label={`Locatie ${labels.track}`}
                    disabled={isEditing}
                    options={profile?.locations}
                    disableCloseOnSelect={false}
                    rules={{ required: 'Verplicht veld' }}
                    helperText={`Gebruikers met toegang tot deze locatie krijgen toegang tot de gekoppelde gegevens bij dit ${labels.track}`}
                  />
                )
              }
            </FormRow>
            <FormRow icon={<TodayIcon />}>
              <PeriodInput
                isEditing={isEditing}
                moduleData={moduleData}
                formObject={formObject}
              />
              {!trackFormSettings.submissionDateTrack.hide && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <DatePicker
                      name='submissionDateTrack'
                      formObject={formObject}
                      label='Datum aanvraag'
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DatePicker
                      name='approvedDateTrack'
                      formObject={formObject}
                      label='Datum goedkeuring'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ visibility: showAllFields ? 'visible' : 'hidden' }}
                  >
                    <DatePicker
                      name='rejectedDateTrack'
                      formObject={formObject}
                      label='Datum afkeuring'
                    />
                  </Grid>
                </Grid>
              )}
            </FormRow>
            <FormRow icon={<BusinessIcon />}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <RelationAndContactInput
                    formObject={formObject}
                    relationName='employerTrack'
                    contactsName='employerPersonTrack'
                    label='Bedrijf werkgever'
                  />
                </Grid>
                <Grid item xs={12}>
                  <RelationAndContactInput
                    formObject={formObject}
                    relationName='payerTrack'
                    contactsName='payerPersonTrack'
                    label='Bedrijf betaler'
                  />
                </Grid>
                <Grid item xs={12}>
                  <RelationAndContactInput
                    formObject={formObject}
                    relationName='referrerTrack'
                    contactsName='referrerPersonTrack'
                    label='Bedrijf verwijzer'
                  />
                </Grid>
              </Grid>
            </FormRow>
            <FormRow icon={<Colleague />}>
              <AutoComplete
                label={capitalize(labels.jobcoach)}
                name='jobcoachTrack'
                formObject={formObject}
                collection='locations/publicLocation/persons'
                labelKeys={['firstNamePers', 'middleNamePers', 'lastNamePers']}
                where={[
                  ['archivedPers', '==', false],
                  ['personTypePers', '==', 'colleague'],
                ]}
                searchKey='keywordsPers'
              />
            </FormRow>
            {!trackFormSettings.workSchedule.hide && (
              <WeekHoursInput
                formObject={formObject}
                showAllFields={showAllFields}
              />
            )}

            {!trackFormSettings.subsidizedValues.hide && (
              <FormRow
                showAllFields={
                  trackAssignData?.hourlyRateSubsidizedTrack?.length > 0 ||
                  trackAssignData?.hoursPerWeekSubsidizedTrack?.length > 0 ||
                  trackAssignData?.costTrack?.length > 0 ||
                  showAllFields
                }
                icon={<AccountBalanceIcon />}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4}>
                    <TextField
                      label='Totaal bedrag'
                      name='costTrack'
                      type='number'
                      formObject={formObject}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      label='UWV uren'
                      name='hoursPerWeekSubsidizedTrack'
                      type='number'
                      formObject={formObject}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      label='UWV uurtarief'
                      name='hourlyRateSubsidizedTrack'
                      type='number'
                      formObject={formObject}
                    />
                  </Grid>
                </Grid>
              </FormRow>
            )}
            {!trackFormSettings.notition.hide && (
              <FormRow icon={<SubjectIcon />}>
                <TextField
                  label='Opmerking traject'
                  name='textTrack'
                  formObject={formObject}
                  multiline
                  rows={3}
                  maxRows={20}
                />
              </FormRow>
            )}
            <FormRow icon={<InsertDriveFileIcon />}>
              <FileUpload
                name='filesTrack'
                label='Bestanden toevoegen'
                profile={profile}
                filesStorePath={filePath}
                fileChangesState={fileChangesState}
                onError={errorLogger}
                formObject={formObject}
                firestoreDoc
                maxFiles={30}
                docExtension='Docu'
                customDocumentData={{
                  locationID: trackLocation?.value || 'publicLocation',
                  personID: participantID,
                  category: 'trackAssign',
                }}
              />
            </FormRow>
          </Grid>
        )
      }}
    />
  )
}

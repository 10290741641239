import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import { AuthWrapper } from 'app/containers/Authentication/components/AuthWrapper'
import { emailRegex } from 'utils/regex'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { TextField } from '@fivano/core'

type UserAuthenticaiton = {
  email: string
  password: string
}

export function PasswordForget() {
  const firebase = useFirebase()
  const errorLogger = useErrorLogger()
  const history = useHistory()
  const [message, setMessage] = useState('')
  const formObject = useForm()
  const { handleSubmit, reset } = formObject

  const handlePasswordReset = (data: UserAuthenticaiton) => {
    firebase
      .resetPassword(data.email)
      .then(() => {
        setMessage(
          'Wachtwoord herstel mail is verzonden. Ga naar uw email inbox om uw wachtwoord te herstellen.',
        )
        reset()
      })
      .catch(error => {
        errorLogger({ error })
        setMessage('Fout bij versturen van herstel email')
      })
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name='description' content='Description of Login' />
      </Helmet>
      <AuthWrapper title='Wachtwoord herstellen'>
        {message === '' ? (
          <form onSubmit={handleSubmit(handlePasswordReset)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  size='medium'
                  name='email'
                  formObject={formObject}
                  rules={{
                    required: true,
                    pattern: {
                      value: emailRegex,
                      message: 'Vul een geldig email adres in',
                    },
                  }}
                  fullWidth
                  label='E-mail'
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  type='submit'
                  size='large'
                  variant='contained'
                  color='primary'
                  disableElevation
                >
                  Wachtwoord herstellen
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color='primary'
                  size='small'
                  onClick={() => history.push('/inloggen')}
                >
                  Terug naar inloggen
                </Button>
              </Grid>
            </Grid>
          </form>
        ) : (
          <Typography variant='body1'>{message}</Typography>
        )}
      </AuthWrapper>
    </>
  )
}

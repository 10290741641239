import { IconButton } from '@fivano/core'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddIcon from '@mui/icons-material/Add'
import { Button, Grid, styled } from '@mui/material'

const GridWrapper = styled(Grid, {
  shouldForwardProp: prop => prop !== 'matchesMdDown',
})<any>(({ matchesMdDown }) => ({
  ...(matchesMdDown
    ? {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 12,
      }
    : {
        position: 'absolute',
        display: 'flex',
        right: 0,
        zIndex: 1,
        top: 5,
      }),
}))

type TimeRegistrationRowButtonsProps = {
  hasItem: boolean
  onDeleteRow: () => void
  onCreateEmptyRow: () => void
  onSubmitRow: () => void
  className?: any
  matchesMdDown?: boolean
  editMode?: boolean
}

export const TimeRegistrationRowButtons = ({
  hasItem,
  onDeleteRow,
  onCreateEmptyRow,
  onSubmitRow,
  className,
  matchesMdDown,
  editMode,
}: TimeRegistrationRowButtonsProps) => (
  <GridWrapper matchesMdDown={matchesMdDown}>
    {editMode && matchesMdDown && (
      <Grid item>
        <Button variant='contained' onClick={onSubmitRow}>
          Opslaan
        </Button>
      </Grid>
    )}
    {hasItem &&
      (matchesMdDown ? (
        <Grid item>
          <Button variant='contained' onClick={onCreateEmptyRow}>
            Toevoegen
          </Button>
        </Grid>
      ) : (
        <Grid item>
          <IconButton label='Toevoegen' onClick={onCreateEmptyRow}>
            <AddIcon />
          </IconButton>
        </Grid>
      ))}
    {matchesMdDown ? (
      <Grid item>
        <Button onClick={onDeleteRow}>Verwijderen</Button>
      </Grid>
    ) : (
      <Grid item className={className}>
        <IconButton label='Verwijderen' onClick={onDeleteRow}>
          <DeleteOutlineIcon />
        </IconButton>
      </Grid>
    )}
  </GridWrapper>
)

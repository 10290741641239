import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { isEmpty, isLoaded, useFirestore } from 'react-redux-firebase'
import { Box, Button, Typography } from '@mui/material'

import { LoadingOverlay } from '@fivano/core'
import { AuthWrapper } from 'app/containers/Authentication/components/AuthWrapper'

import { useSettings } from 'hooks/useSettings'
import { useSelector } from 'hooks/useSelector'
import { selectAppMode } from 'app/containers/DevTools/appModeSlice'

export function NotFoundPage() {
  const history = useHistory()
  const [counter, setCounter] = useState(15)
  const [awaitClaims, setAwaitClaims] = useState(false)
  const auth = useSelector(state => state.firebase.auth)
  const settings = useSettings(true)
  const firestore = useFirestore()
  const appMode = useSelector(selectAppMode)

  const authRedirect = auth =>
    isLoaded(auth) && !isEmpty(auth)
      ? history.push('/')
      : history.push(
          appMode === 'app' ? 'auth/inloggen' : settings.auth.defaultSignInPath,
        )

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    if (counter < 1) {
      authRedirect(auth)
    }
    // @ts-ignore
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, history, auth])

  useEffect(() => {
    if (!isEmpty(auth)) {
      firestore
        .collection('users')
        .doc(auth.uid)
        .get()
        .then(response => {
          if (!response.exists) {
            history.push('/account-controle')
          } else {
            const usersData = response.data()
            if (usersData?.['claims'] === undefined) {
              setAwaitClaims(true)
            }
          }
        })
        .catch(error => console.error(error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  return (
    <>
      <Helmet>
        <title>Applicatie laden</title>
        <meta name='description' content='Applicatie laden' />
      </Helmet>
      <AuthWrapper title='Applicatie laden'>
        <LoadingOverlay label='Applicatie laden'>
          <Box p={2} />
          {awaitClaims === true ? (
            <Typography variant='body1' align='center'>
              U bent ingelogd, maar uw account heeft nog geen toegang tot de
              applicatie. Vraag de applicatie beheerder of admin van uw
              organisatie om rollen toe te wijzen aan uw account.
            </Typography>
          ) : (
            <>
              <Typography variant='body1' align='center'>
                De applicatie wordt geladen. U wordt doorverwezen een moment
                geduld a.u.b.
              </Typography>
              <Box p={2} />
              <Typography variant='h4'>{counter}</Typography>
              <Box p={2} />
              <Button onClick={() => authRedirect(auth)}>
                Direct naar startpagina
              </Button>
            </>
          )}
        </LoadingOverlay>
      </AuthWrapper>
    </>
  )
}

import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'hooks/useSelector'
import { Form, AutoSelect } from '@fivano/core'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { Grid, capitalize } from '@mui/material'
import { useTrackLocationOptions } from 'hooks/useTrackLocationOptions'
import { RouteTypes } from 'types/RouteTypes'
import { useSettings } from 'hooks/useSettings'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { useDataStandards } from 'hooks/useDataStandards'
import {
  DocumentRequired,
  DocumentsRequiredFormProps,
} from './ParticipantDocumentsTypes'
import { FileUpload } from 'app/components/FileUpload'
const publicLocation = { label: 'Openbaar', value: 'publicLocation' }
export const DocumentsRequiredForm = ({
  title,
  documentType,
  onClose,
}: DocumentsRequiredFormProps) => {
  const { participantID } = useParams<RouteTypes>()
  const errorLogger = useErrorLogger()
  const createDataStandards = useDataStandards()
  const firestore = useFirestore()

  const profile = useSelector(state => state.firebase.profile)
  const settings = useSettings()
  const labels = settings.tracks.labels

  const personTracks = useSelector(
    ({ firestore: { ordered } }) => ordered[`persons_${participantID}_tracks`],
  )
  const trackOptions = useTrackLocationOptions({
    participantID,
    onlyActiveTracks: true,
  })
  const locationQuery = useLocationQuery()

  useFirestoreConnect([
    {
      collectionGroup: 'documentsRequired',
      where: [
        ['personIDDore', '==', participantID],
        ['documentTypeIDDore', '==', `${documentType.idDoty}`],
        ...locationQuery('locationIDDore'),
      ],
      limit: 50,
      storeAs: `persons_${participantID}_documentsRequired_${documentType.idDoty}`,
    },
  ])

  const documentRequired = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[
        `persons_${participantID}_documentsRequired_${documentType.idDoty}`
      ]?.[0],
  )

  // ==== ADD/UPDATE DOCUMENTREQUIRED ==== //
  const isEditing = documentRequired?.documentTypeIDDore === documentType.idDoty
  const buildDocument = (data: DocumentRequired) => {
    // set locationID and personID of DocumentRequired
    console.log(personTracks, documentRequired)
    console.log(data, personTracks)
    const trackDore = personTracks.find(
      track => data.trackDore.value === track.id,
    )
    if (trackDore) {
      data.locationDore = trackDore.locationTrack
      data.locationIDDore = trackDore.locationTrack.value
    } else {
      data.locationDore = publicLocation
      data.locationIDDore = publicLocation.value
    }
    data.personIDDore = participantID

    const dataStandards = createDataStandards({
      data,
      dataName: 'Dore',
      editForm: isEditing,
    })
    data = { ...data, ...dataStandards }
    data.documentTypeIDDore = documentType.idDoty
    data.documentTypeDore = documentType.descriptionDoty
    data.privateDore = false

    return data
  }

  const setDocument = async data => {
    await firestore.set(
      `locations/${data.locationIDDore}/persons/${participantID}/documentsRequired/${documentType.idDoty}`,
      data,
    )
  }

  return (
    <Form
      docLabel={title}
      editingDocID={documentRequired?.id}
      buildDocData={buildDocument}
      updateDoc={setDocument}
      createDoc={setDocument}
      docData={documentRequired}
      hasDetails={true}
      wrapInside='modal'
      modalMaxWidth='sm'
      onCloseForm={onClose}
      onError={errorLogger}
      deleteFirestoreDoc
      formInputs={({ formObject, fileChangesState }) => {
        const trackDore = formObject.watch('trackDore')
        const selectedTrack = personTracks?.find(
          track => trackDore?.value === track.id,
        )
        const locationID =
          selectedTrack?.locationTrack?.value || publicLocation.value

        const filePath = `locations/${locationID}/persons/${participantID}/documents`
        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AutoSelect
                label={`${capitalize(labels.track)} van documenten`}
                formObject={formObject}
                name='trackDore'
                options={[publicLocation, ...trackOptions]}
                disableCloseOnSelect={false}
                rules={{
                  required: `Kies een ${labels.track} waar het document aan wordt gekoppeld.`,
                }}
                helperText={`Zonder ${labels.track} kan er geen document worden toegevoegd.`}
              />
            </Grid>

            <Grid item xs={12}>
              <FileUpload
                name='filesDore'
                label='Nieuwe bestanden toevoegen'
                profile={profile}
                filesStorePath={filePath}
                fileChangesState={fileChangesState}
                onError={errorLogger}
                formObject={formObject}
                firestoreDoc
                docExtension='Docu'
                customDocumentData={{
                  locationID: locationID || 'publicLocation',
                  personID: participantID,
                  category: 'documentRequired',
                }}
              />
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

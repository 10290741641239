import React from 'react'
import { Grid, InputAdornment, styled } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { DatePicker } from '../DatePicker'
import { TextField } from '../TextField'

const TextFieldStyled = styled(TextField)(() => ({
  '& input': {
    zIndex: 3,
  },
  '& input[type="time"]::-webkit-calendar-picker-indicator': {
    background: 'transparent',
    cursor: 'pointer',
  },
}))

const InputAdornmentStyled = styled(InputAdornment)(({ theme }) => ({
  position: 'absolute',
  marginRight: '13px',
  right: 0,
  zIndex: 1,
  color: `${
    theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.54)'
      : 'rgba(255, 255, 255)'
  }`,
}))

export const DateTimePicker = ({
  formObject,
  setTimeChanged,
  dateName,
  timeName,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <DatePicker
          formObject={formObject}
          name={dateName}
          label='Voortgang toevoeg datum'
          rules={{ required: 'Verplicht veld' }}
        />
      </Grid>
      <Grid item>
        <TextFieldStyled
          formObject={formObject}
          label='Tijd'
          name={timeName}
          onChange={() => setTimeChanged(true)}
          type='time'
          InputProps={{
            endAdornment: (
              <InputAdornmentStyled position='end'>
                <AccessTimeIcon />
              </InputAdornmentStyled>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60, // timestep of 5min
          }}
        />
      </Grid>
    </Grid>
  )
}

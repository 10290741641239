import React, { useState, createContext, useContext } from 'react'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Modal } from '../Modal'
import { Typography } from '@mui/material'

export type ConfirmModalOptions = {
  /** Label shown on the confirm button. */
  confirmLabel?: string
  /** Label shown on the cancel button. */
  cancelLabel?: string
  /** Title shown in the warning modal. */
  title?: string
  /** Text message shown on the confirm button. */
  message?: string
  /** This function will be triggered when warning is confirmed by the user. */
  onConfirm?: any
  /** Content shown in the warning modal. */
  content?: any
}

type ConfirmModalProps = {
  /** Determines whether the modal should be mounted*/
  open: boolean
} & ConfirmModalOptions

const DEFAULT_PROPS: ConfirmModalProps = {
  open: false,
  title: 'Weet je het zeker?',
  message: '',
  content: undefined,
  confirmLabel: 'Ok',
  cancelLabel: 'Annuleren',
  onConfirm: () => {},
}

export const FilesWillBeDeletedWarning = ({ files, labels }) => (
  <>
    {files && files?.length > 0 && (
      <DialogContentText>{labels.filesWillBeDeleted}</DialogContentText>
    )}
    {files?.map(file => (
      <Typography key={file.fileID} component='div' variant='caption'>
        {file.name}
      </Typography>
    ))}
  </>
)

const ConfirmModal = ({
  props,
  onCancel,
  onConfirm,
  onClose,
}: {
  props: ConfirmModalProps
  onCancel: () => void
  onConfirm: () => void
  onClose: () => void
}) => {
  const { title, message, content, confirmLabel, cancelLabel } = props

  return (
    <Modal title={title} closeButton maxWidth='xs' open onClose={onClose}>
      <DialogContent>
        {message && <DialogContentText>{message}</DialogContentText>}
        {content}
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={onConfirm}>
          {confirmLabel}
        </Button>
        <Button variant='contained' color='primary' onClick={onCancel}>
          {cancelLabel}
        </Button>
      </DialogActions>
    </Modal>
  )
}

const ConfirmModalContext = createContext<
  (options: ConfirmModalOptions) => void
>(() => {})

export const ConfirmModalProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [props, setProps] = useState<ConfirmModalProps>(DEFAULT_PROPS)

  const confirm = (options: ConfirmModalOptions) => {
    setProps({
      ...DEFAULT_PROPS,
      ...options,
      open: true,
    })
  }

  const handleClose = () => {
    setProps(DEFAULT_PROPS)
  }

  const handleConfirm = () => {
    props.onConfirm()
    handleClose()
  }

  return (
    <>
      <ConfirmModalContext.Provider value={confirm}>
        {children}
      </ConfirmModalContext.Provider>
      {props.open && (
        <ConfirmModal
          props={props}
          onClose={handleClose}
          onCancel={handleClose}
          onConfirm={handleConfirm}
        />
      )}
    </>
  )
}

export const useConfirmModal: () => (options: ConfirmModalOptions) => void =
  () => {
    const confirm = useContext(ConfirmModalContext)
    return confirm
  }

import React, { useEffect, useState } from 'react'
import { useCookie } from 'react-use'
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import { Modal } from '../Modal'

export const BrowserSupport = () => {
  const [cookies, updateCookie] = useCookie('browserSupport')
  const [isIE, setIsIE] = useState(false)

  useEffect(() => {
    if (cookies === null && isInterneExplorer()) {
      setIsIE(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Sample function that returns boolean in case the browser is Internet Explorer*/
  const isInterneExplorer = () => {
    const ua = navigator.userAgent

    /* MSIE used to detect old browsers and Trident used to newer ones*/
    const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1

    return isIE
  }

  const onHandleBrowserSupport = () => {
    updateCookie('true')
    setIsIE(false)
  }

  return (
    <Modal
      maxWidth='sm'
      onClose={() => setIsIE(!isIE)}
      open={isIE}
      title='Let op!'
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Typography>
          Wij ondersteunen Internet Explorer niet. Gebruik een andere moderne
          browser zoals{' '}
          <a
            href='https://www.google.com/chrome/'
            target='_blank'
            rel='noreferrer'
          >
            Google Chrome
          </a>{' '}
          voor de beste beveiliging, snelheid en gebruikservaring. Gebruik de
          applicatie in Internet Explorer op eigen risico.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onHandleBrowserSupport()} color='primary'>
          Doorgaan
        </Button>
      </DialogActions>
    </Modal>
  )
}

import React from 'react'
import {
  IconButton,
  Button,
  Grid,
  Container,
  DialogActions,
  Typography,
  useMediaQuery,
  styled,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { FormActionsProps, FormPageActionsLayoutProps } from './formTypes'

const FormActionsBar = styled('div')(({ theme }) => ({
  width: '100%;',
  position: 'sticky',
  top: 0,
  padding: '6px 0px 6px 0px',
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.appBar,
  marginBottom: 20,
  boxShadow: theme.shadows[1],
  [theme.breakpoints.down('sm')]: {
    top: 0,
  },
}))

const MobileActions = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'fixed',
  bottom: 0,
  padding: '12px 16px',
  zIndex: theme.zIndex.modal + 1,
  backgroundColor: theme.palette.background.paper,
}))

const ShowAllFieldsButton = ({ showAllFieldsState, labels }) => {
  const { hideFields, showFields } = labels
  const [showAllFields, setShowAllFields] = showAllFieldsState
  return (
    <Button
      color='primary'
      onClick={() => {
        setShowAllFields(!showAllFields)
      }}
    >
      {showAllFields ? hideFields : showFields}
    </Button>
  )
}

const ActionButtonsPage = ({
  uploadingFilesFor,
  hasDetails,
  showAllFieldsState,
  onSubmit,
  formDirty,
  isEditing,
  onCloseForm,
  labels,
}: FormActionsProps) => {
  return (
    <Grid container spacing={1}>
      {hasDetails && (
        <Grid item>
          <ShowAllFieldsButton
            showAllFieldsState={showAllFieldsState}
            labels={labels}
          />
        </Grid>
      )}
      <Grid item marginLeft='auto'>
        <Button color='primary' onClick={onCloseForm}>
          {formDirty
            ? isEditing
              ? labels.cancelUpdating
              : labels.cancelAdding
            : labels.closing}
        </Button>
        <Button
          onClick={onSubmit}
          variant='contained'
          color='primary'
          type='submit'
          disableElevation
          disabled={!formDirty || uploadingFilesFor?.length > 0}
          sx={{ margin: '2px 8px' }}
        >
          {uploadingFilesFor?.length > 0 ? 'Uploading...' : labels.saving}
        </Button>
      </Grid>
    </Grid>
  )
}

export const FormPageActionsLayout = ({
  ActionButtonsPage,
  onCloseForm,
  title,
}: FormPageActionsLayoutProps) => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm'),
  )
  return (
    <>
      <FormActionsBar>
        <Container>
          <Grid container alignItems='center'>
            <Grid item>
              <IconButton onClick={onCloseForm} size='large'>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant='h5' sx={{ marginTop: '2px' }}>
                {title}
              </Typography>
            </Grid>
            <Grid item marginLeft='auto'>
              {!isSmallScreen && ActionButtonsPage}
            </Grid>
          </Grid>
        </Container>
      </FormActionsBar>
      {isSmallScreen && <MobileActions>{ActionButtonsPage}</MobileActions>}
    </>
  )
}

export const FormPageActions = ({
  onSubmit,
  hasDetails,
  showAllFieldsState,
  formDirty,
  title,
  isEditing,
  onCloseForm,
  labels,
  uploadingFilesFor,
}: FormActionsProps) => (
  <FormPageActionsLayout
    title={title}
    onCloseForm={onCloseForm}
    ActionButtonsPage={
      <ActionButtonsPage
        uploadingFilesFor={uploadingFilesFor}
        formDirty={formDirty}
        hasDetails={hasDetails}
        onSubmit={onSubmit}
        onCloseForm={onCloseForm}
        isEditing={isEditing}
        showAllFieldsState={showAllFieldsState}
        labels={labels}
      />
    }
  />
)

export const FormModalActions = ({
  onSubmit,
  hasDetails,
  showAllFieldsState,
  formDirty,
  isEditing,
  onCloseForm,
  labels,
  uploadingFilesFor,
}: FormActionsProps) => {
  return (
    <DialogActions>
      <ActionButtonsPage
        uploadingFilesFor={uploadingFilesFor}
        formDirty={formDirty}
        hasDetails={hasDetails}
        onSubmit={onSubmit}
        onCloseForm={onCloseForm}
        isEditing={isEditing}
        showAllFieldsState={showAllFieldsState}
        labels={labels}
      />
    </DialogActions>
  )
}

import React from 'react'
import { FloatingButton, PageContainer } from '@fivano/core'
import { useHistory } from 'react-router-dom'
import { ProductsList } from './ProductsList'
import { Paper } from '@mui/material'

export const ProductsPage = () => {
  const history = useHistory()

  return (
    <PageContainer maxWidth='lg'>
      <Paper>
        <ProductsList />
      </Paper>
      <FloatingButton
        label='Product toevoegen'
        onClick={() => {
          history.push(`/producten/formulier`)
        }}
      />
    </PageContainer>
  )
}

import { GeneralSettings } from './GeneralSettings/GeneralSettings'
import { UserSettings } from './UserSettings/UserSettings'
import { ProgressTags } from './ProgressTags/ProgressTags'
import { DocumentRequiredTypes } from './DocumentRequiredTypes/DocumentRequiredTypes'
import { DocumentCategory } from './DocumentCategory/DocumentCategory'
import { UserRolesProfile } from './UserRolesProfile/UserRolesProfile'
import { PersonTags } from './PersonTags/PersonTags'
import { TrackSettings } from '../Tracks/TrackSettings'
import { TagsProgressBoard } from './TagsProgressBoard/TagsProgressBoard'
import { servicesConfig } from '@fivano/models'
import { Genres } from '../MusicTracks/Genres/Genres'
import { AudioKeys } from '../MusicTracks/AudioKeys/AudioKeys'
import { WorkCodes } from './WorkCodes/WorkCodes'
import { JobOpeningCategorySettings } from '../JobOpenings/JobOpeningCategorySettings'
import { ServicesSettings } from '../../containers/ServicesSettings/ServicesSettings'
import { LogoSettings } from './Logo/LogoSettings'
import { ColorSettings } from './ColorSettings'
import { routeObjectType } from 'app/router/types'
import { SettingsOverview } from './SettingsOverview/SettingsOverview'

export const settingRoutes: routeObjectType = {
  settings: {
    path: '/instellingen',
    component: SettingsOverview,
    claims: { s: 1 },
  },
  generalSettings: {
    path: '/instellingen-algemeen',
    component: GeneralSettings,
    claims: { dev: 1 },
  },
  logo: {
    path: '/instellingen/logo-instellingen',
    component: LogoSettings,
    claims: { dev: 1 },
  },
  documentCategory: {
    path: '/instellingen/documenten',
    component: DocumentCategory,
    claims: { dev: 1 },
  },
  userSettings: {
    path: '/instellingen/gebruikers',
    component: UserSettings,
    claims: { s: 1 },
  },
  userRoleProfile: {
    path: '/instellingen/gebruikers-rollen-profielen',
    component: UserRolesProfile,
    claims: { s: 5 },
  },
  documentRequired: {
    path: '/instellingen/verplichte-documenten',
    component: DocumentRequiredTypes,
    claims: { s: 1 },
  },
  crmBoard: {
    path: '/instellingen/crm-tags',
    component: TagsProgressBoard,
    claims: { s: 1 },
  },
  servicesSettings: {
    path: `/instellingen/${servicesConfig.labels.plural}`,
    component: ServicesSettings,
    claims: { sv: 1 },
  },
  progressTags: {
    path: '/instellingen/voortgang',
    component: ProgressTags,
    claims: { s: 1 },
  },
  personTags: {
    path: '/instellingen/deelnemertags',
    component: PersonTags,
    claims: { s: 1 },
  },
  trackSettings: {
    path: '/instellingen/trajecten',
    component: TrackSettings,
    claims: { dev: 5 },
  },
  jobOpeningCategorySettings: {
    path: '/instellingen/vacature-categorieen',
    component: JobOpeningCategorySettings,
    claims: { dev: 5 },
  },
  genres: {
    path: '/instellingen/genres',
    component: Genres,
    claims: { mt: 5 },
  },
  audioKeys: {
    path: '/instellingen/audio-keys',
    component: AudioKeys,
    claims: { mt: 5 },
  },
  workCodes: {
    path: '/instellingen/werkcodes',
    component: WorkCodes,
    claims: { s: 5 },
  },
  colorSettings: {
    path: '/instellingen/kleur-instellingen',
    component: ColorSettings,
    claims: { dev: 5 },
  },
}

import React from 'react'
import { Grid, styled } from '@mui/material'
import { compareArraysAndObjects } from 'utils/compareArraysAndObjects'
import { TimeRowProps } from './types'
import { TimeRegistrationRowInputs } from './TimeRegistrationRowInputs'
import { TimeRegistrationRowButtons } from './TimeRegistrationRowButtons'

const GridRow = styled(Grid)(({ theme }) => ({
  minHeight: 54,
  position: 'relative',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover .actionButtons': {
      visibility: 'visible',
    },
  },
}))
const GridContent = styled(Grid)(({ theme }) => ({
  padding: '14px 16px 10px 16px',
}))
const TimeRegistrationRowButtonsStyled = styled(TimeRegistrationRowButtons, {
  shouldForwardProp: prop => prop !== 'showLabels',
})<any>(({ showLabels }) => ({
  visibility: showLabels ? 'visible' : 'hidden',
}))

export const TimeRegistrationRow = React.memo(
  ({
    personID,
    editMode = false,
    submitMode = false,
    addMode,
    date,
    docs,
    defaultValues,
    showLabels,
    locationsData,
    projectCodes,
    hasItem,
    onClickRow,
    onCreateRow,
    onUpdateRow,
    onSubmitRow,
    onCreateEmptyRow,
    onDeleteRow,
    onSubmitSuccess,
    onSubmitError,
  }: TimeRowProps) => (
    <GridRow container>
      <GridContent item xs={12} onClick={onClickRow}>
        <TimeRegistrationRowInputs
          personID={personID}
          defaultValues={defaultValues}
          docs={docs}
          date={date}
          onCreateRow={onCreateRow}
          onUpdateRow={onUpdateRow}
          locationsData={locationsData}
          projectCodes={projectCodes}
          showLabels={showLabels}
          editMode={editMode}
          submitMode={submitMode}
          addMode={addMode}
          onSubmitSuccess={onSubmitSuccess}
          onSubmitError={onSubmitError}
        />
      </GridContent>
      <Grid item {...(showLabels && { xs: 12 })}>
        <TimeRegistrationRowButtonsStyled
          onSubmitRow={onSubmitRow}
          onCreateEmptyRow={() => {
            onCreateEmptyRow(date)
          }}
          editMode={editMode}
          matchesMdDown={showLabels}
          showLabels={showLabels}
          hasItem={hasItem}
          onDeleteRow={() => onDeleteRow(defaultValues)}
          className='actionButtons'
        />
      </Grid>
    </GridRow>
  ),
  (prevProps, nextProps) => {
    return (
      compareArraysAndObjects(
        prevProps.defaultValues,
        nextProps.defaultValues,
      ) &&
      prevProps.onClickRow === nextProps.onClickRow &&
      prevProps.editMode === nextProps.editMode &&
      prevProps.submitMode === nextProps.submitMode &&
      prevProps.addMode === nextProps.addMode &&
      prevProps.showLabels === nextProps.showLabels &&
      prevProps.hasItem === nextProps.hasItem
    )
  },
)

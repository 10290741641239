import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'
import { Box, Typography, Grid, Button } from '@mui/material'
import { TextField, LoadingOverlay } from '@fivano/core'
import { AuthWrapper } from 'app/containers/Authentication/components/AuthWrapper'

import { emailRegex } from 'utils/regex'
import { PasswordTextField } from './PasswordTextfield'

type UserAuthentication = {
  email: string
  password: string
}

export function EmailInvitation() {
  const history = useHistory()
  const firebase = useFirebase()

  const formObject = useForm()
  const { handleSubmit } = formObject
  const [loadingMessage, setLoadingMessage] = useState('')
  const [error, setError] = useState('')

  const handleAccountRegistration = ({
    email,
    password,
  }: UserAuthentication) => {
    setLoadingMessage('Email verifieren')
    firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then(() => {
        setTimeout(() => {
          firebase
            .auth()
            .currentUser?.updatePassword(password)
            .then(response => {
              firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(() => {
                  history.push('/')
                })
            })
            .catch(error => {
              console.warn(error)
            })
          setLoadingMessage('')
        }, 500) // use 0.5 sec timeout before rerouting to prevent PrivateRoute check
      })
      .catch(error => {
        setError(
          'Er is iets fout gegaan, neem contact op met de applicatie beheerder' +
            error,
        )
        setLoadingMessage('')
      })
  }
  const [passwordValid, setPasswordValid] = useState(false)

  return (
    <>
      <Helmet>
        <title>Aanmelden</title>
        <meta name='description' content='Aanmelden' />
      </Helmet>
      <AuthWrapper title='Aanmelden'>
        {loadingMessage && (
          <LoadingOverlay
            label={loadingMessage}
            customStyle={{ borderRadius: 4 }}
          />
        )}
        <Typography variant='body1'>
          Welkom! Vul het email adres in waarop u de link naar deze pagina heeft
          ontvangen en kies een wachtwoord.
        </Typography>
        <form onSubmit={handleSubmit(handleAccountRegistration)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='body1' color='error'>
                {error}
              </Typography>
              <Box p={1} />
              <TextField
                name='email'
                formObject={formObject}
                rules={{
                  required: 'Dit is een verplicht veld',
                  pattern: {
                    value: emailRegex,
                    message: 'Vul een geldig email adres in',
                  },
                }}
                label='E-mail'
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordTextField
                name='password'
                label='Wachtwoord'
                formObject={formObject}
                onValidate={setPasswordValid}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type='submit'
                size='large'
                variant='contained'
                color='primary'
                disabled={!passwordValid}
                disableElevation
              >
                Volgende
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color='primary'
                size='small'
                onClick={() => history.push('/inloggen')}
              >
                Al een account? Inloggen
              </Button>
            </Grid>
          </Grid>
        </form>
      </AuthWrapper>
    </>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { InputBase, IconButton } from '@fivano/core'
import SearchIcon from '@mui/icons-material/Search'
import TuneIcon from '@mui/icons-material/Tune'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Grid,
  Popover,
  styled,
  Button,
  Divider,
  Paper,
} from '@mui/material'
import { AutoComplete } from '../AutoComplete'

const generalStyle = { width: '100%' }
const GridStyled = styled(Grid)(() => ({
  maxWidth: 600,
  ...generalStyle,
}))

const PaperStyled = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 40,
  [theme.breakpoints.up('sm')]: {
    width: 'calc(100% - 100px)',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  },
  ...generalStyle,
}))

const SearchIconStyled = styled(SearchIcon)(({ theme }) => ({
  margin: '8px 0px 8px 8px',
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}))

const FormStyled = styled('form')(() => ({
  ...generalStyle,
}))

const InputBaseStyled = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flex: 1,
  ...generalStyle,
}))

const IconButtonStyled = styled(IconButton)(() => ({
  padding: 10,
}))

const DividerStyled = styled(Divider)(() => ({
  height: 28,
  margin: 4,
}))

const PopoverStyled = styled(Popover)(() => ({
  minWidth: '100%',
  maxWidth: '600px',
  '& .MuiPopover-paper': {
    maxWidth: 568,
    width: 'calc(100% - 32px)',
  },
  ...generalStyle,
}))

const ButtonStyled = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  padding: '7px 22px 8px 22px',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

type SearchbarProps = {
  placeholder: string
  value: string
  elevation?: number
  filter?: any
  onClear: any
  onSubmit?: any
  autoComplete?: any
}

type FormValues = {
  searchField: string
  autoCompleteSearch: any
}

const defaultValues = { searchField: '' }

export const Searchbar = ({
  placeholder,
  value,
  elevation = 0,
  filter,
  onClear,
  onSubmit,
  autoComplete,
}: SearchbarProps) => {
  // user account menu functionality
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  let anchorRef = useRef(null)
  const handleClickFilter = () => {
    setAnchorEl(anchorRef.current)
  }
  const handleCloseFilter = () => {
    setAnchorEl(null)
  }

  // ==== SEARCH FUNCTIONALITY ====//
  const formObject = useForm<FormValues>({
    defaultValues,
  })
  const { handleSubmit, reset, watch } = formObject
  const handleSearchSubmit = data => {
    onSubmit(data)
  }
  const autoCompleteSearch = watch('autoCompleteSearch')
  useEffect(() => {
    if (autoCompleteSearch !== undefined && autoCompleteSearch !== null) {
      handleSearchSubmit(autoCompleteSearch)
    } else if (autoCompleteSearch === null) {
      handleSearchSubmit('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCompleteSearch])

  const openFilter = Boolean(anchorEl)
  const id = openFilter ? 'simple-popover' : undefined
  console.warn('This component is deprecated. Please use SearchFilter')
  return (
    <GridStyled container>
      <PaperStyled
        elevation={elevation}
        variant={elevation === 0 ? 'outlined' : 'elevation'}
      >
        <SearchIconStyled color='disabled' />
        <FormStyled
          onSubmit={handleSubmit(handleSearchSubmit)}
          autoComplete='off'
        >
          {autoComplete !== undefined ? (
            <Box pt={'4px'}>
              <AutoComplete
                name='autoCompleteSearch'
                formObject={formObject}
                {...autoComplete}
              />
            </Box>
          ) : (
            <InputBaseStyled
              name='searchField'
              formObject={formObject}
              placeholder={placeholder}
            />
          )}
        </FormStyled>

        {value && value.length > 0 && !autoComplete && (
          <IconButtonStyled
            label='Reset zoekveld'
            onClick={() => {
              onClear()
              reset(defaultValues)
            }}
            size='large'
          >
            <CloseIcon />
          </IconButtonStyled>
        )}
        {filter !== undefined && (
          <>
            <DividerStyled orientation='vertical' />
            <IconButtonStyled
              label='Uitgebreid zoeken'
              color='primary'
              onClick={handleClickFilter}
              size='large'
            >
              <TuneIcon />
            </IconButtonStyled>
            <PopoverStyled
              id={id}
              open={openFilter}
              anchorEl={anchorEl}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box p={2}>{filter}</Box>
            </PopoverStyled>
          </>
        )}
      </PaperStyled>
      <ButtonStyled
        ref={anchorRef}
        onClick={handleSubmit(handleSearchSubmit)}
        size='large'
        color='primary'
        variant='contained'
      >
        <SearchIcon />
      </ButtonStyled>
    </GridStyled>
  )
}

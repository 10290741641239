import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import BreadcrumbsMaterial from '@mui/material/Breadcrumbs'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { styled, Link, Typography } from '@mui/material'
import { routeParts } from 'app/router/routeParts'

const BreadcrumbsStyled = styled(BreadcrumbsMaterial)(({ theme }) => ({
  padding: '12px 16px 8px 16px',
  [theme.breakpoints.down('sm')]: {
    padding: '12px 8px 8px 8px',
  },
  '& .MuiBreadcrumbs-ol': {
    height: 40,
    flexWrap: 'nowrap',
    overflowX: 'auto',
  },
  '& .MuiBreadcrumbs-li': {
    flex: '0 0 auto',
    '&:last-child': {
      color: theme.palette.text.primary,
    },
  },
}))
type breadcrumb = { title: string; path: string }
export interface BreadcrumbsProps {
  /* Array of breadcrumb objects with title and path */
  breadcrumbs?: breadcrumb[]
}

export const Breadcrumbs = ({
  breadcrumbs: breadcrumbsProp,
}: BreadcrumbsProps) => {
  const location = useLocation()
  const [breadcrumbs, setBreadcrumbs] = useState<breadcrumb[]>(
    breadcrumbsProp || [],
  )

  useEffect(() => {
    if (breadcrumbsProp) return
    const crumbs: breadcrumb[] = []
    let newPath = '/'
    const parts = location.pathname.split('/')
    parts?.forEach(path => {
      if (path === '') crumbs.push({ title: 'Home', path: '/' })
      const route = Object.entries(routeParts)?.find(
        ([_, v]) => v.path === path,
      )
      if (route) {
        const routeMatch = route?.[1]
        newPath += routeMatch.path + '/'
        crumbs.push({ title: routeMatch.name, path: newPath.slice(0, -1) })
      }
    })
    setBreadcrumbs(crumbs)
  }, [breadcrumbsProp, location])

  return (
    <BreadcrumbsStyled separator={<NavigateNextIcon fontSize='small' />}>
      {breadcrumbs.map((item, index) => {
        if (index < breadcrumbs.length + 1) {
          return (
            <Link
              key={index}
              color='inherit'
              component={RouterLink}
              to={item.path}
              underline='hover'
            >
              {item.title}
            </Link>
          )
        } else {
          return (
            <Typography key={index} color='textPrimary'>
              {item.title}
            </Typography>
          )
        }
      })}
    </BreadcrumbsStyled>
  )
}

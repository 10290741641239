import React, { useEffect, useState } from 'react'
import {
  Button,
  Grid,
  Link,
  Typography,
  InputBase,
  Tooltip,
  styled,
} from '@mui/material'
import { RegisterOptions, UseFormReturn } from 'react-hook-form'
import { useDebounce } from 'react-use'
import { ErrorMessage } from '@hookform/error-message'

const InputBaseStyled = styled(InputBase)(({ theme }) => ({
  fontSize: 14,
  height: 24,
  borderRadius: 4,
  paddingLeft: 4,
  border: `1px solid ${theme.palette.divider}`,
}))

const stringToSlug = (...args: (string | number)[]) => {
  if (!args.join()) return
  const value = args.join(' ')
  return value
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-') // separator
}

type SlugInputType = {
  name: string
  formObject: UseFormReturn
  sourceSlug: string
  rootSlug: string
  rules?: RegisterOptions
}

export const SlugInput = ({
  name,
  formObject,
  sourceSlug: sourceSlugProp,
  rootSlug,
  rules,
}: SlugInputType) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = formObject
  const slug = watch(name)
  const sourceSlug = watch(sourceSlugProp)

  const [editSlug, setEditSlug] = useState<boolean>(false)
  const [nameDecoupled, setNameDecoupled] = useState<boolean>(
    !(slug === stringToSlug(sourceSlug)),
  )

  useDebounce(
    () => {
      if (!nameDecoupled) {
        setValue(name, stringToSlug(sourceSlug))
      }
    },
    250,
    [sourceSlug],
  )

  const handleSlugBlur = e => {
    setValue(name, stringToSlug(e.target.value))
    setEditSlug(false)
  }
  const hasError = errors[name] !== undefined
  useEffect(() => {
    if (hasError) setEditSlug(true)
  }, [hasError])
  return (
    <Grid container alignItems='center' style={{ paddingLeft: 16 }}>
      <Grid item>
        <Typography
          variant='body2'
          display='inline'
          {...(hasError && { color: 'error' })}
        >
          Link:{' '}
        </Typography>
        {editSlug ? (
          <Typography
            variant='body2'
            display='inline'
            {...(hasError ? { color: 'error' } : { color: 'textSecondary' })}
          >
            {rootSlug}/
          </Typography>
        ) : (
          <Link variant='body2' href={`${rootSlug}/${slug}`} target='_blank'>
            {rootSlug}/{slug}
          </Link>
        )}
      </Grid>
      <Grid
        item
        style={
          editSlug ? { flexGrow: 1, display: 'block' } : { display: 'none' }
        }
      >
        <InputBaseStyled
          {...register(name, rules)}
          onBlur={handleSlugBlur}
          placeholder='link'
        />
      </Grid>
      <Grid item>
        {editSlug ? (
          <>
            <Button
              size='small'
              type='button'
              onClick={() => {
                !slug && setNameDecoupled(false)
                setEditSlug(false)
              }}
            >
              Opslaan
            </Button>
            <Tooltip title='Zet naam om als link'>
              <Button
                size='small'
                type='button'
                onClick={() => {
                  setEditSlug(false)
                  setValue(name, stringToSlug(sourceSlug))
                  setNameDecoupled(false)
                }}
              >
                Herstel
              </Button>
            </Tooltip>
          </>
        ) : (
          <Button
            size='small'
            type='button'
            onClick={() => {
              setNameDecoupled(true)
              setEditSlug(true)
            }}
          >
            Bewerken
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography color='error' variant='caption'>
          <ErrorMessage name={name} errors={errors} />
        </Typography>
      </Grid>
    </Grid>
  )
}

import { GridColDef } from '@mui/x-data-grid-pro'
import { useSelector } from 'hooks/useSelector'

const DocNames = ({ docIDs, collection }) => {
  const productCategories = useSelector(state =>
    docIDs?.map(id => state[collection].entities[id]),
  )
  return productCategories ? (
    <>
      {Object.values(productCategories)?.map(
        (docData: any, index) =>
          `${docData?.name} ${
            index + 1 < productCategories?.length ? ', ' : ''
          }`,
      )}
    </>
  ) : (
    <div />
  )
}

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Product',
    disableColumnMenu: true,
  },
  {
    field: 'productCategories',
    headerName: 'Categorieën',
    disableColumnMenu: true,
    renderCell: params => (
      <DocNames docIDs={params.value} collection='productCategories' />
    ),
  },
  {
    field: 'price',
    headerName: 'Prijs',
    disableColumnMenu: true,
  },
]

export const columnsMobile: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Product',
    disableColumnMenu: true,
  },
]

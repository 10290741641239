import { menuLayoutType } from 'app/router/types'
import {
  Settings,
  Category,
  LocalGroceryStoreOutlined,
} from '@mui/icons-material'
import { productsRoutes } from './productRoutes'

export const productsMenu: menuLayoutType[] = [
  {
    name: 'Producten',
    icon: LocalGroceryStoreOutlined,
    route: productsRoutes.products,
    subMenu: [
      {
        name: 'Categorieen',
        icon: Category,
        description: 'Categorieen',
        route: productsRoutes.productCategories,
      },
      {
        name: 'Instellingen',
        icon: Settings,
        description: 'Instellingen',
        route: productsRoutes.productsSettings,
      },
    ],
  },
]

import { firebaseAddError } from 'utils/firebaseAddError'

export const createNotification = (
  firestore,
  locationID,
  name,
  text,
  type,
  linkNoti,
) => {
  const notificationData: any = {}
  notificationData.locationNoti = locationID
  notificationData.readBy = []
  notificationData.creationDateNoti = new Date()
  notificationData.nameNoti = name
  notificationData.textNoti = text
  notificationData.typeNoti = type
  notificationData.linkNoti = linkNoti
  firestore
    .collection('notifications')
    .add(notificationData)
    .catch(error => {
      firebaseAddError(error, firestore, '', '', undefined, undefined)
    })
}

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'hooks/useSelector'
import { isLoaded, useFirebase, useFirestore } from 'react-redux-firebase'
import { Paper, Box, Typography, Button, Grid, styled } from '@mui/material'

import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { PageContainer } from '@fivano/core'
import { PasswordResetForm } from './PasswordResetForm'

import { PersonFormModal } from '../PersonForm/PersonFormModal'
import { Person } from 'types/Person'
import { useSettings } from 'hooks/useSettings'
import { useErrorLogger } from 'hooks/useErrorLogger'

const PaperStyled = styled(Paper)(() => ({
  maxWidth: '600px',
}))

const breadcrumbArray = [
  { title: 'Home', path: '/' },
  { title: 'Mijn account', path: '/mijn-account' },
]

export function UserProfile() {
  const firebase = useFirebase()
  const firestore = useFirestore()
  const settings = useSettings()
  const [authEmailConfirmed, setAuthEmailConfirmed] = useState(false)
  const errorLogger = useErrorLogger()
  const [personData, setPersonData] = useState<Person>()

  const profile = useSelector(state => state.firebase.profile)
  const auth = useSelector(state => state.firebase.auth)

  const [extraData, setExtraData] = useState<any>()

  useEffect(() => {
    if (firebase.auth().currentUser?.emailVerified) {
      setAuthEmailConfirmed(true)
    }
    if (isLoaded(profile) && isLoaded(auth)) {
      firestore
        .get(`locations/publicLocation/persons/${profile.uid}`)
        .then((response: any) => {
          const data: Person = response?.data()
          data.id = profile?.uid
          data.emailPers = auth?.email || profile?.emailPers || 'emailerror'
          setPersonData(data)
          setExtraData({
            firstNamePers: data.firstNamePers,
            middleNamePers: data.middleNamePers,
            lastNamePers: data.lastNamePers,
            emailPers: auth.email,
            personTypePers: 'userColleague',
            archivedPers: false,
            addressCountryPers: { value: 'nl', label: 'Nederland' },
          })
        })
        .catch(console.warn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, auth])

  const handleProfileUpdate = (id, data) => {
    const newUserProfileData = {
      firstNamePers: data.firstNamePers,
      middleNamePers: data.middleNamePers ? data.middleNamePers : '',
      lastNamePers: data.lastNamePers,
      emailPers: data.emailPers,
      avatarImagePers: data.avatarImagePers && data.avatarImagePers,
      id: id,
      updatedAtPers: firestore.FieldValue.serverTimestamp(),
    }
    firebase
      .updateProfile(newUserProfileData)
      .then(response => {})
      .catch(error => {
        errorLogger({ error })
      })
  }

  const handleSendEmailVerification = () => {
    if (profile.uid) {
      firebase.auth().currentUser?.sendEmailVerification()
    }
  }
  return (
    <>
      <Helmet>
        <title>Mijn account</title>
      </Helmet>
      <PageContainer maxWidth='lg'>
        <Breadcrumbs breadcrumbs={breadcrumbArray} />
        <PaperStyled>
          {personData &&
          userProfileFormSettings?.firstNamePersProps?.disabled === true ? (
            <PersonFormModal
              title='Mijn account'
              open={true}
              extraData={extraData}
              formSettings={userProfileFormSettings}
              defaultValues={personData}
              personTypePers='userColleague'
              onAddSuccess={handleProfileUpdate}
              onUpdateSuccess={handleProfileUpdate}
              disableModal
            />
          ) : (
            'Laden'
          )}
        </PaperStyled>
        <Box p={1} />
        {settings.auth.emailAuth && (
          <PaperStyled>
            <Box p={2}>
              <PasswordResetForm />
            </Box>
          </PaperStyled>
        )}
        <Box p={1} />
        {settings.auth.emailAuth && !authEmailConfirmed && (
          <PaperStyled>
            <Box p={2}>
              <Typography variant='h6' gutterBottom>
                Email bevestigen
              </Typography>
              <Typography variant='body1' gutterBottom>
                Uw email adres is nog niet bevestigd. Klik op de onderstaande
                knop om uw email adres te bevestigen. U ontvangt een email met
                een link om uw email te bevestigen.
              </Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    onClick={handleSendEmailVerification}
                    color='primary'
                    variant='contained'
                    disableElevation
                  >
                    Stuur bevestigings email
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </PaperStyled>
        )}
      </PageContainer>
    </>
  )
}
const userProfileFormSettings = {
  firstNamePersProps: {
    required: false,
    disabled: true,
    autoFocus: false,
  },
  middleNamePersProps: {
    required: false,
    disabled: true,
  },
  lastNamePersProps: {
    helperText:
      'Uw voornaam, voorvoegsel en achternaam kunnen niet worden gewijzigd',
    disabled: true,
  },
  emailPersProps: {
    helperText: 'Uw email kan niet worden gewijzigd.',
    disabled: true,
  },
  locationPers: {
    hide: false,
    disabled: true,
    toggleVisibility: true,
    required: false,
    defaultValue: { value: 'publicLocation', label: 'Openbare locatie' },
    options: [{ value: 'publicLocation', label: 'Openbare locatie' }],
  },
  initialsPers: {
    required: false,
    hide: true,
    toggleVisibility: false,
  },
  givenNamesPers: {
    hide: false,
    toggleVisibility: true,
  },
  birthNamePers: {
    hide: false,
    toggleVisibility: true,
  },
  relationPositionPers: {
    hide: true,
    toggleVisibility: false,
  },
  socialSecurityNumberPers: {
    hide: true,
    toggleVisibility: false,
  },
  birthdatePers: {
    required: false,
    hide: false,
    toggleVisibility: false,
    datepickerProps: { format: 'dd/MM/yyyy', placeholder: 'dd/mm/jjjj' },
  },
  identificationTypePers: {
    hide: true,
    toggleVisibility: true,
  },
  identificationNumberPers: {
    hide: true,
    toggleVisibility: true,
  },
  identificationValidUntilPers: {
    hide: true,
    toggleVisibility: true,
  },
  genderPers: {
    hide: false,
    toggleVisibility: false,
    options: [
      { label: 'Man', value: 'm' },
      { label: 'Vrouw', value: 'f' },
      { label: 'X', value: 'x' },
      { label: 'Onbekend', value: 'o' },
    ],
  },
  addressStreetPers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  addressNumberPers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  addressAdditionPers: {
    hide: false,
    toggleVisibility: false,
  },
  addressZipPers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  addressCityPers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  addressCountryPers: {
    required: false,
    hide: false,
    toggleVisibility: true,
  },
  addressStatePers: {
    required: false,
    hide: false,
    toggleVisibility: true,
  },
  phoneMobilePers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  phoneLinePers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  phoneNoAnswerPers: {
    required: false,
    hide: true,
    toggleVisibility: true,
  },
  martialStatusData: {
    hide: true,
    toggleVisibility: false,
  },
  bankData: {
    hide: true,
    toggleVisibility: false,
  },
  dossierNumber: {
    hide: true,
    toggleVisibility: true,
  },
  archiveData: {
    hide: true,
    toggleVisibility: true,
  },
}

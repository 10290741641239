import React from 'react'
import { Grid } from '@mui/material'
import { TextField } from '@fivano/core'
import { FormRow, Switch } from '@fivano/core'
import { SettingsForm } from 'app/legacy/SettingsBlock/SettingsForm'

export const AudioKeyForm = props => {
  const { firestoreCode } = props
  const id = props.formProps.formID
  return (
    <SettingsForm {...props}>
      {({ formObject, showAllFields }) => {
        const {
          formState: { errors },
        } = formObject
        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label='Key'
                name={`name${firestoreCode}`}
                readOnly={!!id}
                formObject={formObject}
                required
                error={errors.descriptionPrta !== undefined}
                helperText={
                  errors[`name${firestoreCode}`] &&
                  errors[`name${firestoreCode}`].type === 'required' &&
                  'Verplicht veld'
                }
              />
            </Grid>
            <FormRow showAllFields={showAllFields}>
              <TextField
                name={`id${firestoreCode}`}
                formObject={formObject}
                readOnly={!!id}
                label='Key ID'
              />
              <Switch
                name={`archived${firestoreCode}`}
                formObject={formObject}
                label='Key archiveren'
                defaultValue={false}
              />
            </FormRow>
          </Grid>
        )
      }}
    </SettingsForm>
  )
}

import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { TextField, Form, LoadingOverlay, Modal } from '@fivano/core'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { useDataStandards } from 'hooks/useDataStandards'

import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'

import { useErrorLogger } from 'hooks/useErrorLogger'
import { useHistory } from 'react-router-dom'
export const WorkCodesField = ({ optionsData }) => {
  const history = useHistory()
  const firestore = useFirestore()
  const [loadingMessage, setLoadingMessage] = useState<string>('')

  useFirestoreConnect([
    {
      collection: 'appSettings',
      doc: 'workCodes',
      storeAs: 'workCodes',
    },
  ])

  const docData = useSelector(
    ({ firestore: { data } }) => data.workCodes?.options,
  )

  const createDataStandards = useDataStandards()
  const errorLogger = useErrorLogger()

  const onSubmit = data => {
    setLoadingMessage('Werkcode toevoegen')
    data.id = firestoreIDGenerator()

    const dataStandards = createDataStandards({
      data,
      dataName: '',
      editForm: false,
      archived: false,
    })

    return { ...dataStandards, ...data }
  }

  const createDocData = async data => {
    if (docData) {
      const mergedData = [...docData, data]
      await firestore
        .set(`appSettings/workCodes`, {
          settingsName: 'workCodes',
          options: mergedData,
        })
        .then(() => {
          setLoadingMessage('')
        })
    } else {
      await firestore
        .set(`appSettings/workCodes`, {
          settingsName: 'workCodes',
          options: [data],
        })
        .then(() => {
          setLoadingMessage('')
        })
    }
  }

  const updateDocData = async data => {
    return data
  }
  const duplicateNameCheck = (docData, field: string) => {
    return docData?.find(
      workCode => workCode?.label?.toLowerCase() === field?.toLowerCase(),
    )
  }

  return (
    <>
      <Modal
        open={loadingMessage ? true : false}
        maxWidth='sm'
        customHeight='200px'
      >
        {loadingMessage !== '' && <LoadingOverlay label={loadingMessage} />}
      </Modal>

      <Grid container>
        <Grid item xs={9}>
          <Form
            docLabel='Werkcodes'
            buildDocData={onSubmit}
            updateDoc={updateDocData}
            createDoc={createDocData}
            docData={docData}
            wrapInside='page'
            modalMaxWidth='sm'
            hasDetails={false}
            onCloseForm={() => history.push('/instellingen')}
            onError={errorLogger}
            formInputs={({ formObject }) => {
              return (
                <TextField
                  required
                  label='Naam'
                  name='label'
                  formObject={formObject}
                  rules={{
                    required: 'Verplicht veld',
                    validate: {
                      validateDuplicate: value =>
                        duplicateNameCheck(optionsData, value)
                          ? 'Deze naam is al in gebruik'
                          : true,
                    },
                    value: '',
                  }}
                />
              )
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

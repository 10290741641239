import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router'
import {
  useFirestoreConnect,
  ReduxFirestoreQuerySetting,
} from 'react-redux-firebase'
import { format } from 'date-fns'

import { Avatar } from 'app/components/Avatar'
import { Paper, Grid, Box, Typography, Button, Chip } from '@mui/material'

import { Breadcrumbs } from 'app/components/Breadcrumbs'
import {
  PageContainer,
  ListView,
  Checkbox,
  AutoSelect,
  ActionMenu,
  MenuButton,
  FloatingButton,
} from '@fivano/core'
import { PersonFormModal } from '../PersonForm/PersonFormModal'

import {
  formSettings,
  initialParticipantValues as initialFormValues,
} from 'app/legacy/Participants/ParticipantFormSettings'

import { useSelector } from 'hooks/useSelector'
import { useSettings } from 'hooks/useSettings'
import { SearchFilter } from 'app/components/SearchFilter'
import { useFilters } from 'hooks/useFilters'
import { useArchiveDoc } from 'hooks/useArchiveDoc'
import { personFullName } from 'model/person'
import { AutoComplete } from 'app/components/AutoComplete'
import { Visibility, Edit, Archive } from '@mui/icons-material'
import { Person } from 'types'
import { convertToDate } from 'utils/convertToDate'

const breadcrumbArray = [
  { title: 'Home', path: '/' },
  { title: 'Deelnemers', path: '/deelnemers' },
]

export const Participants = () => {
  const history = useHistory()
  const profile = useSelector(state => state.firebase.profile)
  const settings = useSettings()
  const [participantLimit, setParticipantLimit] = useState(20)

  const [filters, setFilters] = useFilters({
    searchField: {
      value: '',
      label: 'Zoek op:',
      param: true,
    },
    archivedPers: {
      value: false,
      label: 'Toon gearchiveerd',
      param: true,
    },
    locationIDPers: {
      value: [],
      label: 'Locaties:',
      cookie: true,
    },
  })
  const handleFilter = data => {
    const newFilters = { ...filters }
    newFilters.searchField.value = data.searchField
    newFilters.archivedPers.value = data.archivedPers
    newFilters.locationIDPers.value = data.locationIDPers
    setFilters(newFilters)
  }

  const participantQuery = (
    { locationIDPers, searchField, archivedPers },
    limit,
  ): ReduxFirestoreQuerySetting => ({
    collectionGroup: 'persons',
    orderBy: ['lastNamePers', 'asc'],
    // @ts-ignore
    where: [
      ['keywordsPers', 'array-contains', searchField.value],
      ['archivedPers', '==', archivedPers.value],
      ['personTypePers', '==', 'participant'],
    ].concat(
      profile.claims.al >= 1 && !(locationIDPers.value?.length > 0)
        ? []
        : [
            [
              'locationIDPers',
              'in',
              locationIDPers.value?.length > 0
                ? locationIDPers.value.map(i => i.value)
                : profile.locationsAccess,
            ],
          ],
    ),
    limit: limit,
    storeAs: 'participants',
  })
  // @ts-ignore
  useFirestoreConnect([participantQuery(filters, participantLimit)])

  // get participants from store and remove duplicate id's from actual return
  const participantsData: Person[] = useSelector(({ firestore: { ordered } }) =>
    ordered?.participants?.reduce((array, item) => {
      return array.some(i => i['id'] === item.id) ? array : [...array, item]
    }, []),
  )
  const participantsLength = useSelector(
    ({ firestore: { ordered } }) => ordered?.participants?.length,
  )

  // ==== PARTCIPANT FORM LOGIC ==== //
  const [participantModal, setParticipantModal] = useState(false)
  const [defaultFormValues, setDefaultFormValues] = useState(initialFormValues)
  const handleEdit = data => {
    setDefaultFormValues(data)
    setParticipantModal(true)
  }
  const handleClosePersonForm = () => {
    setDefaultFormValues(initialFormValues)
    setParticipantModal(false)
  }

  const archiveDoc: any = useArchiveDoc()
  const handleArchive = data => {
    archiveDoc({
      docType: 'Deelnemer',
      path: `locations/${data.locationIDPers}/persons/${data.id}`,
      archived: !data?.archivedPers,
      extension: 'Pers',
    })
  }
  // ==== PARTICIPANT LISTVIEW DEFINITIONS ==== //
  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          accessor: 'lastNamePers',
          gridProps: {
            xs: 12,
            sm: 6,
            md: 5,
          },
          Cell: data => {
            return (
              <>
                <Grid container wrap='nowrap' spacing={2} alignItems='center'>
                  <Grid item>
                    <Avatar personData={data.row.original} />
                  </Grid>
                  <Grid item>
                    <Typography variant='body1' display='inline'>
                      {personFullName(data.row.original)}
                    </Typography>
                    {data.row.original.archivedPers && (
                      <Chip label='Gearchiveerd' size='small' />
                    )}
                  </Grid>
                </Grid>
              </>
            )
          },
        },
        {
          Header: 'Woonplaats',
          accessor: 'addressCityPers',
          gridProps: {
            sm: 6,
            md: 4,
          },
          hidden: { only: ['xs'] },
          Cell: data => {
            return (
              <Grid item>
                <Typography variant='body1'>
                  {data.row.original.addressCityPers}
                </Typography>
              </Grid>
            )
          },
        },
        {
          Header: 'Geboortedatum',
          accessor: 'birthdatePers',
          gridProps: {
            md: 3,
          },
          hidden: { only: ['xs', 'sm'] },
          Cell: data => {
            return (
              <Grid item>
                <Typography variant='body1'>
                  {data.row.original.birthdatePers &&
                    data.row.original.birthdatePers !== '' &&
                    format(
                      convertToDate(data.row.original.birthdatePers),
                      settings.person.birthDateFormat,
                    )}
                </Typography>
              </Grid>
            )
          },
        },
        {
          id: 'actions',
          Header: ' ',
          Cell: data => (
            <Grid item>
              <ActionMenu
                iconPosition='vertical'
                minWidth={250}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <MenuButton
                  label='Deelnemer details'
                  icon={<Visibility />}
                  onClick={() =>
                    history.push(
                      `/locatie/${data.row.original.locationIDPers}/deelnemers/${data.row.original.id}`,
                    )
                  }
                />
                <MenuButton
                  label={`Bewerk deelnemer`}
                  icon={<Edit />}
                  onClick={() => handleEdit(data.row.original)}
                />
                <MenuButton
                  label={
                    data.row.original?.archivedPers
                      ? 'Deelnemer dearchiveren'
                      : 'Deelnemer archiveren'
                  }
                  icon={<Archive />}
                  onClick={() => handleArchive(data.row.original)}
                />
              </ActionMenu>
            </Grid>
          ),
        },
      ],
    },
  ]

  return (
    <>
      <Helmet>
        <title>Deelnemers</title>
      </Helmet>
      <PersonFormModal
        title='Deelnemer'
        open={participantModal}
        onClose={handleClosePersonForm}
        formSettings={formSettings}
        defaultValues={defaultFormValues}
        personTypePers='participant'
        onAddSuccess={(id, data) => {
          handleClosePersonForm()
          history.push(`/locatie/${data.locationIDPers}/deelnemers/${id}`)
        }}
        onUpdateSuccess={() => {
          handleClosePersonForm()
        }}
      />
      <PageContainer maxWidth='lg' disableGuttersMobile>
        <Breadcrumbs breadcrumbs={breadcrumbArray} />
        <Paper style={{ marginBottom: 80 }}>
          <Box p={2}>
            <SearchFilter
              placeholder='Deelnemers zoeken'
              onSubmit={handleFilter}
              filterValues={filters}
              filters={({ formObject }) => {
                return (
                  <>
                    <Grid item xs={12}>
                      {profile.claims.al >= 1 ? (
                        <AutoComplete
                          label='Locatie deelnemer'
                          name='locationIDPers'
                          collection='locations'
                          labelKeys={['nameLoca']}
                          where={[['archivedLoca', '==', false]]}
                          searchKey='keywordsLoca'
                          formObject={formObject}
                          multiple
                          size='medium'
                          options={profile.locations}
                        />
                      ) : (
                        <AutoSelect
                          label='Locatie deelnemer'
                          formObject={formObject}
                          multiple
                          size='medium'
                          name='locationIDPers'
                          options={profile.locations}
                          disableCloseOnSelect={false}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Checkbox
                        label='Toon gearchiveerde deelnemers'
                        formObject={formObject}
                        name='archivedPers'
                      />
                    </Grid>
                  </>
                )
              }}
            />
          </Box>
          <FloatingButton
            label='Deelnemer toevoegen'
            onClick={() => setParticipantModal(true)}
          />
          {participantsLength > 0 ? (
            <>
              <ListView
                data={participantsData}
                columns={listColumns}
                route={`/locatie/{locationIDPers}/deelnemers/{id}`}
              />
              {participantsLength === participantLimit && (
                <Button
                  fullWidth
                  onClick={() => setParticipantLimit(participantLimit + 15)}
                >
                  Meer deelnemers laden
                </Button>
              )}
            </>
          ) : (
            <Box p={2}>
              <Typography variant='body1'>
                Geen deelnemers gevonden. Voeg een nieuwe deelnemer toe
              </Typography>
            </Box>
          )}
        </Paper>
      </PageContainer>
    </>
  )
}

import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { isEmpty, isLoaded, useFirestore } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'

import { AuthWrapper } from 'app/containers/Authentication/components/AuthWrapper'
import { PersonFormModal } from '../PersonForm/PersonFormModal'
import { Typography } from '@mui/material'
import { initialUserProfileFormValues } from '../UserProfile/userProfileFormSettings'
import { useSettings } from 'hooks/useSettings'

export const UserAccount = () => {
  const history = useHistory()
  const firestore = useFirestore()
  const auth = useSelector(state => state.firebase.auth)
  const settings = useSettings(true)

  useEffect(() => {
    if (isLoaded(auth)) {
      if (!isEmpty(auth)) {
        firestore
          .collection('users')
          .doc(auth.uid)
          .get()
          .then(response => {
            const userData = response.data()
            if (
              response.exists &&
              userData?.firstNamePers &&
              userData?.lastNamePers
            ) {
              history.push('/')
            }
          })
      } else {
        history.push(
          settings.auth.microsoftTenantID
            ? '/inloggen-met-microsoft'
            : '/inloggen',
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  const handleProfileUpdate = (id, data) => {
    firestore
      .collection('users')
      .doc(id)
      .set({
        firstNamePers: data.firstNamePers,
        middleNamePers: data.middleNamePers,
        lastNamePers: data.lastNamePers,
        avatarImagePers: data.avatarImagePers,
        keywordsPers: data.keywordsPers,
        uid: id,
        roles: [],
        locationsAccess: ['publicLocation'],
      })
      .then(() => {
        history.push('/')
      })
      .catch(error => console.error(error))
  }

  if (auth.email) {
    initialUserProfileFormValues.emailPers = auth.email
  }
  return (
    <>
      <Helmet>
        <title>Account maken</title>
        <meta name='description' content='Account maken' />
      </Helmet>
      <AuthWrapper
        maxWidth='sm'
        title='Account maken'
        formPadding={'24px 0px 0px 0px'}
      >
        {auth.uid && auth.email ? (
          <PersonFormModal
            title='Account profiel instellen'
            open={true}
            personID={auth.uid}
            extraData={{
              emailPers: auth.email,
              createdByPers: '',
              archivedPers: false,
            }}
            formSettings={userAccountFormSettings}
            defaultValues={initialUserProfileFormValues}
            personTypePers='userColleague'
            onAddSuccess={handleProfileUpdate}
            onUpdateSuccess={handleProfileUpdate}
            disableModal
          />
        ) : (
          <Typography variant='body1'>Account instellingen laden</Typography>
        )}
      </AuthWrapper>
    </>
  )
}
const userAccountFormSettings = {
  emailPersProps: {
    helperText: 'Uw email kan niet worden gewijzigd.',
    readOnly: true,
  },
  locationPers: {
    hide: false,
    disabled: true,
    toggleVisibility: true,
    required: false,
    defaultValue: { value: 'publicLocation', label: 'Openbare locatie' },
    options: [{ value: 'publicLocation', label: 'Openbare locatie' }],
  },
  initialsPers: {
    required: false,
    hide: true,
    toggleVisibility: false,
  },
  givenNamesPers: {
    hide: false,
    toggleVisibility: true,
  },
  birthNamePers: {
    hide: false,
    toggleVisibility: true,
  },
  relationPositionPers: {
    hide: true,
    toggleVisibility: false,
  },
  socialSecurityNumberPers: {
    hide: true,
    toggleVisibility: false,
  },
  birthdatePers: {
    required: false,
    hide: false,
    toggleVisibility: false,
    datepickerProps: { format: 'dd/MM/yyyy', placeholder: 'dd/mm/jjjj' },
  },
  identificationTypePers: {
    hide: true,
    toggleVisibility: true,
  },
  identificationNumberPers: {
    hide: true,
    toggleVisibility: true,
  },
  identificationValidUntilPers: {
    hide: true,
    toggleVisibility: true,
  },
  genderPers: {
    hide: false,
    toggleVisibility: false,
    options: [
      { label: 'Man', value: 'm' },
      { label: 'Vrouw', value: 'f' },
      { label: 'X', value: 'x' },
      { label: 'Onbekend', value: 'o' },
    ],
  },
  addressStreetPers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  addressNumberPers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  addressAdditionPers: {
    hide: false,
    toggleVisibility: false,
  },
  addressZipPers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  addressCityPers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  addressCountryPers: {
    required: false,
    hide: false,
    toggleVisibility: true,
  },
  addressStatePers: {
    required: false,
    hide: false,
    toggleVisibility: true,
  },
  phoneMobilePers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  phoneLinePers: {
    required: false,
    hide: false,
    toggleVisibility: false,
  },
  phoneNoAnswerPers: {
    required: false,
    hide: true,
    toggleVisibility: true,
  },
  martialStatusData: {
    hide: true,
    toggleVisibility: false,
  },
  bankData: {
    hide: true,
    toggleVisibility: false,
  },
  dossierNumber: {
    hide: true,
    toggleVisibility: true,
  },
  archiveData: {
    hide: true,
    toggleVisibility: true,
  },
}

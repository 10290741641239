import React, { useState } from 'react'
import { useSelector } from 'hooks/useSelector'
import {
  useFirestoreConnect,
  ReduxFirestoreQuerySetting,
} from 'react-redux-firebase'
import { Helmet } from 'react-helmet-async'
import { SettingsContainer } from 'app/components/SettingsContainer'
import { Box, Typography, Grid, Button } from '@mui/material'
import { Modal, ListView } from '@fivano/core'
import { UserSettingsForm } from './UserSettingsForm'
import { OptionType } from 'types/CommonTypes'
import { UserAddForm } from './UserAddForm'
import { SearchFilter } from 'app/components/SearchFilter'
import { useFilters } from 'hooks/useFilters'
import { useSettings } from 'hooks/useSettings'

const breadcrumbArray = [
  { title: 'Instellingen', path: '/instellingen' },
  { title: 'Gebruikers', path: '/instellingen/gebruikers' },
]

export function UserSettings(props) {
  const [open, setOpen] = useState(false)
  const [userModal, setUserModal] = useState(false)

  const [defaultValues, setDefaultValues] = useState(null)

  const usersData = useSelector(state => state.firestore.ordered.users_list)

  const [userLimit, setUserLimit] = useState(20)

  const [filters, setFilters] = useFilters({
    searchField: {
      value: '',
      label: 'Zoek op:',
      param: true,
    },
  })
  const handleFilter = data => {
    const newFilters = { ...filters }
    newFilters.searchField.value = data.searchField
    setFilters(newFilters)
  }

  const userQuery = ({ searchField }, limit): ReduxFirestoreQuerySetting => ({
    collection: 'users',
    orderBy: ['lastNamePers', 'asc'],
    ...(searchField.value && {
      where: [['keywordsPers', 'array-contains', searchField.value]],
    }),
    limit: 50,
    storeAs: 'users_list',
  })

  const usersLength = usersData?.length

  // @ts-ignore
  useFirestoreConnect([userQuery(filters, userLimit)])

  const handleEdit = data => {
    setDefaultValues(data)
    setOpen(true)
  }

  const listColumns = [
    {
      Header: ' ',
      canGroupBy: false,
      canSort: false,
      columns: [
        {
          Header: 'Naam',
          accessor: 'lastNamePers',
          gridProps: {
            xs: 12,
            sm: 6,
            md: 4,
          },
          Cell: data => (
            <div>
              <Typography variant='body1'>
                {data.row.original.firstNamePers}{' '}
                {data.row.original.middleNamePers}{' '}
                {data.row.original.lastNamePers}
              </Typography>
              <Typography variant='caption' color='textSecondary'>
                {data.row.original.uid}
              </Typography>
            </div>
          ),
        },
        {
          Header: 'Locaties',
          gridProps: {
            xs: 12,
            sm: 6,
            md: 4,
          },
          hidden: { only: ['xs'] },
          Cell: data => (
            <Grid item>
              <Typography variant='body2' display='inline'>
                {data.row.original.locations?.map(
                  (location, index) =>
                    `${index === 0 ? '' : ', '}${location.label}`,
                )}
              </Typography>
            </Grid>
          ),
        },
        {
          Header: 'Rollen',
          accessor: 'middleNamePers',
          gridProps: {
            xs: 12,
            sm: 6,
            md: 4,
          },
          hidden: { only: ['xs', 'sm'] },
          Cell: data => (
            <Grid item>
              <Typography variant='body2'>
                {data.row.original.roles?.length > 0 &&
                  data.row.original.roles.map(
                    (value: OptionType, index: number) =>
                      value.label && `${index === 0 ? '' : ', '}${value.label}`,
                  )}
              </Typography>
            </Grid>
          ),
        },
      ],
    },
  ]

  const settings = useSettings()

  return (
    <>
      <Helmet>
        <title>Gebruikers - Instellingen</title>
        <meta name='description' content='Gebruikers - instellingen' />
      </Helmet>
      <SettingsContainer breadcrumbs={breadcrumbArray}>
        <Modal
          open={open}
          maxWidth='sm'
          onClose={() => setOpen(false)}
          closeButton
          title='Gebruiker instellingen'
        >
          <UserSettingsForm
            defaultValues={defaultValues}
            onClose={() => setOpen(false)}
          />
        </Modal>
        {settings.auth.enableUserEmailInvite && (
          <Modal
            open={userModal}
            maxWidth='sm'
            onClose={() => setUserModal(false)}
            closeButton
            title='Gebruiker uitnodigen'
          >
            <UserAddForm onClose={() => setUserModal(false)} />
          </Modal>
        )}
        <Box pl={3} pr={3} pt={2} maxWidth={600}>
          <Typography gutterBottom variant='h6'>
            Gebruikers instellingen
          </Typography>
          <Typography gutterBottom variant='body2' color='textSecondary'>
            Stel de rollen en locaties van een gebruikers in. De rollen van een
            gebruiker geven toegang tot bepaalde deelnemer gegevens en bepaalde
            applicatie onderdelen. Een gebruiker krijgt toegang tot alle
            deelnemers geplaatst onder de locaties die zijn toegewezen aan de
            gebruiker.
          </Typography>
          <Button
            color='primary'
            variant='contained'
            disableElevation
            onClick={() => setUserModal(true)}
          >
            Gebruiker uitnodigen
          </Button>
        </Box>
        <Box p={2}>
          <SearchFilter
            placeholder='Gebruiker zoeken'
            onSubmit={handleFilter}
            filterValues={filters}
          />
        </Box>
        {usersLength > 0 ? (
          <>
            <ListView
              data={usersData}
              columns={listColumns}
              onClick={(event, data) => handleEdit(data.original)}
            />
            {usersLength === userLimit && (
              <Button fullWidth onClick={() => setUserLimit(userLimit + 15)}>
                Meer gebruikers laden
              </Button>
            )}
          </>
        ) : (
          <Box p={2}>
            <Typography variant='body1'>
              Geen gebruikers gevonden. Voeg een nieuwe gebruiker toe
            </Typography>
          </Box>
        )}
      </SettingsContainer>
    </>
  )
}

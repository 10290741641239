import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { RouteTypes } from 'types/RouteTypes'
import { Avatar } from 'app/components/Avatar'
import { Grid, Box, Button, Paper, Typography, styled } from '@mui/material'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { ProgressCard } from 'app/legacy/Progress/ProgressCard'
import { optionsCreator } from 'utils/optionsCreator'
import SearchIcon from '@mui/icons-material/Search'
import {
  convertTimestampDate,
  FloatingButton,
  AutoSelect,
  PageContainer,
  useSnackbar,
  useFilesDelete,
  useConfirmModal,
} from '@fivano/core'
import { format } from 'date-fns'
import { ProgressForm } from './ProgressForm'
import { ProgressItemTypes, ProgressTagsDataTypes } from './ProgressTypes'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { personFullName } from 'model/person'

const DivStyled = styled('div')(() => ({
  order: 3,
}))

const ButtonWrapperGridStyled = styled(Grid)(() => ({
  height: 40,
  marginBottom: 6,
}))

const SearchButtonStyled = styled(Button)(() => ({
  height: '100%',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  marginLeft: '-2px',
  width: 60,
}))

const InfoGridStyled = styled(Grid)(({ theme }) => ({
  width: '100%',
  order: 1,
  [theme.breakpoints.up('md')]: {
    order: 2,
  },
}))

export function Progress() {
  const errorLogger = useErrorLogger()
  const firestore = useFirestore()
  const { participantID, locationID } = useParams<RouteTypes>()
  const { enqueueSnackbar } = useSnackbar()
  const formObject = useForm()
  const { handleSubmit, getValues, watch } = formObject

  const participantDetails = useSelector(
    state => state.firestore.ordered[`persons_${participantID}`]?.[0],
  )

  const [participantName, setParticipantName] = useState('Laden')

  if (participantDetails === undefined) {
    firestore.get({
      collection: `/locations/${locationID}/persons`,
      doc: participantID,
      storeAs: `persons_${participantID}`,
    })
  }
  useEffect(() => {
    participantDetails && setParticipantName(personFullName(participantDetails))
  }, [participantDetails])

  const breadcrumbArray = [
    { title: 'Home', path: '/' },
    { title: 'Deelnemers', path: '/deelnemers' },
    {
      title: participantName,
      path: `/locatie/${locationID}/deelnemers/${participantID}`,
    },
    {
      title: 'Voortgang',
      path: `/locatie/${locationID}/deelnemers/${participantID}/voortgang`,
    },
  ]

  const [progressAmount, setProgressAmount] = useState(10)
  const locationQuery = useLocationQuery()
  const [whereState, setWhereState] = useState<any>({
    where: [
      ['personIDProg', '==', participantID],
      ...locationQuery('locationIDProg'),
    ],
  })

  useFirestoreConnect([
    {
      collection: `locations/${locationID}/persons`,
      doc: participantID,
      storeAs: `persons_${participantID}`,
    },
    {
      collection: 'locations',
      doc: locationID,
      subcollections: [
        {
          collection: 'persons',
          doc: participantID,
        },
      ],
      storeAs: `persons_${participantID}`,
    },
    {
      collection: 'appSettings',
      doc: 'progressTags',
      storeAs: 'progressTags',
    },
    {
      collectionGroup: 'progress',
      orderBy: ['dateTimeProg', 'desc'],
      ...whereState,
      limit: progressAmount,
      storeAs: `persons_${participantID}_progress`,
    },
    // load tracks on /progress page to load track to redux store on hard refresh
    {
      collectionGroup: 'tracks',
      limit: 50,
      where: [
        ['personIDTrack', '==', participantID],
        ...locationQuery('locationIDTrack'),
      ],
      storeAs: `persons_${participantID}_tracks`,
    },
  ])

  const progressData: ProgressItemTypes[] = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[`persons_${participantID}_progress`],
  )
  // Convert tags to options array
  const progressTags: ProgressTagsDataTypes[] = useSelector(
    ({ firestore: { ordered } }) => ordered['progressTags'],
  )
  const [tagOptions, setTagOptions] = useState([])
  useEffect(() => {
    if (progressTags?.[0]?.options?.length > 0) {
      setTagOptions(
        optionsCreator(progressTags?.[0]?.options, 'descriptionPrta', 'idPrta'),
      )
    }
  }, [progressTags])

  // ==== PROGRESS ITEMS LOADING ==== //
  const handleLoadMore = () => {
    setProgressAmount(progressAmount + 5)
  }

  // Tag search logic
  const handleSearch = data => {
    if (
      data.searchProg?.value !== null &&
      data.searchProg?.value !== undefined
    ) {
      setWhereState({
        where: [
          ['personIDProg', '==', participantID],
          ['keywordsProg', 'array-contains', data.searchProg.value],
          ...locationQuery('locationIDProg'),
        ],
      })
    } else {
      setWhereState({
        where: [
          ['personIDProg', '==', participantID],
          ...locationQuery('locationIDProg'),
        ],
      })
    }

    // TODO enable multiple tag search
    // if (data.tagsProg.length > 0 && data.tagsProg.length < 10) {
    //   setSearchError('')
    //   const searchTags: Array<any> = []
    //   data.tagsProg.forEach(tag => {
    //     searchTags.push(tag.value)
    //   })
    //   // @ts-ignore

    // } else if (data.tagsProg.length > 9) {
    //   setSearchError('U kunt op maximaal 10 tags tegelijk zoeken.')
    // }
  }
  // Reset search query when tagsProg is empty
  const searchTags = getValues('searchProg')
  const watchTagsProg = watch('searchProg')
  useEffect(() => {
    if (searchTags && searchTags.length === 0) {
      setWhereState([])
    }
  }, [watchTagsProg, searchTags])

  // ==== PROGRESS ADD AND EDIT ==== //
  const [progressFormProps, setProgressFormProps] = useState({
    progressID: '',
    open: false,
  })

  const deleteFiles = useFilesDelete({ onError: errorLogger })
  const handleDeleteFirestore = async progressData => {
    await deleteFiles({
      filesToDelete: progressData.filesProg || [],
      deleteFirestoreDoc: true,
    })
    await firestore
      .delete(
        `locations/${progressData.locationIDProg}/persons/${participantID}/progress/${progressData.id}`,
      )
      .then(() => {
        enqueueSnackbar('Voortgang succesvol verwijderd.', {
          variant: 'success',
        })
      })
      .catch(error => {
        errorLogger({
          error,
          snackbarMessage: `Voortgang verwijderen mislukt. ${error}`,
        })
      })
  }

  const confirmModal = useConfirmModal()
  const handleDelete = progressData => {
    confirmModal({
      onConfirm: () => handleDeleteFirestore(progressData),
      cancelLabel: 'Annuleren',
      confirmLabel: 'Verwijderen',
      title: 'Bevestig verwijderen',
      message:
        'Het voortgang item en de eventueel gekoppelde bijlages worden permanent verwijdert. Deze actie kan niet ongedaan gemaakt worden.',
    })
  }

  return (
    <>
      <Helmet>
        <title>Voortgang {`${participantName}`} </title>
      </Helmet>
      {progressFormProps.open && (
        <ProgressForm
          wrapInside='modal'
          modalMaxWidth='sm'
          onCloseForm={() => {
            setProgressFormProps({ progressID: '', open: false })
          }}
          editingDocID={progressFormProps.progressID}
        />
      )}
      <PageContainer maxWidth='lg'>
        <Breadcrumbs key={participantName} breadcrumbs={breadcrumbArray} />
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <DivStyled>
              <FloatingButton
                label='Voortgang toevoegen'
                onClick={() => {
                  setProgressFormProps({ progressID: '', open: true })
                }}
              />
              <Paper>
                <Box p={2} mb={2}>
                  <form onSubmit={handleSubmit(handleSearch)}>
                    <Grid container>
                      <Grid item width='calc(100% - 80px)'>
                        <AutoSelect
                          placeholder='Zoeken op tag'
                          formObject={formObject}
                          name='searchProg'
                          options={tagOptions}
                          // error={searchError !== ''} TODO enable multiple tag search
                        />
                      </Grid>
                      <ButtonWrapperGridStyled item>
                        <SearchButtonStyled
                          color='primary'
                          variant='contained'
                          type='submit'
                          disableElevation
                        >
                          <SearchIcon />
                        </SearchButtonStyled>
                      </ButtonWrapperGridStyled>
                      {/* <Grid item xs={12}> TODO enable multiple tag search
                        <Typography variant='caption' color='error'>
                          {searchError}
                        </Typography>
                      </Grid> */}
                    </Grid>
                  </form>
                </Box>
              </Paper>
              <Grid container spacing={1}>
                {progressData?.length > 0 ? (
                  <>
                    {progressData.map((item: ProgressItemTypes) => {
                      return (
                        <Grid item xs={12} key={item.id}>
                          <ProgressCard
                            id={item.id}
                            onEdit={() => {
                              setProgressFormProps({
                                progressID: item.id,
                                open: true,
                              })
                            }}
                            onDelete={(_, progressData) =>
                              handleDelete(progressData)
                            }
                            text={item.textProg}
                            tags={item.tagsProg}
                            files={item.filesProg}
                            creatorID={item.createdByProg}
                            firstName={item.creatorFirstNameProg}
                            middleName={item.creatorMiddleNameProg}
                            lastName={item.creatorLastNameProg}
                            avatarImage=''
                            dateTime={item.dateTimeProg}
                            duration={item.durationProg}
                            showActions
                            progressData={item}
                            wrapItems
                          />
                        </Grid>
                      )
                    })}
                    {progressData.length === progressAmount && (
                      <Grid item>
                        <Button fullWidth onClick={handleLoadMore}>
                          Meer voortgang laden
                        </Button>
                      </Grid>
                    )}
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Paper>
                      <Box p={2}>
                        <Typography variant='body1'>
                          Geen voortgang gevonden, voeg voortgang bij deelnemer
                          toe.
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </DivStyled>
          </Grid>
          <InfoGridStyled item md={4}>
            {participantDetails && (
              <Paper>
                <Box p={2}>
                  <Grid
                    container
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Grid item>
                      <Box mr={2}>
                        <Avatar size='medium' personData={participantDetails} />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {participantDetails?.firstNamePers}{' '}
                        {participantDetails?.lastNamePers}
                      </Typography>
                      <Typography
                        variant='body1'
                        color='textSecondary'
                        display='block'
                      >
                        {participantDetails?.birthdatePers
                          ? format(
                              convertTimestampDate(
                                participantDetails?.birthdatePers,
                              ),
                              'dd/MM/yyyy',
                            )
                          : 'Geen datum'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            )}
          </InfoGridStyled>
        </Grid>
      </PageContainer>
    </>
  )
}

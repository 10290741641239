import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, styled } from '@mui/material'
import { PrivateRoute } from './PrivateRoute'
import { publicRoutes } from 'app/PublicRoutes'
import {
  BrowserSupport,
  ConfirmModalProvider,
  SnackbarProvider,
} from '@fivano/core'
import { MobileNavigation } from './components/MobileNavigation'
import { NotFoundPage } from './legacy/NotFoundPage'
import { useSettings } from 'hooks/useSettings'
import { hasClaimLevel } from 'utils/hasClaimLevel'
import { useSelector } from 'hooks/useSelector'
import 'swiper/swiper.min.css'
import { Navigation } from './containers/Navigation'
import {
  DRAWER_WIDTH_CLOSED,
  DRAWER_WIDTH_OPEN,
} from './containers/Navigation/Navigation'
import { selectAppMode } from './containers/DevTools/appModeSlice'
import { legacyRoutes } from './router/legacyRoutes'
import { legacyMenu } from './containers/Navigation/legacyMenu'
import { legacyMobileMenu } from './containers/Navigation/legacyMobileMenu'
import { Router } from './router/Router'

const DivStyled = styled('div')(() => ({
  display: 'flex',
}))

const Root = styled('div', {
  shouldForwardProp: prop => prop !== 'menuOpen',
})<any>(({ theme, menuOpen }) => ({
  '.notistack-root': {
    [theme.breakpoints.up('sm')]: {
      left: `${
        (menuOpen ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSED) + 16
      }px !important`,
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '60px !important',
    },
  },
}))

/** App This component is the skeleton around the actual pages and should only
 * contain code that should be seen on all pages. (e.g. navigation bar) */
export function App() {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const mobileMenu = useMediaQuery(theme.breakpoints.down('sm'))
  const settings = useSettings()
  const profile = useSelector(state => state.firebase.profile)
  const appMode = useSelector(selectAppMode)
  document.body.style.backgroundColor = theme.fivano.palette.backgroundColor

  const menuWidth = mobileMenu
    ? '100vw'
    : `calc(100vw - ${open ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSED}px)`

  return (
    <BrowserRouter
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt for NavigationPrompt */
      }}
    >
      <Helmet
        titleTemplate={`%s - ${settings.applicationName}`}
        defaultTitle={`${settings.applicationName}`}
      >
        <meta
          name='description'
          content={`${settings.applicationName} - Fivano.com`}
        />
        <link
          rel='icon'
          href={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FB_PROJECT_ID}.appspot.com/o/logos%2Ffavicon.ico?alt=media`}
        ></link>
      </Helmet>
      <BrowserSupport />
      <ConfirmModalProvider>
        <Root menuOpen={open}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            classes={{
              containerAnchorOriginBottomLeft: 'notistack-root',
            }}
            maxSnack={5}
          >
            {appMode === 'app' ? (
              <Router
                settings={settings}
                mobileMenu={mobileMenu}
                open={open}
                setOpen={setOpen}
                menuWidth={menuWidth}
              />
            ) : (
              <DivStyled>
                <Navigation
                  onClickMenu={() => setOpen(!open)}
                  open={open}
                  mobileMenu={mobileMenu}
                  menuData={legacyMenu(settings)}
                />
                <div
                  style={{
                    width: menuWidth,
                  }}
                >
                  {mobileMenu && (
                    <MobileNavigation menuData={legacyMobileMenu(settings)} />
                  )}
                  <Switch>
                    {!profile.isEmpty &&
                      Object.entries(legacyRoutes).map(
                        ([key, route]) =>
                          hasClaimLevel(profile, route?.claims) && (
                            <PrivateRoute
                              key={key}
                              exact
                              claims={route?.claims}
                              path={route?.path}
                              // @ts-ignore
                              children={<route.component />}
                            />
                          ),
                      )}
                    {publicRoutes.map((route, index) => {
                      if (!route.disabled) {
                        return (
                          <Route
                            key={index}
                            exact
                            path={route.path}
                            // @ts-ignore
                            children={<route.component />}
                          />
                        )
                      } else {
                        return null
                      }
                    })}
                    <Route component={NotFoundPage} />
                  </Switch>
                </div>
              </DivStyled>
            )}
          </SnackbarProvider>
        </Root>
      </ConfirmModalProvider>
    </BrowserRouter>
  )
}

import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function MaritalStatusIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M15,14C12.3,14 7,15.3 7,18V20H23V18C23,15.3 17.7,14 15,14M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12M5,15L4.4,14.5C2.4,12.6 1,11.4 1,9.9C1,8.7 2,7.7 3.2,7.7C3.9,7.7 4.6,8 5,8.5C5.4,8 6.1,7.7 6.8,7.7C8,7.7 9,8.6 9,9.9C9,11.4 7.6,12.6 5.6,14.5L5,15Z'
      />
    </SvgIcon>
  )
}

import { ActionMenu, MenuButton } from '@fivano/core'
import { DataGridNew } from 'app/components/DataGridNew'
import useMongo from 'hooks/useMongo'
import { useEffect, useState } from 'react'
import {
  categoryColumns,
  categoryColumnsMobile,
} from '../DataGrid/categoryColumns'

export const ProductCategoriesList = ({ onClickEdit }) => {
  const [docs, setDocs] = useState([])
  const { useQueryDocs } = useMongo()

  const { docsData } = useQueryDocs({
    collection: 'productCategories',
  })
  useEffect(() => {
    if (docsData) {
      const emptyArray: any = Object.entries(docsData).map(
        ([_, value]) => value,
      )
      setDocs(emptyArray)
    }
  }, [docsData])

  // TODO: ARCHIVEREN VAN EEN CATEGORIE
  return (
    <DataGridNew
      name='productCategories'
      rows={docs}
      columns={categoryColumns}
      columnsMobile={categoryColumnsMobile}
      hideFooterSelectedRowCount={true}
      hideFooterRowCount={true}
      isEditable={true}
      rowActions={{
        renderCell: params => (
          <ActionMenu
            iconPosition='vertical'
            minWidth={250}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <MenuButton
              label='Bewerken'
              onClick={() => onClickEdit(params.row._id)}
            />
          </ActionMenu>
        ),
      }}
    />
  )
}

import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { PageContainer } from '@fivano/core'
import { Breadcrumbs } from 'app/components/Breadcrumbs'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { JobOpeningCard } from './JobOpeningCard'

const breadcrumbArray = [
  { title: 'Home', path: '/' },
  { title: 'Vacatures', path: '/vacatures-overzicht' },
]

export const JobOpeningsOverview = () => {
  const firestore = useFirestore()

  useEffect(() => {
    firestore.get({
      collection: 'jobOpenings',
      where: ['archivedJoop', '==', false],
      storeAs: 'jobOpeningsList',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const jobOpeningsData = useSelector(
    state => state.firestore.ordered.jobOpeningsList,
  )
  return (
    <PageContainer>
      <Breadcrumbs breadcrumbs={breadcrumbArray} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          filters
        </Grid>
        <Grid item container xs={12} md={9} spacing={1}>
          {jobOpeningsData?.length > 0 &&
            jobOpeningsData.map(jobData => (
              <Grid item key={jobData.id}>
                <JobOpeningCard data={jobData} />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </PageContainer>
  )
}

import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { styled } from '@mui/material'
import { LoadingOverlay } from '@fivano/core'
import { MobileNavigation } from 'app/components/MobileNavigation'
import { NotFoundPage } from 'app/legacy/NotFoundPage'
import { Navigation } from 'app/containers/Navigation'
import { AuthGuard } from 'app/router/AuthGuard'
import { menuList } from 'app/containers/Navigation/menuList'
import { routes } from 'app/router/routes'
import { menuMobile } from 'app/containers/Navigation/menuMobile'

const DivStyled = styled('div')(() => ({
  display: 'flex',
}))

export const Router = ({ settings, mobileMenu, open, setOpen, menuWidth }) => {
  return (
    <DivStyled>
      <Navigation
        onClickMenu={() => setOpen(!open)}
        open={open}
        mobileMenu={mobileMenu}
        menuData={menuList()}
      />
      <div
        style={{
          flexGrow: 1,
        }}
      >
        {mobileMenu && <MobileNavigation menuData={menuMobile(settings)} />}
        <AuthGuard>
          <Suspense
            fallback={
              <Switch>
                {Object.entries(routes).map(([key, route]) => (
                  <Route
                    key={key}
                    exact
                    path={route.path}
                    children={
                      route.skeleton ? (
                        // @ts-ignore
                        <route.skeleton />
                      ) : (
                        <LoadingOverlay label='Pagina laden' />
                      )
                    }
                  />
                ))}
              </Switch>
            }
          >
            <Switch>
              {Object.entries(routes).map(([key, route]) => (
                <Route
                  key={key}
                  exact
                  path={route.path}
                  // @ts-ignore
                  children={<route.component />}
                />
              ))}
              <Route component={NotFoundPage} />
            </Switch>
          </Suspense>
        </AuthGuard>
      </div>
    </DivStyled>
  )
}

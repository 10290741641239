import { isBefore } from 'date-fns'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { useSelector } from 'hooks/useSelector/useSelector'
import { useFirestoreConnect } from 'react-redux-firebase'
import { convertTimestampDate } from '@fivano/core'

type useTrackLocationOptionsProps = {
  participantID: string
  onlyActiveTracks: boolean
}

export const useTrackLocationOptions = ({
  participantID,
  onlyActiveTracks,
}: useTrackLocationOptionsProps) => {
  const locationQuery = useLocationQuery()
  useFirestoreConnect([
    {
      collectionGroup: 'tracks',
      limit: 50,
      where: [
        ['personIDTrack', '==', participantID],
        ...locationQuery('locationIDTrack'),
      ],
      orderBy: ['endDateTrack', 'desc'],
      storeAs: `persons_${participantID}_tracks`,
    },
  ])
  const trackData = useSelector(
    state => state.firestore.ordered[`persons_${participantID}_tracks`],
  )
  const trackOptions: any = []
  trackData?.forEach(option => {
    if (
      option.moduleTrack?.label !== undefined &&
      (onlyActiveTracks
        ? !isBefore(convertTimestampDate(option.endDateTrack), new Date())
        : true)
    ) {
      trackOptions.push({
        value: option.id,
        label: option.moduleTrack.label,
      })
    }
  })
  return trackOptions
}

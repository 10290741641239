import { useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'

type UploadTypes = {
  file: Blob | File
  path?: string
  filename?: string
}

export const useUpload = () => {
  const firebase = useFirebase()
  const [status, setStatus] = useState<any>()

  const upload = async ({ file, path, filename }: UploadTypes) =>
    new Promise((resolve, reject) => {
      const storageRef = firebase
        .storage()
        .ref(path)
        .child(filename || firestoreIDGenerator())
      const uploadTask = storageRef.put(file, {
        customMetadata: {
          emulatorPassword: `${process.env.REACT_APP_EMULATOR_PASSWORD}`,
        },
      })
      uploadTask.on('state_changed', snap => {
        setStatus(snap)
      })
      uploadTask.then(resolve).catch(reject)
    })

  return { upload, status }
}

import React from 'react'
import { styled } from '@mui/material/styles'
import {
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  SvgIconProps,
  ListItemButton,
} from '@mui/material'
const ListItemIconStyled = styled(ListItemIcon)(() => ({
  root: {
    minWidth: '42px',
  },
}))

type MenuButtonProps = {
  /**Visible label*/
  label: string
  /**Visible subtitle*/
  subtitle?: string
  /**Function that gets triggered when the button gets clicked*/
  onClick: React.MouseEventHandler<HTMLDivElement>
  /**Visible icon*/
  icon?: SvgIconProps
  /**Visible avatar*/
  avatar?: any
  /**Boolean whether the button is active or not*/
  disabled?: boolean
  /**Classnames for extra styling*/
  className?: string
}

export const MenuButton = ({
  label,
  subtitle,
  icon,
  onClick,
  avatar,
  disabled = false,
  className,
}: MenuButtonProps) => {
  return (
    <ListItemButton disabled={disabled} onClick={onClick} className={className}>
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      {icon && <ListItemIconStyled>{icon}</ListItemIconStyled>}
      <ListItemText primary={label} secondary={subtitle} />
    </ListItemButton>
  )
}

import React from 'react'

export const NavigationLogo = ({
  open,
  onClickMenu,
}: {
  open: boolean
  onClickMenu: () => void
}) => {
  return (
    <div
      onClick={onClickMenu}
      style={{
        padding: '8px 4px',
        textAlign: open ? 'left' : 'center',
        cursor: 'pointer',
      }}
    >
      <img
        src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FB_PROJECT_ID}.appspot.com/o/logos%2Flogo192.png?alt=media`}
        alt='logo-icon'
        style={{ width: 48, height: 'auto' }}
      />
      {open && (
        <img
          src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FB_PROJECT_ID}.appspot.com/o/logos%2Flogo-text-light.png?alt=media`}
          alt='logo-icon'
          style={{ width: 200, height: 'auto', marginBottom: 10 }}
        />
      )}
    </div>
  )
}

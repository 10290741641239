import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Typography, Box } from '@mui/material'
import { FileChips } from '@fivano/core'
import { UseFormReturn } from 'react-hook-form'

type FileInputProps = {
  name: string
  formObject: UseFormReturn
  multiple?: boolean
  maxSize?: number
  maxFiles?: number
  fileInputTitle?: string
  acceptedFileTypes?: string[]
  required?: boolean
}

export const FileInput = ({
  name,
  multiple = true,
  maxSize = 25,
  maxFiles = 10,
  fileInputTitle = 'Bestanden toevoegen',
  acceptedFileTypes,
  formObject: {
    register,
    setValue,
    watch,
    formState: {
      errors: { formErrors },
    },
  },
  required,
}: FileInputProps) => {
  const [errors, setErrors] = useState<String[]>([])
  const files: File[] = watch(name)

  const handleDrop = useCallback(
    droppedFiles => {
      const newErrors: String[] = []
      const newFiles: any[] = [...(files ? files : [])]

      const nameExists = (file, newFiles) => {
        return newFiles
          .map(file => file.customMetadata?.originalName || file.name)
          .some(name => name === file.name)
      }

      droppedFiles.forEach(file => {
        if (nameExists(file, newFiles)) {
          newErrors.push(`${file.name} is al toegevoegd.`)
        } else if (newFiles.length === maxFiles) {
          newErrors.push(`Maximaal ${maxFiles} bestanden.`)
        } else {
          newFiles.push(file)
        }
      })
      setErrors(newErrors)
      setValue(name, newFiles, { shouldValidate: true, shouldDirty: true })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxFiles, name, files],
  )
  const errorCodes = {
    'file-too-large': `is te groot, uploaden bestanden tot ${Math.floor(
      maxSize,
    )} mb.`,
    'file-invalid-type': `verkeerd bestandstype, kies een correct bestandstype`,
  }
  const fileErrorMessage = file => {
    const filename = file.file.name
    const errorCode = file.errors[0].code
    return `${filename}: ${errorCodes[errorCode]}`
  }
  const handleDropRejected = useCallback(
    files => {
      const errorsArray: any[] = []
      files.forEach(file => {
        const error = file.errors[0]
        if (error.code) {
          const errorMessage = fileErrorMessage(file)
          if (!errorsArray.includes(errorMessage)) {
            errorsArray.push(errorMessage)
          }
        }
      })
      setErrors(errorsArray)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxSize],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    onDropRejected: handleDropRejected,
    multiple: multiple,
    maxSize: maxSize * 1024 * 1024,
    accept: acceptedFileTypes,
  })
  const onDelete = fileToDelete => {
    console.log(name, files, fileToDelete)
    setValue(
      name,
      files.filter(file => file !== fileToDelete),
      { shouldDirty: true },
    )
  }

  useEffect(() => {
    register(name, { required })
  }, [register, name, required])

  useEffect(() => {
    if (formErrors?.[name]) {
      if (formErrors?.[name]?.type === 'required') {
        setErrors(['Verplicht veld'])
      }
    }
  }, [formErrors, name])
  return (
    <Box mb={1}>
      <div {...getRootProps()}>
        <Box height={48}>
          <Typography
            variant='body1'
            color={errors?.[name] ? 'error' : 'textSecondary'}
          >
            {fileInputTitle}
            {required && ' *'}
          </Typography>
          <Typography
            variant='caption'
            color={errors?.[name] ? 'error' : 'textSecondary'}
          >
            {isDragActive
              ? 'Upload bestanden door los te laten'
              : 'Sleep bestanden naar upload of klik en selecteer'}
          </Typography>
        </Box>
        <input id={name} {...getInputProps()} />
      </div>
      {errors?.map((error, index) => (
        <Typography
          variant='caption'
          color='error'
          key={index}
          style={{ marginLeft: 12 }}
        >
          {error}
        </Typography>
      ))}
      {files?.length > 0 && (
        <FileChips
          files={files}
          onDelete={onDelete}
          fileDownloadLinkKey='fullPath'
        />
      )}
    </Box>
  )
}

import React, { useState, useEffect } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'hooks/useSelector'
import { Typography, Box, Button } from '@mui/material'
import { AuthWrapper } from 'app/containers/Authentication/components/AuthWrapper'
import { useErrorLogger } from 'hooks/useErrorLogger'

export function RegisterVerification() {
  const firebase = useFirebase()
  const errorLogger = useErrorLogger()
  const history = useHistory()
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')

  const auth = useSelector(state => state.firebase.auth)
  useEffect(() => {
    if (auth.isLoaded && !auth.isEmpty && auth.uid !== undefined) {
      if (auth.emailVerified) {
        history.push('/')
      } else if (!auth.emailVerified) {
        history.push('/email-bevestigen')
      }
    }
  }, [auth, history])

  const handleSendEmailVerification = () => {
    firebase
      .auth()
      .currentUser?.sendEmailVerification()
      .then(response => {
        setMessage('Verificatie email opnieuw verzonden.')
      })
      .catch(error => {
        if (error === 'auth/missing-android-pkg-name') {
          setError(
            'Android fout pkg name ontbreekt, neem contact op met uw organisatie. ',
          )
        } else if (error === 'auth/missing-continue-uri') {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        } else if (error === 'auth/missing-ios-bundle-id') {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        } else if (error === 'auth/invalid-continue-uri') {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        } else if (error === 'auth/unauthorized-continue-uri') {
          setError(
            'Verificatie mail kon niet worden verzonden. Probeer het opnieuw.',
          )
        }
        errorLogger({ error })
      })
  }

  return (
    <>
      <Helmet>
        <title>Bevestig email</title>
      </Helmet>
      <AuthWrapper title='Email bevestigeren'>
        <Typography variant='body1' gutterBottom>
          Uw account is geregistreerd. Voordat u kunt inloggen moet uw email
          worden bevestigd.
        </Typography>
        <Box p={1} />
        <Typography variant='body1'>
          Bevestig uw email via de link die naar uw mail is verzonden.
        </Typography>
        <Box p={1} />

        <Typography variant='body1' color='error'>
          {error}
        </Typography>
        <Box p={1} />
        {message === '' ? (
          <Button
            onClick={handleSendEmailVerification}
            size='small'
            disableElevation
            fullWidth
          >
            Verificatie mail opnieuw versturen
          </Button>
        ) : (
          <Typography variant='body1'>{message}</Typography>
        )}
      </AuthWrapper>
    </>
  )
}

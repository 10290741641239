import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeThemeMode, selectThemeMode } from 'styles/theme/slice'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Grid, styled } from '@mui/material'
import { DarkModeSwitch } from '@fivano/core'
import { Logo } from 'app/components/Logo'
import { DevTools } from 'app/containers/DevTools'

type AuthWrapperProps = {
  title: string
  children: any
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  formPadding?: any
}

const BoxStyled = styled(Box)(() => ({
  minHeight: 480,
  position: 'relative',
}))

const WrapperDivStyled = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: theme.zIndex.modal,
  background: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  padding: '4px 8px',
}))

const LogoDivStyled = styled('div')(() => ({
  padding: 24,
  maxHeight: 200,
  maxWidth: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
export const AuthWrapper = ({
  title,
  children,
  maxWidth = 'xs',
  formPadding = 3,
}: AuthWrapperProps) => {
  const darkMode = useSelector(selectThemeMode)

  const dispatch = useDispatch()

  const handleThemeModeChange = () => {
    dispatch(changeThemeMode(!darkMode))
  }

  return (
    <WrapperDivStyled>
      <Container disableGutters maxWidth={maxWidth}>
        <LogoDivStyled>
          <Logo />
        </LogoDivStyled>
        <Paper>
          <BoxStyled p={formPadding}>
            <Typography variant='h5' gutterBottom align='center'>
              {title}
            </Typography>
            <Box p={1} />
            {children}
          </BoxStyled>
        </Paper>
        <Box m='8px 24px 32px 24px'>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box pt={1}>
                <Typography color='textSecondary'>Nederlands</Typography>
              </Box>
            </Grid>
            <Grid item xs={4} container justifyContent='flex-end'>
              <DarkModeSwitch
                value={darkMode}
                onChange={handleThemeModeChange}
                label={darkMode ? 'Donker' : 'Licht'}
              />
            </Grid>
          </Grid>
          {/** DevTools menu: only visible when developing*/}
          {process.env.REACT_APP_ENV === 'development' && <DevTools />}
        </Box>
      </Container>
    </WrapperDivStyled>
  )
}

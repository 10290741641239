import React, { useState } from 'react'
import { Paper, Grid, Box, styled } from '@mui/material'
import { ExpandButton } from '../AnimatedButtons'
import { AccordionBase } from '../AccordionBase'

const PaperStyled = styled(Paper)(() => ({
  position: 'relative',
  padding: 16,
}))

const BoxStyled = styled(Box)(() => ({
  zIndex: 1,
  position: 'absolute',
  right: 16,
  top: 16,
}))

const GridStyled = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}))

const ExpandGridStyled = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: '10px',
  },
}))

const WrapperGridStyled = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  marginLeft: 'auto',
}))

type HeaderInfoCardProps = {
  /**Content for the icon part of the header*/
  actionIconHeaderInfo?: JSX.Element
  /**Content on the left side of the header*/
  leftSideHeaderInfo?: JSX.Element
  /**Content on the right side of the header*/
  rightSideHeaderInfo?: JSX.Element
  /**Additional content on the left side of the header*/
  expandibleHeaderLeftInfo?: JSX.Element
  /**Additional content on the right side of the header*/
  expandibleHeaderRightInfo?: JSX.Element
  /**Content when the header has full width*/
  expandibleHeaderFullWidthInfo?: JSX.Element
}

export function HeaderInfoCard(props: HeaderInfoCardProps) {
  const {
    actionIconHeaderInfo,
    leftSideHeaderInfo,
    rightSideHeaderInfo,
    expandibleHeaderLeftInfo,
    expandibleHeaderRightInfo,
    expandibleHeaderFullWidthInfo,
  } = props

  const [expanded, setExpanded] = useState(false)

  return (
    <PaperStyled>
      {actionIconHeaderInfo && <BoxStyled>{actionIconHeaderInfo}</BoxStyled>}
      <Grid container>
        <AccordionBase
          expanded={expanded}
          visibleContent={
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <GridStyled container>{leftSideHeaderInfo}</GridStyled>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {rightSideHeaderInfo}
              </Grid>
            </Grid>
          }
          expandableContent={
            <>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  {expandibleHeaderLeftInfo}
                </Grid>
                <ExpandGridStyled item xs={12} sm={6}>
                  {expandibleHeaderRightInfo}
                </ExpandGridStyled>
              </Grid>
              {expandibleHeaderFullWidthInfo && (
                <Grid container style={{ marginTop: 20 }}>
                  <Grid item>{expandibleHeaderFullWidthInfo}</Grid>
                </Grid>
              )}
            </>
          }
        />
        <WrapperGridStyled item>
          <ExpandButton
            label={expanded ? 'Inklappen' : 'Uitklappen'}
            rotate={expanded}
            onClick={() => setExpanded(!expanded)}
          />
        </WrapperGridStyled>
      </Grid>
    </PaperStyled>
  )
}

import React, { useState } from 'react'
import {
  Grid,
  Typography,
  Box,
  Button,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import useMongo from 'hooks/useMongo'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Skeleton } from '@mui/material'
import { Form, Modal, ListView } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { FormInputsBuilder } from 'app/components/Form/FormInputsBuilder'

const listColumnsData = [
  {
    Header: ' ',
    canGroupBy: false,
    canSort: false,
    columns: [
      {
        Header: 'Naam',
        accessor: `name`,
        gridProps: {
          xs: 9,
          sm: 9,
          md: 9,
        },
      },
      {
        Header: 'Gearchiveerd',
        accessor: `archived`,
        gridProps: {
          xs: 3,
          sm: 3,
          md: 3,
        },
        Cell: data => (
          <Grid item>
            {data.row.original[`archived`] && <Chip label='Gearchiveerd' />}
          </Grid>
        ),
      },
    ],
  },
]

const CategoryForm = ({ onCloseForm, editingDocID }) => {
  const { createDoc, updateDoc, useRefetchDoc } = useMongo()
  const errorLogger = useErrorLogger()
  const updateCategory = async data => {
    return await updateDoc({
      collection: 'serviceCategories',
      docID: editingDocID,
      data,
    })
  }
  const createCategory = async data => {
    return await createDoc({ collection: 'serviceCategories', data })
  }

  const { status, docData } = useRefetchDoc({
    collection: 'serviceCategories',
    docID: editingDocID,
  })

  if (status === 'loading') {
    return (
      <Modal maxWidth='sm' open>
        <Skeleton height={40} />
      </Modal>
    )
  }

  return (
    <Form
      onCloseForm={onCloseForm}
      wrapInside='modal'
      modalMaxWidth='sm'
      hasDetails={true}
      docLabel='Categorie'
      updateDoc={updateCategory}
      createDoc={createCategory}
      editingDocID={editingDocID}
      docData={docData}
      onError={errorLogger}
      formInputs={({ formObject, showAllFields }) => {
        const inputsConfig = [
          {
            type: 'FormRow',
            content: [
              {
                type: 'TextField',
                props: {
                  name: 'name',
                  label: 'Naam',
                  rules: { required: 'Verplicht veld' },
                },
              },
            ],
          },
          {
            type: 'FormRow',
            props: { showAllFields },
            content: [
              {
                type: 'Switch',
                props: { name: 'archived', label: 'Archiveren' },
              },
            ],
          },
        ]
        return (
          <FormInputsBuilder
            inputsConfig={inputsConfig}
            formObject={formObject}
          />
        )
      }}
    />
  )
}

export const ServiceCategories = () => {
  const [categoryForm, setCategoryForm] = useState({
    categoryID: undefined,
    open: false,
  })
  const { useQueryDocs } = useMongo()

  const { docsData } = useQueryDocs({
    collection: 'serviceCategories',
  })

  const listData = Object.values(docsData)
  return (
    <>
      {categoryForm.open && (
        <CategoryForm
          editingDocID={categoryForm.categoryID}
          onCloseForm={() =>
            setCategoryForm({
              categoryID: undefined,
              open: false,
            })
          }
        />
      )}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box>
            <Typography variant='h6'>Categorieën</Typography>
            <Typography marginLeft='12px' variant='caption'>
              Voeg categorieën toe om de diensten in te kunnen onderverdelen.
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box width='100%'>
            <Box display='flex' flexDirection='row-reverse' marginBottom={1}>
              <Button
                color='primary'
                variant='contained'
                onClick={() =>
                  setCategoryForm({ open: true, categoryID: undefined })
                }
              >
                Categorie toevoegen
              </Button>
            </Box>
            {listData && listData.length > 0 ? (
              <ListView
                onClick={(_, data) =>
                  setCategoryForm({
                    categoryID: data.original._id,
                    open: true,
                  })
                }
                data={listData
                  ?.slice()
                  // @ts-ignore
                  ?.sort((x, y) => x[`archived`] - y[`archived`])}
                columns={listColumnsData}
              />
            ) : (
              <Box pl={3} pr={3} pt={2} maxWidth={600}>
                <Typography>Er zijn nog geen categorieën toegevoegd</Typography>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

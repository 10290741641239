// secondsToTimeString.ts
export const secondsToTimeString = seconds => {
  return new Date(seconds * 1000).toISOString().substr(11, 5)
}

// Seconds to HH:mm string
export const secondsArrayToTimeString = array => {
  const newArray: Array<any> = []
  array?.forEach(item => {
    newArray.push(new Date(item * 1000).toISOString().substr(11, 5))
  })
  return newArray
}

// Seconds to HHH:mm string
export const secondsToCustomTimeString = array => {
  const newArray: Array<any> = []
  array.forEach(item => {
    var h = Math.floor(item / 3600)
    var m = Math.floor((item % 3600) / 60)

    newArray.push((h > 9 ? '' : '0') + h + ':' + ('0' + m).slice(-2))
  })
  return newArray
}

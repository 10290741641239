import {
  Checkbox,
  DatePicker,
  FormPaper,
  FormRow,
  NEWFileInput,
  Switch,
  TextField,
  RadioGroup,
} from '@fivano/core'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { AutoCompleteV2 } from '../AutoComplete'

import TitleIcon from '@mui/icons-material/Title'
import CategoryIcon from '@mui/icons-material/Category'
import PublicIcon from '@mui/icons-material/Public'
import SubjectIcon from '@mui/icons-material/Subject'
import EuroIcon from '@mui/icons-material/Euro'
import LocationIcon from 'app/icons/LocationIcon'
import IdentificationIcon from 'app/icons/Identification'
import WcIcon from '@mui/icons-material/Wc'
import RoomIcon from '@mui/icons-material/Room'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import MaritalStatusIcon from 'app/icons/MaritalStatusIcon'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { AccountBalance } from '@mui/icons-material'

const renderIcon = iconName =>
  ({
    title: <TitleIcon />,
    category: <CategoryIcon />,
    subject: <SubjectIcon />,
    public: <PublicIcon />,
    euro: <EuroIcon />,
    location: <LocationIcon />,
    identity: <IdentificationIcon />,
    gender: <WcIcon />,
    address: <RoomIcon />,
    phone: <PhoneIcon />,
    email: <EmailIcon />,
    maritalStatus: <MaritalStatusIcon />,
    education: <AccountBalance />,
    credit: <CreditCardIcon />,
  }[iconName] || '')

export const FormInputsBuilder = ({ inputsConfig, formObject }) => {
  const buildJSX = (input, index) => {
    if (input.hide) {
      return
    } else {
      switch (input.type) {
        case 'FormPaper':
          return (
            <FormPaper key={index}>
              {input?.content &&
                input.content.map((input, index) => buildJSX(input, index))}
            </FormPaper>
          )
        case 'FormRow':
          return (
            <FormRow
              key={index}
              {...input.props}
              icon={input?.icon && renderIcon(input.icon)}
            >
              {input?.content &&
                input.content.map((input, index) => buildJSX(input, index))}
            </FormRow>
          )
        case 'Grid':
          return (
            <Grid key={index} {...input.props}>
              {input?.content &&
                input.content.map((input, index) => buildJSX(input, index))}
            </Grid>
          )
        case 'Typography':
          return (
            <Typography {...input.props} key={index}>
              {input.title}
            </Typography>
          )
        case 'TextField':
          return (
            <TextField {...input.props} key={index} formObject={formObject} />
          )
        case 'AutoComplete':
          return (
            <AutoCompleteV2
              {...input.props}
              key={index}
              formObject={formObject}
            />
          )
        case 'DatePicker':
          return (
            <DatePicker {...input.props} key={index} formObject={formObject} />
          )
        case 'Checkbox':
          return (
            <Checkbox {...input.props} key={index} formObject={formObject} />
          )
        case 'Switch':
          return <Switch {...input.props} key={index} formObject={formObject} />
        case 'FileInput':
          return (
            <NEWFileInput
              {...input.props}
              key={index}
              formObject={formObject}
            />
          )
        case 'RadioGroup':
          return (
            <RadioGroup {...input.props} key={index} formObject={formObject} />
          )
        case 'Custom':
          return <React.Fragment key={index}>{input.content}</React.Fragment>

        default:
          return <div>Error FormInPutsBuilder: Component not found.</div>
      }
    }
  }

  return inputsConfig.map((input, index) => buildJSX(input, index))
}

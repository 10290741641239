import {
  DialogContent,
  useTheme,
  useMediaQuery,
  styled,
  Grid,
  Typography,
} from '@mui/material'

import { Modal } from '@fivano/core'
import useMongo from 'hooks/useMongo'

import PoolIcon from '@mui/icons-material/Pool'
import SchoolIcon from '@mui/icons-material/School'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import CakeIcon from '@mui/icons-material/Cake'
import AddIcon from '@mui/icons-material/Add'

export const CategoryForm = ({ layoutDoc, onClose, onSubmitSucces }) => {
  const { useQueryDocs, createDoc, updateDoc } = useMongo()

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  const currentIDs = layoutDoc.categories?.map(item => item.id)

  const isEmptyObject = objectValue =>
    objectValue &&
    Object.keys(objectValue).length === 0 &&
    objectValue.constructor === Object

  const { docsData } = useQueryDocs({
    collection: 'productCategories',
    array: true,
    ...(!isEmptyObject(layoutDoc)
      ? {
          query: {
            _id: { $nin: currentIDs },
          },
        }
      : {}),
  })

  const onSubmit = data => {
    if (!layoutDoc?._id) {
      const newLayout = {
        name: 'test',
        categories: [
          {
            id: data._id,
            products: [],
          },
        ],
      }

      createDoc({ collection: 'pointOfSaleLayout', data: newLayout }).then(
        response => {
          console.log(response)
          onSubmitSucces(response, response.docs[0]._id)
        },
      )
    } else {
      const updateLayout = { ...layoutDoc }
      updateLayout.categories = [
        ...updateLayout.categories,
        { id: data._id, products: [] },
      ]

      updateDoc({
        collection: 'pointOfSaleLayout',
        docID: updateLayout._id,
        data: updateLayout,
      }).then(response => {
        onSubmitSucces(response, layoutDoc._id)
      })
    }
  }

  return (
    <Modal
      open={true}
      onClose={onClose}
      closeButton
      title='Categorie toevoegen'
      customHeight={mobile ? '100vh' : 'auto'}
    >
      <>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {docsData.length > 0 ? (
              docsData.map(doc => {
                return (
                  <Grid
                    item
                    xs={3}
                    key={doc._id}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => onSubmit(doc)}
                  >
                    <CategoryCard doc={doc} />
                  </Grid>
                )
              })
            ) : (
              <Typography>Geen Categorieën beschikbaar</Typography>
            )}
          </Grid>
        </DialogContent>
      </>
    </Modal>
  )
}

const CardWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'hover',
})<any>(({ theme, active, hover }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 20,
  height: '100%',
  backgroundColor: active
    ? theme.palette.primary.main
    : theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  borderRadius: 4,
}))

const renderIcon = iconName =>
  ({
    pool: <PoolIcon />,
    school: <SchoolIcon />,
    subscription: <PersonOutlineIcon />,
    party: <CakeIcon />,
    add: <AddIcon />,
  }[iconName] || '')

const CategoryCard = ({ doc }) => {
  return (
    <CardWrapper>
      <div style={{ margin: 'auto 0', textAlign: 'center' }}>
        {doc?.icon && renderIcon(doc.icon)}
        <Typography variant='h6'>{doc?.name}</Typography>
      </div>
    </CardWrapper>
  )
}

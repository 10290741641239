import React from 'react'
import { Grid } from '@mui/material'
import { firestoreIDGenerator } from 'utils/firestoreIDGenerator'
import { keywordsCreator } from 'utils/keywordsCreator'
import { emailRegex } from 'utils/regex'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { TextField, Form } from '@fivano/core'

type UserAddFromProps = {
  id: string
  firstNamePers: string
  middleNamePers: string
  lastNamePers: string
  emailPers: string
  keywordsPers: string[]
}

type UserAddFormProps = {
  onClose?: any
  closeButton?: boolean
}

export const UserAddForm = ({ onClose }: UserAddFormProps) => {
  const handleFormSubmit = async (data: UserAddFromProps) => {
    data.keywordsPers = keywordsCreator([
      data.firstNamePers,
      data.middleNamePers,
      data.lastNamePers,
    ])
  }

  const errorLogger = useErrorLogger()

  return (
    <>
      <Form
        wrapInside='modal'
        modalMaxWidth='sm'
        hasDetails={false}
        docLabel='Gebruiker'
        updateDoc={async () => {}}
        createDoc={handleFormSubmit}
        editingDocID={''}
        docData={{}}
        onCloseForm={onClose}
        onError={errorLogger}
        formInputs={({ formObject }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                name='firstNamePers'
                label='Voornaam'
                rules={{ required: true }}
                formObject={formObject}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name='middleNamePers'
                label='Tussenvoegsel'
                rules={{ required: true }}
                formObject={formObject}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='lastNamePers'
                label='Achternaam'
                rules={{ required: true }}
                formObject={formObject}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='emailPers'
                label='Email gebruiker'
                rules={{ required: true, pattern: emailRegex }}
                formObject={formObject}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='id'
                label='UID gebruiker'
                rules={{ required: true }}
                formObject={formObject}
                defaultValue={`${firestoreIDGenerator()}${firestoreIDGenerator()}`.slice(
                  0,
                  28,
                )}
              />
            </Grid>
          </Grid>
        )}
      />
    </>
  )
}

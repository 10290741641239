import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { DialogContent } from '@mui/material'
import { LoadingOverlay } from '../LoadingOverlay'
import { FileChanges, FormContentProps } from './formTypes'
import { useFilesDelete } from '../../hooks/useFilesDelete'
import { RHFDevTool } from './RHFDevTool'
import { NavigationPrompt } from '../NavigationPrompt/NavigationPrompt'
import { FormModalActions, FormPageActions } from './FormActions'
import { useConfirmModal, FilesWillBeDeletedWarning } from '../ConfirmModal'
import { PageContainer } from '../PageContainer'

const getFormDirty = ({ formObject, formUpdated, fileChanges }) =>
  Object.entries(formObject.formState.dirtyFields).length > 0 ||
  fileChanges.filesToDelete.length > 0 ||
  fileChanges.filesUploaded.length > 0 ||
  formUpdated

const setEmptyFields = (modelConfig, data) =>
  Object.keys(modelConfig.fields).reduce((acc, key) => {
    return {
      ...acc,
      [key]:
        data[key] ||
        (Array.isArray(modelConfig.fields[key])
          ? []
          : modelConfig.fields[key].name === 'Boolean'
          ? false
          : null),
    }
  }, {})

export const FormContent = ({
  onCloseForm,
  hasDetails,
  buildDocData,
  updateDoc,
  createDoc,
  modelConfig,
  editingDocID,
  docData,
  formUpdated = false,
  onCreateSuccess,
  onUpdateSuccess,
  wrapInside,
  formInputs,
  onError,
  labels,
  disablePrompt = false,
  setIsDirty,
  deleteFirestoreDoc,
}: FormContentProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const confirmModal = useConfirmModal()
  const deleteFiles = useFilesDelete({ onError })

  const isEditing = Boolean(editingDocID)

  const fileChangesState = useState<FileChanges>({
    uploadingFilesFor: [],
    filesUploaded: [],
    filesToDelete: [],
  })

  const [fileChanges, setFileChanges] = fileChangesState

  const showAllFieldsState = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')

  const formObject = useForm({
    defaultValues: docData,
  })
  const formDirty = getFormDirty({
    formObject,
    formUpdated,
    fileChanges,
  })
  useEffect(() => {
    setIsDirty(formDirty)
  }, [formDirty, setIsDirty])

  const submit = readyData =>
    isEditing ? updateDoc(readyData) : createDoc(readyData)

  const submitForm = async data => {
    let newDocData = buildDocData ? await buildDocData(data) : data

    if (modelConfig) newDocData = setEmptyFields(modelConfig, newDocData)

    setLoadingMessage(labels.deletingFiles)

    await deleteFiles({
      filesToDelete: fileChanges.filesToDelete,
      deleteFirestoreDoc: deleteFirestoreDoc,
    })

    setFileChanges({
      uploadingFilesFor: [],
      filesToDelete: [],
      filesUploaded: [],
    })

    setLoadingMessage(labels.mutatingLabel)

    submit(newDocData)
      .then(response => {
        formObject.reset(data)

        isEditing
          ? onUpdateSuccess && onUpdateSuccess(response)
          : onCreateSuccess && onCreateSuccess(response)

        enqueueSnackbar(labels.mutatedLabel, {
          variant: 'success',
        })
        setLoadingMessage('')
        onCloseForm && wrapInside === 'modal' && onCloseForm()
      })
      .catch(error => {
        onError({
          error,
          snackbarMessage: `${labels.mutatingLabel} ${labels.failed}. ${
            error?.message || error
          }`,
        })
      })
  }

  const submitCheck = async data => {
    if (fileChanges.filesToDelete.length > 0) {
      confirmModal({
        title: labels.warningMessage,
        confirmLabel: labels.saving,
        cancelLabel: labels.abortSave,
        content: (
          <FilesWillBeDeletedWarning
            files={fileChanges.filesToDelete}
            labels={labels}
          />
        ),
        onConfirm: async () => {
          await submitForm(data)
        },
      })
    } else {
      await submitForm(data)
    }
  }

  const onSubmit = formObject.handleSubmit(submitCheck)

  const beforeCancelForm = async () => {
    await deleteFiles({
      filesToDelete: fileChanges.filesUploaded,
    })
    setFileChanges(state => ({
      ...state,
      filesUploaded: [],
    }))
  }

  // const preventSubmitOnEnter = e => e.preventDefault()

  const cancelAndCloseForm = async () => {
    await beforeCancelForm()
    onCloseForm()
  }

  const onCancel = async () => {
    if (formDirty) {
      confirmModal({
        title: labels.closeText,
        confirmLabel: labels.closing,
        cancelLabel: labels.staying,
        message: isEditing ? labels.closeWarningEdit : labels.closeWarning,
        content: (
          <FilesWillBeDeletedWarning
            files={fileChanges.filesUploaded}
            labels={labels}
          />
        ),
        onConfirm: cancelAndCloseForm,
      })
    } else {
      cancelAndCloseForm()
    }
  }
  const enableSubmitOnEnter = false
  return (
    <>
      {!disablePrompt && (
        <NavigationPrompt
          fileChanges={fileChanges}
          isEditing={isEditing}
          open={formDirty}
          beforeCancelForm={beforeCancelForm}
        />
      )}
      {loadingMessage && <LoadingOverlay label={loadingMessage} />}
      {process.env.REACT_APP_ENV === 'development' && (
        <RHFDevTool control={formObject.control} />
      )}
      {/** PAGE MODE */}
      {wrapInside === 'page' && (
        <>
          <FormPageActions
            uploadingFilesFor={fileChanges.uploadingFilesFor}
            onSubmit={onSubmit}
            hasDetails={hasDetails}
            showAllFieldsState={showAllFieldsState}
            formDirty={formDirty}
            isEditing={isEditing}
            onCloseForm={onCloseForm}
            title={labels.mutatingLabel}
            labels={labels}
          />
          <PageContainer sx={{ paddingTop: '16px' }}>
            <form
              onSubmit={e =>
                enableSubmitOnEnter ? onSubmit(e) : e.preventDefault()
              }
            >
              {!loadingMessage &&
                formInputs({
                  loadingMessage,
                  fileChangesState,
                  formObject: formObject,
                  showAllFields: showAllFieldsState[0],
                })}
            </form>
          </PageContainer>
        </>
      )}
      {/** PAGE MODE */}
      {wrapInside === 'modal' && (
        <>
          <DialogContent dividers sx={{ padding: '16px' }}>
            <form
              onSubmit={e =>
                enableSubmitOnEnter ? onSubmit(e) : e.preventDefault()
              }
            >
              {!loadingMessage &&
                formInputs({
                  fileChangesState,
                  formObject: formObject,
                  showAllFields: showAllFieldsState[0],
                })}
            </form>
          </DialogContent>
          <FormModalActions
            uploadingFilesFor={fileChanges.uploadingFilesFor}
            onSubmit={onSubmit}
            hasDetails={hasDetails}
            showAllFieldsState={showAllFieldsState}
            formDirty={formDirty}
            isEditing={isEditing}
            onCloseForm={onCancel}
            labels={labels}
          />
        </>
      )}
      {/** SIMPLE MODE */}
      {wrapInside === 'formElement' && (
        <form
          onSubmit={e =>
            enableSubmitOnEnter ? onSubmit(e) : e.preventDefault()
          }
        >
          {formInputs({
            fileChangesState,
            formObject: formObject,
            showAllFields: showAllFieldsState[0],
            onSubmit,
          })}
        </form>
      )}
    </>
  )
}

import React from 'react'
import { Form } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'
import { FormInputsBuilder } from 'app/components/Form/FormInputsBuilder'
import { useTrackLocationOptions } from 'hooks/useTrackLocationOptions'
import { useSelector } from 'hooks/useSelector'

export const ParticipantDocumentForm = ({ participantID, onCloseForm }) => {
  const profile = useSelector(state => state.firebase.profile)

  const createDocument = async data => {}
  const updateDocument = async data => {}
  const errorLogger = useErrorLogger()

  const trackOptions = useTrackLocationOptions({
    participantID,
    onlyActiveTracks: true,
  })
  const personTracks = useSelector(
    state => state.firestore.ordered[`persons_${participantID}_tracks`],
  )

  const trackOptionsObject = {}
  trackOptions.forEach(track => {
    trackOptionsObject[track.value] = track.label
  })

  const getLocationID = ({ personTracks, trackID }) => {
    const track = personTracks?.find(track => trackID === track.id)
    return track?.locationTrack.value
  }

  const inputsConfig = ({ formObject, fileChangesState }) => {
    const locationID = getLocationID({
      personTracks,
      trackID: formObject.watch('track'),
    })
    return [
      {
        type: 'FormRow',
        content: [
          {
            type: 'AutoComplete',
            props: {
              name: 'track',
              label: 'Traject',
              readOnly: formObject.watch('track'),
              rules: { required: 'Verplicht veld' },
              staticOptions: trackOptionsObject,
            },
          },
          {
            type: 'Typography',
            variant: 'caption',
            title: `${
              formObject.watch('track')
                ? 'Heropen dit formulier om het traject aan te passen.'
                : 'Het document zal onder dit traject worden toegevoegd.'
            }`,
          },
        ],
      },
      {
        type: 'FormRow',
        hide: !formObject.watch('track'),
        content: [
          {
            type: 'FileInput',
            props: {
              name: 'file',
              label: 'Document',
              required: true,
              onError: errorLogger,
              profile,
              multiple: true,
              maxFiles: 10,
              fileChangesState,
              customDocumentData: {
                personID: participantID,
                locationID: locationID,
                category: 'document',
              },
              docExtension: 'Docu',
              firestoreDoc: true,
              filesStorePath: `locations/${locationID}/persons/${participantID}/documents`,
            },
          },
        ],
      },
    ]
  }

  return (
    <Form
      onError={errorLogger}
      hasDetails={false}
      docLabel='Deelnemerdocument'
      docData={undefined}
      updateDoc={updateDocument}
      wrapInside='modal'
      modalMaxWidth='sm'
      createDoc={createDocument}
      onCloseForm={onCloseForm}
      formInputs={({ formObject, fileChangesState }) => (
        <FormInputsBuilder
          inputsConfig={inputsConfig({ formObject, fileChangesState })}
          formObject={formObject}
        />
      )}
    />
  )
}

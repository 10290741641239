import React, { useState } from 'react'
import {
  Checkbox,
  Typography,
  Grid,
  capitalize,
  Box,
  Link,
} from '@mui/material'
import { roleOptions } from '@fivano/models'
import { useController } from 'react-hook-form'

export const RoleInput = ({ name, formObject, model }) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control: formObject.control,
    defaultValue: [],
  })

  const [showOnlyOwn, setShowOnlyOwn] = useState(
    value.some(role => role.includes('_OwnOnly')),
  )

  const toOwnOnly = role => role + '_OwnOnly'

  const toggleExtraOptions = () => {
    onChange(value.map(role => role.replace('_OwnOnly', '')))
    setShowOnlyOwn(!showOnlyOwn)
  }

  const handleCheck = role =>
    onChange(
      value.includes(role) || value.includes(toOwnOnly(role))
        ? value.filter(i => i !== role && i !== toOwnOnly(role))
        : [...value, role],
    )

  const toggleOwnOnly = role => {
    const newValue = value.includes(toOwnOnly(role))
      ? [...value.filter(i => i !== toOwnOnly(role)), role]
      : [...value.filter(i => i !== role), toOwnOnly(role)]
    onChange(newValue)
  }

  return (
    <React.Fragment>
      <Grid item xs={12} md={4} sx={{ padding: '12px 16px 12px 0px' }}>
        <Typography variant='body1'>
          {capitalize(model.labels.plural)}
        </Typography>
        <Typography variant='caption'>{model.description}</Typography>
        <Link
          component='button'
          sx={{ display: 'block' }}
          variant='caption'
          onClick={e => {
            e.preventDefault()
            toggleExtraOptions()
          }}
        >
          {showOnlyOwn ? 'Verwijder extra opties' : 'Extra opties'}
        </Link>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box pt={2}>
          <Grid container direction='row'>
            {Object.entries(roleOptions)?.map(([role, label]) => {
              const isChecked =
                value.includes(role) || value.includes(toOwnOnly(role))
              return (
                <Grid item lg={2} md={3} xs={4} key={role}>
                  <Grid container direction='column'>
                    <Grid item>
                      <Checkbox
                        color='primary'
                        onChange={() => handleCheck(role)}
                        checked={isChecked}
                      />
                      <Typography align='center' component='span'>
                        {label}
                      </Typography>
                    </Grid>

                    {showOnlyOwn && (
                      <Grid item sx={{ marginTop: -1, marginLeft: '4px' }}>
                        <Checkbox
                          color='primary'
                          size='small'
                          disabled={!isChecked}
                          onChange={() => toggleOwnOnly(role)}
                          checked={value?.includes(toOwnOnly(role))}
                        />
                        <Typography
                          color={!isChecked ? 'GrayText' : undefined}
                          variant='caption'
                        >
                          Alleen eigen
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Grid>
    </React.Fragment>
  )
}

import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { Grid } from '@mui/material'
import { useModalRoute } from 'hooks/useModalRoutes'
import useMongo from 'hooks/useMongo'
import { deepCopy } from 'utils/deepCopy'
import { Draggable } from './Draggable'
import { Droppable } from './Droppable'
import { ProductCard } from './ProductCard'
import { ProductForm } from './ProductForm'

export const ProductsLayout = ({
  layoutDoc,
  activeID,
  items,
  rows,
  columns,
  onDragEnd,
  onRefetch,
}) => {
  const { updateDoc } = useMongo()
  const { modalData, closeModal, openModal } = useModalRoute()

  const handleDragEnd = (event, activeID, layout) => {
    const { active, over } = event

    if (!items?.find(item => item?.id === over?.id) && over) {
      const newPositions = changePosition(items, active, over)
      const newLayout = deepCopy(layout)
      const index = layout.categories.findIndex(x => x.id === activeID)

      newLayout.categories[index].products = newPositions

      onDragEnd(newPositions)
      updateDoc({
        collection: 'pointOfSaleLayout',
        docID: newLayout._id,
        data: newLayout,
      }).then(() => {
        onRefetch(newLayout._id)
      })
    }
  }

  const deleteCard = (data, layout) => {
    const newLayout = deepCopy(layout)

    const index = newLayout.categories.findIndex(x => x.id === activeID)
    const productIndex = newLayout.categories[index].products.findIndex(
      x => x.productID === data.productID,
    )

    newLayout.categories[index].products.splice(productIndex, 1)

    updateDoc({
      collection: 'pointOfSaleLayout',
      docID: newLayout._id,
      data: newLayout,
    }).then(() => {
      onRefetch(newLayout._id)
    })
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 100, tolerance: 5 },
    }),
  )

  return (
    <>
      {modalData.open && (
        <ProductForm
          layoutDoc={layoutDoc}
          id={modalData.id}
          activeID={activeID}
          onClose={() => closeModal()}
          onSubmitSucces={(_, layoutID) => {
            onRefetch(layoutID)
            closeModal()
          }}
        />
      )}
      <DndContext
        onDragEnd={event => handleDragEnd(event, activeID, layoutDoc)}
        sensors={sensors}
        modifiers={[restrictToWindowEdges]}
      >
        <Grid container spacing={2}>
          {[...Array(columns * rows)].map((_, index) => {
            const item = items?.find(item => item.id === `${index + 1}`)
            return (
              <Grid
                key={`${index + 1}`}
                item
                // @ts-ignore
                xs={12 / columns}
              >
                <Droppable
                  id={`${index + 1}`}
                  createItem={id => {
                    openModal(id)
                  }}
                  isEmpty={!item}
                  height={130}
                >
                  {item && (
                    <Draggable data={item}>
                      <ProductCard
                        data={item}
                        onDelete={data => deleteCard(data, layoutDoc)}
                      />
                    </Draggable>
                  )}
                </Droppable>
              </Grid>
            )
          })}
        </Grid>
      </DndContext>
    </>
  )
}

const changePosition = (items, active, over) => {
  const newItems = deepCopy(items)
  const draggedIndex = newItems.findIndex(item => item.id === active.id)
  const draggedItem = newItems.find(item => item.id === active.id)

  draggedItem.id = over.id
  newItems[draggedIndex] = draggedItem

  return newItems
}

import React from 'react'
import { Form, TextField } from '@fivano/core'
import useMongo from 'hooks/useMongo'
import { personsConfig } from '@fivano/models'
import { AutoCompleteV2 } from 'app/components/AutoComplete'
import { Grid } from '@mui/material'

export const UserSettingsForm = ({ personID, onCloseForm }) => {
  const { updateDoc, useRefetchDoc, createDoc } = useMongo()

  const createPerson = async data =>
    await createDoc({ collection: 'persons', data })

  const updatePerson = async data =>
    updateDoc({ collection: 'persons', docID: personID, data })

  const { status, docData } = useRefetchDoc({
    collection: 'persons',
    docID: personID,
    disabled: !personID,
  })

  const buildPerson = data => {
    return data
  }

  return (
    <Form
      modelConfig={personsConfig}
      loading={status === 'loading'}
      onCloseForm={onCloseForm}
      docData={docData}
      hasDetails={false}
      editingDocID={personID}
      updateDoc={updatePerson}
      createDoc={createPerson}
      buildDocData={buildPerson}
      wrapInside='modal'
      docLabel='Mongo Person'
      modalMaxWidth='sm'
      onError={error => console.log(error)}
      formInputs={({ formObject }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              formObject={formObject}
              name='firstName'
              label='Voornaam'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              formObject={formObject}
              name='lastName'
              label='Achternaam'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              formObject={formObject}
              name='uid'
              label='Google Auth ID'
            />
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteV2
              name='roleProfiles'
              multiple={true}
              formObject={formObject}
              label='Rollen'
              optionsCollection='roleProfiles'
            />
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteV2
              name='workspaces'
              multiple={true}
              formObject={formObject}
              label='Workspaces'
              optionsCollection='workspaces'
            />
          </Grid>
        </Grid>
      )}
    />
  )
}

// timeStringToSeconds.ts
import { parse } from 'date-fns'
export const timeStringToSeconds = timeString => {
  const timeDate = parse(timeString, 'HH:mm', new Date())
  return timeDate.getHours() * 60 * 60 + timeDate.getMinutes() * 60
}

export const timeArrayStringsToSeconds = timeStringsArray => {
  const emptyArray: Array<any> = []
  timeStringsArray.forEach(item => {
    const timeDate = parse(item, 'HH:mm', new Date())
    const seconds = timeDate.getHours() * 60 * 60 + timeDate.getMinutes() * 60
    if (!isNaN(seconds)) {
      emptyArray.push(seconds)
    } else {
      emptyArray.push(0)
    }
  })
  return emptyArray
}

import React from 'react'
import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { capitalize, Grid } from '@mui/material'
import { useDataStandards } from 'hooks/useDataStandards'
import { TextField, FormRow, Form, AutoSelect } from '@fivano/core'
import { AutoComplete } from 'app/components/AutoComplete'
import { Subject, DynamicFeed, Timelapse, Business } from '@mui/icons-material'
import { useSettings } from 'hooks/useSettings'
import { useErrorLogger } from 'hooks/useErrorLogger'
import {
  ModuleBuildTypes,
  ModuleDatastandardTypes,
  ModuleFormTypes,
} from './ModuleTypes'

export const ModuleForm = ({
  onCloseForm,
  wrapInside,
  modalMaxWidth,
  editingDocID,
}) => {
  const firestore = useFirestore()
  const isEditing = !!editingDocID
  const settings = useSettings()
  const labels = settings.tracks.labels
  const errorLogger = useErrorLogger()

  const moduleFormConfig = {
    docLabel: capitalize(labels.track),
    defaultValues: {
      codeModu: '',
      descriptionModu: '',
      nameModu: '',
      durationUnitModu: { value: 'months', label: 'Maanden' },
      archivedModu: false,
      fromDateModu: new Date(),
      untilDateModu: null,
    },
  }

  const updateModule = async data => {
    await firestore.update(`modules/${editingDocID}`, data)
  }

  const createModule = async data => {
    await firestore.collection('modules').add(data)
  }

  const moduleData: ModuleFormTypes = useSelector(({ firestore: { data } }) => {
    if (editingDocID) {
      return data?.modules?.[editingDocID]
    }
  })

  const createDataStandards = useDataStandards()

  const buildModuleData = (data: ModuleBuildTypes) => {
    isEditing &&
      (data.nameModu = moduleData.nameModu) &&
      (data.codeModu = moduleData.codeModu)

    const dataStandards: ModuleDatastandardTypes = createDataStandards({
      data,
      dataName: 'Modu',
      editForm: isEditing,
      keywords: [data.nameModu, data.codeModu],
    })
    data.archivedModu = false
    return { ...data, ...dataStandards }
  }

  const durationUnitOptions = [
    { value: 'years', label: 'Jaren' },
    { value: 'months', label: 'Maanden' },
    { value: 'weeks', label: 'Weken' },
    { value: 'days', label: 'Dagen' },
  ]

  return (
    <Form
      docLabel={moduleFormConfig.docLabel}
      editingDocID={editingDocID}
      buildDocData={buildModuleData}
      updateDoc={updateModule}
      createDoc={createModule}
      wrapInside={wrapInside}
      modalMaxWidth={modalMaxWidth}
      docData={isEditing ? moduleData : moduleFormConfig.defaultValues}
      onCloseForm={onCloseForm}
      hasDetails={false}
      onError={errorLogger}
      formInputs={({ formObject }) => (
        <Grid container spacing={1}>
          <FormRow icon={<DynamicFeed />}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={8}>
                <TextField
                  label={`${capitalize(labels.track)} naam`}
                  name='nameModu'
                  rules={{ required: 'Naam verplicht' }}
                  autoFocus
                  disabled={isEditing}
                  formObject={formObject}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={`${capitalize(labels.track)} code`}
                  name='codeModu'
                  rules={{ required: 'Code verplicht' }}
                  disabled={isEditing}
                  formObject={formObject}
                />
              </Grid>
            </Grid>
          </FormRow>
          <FormRow icon={<Subject />}>
            <TextField
              label={`${capitalize(labels.track)} omschrijving`}
              name='descriptionModu'
              rows={4}
              maxRows={20}
              multiline
              formObject={formObject}
            />
          </FormRow>
          <FormRow icon={<Timelapse />}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={4}>
                <TextField
                  label={`${capitalize(labels.track)} duur`}
                  type='number'
                  name='durationAmountModu'
                  formObject={formObject}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <AutoSelect
                  name='durationUnitModu'
                  formObject={formObject}
                  options={durationUnitOptions}
                />
              </Grid>
            </Grid>
          </FormRow>
          <FormRow icon={<Business />}>
            <AutoComplete
              label='Bedrijf kiezen'
              name='relationIDModu'
              formObject={formObject}
              collection='relations'
              labelKeys={['nameRela']}
              where={[['archivedRela', '==', false]]}
              searchKey='keywordsRela'
            />
          </FormRow>
        </Grid>
      )}
    />
  )
}

import { useBeforeunload } from './hooks/useBeforeunload'
type BeforeunloadProps = {
  children: any
  /** If true, beforeunload listener will be added */
  addListener: boolean
}

export const Beforeunload = ({
  children = null,
  addListener = true,
}: BeforeunloadProps) => {
  useBeforeunload(addListener)
  return children
}

import { Grid, Typography, Box } from '@mui/material'

const titles = {
  code: [
    { title: 'Bedrijf/locatie' },
    { title: 'Projectcode' },
    { title: 'Werkcode' },
  ],
  time: [{ title: 'Begintijd' }, { title: 'Duur' }, { title: 'Eindtijd' }],
}

export const TimeRegistrationRowHeader = () => {
  return (
    <Grid item xs={11}>
      <Box p='2px 16px 2px 16px'>
        <Grid container spacing={1}>
          <Grid item md={7}>
            <Grid container spacing={1}>
              {titles.code.map(column => {
                return (
                  <Grid key={column.title} item md={4}>
                    <Typography variant='body2'>{column.title}</Typography>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          <Grid item md={5}>
            <Grid container spacing={1}>
              {titles.time.map(column => {
                return (
                  <Grid key={column.title} item md={4}>
                    <Typography variant='body2'>{column.title}</Typography>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

import React, { useState } from 'react'
import {
  Chip,
  Grid,
  styled,
  Typography,
  TextField,
  Button,
  ClickAwayListener,
  InputAdornment,
} from '@mui/material'
import { useDebounce } from 'react-use'
import { DatePicker as MaterialDatePicker } from '@mui/lab'
import { subDays, addDays, format, getISOWeek } from 'date-fns'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Controller, useForm } from 'react-hook-form'
import { Check, Event } from '@mui/icons-material'

import { convertTimestampDate } from '../../utils/convertTimestampDate'
import { IconButton } from '../IconButton'
import { convertToDate } from '../../utils/convertToDate'
import { nl } from 'date-fns/locale'

const DATE_WIDTH = 250
const DATE_WIDTH_MOBILE = 230

const TodayButtonStyled = styled(Button)(() => ({
  height: '40px',
  marginLeft: 8,
}))
const TextFieldStyled = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: DATE_WIDTH_MOBILE,
  },
}))
const DateViewContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: DATE_WIDTH,
  padding: '0px 8px 0px 13px',
  height: '40px',
  borderRadius: '6px',
  border: `1px solid rgba(255,255,255,0)`,
  '&:hover': {
    borderColor: theme.palette.divider,
    cursor: 'pointer',
  },
  '& .MuiChip-root': {
    margin: '0px 8px 0px 8px',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.action.active,
  },
  '> p': {
    paddingTop: 1,
    width: 160,
  },
  [theme.breakpoints.down('sm')]: {
    width: DATE_WIDTH_MOBILE,
  },
}))

type DateNavigationLabels = {
  today: string
  week: string
}
const defaultLabels: DateNavigationLabels = {
  today: 'vandaag',
  week: 'week',
}

type DateNavigationTypes = {
  /** Name of the TextField*/
  name: string
  /** Additional onChange to get values from the DatePicker in custom implementations.*/
  onChange: (date: Date | null, value: string | undefined | Date) => void
  /**Array of elements that are shown inside the action menu */
  labels?: DateNavigationLabels
  minDate?: string | Date
  debounce?: number
}

type OnChangeProps = {
  fn: (value: Date, oldValue: Date) => void
  newValue: Date
  oldValue: Date
}

export const DateNavigation = ({
  labels = defaultLabels,
  name,
  minDate,
  onChange,
  debounce = 300,
  ...props
}: DateNavigationTypes) => {
  const [viewMode, setViewMode] = useState(true)
  const { control } = useForm({ defaultValues: { [`${name}`]: new Date() } })

  const [value, setValue] = useState<OnChangeProps | undefined>(undefined)

  /** Debounce on date changed */
  useDebounce(
    () => {
      if (value) {
        value.fn(value.newValue, value.oldValue)
      }
    },
    debounce,
    [value],
  )

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={convertTimestampDate(
        control._defaultValues?.current?.[name],
      )}
      render={({ field: { onChange: onChangeRHF, value } }) => (
        <>
          <Grid item>
            <IconButton
              label='Vorige week'
              onClick={() => {
                setValue({
                  fn: onChange,
                  newValue: subDays(value, 7),
                  oldValue: value,
                })
                onChangeRHF(subDays(value, 7))
              }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              label='Volgende week'
              onClick={() => {
                setValue({
                  fn: onChange,
                  newValue: addDays(value, 7),
                  oldValue: value,
                })
                onChangeRHF(addDays(value, 7))
              }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Grid>
          <Grid item>
            {!viewMode ? (
              <ClickAwayListener onClickAway={() => setViewMode(!viewMode)}>
                <MaterialDatePicker
                  {...props}
                  open={!viewMode}
                  label='Date picker'
                  value={convertTimestampDate(value)}
                  onClose={() => setViewMode(!viewMode)}
                  mask='__-__-____'
                  onChange={(newValue: any) => {
                    if (newValue) {
                      if (!isNaN(newValue.getTime())) {
                        if (minDate) {
                          if (
                            newValue.getTime() >=
                            convertToDate(minDate).getTime()
                          ) {
                            setValue({
                              fn: onChange,
                              newValue: newValue,
                              oldValue: value,
                            })
                            onChangeRHF(newValue)
                          }
                        } else {
                          setValue({
                            fn: onChange,
                            newValue: newValue,
                            oldValue: value,
                          })
                          onChangeRHF(newValue)
                        }
                      }
                    }
                  }}
                  onAccept={() => {
                    setViewMode(true)
                  }}
                  onOpen={() => setViewMode(!viewMode)}
                  renderInput={({ inputRef, inputProps }: any) => (
                    <div ref={inputRef}>
                      <TextFieldStyled
                        {...inputProps}
                        size='small'
                        name={name}
                        type='input'
                        onClick={e => e.stopPropagation()}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                edge='end'
                                label='Save'
                                onClick={() => setViewMode(true)}
                              >
                                <Check />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                />
              </ClickAwayListener>
            ) : (
              <DateViewContainer onClick={() => setViewMode(false)}>
                <Typography noWrap>
                  {format(value, 'dd MMMM', { locale: nl })}
                </Typography>
                <Chip
                  size='small'
                  label={`${labels.week}: ${getISOWeek(value)}`}
                />
                <Event />
              </DateViewContainer>
            )}
          </Grid>
          <Grid item>
            <TodayButtonStyled
              onClick={() => {
                onChange(new Date(), new Date())
                onChangeRHF(new Date())
                setViewMode(true)
              }}
            >
              {labels.today}
            </TodayButtonStyled>
          </Grid>
        </>
      )}
    />
  )
}

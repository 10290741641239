import { routeParts } from 'app/router/routeParts'
import { routeObjectType } from 'app/router/types'
import { PersonFormWrapper } from './PersonFormWrapper'
import { PersonsList } from './PersonsList'

export const personRoutes: routeObjectType = {
  personList: {
    path: `/${routeParts.persons.path}`,
    component: PersonsList,
    claims: {},
  },
  personForm: {
    path: `/${routeParts.persons.path}/:personID`,
    component: PersonFormWrapper,
    claims: {},
  },
  personFormNew: {
    path: `/${routeParts.persons.path}/${routeParts.adding.path}`,
    component: PersonFormWrapper,
    claims: {},
  },
}

import { keywordsCreator } from '../keywordsCreator'

type DataStandardTypes = {
  /** Data wherefor datastandards are created */
  data: any
  /** Is the data edited of new, default: false */
  editForm?: boolean
  /** uid of signed in user */
  uid: string
  /** Array of string keywords that should be searchable */
  keywords?: string[]
  /** Adds archived${dataName} to data which defaults to false, set this to true to archive when editing form */
  archived?: boolean
}

/** React hook to create data standards that every doc should use */

export const dataStandards = ({
  data,
  editForm = false,
  uid,
  keywords,
  archived,
}: DataStandardTypes) => {
  const newObject: any = {}

  if (archived !== undefined) {
    newObject.archived = archived
  }

  if (!data?.archived) {
    newObject.untilDate = ''
  } else {
    newObject.untilDate = new Date()
  }

  if (!editForm) {
    newObject.createdAt = new Date()
    newObject.fromDate = new Date()
    newObject.createdBy = uid
  } else {
    newObject.updatedAt = new Date()
    newObject.updatedBy = uid
  }

  if (keywords) {
    newObject.keywords = keywordsCreator(keywords)
  }
  return newObject
}

import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'hooks/useSelector'
import { Grid, Paper, Box, Typography, Button } from '@mui/material'
import { PersonListItem } from 'app/components/ListItems/PersonListItem'
import { PageContainer } from '@fivano/core'
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader'
import { ListItemBase } from 'app/components/ListItems/ListItemBase'
import { IconButton } from '@fivano/core'
import ParticipantsIcon from '@mui/icons-material/Group'
import AppsIcon from '@mui/icons-material/Apps'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import { useLocationQuery } from 'hooks/useLocationQuery'
import { legacyShortcuts } from 'app/containers/Navigation/legacyMenu'
import { ShortcutCard } from './ShortCutCard'
import { useSettings } from 'hooks/useSettings'
import { hasClaimLevel } from 'utils/hasClaimLevel'
export const timeDefaultValues = {
  id: '',
  startDate: new Date(new Date().setHours(0, 0, 0, 0)),
  startTime: new Date(new Date().setHours(8, 30, 0, 0)),
  endTime: new Date(new Date().setHours(9, 30, 0, 0)),
  text: '',
  duration: 60,
  locationID: '',
  personID: '',
}

export const HomePage = () => {
  const history = useHistory()
  const settings = useSettings()
  const profile = useSelector(state => state.firebase.profile)

  const [participantLimit, setParticipantLimit] = useState(5)

  const handleLoadMore = () => {
    setParticipantLimit(participantLimit + 5)
  }
  const locationQuery = useLocationQuery()
  useFirestoreConnect([
    {
      collectionGroup: 'persons',
      orderBy: ['lastNamePers', 'asc'],
      where: [
        ['archivedPers', '==', false],
        ['personTypePers', '==', 'participant'],
        ...locationQuery('locationIDPers'),
      ],
      limit: 10,
      storeAs: 'participantsHome',
    },
  ])

  // get participants from store and remove duplicate id's from actual return
  const participantsData = useSelector(({ firestore: { ordered } }) =>
    ordered?.participantsHome?.reduce((array, item) => {
      return array.some(i => i['id'] === item.id) ? array : [...array, item]
    }, []),
  )

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <PageContainer maxWidth='lg'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <WidgetHeader
              title='Deelnemers'
              icon={ParticipantsIcon}
              path='/deelnemers'
            />
            <Paper>
              <Box pt={1} pb={1}>
                {participantsData?.length > 0 ? (
                  participantsData.map(person => {
                    return (
                      <ListItemBase
                        key={person.id}
                        chevronIcon
                        onClick={() => {
                          history.push(
                            `/locatie/${person.locationIDPers}/deelnemers/${person.id}`,
                          )
                        }}
                        listItemActions={
                          <>
                            {person.phoneLinePers && (
                              <IconButton
                                onClick={() => {
                                  window.open(
                                    `tel:${person.phoneLinePers}`,
                                    '_self',
                                  )
                                }}
                                label='Telefoon'
                                size='large'
                              >
                                <PhoneEnabledIcon />
                              </IconButton>
                            )}
                          </>
                        }
                      >
                        <PersonListItem personData={person} showBirthday />
                      </ListItemBase>
                    )
                  })
                ) : (
                  <Box p={2}>
                    <Typography variant='body1'>
                      Geen deelnemers gevonden. Voeg een nieuwe deelnemer toe
                    </Typography>
                  </Box>
                )}
                {participantsData?.length === participantLimit && (
                  <Button fullWidth onClick={handleLoadMore}>
                    Meer deelnemers laden
                  </Button>
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Grid container>
                  <WidgetHeader title='Snelkoppelingen' icon={AppsIcon} />
                </Grid>
                <Grid container spacing={1}>
                  {legacyShortcuts(settings)?.map(
                    item =>
                      hasClaimLevel(profile, item.route.claims) && (
                        <Grid item xs={6} md={4} lg={3} key={item.name}>
                          <ShortcutCard
                            label={item.name}
                            icon={item.icon}
                            path={item.route.path}
                          />
                        </Grid>
                      ),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
    </>
  )
}

import React from 'react'
import {
  Grid,
  Box,
  Slider as MaterialSlider,
  Typography,
} from '@mui/material'
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { TextField } from '../TextField'

type SliderTypes = {
  /**Name of the Slider*/
  name: string
  /**Visible label*/
  label?: string
  /**Array of option objects*/
  options: Array<any>
  /**Object with all the form methods from react-hook-form.*/
  formObject: UseFormReturn
  /**Boolean whether the Slider is required*/
  required?: boolean
  /**Rules the Slider has to abide to*/
  rules?: RegisterOptions
  /**The minimum value of the Slider*/
  min?: number
  /**The maximum value of the Slider*/
  max?: number
  /**Number that tells the size between two values on the Slider*/
  step?: number
  /**Boolean if the Slider is disabled or not*/
  disabled?: boolean
  /**Boolean whether the input field is shown*/
  showInput?: boolean
  /**Default value*/
  defaultValue?: number
}

export function Slider({
  name,
  label,
  formObject,
  options,
  defaultValue = 0,
  min = 0,
  max = 10,
  step = 1,
  disabled,
  showInput = false,
  rules,
}: SliderTypes) {
  const {
    control,
    formState: { errors },
  } = formObject
  const inputWidth = 80
  defaultValue < min &&
    console.warn('Slider default value is lower than min value')
  defaultValue > max &&
    console.warn('Slider default value is higher than max value')

  return (
    <Box>
      {label && (
        <Typography variant='body1' {...(!!errors[name] && { color: 'error' })}>
          {label} {rules?.required ? '*' : ''}
        </Typography>
      )}
      <Controller
        name={name}
        rules={{
          ...rules,
          min: { value: min, message: `Selecteer een waarde hoger dan ${min}` },
          max: { value: max, message: `Selecteer een lager hoger dan ${max}` },
        }}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => {
          const handleInputChange = e => {
            const newValue = parseInt(e.target.value)
            onChange(newValue)
            if (newValue >= min && newValue <= max) {
              onChange(newValue)
            }
          }
          return (
            <Grid container>
              {showInput && (
                <Grid item style={{ width: inputWidth }}>
                  <TextField
                    name={name}
                    formObject={formObject}
                    size='small'
                    type='number'
                    value={value}
                    onChange={handleInputChange}
                  />
                </Grid>
              )}
              <Grid
                item
                width={showInput ? `calc(100% - ${inputWidth}px)` : '100%'}
                paddingLeft={showInput ? 16 : 8}
              >
                <MaterialSlider
                  value={value}
                  onChange={(_, value) => {
                    onChange(value)
                  }}
                  onBlur={onBlur}
                  defaultValue={defaultValue}
                  marks={options}
                  min={min}
                  max={max}
                  step={step}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          )
        }}
      />
      <Typography variant='caption' color='error'>
        <ErrorMessage name={name} errors={errors} />
      </Typography>
    </Box>
  )
}

import React from 'react'
import { Grid, Box } from '@mui/material'
import { useSettings } from 'hooks/useSettings'
import { ThemeColorSettings } from './ThemeColorSettings'
import { useFirestore } from 'react-redux-firebase'
import { useSnackbar } from '@fivano/core'
import { SettingsContainer } from 'app/components/SettingsContainer'
import { Form } from '@fivano/core'
import { useErrorLogger } from 'hooks/useErrorLogger'

const breadcrumbArray = [
  { title: 'Instellingen', path: '/instellingen' },
  { title: 'Kleur instellingen', path: '/instellingen/kleur-instellingen' },
]

const findInObjectByKey = (object, string) => {
  const themeObject = {}
  for (const [key, value] of Object.entries(object)) {
    if (key.includes(string)) {
      themeObject[key.replace(`${string}_`, '')] = value
    }
  }
  return themeObject
}

export const ColorSettings = () => {
  const firestore = useFirestore()
  const settings = useSettings()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async data => {
    const newObject = {}
    const themeNames = ['primary', 'secondary']
    themeNames.forEach(theme => {
      const themeObject = findInObjectByKey(data, theme)
      newObject[theme] = themeObject
    })
    settings.theme.palette = newObject

    await firestore
      .set(
        {
          collection: 'appSettings',
          doc: 'settings',
        },
        settings,
      )
      .then(() => {
        enqueueSnackbar(`Kleuren succesvol aangepast.`, {
          variant: 'success',
        })
        firestore.get({
          collection: 'appSettings',
          doc: 'settings',
          storeAs: 'settings',
        })
      })
  }

  const errorLogger = useErrorLogger()

  return (
    <SettingsContainer breadcrumbs={breadcrumbArray} showTitle={false}>
      <Box py={2} px={1} maxWidth={600}>
        <Grid container direction='column' spacing={2}>
          <Grid item xs={12}>
            <Form
              wrapInside='page'
              hasDetails={false}
              docLabel='Kleur'
              updateDoc={async () => {}}
              createDoc={onSubmit}
              editingDocID={''}
              docData={{}}
              onError={errorLogger}
              onCloseForm={{}}
              formInputs={({ formObject }) => (
                <Grid container>
                  {Object.keys(settings.theme.palette).map((theme, index) => {
                    if (typeof settings.theme.palette[theme] === 'object') {
                      return (
                        <ThemeColorSettings
                          key={`${theme}_${index}`}
                          theme={settings.theme.palette[theme]}
                          themeName={theme}
                          {...{ formObject }}
                        />
                      )
                    } else {
                      return false
                    }
                  })}
                </Grid>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </SettingsContainer>
  )
}

import { useSelector } from 'hooks/useSelector'
import { useSnackbar } from '@fivano/core'
import { useFirestore } from 'react-redux-firebase'
import { useLocation } from 'react-router-dom'

type ErrorType = {
  /** Error code */
  code: string
  /** Error name */
  name: string
  /** Error message */
  message: string
  /** Error stack */
  stack: string
}

type ErrorLoggerTypes = {
  /** Error with all  */
  error: ErrorType
  /** Snackbar message, only shows when message is not empty */
  snackbarMessage?: string
}

export const useErrorLogger = () => {
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const { pathname } = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const addErrorLogger = ({ error, snackbarMessage }: ErrorLoggerTypes) => {
    const errorObject = {
      codeError: error?.code || 'codeError empty',
      nameError: error?.name || 'nameError empty',
      messageError: error?.message || 'messageError empty',
      stackError: error?.stack || 'stackError empty',
      dateError: new Date(),
      profileIDError: profile?.uid || 'NO_UID',
      pathnameError: pathname || 'no_pathname',
    }

    console.warn(error) // DONT REMOVE THIS CONSOLE.WARN

    if (errorObject.codeError) {
      firestore
        .collection('errorLogger')
        .add(errorObject)
        .catch(error => {
          return error
        })
    }

    if (snackbarMessage) {
      enqueueSnackbar(snackbarMessage, {
        variant: 'error',
      })
    }
  }
  return addErrorLogger
}
